import type { TFunction } from 'i18next';
import i18next from 'i18next';
import type { HTTPClientWithAuth } from '@stimcar/libs-base';
import type { KeyValueStorage } from '@stimcar/libs-kernel';
import type { ActionContext, GlobalDispatch } from '@stimcar/libs-uikernel';
import type { AppEnvironment, ProgressBarMonitor } from '@stimcar/libs-uitoolkit';
import { HTTPClientWithAuthImpl } from '@stimcar/libs-base';
import { BrowserKeyValueStorageImpl } from '@stimcar/libs-uikernel';
import {
  BrowserEventSourceFactory,
  BrowserFormDataFactory,
  launchApp,
} from '@stimcar/libs-uitoolkit';
import { ThemeSelector } from '../themes/ThemeSelector.js';
import { postLoginChecksAndInitializationAction } from './authentication/utils.js';
import './i18n.js';
import type {
  SubcontractorStore,
  SubcontractorStoreActionContext,
  SubcontractorStoreState,
} from './state/typings/store.js';
import { EMPTY_SUBCONTRACTOR_STORE_STATE } from './state/store.js';
import { SubcontractorApp } from './SubcontractorApp.js';

interface SubcontractorEnv extends AppEnvironment {
  readonly keyValueStorage: KeyValueStorage;
  readonly httpClient: HTTPClientWithAuth;
}

function bindEnvironmentToStore(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  env: SubcontractorEnv,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dispatch: GlobalDispatch<SubcontractorStore>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  progressBarMonitor: ProgressBarMonitor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  t: TFunction
): void {
  // Actually, nothing to bind in the store for the subcontractor app
}

function createStoreActionContext(
  { httpClient, keyValueStorage }: SubcontractorEnv,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  progressBarMonitor: ProgressBarMonitor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  t: TFunction
): Partial<SubcontractorStoreActionContext> {
  return {
    keyValueStorage,
    httpClient,
  };
}

const onStoreReadyAction = async ({
  httpClient,
  actionDispatch,
}: ActionContext<SubcontractorStore, SubcontractorStoreState>) => {
  const authenticatedUser = await httpClient.getAuthenticatedUser();
  actionDispatch.scopeProperty('session').setProperty('user', authenticatedUser);
  if (authenticatedUser !== undefined) {
    await actionDispatch.exec(postLoginChecksAndInitializationAction);
  }
};

// Launch the application
export function launchSubcontractorApp() {
  launchApp<SubcontractorStore, SubcontractorEnv>({
    appComponent: SubcontractorApp,
    appDecoratorComponent: ThemeSelector,
    initialStoreState: EMPTY_SUBCONTRACTOR_STORE_STATE,
    environment: {
      keyValueStorage: new BrowserKeyValueStorageImpl(),
      httpClient: new HTTPClientWithAuthImpl(
        fetch.bind(window),
        BrowserFormDataFactory,
        BrowserEventSourceFactory
      ),
    },
    bindEnvironmentToStore,
    createStoreActionContext,
    onStoreReadyAction,
    t: i18next.t,
  });
}
