import { isTruthy } from '@stimcar/libs-kernel';
import type { CoreFields } from '../../model/index.js';
import type {
  Kanban,
  KanbanHandling,
  KanbanWorkInterval,
  StandHandlingStatus,
} from '../../model/typings/kanban.js';
import type { WithProgress } from './workflowProgressHelpers.js';
import { globalHelpers } from './globalHelpers.js';

const getOpenHandlingsFor = <K extends CoreFields<Kanban>>(k: K): KanbanHandling[] => {
  if (k.handlings.length !== 0) {
    const openHandlings = k.handlings.filter((h) => !isTruthy(h.endDate));
    if (openHandlings.length > 0) {
      return openHandlings;
    }
  }
  return [];
};

const getOpenHandlingForPost = <K extends CoreFields<Kanban>>(
  k: K,
  standId: string,
  postId: string
): KanbanHandling | undefined => {
  const handlings = getOpenHandlingsFor(k).filter(
    (h) => h.standId === standId && h.postId === postId
  );
  if (handlings.length > 1) {
    throw Error('The same post cannot have two open handlings on the same kanban');
  }
  return handlings.length === 0 ? undefined : handlings[0];
};

const getClosedHandlingsFor = (k: Kanban): KanbanHandling[] => {
  if (k.handlings.length !== 0) {
    const openHandlings = k.handlings.filter((h) => isTruthy(h.endDate));
    if (openHandlings.length > 0) {
      return openHandlings;
    }
  }
  return [];
};

function getOpenWorkshopHandlings<K extends CoreFields<Kanban>>(kanban: K): KanbanHandling[] {
  return getOpenHandlingsFor(kanban).filter(
    (h) =>
      globalHelpers.isQualifiedWorkshopPostId(h.postId) ||
      globalHelpers.isQualifiedCategory(h.postId)
  );
}

const isAnActiveHandling = (handling: KanbanHandling): boolean => {
  return !handling.endDate && handling.intervals.filter((i) => !i.endDate).length > 0;
};

const isAPausedHandling = (handling: KanbanHandling): boolean => {
  return !handling.endDate && handling.intervals.filter((i) => !i.endDate).length === 0;
};

const isAnActiveAnomalyHandling = (handling: KanbanHandling): boolean => {
  return (
    isAnActiveHandling(handling) && globalHelpers.isAnomalyPostQualifiedCategoryId(handling.postId)
  );
};

const getCurrentIntervalInActiveHandling = (
  handling: KanbanHandling
): KanbanWorkInterval | undefined => {
  const intervals = handling.intervals.filter((i) => !isTruthy(i.endDate));
  if (intervals.length > 1) {
    throw Error('A handling cannot have multiple open intervals');
  }
  return intervals.length === 0 ? undefined : intervals[0];
};

const isKanbanInWorkshopAnomaly = (standHandlings: readonly KanbanHandling[]): boolean => {
  let result = false;
  for (const handling of standHandlings) {
    if (isAnActiveAnomalyHandling(handling)) {
      result = true;
      break;
    }
  }
  return result;
};

const getHandleStatusForStand = (
  handlings: readonly KanbanHandling[],
  standId: string
): StandHandlingStatus => {
  let standHandlingStatus: StandHandlingStatus = 'none';
  const standHandlings = handlings.filter((h) => standId === h.standId);
  if (isKanbanInWorkshopAnomaly(standHandlings)) {
    // this checking has to occur first because a open handling on anomaly category IS a open handling
    // in other words : 'anomaly' is a sub-case of 'currentlyHandled'
    standHandlingStatus = 'anomaly';
  } else if (standHandlings.filter((h) => isAnActiveHandling(h)).length > 0) {
    standHandlingStatus = 'currentlyHandled';
  } else if (standHandlings.filter((h) => !h.endDate && h.intervals.length === 0).length > 0) {
    standHandlingStatus = 'waitingForHandle';
  } else if (standHandlings.length > 0) {
    standHandlingStatus = 'hasBeenHandled';
  }
  return standHandlingStatus;
};

export interface KanbanHandlingStatusInfo {
  readonly currentlyHandledOnStands: readonly string[];
  readonly hasBeenHandledOrIsInPauseOnStands: readonly string[];
  readonly isInAnomaly: boolean;
}

const computeKanbanHandlingStatusInfo = <K extends CoreFields<Kanban>>(
  kanban: WithProgress<K>,
  selectedStands: readonly string[]
): KanbanHandlingStatusInfo => {
  const hasBeenHandledOrIsInPauseOnStands: Set<string> = new Set();
  const currentlyHandledOnStands: Set<string> = new Set();
  let isInAnomaly = false;
  kanban.handlings.forEach((h) => {
    if (selectedStands.includes(h.standId)) {
      if (isAnActiveAnomalyHandling(h)) {
        isInAnomaly = true;
      } else if (isAnActiveHandling(h)) {
        currentlyHandledOnStands.add(h.standId);
      } else if (isAPausedHandling(h)) {
        hasBeenHandledOrIsInPauseOnStands.add(h.standId);
      } else {
        hasBeenHandledOrIsInPauseOnStands.add(h.standId);
      }
    }
  });
  return {
    hasBeenHandledOrIsInPauseOnStands: [...hasBeenHandledOrIsInPauseOnStands],
    currentlyHandledOnStands: [...currentlyHandledOnStands],
    isInAnomaly,
  };
};

const getStatusFromKanbanHandlingStatusInfo = (
  status: KanbanHandlingStatusInfo
): StandHandlingStatus => {
  if (status.isInAnomaly) {
    return 'anomaly';
  }
  if (status.currentlyHandledOnStands.length > 0) {
    return 'currentlyHandled';
  }
  if (status.hasBeenHandledOrIsInPauseOnStands.length > 0) {
    return 'hasBeenHandled';
  }
  return 'none';
};

export const handlingHelpers = {
  getOpenHandlingsFor,
  getClosedHandlingsFor,
  isAnActiveHandling,
  isAPausedHandling,
  getOpenHandlingForPost,
  getCurrentIntervalInActiveHandling,
  isAnActiveAnomalyHandling,
  isKanbanInWorkshopAnomaly,
  getHandleStatusForStand,
  computeKanbanHandlingStatusInfo,
  getStatusFromKanbanHandlingStatusInfo,
  getOpenWorkshopHandlings,
};
