import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { KanbanRepository } from '@stimcar/core-libs-repository';
import type { Kanban } from '@stimcar/libs-base';
import type { DeepPartial } from '@stimcar/libs-kernel';
import type { NoArgActionCallback, StoreStateSelector } from '@stimcar/libs-uikernel';
import { applyPayload, computePayload, formatPrice, isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ClickableIcon } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';

async function updateKanbanIfRequired(
  kanbanRepository: KanbanRepository,
  kanbanId: string,
  change: DeepPartial<Kanban>
): Promise<void> {
  const kanban = await kanbanRepository.getEntity(kanbanId);
  const updatedKanban = applyPayload(kanban, change);
  const payload = computePayload(kanban, updatedKanban);
  if (payload !== undefined) {
    await kanbanRepository.updateEntityFromPayload({ entityId: kanbanId, payload });
  }
}

export interface OverviewPackageDealItemProps {
  readonly $canceledPackageDealIds: StoreStateSelector<Store, readonly string[]>;
  readonly saveOverviewActionCallback: NoArgActionCallback<Store>;
  readonly id: string;
  readonly label: string;
  readonly price: number;
  readonly kanban: Kanban;
  readonly isPrimary?: boolean;
  readonly isCancelable?: boolean;
}

export function OverviewPackageDealItem({
  $canceledPackageDealIds,
  saveOverviewActionCallback,
  id,
  label,
  price,
  kanban,
  isCancelable,
  isPrimary = false,
}: OverviewPackageDealItemProps): JSX.Element {
  const [t] = useTranslation('details');
  const size = isPrimary ? 'is-size-6 has-text-weight-bold' : 'is-size-6';
  const canceledPackageDealIds: readonly string[] = useGetState($canceledPackageDealIds);
  const kanbanId = kanban.id;

  const isCanceled = useMemo(
    () => canceledPackageDealIds.includes(id),
    [id, canceledPackageDealIds]
  );

  const cancelPackageDealActionCallback = useActionCallback(
    async ({ actionDispatch, kanbanRepository }): Promise<void> => {
      await actionDispatch.execCallback(saveOverviewActionCallback);
      const updatedCanceledPackageDealIds = [...canceledPackageDealIds, id];
      actionDispatch.setValue(updatedCanceledPackageDealIds);
      await updateKanbanIfRequired(kanbanRepository, kanbanId, {
        marketplaceInfos: {
          canceledPackageDealIds: updatedCanceledPackageDealIds,
        },
        packageDeals: [
          {
            id,
            status: 'canceled',
          },
        ],
      });
    },
    [id, kanbanId, canceledPackageDealIds, saveOverviewActionCallback],
    $canceledPackageDealIds
  );

  const reactivatePackageDealActionCallback = useActionCallback(
    async ({ actionDispatch, kanbanRepository }): Promise<void> => {
      await actionDispatch.execCallback(saveOverviewActionCallback);
      const updatedCanceledPackageDealIds = canceledPackageDealIds.filter((value) => value !== id);
      actionDispatch.setValue(updatedCanceledPackageDealIds);
      await updateKanbanIfRequired(kanbanRepository, kanbanId, {
        marketplaceInfos: {
          canceledPackageDealIds: updatedCanceledPackageDealIds,
        },
        packageDeals: [
          {
            id,
            status: 'available',
          },
        ],
      });
    },
    [id, kanbanId, canceledPackageDealIds, saveOverviewActionCallback],
    $canceledPackageDealIds
  );

  return (
    <div className={`${size} is-flex is-justify-content-space-between`}>
      <div className={isCanceled ? 'strike-through' : ''}>{label}</div>
      <div className="is-flex">
        <div className={isCanceled ? 'strike-through' : ''}>{formatPrice(price, 2)}</div>
        {isTruthy(isCancelable) && !isCanceled && (
          <ClickableIcon
            id="trash"
            elementClassName="ml-2"
            tooltip={t('tabs.marketplace.overview.cancelPackageDealTooltip')}
            clickHandler={cancelPackageDealActionCallback}
          />
        )}
        {isTruthy(isCancelable) && isCanceled && (
          <ClickableIcon
            id="plus"
            elementClassName="ml-2"
            tooltip={t('tabs.marketplace.overview.reactivatePackageDealTooltip')}
            clickHandler={reactivatePackageDealActionCallback}
          />
        )}
      </div>
    </div>
  );
}
