export const DEFAULT_VAT_RATE = 20;

function getVAT(priceWithoutVAT: number, VATRate: number = DEFAULT_VAT_RATE): number {
  return priceWithoutVAT * (VATRate / 100);
}

function getPriceWithVAT(priceWithoutVAT: number, VATRate: number = DEFAULT_VAT_RATE): number {
  const VAT = getVAT(priceWithoutVAT, VATRate);
  return priceWithoutVAT + VAT;
}

function getPriceWithoutVAT(priceWithVAT: number, VATRate: number = DEFAULT_VAT_RATE): number {
  return priceWithVAT / (1 + VATRate);
}

function isSameAmount(price1: number, price2: number): boolean {
  return Math.abs(price1 - price2) < 0.001;
}

export const priceHelpers = {
  getVAT,
  getPriceWithVAT,
  getPriceWithoutVAT,
  isSameAmount,
};
