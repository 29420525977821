import { useMemo } from 'react';
import type { SiteConfiguration } from '@stimcar/libs-base';
import { Role } from '@stimcar/libs-base';
import { isTruthy, nonnull } from '@stimcar/libs-kernel';

export function useGetImplantationIds(
  configuration: SiteConfiguration | undefined,
  role: string
): readonly string[] {
  return useMemo((): string[] => {
    const implIds: string[] = [];
    if (configuration && role === Role.WorkshopOperator) {
      configuration.stands
        .filter((stand) => isTruthy(stand.implantations))
        .forEach((stand) => {
          nonnull(stand.implantations).forEach((impl) => {
            implIds.push(impl.id);
          });
        });
    }
    return implIds;
  }, [configuration, role]);
}
