/* eslint-disable jsx-a11y/control-has-associated-label */
import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Customer } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  AvailablePermissionPaths,
  globalHelpers,
  mergeArrayItems,
  shortDayMonthYearHourFormatOptions,
  sortingHelpers,
} from '@stimcar/libs-base';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import {
  FaIcon,
  InputFormField,
  ModalCardDialog,
  ScrollableTableComponent,
  TableActionCell,
  TruncableTableTd,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { TableActionHeaderCell } from '../../../lib/bulma/elements/table/TableHeaderActionCell.js';
import { RepositoryEntityDirtyIcon } from '../../../lib/components/misc/RepositoryEntityDirtyIcon.js';
import { TableSortableHeaderComponent } from '../../../lib/components/TableSortableHeaderComponent.js';
import { useComponentWithMountTracking } from '../../../lib/hooks/useComponentWithMountTracking.js';
import { useHasModifyPermission } from '../../../registeredapp/permissionHooks.js';
import type {
  AdminCustomersState,
  AdminEditCustomerDialogState,
  DeleteCustomerModalState,
} from './typings/store.js';
import {
  AdminEditCustomerDialog,
  openAdminCreateCustomerDialogAction,
  openAdminEditCustomerDialogAction,
  updateAdminCustomerEditDialogStateFromSSEAction,
} from './AdminEditCustomerDialog.js';
import { EMPTY_ADMIN_CUSTOMERS_STATE } from './typings/store.js';

const applyAndFilterCustomerAction = (
  { getState, actionDispatch }: ActionContext<Store, AdminCustomersState>,
  customers: readonly Customer[]
): void => {
  const { searchFilter } = getState();
  const filter = searchFilter.trim().toLowerCase();
  const filteredCustomers = customers.filter(
    ({ company, lastName, firstName, city, zipCode }) =>
      company.toLowerCase().includes(filter) ||
      lastName.toLowerCase().includes(filter) ||
      firstName.toLowerCase().includes(filter) ||
      city.toLowerCase().includes(filter) ||
      zipCode.toLowerCase().includes(filter)
  );
  actionDispatch.setProperty('customers', filteredCustomers);
};

export async function updateAdminCustomersViewStateFromSSEAction(
  ctx: ActionContext<Store, AdminCustomersState>,
  updatedOrAddedCustomers: readonly Customer[],
  removedCustomerIds: readonly string[]
): Promise<void> {
  const { getState, actionDispatch } = ctx;
  const { componentIsMounted } = getState();
  if (!componentIsMounted) {
    return;
  }

  // Merge actual items with SSE updates
  const { customers } = getState();
  const newCustomers = mergeArrayItems(customers, updatedOrAddedCustomers, removedCustomerIds);

  applyAndFilterCustomerAction(ctx, newCustomers);

  await actionDispatch
    .scopeProperty('editCustomerDialog')
    .exec(updateAdminCustomerEditDialogStateFromSSEAction, newCustomers);
}

async function initializeAdminCustomerStateAction(
  ctx: ActionContext<Store, AdminCustomersState>
): Promise<void> {
  const { customerRepository } = ctx;
  const customers = await customerRepository.getAllEntities();
  applyAndFilterCustomerAction(ctx, customers);
}

interface AdminCustomerTableItemProps {
  readonly customer: Customer;
  readonly isOnline: boolean;
  readonly $: StoreStateSelector<Store, AdminCustomersState>;
  readonly $modal: StoreStateSelector<Store, AdminEditCustomerDialogState>;
  readonly isEditionForbidden: boolean;
}

function AdminCustomerTableItem({
  customer,
  isOnline,
  $,
  $modal,
  isEditionForbidden,
}: AdminCustomerTableItemProps): JSX.Element {
  const [t] = useTranslation(['adminCustomers', 'globals']);
  const { id } = customer;
  const openEditDialogActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openAdminEditCustomerDialogAction, id);
    },
    [id],
    $modal
  );
  const openCloneDialogActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openAdminEditCustomerDialogAction, id, 'create');
    },
    [id],
    $modal
  );
  const showDeleteModalCallback = useActionCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async ({ actionDispatch }) => {
      const deleteModalDispatch = actionDispatch.scopeProperty('deleteCustomerDialog');
      deleteModalDispatch.setProperty('active', true);
      deleteModalDispatch.setProperty('customerId', id);
    },
    [id],
    $
  );
  return (
    <tr>
      <td className="has-text-centered">
        <RepositoryEntityDirtyIcon isOnline={isOnline} entity={customer} />
      </td>
      <td className="has-text-centered">
        <FaIcon id={customer.individual ? 'male' : 'building'} />
      </td>
      <TruncableTableTd>{customer.contract}</TruncableTableTd>
      <TruncableTableTd>{customer.shortName}</TruncableTableTd>
      <TruncableTableTd>{customer.company}</TruncableTableTd>
      <TruncableTableTd>{t(`common:civilities.${customer.civility}`)}</TruncableTableTd>
      <TruncableTableTd>{customer.lastName}</TruncableTableTd>
      <TruncableTableTd>{customer.firstName}</TruncableTableTd>
      <TruncableTableTd>{customer.zipCode}</TruncableTableTd>
      <TruncableTableTd>{customer.city}</TruncableTableTd>
      <TruncableTableTd className="has-text-right">
        {globalHelpers.convertTimestampToDateString(
          customer.timestamp,
          shortDayMonthYearHourFormatOptions
        )}
      </TruncableTableTd>
      <TableActionCell
        onClick={openCloneDialogActionCallback}
        iconId="clone"
        tooltip={t('table.cloneButton')}
        disabled={isEditionForbidden}
      />
      <TableActionCell
        onClick={openEditDialogActionCallback}
        iconId="edit"
        tooltip={t('table.editButton')}
        disabled={isEditionForbidden}
      />
      <TableActionCell
        onClick={showDeleteModalCallback}
        iconId="trash"
        tooltip={t('table.deleteButton')}
        disabled={isEditionForbidden}
      />
    </tr>
  );
}

export function AdminCustomers({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation(['adminCustomers', 'globals']);
  const { $adminCustomers } = $gs.$adminView;
  const { $editCustomerDialog, $deleteCustomerDialog } = $adminCustomers;

  useComponentWithMountTracking(
    $adminCustomers,
    initializeAdminCustomerStateAction,
    EMPTY_ADMIN_CUSTOMERS_STATE
  );

  const isOnline = useGetState($gs.$session.$isOnline);
  const isEditionForbidden = !useHasModifyPermission(
    $gs,
    AvailablePermissionPaths.REPOSITORY_ACCESS_PERMISSION('customer')
  );

  const reloadCustomersActionCallback = useActionCallback(
    initializeAdminCustomerStateAction,
    [],
    $adminCustomers
  );

  const customers = useGetState($adminCustomers.$customers);
  const sortBy = useGetState($adminCustomers.$sort.$by);
  const sortDirection = useGetState($adminCustomers.$sort.$direction);

  const sortedCustomers = useMemo((): readonly Customer[] => {
    let sortFunction: (k1: Customer, k2: Customer) => number;
    switch (sortBy) {
      case 'shortName':
      case 'company':
      case 'civility':
      case 'firstName':
      case 'lastName':
      case 'zipCode':
      case 'city':
      case 'contract':
        sortFunction = sortingHelpers.createSortByStringField(sortDirection, sortBy);
        break;
      case 'timestamp':
        sortFunction = sortingHelpers.createSortByNumericField(sortDirection, sortBy);
        break;
      default:
        return customers;
    }
    return [...customers].sort(sortFunction);
  }, [customers, sortBy, sortDirection]);

  const openCreateDialogActionCallback = useActionCallback(
    openAdminCreateCustomerDialogAction,
    [],
    $editCustomerDialog
  );

  const deleteActionCallback = useActionCallback(
    async ({
      actionDispatch,
      getState,
      customerRepository,
    }: ActionContext<Store, DeleteCustomerModalState>): Promise<void> => {
      const { customerId } = getState();
      await customerRepository.archiveEntity(customerId);
      actionDispatch.setProperty('active', false);
    },
    [],
    $deleteCustomerDialog
  );
  return (
    <>
      <div className="level">
        <div className="level-left">
          <div>
            <p className="title is-2">{t('title')}</p>
            <p className="subtitle is-4">{t('subtitle')}</p>
          </div>
        </div>
        <div className="level-right">
          <div className="buttons">
            <button
              type="button"
              className="button"
              onClick={openCreateDialogActionCallback}
              disabled={isEditionForbidden}
            >
              <span>{t('buttons.new')}</span>
            </button>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-2">
          <InputFormField
            label={t('searchFilter')}
            $={useSelectorWithChangeTrigger(
              $adminCustomers.$searchFilter,
              reloadCustomersActionCallback
            )}
          />
        </div>
        <div className="column">
          <ScrollableTableComponent
            isTruncable
            tableClassName="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
          >
            <thead>
              <tr>
                <TableActionHeaderCell
                  iconId="r/check-circle"
                  tooltip={t('table.columns.status')}
                />
                <TableActionHeaderCell iconId="question" />
                <TableSortableHeaderComponent
                  content={t('table.columns.contract')}
                  isTruncable
                  centerLabel={false}
                  sortedField="contract"
                  $sort={$adminCustomers.$sort}
                  style={{ width: '10%' }}
                />
                <TableSortableHeaderComponent
                  content={t('table.columns.shortName')}
                  isTruncable
                  centerLabel={false}
                  sortedField="shortName"
                  $sort={$adminCustomers.$sort}
                  style={{ width: '15%' }}
                />
                <TableSortableHeaderComponent
                  content={t('table.columns.company')}
                  isTruncable
                  centerLabel={false}
                  sortedField="company"
                  $sort={$adminCustomers.$sort}
                  style={{ width: '15%' }}
                />
                <TableSortableHeaderComponent
                  content={t('table.columns.civility')}
                  isTruncable
                  centerLabel={false}
                  sortedField="civility"
                  $sort={$adminCustomers.$sort}
                  style={{ width: '10%' }}
                />
                <TableSortableHeaderComponent
                  content={t('table.columns.lastName')}
                  isTruncable
                  centerLabel={false}
                  sortedField="lastName"
                  $sort={$adminCustomers.$sort}
                  style={{ width: '15%' }}
                />
                <TableSortableHeaderComponent
                  content={t('table.columns.firstName')}
                  isTruncable
                  centerLabel={false}
                  sortedField="firstName"
                  $sort={$adminCustomers.$sort}
                  style={{ width: '15%' }}
                />
                <TableSortableHeaderComponent
                  content={t('table.columns.zipCode')}
                  isTruncable
                  centerLabel={false}
                  sortedField="zipCode"
                  $sort={$adminCustomers.$sort}
                  style={{ width: '10%' }}
                />
                <TableSortableHeaderComponent
                  content={t('table.columns.city')}
                  isTruncable
                  centerLabel={false}
                  sortedField="city"
                  $sort={$adminCustomers.$sort}
                  style={{ width: '15%' }}
                />
                <TableSortableHeaderComponent
                  content={t('table.columns.lastModified')}
                  isTruncable
                  centerLabel={false}
                  sortedField="timestamp"
                  $sort={$adminCustomers.$sort}
                  style={{ width: '10%' }}
                />
                <TableActionHeaderCell />
                <TableActionHeaderCell />
                <TableActionHeaderCell />
              </tr>
            </thead>
            <tbody>
              {customers.length === 0 && (
                <tr key="emptyline">
                  <td>&nbsp;</td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              )}
              {sortedCustomers.map(
                (customer: Customer): JSX.Element => (
                  <AdminCustomerTableItem
                    key={customer.id}
                    customer={customer}
                    isOnline={isOnline}
                    $modal={$editCustomerDialog}
                    $={$adminCustomers}
                    isEditionForbidden={isEditionForbidden}
                  />
                )
              )}
            </tbody>
          </ScrollableTableComponent>
        </div>
      </div>
      <AdminEditCustomerDialog $gs={$gs} />
      <ModalCardDialog
        $active={$deleteCustomerDialog.$active}
        title={t('deleteModal.title')}
        onOkClicked={deleteActionCallback}
      >
        <p>{t('deleteModal.message')}</p>
      </ModalCardDialog>
    </>
  );
}
