import { isTruthy, nonnull } from '@stimcar/libs-kernel';
import type { SiteConfiguration } from '../../model/typings/configuration.js';
import type { KanbanCustomerSideValidationSignature } from '../../model/typings/db.js';
import type { PackageDealSignature } from '../../model/typings/kanban.js';
import type {
  AdditionalDataForCustomerEstimateSignature,
  KanbanSignatureType,
  SharedKanban,
} from '../../model/typings/share.js';
import { CUSTOMER_VALIDATION_STAND_ID, DELIVERY_STAND_ID } from '../../model/globalConstants.js';
import { expertiseHelpers } from './expertiseHelpers.js';
import { kanbanHelpers } from './kanbanHelpers.js';
import { workflowProgressHelpers } from './workflowProgressHelpers.js';

function isAdditionalDataForElectronicSignatureCorrect(
  mode: KanbanSignatureType,
  additionalData: unknown
): boolean {
  let isValid = false;
  switch (mode) {
    case 'deliverySignature':
      isValid = true;
      break;
    case 'estimateSignature':
      if (typeof additionalData === 'object' && additionalData !== null) {
        const data = additionalData as AdditionalDataForCustomerEstimateSignature;
        if (
          isTruthy(data.availablePackageDealIds) &&
          Array.isArray(data.availablePackageDealIds) &&
          isTruthy(data.canceledPackageDealIds) &&
          Array.isArray(data.canceledPackageDealIds) &&
          isTruthy(data.totalRefurbishRevenueWithoutVAT) &&
          typeof data.totalRefurbishRevenueWithoutVAT === 'number'
        ) {
          isValid = true;
        }
      }
      break;
    default:
      break;
  }
  return isValid;
}

function sortKanbanCustomerSideValidationSignature(
  signature: KanbanCustomerSideValidationSignature
): KanbanCustomerSideValidationSignature {
  function sort(pcks: readonly PackageDealSignature[]): PackageDealSignature[] {
    return [...pcks]
      .sort((s1, s2) => s1.code.localeCompare(s2.code))
      .sort((s1, s2) => s1.price - s2.price);
  }

  return {
    ...signature,
    packageDealsSignature: {
      canceledPackageDeals: sort(signature.packageDealsSignature.canceledPackageDeals),
      selectedPackageDeals: {
        achievables: sort(signature.packageDealsSignature.selectedPackageDeals.achievables),
        unachievables: sort(signature.packageDealsSignature.selectedPackageDeals.unachievables),
      },
    },
  };
}

function areSignaturesEqual(
  source: KanbanCustomerSideValidationSignature,
  target: KanbanCustomerSideValidationSignature
): boolean {
  const stringifiedSortedSource = JSON.stringify(sortKanbanCustomerSideValidationSignature(source));
  const stringifiedSortedTarget = JSON.stringify(sortKanbanCustomerSideValidationSignature(target));
  return stringifiedSortedSource === stringifiedSortedTarget;
}

function hasADocumentToSignByCustomer<SK extends SharedKanban>(
  kanban: SK,
  siteConfiguration: SiteConfiguration,
  standId: typeof DELIVERY_STAND_ID | typeof CUSTOMER_VALIDATION_STAND_ID
): {
  canBeSigned: boolean;
  reasonIfNot: 'notReadyForSignature' | 'signatureAlreadyDone' | 'isCurrentlyHandled' | null;
} {
  const workflow = nonnull(siteConfiguration.workflows.find((w) => w.id === kanban.workflowId));
  const { expectedStandIds, currentStandIds } = workflowProgressHelpers.computeProgress(
    workflow.definition,
    kanban
  );

  const upstreamStandsIncludingSelf = [
    ...workflowProgressHelpers.collectUpstreamStands(workflow.definition, standId),
  ];
  const upstreamStands = upstreamStandsIncludingSelf.slice(
    0,
    upstreamStandsIncludingSelf.length - 1
  );

  let hasAnOperationToComplete = false;
  let hasNotReceivedSpareParts = false;

  switch (standId) {
    case DELIVERY_STAND_ID:
      hasAnOperationToComplete = kanbanHelpers.hasAUnfinishedDeliveryOperation(kanban);
      hasNotReceivedSpareParts = kanbanHelpers.getAllNotReceivedSpareParts(kanban).length !== 0;
      break;
    case CUSTOMER_VALIDATION_STAND_ID:
      hasAnOperationToComplete = expertiseHelpers.hasAnExpertiseToValidate(
        kanban.packageDeals,
        kanban.workflowId,
        siteConfiguration
      );
      break;
    default:
      throw Error(`Customer cannot sign a document for the stand ${standId}`);
  }

  function hasIntersection(array1: readonly string[], array2: readonly string[]): boolean {
    return array1.filter((x) => array2.includes(x)).length > 0;
  }

  let reasonIfNot: 'notReadyForSignature' | 'signatureAlreadyDone' | 'isCurrentlyHandled' | null =
    null;
  if (
    hasIntersection(expectedStandIds, upstreamStands) ||
    (standId === DELIVERY_STAND_ID && hasNotReceivedSpareParts)
  ) {
    reasonIfNot = 'notReadyForSignature';
  } else if (standId === DELIVERY_STAND_ID && hasIntersection(currentStandIds, upstreamStands)) {
    reasonIfNot = 'isCurrentlyHandled';
  } else if (!hasAnOperationToComplete) {
    reasonIfNot = 'signatureAlreadyDone';
  }

  return {
    canBeSigned: reasonIfNot === null,
    reasonIfNot,
  };
}

export const electronicSignatureHelpers = {
  isAdditionalDataForElectronicSignatureCorrect,
  hasADocumentToSignByCustomer,
  areSignaturesEqual,
  sortKanbanCustomerSideValidationSignature,
};
