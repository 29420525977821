import type { JSX } from 'react';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { KanbanBiWorkloadRanges } from '@stimcar/core-libs-common';
import type { PackageDealCategory } from '@stimcar/libs-base';
import type {
  ActionContext,
  GlobalStoreStateSelector,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import { CoreBackendRoutes, globalHelpers } from '@stimcar/libs-base';
import { Logger } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useArrayItemSelector,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import { FaIcon } from '@stimcar/libs-uitoolkit';
import type { Store } from '../state/typings/store.js';
import { RadioButtonsFormField } from '../../lib/bulma/form/RadioButtonsFormField.js';
import { SwitchFormField } from '../../lib/bulma/form/SwitchFormField.js';
import type { ShiftProgressDisplayState, ShiftProgressRange } from './typings/store.js';
import { EMPTY_SHIFT_PROGRESS_DISPLAY_STATE } from './typings/store.js';

const log: Logger = Logger.new(import.meta.url);

const EXP_CATEGORY: PackageDealCategory = 'EXP';
const MECA_CARRO_AUTRE_CATEGORIES: readonly PackageDealCategory[] = ['CARRO', 'MECA', 'AUTRE'];

async function updateShiftProgressStatsAction({
  actionDispatch,
  httpClient,
  getState,
  getGlobalState,
  loggerClient,
}: ActionContext<Store, ShiftProgressDisplayState>) {
  // Only refresh the stats if the session is online
  if (getGlobalState().session.isOnline) {
    const { mode, includeSubcontractors } = getState();

    // Select the right shift configuration
    const { startHour, startMinute, collaboratorsCount, expectedWorkloadByCollaborator, factor } =
      mode === 'EXP'
        ? getGlobalState().siteConfiguration.shiftsConfiguration.expertise
        : getGlobalState().siteConfiguration.shiftsConfiguration.workshop;

    try {
      const [shift1Start, shift2Start, shift3Start] = globalHelpers.computeShiftHours(startHour);
      const lastShiftStart = globalHelpers.computePreviousShiftStart(startHour, startMinute);
      let shiftCollaboratorsCount = 0;
      switch (lastShiftStart.getHours()) {
        case shift1Start:
          shiftCollaboratorsCount = collaboratorsCount.shift1;
          break;
        case shift2Start:
          shiftCollaboratorsCount = collaboratorsCount.shift2;
          break;
        case shift3Start:
          shiftCollaboratorsCount = collaboratorsCount.shift3;
          break;
        default:
          loggerClient.error(
            'ShiftProgress',
            'Last shift does not correspond to any shift hour',
            undefined,
            [
              {
                key: 'lastShiftStart',
                value: lastShiftStart.toLocaleString(),
              },
              {
                key: 'shift1Start',
                value: shift1Start.toLocaleString(),
              },
              {
                key: 'shift2Start',
                value: shift2Start.toLocaleString(),
              },
              {
                key: 'shift3Start',
                value: shift3Start.toLocaleString(),
              },
              {
                key: 'shiftsConfiguration',
                value: JSON.stringify(
                  getGlobalState().siteConfiguration.shiftsConfiguration,
                  null,
                  2
                ),
              },
            ]
          );
          log.error('lastShiftStart:', lastShiftStart);
          log.error('shiftStarts:', [shift1Start, shift2Start, shift3Start]);
          throw new Error('Last shift does not correspond to any shift hour');
      }
      // Move back one day
      const from0 = new Date(lastShiftStart.getTime());
      from0.setDate(from0.getDate() - 1);
      // Move forward 8H (following shift)
      const from1 = new Date(from0);
      from1.setHours(from1.getHours() + 8);
      // Move forward 8H (following shift)
      const from2 = new Date(from1);
      from2.setHours(from2.getHours() + 8);
      const froms = [from0 /* -24H */, from1 /* -16H */, from2 /* -8H */, lastShiftStart /* 0H */];

      // Compute selected categories
      const categories: readonly PackageDealCategory[] =
        mode === 'EXP' ? [EXP_CATEGORY] : MECA_CARRO_AUTRE_CATEGORIES;

      // If no category is selected, simply reset the state
      if (categories.length === 0) {
        actionDispatch.setProperty('lastShifts', EMPTY_SHIFT_PROGRESS_DISPLAY_STATE.lastShifts);
        actionDispatch.setProperty('currentWorkload', 0);
        actionDispatch.setProperty('expectedWorkload', 0);
      } else {
        // Otherwise query elastic
        const { ranges } = await httpClient.httpGetAsJson<KanbanBiWorkloadRanges>(
          `${CoreBackendRoutes.KANBAN_PROD_BI_WORKLOAD_STATS}?${categories.map((cat) => `category=${cat}`).join('&')}&includeSubcontractors=${includeSubcontractors}${froms.map((from) => `&from=${from.getTime()}`).join('&')}`
        );

        // Set state
        actionDispatch.setProperty(
          'lastShifts',
          ranges.slice(0, 3).map(({ from, workload, to }, index) => ({
            id: String(index),
            from,
            to,
            workload: workload * factor,
          })) as [ShiftProgressRange, ShiftProgressRange, ShiftProgressRange]
        );
        actionDispatch.setProperty('currentWorkload', ranges[ranges.length - 1].workload * factor);
        const totalExpectedShiftWorkload = shiftCollaboratorsCount * expectedWorkloadByCollaborator;
        const dayProgress = (Date.now() - lastShiftStart.getTime()) / (8 * 60 * 60 * 1000);
        actionDispatch.setProperty(
          'expectedWorkload',
          totalExpectedShiftWorkload * dayProgress * factor
        );
      }
    } catch (ignored) {
      // Don't fail if an HTTP error occurs
      log.error('Unexpected error during stats refresh', ignored);
    }
  }
}

async function persistStateActionReloadStats({
  getState,
  actionDispatch,
}: ActionContext<Store, ShiftProgressDisplayState>) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mode, size, includeSubcontractors } = getState();
  localStorage.setItem(
    LocalStorageKeys.SHIFT_PROGRESS_DISPLAY,
    JSON.stringify({ mode, size, includeSubcontractors })
  );
  await actionDispatch.exec(updateShiftProgressStatsAction);
}

const MODES = ['EXP', 'TUBE'];

const SIZES = [1, 2, 3, 4, 5, 6, 7, 8, 9];

interface Props {
  readonly $gs: GlobalStoreStateSelector<Store>;
}

export function ShiftProgressDisplay({ $gs }: Props): JSX.Element {
  const [t] = useTranslation(['display']);
  const { $shiftProgressDisplay } = $gs.$displayView;

  const loadInitialStateActionCallback = useActionCallback(
    function loadInitialStateAction({ actionDispatch }) {
      const persistedState = localStorage.getItem(LocalStorageKeys.SHIFT_PROGRESS_DISPLAY);
      if (persistedState) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        actionDispatch.applyPayload({
          ...JSON.parse(persistedState),
        });
      }
    },
    [],
    $shiftProgressDisplay
  );

  useEffect((): void => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadInitialStateActionCallback();
  }, [loadInitialStateActionCallback]);

  const updateShiftProgressStatsActionCallback = useActionCallback(
    updateShiftProgressStatsAction,
    [],
    $shiftProgressDisplay
  );

  const siteConfiguration = useGetState($gs.$siteConfiguration);

  useEffect(() => {
    // Update the stats if the site configuration changes
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateShiftProgressStatsActionCallback();
  }, [siteConfiguration, updateShiftProgressStatsActionCallback]);

  useEffect((): (() => void) => {
    // Update stats once (otherwise, the interval wil only schedule the first
    // execution in a few seconds)
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateShiftProgressStatsActionCallback();
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const intervalId = setInterval(updateShiftProgressStatsActionCallback, 15000);
    return () => {
      clearInterval(intervalId);
    };
  }, [updateShiftProgressStatsActionCallback]);

  const $shift0 = useArrayItemSelector($shiftProgressDisplay.$lastShifts, '0');
  const $shift1 = useArrayItemSelector($shiftProgressDisplay.$lastShifts, '1');
  const $shift2 = useArrayItemSelector($shiftProgressDisplay.$lastShifts, '2');

  const size = useGetState($shiftProgressDisplay.$size);
  const currentWorkload = useGetState($shiftProgressDisplay.$currentWorkload);
  const expectedWorkload = useGetState($shiftProgressDisplay.$expectedWorkload);
  const delta = currentWorkload - expectedWorkload;

  const persistStateActionCallback = useActionCallback(
    persistStateActionReloadStats,
    [],
    $shiftProgressDisplay
  );

  const $shiftProgressDisplayWithChangeTrigger = useSelectorWithChangeTrigger(
    $shiftProgressDisplay,
    persistStateActionCallback
  );

  const colorClassName = useMemo(() => {
    if (delta >= -0.1) {
      return 'has-text-success';
    }
    if (delta < 0 && Math.abs(delta / expectedWorkload) < 0.1 /* 10% */) {
      return 'has-text-warning';
    }
    return 'has-text-danger';
  }, [delta, expectedWorkload]);

  const faIcon = useMemo(() => {
    if (delta >= -0.1) {
      return 'rocket';
    }
    if (delta < 0 && Math.abs(delta / expectedWorkload) < 0.1 /* 10% */) {
      return 'cloud-sun-rain';
    }
    return 'cloud-showers-heavy';
  }, [delta, expectedWorkload]);

  return (
    <>
      <div className="columns">
        <div className="column is-narrow">
          <RadioButtonsFormField
            label={t('shiftProgressDisplay.size')}
            $={$shiftProgressDisplayWithChangeTrigger.$size}
            id="size"
            entries={SIZES}
            radioGroupLayout="vertical"
          />
          <RadioButtonsFormField
            label={t('shiftProgressDisplay.mode')}
            $={$shiftProgressDisplayWithChangeTrigger.$mode}
            id="mode"
            entries={MODES}
            radioGroupLayout="vertical"
          />
          <SwitchFormField
            label={t('shiftProgressDisplay.includeSubcontractors')}
            $={$shiftProgressDisplayWithChangeTrigger.$includeSubcontractors}
            switchId="includeSubcontractors"
          />
        </div>
        <div className="column has-text-centered">
          <div>
            <span
              className={colorClassName}
              style={{ fontSize: `${100 * size}px`, lineHeight: '1em' }}
            >
              {`${delta >= 0 ? '+' : ''}${globalHelpers.roundTo(delta, 1)} `}
            </span>
            <FaIcon id={faIcon} additionalClass={colorClassName} size={50 * size} />
          </div>
          <div>
            <span
              className={colorClassName}
              style={{ fontSize: `${90 * size}px`, lineHeight: '1em' }}
            >
              {globalHelpers.roundTo(currentWorkload, 0)}
            </span>
            <span style={{ fontSize: `${90 * size}px`, lineHeight: '1em' }}>
              {` / ${globalHelpers.roundTo(expectedWorkload, 0)}`}
            </span>
          </div>
        </div>
        <div className="column is-narrow has-text-centered">
          <LastShiftProgressStat size={size} $={$shift2} />
          <LastShiftProgressStat size={size} $={$shift1} />
          <LastShiftProgressStat size={size} $={$shift0} />
        </div>
      </div>
    </>
  );
}

function toTimeString(date: Date): string {
  return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
}
interface LastShiftProgressStatProps {
  readonly $: StoreStateSelector<Store, ShiftProgressRange>;
  readonly size: number;
}

function LastShiftProgressStat({ $, size }: LastShiftProgressStatProps): JSX.Element {
  const fromTimestamp = useGetState($.$from);
  const toTimestamp = useGetState($.$to);
  const shiftTime = useMemo(() => {
    const from = new Date(fromTimestamp);
    const to = new Date(toTimestamp!);
    return `${toTimeString(from)}➡️${toTimeString(to)}`;
  }, [fromTimestamp, toTimestamp]);
  const shiftWorkload = useGetState($.$workload);
  return (
    <>
      <div>
        <span style={{ fontSize: `${7 * size}px`, lineHeight: '1em' }}>{shiftTime}</span>
      </div>
      <div>
        <span style={{ fontSize: `${55 * size}px`, lineHeight: '1em' }}>
          {globalHelpers.roundTo(shiftWorkload, 0)}
        </span>
      </div>
    </>
  );
}
