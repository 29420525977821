import type { TFunction } from 'i18next';
import type { Environment, LightEnvironment } from '@stimcar/core-libs-repository';
import { CoreBackendRoutes, getMajorVersion, Role } from '@stimcar/libs-base';
import { BUILD_VERSION, BUILD_VERSION_HEADER, Logger } from '@stimcar/libs-kernel';
import { RESET_DATA_FULL_PATH } from '@stimcar/libs-uitoolkit';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

// Use same key as HTTP header
const CLIENT_BUILD_VERSION_KEY = BUILD_VERSION_HEADER;

export async function handleModelMigrationUpgrades<IS_LIGHT_ENVIRONMENT extends boolean>(
  env: IS_LIGHT_ENVIRONMENT extends false ? Environment : LightEnvironment,
  localStorageKeysToRemoveWhenAppIsUpdated: readonly string[],
  t: TFunction
): Promise<void> {
  const httpClient = env.getHttpClient();
  if (httpClient.isOnline()) {
    const keyValueStorage = env.getKeyValueStorage();
    // When the network becomes online, we must first check the build version
    const serverBuildVersion = await httpClient.httpGetAsText(CoreBackendRoutes.BUILD_VERSION);
    // Get client version from persistent storage
    let clientVersion = keyValueStorage.getItem(CLIENT_BUILD_VERSION_KEY);
    if (!clientVersion) {
      // If the client version is missing, persist the default one
      // (which happens on first connection)
      clientVersion = BUILD_VERSION;
      keyValueStorage.setItem(CLIENT_BUILD_VERSION_KEY, clientVersion);
    }
    const clientMajorVersion = getMajorVersion(clientVersion);
    const serverMajorVersion = getMajorVersion(serverBuildVersion);
    // If a user is authenticated, open an alert to warn about the coming refresh
    // If the user is a WorkshopOperator, do not open the alert since, they cannot lose any data
    const authenticatedUser = await httpClient.getAuthenticatedUser();
    const displayVersionMismatchMessage =
      authenticatedUser !== undefined &&
      httpClient.getBrowserInfos().role !== Role.WorkshopOperator;
    // If a major version mismatch occurs, data has been upgraded on
    // the server side
    if (clientMajorVersion !== serverMajorVersion) {
      if (displayVersionMismatchMessage) {
        // Version mismatches alerts use native browser alert because data may need to be refreshed
        // as soon as possible (going through react state may lead to an unexpected state).
        // eslint-disable-next-line no-alert
        window.alert(t('refitit:boot.majorVersionHasChangedAlert'));
      }
      // Release persisted client version
      keyValueStorage.removeItem(CLIENT_BUILD_VERSION_KEY);
      // Call reset route (which will cause the javascript bundle to be upgraded +
      // indexed data to be reinitialized)
      document.location.href = RESET_DATA_FULL_PATH;
    }
    // Otherwise, the application has been updated, so that we need to refresh
    // the page to reload javascript bundles
    else if (serverBuildVersion !== BUILD_VERSION) {
      // If a user is authenticated, open an alert to warn about the coming refresh + reset
      if (displayVersionMismatchMessage) {
        // Version mismatches alerts use native browser alert because data may need to be refreshed
        // as soon as possible (going through react state may lead to an unexpected state).
        // eslint-disable-next-line no-alert
        window.alert(t('refitit:boot.minorVersionHasChangedAlert'));
      }
      // Remove local storage key that need to be remove when the javascript bundle is updated
      localStorageKeysToRemoveWhenAppIsUpdated.forEach((k) => keyValueStorage.removeItem(k));
      // Release persisted client version
      keyValueStorage.removeItem(CLIENT_BUILD_VERSION_KEY);
      // Reload the document (which will cause the javascript bundle to be upgraded)
      document.location.reload();
    } else {
      log.info('Client build version matches server build version', serverBuildVersion);
    }
  }
}
