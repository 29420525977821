import type { JSX } from 'react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { DropResult } from '@hello-pangea/dnd';
import type { CarElement, RepositoryEntityPayload } from '@stimcar/libs-base';
import type { ActionContext, NoArgActionCallback } from '@stimcar/libs-uikernel';
import type {
  AppProps,
  CheckFormConsistencyAction,
  CheckFormFieldContentActions,
} from '@stimcar/libs-uitoolkit';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { carElementHelpers, mergeArrayItems, sortingHelpers } from '@stimcar/libs-base';
import { applyPayload, computePayload, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useFormFieldWarning, useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type {
  AdminCarElementsState,
  SortCarElementDialogState,
  SortCarElementFormData,
} from './typings/store.js';
import {
  EMPTY_SORT_CAR_ELEMENT_DIALOG_STATE,
  EMPTY_SORT_CAR_ELEMENT_FORM,
} from './typings/store.js';

export function computeDuplicateIndexesArray(carElements: readonly CarElement[]): string[] {
  const duplicates: string[] = [];
  carElements
    .map((ce) => ce.index)
    .forEach((value, index, array) => {
      if (
        (array.indexOf(value) !== index || array.lastIndexOf(value) !== index) &&
        !duplicates.includes(value.toString())
      )
        duplicates.push(value.toString());
    });
  return duplicates;
}

export function handleCarElementsDragAndDropWithSort(
  carElements: readonly CarElement[],
  sourceIndex: number,
  destinationIndex: number
): CarElement[] {
  const mutableCarElementsArray = carElements.slice();

  // Remove Element from origin array
  const removedElement = mutableCarElementsArray.splice(sourceIndex, 1)[0];
  // Insert removed element in new position
  mutableCarElementsArray.splice(destinationIndex, 0, removedElement);

  const newCarElements = mutableCarElementsArray.map((ce, index): CarElement => {
    return { ...ce, index };
  });
  return newCarElements;
}

export async function openSortCarElementAction({
  actionDispatch,
  carElementRepository,
}: ActionContext<Store, AdminCarElementsState>): Promise<void> {
  const carElements = await carElementRepository.getAllEntities();

  const stateCarElements = carElements
    .slice()
    .sort(sortingHelpers.createSortByNumericField('DOWN', 'index'));

  const duplicateIndexes = computeDuplicateIndexesArray(stateCarElements);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionDispatch.scopeProperty('sortCarElementDialog').reduce((initial) => {
    return {
      ...EMPTY_SORT_CAR_ELEMENT_DIALOG_STATE,
      initialCarElements: carElements,
      carElements: stateCarElements,
      formData: {
        ...EMPTY_SORT_CAR_ELEMENT_FORM,
        duplicateIndexArray: duplicateIndexes,
      },
      active: true,
    };
  });
}

export function updateCarElementInSortDialogStateFromSSEAction(
  { getState, actionDispatch }: ActionContext<Store, SortCarElementDialogState>,
  addedOrUpdatedCarElements: CarElement[],
  removedCarElementIds: readonly string[]
): void {
  const { active, initialCarElements, carElements } = getState();
  if (active) {
    // Merge actual items with SSE updates
    const newInitialCarElements = mergeArrayItems(
      initialCarElements,
      addedOrUpdatedCarElements,
      removedCarElementIds
    );

    actionDispatch.setProperty('initialCarElements', newInitialCarElements);
    const payloads = computePayload(initialCarElements, carElements);
    const newCarElements = payloads
      ? applyPayload(newInitialCarElements, payloads)
      : newInitialCarElements;
    const sortedCarElements = newCarElements
      .slice()
      .sort(sortingHelpers.createSortByNumericField('DOWN', 'index'));

    const duplicateIndexes = computeDuplicateIndexesArray(sortedCarElements);
    actionDispatch.reduce((initial) => {
      return {
        ...initial,
        carElements: sortedCarElements,
        formData: {
          ...initial.formData,
          duplicateIndexArray: duplicateIndexes,
        },
      };
    });
  }
}

export async function saveSortAction(
  {
    carElementRepository,
    getState,
    actionDispatch,
  }: ActionContext<Store, SortCarElementDialogState>,
  sortTableItemsActionCallback: NoArgActionCallback<Store>
): Promise<void> {
  const { carElements, initialCarElements } = getState();

  const payloads = computePayload(initialCarElements, carElements);

  if (payloads) {
    const entityPayloads = payloads
      .filter((p) => isTruthyAndNotEmpty(p.id))
      .map((p): RepositoryEntityPayload<CarElement, never> => {
        return {
          // We are sure that the id is here. This error comes from a limitation in typing of payload computation methods
          // @ts-ignore
          entityId: p.id,
          payload: p,
        };
      });
    await carElementRepository.updateEntitiesFromPayloads(...entityPayloads);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionDispatch.reduce((initial) => {
    return {
      ...EMPTY_SORT_CAR_ELEMENT_DIALOG_STATE,
    };
  });
  await actionDispatch.execCallback(sortTableItemsActionCallback);
}

function onDragEndAction(
  { getState, actionDispatch }: ActionContext<Store, SortCarElementDialogState>,
  result: DropResult
): void {
  const { destination, source } = result;
  if (!destination) {
    return;
  }

  const { carElements } = getState();

  const newCarElements = handleCarElementsDragAndDropWithSort(
    carElements,
    source.index,
    destination.index
  );

  const duplicateIndexes = computeDuplicateIndexesArray(newCarElements);

  actionDispatch.reduce((initial) => {
    return {
      ...initial,
      carElements: newCarElements,
      formData: {
        ...initial.formData,
        duplicateIndexArray: duplicateIndexes,
      },
    };
  });
}

const mandatoryFields: (keyof SortCarElementFormData)[] = [];

const checkFieldContentActions: CheckFormFieldContentActions<Store, SortCarElementDialogState> = {
  duplicateIndexArray: ({ value, t }): string | undefined => {
    return value.length > 0 ? t(`sortCarElementsDialog.form.warnings.duplicateIndex`) : undefined;
  },
};

const checkFormConsistencyAction: CheckFormConsistencyAction<Store, SortCarElementDialogState> = ({
  formState,
  t,
}): string | undefined => {
  const { initialCarElements, carElements } = formState;
  const payloads = computePayload(initialCarElements, carElements);
  if (!payloads || payloads.length === 0) {
    return t('sortCarElementsDialog.form.warnings.noChange');
  }
  return undefined;
};

interface SortCarElementsDialogProps extends AppProps<Store> {
  readonly sortTableItemsActionCallback: NoArgActionCallback<Store>;
}

export function SortCarElementsDialog({
  $gs,
  sortTableItemsActionCallback,
}: SortCarElementsDialogProps): JSX.Element {
  const [t] = useTranslation('adminCarElements');
  const { $sortCarElementDialog } = $gs.$adminView.$adminCarElements;
  const height = useGetState($gs.$window.$height);

  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) =>
      await actionDispatch.exec(saveSortAction, sortTableItemsActionCallback),
    [sortTableItemsActionCallback],
    $sortCarElementDialog
  );

  const [onFormSubmit, genericOnFormChange, $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    SortCarElementDialogState
  >({
    $: $sortCarElementDialog,
    mandatoryFields,
    checkFieldContentActions,
    checkFormConsistencyAction,
    submitValidDataAction,
    t,
  });

  const onDragEndActionCallback = useActionCallback(
    async (
      { actionDispatch }: ActionContext<Store, SortCarElementDialogState>,
      result: DropResult
    ): Promise<void> => {
      await actionDispatch.exec(onDragEndAction, result);
      await actionDispatch.execCallback(genericOnFormChange);
    },
    [genericOnFormChange],
    $sortCarElementDialog
  );

  const duplicateIndexArray = useGetState($formDataWithChangeTrigger.$duplicateIndexArray);
  const duplicateIndexArrayWarning = useFormFieldWarning(
    $formDataWithChangeTrigger.$duplicateIndexArray
  );

  const computeRowStyle = useCallback(
    (ce: CarElement, isDragging: boolean): string => {
      if (isDragging) {
        return 'is-selected';
      }
      if (duplicateIndexArray.some((i) => i === ce.index.toString())) {
        return 'has-background-info';
      }
      return '';
    },
    [duplicateIndexArray]
  );

  const formWarning = useGetState($sortCarElementDialog.$formWarning);
  const carElements = useGetState($sortCarElementDialog.$carElements);

  return (
    <ModalCardDialog
      title={t('sortCarElementsDialog.title')}
      $active={$sortCarElementDialog.$active}
      onOkClicked={onFormSubmit}
      warning={formWarning}
      // React Beautiful DnD does not support nested scroll container yet https://github.com/atlassian/react-beautiful-dnd/issues/131
      // The css class used for modal body add a "overflow: auto" which can lead to a scrollable
      // element on top of the DnD part and block the DnD scroll on the following list
      // The following props add a "overflow: hidden" style to the modal body. Then the scroll must be handled in the child element
      preventModalBodyScrolling
    >
      <>
        <DragDropContext onDragEnd={onDragEndActionCallback}>
          <Droppable droppableId="droppable">
            {(provided): JSX.Element => (
              <div
                className="table-container m-b-xs"
                style={{
                  overflow: 'scroll',
                  // Height of the screen minus 250 to have space for modal header and footer
                  maxHeight: `${height - 250}px`,
                }}
              >
                <table
                  {...provided.droppableProps}
                  // Beautiful-react-dnd API returns an any
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  ref={(e): any => provided.innerRef(e)}
                  className="table is-narrow is-fullwidth"
                >
                  <tbody>
                    {carElements.map((ce, index): JSX.Element => {
                      return (
                        <Draggable key={ce.id} draggableId={ce.id} index={index}>
                          {(draggableProvided, snapshot): JSX.Element => (
                            <tr
                              className={computeRowStyle(ce, snapshot.isDragging)}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              // Beautiful-react-dnd API returns an any
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              ref={(e): any => draggableProvided.innerRef(e)}
                            >
                              <td>
                                {carElementHelpers.getCarElementIndexToDisplayWithPadding(ce)}
                              </td>
                              <td>{t(`globals:operationCategories.${ce.category}`)}</td>
                              <td>{ce.label}</td>
                            </tr>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </tbody>
                </table>
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {isTruthyAndNotEmpty(duplicateIndexArrayWarning) && (
          <p
            className="help has-text-centered is-primary"
            style={{ position: 'relative', top: '-10px' }}
          >
            {duplicateIndexArrayWarning}
          </p>
        )}
      </>
    </ModalCardDialog>
  );
}
