import type { JSX } from 'react';
import React from 'react';
import type { Operation, PackageDeal } from '@stimcar/libs-base';
import type { DeepPartial } from '@stimcar/libs-kernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { packageDealHelpers } from '@stimcar/libs-base';
import { isTruthy, keysOf } from '@stimcar/libs-kernel';
import {
  type ActionCallbackFromFunction,
  type ActionContext,
  type StoreStateSelector,
} from '@stimcar/libs-uikernel';
import type { Store } from '../../state/typings/store.js';
import type { SubcontractableInfo } from './SelectSubcontractorFirmModalDialog.js';
import type { AdditionalStepsForOperationCompletionModalState } from './typings/store.js';
import { SelectSubcontractorFirmModalDialog } from './SelectSubcontractorFirmModalDialog.js';
import {
  EMPTY_SELECT_SUBCONTRACTOR_FIRM_MODAL_STATE,
  EMPTY_UPLOAD_DOCUMENT_FOR_COMPLETION_MODAL_STATE,
} from './typings/store.js';
import { UploadDocumentForCompletionModalDialog } from './UploadDocumentForOperationCompletionModalDialog.js';

export type ApplyToggleOperationsPayloadAction<
  A extends AdditionalStepsForOperationCompletionModalState,
> = (
  ctx: ActionContext<Store, A>,
  kanbanId: string,
  updatedPackageDeals: readonly DeepPartial<PackageDeal>[]
) => Promise<void> | void;

function getPackageDealWithOperationToggled(
  packageDeal: PackageDeal,
  operationId: string,
  login: string | null,
  subcontractor: string | null,
  selectedFiles?: readonly string[]
): DeepPartial<PackageDeal> {
  const operation = packageDeal.operations.find(({ id }) => id === operationId)!;
  const { documentsInfo } = operation;
  const isOperationCompleted = isTruthy(operation.completionDate);

  let updatedOperation: DeepPartial<Operation> = operation;

  if (
    operation.type === 'UploadDocument' &&
    isOperationCompleted &&
    isTruthy(selectedFiles) &&
    selectedFiles.length > 0
  ) {
    updatedOperation = {
      ...operation,
      documentsInfo: { ...operation.documentsInfo, documentsFileNames: selectedFiles },
    };
  } else {
    updatedOperation = {
      id: operationId,
      user: isOperationCompleted ? null : login,
      completionDate: isOperationCompleted ? null : Date.now(),
      // Only append the subcontractor if it is set or if the key is already
      // present in the object
      ...(typeof subcontractor === 'string' || keysOf(operation).includes('subcontractor')
        ? { subcontractor }
        : {}),
      documentsInfo:
        operation.type === 'UploadDocument'
          ? { ...documentsInfo, documentsFileNames: selectedFiles }
          : undefined,
    };
  }

  const updatedPackageDeal: DeepPartial<PackageDeal> = {
    id: packageDeal.id,
    operations: [updatedOperation],
    status: packageDeal.status,
  };
  return updatedPackageDeal;
}

export async function toggleOperationAction<
  A extends AdditionalStepsForOperationCompletionModalState,
>(
  { actionDispatch, getState, getGlobalState }: ActionContext<Store, A>,
  kanbanId: string,
  packageDeals: readonly PackageDeal[],
  operationId: string,
  applyToggleOperationPayloadAction: ApplyToggleOperationsPayloadAction<A>,
  subcontractableInfo?: SubcontractableInfo,
  selectedFiles?: readonly string[]
) {
  const flatOperations = packageDealHelpers.getFlatOperationList(packageDeals, 'achievable', true);
  const operation = flatOperations.find(({ id }) => id === operationId)!;
  const packageDeal = packageDeals.find(({ id }) => id === operation.packageDealId)!;

  if (!isTruthy(selectedFiles) && operation.type === 'UploadDocument' && operation.documentsInfo) {
    actionDispatch.scopeProperty('uploadDocumentForCompletionModal').setValue({
      ...EMPTY_UPLOAD_DOCUMENT_FOR_COMPLETION_MODAL_STATE,
      kanbanId,
      operation,
      active: true,
      documentsInfo: operation.documentsInfo,
    });
  } else if (
    !isTruthy(subcontractableInfo) &&
    !isTruthy(operation.completionDate) &&
    packageDeal.isSubcontractable
  ) {
    const { documentsInfo } = getState().uploadDocumentForCompletionModal;
    const { subcontractors } = getGlobalState().siteConfiguration;
    actionDispatch.scopeProperty('selectSubcontractorFirmModal').setValue({
      ...EMPTY_SELECT_SUBCONTRACTOR_FIRM_MODAL_STATE,
      kanbanId,
      subcontractors,
      operationId,
      active: true,
      documentsInfo,
    });
  } else {
    const { login } = getGlobalState().session.user!;
    const packageDealWithOperationToggled = getPackageDealWithOperationToggled(
      packageDeal,
      operationId,
      login,
      subcontractableInfo && subcontractableInfo.isSubcontracted
        ? subcontractableInfo.subcontractor
        : null,
      selectedFiles
    );

    await actionDispatch.exec(applyToggleOperationPayloadAction, kanbanId, [
      packageDealWithOperationToggled,
    ]);
  }
}

export type ToggleOperationActionCallback = ActionCallbackFromFunction<
  Store,
  (
    operationId: string,
    subcontractorInfos?: SubcontractableInfo,
    selectedFiles?: readonly string[]
  ) => Promise<void>
>;

interface AdditionalStepsForOperationCompletionModalProps<
  A extends AdditionalStepsForOperationCompletionModalState,
> extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, A>;
  readonly toggleOperationActionCallback: ToggleOperationActionCallback;
}

export function AdditionalStepsForOperationCompletionModal<
  A extends AdditionalStepsForOperationCompletionModalState,
>({
  $,
  $gs,
  toggleOperationActionCallback,
}: AdditionalStepsForOperationCompletionModalProps<A>): JSX.Element {
  return (
    <>
      <SelectSubcontractorFirmModalDialog
        $={$.$selectSubcontractorFirmModal}
        toggleOperationActionCallback={toggleOperationActionCallback}
      />
      <UploadDocumentForCompletionModalDialog
        $gs={$gs}
        $={$.$uploadDocumentForCompletionModal}
        toggleOperationActionCallback={toggleOperationActionCallback}
      />
    </>
  );
}
