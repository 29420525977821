import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { KanbanRepository, RepositoryHTTPClient } from '@stimcar/core-libs-repository';
import type {
  AuthenticatedUser,
  FlatOperationItem,
  Kanban,
  Operation,
  PackageDeal,
} from '@stimcar/libs-base';
import type { DeepPartial } from '@stimcar/libs-kernel';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps, ButtonProps, CheckFormConsistencyAction } from '@stimcar/libs-uitoolkit';
import { AvailablePermissionPaths, kanbanHelpers, packageDealHelpers } from '@stimcar/libs-base';
import { nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  Button,
  HORIZONTAL_FORM_SMALL_LABEL,
  InputFormField,
  ModalCardDialog,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type {
  ActionOnKanbanWithReasonData,
  RevokeKanbanModalState,
} from '../details/typings/store.js';
import type { Store } from '../state/typings/store.js';
import { Checkbox } from '../../lib/bulma/form/Checkbox.js';
import { useHasModifyPermission } from '../../registeredapp/permissionHooks.js';
import { EMPTY_REVOKE_KANBAN_MODAL_STATE } from '../details/typings/store.js';

type RevokeKanbanProps = Pick<ButtonProps, 'isFullWidth' | 'size' | 'additionalClass'> & {
  readonly kanban: Kanban;
  readonly $: StoreStateSelector<Store, RevokeKanbanModalState>;
} & AppProps<Store>;

/**
 * Open the revode kanban dialog so the user can give the reason
 */
// eslint-disable-next-line @typescript-eslint/require-await
async function openRevokeKanbanDialogAction({
  actionDispatch,
}: ActionContext<Store, RevokeKanbanModalState>): Promise<void> {
  actionDispatch.applyPayload({
    ...EMPTY_REVOKE_KANBAN_MODAL_STATE,
    reasonDialog: {
      active: true,
    },
  });
}

/**
 * Open the revoke kanban confirmation dialog. The user can confirm or cancel with this dialog
 */
// eslint-disable-next-line @typescript-eslint/require-await
async function openRevokeKanbanConfirmDialogAction({
  actionDispatch,
}: ActionContext<Store, RevokeKanbanModalState>): Promise<void> {
  actionDispatch.applyPayload({
    reasonDialog: {
      active: false,
    },
    confirmDialog: {
      active: true,
    },
  });
}

/**
 * Close all the opened dialog actions (form or confirmation dialogs)
 */
// eslint-disable-next-line @typescript-eslint/require-await
async function closeAllDialogsAction({
  actionDispatch,
}: ActionContext<Store, RevokeKanbanModalState>): Promise<void> {
  actionDispatch.applyPayload(EMPTY_REVOKE_KANBAN_MODAL_STATE);
}

/**
 * Return the finished operations for a kanban
 * @param kanban Specified kanban
 * @returns array of finished operations on kanban
 */
function getFinishedOperations(kanban: Kanban): FlatOperationItem[] {
  return packageDealHelpers
    .getFlatOperationList(kanban.packageDeals, 'achievable')
    .filter((k) => k.completionDate);
}

/**
 * Revoke the provided kanban
 * @param kanban Kanban to be revoked
 * @param reason Reason for revocation
 * @param httpClient HttpClient used to get infos on connected user
 * @param kanbanRepository
 * @param t
 */
async function revokeKanban(
  kanban: Kanban,
  reason: string,
  force: boolean,
  httpClient: RepositoryHTTPClient,
  { firstName, lastName, login }: AuthenticatedUser,
  kanbanRepository: KanbanRepository,
  t: TFunction
): Promise<boolean> {
  if (!force) {
    const finishedOperations = getFinishedOperations(kanban);
    if (finishedOperations.length > 0) {
      const errorMsg = t('actions.revoke.finishedOperations', { license: kanban.infos.license });
      throw Error(errorMsg);
    }
  }
  await kanbanRepository.updateEntityFromPayload({
    entityId: kanban.id,
    payload: {
      packageDeals: kanban.packageDeals.map((pkgd): DeepPartial<PackageDeal> => {
        return {
          id: pkgd.id,
          status: 'canceled',
          price: 0,
          deleted: true,
          operations: pkgd.operations.map((o): DeepPartial<Operation> => {
            return {
              id: o.id,
              completionDate: null,
              user: null,
              deleted: true,
            };
          }),
        };
      }),
      messages: [
        ...kanban.messages,
        kanbanHelpers.createKanbanComment(
          httpClient.getBrowserSequence().next(),
          login,
          t('actions.revoke.confirmationLog', { firstName, lastName, reason })
        ),
      ],
    },
  });
  await kanbanRepository.archiveEntity(kanban.id);
  return true;
}

/**
 * Action to revoke a kanban
 */
async function revokeKanbanAction(
  {
    getState,
    actionDispatch,
    httpClient,
    kanbanRepository,
    globalActionDispatch,
    getGlobalState,
  }: ActionContext<Store, RevokeKanbanModalState>,
  kanban: Kanban,
  forceIsRequired: boolean,
  t: TFunction
): Promise<void> {
  const { reason, force } = getState().formData;

  // check if user has forced the revocation when required
  if (forceIsRequired && !force) {
    const errorMessage = t('actions.revoke.forceIsRequired');
    globalActionDispatch.setProperty('message', errorMessage);
    return;
  }

  // Execute revoke
  const success = await revokeKanban(
    kanban,
    reason,
    force,
    httpClient,
    nonnull(getGlobalState().session.user),
    kanbanRepository,
    t
  );

  // Close opened dialogs
  await actionDispatch.exec(closeAllDialogsAction);
  if (success) {
    // Display confirmation message
    const { license } = kanban.infos;
    const successMessage = t('actions.revoke.confirmationMessage', { license });
    globalActionDispatch.setProperty('message', successMessage);
  }
}

const mandatoryFields: (keyof ActionOnKanbanWithReasonData)[] = ['reason'];

const checkFormConsistencyAction: CheckFormConsistencyAction<
  Store,
  RevokeKanbanModalState,
  [boolean]
> = ({ formState, t }, forceIsRequired): string | undefined => {
  const { force } = formState.formData;
  // If revocation is required we can not submit the form until the checkbox is checked
  if (forceIsRequired && !force) {
    return t('actions.revoke.forceIsRequired');
  }
  return undefined;
};

export function RevokeKanbanButton({ kanban, $, $gs, ...props }: RevokeKanbanProps): JSX.Element {
  const [t] = useTranslation('details');
  const { license } = kanban.infos;

  const openRevokeDialogHandler = useActionCallback(openRevokeKanbanDialogAction, [], $);

  // Revoke button is disabled if at least one operation is marked as finished
  // or if kanban is not opened
  const canForceRevocation = useHasModifyPermission(
    $gs,
    AvailablePermissionPaths.CAN_FORCE_KANBAN_REVOCATION
  );

  const canForceMarketplaceRevocation = useHasModifyPermission(
    $gs,
    AvailablePermissionPaths.CAN_FORCE_MARKETPLACE_KANBAN_REVOCATION
  );

  const isAllowedToForce =
    canForceRevocation ||
    (canForceMarketplaceRevocation && kanbanHelpers.isMarketplaceKanban(kanban));

  const forceIsRequired = getFinishedOperations(kanban).length > 0 && isAllowedToForce;

  let revokeButtonDisabled = false;
  let revokeButtonTooltip: string | undefined;
  if (kanban.status !== 'open') {
    revokeButtonDisabled = true;
    revokeButtonTooltip = t('actions.revoke.notOpenedKanban', { license });
  } else if (!isAllowedToForce && getFinishedOperations(kanban).length > 0) {
    revokeButtonDisabled = true;
    revokeButtonTooltip = t('actions.revoke.finishedOperations', { license });
  }

  const submitRevokeReasonAction = useActionCallback(openRevokeKanbanConfirmDialogAction, [], $);
  const revokeKanbanActionHandler = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(revokeKanbanAction, kanban, forceIsRequired, t);
    },
    [kanban, forceIsRequired, t],
    $
  );

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    RevokeKanbanModalState,
    [boolean]
  >(
    {
      $,
      mandatoryFields,
      checkFormConsistencyAction,
      submitValidDataAction: submitRevokeReasonAction,
      t,
    },
    forceIsRequired
  );

  return (
    <>
      <Button
        iconId="trash"
        label={t('actions.revoke.buttonLabel')}
        onClick={openRevokeDialogHandler}
        disabled={revokeButtonDisabled}
        tooltip={revokeButtonTooltip}
        {...props}
      />
      <ModalCardDialog
        titleIconId="trash"
        title={t('actions.revoke.dialogTitle')}
        $active={$.$reasonDialog.$active}
        okLabel={t('actions.revoke.buttonLabel')}
        onOkClicked={onFormSubmit}
        warning={useGetState($.$formWarning)}
      >
        {t('actions.revoke.dialogMessage', { license })}
        <div className="m-t-md">
          <InputFormField
            type="text"
            label={t('actions.revoke.reason')}
            placeholder={t('actions.revoke.reason')}
            $={$formDataWithChangeTrigger.$reason}
            horizontal={HORIZONTAL_FORM_SMALL_LABEL}
          />
          {forceIsRequired && (
            <Checkbox text={t('actions.revoke.force')} $={$formDataWithChangeTrigger.$force} />
          )}
        </div>
      </ModalCardDialog>
      <ModalCardDialog
        titleIconId="circle-question"
        title={t('actions.revoke.dialogTitle')}
        $active={$.$confirmDialog.$active}
        onOkClicked={revokeKanbanActionHandler}
      >
        {t('actions.revoke.confirmationQuestion', { license })}
      </ModalCardDialog>
    </>
  );
}
