/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import type { GroupBase, SingleValue } from 'react-select';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import './ReactSelect.scss';

export interface ReactSelectOption<T extends string | number> {
  readonly value: T;
  readonly label: string;
}

export type SimpleOption<T extends string | number = string> = ReactSelectOption<T>;

export type RichOption<T extends string | number = string> = SimpleOption<T> & {
  readonly icon: string;
  readonly color: string;
};
export interface CreationToolkit {
  readonly validationFunction?: (value: string) => string | undefined;
  readonly formatValidLabel?: (inputValue: string) => string;
}

export type CustomStylesType = Record<string, React.CSSProperties>;

function mergeOptions(options: CustomStylesType, newOptions: CustomStylesType) {
  const result = options;
  // eslint-disable-next-line no-restricted-syntax
  for (const key in newOptions) {
    if (Object.prototype.hasOwnProperty.call(newOptions, key)) {
      const existingValues = options[key];
      const newValues = newOptions[key];
      result[key] = { ...existingValues, ...newValues };
    }
  }
  return result;
}
const DEFAULT_CUSTOM_STYLES = {
  menuPortal: {
    zIndex: 9999,
  },
  control: {
    minHeight: '0px',
  },
  valueContainer: {
    padding: '0px 0px',
  },
  dropdownIndicator: {
    padding: '0px',
  },
  clearIndicator: {
    padding: '0px',
  },
  indicatorSeparator: {
    marginTop: '0px',
    marginBottom: '0px',
  },
};

export function getCustomStyles(specifiedCustomStyles: CustomStylesType) {
  const customStylesOptions = mergeOptions(DEFAULT_CUSTOM_STYLES, specifiedCustomStyles);

  const customStyles: Record<string, (provided: any) => any> = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key in customStylesOptions) {
    if (Object.prototype.hasOwnProperty.call(customStylesOptions, key)) {
      const customValues = customStylesOptions[key];
      customStyles[key] = (provided: any) => ({
        ...provided,
        ...customValues,
      });
    }
  }
  return customStyles;
}

export type BaseReactSelectProps<T extends string | number> = {
  readonly suggestions?: readonly (
    | GroupBase<ReactSelectOption<T> | T>
    | ReactSelectOption<T>
    | T
  )[];
  readonly creation?: CreationToolkit | boolean;
  readonly isClearable?: boolean;
  readonly formatOptionLabel?: (option: ReactSelectOption<T>) => React.ReactNode;
  readonly placeholder?: string;
  readonly hideDropdownIndicator?: boolean;
  readonly isDisabled?: boolean;
  readonly customStylesOptions?: Record<string, Record<string, string | number>>;
};

export type ReactSelectProps<
  SD extends AnyStoreDef,
  T extends string | number,
> = BaseReactSelectProps<T> & {
  readonly $: StoreStateSelector<SD, T>;
};

export const inputCreationValidationFunction = (
  creationToolkit: CreationToolkit | undefined,
  inputValue: string
): boolean => {
  if (!isTruthyAndNotEmpty(inputValue)) {
    return false;
  }
  if (isTruthy(creationToolkit?.validationFunction)) {
    const validationFunction = nonnull(creationToolkit?.validationFunction);
    return validationFunction(inputValue) === undefined;
  }
  return true;
};

export const noOptionMessageFunction = (
  t: TFunction,
  creationToolkit: CreationToolkit | undefined,
  inputValue: string
): string => {
  if (isTruthy(creationToolkit?.validationFunction)) {
    const validationFunction = nonnull(creationToolkit?.validationFunction);
    return validationFunction(inputValue) ?? t('reactSelect.noOptionsLabel');
  }
  return t('reactSelect.noOptionsLabel');
};

const convertToReactSelectOption = <T extends string | number>(value: T): ReactSelectOption<T> => ({
  value,
  label: typeof value === 'number' ? String(value) : value,
});

export const ensureNoPrimitivesLeftInOptions = <T extends string | number>(
  suggestions: readonly (T | ReactSelectOption<T> | GroupBase<ReactSelectOption<T> | T>)[]
): readonly (ReactSelectOption<T> | GroupBase<ReactSelectOption<T>>)[] => {
  return suggestions.map((suggestion): GroupBase<ReactSelectOption<T>> | ReactSelectOption<T> => {
    if (typeof suggestion === 'object') {
      // GroupBase<ReactSelectOption<T>> case
      if (Object.keys(suggestion).includes('options')) {
        const groupBase: GroupBase<ReactSelectOption<T>> = {
          label: suggestion.label,
          options: (suggestion as GroupBase<ReactSelectOption<T>>).options.map((option) =>
            typeof option === 'object' ? option : convertToReactSelectOption(option)
          ),
        };
        return groupBase;
      }
      //  ReactSelectOption<T> case
      return suggestion as ReactSelectOption<T>;
    }
    // string | number case
    return convertToReactSelectOption(suggestion);
  });
};

export function flattenOptionsAndGroupedOptions<T extends string | number>(
  structuredSuggestions: readonly (ReactSelectOption<T> | GroupBase<ReactSelectOption<T>>)[]
) {
  return structuredSuggestions.reduce<ReactSelectOption<T>[]>(
    (p, c) => [
      ...p,
      ...(Object.keys(c).includes('options')
        ? (c as GroupBase<ReactSelectOption<T>>).options
        : [c as ReactSelectOption<T>]),
    ],
    []
  );
}

export function ReactSelect<SD extends AnyStoreDef, T extends string | number>({
  $,
  suggestions,
  isClearable = false,
  placeholder,
  formatOptionLabel,
  creation = false,
  hideDropdownIndicator = false,
  isDisabled = false,
  customStylesOptions = {},
}: ReactSelectProps<SD, T>): JSX.Element {
  const [t] = useTranslation('bulma');
  const value = useGetState($);

  const creationToolkit = useMemo(() => {
    if (typeof creation === 'boolean') {
      if (creation) {
        return {};
      }
      return undefined;
    }
    return creation;
  }, [creation]);

  const structuredSuggestions = useMemo(
    () => ensureNoPrimitivesLeftInOptions(suggestions ?? []),
    [suggestions]
  );

  const dispatchedOption: ReactSelectOption<T> | undefined = useMemo(() => {
    return (
      // Turn options to a flat list
      flattenOptionsAndGroupedOptions(structuredSuggestions).find(
        (option) => option.value === value
      ) ?? {
        value,
        label: value === null || value === undefined ? '' : String(value),
      }
    );
  }, [value, structuredSuggestions]);

  const handleChangeActionCallback = useActionCallback(
    ({ actionDispatch }, newValue: SingleValue<ReactSelectOption<T>>) => {
      if (isTruthy(newValue)) {
        actionDispatch.setValue(newValue.value);
      }
      // this will happen by click on the clear button (newValue will be null)
      else {
        actionDispatch.setValue('' as T);
      }
    },
    [],
    $
  );

  const formatCreateLabelFunction = useCallback(
    (inputValue: string): string => {
      const toolkit = nonnull(creationToolkit);
      return toolkit.formatValidLabel
        ? toolkit.formatValidLabel(inputValue)
        : t('reactSelect.createLabel', { value: inputValue });
    },
    [creationToolkit, t]
  );

  const customStyles = getCustomStyles(customStylesOptions);
  const placeHolder = isTruthyAndNotEmpty(placeholder) ? placeholder : t('reactSelect.placeholder');

  const inputCreationValidationFunctionCallback = (inputValue: string): boolean => {
    return inputCreationValidationFunction(creationToolkit, inputValue);
  };

  const noOptionMessageFunctionCallback = ({ inputValue }: { inputValue: string }): string => {
    return noOptionMessageFunction(t, creationToolkit, inputValue);
  };

  return (
    <div className="control">
      {creationToolkit ? (
        <CreatableSelect
          value={dispatchedOption}
          options={structuredSuggestions}
          onChange={handleChangeActionCallback}
          isClearable={isClearable}
          formatCreateLabel={formatCreateLabelFunction}
          formatOptionLabel={formatOptionLabel}
          noOptionsMessage={noOptionMessageFunctionCallback}
          isValidNewOption={inputCreationValidationFunctionCallback}
          placeholder={placeHolder}
          className="refitit-select"
          classNamePrefix="refitit-select"
          // these two last props set the options list's displaying on foreground with fixed position
          menuPortalTarget={document.body}
          styles={customStyles}
          components={
            hideDropdownIndicator
              ? { DropdownIndicator: () => null, IndicatorSeparator: () => null }
              : undefined
          }
          isDisabled={isDisabled}
        />
      ) : (
        <Select
          value={dispatchedOption}
          options={structuredSuggestions}
          onChange={handleChangeActionCallback}
          noOptionsMessage={noOptionMessageFunctionCallback}
          isClearable={isClearable}
          formatOptionLabel={formatOptionLabel}
          placeholder={placeHolder}
          className="refitit-select"
          classNamePrefix="refitit-select"
          // these two last props set the options list's displaying on foreground with fixed position
          menuPortalTarget={document.body}
          styles={customStyles}
          components={
            hideDropdownIndicator
              ? { DropdownIndicator: () => null, IndicatorSeparator: () => null }
              : undefined
          }
          isDisabled={isDisabled}
        />
      )}
    </div>
  );
}
