import type {
  CoreFields,
  Kanban,
  KanbanColorationCharter,
  KanbanMessage,
  KanbanSummary,
} from '@stimcar/libs-base';
import { kanbanHelpers, KanbanPriorityLevel } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';

function getCssIdFromKanbanPriorityLevel(kanbanPriorityLevel: KanbanPriorityLevel): string {
  if (kanbanPriorityLevel !== KanbanPriorityLevel.none) {
    return `has-background-${kanbanPriorityLevel.valueOf()}`;
  }
  return '';
}

function getCssIdForPriorityLevelFromKanban<K extends CoreFields<Kanban>>(
  kanbanOrKanbanSummary: K | KanbanSummary,
  kanbanColorationCharter: KanbanColorationCharter | undefined
): string {
  const priorityLevel = kanbanHelpers.computePriorityLevel(
    kanbanOrKanbanSummary,
    kanbanColorationCharter
  );
  return getCssIdFromKanbanPriorityLevel(priorityLevel);
}

function getKanbanMessageColorClassName(
  message: KanbanMessage,
  coloredElement: 'text' | 'background' = 'background'
): string {
  let classname = '';
  if (isTruthy(message) && message.type === 'request') {
    switch (message.status) {
      case 'pending':
        classname = `has-${coloredElement}-warning`;
        break;
      case 'accepted':
        classname = `has-${coloredElement}-success`;
        break;
      case 'rejected':
        classname = `has-${coloredElement}-danger`;
        break;
      default:
        break;
    }
  }
  return classname;
}

export const kanbanPriorityLevelHelpers = {
  getCssIdFromKanbanPriorityLevel,
  getCssIdForPriorityLevelFromKanban,
  getKanbanMessageColorClassName,
};
