import type { PackageDealDesc } from '@stimcar/libs-base';
import { EMPTY_SITE_CONFIGURATION } from '@stimcar/libs-base';

export type AdminWorkflowState = {
  readonly validationErrors: readonly string[];
  readonly validationWarnings: readonly string[];
  readonly packageDealDescsPerDatabase: Record<string, PackageDealDesc[]>;
  readonly displayedWorkflow: string;
  readonly rawSiteConfiguration: string;
  readonly originRawSiteConfiguration: string;
};

export const EMPTY_ADMIN_WORKFLOW_STATE: AdminWorkflowState = {
  validationErrors: [],
  validationWarnings: [],
  packageDealDescsPerDatabase: {},
  displayedWorkflow: '',
  rawSiteConfiguration: JSON.stringify(EMPTY_SITE_CONFIGURATION, null, 2),
  originRawSiteConfiguration: JSON.stringify(EMPTY_SITE_CONFIGURATION, null, 2),
};
