import type { Operation, OperationDocumentsInfo } from '@stimcar/libs-base';
import type { FormWithValidationState } from '@stimcar/libs-uitoolkit';
import { EMPTY_OPERATION } from '@stimcar/libs-base';
import type { AttachmentsTabState } from '../../../../lib/components/attachments/typings/store.js';
import { ATTACHMENTS_TAB_EMPTY_STATE } from '../../../../lib/components/attachments/typings/store.js';

export type SelectSubcontractorFirmForm = {
  readonly realizedBy: 'stimcar' | 'subcontractor';
  readonly subcontractor: string;
};

export type SelectSubcontractorFirmModalState =
  FormWithValidationState<SelectSubcontractorFirmForm> & {
    readonly active: boolean;
    readonly kanbanId?: string;
    readonly operationId: string;
    readonly subcontractors: readonly string[];
    readonly documentsInfo?: OperationDocumentsInfo;
  };

export const EMPTY_SELECT_SUBCONTRACTOR_FIRM_MODAL_STATE: SelectSubcontractorFirmModalState = {
  active: false,
  subcontractors: [],
  operationId: '',
  formData: {
    realizedBy: 'subcontractor',
    subcontractor: '',
    warnings: {},
  },
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formWarning: undefined,
};

export type UploadDocumentForCompletionModalState = {
  readonly active: boolean;
  readonly kanbanId: string;
  readonly warning?: string;
  readonly operation: Operation;
  readonly documentsInfo: OperationDocumentsInfo;
  readonly attachmentGallery: AttachmentsTabState;
};

export const EMPTY_OPERATION_DOCUMENT_INFOS = {
  targetFolder: '',
  documentLabel: '',
  documentsFileNames: [],
};

export const EMPTY_UPLOAD_DOCUMENT_FOR_COMPLETION_MODAL_STATE: UploadDocumentForCompletionModalState =
  {
    kanbanId: '',
    active: false,
    operation: EMPTY_OPERATION,
    attachmentGallery: ATTACHMENTS_TAB_EMPTY_STATE,
    documentsInfo: EMPTY_OPERATION_DOCUMENT_INFOS,
  };

export type AdditionalStepsForOperationCompletionModalState = {
  readonly selectSubcontractorFirmModal: SelectSubcontractorFirmModalState;
  readonly uploadDocumentForCompletionModal: UploadDocumentForCompletionModalState;
};
