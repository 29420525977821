import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { kanbanHelpers } from '@stimcar/libs-base';
import { isTruthy, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../app/state/typings/store.js';
import type { PostEjectionModalState } from '../typings/store.js';
import { POST_EJECTION_MODAL_EMPTY_STATE } from '../typings/store.js';

interface Props {
  readonly $: StoreStateSelector<Store, PostEjectionModalState>;
}

export function KanbanEjectionModal({ $ }: Props): JSX.Element {
  const [t] = useTranslation('libComponents');

  const cancelEjectionCallback = useActionCallback(
    ({ actionDispatch }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      actionDispatch.reduce((initial) => {
        return POST_EJECTION_MODAL_EMPTY_STATE;
      });
    },
    [],
    $
  );

  const ejectCallback = useActionCallback(
    async ({
      actionDispatch,
      kanbanRepository,
      getState,
    }: ActionContext<Store, PostEjectionModalState>) => {
      const { qualifiedPostId, kanban } = getState();
      if (isTruthy(qualifiedPostId)) {
        const theKanban = kanbanHelpers.closeCurrentHandling(nonnull(kanban), qualifiedPostId);
        await kanbanRepository.updateEntity(theKanban);
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      actionDispatch.reduce((initial) => {
        return POST_EJECTION_MODAL_EMPTY_STATE;
      });
    },
    [],
    $
  );

  return (
    <ModalCardDialog
      title={t('workshopKanbanEjectionModal.title')}
      $active={$.$isActive}
      onOkClicked={ejectCallback}
      onCancelClicked={cancelEjectionCallback}
      okLabel={t('workshopKanbanEjectionModal.okLabel')}
    >
      {t('workshopKanbanEjectionModal.content')}
    </ModalCardDialog>
  );
}
