import type { FormWithValidationState } from '@stimcar/libs-uitoolkit';

export type AdminShiftParametersConfigurationFormState = {
  readonly workshopStartTime: string;
  readonly workshopShift1CollaboratorsCount: string;
  readonly workshopShift2CollaboratorsCount: string;
  readonly workshopShift3CollaboratorsCount: string;
  readonly workshopExpectedWorkloadByCollaborator: string;
  readonly workshopWorkloadFactor: string;
  readonly expertiseStartTime: string;
  readonly expertiseShift1CollaboratorsCount: string;
  readonly expertiseShift2CollaboratorsCount: string;
  readonly expertiseShift3CollaboratorsCount: string;
  readonly expertiseExpectedWorkloadByCollaborator: string;
  readonly expertiseWorkloadFactor: string;
};

export type AdminShiftParametersConfigurationDialogState =
  FormWithValidationState<AdminShiftParametersConfigurationFormState> & {
    readonly active: boolean;
  };

export const EMPTY_ADMIN_SHIFT_PARAMETERS_CONFIGURATION_DIALOG_STATE: AdminShiftParametersConfigurationDialogState =
  {
    active: false,
    formData: {
      workshopExpectedWorkloadByCollaborator: '0',
      workshopShift1CollaboratorsCount: '0',
      workshopShift2CollaboratorsCount: '0',
      workshopShift3CollaboratorsCount: '0',
      workshopStartTime: '',
      workshopWorkloadFactor: '1',
      expertiseExpectedWorkloadByCollaborator: '0',
      expertiseShift1CollaboratorsCount: '0',
      expertiseShift2CollaboratorsCount: '0',
      expertiseShift3CollaboratorsCount: '0',
      expertiseStartTime: '',
      expertiseWorkloadFactor: '1',
      warnings: {},
    },
    formSubmitClickedOnce: false,
    formSubmitted: false,
  };
