import type { Kanban, PackageDeal, PurchaseOrder } from '@stimcar/libs-base';
import type { DeepPartial } from '@stimcar/libs-kernel';
import { groupBy, nonDeleted } from '@stimcar/libs-base';
import {
  applyPayload,
  computePayload,
  isTruthy,
  isTruthyAndNotEmpty,
  nonnull,
} from '@stimcar/libs-kernel';

export type ForbiddenPurchaseOrderChange = {
  kanbanId: string;
  kanbanLicence: string;
  purchaseOrderId: string;
  reason: string;
};

export type PurchaseOrdersChanges = {
  minimalPayload: readonly DeepPartial<PurchaseOrder>[];
  creations: readonly PurchaseOrder[];
  updates: readonly PurchaseOrder[];
  deletions: readonly PurchaseOrder[];
  forbiddenChanges: readonly ForbiddenPurchaseOrderChange[];
};

function getPurchaseOrderIdsWithManualPkgDealAllocation(
  packageDeals: readonly PackageDeal[],
  pkgDealForPurchaseOrderCodes: readonly string[]
): readonly string[] {
  const purchaseOrderIds = packageDeals
    .filter(({ purchaseOrderId }) => isTruthyAndNotEmpty(purchaseOrderId))
    .filter((packageDeal) => !pkgDealForPurchaseOrderCodes.includes(packageDeal.code))
    .map(({ purchaseOrderId }) => nonnull(purchaseOrderId));
  return [...new Set(purchaseOrderIds)];
}

function getPurchaseOrderIdsWithCompletedOperations(
  packageDeals: readonly PackageDeal[]
): readonly string[] {
  const purchaseOrderIds = packageDeals
    .filter(({ purchaseOrderId }) => isTruthyAndNotEmpty(purchaseOrderId))
    .filter((packageDeal) => packageDeal.operations.some((op) => op.completionDate !== null))
    .map(({ purchaseOrderId }) => nonnull(purchaseOrderId));
  return [...new Set(purchaseOrderIds)];
}

function computePurchaseOrderToCreate(
  newPurchaseOrders: readonly PurchaseOrder[],
  originalPurchaseOrderIds: readonly string[]
): readonly PurchaseOrder[] {
  return newPurchaseOrders.filter(
    (purchaseOrder) => !originalPurchaseOrderIds.includes(purchaseOrder.id)
  );
}

function computePurchaseOrderToUpdate(
  newPurchaseOrders: readonly PurchaseOrder[],
  originalPurchaseOrdersById: Record<string, readonly PurchaseOrder[]>
): readonly PurchaseOrder[] {
  return newPurchaseOrders
    .filter(({ id }) => isTruthy(originalPurchaseOrdersById[id]))
    .filter((newPurchaseOrder) => {
      const originalPurchaseOrder = originalPurchaseOrdersById[newPurchaseOrder.id][0];
      const hasLabelChange = originalPurchaseOrder.label !== newPurchaseOrder.label;
      const isReactivated = originalPurchaseOrder.deleted && !newPurchaseOrder.deleted;
      return hasLabelChange || isReactivated;
    })
    .map((newPurchaseOrder): PurchaseOrder => {
      const originalPurchaseOrder = originalPurchaseOrdersById[newPurchaseOrder.id][0];
      if (originalPurchaseOrder.deleted) {
        return {
          ...originalPurchaseOrder,
          purchaseNumber: newPurchaseOrder.purchaseNumber,
          label: newPurchaseOrder.label,
          deleted: false,
        };
      }
      return {
        ...originalPurchaseOrder,
        purchaseNumber: newPurchaseOrder.purchaseNumber,
        label: newPurchaseOrder.label,
      };
    });
}

function computePurchaseOrderToDelete(
  newPurchaseOrders: readonly PurchaseOrder[],
  originalPurchaseOrders: readonly PurchaseOrder[],
  purchaseOrderIdsWithManualPkgDealAllocation: readonly string[],
  purchaseOrderIdsWithCompletedOperations: readonly string[]
): readonly PurchaseOrder[] {
  const newPurchaseOrderIds = newPurchaseOrders.filter(nonDeleted).map(({ id }) => id);
  return originalPurchaseOrders
    .filter(({ id }) => !newPurchaseOrderIds.includes(id))
    .filter(
      ({ id }) =>
        !purchaseOrderIdsWithManualPkgDealAllocation.includes(id) &&
        !purchaseOrderIdsWithCompletedOperations.includes(id)
    )
    .map((purchaseOrder): PurchaseOrder => {
      return {
        ...purchaseOrder,
        deleted: true,
      };
    });
}

function computePurchaseOrderForbiddenChanges(
  originalPurchaseOrders: readonly PurchaseOrder[],
  newPurchaseOrderIds: readonly string[],
  purchaseOrderIdsWithManualPkgDealAllocation: readonly string[],
  purchaseOrderIdsWithCompletedOperations: readonly string[],
  kanbanId: string,
  kanbanLicence: string
): readonly ForbiddenPurchaseOrderChange[] {
  return originalPurchaseOrders
    .filter(({ id }) => !newPurchaseOrderIds.includes(id))
    .map(({ id }): ForbiddenPurchaseOrderChange | undefined => {
      if (purchaseOrderIdsWithManualPkgDealAllocation.includes(id)) {
        return {
          kanbanId,
          kanbanLicence,
          purchaseOrderId: id,
          reason: 'ManuallyAllocatedPackageDeals',
        };
      }
      if (purchaseOrderIdsWithCompletedOperations.includes(id)) {
        return {
          kanbanId,
          kanbanLicence,
          purchaseOrderId: id,
          reason: 'PackageDealsWithCompletedOperations',
        };
      }
      return undefined;
    })
    .filter(isTruthy);
}

function computeMinimalPayload(
  originalPurchaseOrders: readonly PurchaseOrder[],
  purchaseOrdersToCreate: readonly PurchaseOrder[],
  purchaseOrdersToUpdate: readonly PurchaseOrder[],
  purchaseOrdersToDelete: readonly PurchaseOrder[]
): readonly DeepPartial<PurchaseOrder>[] {
  const payload = applyPayload(originalPurchaseOrders, [
    ...purchaseOrdersToCreate,
    ...purchaseOrdersToUpdate,
    ...purchaseOrdersToDelete,
  ]);
  return computePayload(originalPurchaseOrders, payload);
}

export function computePurchaseOrderChanges(
  originalKanban: Kanban,
  newPurchaseOrders: readonly PurchaseOrder[]
): PurchaseOrdersChanges {
  const { workflowId } = originalKanban;
  const contractConfig = originalKanban.contract.configuration;

  const pkgDealCodesForPurchaseOrder = contractConfig.pkgDealDescCodesForPurchaseOrder[workflowId];
  if (!isTruthy(pkgDealCodesForPurchaseOrder)) {
    throw new Error(`pkgDealDescCodesForPurchaseOrder not available for workflow '${workflowId}'`);
  }

  const newPurchaseOrderIds = newPurchaseOrders.map(({ id }) => id);
  const originalPurchaseOrderIds = originalKanban.purchaseOrders.map(({ id }) => id);
  const originalPurchaseOrdersById = groupBy(originalKanban.purchaseOrders, ({ id }) => id);

  const purchaseOrderIdsWithManualPkgDealAllocation =
    getPurchaseOrderIdsWithManualPkgDealAllocation(
      originalKanban.packageDeals,
      pkgDealCodesForPurchaseOrder
    );
  const purchaseOrderIdsWithCompletedOperations = getPurchaseOrderIdsWithCompletedOperations(
    originalKanban.packageDeals
  );

  const purchaseOrdersToCreate = computePurchaseOrderToCreate(
    newPurchaseOrders,
    originalPurchaseOrderIds
  );
  const purchaseOrdersToUpdate = computePurchaseOrderToUpdate(
    newPurchaseOrders,
    originalPurchaseOrdersById
  );
  const purchaseOrdersToDelete = computePurchaseOrderToDelete(
    newPurchaseOrders,
    originalKanban.purchaseOrders,
    purchaseOrderIdsWithManualPkgDealAllocation,
    purchaseOrderIdsWithCompletedOperations
  );
  const forbiddenChanges = computePurchaseOrderForbiddenChanges(
    originalKanban.purchaseOrders,
    newPurchaseOrderIds,
    purchaseOrderIdsWithManualPkgDealAllocation,
    purchaseOrderIdsWithCompletedOperations,
    originalKanban.id,
    originalKanban.infos.license
  );

  const minimalPayload = computeMinimalPayload(
    originalKanban.purchaseOrders,
    purchaseOrdersToCreate,
    purchaseOrdersToUpdate,
    purchaseOrdersToDelete
  );

  return {
    minimalPayload,
    creations: purchaseOrdersToCreate,
    updates: purchaseOrdersToUpdate,
    deletions: purchaseOrdersToDelete,
    forbiddenChanges,
  };
}
