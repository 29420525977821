import type { WorkshopPostCategory, WorkshopStandImplantation } from '@stimcar/libs-base';
import { transverseHelpers } from '@stimcar/libs-base';
import { nonnull } from '@stimcar/libs-kernel';

export function useGetFurtherCategories(
  implantation: WorkshopStandImplantation | undefined,
  currentCategory: string,
  includeSearchedPost = true
): readonly WorkshopPostCategory[] {
  if (implantation === undefined) {
    return [];
  }
  return transverseHelpers.getFurtherWorkshopCategories(
    implantation,
    currentCategory,
    includeSearchedPost
  );
}

export function areOriginCategoryBeforeDestinationCategoryInTheSameTube(
  implantation: WorkshopStandImplantation,
  originPostCategory: string,
  destinationCategory: string
): boolean {
  const furtherCategoriesFromOrigin = transverseHelpers.getFurtherWorkshopCategories(
    implantation,
    originPostCategory,
    false
  );
  const furtherCategoryIds = furtherCategoriesFromOrigin.map((fl) => fl.id);
  return furtherCategoryIds.includes(destinationCategory);
}
export function getCategoriesGapBetweenOriginAndDestination(
  implantation: WorkshopStandImplantation | undefined,
  originPostCategory: string,
  destinationCategory: string
): readonly WorkshopPostCategory[] {
  if (implantation === undefined) {
    return [];
  }
  if (
    !areOriginCategoryBeforeDestinationCategoryInTheSameTube(
      nonnull(implantation),
      originPostCategory,
      destinationCategory
    )
  ) {
    return [];
  }
  const qualifiedCategoryIdsGap: WorkshopPostCategory[] = [];
  const furtherCategories = transverseHelpers.getFurtherWorkshopCategories(
    implantation,
    originPostCategory,
    true
  );
  for (const furtherCategory of furtherCategories) {
    if (furtherCategory.id === destinationCategory) {
      break;
    }
    qualifiedCategoryIdsGap.push(furtherCategory);
  }
  return qualifiedCategoryIdsGap;
}

export function useGetCategoriesGapBetweenOriginAndDestination(
  implantation: WorkshopStandImplantation | undefined,
  originPostCategory: string,
  destinationCategory: string
): readonly WorkshopPostCategory[] {
  return getCategoriesGapBetweenOriginAndDestination(
    implantation,
    originPostCategory,
    destinationCategory
  );
}
