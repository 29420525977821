import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { OperationColoredBlock, OperationsCell } from '../typings/store.js';
import { ColorBoxSpan } from '../../../bulma/elements/ColorBoxSpan.js';
import { DEFAULT_POST_OPERATOR_COUNT } from '../constants.js';
import { getCellTotalCellWorkload } from '../operationsDispatchUtils.js';

interface OperationBlockProps {
  readonly height: number;
  readonly duration: number;
  readonly tooltip: string;
  readonly color: string;
  readonly widthPerTenthOfHour: number;
}

function OperationBlock({
  duration,
  height,
  color,
  tooltip,
  widthPerTenthOfHour,
}: OperationBlockProps): JSX.Element {
  return (
    <ColorBoxSpan
      colorOrCssClass={color}
      tooltip={tooltip}
      height={`calc(${height}px - 4px)`}
      width={`${duration * widthPerTenthOfHour}px`}
    />
  );
}

interface ColorBlockRowsProps {
  readonly colorInfos: Map<string, string>;
  readonly height: number;
  readonly operators?: number;
  readonly blockWidthPerTenthOfHour: number;
  readonly cell: OperationsCell | undefined;
  readonly filterDoneOperationsBlocks: boolean;
}

export function ColorBlockRows({
  colorInfos,
  operators = DEFAULT_POST_OPERATOR_COUNT,
  height,
  blockWidthPerTenthOfHour,
  cell,
  filterDoneOperationsBlocks,
}: ColorBlockRowsProps): JSX.Element {
  const [t] = useTranslation('libComponents');

  const operationBlocks = useMemo(() => {
    const totalTime = getCellTotalCellWorkload(cell, filterDoneOperationsBlocks);

    let currentBlockIndex = 0;
    let alreadyUsedTimeInBlock = 0;
    const totalLineTime = totalTime / operators;
    const elements: JSX.Element[][] = [];

    if (!cell) {
      return elements;
    }

    const computeBlockColor = (block: OperationColoredBlock): string => {
      const color = colorInfos.get(block.operationId);
      if (block.isFinished) {
        return 'lightgrey';
      }
      if (!color) {
        return 'grey';
      }
      return color;
    };

    const computeBlockTooltip = (block: OperationColoredBlock): string => {
      if (block.isFinished) {
        return t('operationsDispatch.colorBlockTooltip_finished', {
          label: block.operationLabel,
          duration: block.workload,
        });
      }
      return t('operationsDispatch.colorBlockTooltip', {
        label: block.operationLabel,
        duration: block.workload,
      });
    };
    for (let index = 0; index < operators; index += 1) {
      let alreadyHandledTimeForLine = 0;
      elements[index] = [];
      while (alreadyHandledTimeForLine < totalLineTime && currentBlockIndex < cell.blocks.length) {
        const block = cell.blocks[currentBlockIndex];
        const timeForBlock = block.workload - alreadyUsedTimeInBlock;

        if (!filterDoneOperationsBlocks || !block.isFinished) {
          if (alreadyHandledTimeForLine + timeForBlock > totalLineTime) {
            const availableTime = totalLineTime - alreadyHandledTimeForLine;
            elements[index].push(
              <OperationBlock
                height={height}
                widthPerTenthOfHour={blockWidthPerTenthOfHour}
                key={block.operationId}
                tooltip={computeBlockTooltip(block)}
                duration={availableTime}
                color={computeBlockColor(block)}
              />
            );
            alreadyUsedTimeInBlock += availableTime;
            alreadyHandledTimeForLine += availableTime;
          } else {
            elements[index].push(
              <OperationBlock
                height={height}
                widthPerTenthOfHour={blockWidthPerTenthOfHour}
                key={block.operationId}
                tooltip={computeBlockTooltip(block)}
                duration={timeForBlock}
                color={computeBlockColor(block)}
              />
            );
            currentBlockIndex += 1;
            alreadyHandledTimeForLine += timeForBlock;
            alreadyUsedTimeInBlock = 0;
          }
        } else {
          currentBlockIndex += 1;
        }
      }
    }
    return elements;
  }, [
    cell,
    colorInfos,
    operators,
    t,
    height,
    blockWidthPerTenthOfHour,
    filterDoneOperationsBlocks,
  ]);

  return (
    <>
      {operationBlocks.map((e, i): JSX.Element => {
        return (
          <div
            // We don't have any informations able to tell prevent React to re-render this element
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            style={{
              height: `${height}px`,
              marginTop: '2px',
              marginBottom: '2px',
            }}
          >
            {e.map((b) => b)}
          </div>
        );
      })}
    </>
  );
}
