import type { JSX } from 'react';
import React from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import type { FormFieldProps } from '@stimcar/libs-uitoolkit';
import { useFormFieldWarning } from '@stimcar/libs-uikernel';
import { FormField } from '@stimcar/libs-uitoolkit';
import type { ChoiceAddOnButtonsProps } from './ChoiceAddOnButtons.js';
import { ChoiceAddOnButtons } from './ChoiceAddOnButtons.js';

export interface ChoiceAddOnButtonsFormFieldProps<SD extends AnyStoreDef>
  extends ChoiceAddOnButtonsProps<SD>,
    Omit<FormFieldProps, 'children' | 'warning' | 'noExclamationTriangleIfWarning'> {}

export function ChoiceAddOnButtonsFormField<SD extends AnyStoreDef>({
  label,
  horizontal,
  $,
  ...props
}: ChoiceAddOnButtonsFormFieldProps<SD>): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const warning = useFormFieldWarning($);
  return (
    <FormField
      label={label}
      warning={warning}
      noExclamationTriangleIfWarning
      horizontal={horizontal}
    >
      <ChoiceAddOnButtons $={$} {...props} />
    </FormField>
  );
}
