import { type SiteConfiguration, type UIContract } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';

export type ContractOrWorkflowIssue =
  | 'unknownContract'
  | 'unknownContractForDb'
  | 'unknownWorkflowId'
  | 'unknownWorkflowForSiteConfig'
  | 'unknownPkgDealCodesForKanban'
  | 'unknownPkgDealCodesForPurchaseOrder';

export function validateContractAndWorkflow(
  contract: UIContract | undefined,
  workflowId: string,
  siteConfiguration: SiteConfiguration
): ContractOrWorkflowIssue | undefined {
  if (!isTruthy(contract)) {
    return 'unknownContract';
  }
  if (!isTruthyAndNotEmpty(workflowId)) {
    return 'unknownWorkflowId';
  }

  if (!siteConfiguration.packageDealDatabases.includes(contract.packageDealDatabase)) {
    return 'unknownContractForDb';
  }
  const workflow = siteConfiguration.workflows.find((c) => c.id === workflowId);
  if (!workflow) {
    return 'unknownWorkflowForSiteConfig';
  }
  const pkgDealCodesForKanban = contract.pkgDealDescCodesForKanban[workflowId];
  const pkgDealCodesForPurchaseOrder = contract.pkgDealDescCodesForPurchaseOrder[workflowId];
  if (!isTruthy(pkgDealCodesForKanban)) {
    return 'unknownPkgDealCodesForKanban';
  }
  if (!isTruthy(pkgDealCodesForPurchaseOrder)) {
    return 'unknownPkgDealCodesForPurchaseOrder';
  }
  return undefined;
}

export function throwErrorIfContractOrWorkflowIssue(
  issue: ContractOrWorkflowIssue | undefined,
  contractCode: string,
  workflowId: string
): void {
  switch (issue) {
    case 'unknownContract':
      throw new Error('Cannot create default package deals if no contract is provided');
    case 'unknownWorkflowId':
      throw new Error('Cannot create default package deals if no workflow is provided');
    case 'unknownContractForDb':
      throw new Error(`Unknown contract '${contractCode}' for contract database`);
    case 'unknownWorkflowForSiteConfig':
      throw new Error(`Unknown workflow: ${workflowId}`);
    case 'unknownPkgDealCodesForKanban':
      throw new Error(
        `The contract ${contractCode} does not contain a pkgDealDescCodesForKanban for workflow ${workflowId}`
      );
    case 'unknownPkgDealCodesForPurchaseOrder':
      throw new Error(
        `The contract ${contractCode} does not contain a pkgDealDescCodesForPurchaseOrder for workflow ${workflowId}`
      );
    case undefined:
      // Nothing to do
      break;
    default:
      throw new Error(`Unknown ContractOrWorkflowIssue`);
  }
}
