export type ImageModalState = {
  readonly active: boolean;
  readonly imagesUrls: readonly string[];
  readonly imageIndex: number;
};

export const EMPTY_IMAGE_MODAL_STATE: ImageModalState = {
  active: false,
  imagesUrls: [],
  imageIndex: 0,
};

export type ImageModalContainerState = {
  readonly imageModal: ImageModalState;
};
