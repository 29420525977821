import { useEffect } from 'react';
import type { AnyStoreDef, NoArgAction, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback } from '@stimcar/libs-uikernel';

export type ComponentWithMountTracking = {
  readonly componentIsMounted: boolean;
};

/**
 * Stores a boolean in the state that allows to know if the component is mounted
 * or not.
 *
 * This is useful in components that must react to external notifications (like repository
 * changes, ...). Thanks to that boolean it is possible not to trigger the update process
 * when the component is not mounted.
 *
 * @param $ the sttate selector.
 * @param initComponentAction the action that will be called when the component is mounted.
 * @param unmountState the state to use when unmounting the component (to clean the state).
 */
export const useComponentWithMountTracking = <
  SD extends AnyStoreDef,
  S extends ComponentWithMountTracking,
>(
  $: StoreStateSelector<SD, S>,
  initComponentAction?: NoArgAction<SD, S>,
  unmountState?: S
) => {
  const asyncMountEffect = useActionCallback(
    async ({ actionDispatch }) => {
      // Assert the component is mounted
      actionDispatch.setProperty('componentIsMounted', true);
      if (initComponentAction) {
        await actionDispatch.exec(initComponentAction);
      }
    },
    [initComponentAction],
    $
  );

  const asyncUnmountEffect = useActionCallback(
    ({ actionDispatch }) => {
      // Reset state if needed
      if (unmountState) {
        actionDispatch.setValue(unmountState);
      }
      // And then assert the component is unmounted
      actionDispatch.setProperty('componentIsMounted', false);
    },
    [unmountState],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncMountEffect();
    return asyncUnmountEffect as () => void;
  }, [asyncMountEffect, asyncUnmountEffect]);
};
