import type { DeepPartial } from '@stimcar/libs-kernel';
import type {
  Action,
  ActionArgs,
  ActionCallback,
  ActionContext,
  AnyStoreDef,
  ArrayItemStateType,
  Dispatch,
  NoArgActionCallback,
  PayloadProvider,
  Reducer,
  ReducerArgs,
  State,
} from './typings/index.js';

type DispatchWithChangeTrigger<SD extends AnyStoreDef, S extends State> = Dispatch<SD, S> & {
  readonly wrapped: Dispatch<SD, S>;
  readonly onChangeActionCallback: NoArgActionCallback<SD>;
};

export class DispatchWithChangeTriggerImpl<SD extends AnyStoreDef, S extends State>
  implements DispatchWithChangeTrigger<SD, S>
{
  public readonly wrapped: Dispatch<SD, S>;

  public readonly onChangeActionCallback: NoArgActionCallback<SD>;

  public readonly path: string;

  constructor(
    dispatch: Dispatch<SD, S> | DispatchWithChangeTrigger<SD, S>,
    triggerActionCallback: NoArgActionCallback<SD>
  ) {
    // If the provided dispatch is a dispatch with a trigger :
    if ((dispatch as DispatchWithChangeTrigger<SD, S>).onChangeActionCallback) {
      const dispatchWithChangeTrigger = dispatch as DispatchWithChangeTrigger<SD, S>;
      this.wrapped = dispatchWithChangeTrigger.wrapped;
      // The provided trigger must be aggregated with the existing one
      this.onChangeActionCallback = this.wrapped.createActionCallback(
        async ({ actionDispatch }) => {
          await actionDispatch.execCallback(dispatchWithChangeTrigger.onChangeActionCallback);
          await actionDispatch.execCallback(triggerActionCallback);
        }
      );
    }
    // Otherwise, with a standard trigger
    else {
      this.wrapped = dispatch;
      this.onChangeActionCallback = triggerActionCallback;
    }
    this.path = dispatch.path;
  }

  public createActionCallback<A extends Action<SD, S>>(action: A): ActionCallback<SD, A> {
    return this.wrapped.createActionCallback(
      this.createActionWithChangeTrigger(async ({ actionDispatch }, ...args: ActionArgs<A>) =>
        actionDispatch.exec(action, ...args)
      )
    );
  }

  public async applyPayload(
    payload: DeepPartial<S> | PayloadProvider<S> | undefined
  ): Promise<void> {
    await this.execActionWithChangeTrigger(({ actionDispatch }) =>
      actionDispatch.applyPayload(payload)
    );
  }

  public async exec<A extends Action<SD, S>>(action: A, ...args: ActionArgs<A>): Promise<void> {
    await this.execActionWithChangeTrigger(async ({ actionDispatch }) =>
      actionDispatch.exec(action, ...args)
    );
  }

  public async execCallback<A extends Action<SD, State>>(
    callback: ActionCallback<SD, A>,
    ...args: ActionArgs<A>
  ): Promise<void> {
    await this.execActionWithChangeTrigger(async ({ actionDispatch }) =>
      actionDispatch.execCallback(callback, ...args)
    );
  }

  public async reduce<R extends Reducer<S>>(reducer: R, ...args: ReducerArgs<S, R>): Promise<void> {
    await this.execActionWithChangeTrigger(({ actionDispatch }) =>
      actionDispatch.reduce(reducer, ...args)
    );
  }

  public async setProperty<K extends keyof S>(key: K, value: S[K]): Promise<void> {
    await this.execActionWithChangeTrigger(({ actionDispatch }) =>
      actionDispatch.setProperty(key, value)
    );
  }

  public async setValue(value: S): Promise<void> {
    await this.execActionWithChangeTrigger(({ actionDispatch }) => actionDispatch.setValue(value));
  }

  private async execActionWithChangeTrigger<A extends Action<SD, S>>(action: A): Promise<void> {
    await this.wrapped.exec(this.createActionWithChangeTrigger(action));
  }

  private createActionWithChangeTrigger<A extends Action<SD, S>>(action: A): Action<SD, S> {
    const { onChangeActionCallback } = this;
    return async function actionWithChangeTrigger(
      { actionDispatch }: ActionContext<SD, S>,
      ...args: ActionArgs<A>
    ): Promise<void> {
      await actionDispatch.exec(action, ...args);
      // Link the change trigger
      await actionDispatch.execCallback(onChangeActionCallback);
    };
  }

  public scopeArrayItem(
    id: string | number
  ): S extends readonly (infer ARRAY_ITEM)[]
    ? ARRAY_ITEM extends ArrayItemStateType
      ? Dispatch<SD, ARRAY_ITEM>
      : never
    : never {
    const scopedDispatch = this.wrapped.scopeArrayItem(id);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new DispatchWithChangeTriggerImpl(scopedDispatch, this.onChangeActionCallback) as any;
  }

  public scopeProperty<K extends keyof S>(key: K): S[K] extends State ? Dispatch<SD, S[K]> : never {
    const scopedDispatch = this.wrapped.scopeProperty(key);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new DispatchWithChangeTriggerImpl(scopedDispatch, this.onChangeActionCallback) as any;
  }

  public scopeRecordItem(
    key: string
  ): S extends Record<string, infer V> ? (V extends State ? Dispatch<SD, V> : never) : never {
    const scopedDispatch = this.wrapped.scopeRecordItem(key);
    return new DispatchWithChangeTriggerImpl(
      scopedDispatch as any, // eslint-disable-line @typescript-eslint/no-explicit-any
      this.onChangeActionCallback
    ) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }
}
