import type React from 'react';
import { useCallback, useEffect, useRef } from 'react';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';

const WAIT_INTERVAL = 400;
const ENTER_KEY = 13;

interface TimerContainer {
  timer: NodeJS.Timeout | null;
}

interface Result<T extends HTMLInputElement | HTMLTextAreaElement> {
  readonly inputFieldRef: React.RefObject<T | null>;
  readonly scheduleDelayedStateChange: () => void;
  readonly scheduleImmediateStateChange: () => void;
  readonly onKeyDown: (e: React.KeyboardEvent<T>) => void;
}

interface StoreProps<SD extends AnyStoreDef> {
  readonly $: StoreStateSelector<SD, string | undefined>;
}

export function useTextFieldWithDelayedStateChange<
  SD extends AnyStoreDef,
  T extends HTMLInputElement | HTMLTextAreaElement,
>({ $ }: StoreProps<SD>): Result<T> {
  const value = useGetState($);
  const inputFieldRef = useRef<T>(null);

  /**
   * The state is not updated each time a change occurs : instead we wait
   * for a little delay before flushing the state.
   *
   * The delay implementation has been inspired by : https://gist.github.com/krambertech/76afec49d7508e89e028fce14894724c#gistcomment-2674845
   */
  const timerContainerRef = useRef<TimerContainer>({ timer: null });

  const applyStateChange = useActionCallback(
    ({ actionDispatch }) => {
      if (inputFieldRef.current) {
        actionDispatch.setValue(inputFieldRef.current.value);
      }
    },
    [],
    $
  );

  /**
   * Schedule an update after a delay
   */
  const scheduleDelayedStateChange = useCallback(() => {
    const timerContainer = timerContainerRef.current;
    if (timerContainer.timer) {
      clearTimeout(timerContainer.timer);
    }
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    timerContainer.timer = setTimeout(applyStateChange, WAIT_INTERVAL);
  }, [timerContainerRef, applyStateChange]);

  /**
   * Schedule an update immediately
   */
  const scheduleImmediateStateChange = useCallback(() => {
    const timerContainer = timerContainerRef.current;
    if (timerContainer.timer) {
      clearTimeout(timerContainer.timer);
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    applyStateChange();
  }, [timerContainerRef, applyStateChange]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<T>) => {
      if (e.keyCode === ENTER_KEY) {
        // Schedule an immediate update if the user presses Return
        scheduleImmediateStateChange();
      }
    },
    [scheduleImmediateStateChange]
  );

  useEffect((): void => {
    if (inputFieldRef.current) {
      inputFieldRef.current.value = value || '';
    }
  }, [inputFieldRef, value]);

  return { inputFieldRef, scheduleDelayedStateChange, scheduleImmediateStateChange, onKeyDown };
}
