import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Attachment } from '@stimcar/libs-base';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { WORK_SHEET_ATTACHMENTS_FOLDER, WORK_SHEET_ATTRIBUTE_NAME } from '@stimcar/libs-base';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { ImportButton } from '@stimcar/libs-uitoolkit';
import type { ComputeAttachmentUrlCallback } from '../../lib/components/attachments/typings/attachment.js';
import type { Store } from '../state/typings/store.js';
import { AttachmentThumbnail } from '../../lib/components/attachments/AttachmentThumbnail.js';
import { importAttachmentsAction } from '../utils/attachmentGalleryActions.js';

interface WorkSheetTabComponentProps extends AppProps<Store> {
  readonly kanbanId: string;
  readonly isOnline: boolean;
  readonly workSheetFileName: string;
  readonly computeAttachmentUrl: ComputeAttachmentUrlCallback;
}

export function WorkSheetTabComponent({
  $gs,
  isOnline,
  kanbanId,
  workSheetFileName,
  computeAttachmentUrl,
}: WorkSheetTabComponentProps) {
  const [t] = useTranslation('details');

  const attachments = useMemo(
    () => [
      {
        folder: WORK_SHEET_ATTACHMENTS_FOLDER.id,
        name: workSheetFileName,
      } as Attachment,
    ],
    [workSheetFileName]
  );

  const importAttachmentsCallback = useActionCallback(
    async ({ actionDispatch, kanbanRepository }, files: readonly File[]): Promise<void> => {
      const workSheetFile = files[0];
      if (workSheetFile) {
        await actionDispatch.exec(
          importAttachmentsAction,
          'kanban',
          kanbanId,
          WORK_SHEET_ATTACHMENTS_FOLDER.id,
          [workSheetFile],
          undefined
        );
        // If the file is an image, it is converted on the server side into webp
        const workSheetFileName = workSheetFile.name.replace(/.(jpeg|jpg|png)$/gi, '.webp');
        await kanbanRepository.updateEntityFromPayload({
          entityId: kanbanId,
          payload: { attributes: { [WORK_SHEET_ATTRIBUTE_NAME]: workSheetFileName } },
        });
      }
    },
    [kanbanId],
    $gs
  );

  return (
    <>
      <ImportButton
        size="small"
        disabled={!isOnline}
        additionalClass="is-primary"
        onFilesReadyCallback={importAttachmentsCallback}
        label={t('tabs.workSheet.importWorkSheetButton')}
      />
      {isTruthyAndNotEmpty(workSheetFileName) ? (
        <>
          <h1>{t('tabs.workSheet.title')}</h1>
          <div style={{ width: 128 }}>
            <AttachmentThumbnail
              size={128}
              category="kanban"
              objectId={kanbanId}
              attachmentIndex={0}
              attachments={attachments}
              caption={workSheetFileName}
              $imageModal={$gs.$imageModal}
              computeAttachmentUrl={computeAttachmentUrl}
            />
          </div>
        </>
      ) : (
        <p>{t('tabs.workSheet.noWorkSheetFound')}</p>
      )}
    </>
  );
}
