import type { JSX } from 'react';
import React from 'react';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';

interface MenuItemProps<SD extends AnyStoreDef> {
  readonly isActive: boolean;
  readonly id: string;
  readonly label: string;
  readonly $: StoreStateSelector<SD, string>;
}

function MenuItem<SD extends AnyStoreDef>({
  isActive,
  label,
  $,
  id,
}: MenuItemProps<SD>): JSX.Element {
  const setMenuItemCallback = useActionCallback(
    ({ actionDispatch }) => actionDispatch.setValue(id),
    [id],
    $
  );
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
      <li onClick={setMenuItemCallback}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className={isActive ? 'is-active' : ''}>{label}</a>
      </li>
    </>
  );
}

export interface MenuEntry {
  readonly id: string;
  readonly label: string;
}
export interface MenuItem {
  readonly categoryLabel?: string;
  readonly entries: readonly MenuEntry[];
}
interface MenuProps<SD extends AnyStoreDef> {
  readonly menuItems: readonly MenuItem[];
  readonly $: StoreStateSelector<SD, string>;
}

export function Menu<SD extends AnyStoreDef>({ menuItems, $ }: MenuProps<SD>): JSX.Element {
  const selectedMenuItemId = useGetState($);
  return (
    <div className="menu">
      {menuItems.map((item, index): JSX.Element => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            {isTruthy(item.categoryLabel) && <p className="menu-label">{item.categoryLabel}</p>}
            <ul className="menu-list">
              {item.entries.map((entry): JSX.Element => {
                return (
                  <MenuItem
                    key={entry.id}
                    isActive={selectedMenuItemId === entry.id}
                    id={entry.id}
                    label={entry.label}
                    $={$}
                  />
                );
              })}
            </ul>
          </React.Fragment>
        );
      })}
    </div>
  );
}
