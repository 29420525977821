import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import React, { Suspense, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PackageDealDesc, SiteConfiguration, WorkflowNode } from '@stimcar/libs-base';
import type { ActionContext, Reducer } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX } from '@stimcar/core-libs-repository';
import {
  AvailablePermissionPaths,
  CoreBackendRoutes,
  enumerate,
  isSiteConfiguration,
  workflowHelpers,
} from '@stimcar/libs-base';
import { ensureError, isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { useRemainingVerticalSpace } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { Notification } from '../../../lib/bulma/elements/Notification.js';
import { GraphicWorkflow } from '../../../lib/components/GraphicWorkflow.js';
import { Tabs } from '../../../lib/components/Tabs.js';
import { useHasModifyPermission } from '../../../registeredapp/permissionHooks.js';
import { LazyAceEditor } from '../../utils/LazyAceEditor.js';
import type { AdminWorkflowState } from './typings/store.js';
import { assertStandImplantationLevelIdUnicity } from './workflowUtils.js';

async function updateConfigurationAction(
  {
    httpClient,
    packageDealDescRepository,
    globalActionDispatch,
    getState,
  }: ActionContext<Store, AdminWorkflowState>,
  t: TFunction
): Promise<void> {
  const newConfiguration: SiteConfiguration = JSON.parse(getState().rawSiteConfiguration);
  const originConfiguration: SiteConfiguration = JSON.parse(getState().originRawSiteConfiguration);

  const originPackageDealDatabases = originConfiguration.packageDealDatabases;
  const newPackageDealDatabases = newConfiguration.packageDealDatabases;

  const deletedContractIds = originPackageDealDatabases.filter(
    (c) => !newPackageDealDatabases.includes(c)
  );

  const deletedContractsWithExistingPDD: string[] = [];

  await Promise.all(
    deletedContractIds.map(async (c): Promise<void> => {
      const pdds = await packageDealDescRepository.getEntitiesFromIndex(
        PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX,
        c
      );
      if (pdds.length > 0) {
        deletedContractsWithExistingPDD.push(c);
      }
    })
  );

  if (deletedContractsWithExistingPDD.length > 0) {
    globalActionDispatch.setProperty(
      'message',
      t('errors.deletedContractWithPDD', {
        contracts: enumerate(deletedContractsWithExistingPDD),
      })
    );
  } else {
    // Simply push the new configuration to the server
    // (the new configuration will then be pushed by the server
    // to all clients, including the current one)
    await httpClient.httpPostAsJSON(CoreBackendRoutes.SITE_CONFIGURATION, newConfiguration);
  }
}

const validConfigurationReducer =
  (t: TFunction): Reducer<AdminWorkflowState> =>
  (state: AdminWorkflowState): AdminWorkflowState => {
    try {
      const { rawSiteConfiguration } = state;
      const editedSiteConfiguration = JSON.parse(rawSiteConfiguration);
      const warnings: string[] = [];
      const errors: string[] = [];

      if (!isSiteConfiguration(editedSiteConfiguration)) {
        return {
          ...state,
          validationErrors: [t('errors.notConfiguration')],
        };
      }
      if (
        editedSiteConfiguration.stands.length === 0 ||
        editedSiteConfiguration.workflows.length === 0 ||
        editedSiteConfiguration.packageDealDatabases.length === 0
      ) {
        return {
          ...state,
          validationErrors: [t('errors.noStandOrNoWorkflowOrNoContract')],
        };
      }
      let duplicates: { workflowId: string; duplicates: string[] } | undefined;
      editedSiteConfiguration.workflows.forEach((w) => {
        const wDuplicates = workflowHelpers.findDuplicateWorkflowNodeIds(w.definition);
        if (wDuplicates.length > 0) {
          duplicates = {
            workflowId: w.id,
            duplicates: wDuplicates,
          };
        }
      });
      if (duplicates) {
        return {
          ...state,
          validationErrors: [t('errors.duplicateWorkflowNodes', duplicates)],
        };
      }

      editedSiteConfiguration.stands.forEach((stand) => {
        if (isTruthy(stand.implantations)) {
          if (stand.implantations.length > 1) {
            errors.push('Une seule implantation par stand est supportée pour le moment');
          }
          stand.implantations.forEach((i) => {
            const error = assertStandImplantationLevelIdUnicity(t, i);
            if (isTruthyAndNotEmpty(error)) {
              errors.push(error);
            }
          });
        }
      });

      let { displayedWorkflow } = state;
      if (
        displayedWorkflow === '' ||
        editedSiteConfiguration.workflows.filter((w): boolean => w.id === displayedWorkflow)
          .length === 0
      ) {
        displayedWorkflow = editedSiteConfiguration.workflows[0].id;
      }

      return {
        ...state,
        displayedWorkflow,
        validationWarnings: warnings,
        validationErrors: errors,
      };
    } catch (err) {
      return {
        ...state,
        validationErrors: [`An unexpected error occurred : ${ensureError(err).message}`],
      };
    }
  };

export function AdminWorkflow({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation('workflows');
  const { $adminWorkflows } = $gs.$adminView;

  const isEditionForbidden = !useHasModifyPermission($gs, AvailablePermissionPaths.WORKFLOW_VIEW);

  const initConfigurationActionCallback = useActionCallback(
    async ({
      actionDispatch,
      httpClient,
      packageDealDescRepository,
    }: ActionContext<Store, AdminWorkflowState>): Promise<void> => {
      const packageDealDescs = await packageDealDescRepository.getAllEntities();
      const packageDealDescsPerDatabase: Record<string, PackageDealDesc[]> = {};
      packageDealDescs.forEach((pdd) => {
        const pddList = packageDealDescsPerDatabase[pdd.database] ?? [];
        pddList.push(pdd);
        packageDealDescsPerDatabase[pdd.database] = pddList;
      });
      // Don't take the configuration that is saved in the state as it is
      // not in a concise notation
      const siteConfiguration = await httpClient.httpGetAsJson<SiteConfiguration<'concise'>>(
        CoreBackendRoutes.SITE_CONFIGURATION
      );
      const displayedWorkflow =
        siteConfiguration.workflows.length > 0 ? siteConfiguration.workflows[0].id : undefined;
      actionDispatch.applyPayload({
        packageDealDescsPerDatabase,
        rawSiteConfiguration: JSON.stringify(siteConfiguration, null, 2),
        originRawSiteConfiguration: JSON.stringify(siteConfiguration, null, 2),
        displayedWorkflow,
      });
    },
    [],
    $adminWorkflows
  );

  const rawSiteConfiguration = useGetState($adminWorkflows.$rawSiteConfiguration);

  const onSiteConfigurationChangeActionCallback = useActionCallback(
    ({ actionDispatch }, value: string) => {
      actionDispatch.setProperty('rawSiteConfiguration', value);
      actionDispatch.reduce(validConfigurationReducer(t));
    },
    [t],
    $adminWorkflows
  );

  const originRawSiteConfiguration = useGetState($adminWorkflows.$originRawSiteConfiguration);

  const editedSiteConfiguration = useMemo((): SiteConfiguration => {
    try {
      return JSON.parse(rawSiteConfiguration) as SiteConfiguration;
    } catch {
      return JSON.parse(originRawSiteConfiguration) as SiteConfiguration;
    }
  }, [originRawSiteConfiguration, rawSiteConfiguration]);

  useEffect((): void => {
    const asyncEffect = initConfigurationActionCallback;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
  }, [initConfigurationActionCallback]);

  const displayedWorkflow = useGetState($adminWorkflows.$displayedWorkflow);

  const displayedWorkflowNode = useMemo((): WorkflowNode | undefined => {
    if (!displayedWorkflow) {
      return undefined;
    }
    const workflow = editedSiteConfiguration.workflows.find(
      (w): boolean => w.id === displayedWorkflow
    );
    if (!workflow) {
      return undefined;
    }
    return workflow.definition;
  }, [editedSiteConfiguration.workflows, displayedWorkflow]);

  const workflowTabLabels = useMemo(() => {
    const result: Record<string, string> = {};
    editedSiteConfiguration.workflows.forEach((w) => {
      result[w.id] = w.id;
    });
    return result;
  }, [editedSiteConfiguration]);

  const computeRightViewPart = (): JSX.Element => {
    return (
      <>
        {editedSiteConfiguration.workflows.length === 0 ? (
          <div className="has-text-centered">{t('noGraph')}</div>
        ) : (
          <>
            <Tabs labels={workflowTabLabels} $selectedTab={$adminWorkflows.$displayedWorkflow} />
            <div className="has-text-centered">
              {displayedWorkflowNode && <GraphicWorkflow workflowNode={displayedWorkflowNode} />}
            </div>
          </>
        )}
      </>
    );
  };

  const updateConfigurationActionCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.exec(updateConfigurationAction, t);
    },
    [t],
    $adminWorkflows
  );

  const [containerHeight, measuredRef] = useRemainingVerticalSpace();

  const validationErrors = useGetState($adminWorkflows.$validationErrors);

  const validationWarnings = useGetState($adminWorkflows.$validationWarnings);

  return (
    <>
      <p className="title is-2">{t('title')}</p>
      <p className="subtitle is-4">{t('subtitle')}</p>
      <div className="columns">
        <div className="column is-8">
          <div className="box">
            <div className="columns">
              <div className="column is-10" ref={measuredRef}>
                <Suspense fallback="...">
                  <LazyAceEditor
                    editorProps={{
                      $blockScrolling: Infinity,
                    }}
                    mode="json"
                    theme="github"
                    value={rawSiteConfiguration}
                    onChange={onSiteConfigurationChangeActionCallback}
                    name="UNIQUE_ID_OF_DIV"
                    width="100%"
                    height={`${containerHeight}PX`}
                    readOnly={isEditionForbidden}
                  />
                </Suspense>
              </div>
              <div className="column has-text-centered">
                <div className="m-t-lg m-b-lg">
                  <button
                    type="button"
                    className="button is-primary is-fullwidth"
                    onClick={updateConfigurationActionCallback}
                    disabled={isEditionForbidden || validationErrors.length !== 0}
                  >
                    {t('saveButton')}
                  </button>
                </div>
                <div className="m-t-lg m-b-lg">
                  <button
                    type="button"
                    className="button is-fullwidth"
                    onClick={initConfigurationActionCallback}
                    disabled={isEditionForbidden || validationErrors.length !== 0}
                  >
                    {t('Reset')}
                  </button>
                </div>
              </div>
            </div>
            {validationErrors.map((e): JSX.Element => {
              return <Notification key={e} value={e} type="error" />;
            })}
            {validationWarnings.map((e): JSX.Element => {
              return <Notification key={e} value={e} type="warning" />;
            })}
          </div>
        </div>
        <div className="column">
          <div className="box">{computeRightViewPart()}</div>
        </div>
      </div>
    </>
  );
}
