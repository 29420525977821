import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { loadUIToolkitResourceBundles } from './i18n-resourcebundles.js';

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      lng: 'fr',
      fallbackLng: 'fr',
      interpolation: {
        escapeValue: false, // react is already safe from xss
      },
      returnNull: false,
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (error: any): void => {
      if (error) {
        throw error;
      }
      // Load resource bundles
      loadUIToolkitResourceBundles(i18n);
    }
  );

// eslint-disable-next-line import/no-default-export
export default i18n;
