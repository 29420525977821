import type { JSX } from 'react';
import { t } from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  AvailableStimer,
  CommissionLine,
  MarketplaceOverviewRequest,
  MarketplaceStimerOverview,
  StimerInfos,
} from '@stimcar/core-libs-common';
import type { Kanban, PackageDeal } from '@stimcar/libs-base';
import type { WarrantyExtensionValue } from '@stimcar/libs-kernel';
import type { GlobalStoreStateSelector, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { SimpleOption } from '@stimcar/libs-uitoolkit';
import {
  CoreBackendRoutes,
  MARKETPLACE_DOC_TYPE_STIMER,
  MKTP_PKG_DEAL_CODE_CLIENT_DELIVERY,
  MKTP_PKG_DEAL_CODE_VARIABLE_FEE,
  nonDeleted,
  packageDealHelpers,
  priceHelpers,
} from '@stimcar/libs-base';
import {
  applyPayload,
  computePayload,
  formatPrice,
  isTruthy,
  MKTP_WARRANTY_EXTENSION_NOT_SPECIFIED,
} from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  Button,
  InputFormField,
  ModalCardDialog,
  ReactSelectFormField,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type { MarketplaceStimerState } from './typings/store.js';
import { availableOrRecommended } from './overview/MarketplaceOverview.js';

const DEFAULT_STIMER_COMMISSION_RATE = 0.6;
const DEFAULT_REFURBISH_MARGIN_RATE = 0.25;
const DEFAULT_LOAN_RATE = 0.015;

type Commissions = {
  readonly variableFeeWithoutVAT: number;
  readonly refurbishmentWithoutVAT: number;
  readonly warrantyExtensionWithoutVAT: number;
  readonly loanWithoutVAT: number;
  readonly clientDeliveryWithoutVAT: number;
  readonly totalStimerWithoutVAT: number;
  readonly totalCommissionsWithoutVAT: number;
  readonly totalCommissionsVAT: number;
  readonly totalCommissionsWithVAT: number;
};

function computeVariableFeeCommissionWithoutVAT(
  packageDeals: readonly PackageDeal[],
  stimerCommissionRate: number = DEFAULT_STIMER_COMMISSION_RATE
): number {
  const variableFeePkgDeal = packageDeals
    .filter(nonDeleted)
    .find(({ code }) => code === MKTP_PKG_DEAL_CODE_VARIABLE_FEE);
  if (isTruthy(variableFeePkgDeal)) {
    const variableFeePriceWithoutVAT =
      packageDealHelpers.getPackageDealAndSparePartsPriceWithoutVAT(variableFeePkgDeal, 'all');
    return variableFeePriceWithoutVAT * stimerCommissionRate;
  }
  return 0;
}

function computeRefurbishmentCommissionWithoutVAT(
  packageDeals: readonly PackageDeal[],
  stimerCommissionRate: number = DEFAULT_STIMER_COMMISSION_RATE,
  refurbishMarginRate: number = DEFAULT_REFURBISH_MARGIN_RATE
): number {
  const refurbishmentPkgDeals = packageDeals
    .filter(
      ({ marketplaceSaleCategory, marketplaceBuyCategory }) =>
        !isTruthy(marketplaceSaleCategory) && !isTruthy(marketplaceBuyCategory)
    )
    .filter((pkgDeal) =>
      packageDealHelpers.isPackageDealWithGivenCategory(pkgDeal, ['MECA', 'CARRO'])
    )
    .filter(nonDeleted)
    .filter(availableOrRecommended);

  const totalRefurbishmentPkgDealsWithoutVAT =
    packageDealHelpers.getPackageDealsAndSparePartsTotalPriceWithoutVAT(
      refurbishmentPkgDeals,
      'all'
    );

  return totalRefurbishmentPkgDealsWithoutVAT * refurbishMarginRate * stimerCommissionRate;
}

function computeWarrantyExtensionCommissionWithoutVAT(
  warrantyExtension: WarrantyExtensionValue,
  stimerCommissionRate: number = DEFAULT_STIMER_COMMISSION_RATE
): number {
  const warrantyExtensionPrice = (() => {
    switch (warrantyExtension) {
      case '12MonthsT3':
        return 80;
      case '24MonthsT3':
        return 160;
      case '36MonthsT3':
        return 200;
      case '48MonthsT3':
        return 240;
      case '12MonthsT4':
        return 120;
      case '24MonthsT4':
        return 240;
      case '36MonthsT4':
        return 300;
      case '48MonthsT4':
        return 360;
      case '12MonthsT5':
        return 160;
      case '24MonthsT5':
        return 300;
      case '36MonthsT5':
        return 400;
      case '48MonthsT5':
        return 480;
      case 'unknown':
      default:
        return 0;
    }
  })();
  return warrantyExtensionPrice * stimerCommissionRate;
}

function computeLoanCommissionWithoutVAT(
  loanAmount: number,
  stimerCommissionRate: number = DEFAULT_STIMER_COMMISSION_RATE
): number {
  return DEFAULT_LOAN_RATE * loanAmount * stimerCommissionRate;
}

function computeClientDeliveryCommissionWithoutVAT(
  packageDeals: readonly PackageDeal[],
  stimerCommissionRate: number = DEFAULT_STIMER_COMMISSION_RATE
): number {
  const clientDeliveryPackageDeal = packageDeals
    .filter(nonDeleted)
    .find(({ code }) => code === MKTP_PKG_DEAL_CODE_CLIENT_DELIVERY);
  if (isTruthy(clientDeliveryPackageDeal)) {
    const clientDeliveryPriceWithoutVAT =
      packageDealHelpers.getPackageDealAndSparePartsPriceWithoutVAT(
        clientDeliveryPackageDeal,
        'all'
      );
    return clientDeliveryPriceWithoutVAT * stimerCommissionRate;
  }
  return 0;
}

function computeCommissionValues(
  packageDeals: readonly PackageDeal[],
  warrantyExtensionValue: WarrantyExtensionValue,
  loanAmount: number,
  stimerPercentageRate: number
): Commissions {
  const variableFeeWithoutVAT = computeVariableFeeCommissionWithoutVAT(packageDeals);
  const refurbishmentWithoutVAT = computeRefurbishmentCommissionWithoutVAT(packageDeals);
  const warrantyExtensionWithoutVAT =
    computeWarrantyExtensionCommissionWithoutVAT(warrantyExtensionValue);
  const loanWithoutVAT = computeLoanCommissionWithoutVAT(loanAmount);
  const clientDeliveryWithoutVAT = computeClientDeliveryCommissionWithoutVAT(packageDeals);
  const totalCommissionsWithoutVAT =
    variableFeeWithoutVAT +
    refurbishmentWithoutVAT +
    warrantyExtensionWithoutVAT +
    loanWithoutVAT +
    clientDeliveryWithoutVAT;
  const totalStimerWithoutVAT = totalCommissionsWithoutVAT * stimerPercentageRate;
  const totalCommissionsVAT = priceHelpers.getVAT(totalCommissionsWithoutVAT);
  const totalCommissionsWithVAT = totalCommissionsWithoutVAT + totalCommissionsVAT;
  return {
    variableFeeWithoutVAT,
    refurbishmentWithoutVAT,
    warrantyExtensionWithoutVAT,
    loanWithoutVAT,
    clientDeliveryWithoutVAT,
    totalCommissionsWithoutVAT,
    totalStimerWithoutVAT,
    totalCommissionsVAT,
    totalCommissionsWithVAT,
  };
}

function generateCommissionLine(
  label: string,
  priceWithoutVAT: number,
  ignoreVAT: boolean
): CommissionLine {
  const VAT = ignoreVAT ? 0 : priceHelpers.getVAT(priceWithoutVAT);
  const priceWithVAT = priceWithoutVAT + VAT;
  return {
    label,
    priceWithoutVAT,
    VAT,
    priceWithVAT,
  };
}

function computeStimerCommissions(
  commissions: Commissions,
  ignoreVAT: boolean
): readonly CommissionLine[] {
  return [
    generateCommissionLine(
      t('details:tabs.marketplace.stimer.commissions.variableFee', {
        stimerPercentage: DEFAULT_STIMER_COMMISSION_RATE * 100,
      }),
      commissions.variableFeeWithoutVAT,
      ignoreVAT
    ),
    generateCommissionLine(
      t('details:tabs.marketplace.stimer.commissions.refurbishment', {
        stimerPercentage: DEFAULT_STIMER_COMMISSION_RATE * 100 * DEFAULT_REFURBISH_MARGIN_RATE,
      }),
      commissions.refurbishmentWithoutVAT,
      ignoreVAT
    ),
    generateCommissionLine(
      t('details:tabs.marketplace.stimer.commissions.warrantyExtension', {
        stimerPercentage: DEFAULT_STIMER_COMMISSION_RATE * 100,
      }),
      commissions.warrantyExtensionWithoutVAT,
      ignoreVAT
    ),
    generateCommissionLine(
      t('details:tabs.marketplace.stimer.commissions.loan', {
        stimerPercentage: DEFAULT_STIMER_COMMISSION_RATE * 100,
      }),
      commissions.loanWithoutVAT,
      ignoreVAT
    ),
    generateCommissionLine(
      t('details:tabs.marketplace.stimer.commissions.clientDelivery', {
        stimerPercentage: DEFAULT_STIMER_COMMISSION_RATE * 100,
      }),
      commissions.clientDeliveryWithoutVAT,
      ignoreVAT
    ),
  ];
}

function computeAvailableStimerValues(
  availableStimer: readonly AvailableStimer[]
): SimpleOption<string>[] {
  return availableStimer.map((ref) => ({
    label: ref.name,
    value: ref.id,
  }));
}

function computeMarketplaceStimerOverview(
  invoiceId: string,
  stimerInfos: StimerInfos,
  stimerPercentage: string,
  commissions: Commissions
): MarketplaceStimerOverview {
  const commissionLines = computeStimerCommissions(commissions, stimerInfos.ignoreVAT);
  return {
    invoiceId,
    stimerInfos,
    stimerPercentage,
    commissions: commissionLines,
    totalStimerWithoutVAT: commissions.totalStimerWithoutVAT,
    totalCommissionsWithoutVAT: commissions.totalCommissionsWithoutVAT,
    totalCommissionsVAT: commissions.totalCommissionsVAT,
    totalCommissionsWithVAT: commissions.totalCommissionsWithVAT,
  };
}

export interface MarketplaceStimerProps {
  readonly $: StoreStateSelector<Store, MarketplaceStimerState>;
  readonly $gs: GlobalStoreStateSelector<Store>;
  readonly $selectedKanban: StoreStateSelector<Store, Kanban>;
}

export function MarketplaceStimer({
  $,
  $gs,
  $selectedKanban,
}: MarketplaceStimerProps): JSX.Element {
  const [t] = useTranslation('details');

  const isOnline = useGetState($gs.$session.$isOnline);
  const kanban = useGetState($selectedKanban);
  const kanbanId = useGetState($selectedKanban.$id);
  const stimerId = useGetState($.$stimerId);
  const percentage = useGetState($.$stimerPercentage);
  const availableStimers = useGetState($.$availableStimers);

  const percentageRate = useMemo(() => Number.parseFloat(percentage) / 100, [percentage]);
  const commissions: Commissions = useMemo(() => {
    return computeCommissionValues(
      kanban.packageDeals,
      kanban.marketplaceInfos?.warrantyExtension ?? MKTP_WARRANTY_EXTENSION_NOT_SPECIFIED,
      kanban.marketplaceInfos?.loanAmount ?? 0,
      percentageRate
    );
  }, [kanban.packageDeals, kanban.marketplaceInfos, percentageRate]);

  const stimerSuggestions = useMemo(
    () => computeAvailableStimerValues(availableStimers),
    [availableStimers]
  );

  const openSaveConfirmationModalActionCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.scopeProperty('saveConfirmationDialog').setProperty('active', true);
    },
    [],
    $
  );

  const saveStimerValuesToKanban = useActionCallback(
    async ({ actionDispatch, kanbanRepository }): Promise<void> => {
      const updatedKanban = applyPayload(kanban, {
        marketplaceInfos: {
          stimerId,
          stimerPercentageRate: percentageRate,
        },
      });
      // Update the kanban in the repository if needed
      const payload = computePayload(kanban, updatedKanban);
      if (payload !== undefined) {
        await kanbanRepository.updateEntityFromPayload({ entityId: kanbanId, payload });
      }
      actionDispatch.scopeProperty('saveConfirmationDialog').setProperty('active', false);
    },
    [kanbanId, kanban, stimerId, percentageRate],
    $
  );

  const generateStimerInvoiceActionCallback = useActionCallback(
    async ({ actionDispatch, httpClient }): Promise<void> => {
      await actionDispatch.execCallback(saveStimerValuesToKanban);
      const invoiceId = await httpClient.httpPostAsJSON<object, string>(
        CoreBackendRoutes.GET_AND_INCREMENT_STIMER_INVOICE_ID(stimerId),
        {},
        'POST'
      );
      const stimerInfos: StimerInfos = await httpClient.httpGetAsJson<StimerInfos>(
        CoreBackendRoutes.GET_STIMER(stimerId)
      );
      const stimerOverview: MarketplaceStimerOverview = computeMarketplaceStimerOverview(
        invoiceId,
        stimerInfos,
        percentage,
        commissions
      );
      await httpClient.httpPostAsJSON<MarketplaceOverviewRequest, null>(
        CoreBackendRoutes.CREATE_MARKETPLACE_OVERVIEW_DOC(kanbanId, MARKETPLACE_DOC_TYPE_STIMER),
        { stimerOverview },
        'POST'
      );
      actionDispatch.scopeProperty('overviewButtonDialog').setProperty('active', true);
    },
    [kanbanId, commissions, stimerId, percentage, saveStimerValuesToKanban],
    $
  );

  const closeOverviewGenerationModalActionCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.scopeProperty('overviewButtonDialog').setProperty('active', false);
    },
    [],
    $
  );

  const loadValuesAsyncEffect = useActionCallback(
    async ({ actionDispatch, httpClient }): Promise<void> => {
      const availableStimers = await httpClient.httpGetAsJson<AvailableStimer[]>(
        CoreBackendRoutes.GET_AVAILABLE_STIMERS
      );
      actionDispatch.applyPayload({
        stimerId: kanban?.marketplaceInfos?.stimerId ?? '',
        stimerPercentage: String((kanban?.marketplaceInfos?.stimerPercentageRate ?? 0) * 100),
        availableStimers,
      });
    },
    [kanban],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadValuesAsyncEffect();
  }, [kanban, loadValuesAsyncEffect]);

  return (
    <>
      <ModalCardDialog
        $active={$.$overviewButtonDialog.$active}
        title={t('tabs.marketplace.overview.modal.generationModalTitle')}
        onOkClicked={closeOverviewGenerationModalActionCallback}
        dontShowCancelButton
      >
        <p>{t('tabs.marketplace.overview.modal.generationModalMessage')}</p>
      </ModalCardDialog>
      <ModalCardDialog
        $active={$.$saveConfirmationDialog.$active}
        title={t('tabs.marketplace.overview.modal.saveConfirmationModalTitle')}
        onOkClicked={saveStimerValuesToKanban}
        dontShowCancelButton
      >
        <p>{t('tabs.marketplace.overview.modal.saveConfirmationModalMessage')}</p>
      </ModalCardDialog>
      <div className="m-2">
        <div className="field is-grouped is-flex is-align-items-flex-end is-justify-content-center">
          <p className="control">
            <ReactSelectFormField
              label={t('tabs.marketplace.stimer.stimerId')}
              $={$.$stimerId}
              suggestions={stimerSuggestions}
            />
          </p>
          <p className="control">
            <InputFormField
              $={$.$stimerPercentage}
              type="number"
              min="0"
              max="100"
              label={t('tabs.marketplace.stimer.percentage')}
            />
          </p>
          <p className="control">
            <Button
              iconId="save"
              label={t('tabs.marketplace.saveButton')}
              onClick={openSaveConfirmationModalActionCallback}
              additionalClass="is-primary"
              disabled={!isOnline}
            />
          </p>
          <p className="control">
            <Button
              iconId="dollar-sign"
              label={t('tabs.marketplace.stimer.generateInvoiceButton')}
              onClick={generateStimerInvoiceActionCallback}
              additionalClass="is-primary"
              disabled={!isOnline}
            />
          </p>
        </div>
      </div>
      <table className="table is-striped is-bordered is-hoverable is-fullwidth mx-2" role="none">
        <thead>
          <tr>
            <th colSpan={2} className="has-text-centered">
              {t('tabs.marketplace.stimer.commissionsTitle')}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr key="variableFee">
            <td className="is-size-6">
              {t('tabs.marketplace.stimer.commissions.variableFee', {
                stimerPercentage: DEFAULT_STIMER_COMMISSION_RATE * 100,
              })}
            </td>
            <td className="is-size-6 has-text-right">
              {formatPrice(commissions.variableFeeWithoutVAT, 2)}
            </td>
          </tr>
          <tr key="refurbishment">
            <td className="is-size-6 ">
              {t('tabs.marketplace.stimer.commissions.refurbishment', {
                stimerPercentage:
                  DEFAULT_STIMER_COMMISSION_RATE * 100 * DEFAULT_REFURBISH_MARGIN_RATE,
              })}
            </td>
            <td className="is-size-6 has-text-right">
              {formatPrice(commissions.refurbishmentWithoutVAT, 2)}
            </td>
          </tr>
          <tr key="warrantyExtension">
            <td className="is-size-6 ">
              {t('tabs.marketplace.stimer.commissions.warrantyExtension', {
                stimerPercentage: DEFAULT_STIMER_COMMISSION_RATE * 100,
              })}
            </td>
            <td className="is-size-6 has-text-right">
              {formatPrice(commissions.warrantyExtensionWithoutVAT, 2)}
            </td>
          </tr>
          <tr key="loan">
            <td className="is-size-6 ">
              {t('tabs.marketplace.stimer.commissions.loan', {
                stimerPercentage: DEFAULT_STIMER_COMMISSION_RATE * 100,
              })}
            </td>
            <td className="is-size-6 has-text-right">
              {formatPrice(commissions.loanWithoutVAT, 2)}
            </td>
          </tr>
          <tr key="clientDelivery">
            <td className="is-size-6 ">
              {t('tabs.marketplace.stimer.commissions.clientDelivery', {
                stimerPercentage: DEFAULT_STIMER_COMMISSION_RATE * 100,
              })}
            </td>
            <td className="is-size-6 has-text-right">
              {formatPrice(commissions.clientDeliveryWithoutVAT, 2)}
            </td>
          </tr>
          <tr key="totalCommissionsWithoutVAT">
            <td className="is-size-6 has-text-weight-bold has-text-right">
              {t('tabs.marketplace.stimer.commissions.totalCommissionsWithoutVAT')}
            </td>
            <td className="is-size-6 has-text-weight-bold has-text-right">
              {formatPrice(commissions.totalCommissionsWithoutVAT, 2)}
            </td>
          </tr>
          <tr key="finalTotalCommissionsWithoutVAT">
            <td className="is-size-6 has-text-weight-bold has-text-right">
              {t('tabs.marketplace.stimer.commissions.totalVAT')}
            </td>
            <td className="is-size-6 has-text-weight-bold has-text-right">
              {formatPrice(commissions.totalCommissionsVAT, 2)}
            </td>
          </tr>
          <tr key="finalTotalCommissionsWithoutVAT">
            <td className="is-size-6 has-text-weight-bold has-text-right">
              {t('tabs.marketplace.stimer.commissions.totalPriceWithVAT')}
            </td>
            <td className="is-size-6 has-text-weight-bold has-text-right">
              {formatPrice(commissions.totalCommissionsWithVAT, 2)}
            </td>
          </tr>
          <tr key="finalTotalCommissionsWithoutVAT">
            <td className="is-size-6 has-text-weight-bold has-text-right">
              {t('tabs.marketplace.stimer.commissions.totalStimerWithoutVAT', {
                percentage,
              })}
            </td>
            <td className="is-size-6 has-text-weight-bold has-text-right">
              {formatPrice(commissions.totalStimerWithoutVAT, 2)}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
