import type { JSX } from 'react';
import React, { useMemo } from 'react';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AddOnButton, AddOnButtonsProps, FaIconSize } from '@stimcar/libs-uitoolkit';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { AddOnButtons } from '@stimcar/libs-uitoolkit';
import type { ChoiceButtonDef } from './ChoiceAddOnButtons.js';
import { convertChoiceButtonDefToAddOnButton } from './ChoiceAddOnButtons.js';

export interface BinaryChoiceAddOnButtonsProps<SD extends AnyStoreDef> {
  readonly $: StoreStateSelector<SD, string>;
  readonly leftButton: ChoiceButtonDef;
  readonly rightButton: ChoiceButtonDef;
  readonly size?: FaIconSize;
  readonly disabled?: boolean;
}

// eslint-disable-next-line @typescript-eslint/promise-function-async
const NOOP = async (): Promise<void> => {
  // no op
};

export function BinaryChoiceAddOnButtons<SD extends AnyStoreDef>({
  $,
  leftButton,
  rightButton,
  disabled = false,
  size,
}: BinaryChoiceAddOnButtonsProps<SD>): JSX.Element {
  const value = useGetState($);
  const setValueActionCallback = useActionCallback(
    ({ actionDispatch }, newValue: string) => {
      actionDispatch.setValue(newValue);
    },
    [],
    $
  );
  const onClick = useMemo(() => {
    return disabled ? NOOP : setValueActionCallback;
  }, [disabled, setValueActionCallback]);
  const buttons = useMemo(
    (): readonly AddOnButton[] => [
      convertChoiceButtonDefToAddOnButton(leftButton, 'is-success', value),
      convertChoiceButtonDefToAddOnButton(rightButton, 'is-danger', value),
    ],
    [leftButton, rightButton, value]
  );
  return (
    <AddOnButtons
      onClick={onClick as AddOnButtonsProps['onClick']}
      buttons={buttons}
      size={size}
      disabled={disabled}
      isRounded
    />
  );
}
