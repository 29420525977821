import type { SparePartsProvider } from '@stimcar/libs-base';
import type { ActionCallbackFromFunction, BusinessObjectToFormData } from '@stimcar/libs-uikernel';
import type { FormWithValidationState } from '@stimcar/libs-uitoolkit';
import type { TableState } from '../../../../lib/bulma/elements/table/typings/store.js';
import type { Store } from '../../../state/typings/store.js';
import { TABLE_EMPTY_STATE } from '../../../../lib/bulma/elements/table/typings/store.js';

export type OnSparePartProviderChangeActionCallback = ActionCallbackFromFunction<
  Store,
  (addedOrUpdated: readonly SparePartsProvider[], removedIds: readonly string[]) => void
>;

export type EditSparePartsProviderData = BusinessObjectToFormData<SparePartsProvider> & {
  readonly sparePartMargin: string;
};

export const EMPTY_CREATE_SPAREPARTS_PROVIDER_DATA: EditSparePartsProviderData = {
  id: '',
  sparePartMargin: '',
};

export type EditSparePartsProviderDialogState =
  FormWithValidationState<EditSparePartsProviderData> & {
    readonly active: boolean;
    readonly mode: 'create' | 'update';
  };

export const EMPTY_EDIT_SPAREPARTS_PROVIDER_DIALOG_STATE: EditSparePartsProviderDialogState = {
  active: false,
  mode: 'create',
  formSubmitted: false,
  formSubmitClickedOnce: false,
  formWarning: undefined,
  formData: {
    ...EMPTY_CREATE_SPAREPARTS_PROVIDER_DATA,
    warnings: {},
  },
};

export type DeleteSparePartsProviderDialogState = {
  readonly active: boolean;
  readonly providerId: string;
};

export const EMPTY_DELETE_SPAREPARTS_PROVIDER_DIALOG_STATE: DeleteSparePartsProviderDialogState = {
  active: false,
  providerId: '',
};

export type AdminSparePartsProvidersState = TableState<SparePartsProvider> & {
  readonly editSparePartsProviderDialogState: EditSparePartsProviderDialogState;
  readonly deleteSparePartsProviderDialogState: DeleteSparePartsProviderDialogState;
};

export const EMPTY_ADMIN_SPAREPARTS_PROVIDERS_STATE: AdminSparePartsProvidersState = {
  ...TABLE_EMPTY_STATE,
  editSparePartsProviderDialogState: EMPTY_EDIT_SPAREPARTS_PROVIDER_DIALOG_STATE,
  deleteSparePartsProviderDialogState: EMPTY_DELETE_SPAREPARTS_PROVIDER_DIALOG_STATE,
};
