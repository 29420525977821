import type { JSX } from 'react';
import React, { useCallback, useMemo } from 'react';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { chunkArrayInNParts } from '@stimcar/libs-base';
import { useActionCallback } from '@stimcar/libs-uikernel';

interface NumericKeyboardProps<SD extends AnyStoreDef> {
  readonly $: StoreStateSelector<SD, string | undefined>;
  readonly isOneLine?: boolean;
}

const numValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0] as const;

export function NumericKeyboard<SD extends AnyStoreDef>({
  $,
  isOneLine = false,
}: NumericKeyboardProps<SD>): JSX.Element {
  const addNumberValueAction = useActionCallback(
    ({ actionDispatch, getState }, input: number) => {
      const value = getState();
      actionDispatch.setValue(`${value ?? ''}${input}`);
    },
    [],
    $
  );

  const values = useMemo(() => {
    if (isOneLine) {
      return [numValues];
    }
    return chunkArrayInNParts(numValues, 2);
  }, [isOneLine]);

  return (
    <>
      {values.map(
        (numbers, i): JSX.Element => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="columns" key={i}>
            {numbers.map(
              (n): JSX.Element => (
                <SingleNumButton
                  key={n}
                  addNumberValueAction={addNumberValueAction}
                  value={n}
                  isOneLine={isOneLine}
                />
              )
            )}
          </div>
        )
      )}
    </>
  );
}

interface SingleNumericButtonProps {
  readonly value: number;
  readonly addNumberValueAction: (input: number) => Promise<void>;
  readonly isOneLine: boolean;
}

function SingleNumButton({
  value,
  addNumberValueAction,
  isOneLine,
}: SingleNumericButtonProps): JSX.Element {
  const clickHandler = useCallback(async (): Promise<void> => {
    await addNumberValueAction(value);
  }, [addNumberValueAction, value]);

  return (
    <div
      className="column is-narrow"
      style={isOneLine ? { paddingLeft: '0.2rem', paddingRight: '0.2rem' } : {}}
    >
      <button
        type="button"
        className={`button is-rounded is-primary is-light ${isOneLine ? 'is-smaller' : ''}`}
        onClick={clickHandler}
      >
        {value}
      </button>
    </div>
  );
}
