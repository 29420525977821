import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { useActionCallback } from '@stimcar/libs-uikernel';
import type { SubcontractorStore } from '../state/typings/store.js';
import { LoginComponent } from '../../lib/components/login/LoginComponent.js';
import { loginAction } from './utils.js';

export function SubcontractorLoginComponent({ $gs }: AppProps<SubcontractorStore>): JSX.Element {
  const [t] = useTranslation('subcontractor');
  const loginActionCallback = useActionCallback(loginAction, [], $gs.$loginView);
  return (
    <div className="columns">
      <div className="column is-one-third" />
      <div className="column is-one-third">
        <h1 className="title">{t('connect')}</h1>
        <LoginComponent $={$gs.$loginView} loginActionCallback={loginActionCallback} />
      </div>
      <div className="column is-one-third" />
    </div>
  );
}
