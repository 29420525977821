import { EMPTY_REGISTERED_APP_STORE_STATE } from '../../registeredapp/state/store.js';
import type { DisplayStoreState } from './typings/store.js';

// Empty state
export const EMPTY_DISPLAY_STORE_STATE: DisplayStoreState = {
  ...EMPTY_REGISTERED_APP_STORE_STATE,
  navBar: {
    burgerIsActive: false,
  },
};
