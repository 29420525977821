import type { PackageDealDesc } from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { Store, StoreState } from '../typings/store.js';
import { updateAdminPackageDealDescViewStateFromSSEAction } from '../../admin/packageDealDesc/AdminPackageDealDescs.js';

export async function packageDealDescRepositoryEventListenerAction(
  { actionDispatch }: ActionContext<Store, StoreState>,
  updatedEntities: readonly PackageDealDesc[],
  addedEntities: readonly PackageDealDesc[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  archivedEntities: readonly string[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  closedEntities: readonly string[]
) {
  await actionDispatch
    .scopeProperty('adminView')
    .scopeProperty('adminPackageDealDescs')
    .exec(
      updateAdminPackageDealDescViewStateFromSSEAction,
      [...updatedEntities, ...addedEntities],
      [...archivedEntities, ...closedEntities]
    );
}
