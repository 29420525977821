import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  AirtableConnectorArgs,
  CarsExpectedAtStandMailNotificationArgs,
  ColumnToDestinationDef,
  CustomersBasedMailNotificationArgs,
  DailyProdMailNotificationArgs,
  InvoiceMailNotificationArgs,
  MailNotificationArgs,
  ScheduledTask,
  ScheduledTaskSchedule,
  SparePartsMailNotificationArgs,
  VOLeanConnectorArgs,
} from '@stimcar/libs-base';
import type {
  ActionContext,
  StoreStateSelector,
  WithFormValidationWarnings,
} from '@stimcar/libs-uikernel';
import type {
  AppProps,
  FormWithValidationState,
  HorizontalFormFieldProps,
} from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes, scheduledTaskHelpers } from '@stimcar/libs-base';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { LabelledEntity } from '../../../lib/components/LabelledEntityList.js';
import type { Store } from '../../state/typings/store.js';
import type {
  AdminScheduledTasksState,
  AirtableConnectorArgsData,
  BaseScheduledTaskDataForUpdate,
  CarsExpectedAtStandMailNotificationArgsData,
  CustomersBasedMailNotificationArgsData,
  DailyProdMailNotificationArgsData,
  InvoiceMailNotificationArgsData,
  MailNotificationArgsData,
  OnScheduledTaskChangeActionCallback,
  ScheduledTaskIdentityData,
  ScheduledTaskScheduleData,
  SparePartsMailNotificationArgsData,
  UpdateImportAirtableDialogState,
  UpdateScheduledTaskDialogState,
  VOLeanConnectorArgsData,
} from './typings/store.js';
import {
  checkEmailFieldContentActions,
  checkImportAirtableFieldContentActions,
  checkInvoiceEmailFieldContentActions,
  checkLabelFieldContentActions,
  getActualCustomerShortnames,
  getActualScheduleDays,
  getArgumentsMandatoryFields,
  getScheduledTaskSchedule,
  SCHEDULE_MANDATORY_FIELDS,
} from './adminScheduledTasksUtils.js';
import { ImportAirtableInputs } from './inputs/ImportAirtableInputs.js';
import { ImportVOLeanInputs } from './inputs/ImportVOLeanInputs.js';
import {
  CarsExpectedAtStandMailInputs,
  CustomersBasedMailInputs,
  DailyProdMailInputs,
  InvoiceMailInputs,
  SparePartsMailInputs,
} from './inputs/MailTypedScheduledTaskInputs.js';
import { ScheduledTaskIdentityForUpdateInputs } from './inputs/ScheduledTaskIdentityInputs.js';
import { ScheduledTaskScheduleInputs } from './inputs/ScheduledTaskScheduleInputs.js';
import { EMPTY_UPDATE_SCHEDULED_TASK_DIALOG_STATE } from './typings/store.js';

const BASE_MANDATORY_FIELDS: (keyof BaseScheduledTaskDataForUpdate)[] = [
  'label',
  'scheduledWeekDays',
  'scheduledTaskActive',
];

async function updateScheduledTaskAction(
  { getState, httpClient, actionDispatch }: ActionContext<Store, AdminScheduledTasksState>,
  onScheduledTaskChangeActionCallback: OnScheduledTaskChangeActionCallback
): Promise<void> {
  const { formData, siteId, type, scheduledTaskId } = getState().updateScheduledTaskDialogState;
  const { label, scheduledTaskActive } = formData;
  const { scheduledWeekDays, scheduledHours, scheduledMinutes, asOftenAsPossibleWithinADay } =
    formData;
  const scheduledTaskScheduleData: ScheduledTaskScheduleData = {
    scheduledWeekDays: getActualScheduleDays(scheduledWeekDays),
    scheduledHours,
    scheduledMinutes,
    asOftenAsPossibleWithinADay,
  };
  const schedule: ScheduledTaskSchedule = getScheduledTaskSchedule(scheduledTaskScheduleData);
  const taskToUpdateWithoutArgs: Omit<ScheduledTask, 'id' | 'cronArgs'> = {
    type,
    label,
    schedule,
    active: scheduledTaskActive,
  };
  let taskToUpdate: Omit<ScheduledTask, 'id'> | undefined;
  if (scheduledTaskHelpers.isEmailScheduledTaskType(type)) {
    const { to, cc, subject, overrideDefaultSubject, replyTo } =
      formData as WithFormValidationWarnings<
        BaseScheduledTaskDataForUpdate & MailNotificationArgsData
      >;
    const mailNotificationArgs: MailNotificationArgs = {
      to,
      cc,
      subject: overrideDefaultSubject ? subject : undefined,
      replyTo: isTruthyAndNotEmpty(replyTo) ? replyTo : undefined,
    };

    let actualEmailCronArgs:
      | CustomersBasedMailNotificationArgs
      | InvoiceMailNotificationArgs
      | DailyProdMailNotificationArgs
      // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
      | SparePartsMailNotificationArgs
      | CarsExpectedAtStandMailNotificationArgs;
    switch (type) {
      case 'invoiceMail': {
        const { replyTo: invoiceReplyTo, customerShortNames } =
          formData as WithFormValidationWarnings<
            BaseScheduledTaskDataForUpdate & InvoiceMailNotificationArgsData
          >;
        const actualCustomerShortNames = getActualCustomerShortnames(customerShortNames);
        actualEmailCronArgs = {
          ...mailNotificationArgs,
          replyTo: invoiceReplyTo,
          customerShortNames: actualCustomerShortNames,
        };
        break;
      }
      case 'ctmail': {
        const { customersGroupName, customerShortNames } = formData as WithFormValidationWarnings<
          BaseScheduledTaskDataForUpdate & CustomersBasedMailNotificationArgsData
        >;
        const actualCustomerShortNames = getActualCustomerShortnames(customerShortNames);
        const customersBasedMailNotificationArgs: CustomersBasedMailNotificationArgs = {
          ...mailNotificationArgs,
          customerShortNames: actualCustomerShortNames,
          customersGroupName,
        };
        actualEmailCronArgs = customersBasedMailNotificationArgs;
        break;
      }
      case 'dailyprodmail': {
        const {
          standId,
          displayEstimateInfos,
          displayAcceptanceInfos,
          customersGroupName,
          customerShortNames,
        } = formData as WithFormValidationWarnings<
          BaseScheduledTaskDataForUpdate & DailyProdMailNotificationArgsData
        >;
        const actualCustomerShortNames = getActualCustomerShortnames(customerShortNames);
        const customersBasedMailNotificationArgs: CustomersBasedMailNotificationArgs = {
          ...mailNotificationArgs,
          customerShortNames: actualCustomerShortNames,
          customersGroupName,
        };
        actualEmailCronArgs = {
          ...customersBasedMailNotificationArgs,
          standId,
          displayEstimateInfos,
          displayAcceptanceInfos,
        };
        break;
      }
      case 'sparepartsmail': {
        const { customersGroupName, customerShortNames } = formData as WithFormValidationWarnings<
          BaseScheduledTaskDataForUpdate & SparePartsMailNotificationArgsData
        >;
        const actualCustomerShortNames = getActualCustomerShortnames(customerShortNames);
        const customersBasedMailNotificationArgs: CustomersBasedMailNotificationArgs = {
          ...mailNotificationArgs,
          customerShortNames: actualCustomerShortNames,
          customersGroupName,
        };
        actualEmailCronArgs = {
          ...customersBasedMailNotificationArgs,
        };
        break;
      }
      case 'carsexpectedatstandmail': {
        const { standId, mode, customersGroupName, customerShortNames, tagsFilteringPkgs } =
          formData as WithFormValidationWarnings<
            BaseScheduledTaskDataForUpdate & CarsExpectedAtStandMailNotificationArgsData
          >;
        const actualCustomerShortNames = getActualCustomerShortnames(customerShortNames);
        const customersBasedMailNotificationArgs: CustomersBasedMailNotificationArgs = {
          ...mailNotificationArgs,
          customerShortNames: actualCustomerShortNames,
          customersGroupName,
        };
        actualEmailCronArgs = {
          ...customersBasedMailNotificationArgs,
          standId,
          mode,
          tagsFilteringPkgs,
        };
        break;
      }
      default:
        throw new Error('Unknown email task type');
    }
    taskToUpdate = {
      ...taskToUpdateWithoutArgs,
      scheduledTaskArgs: actualEmailCronArgs,
    };
  } else if (type === 'airtableImport') {
    const {
      customColumnDialogState,
      documentColumnsToDownloadState,
      textToDocumentColumnsToDownloadState,
      textColumnsToAttributesState,
    } = getState().updateScheduledTaskDialogState as UpdateImportAirtableDialogState;
    const {
      tableNames,
      contractCode,
      token,
      databaseId,
      databaseName,
      workflowId,
      customerAsFieldMode,
      to,
      cc,
      replyTo,
      subject,
      overrideDefaultSubject,
    } = formData as WithFormValidationWarnings<
      BaseScheduledTaskDataForUpdate & AirtableConnectorArgsData
    >;
    const mailNotificationArgs: MailNotificationArgs | undefined =
      to.length > 0
        ? {
            to,
            cc,
            replyTo: isTruthyAndNotEmpty(replyTo) ? replyTo : undefined,
            subject: overrideDefaultSubject ? subject : undefined,
          }
        : undefined;
    const airtableImportCronArgument: AirtableConnectorArgs = {
      tableNames,
      contractCode,
      token,
      databaseId,
      databaseName,
      workflowId,
      customerAsFieldMode,
      packageDealColumnsToHandle: customColumnDialogState.customColumns,
      documentColumnsToDownload: documentColumnsToDownloadState.columnsToDestinations,
      textToDocumentColumnsToDownload: textToDocumentColumnsToDownloadState.columnsToDestinations,
      textColumnsToAttributes: textColumnsToAttributesState.columnsToDestinations,
      importErrorMail: mailNotificationArgs,
    };
    taskToUpdate = {
      ...taskToUpdateWithoutArgs,
      scheduledTaskArgs: airtableImportCronArgument,
    };
  } else if (type === 'voLeanImport') {
    const {
      contractCode,
      workflowId,
      voLeanClientId,
      voLeanPole,
      enablePkgDealsModifications,
      qualityControlInVOLean,
      to,
      cc,
      replyTo,
      subject,
      overrideDefaultSubject,
    } = formData as WithFormValidationWarnings<
      BaseScheduledTaskDataForUpdate & VOLeanConnectorArgsData
    >;
    const mailNotificationArgs: MailNotificationArgs | undefined =
      to.length > 0
        ? {
            to,
            cc,
            replyTo: isTruthyAndNotEmpty(replyTo) ? replyTo : undefined,
            subject: overrideDefaultSubject ? subject : undefined,
          }
        : undefined;
    const voLeanImportCronArgument: VOLeanConnectorArgs = {
      contractCode,
      workflowId,
      voLeanClientId,
      voLeanPole,
      enablePkgDealsModifications,
      qualityControlInVOLean,
      importErrorMail: mailNotificationArgs,
    };
    taskToUpdate = {
      ...taskToUpdateWithoutArgs,
      scheduledTaskArgs: voLeanImportCronArgument,
    };
  } else {
    throw Error(`Unexpected task type: ${type}`);
  }
  await httpClient.httpPostAsJSON(CoreBackendRoutes.UPDATE_SCHEDULED_TASK(scheduledTaskId), {
    siteId,
    taskToUpdate,
  });

  await actionDispatch.execCallback(
    onScheduledTaskChangeActionCallback,
    [{ ...taskToUpdate, id: scheduledTaskId }],
    []
  );
  actionDispatch.setProperty(
    'updateScheduledTaskDialogState',
    EMPTY_UPDATE_SCHEDULED_TASK_DIALOG_STATE
  );
}

function columnsToDestinationsAsEntities(
  columnsToDestinations: readonly ColumnToDestinationDef[]
): LabelledEntity[] {
  return columnsToDestinations.map(({ id, destination }) => ({
    id,
    label: `${id} => ${destination}`,
  }));
}

export function UpdateImportAirtableScheduledTaskModal({
  $,
  $gs,
  horizontalFormFields,
  onScheduledTaskChangeActionCallback,
  isEditionForbidden,
}: UpdateScheduledTaskProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');
  const $updateScheduledTaskDialogState = $.$updateScheduledTaskDialogState as StoreStateSelector<
    Store,
    UpdateImportAirtableDialogState
  >;
  const formWarning = useGetState($updateScheduledTaskDialogState.$formWarning);
  const type = useGetState($.$updateScheduledTaskDialogState.$type);
  const customColumns = useGetState(
    $updateScheduledTaskDialogState.$customColumnDialogState.$customColumns
  );
  const documentColumnsToDownload = useGetState(
    $updateScheduledTaskDialogState.$documentColumnsToDownloadState.$columnsToDestinations
  );
  const textToDocColumnsToDownload = useGetState(
    $updateScheduledTaskDialogState.$textToDocumentColumnsToDownloadState.$columnsToDestinations
  );
  const textColumnsToAttributes = useGetState(
    $updateScheduledTaskDialogState.$textColumnsToAttributesState.$columnsToDestinations
  );
  const allWorkflowIds = useGetState($updateScheduledTaskDialogState.$allWorkflowIds);
  const allCustomerSummaries = useGetState($updateScheduledTaskDialogState.$allCustomerSummaries);

  const mandatoryFields = useMemo((): (
    | keyof BaseScheduledTaskDataForUpdate
    | keyof AirtableConnectorArgsData
  )[] => {
    return [
      ...BASE_MANDATORY_FIELDS,
      ...SCHEDULE_MANDATORY_FIELDS,
      ...(getArgumentsMandatoryFields(
        type,
        false,
        undefined
      ) as (keyof AirtableConnectorArgsData)[]),
    ];
  }, [type]);

  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) =>
      await actionDispatch.exec(updateScheduledTaskAction, onScheduledTaskChangeActionCallback),
    [onScheduledTaskChangeActionCallback],
    $
  );

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    FormWithValidationState<
      ScheduledTaskIdentityData & ScheduledTaskScheduleData & AirtableConnectorArgsData
    >
  >({
    $: $updateScheduledTaskDialogState,
    mandatoryFields,
    checkFieldContentActions: {
      ...checkImportAirtableFieldContentActions,
      ...checkLabelFieldContentActions,
      ...checkLabelFieldContentActions,
    },
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });
  const customColumnsAsEntities = useMemo(
    (): LabelledEntity[] =>
      customColumns.map((o) => ({
        label: o.id,
        ...o,
      })),
    [customColumns]
  );

  const documentColumnsToDownloadAsEntities = useMemo(
    (): LabelledEntity[] => columnsToDestinationsAsEntities(documentColumnsToDownload),
    [documentColumnsToDownload]
  );

  const textToDocColumnsToDownloadAsEntities = useMemo(
    (): LabelledEntity[] => columnsToDestinationsAsEntities(textToDocColumnsToDownload),
    [textToDocColumnsToDownload]
  );

  const textColumnsToAttributesAsEntities = useMemo(
    (): LabelledEntity[] => columnsToDestinationsAsEntities(textColumnsToAttributes),
    [textColumnsToAttributes]
  );

  return (
    <ModalCardDialog
      $active={$updateScheduledTaskDialogState.$active}
      titleIconId="exclamation-triangle"
      title={t('updateTitle')}
      onOkClicked={onFormSubmit}
      warning={formWarning}
      isReadonly={isEditionForbidden}
    >
      <ScheduledTaskIdentityForUpdateInputs
        $formData={$formDataWithChangeTrigger}
        type={type}
        horizontalFormFields={horizontalFormFields}
      />
      <ScheduledTaskScheduleInputs
        $formData={$formDataWithChangeTrigger}
        horizontalFormFields={horizontalFormFields}
      />
      <ImportAirtableInputs
        $={$updateScheduledTaskDialogState}
        $gs={$gs}
        $formData={$formDataWithChangeTrigger}
        allCustomerSummaries={allCustomerSummaries}
        allWorkflowIds={allWorkflowIds ?? []}
        packageDealColumnsToHandleEntries={customColumnsAsEntities}
        horizontalFormFields={horizontalFormFields}
        documentColumnsToDownloadEntries={documentColumnsToDownloadAsEntities}
        textToDocColumnsToDownloadEntries={textToDocColumnsToDownloadAsEntities}
        textColumnsToAttributesEntries={textColumnsToAttributesAsEntities}
      />
    </ModalCardDialog>
  );
}

export function UpdateImportVOLeanScheduledTaskModal({
  $,
  $gs,
  horizontalFormFields,
  onScheduledTaskChangeActionCallback,
  isEditionForbidden,
}: UpdateScheduledTaskProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');
  const { $updateScheduledTaskDialogState } = $;

  const formWarning = useGetState($updateScheduledTaskDialogState.$formWarning);
  const type = useGetState($.$updateScheduledTaskDialogState.$type);
  const allWorkflowIds = useGetState(
    ($updateScheduledTaskDialogState as StoreStateSelector<Store, UpdateScheduledTaskDialogState>)
      .$allWorkflowIds
  );

  const mandatoryFields = useMemo((): (
    | keyof BaseScheduledTaskDataForUpdate
    | keyof VOLeanConnectorArgsData
  )[] => {
    return [
      ...BASE_MANDATORY_FIELDS,
      ...SCHEDULE_MANDATORY_FIELDS,
      ...(getArgumentsMandatoryFields(type, false, undefined) as (keyof VOLeanConnectorArgsData)[]),
    ];
  }, [type]);
  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) =>
      await actionDispatch.exec(updateScheduledTaskAction, onScheduledTaskChangeActionCallback),
    [onScheduledTaskChangeActionCallback],
    $
  );

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    FormWithValidationState<BaseScheduledTaskDataForUpdate & VOLeanConnectorArgsData>
  >({
    $: $updateScheduledTaskDialogState as StoreStateSelector<
      Store,
      FormWithValidationState<BaseScheduledTaskDataForUpdate & VOLeanConnectorArgsData>
    >,
    mandatoryFields,
    checkFieldContentActions: {
      ...checkLabelFieldContentActions,
    },
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });

  return (
    <ModalCardDialog
      $active={$updateScheduledTaskDialogState.$active}
      titleIconId="exclamation-triangle"
      title={t('updateTitle')}
      onOkClicked={onFormSubmit}
      warning={formWarning}
      isReadonly={isEditionForbidden}
    >
      <ScheduledTaskIdentityForUpdateInputs
        $formData={$formDataWithChangeTrigger}
        type={type}
        horizontalFormFields={horizontalFormFields}
      />
      <ScheduledTaskScheduleInputs
        $formData={$formDataWithChangeTrigger}
        horizontalFormFields={horizontalFormFields}
      />
      <ImportVOLeanInputs
        $formData={$formDataWithChangeTrigger}
        $gs={$gs}
        allWorkflowIds={allWorkflowIds ?? []}
        horizontalFormFields={horizontalFormFields}
      />
    </ModalCardDialog>
  );
}

export function UpdateInvoiceMailScheduledTaskModal({
  $,
  allCustomerShortnames,
  horizontalFormFields,
  onScheduledTaskChangeActionCallback,
  isEditionForbidden,
}: UpdateAllCustomerShornamesNeededScheduledTaskProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');
  const { $updateScheduledTaskDialogState } = $;
  const formWarning = useGetState($updateScheduledTaskDialogState.$formWarning);
  const type = useGetState($.$updateScheduledTaskDialogState.$type);
  const overrideDefaultSubject = useGetState(
    $updateScheduledTaskDialogState.$formData.$overrideDefaultSubject
  );

  const mandatoryFields = useMemo((): (
    | keyof BaseScheduledTaskDataForUpdate
    | keyof InvoiceMailNotificationArgsData
  )[] => {
    return [
      ...BASE_MANDATORY_FIELDS,
      ...SCHEDULE_MANDATORY_FIELDS,
      ...(getArgumentsMandatoryFields(
        type,
        overrideDefaultSubject,
        undefined
      ) as (keyof InvoiceMailNotificationArgsData)[]),
    ];
  }, [overrideDefaultSubject, type]);
  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) =>
      await actionDispatch.exec(updateScheduledTaskAction, onScheduledTaskChangeActionCallback),
    [onScheduledTaskChangeActionCallback],
    $
  );

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    FormWithValidationState<BaseScheduledTaskDataForUpdate & InvoiceMailNotificationArgsData>
  >({
    $: $updateScheduledTaskDialogState as StoreStateSelector<
      Store,
      FormWithValidationState<BaseScheduledTaskDataForUpdate & InvoiceMailNotificationArgsData>
    >,
    mandatoryFields,
    checkFieldContentActions: {
      ...checkEmailFieldContentActions,
      ...checkInvoiceEmailFieldContentActions,
      ...checkLabelFieldContentActions,
    },
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });

  return (
    <ModalCardDialog
      $active={$updateScheduledTaskDialogState.$active}
      titleIconId="exclamation-triangle"
      title={t('updateTitle')}
      onOkClicked={onFormSubmit}
      warning={formWarning}
      isReadonly={isEditionForbidden}
    >
      <ScheduledTaskIdentityForUpdateInputs
        $formData={$formDataWithChangeTrigger}
        type={type}
        horizontalFormFields={horizontalFormFields}
      />
      <ScheduledTaskScheduleInputs
        $formData={$formDataWithChangeTrigger}
        horizontalFormFields={horizontalFormFields}
      />
      <InvoiceMailInputs
        $formData={$formDataWithChangeTrigger}
        allCustomerShortnames={allCustomerShortnames}
        horizontalFormFields={horizontalFormFields}
      />
    </ModalCardDialog>
  );
}

export function UpdateCustomersBasedMailScheduledTaskModal({
  $,
  allCustomerShortnames,
  horizontalFormFields,
  onScheduledTaskChangeActionCallback,
  isEditionForbidden,
}: UpdateAllCustomerShornamesNeededScheduledTaskProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');
  const { $updateScheduledTaskDialogState } = $;

  const formWarning = useGetState($updateScheduledTaskDialogState.$formWarning);
  const type = useGetState($.$updateScheduledTaskDialogState.$type);
  const overrideDefaultSubject = useGetState(
    $updateScheduledTaskDialogState.$formData.$overrideDefaultSubject
  );
  const customerShortNames = useGetState(
    (
      $updateScheduledTaskDialogState.$formData as StoreStateSelector<
        Store,
        WithFormValidationWarnings<
          BaseScheduledTaskDataForUpdate & CustomersBasedMailNotificationArgsData
        >
      >
    ).$customerShortNames
  );

  const mandatoryFields = useMemo((): (
    | keyof BaseScheduledTaskDataForUpdate
    | keyof CustomersBasedMailNotificationArgsData
  )[] => {
    return [
      ...BASE_MANDATORY_FIELDS,
      ...SCHEDULE_MANDATORY_FIELDS,
      ...(getArgumentsMandatoryFields(
        type,
        overrideDefaultSubject,
        customerShortNames
      ) as (keyof CustomersBasedMailNotificationArgsData)[]),
    ];
  }, [customerShortNames, overrideDefaultSubject, type]);
  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) =>
      await actionDispatch.exec(updateScheduledTaskAction, onScheduledTaskChangeActionCallback),
    [onScheduledTaskChangeActionCallback],
    $
  );

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    FormWithValidationState<BaseScheduledTaskDataForUpdate & CustomersBasedMailNotificationArgsData>
  >({
    $: $updateScheduledTaskDialogState as StoreStateSelector<
      Store,
      FormWithValidationState<
        BaseScheduledTaskDataForUpdate & CustomersBasedMailNotificationArgsData
      >
    >,
    mandatoryFields,
    checkFieldContentActions: {
      ...checkEmailFieldContentActions,
      ...checkLabelFieldContentActions,
    },
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });

  return (
    <ModalCardDialog
      $active={$updateScheduledTaskDialogState.$active}
      titleIconId="exclamation-triangle"
      title={t('updateTitle')}
      onOkClicked={onFormSubmit}
      warning={formWarning}
      isReadonly={isEditionForbidden}
    >
      <ScheduledTaskIdentityForUpdateInputs
        $formData={$formDataWithChangeTrigger}
        type={type}
        horizontalFormFields={horizontalFormFields}
      />
      <ScheduledTaskScheduleInputs
        $formData={$formDataWithChangeTrigger}
        horizontalFormFields={horizontalFormFields}
      />
      <CustomersBasedMailInputs
        $formData={$formDataWithChangeTrigger}
        allCustomerShortnames={allCustomerShortnames}
        horizontalFormFields={horizontalFormFields}
      />
    </ModalCardDialog>
  );
}

export function UpdateDailyProdMailScheduledTaskModal({
  $,
  allCustomerShortnames,
  horizontalFormFields,
  onScheduledTaskChangeActionCallback,
  isEditionForbidden,
}: UpdateAllCustomerShornamesNeededScheduledTaskProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');
  const { $updateScheduledTaskDialogState } = $;

  const formWarning = useGetState($updateScheduledTaskDialogState.$formWarning);
  const type = useGetState($.$updateScheduledTaskDialogState.$type);
  const overrideDefaultSubject = useGetState(
    $updateScheduledTaskDialogState.$formData.$overrideDefaultSubject
  );
  const customerShortNames = useGetState(
    (
      $updateScheduledTaskDialogState.$formData as StoreStateSelector<
        Store,
        WithFormValidationWarnings<
          BaseScheduledTaskDataForUpdate & DailyProdMailNotificationArgsData
        >
      >
    ).$customerShortNames
  );
  const allStandIds = useGetState(
    ($updateScheduledTaskDialogState as StoreStateSelector<Store, UpdateScheduledTaskDialogState>)
      .$allStandIds
  );

  const mandatoryFields = useMemo((): (
    | keyof BaseScheduledTaskDataForUpdate
    | keyof DailyProdMailNotificationArgsData
  )[] => {
    return [
      ...BASE_MANDATORY_FIELDS,
      ...SCHEDULE_MANDATORY_FIELDS,
      ...(getArgumentsMandatoryFields(
        type,
        overrideDefaultSubject,
        customerShortNames
      ) as (keyof DailyProdMailNotificationArgsData)[]),
    ];
  }, [customerShortNames, overrideDefaultSubject, type]);
  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(updateScheduledTaskAction, onScheduledTaskChangeActionCallback);
    },
    [onScheduledTaskChangeActionCallback],
    $
  );

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    FormWithValidationState<BaseScheduledTaskDataForUpdate & DailyProdMailNotificationArgsData>
  >({
    $: $updateScheduledTaskDialogState as StoreStateSelector<
      Store,
      FormWithValidationState<BaseScheduledTaskDataForUpdate & DailyProdMailNotificationArgsData>
    >,
    mandatoryFields,
    checkFieldContentActions: {
      ...checkEmailFieldContentActions,
      ...checkLabelFieldContentActions,
    },
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });

  return (
    <ModalCardDialog
      $active={$updateScheduledTaskDialogState.$active}
      titleIconId="exclamation-triangle"
      title={t('updateTitle')}
      onOkClicked={onFormSubmit}
      warning={formWarning}
      isReadonly={isEditionForbidden}
    >
      <ScheduledTaskIdentityForUpdateInputs
        $formData={$formDataWithChangeTrigger}
        type={type}
        horizontalFormFields={horizontalFormFields}
      />
      <ScheduledTaskScheduleInputs
        $formData={$formDataWithChangeTrigger}
        horizontalFormFields={horizontalFormFields}
      />
      <DailyProdMailInputs
        $formData={$formDataWithChangeTrigger}
        allStandIds={allStandIds ?? []}
        allCustomerShortnames={allCustomerShortnames}
        horizontalFormFields={horizontalFormFields}
      />
    </ModalCardDialog>
  );
}

export function UpdateSparePartsMailScheduledTaskModal({
  $,
  allCustomerShortnames,
  horizontalFormFields,
  onScheduledTaskChangeActionCallback,
  isEditionForbidden,
}: UpdateAllCustomerShornamesNeededScheduledTaskProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');
  const { $updateScheduledTaskDialogState } = $;
  const formWarning = useGetState($updateScheduledTaskDialogState.$formWarning);
  const type = useGetState($.$updateScheduledTaskDialogState.$type);
  const overrideDefaultSubject = useGetState(
    $updateScheduledTaskDialogState.$formData.$overrideDefaultSubject
  );
  const customerShortNames = useGetState(
    (
      $updateScheduledTaskDialogState.$formData as StoreStateSelector<
        Store,
        WithFormValidationWarnings<
          BaseScheduledTaskDataForUpdate & SparePartsMailNotificationArgsData
        >
      >
    ).$customerShortNames
  );

  const mandatoryFields = useMemo((): (
    | keyof BaseScheduledTaskDataForUpdate
    | keyof SparePartsMailNotificationArgsData
  )[] => {
    return [
      ...BASE_MANDATORY_FIELDS,
      ...SCHEDULE_MANDATORY_FIELDS,
      ...(getArgumentsMandatoryFields(
        type,
        overrideDefaultSubject,
        customerShortNames
      ) as (keyof SparePartsMailNotificationArgsData)[]),
    ];
  }, [customerShortNames, overrideDefaultSubject, type]);
  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(updateScheduledTaskAction, onScheduledTaskChangeActionCallback);
    },
    [onScheduledTaskChangeActionCallback],
    $
  );

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    FormWithValidationState<BaseScheduledTaskDataForUpdate & SparePartsMailNotificationArgsData>
  >({
    $: $updateScheduledTaskDialogState as StoreStateSelector<
      Store,
      FormWithValidationState<BaseScheduledTaskDataForUpdate & SparePartsMailNotificationArgsData>
    >,
    mandatoryFields,
    checkFieldContentActions: {
      ...checkEmailFieldContentActions,
      ...checkLabelFieldContentActions,
    },
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });

  return (
    <ModalCardDialog
      $active={$updateScheduledTaskDialogState.$active}
      titleIconId="exclamation-triangle"
      title={t('updateTitle')}
      onOkClicked={onFormSubmit}
      warning={formWarning}
      isReadonly={isEditionForbidden}
    >
      <ScheduledTaskIdentityForUpdateInputs
        $formData={$formDataWithChangeTrigger}
        type={type}
        horizontalFormFields={horizontalFormFields}
      />
      <ScheduledTaskScheduleInputs
        $formData={$formDataWithChangeTrigger}
        horizontalFormFields={horizontalFormFields}
      />
      <SparePartsMailInputs
        $formData={$formDataWithChangeTrigger}
        allCustomerShortnames={allCustomerShortnames}
        horizontalFormFields={horizontalFormFields}
      />
    </ModalCardDialog>
  );
}

export function UpdateCarsExpectedAtStandMailScheduledTaskModal({
  $,
  allCustomerShortnames,
  horizontalFormFields,
  onScheduledTaskChangeActionCallback,
  isEditionForbidden,
}: UpdateAllCustomerShornamesNeededScheduledTaskProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');
  const { $updateScheduledTaskDialogState } = $;
  const formWarning = useGetState($updateScheduledTaskDialogState.$formWarning);
  const type = useGetState($.$updateScheduledTaskDialogState.$type);
  const overrideDefaultSubject = useGetState(
    $updateScheduledTaskDialogState.$formData.$overrideDefaultSubject
  );
  const customerShortNames = useGetState(
    (
      $updateScheduledTaskDialogState.$formData as StoreStateSelector<
        Store,
        WithFormValidationWarnings<
          BaseScheduledTaskDataForUpdate & CarsExpectedAtStandMailNotificationArgsData
        >
      >
    ).$customerShortNames
  );
  const allStandIds = useGetState(
    ($updateScheduledTaskDialogState as StoreStateSelector<Store, UpdateScheduledTaskDialogState>)
      .$allStandIds
  );

  const mandatoryFields = useMemo((): (
    | keyof BaseScheduledTaskDataForUpdate
    | keyof CarsExpectedAtStandMailNotificationArgsData
  )[] => {
    return [
      ...BASE_MANDATORY_FIELDS,
      ...SCHEDULE_MANDATORY_FIELDS,
      ...(getArgumentsMandatoryFields(
        type,
        overrideDefaultSubject,
        customerShortNames
      ) as (keyof CarsExpectedAtStandMailNotificationArgsData)[]),
    ];
  }, [customerShortNames, overrideDefaultSubject, type]);
  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) =>
      await actionDispatch.exec(updateScheduledTaskAction, onScheduledTaskChangeActionCallback),
    [onScheduledTaskChangeActionCallback],
    $
  );

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    FormWithValidationState<
      BaseScheduledTaskDataForUpdate & CarsExpectedAtStandMailNotificationArgsData
    >
  >({
    $: $updateScheduledTaskDialogState as StoreStateSelector<
      Store,
      FormWithValidationState<
        BaseScheduledTaskDataForUpdate & CarsExpectedAtStandMailNotificationArgsData
      >
    >,
    mandatoryFields,
    checkFieldContentActions: {
      ...checkEmailFieldContentActions,
      ...checkLabelFieldContentActions,
    },
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });

  return (
    <ModalCardDialog
      $active={$updateScheduledTaskDialogState.$active}
      titleIconId="exclamation-triangle"
      title={t('updateTitle')}
      onOkClicked={onFormSubmit}
      warning={formWarning}
      isReadonly={isEditionForbidden}
    >
      <ScheduledTaskIdentityForUpdateInputs
        $formData={$formDataWithChangeTrigger}
        type={type}
        horizontalFormFields={horizontalFormFields}
      />
      <ScheduledTaskScheduleInputs
        $formData={$formDataWithChangeTrigger}
        horizontalFormFields={horizontalFormFields}
      />
      <CarsExpectedAtStandMailInputs
        $formData={$formDataWithChangeTrigger}
        allCustomerShortnames={allCustomerShortnames}
        allStandIds={allStandIds ?? []}
        horizontalFormFields={horizontalFormFields}
      />
    </ModalCardDialog>
  );
}

export interface UpdateScheduledTaskProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, AdminScheduledTasksState>;
  readonly horizontalFormFields?: boolean | HorizontalFormFieldProps;
  readonly onScheduledTaskChangeActionCallback: OnScheduledTaskChangeActionCallback;
  readonly isEditionForbidden: boolean;
}

export interface UpdateAllCustomerShornamesNeededScheduledTaskProps
  extends UpdateScheduledTaskProps {
  readonly allCustomerShortnames: readonly string[];
}

export function UpdateScheduledTaskModal({
  $,
  $gs,
  horizontalFormFields,
  onScheduledTaskChangeActionCallback,
  isEditionForbidden,
}: UpdateScheduledTaskProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');
  const allCustomerSummaries = useGetState($.$updateScheduledTaskDialogState.$allCustomerSummaries);
  const type = useGetState($.$updateScheduledTaskDialogState.$type);
  const allCustomerShortnames = useMemo(() => {
    return allCustomerSummaries.map((c) => c.shortName);
  }, [allCustomerSummaries]);
  switch (type) {
    case 'sparepartsmail':
      return (
        <UpdateSparePartsMailScheduledTaskModal
          $={$}
          $gs={$gs}
          allCustomerShortnames={allCustomerShortnames}
          horizontalFormFields={horizontalFormFields}
          onScheduledTaskChangeActionCallback={onScheduledTaskChangeActionCallback}
          isEditionForbidden={isEditionForbidden}
        />
      );
    case 'dailyprodmail':
      return (
        <UpdateDailyProdMailScheduledTaskModal
          $={$}
          $gs={$gs}
          allCustomerShortnames={allCustomerShortnames}
          horizontalFormFields={horizontalFormFields}
          onScheduledTaskChangeActionCallback={onScheduledTaskChangeActionCallback}
          isEditionForbidden={isEditionForbidden}
        />
      );
    case 'ctmail':
      return (
        <UpdateCustomersBasedMailScheduledTaskModal
          $={$}
          $gs={$gs}
          allCustomerShortnames={allCustomerShortnames}
          horizontalFormFields={horizontalFormFields}
          onScheduledTaskChangeActionCallback={onScheduledTaskChangeActionCallback}
          isEditionForbidden={isEditionForbidden}
        />
      );
    case 'invoiceMail':
      return (
        <UpdateInvoiceMailScheduledTaskModal
          $={$}
          $gs={$gs}
          allCustomerShortnames={allCustomerShortnames}
          horizontalFormFields={horizontalFormFields}
          onScheduledTaskChangeActionCallback={onScheduledTaskChangeActionCallback}
          isEditionForbidden={isEditionForbidden}
        />
      );
    case 'carsexpectedatstandmail':
      return (
        <UpdateCarsExpectedAtStandMailScheduledTaskModal
          $={$}
          $gs={$gs}
          allCustomerShortnames={allCustomerShortnames}
          horizontalFormFields={horizontalFormFields}
          onScheduledTaskChangeActionCallback={onScheduledTaskChangeActionCallback}
          isEditionForbidden={isEditionForbidden}
        />
      );
    case 'airtableImport':
      return (
        <UpdateImportAirtableScheduledTaskModal
          $={$}
          $gs={$gs}
          horizontalFormFields={horizontalFormFields}
          onScheduledTaskChangeActionCallback={onScheduledTaskChangeActionCallback}
          isEditionForbidden={isEditionForbidden}
        />
      );
    case 'voLeanImport':
      return (
        <UpdateImportVOLeanScheduledTaskModal
          $={$}
          $gs={$gs}
          horizontalFormFields={horizontalFormFields}
          onScheduledTaskChangeActionCallback={onScheduledTaskChangeActionCallback}
          isEditionForbidden={isEditionForbidden}
        />
      );
    case 'archiveKanbans':
    case 'closeEntities':
    case 'createInvoices':
      throw new Error(t('errors.creationOrEditionNotAllowedForType', { type }));
    default:
      return <></>;
  }
}
