/* eslint-disable max-classes-per-file */
import type {
  CounterMetric,
  GaugeMetric,
  HistogramMetric,
  HistogramTimerFunction,
  MetricsRegistry,
} from './typings/metrics.js';

class NullCounter<T extends string = string> implements CounterMetric<T> {
  public inc(): void {
    // Do nothing
  }
}

class NullGauge<T extends string = string> implements GaugeMetric<T> {
  public set(): void {
    // Do nothing
  }

  public inc(): void {
    // Do nothing
  }

  public dec(): void {
    // Do nothing
  }
}

class NullHistogram<T extends string = string> implements HistogramMetric<T> {
  public startTimer(): HistogramTimerFunction<T> {
    return (): number => {
      return 0;
    };
  }
}

export class NullMetricsRegistry implements MetricsRegistry {
  public counter<T extends string = string>(): CounterMetric<T> {
    return new NullCounter<T>();
  }

  public gauge<T extends string = string>(): GaugeMetric<T> {
    return new NullGauge<T>();
  }

  public histogram<T extends string = string>(): HistogramMetric<T> {
    return new NullHistogram();
  }

  public async close(): Promise<void> {
    // Do nothing
  }
}
