import type { Kanban } from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import { Role } from '@stimcar/libs-base';
import { applyAsyncCallbackSequentially, isTruthy } from '@stimcar/libs-kernel';
import type { Store, StoreState } from '../typings/store.js';
import { updateKanbansHandledInWorkshopForDisplayViewStateFromSSEAction } from '../../../lib/components/workshop/implantation/workshopImplantationUtils.js';
import { udpateSearchViewStateFromSSEAction } from '../../archives/SearchArchivesView.js';
import {
  handleClosedOrArchivedKanbansInDetailsViewStateFromSSEAction,
  updateDetailsViewStateFromSSEAction,
} from '../../details/KanbanDetails.js';
import { updateDisplayViewStateFromSSEAction } from '../../display/Display.js';
import { updateOperatorViewStateFromSSEAction } from '../../operators/OperatorView.js';
import { updateSelectKanbanStateFromSSEAction } from '../../selectKanban/SelectKanban.js';
import { filterNotHandledOnPostKanbans } from '../../utils/operatorUtils.js';
import { updateKanbansHandledInWorkshopStateFromSSEAction } from '../../workshop/implantation/WorkshopImplantationView.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function kanbanRepositoryEventListenerAction(
  { httpClient, actionDispatch, getState }: ActionContext<Store, StoreState>,
  updatedEntities: readonly Kanban[],
  addedEntities: readonly Kanban[],
  archivedEntities: readonly string[],
  closedEntities: readonly string[]
) {
  const isWorkshop =
    httpClient.isBrowserRegistered() && httpClient.getBrowserInfos().role === Role.WorkshopOperator;

  if (!isWorkshop) {
    // Proceed
    const { handledKanbanId, session } = getState();
    if (isTruthy(session.infos)) {
      const { id: postId } = session.infos;

      const found = updatedEntities.find((e) => e.id === handledKanbanId);
      if (isTruthy(found)) {
        const updatedEntitiesOnPost = filterNotHandledOnPostKanbans([found], postId);
        if (updatedEntitiesOnPost.length === 0) {
          actionDispatch.reduce((initial) => {
            return {
              ...initial,
              handledKanbanId: undefined,
            };
          });
        }
      }
    }
  }
  await applyAsyncCallbackSequentially(updatedEntities, async (k): Promise<void> => {
    if (!isWorkshop) {
      await actionDispatch
        .scopeProperty('operatorView')
        .exec(updateOperatorViewStateFromSSEAction, k);
      await actionDispatch
        .scopeProperty('archivesView')
        .exec(udpateSearchViewStateFromSSEAction, k);
    }
    await actionDispatch.scopeProperty('detailsView').exec(updateDetailsViewStateFromSSEAction, k);
  });
  await actionDispatch.scopeProperty('displayView').exec(updateDisplayViewStateFromSSEAction);
  if (!isWorkshop) {
    await actionDispatch
      .scopeProperty('selectKanbanView')
      .exec(
        updateSelectKanbanStateFromSSEAction,
        [...updatedEntities, ...addedEntities],
        [...archivedEntities, ...closedEntities]
      );
  }
  await actionDispatch
    .scopeProperty('workshopImplantationView')
    .exec(updateKanbansHandledInWorkshopStateFromSSEAction, [...updatedEntities, ...addedEntities]);
  await actionDispatch
    .scopeProperty('displayView')
    .scopeProperty('workshopImplantationView')
    .exec(updateKanbansHandledInWorkshopForDisplayViewStateFromSSEAction, [
      ...updatedEntities,
      ...addedEntities,
    ]);
  if (!isWorkshop) {
    await actionDispatch
      .scopeProperty('adminView')
      .scopeProperty('adminWorkshopImplantation')
      .exec(updateKanbansHandledInWorkshopForDisplayViewStateFromSSEAction, [
        ...updatedEntities,
        ...addedEntities,
      ]);
  }
  await actionDispatch
    .scopeProperty('detailsView')
    .exec(handleClosedOrArchivedKanbansInDetailsViewStateFromSSEAction, [
      ...archivedEntities,
      ...closedEntities,
    ]);
  if (!isWorkshop) {
    await actionDispatch
      .scopeProperty('archivesView')
      .scopeProperty('detailsState')
      .exec(handleClosedOrArchivedKanbansInDetailsViewStateFromSSEAction, [
        ...archivedEntities,
        ...closedEntities,
      ]);
  }
}
