import type { PackageDealDesc, PackageDealDescValidationResult } from '@stimcar/libs-base';
import { enumerate, isBooleanVariable } from '@stimcar/libs-base';
import { isTruthy, keysOf } from '@stimcar/libs-kernel';

export type PackageDealDescIssues = {
  readonly multipleValuesVariablesWithoutDefaultPddCodes: ReadonlySet<string>;
  readonly multipleCarElementsPddCodes: ReadonlySet<string>;
  readonly unknownForWorkflowPddCodes: ReadonlySet<string>;
};

export function validatePackageDealDescCode(
  packageDealCode: string,
  pkgDealDescsByCode: Map<string, PackageDealDesc>
): PackageDealDescValidationResult {
  // Check missing package deal desc
  const packageDealDesc = pkgDealDescsByCode.get(packageDealCode);
  if (!isTruthy(packageDealDesc)) {
    return {
      code: packageDealCode,
      isValid: false,
      issue: 'unknownPackageDealDesc',
    };
  }

  return validatePackageDealDesc(packageDealDesc);
}

export function validatePackageDealDesc(
  packageDealDesc: PackageDealDesc
): PackageDealDescValidationResult {
  // Check multiple car elements
  if (packageDealDesc.carElementIds.length > 1) {
    return {
      code: packageDealDesc.code,
      isValid: false,
      issue: 'hasMultipleCarElements',
    };
  }

  // Check multipleValuedVariablesWithoutDefault
  for (const key of keysOf(packageDealDesc.variableDescs)) {
    const variableDesc = packageDealDesc.variableDescs[key];
    if (
      isTruthy(variableDesc) &&
      (isBooleanVariable(variableDesc) || variableDesc.availableValues.length > 1) &&
      (variableDesc.defaultValue === undefined || variableDesc.defaultValue === null)
    ) {
      return {
        code: packageDealDesc.code,
        isValid: false,
        issue: 'hasMultipleValuedVariablesWithoutDefault',
      };
    }
  }

  // No issue
  return {
    code: packageDealDesc.code,
    isValid: true,
  };
}

export function toPackageDealDescIssues(
  pkgDealDescValidationResults: readonly PackageDealDescValidationResult[]
): PackageDealDescIssues {
  const multipleCarElementsPddCodes = new Set<string>();
  const multipleValuesVariablesWithoutDefaultPddCodes = new Set<string>();
  const unknownForWorkflowPddCodes = new Set<string>();

  pkgDealDescValidationResults.forEach(({ issue, code }) => {
    switch (issue) {
      case undefined:
        // Nothing to do if there is no error
        break;
      case 'unknownPackageDealDesc':
        unknownForWorkflowPddCodes.add(code);
        break;
      case 'hasMultipleCarElements':
        multipleCarElementsPddCodes.add(code);
        break;
      case 'hasMultipleValuedVariablesWithoutDefault':
        multipleValuesVariablesWithoutDefaultPddCodes.add(code);
        break;
      default:
        throw Error(`Unknown PackageDealDescValidationResult: ${issue}`);
    }
  });

  return {
    unknownForWorkflowPddCodes,
    multipleCarElementsPddCodes,
    multipleValuesVariablesWithoutDefaultPddCodes,
  };
}

export function throwErrorIfPackageDealDescValidationIssues({
  multipleCarElementsPddCodes,
  multipleValuesVariablesWithoutDefaultPddCodes,
  unknownForWorkflowPddCodes,
}: PackageDealDescIssues): void {
  if (multipleCarElementsPddCodes.size > 0) {
    throw new Error(
      `Cannot create package deals associated to more than one car element:
       ${enumerate([...multipleCarElementsPddCodes])}`
    );
  }
  if (multipleValuesVariablesWithoutDefaultPddCodes.size > 0) {
    throw new Error(
      `Cannot create package deals with multiple available durations and without default value:
       ${enumerate([...multipleValuesVariablesWithoutDefaultPddCodes])}`
    );
  }
  if (unknownForWorkflowPddCodes.size > 0) {
    throw new Error(
      `Cannot create package deals with codes not defined in the workflow:
      ${enumerate([...unknownForWorkflowPddCodes])}`
    );
  }
}
