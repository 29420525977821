import '@mdxeditor/editor/style.css';
import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import {
  applyPayload,
  computePayload,
  MKTP_EQUIPMENT_CATEGORY_COMFORT,
  MKTP_EQUIPMENT_CATEGORY_MULTIMEDIA,
  MKTP_EQUIPMENT_CATEGORY_OTHERS,
  MKTP_EQUIPMENT_CATEGORY_SECURITY,
} from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Button, FaIcon, ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { MarketplaceDetailsProps } from '../MarketplaceDetails.js';
import type {
  MarketplaceEditEquipmentsDialogState,
  MarketplaceEditEquipmentsForm,
  MarketplaceEquipmentForm,
} from '../typings/store.js';

const mandatoryFields: readonly (keyof MarketplaceEditEquipmentsForm)[] = [];

interface RemovableEquipmentProps {
  readonly $form: StoreStateSelector<Store, MarketplaceEquipmentForm>;
  readonly value: string;
}

export function RemovableEquipment({ $form, value }: RemovableEquipmentProps): JSX.Element {
  const [t] = useTranslation('details');

  const onAdditionalEquipmentRemoveButtonClickActionCallback = useActionCallback(
    ({ actionDispatch, getState }): void => {
      const currentAdditionalValues = getState().additionalEquipments;
      const newAdditionalValues = currentAdditionalValues.filter(
        (existingValue) => existingValue !== value
      );
      actionDispatch.setProperty('additionalEquipments', newAdditionalValues);
    },
    [value],
    $form
  );

  return (
    <div className="is-flex is-align-items-center mx-4">
      <div>{value}</div>
      <button
        type="button"
        className="button is-small is-transparent"
        onClick={onAdditionalEquipmentRemoveButtonClickActionCallback}
      >
        <FaIcon
          id="trash"
          tooltip={t('tabs.marketplace.equipments.deleteEquipmentButtonTooltip')}
        />
      </button>
    </div>
  );
}

interface AdditionalEquipmentsProps {
  readonly $form: StoreStateSelector<Store, MarketplaceEquipmentForm>;
}

export function AdditionalEquipments({ $form }: AdditionalEquipmentsProps): JSX.Element {
  const [t] = useTranslation('details');
  const additionalEquipments = useGetState($form.$additionalEquipments);
  const newEquipmentValue = useGetState($form.$newEquipmentValue);

  const onNewEquipmentValueChangeActionCallback = useActionCallback(
    ({ actionDispatch }, e: React.ChangeEvent<HTMLInputElement>) => {
      actionDispatch.setProperty('newEquipmentValue', e.target.value);
    },
    [],
    $form
  );
  const onNewEquipmentButtonClickActionCallback = useActionCallback(
    ({ actionDispatch, getState }) => {
      const { newEquipmentValue } = getState();
      const currentAdditionalValues = getState().additionalEquipments;
      actionDispatch.setProperty('additionalEquipments', [
        ...currentAdditionalValues,
        newEquipmentValue,
      ]);
      actionDispatch.setProperty('newEquipmentValue', '');
    },
    [],
    $form
  );

  return (
    <div className="mb-4">
      <div className="columns mx-4 mb-0">
        <div className="column is-half is-flex is-align-items-center">
          <b className="is-size-6">
            {t('tabs.marketplace.equipments.additionalEquipmentsSubtitle')}
          </b>
        </div>
        <div className="column is-half">
          <div className="is-flex is-align-items-center is-justify-content-space-between">
            <input
              className="input is-small mr-2"
              type="text"
              value={newEquipmentValue}
              onChange={onNewEquipmentValueChangeActionCallback}
            />
            <Button
              iconId="plus"
              size="small"
              onClick={onNewEquipmentButtonClickActionCallback}
              label={t('tabs.marketplace.equipments.addButton')}
            />
          </div>
        </div>
      </div>
      <div className="mx-5">
        {additionalEquipments.length > 0 ? (
          <div className="fixed-grid has-2-cols">
            <div className="grid is-gap-0">
              {additionalEquipments.map((value) => (
                <RemovableEquipment $form={$form} value={value} />
              ))}
            </div>
          </div>
        ) : (
          <div className="mx-4">{t('tabs.marketplace.equipments.noValue')}</div>
        )}
      </div>
    </div>
  );
}

interface EquipmentsSelectionProps {
  readonly $form: StoreStateSelector<Store, MarketplaceEquipmentForm>;
  readonly $availableEquipments: StoreStateSelector<Store, readonly string[]>;
}

export function AvailableEquipmentsSelection({
  $form,
  $availableEquipments,
}: EquipmentsSelectionProps): JSX.Element {
  const availableEquipments = useGetState($availableEquipments);
  const selectedEquipments = useGetState($form.$selectedEquipments);

  const onValueClickedActionCallback = useActionCallback(
    ({ actionDispatch, getState }, e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = e.target;
      const originalValues = getState().selectedEquipments;
      const newSelectedValues = checked
        ? [...originalValues, value]
        : originalValues.filter((originalValue) => originalValue !== value);
      actionDispatch.setProperty('selectedEquipments', newSelectedValues);
    },
    [],
    $form
  );

  return (
    <div className="fixed-grid has-2-cols mx-4">
      <div className="grid is-gap-0">
        {availableEquipments.map((value) => (
          <label key={`${value}-label`} className="checkbox cell mx-4">
            <input
              type="checkbox"
              key={`${value}-checkbox`}
              value={value}
              onChange={onValueClickedActionCallback}
              checked={selectedEquipments.includes(value)}
            />
            <span className="ml-2 is-size-6-and-half">{value}</span>
          </label>
        ))}
      </div>
    </div>
  );
}

interface MarketplaceEditEquipmentsModalDialogProps extends Pick<MarketplaceDetailsProps, '$gs'> {
  readonly $: StoreStateSelector<Store, MarketplaceEditEquipmentsDialogState>;
  readonly kanbanId: string;
}

export function MarketplaceEditEquipmentsModalDialog({
  $,
  $gs,
  kanbanId,
}: MarketplaceEditEquipmentsModalDialogProps): JSX.Element {
  const [t] = useTranslation('details');
  const submitValidDataActionCallback = useActionCallback(
    async ({
      actionDispatch,
      kanbanRepository,
      getState,
    }: ActionContext<Store, MarketplaceEditEquipmentsDialogState>): Promise<void> => {
      const { security, comfort, multimedia, others } = getState().formData;
      const kanban = await kanbanRepository.getEntity(kanbanId);
      const newKanban = applyPayload(kanban, {
        marketplaceInfos: {
          equipments: {
            [MKTP_EQUIPMENT_CATEGORY_SECURITY]: [
              ...security.selectedEquipments,
              ...security.additionalEquipments,
            ],
            [MKTP_EQUIPMENT_CATEGORY_COMFORT]: [
              ...comfort.selectedEquipments,
              ...comfort.additionalEquipments,
            ],
            [MKTP_EQUIPMENT_CATEGORY_MULTIMEDIA]: [
              ...multimedia.selectedEquipments,
              ...multimedia.additionalEquipments,
            ],
            [MKTP_EQUIPMENT_CATEGORY_OTHERS]: [
              ...others.selectedEquipments,
              ...others.additionalEquipments,
            ],
          },
        },
      });

      // Update the kanban in the repository if needed
      const payload = computePayload(kanban, newKanban);
      if (payload !== undefined) {
        await kanbanRepository.updateEntityFromPayload({ entityId: kanbanId, payload });
      }
      actionDispatch.setProperty('active', false);
    },
    [kanbanId],
    $
  );

  const [onFormSubmit] = useFormWithValidation<Store, MarketplaceEditEquipmentsDialogState>({
    $,
    mandatoryFields,
    checkFieldContentActions: undefined,
    checkFormConsistencyAction: undefined,
    submitValidDataAction: submitValidDataActionCallback,
    t,
  });

  const formWarning = useGetState($.$formWarning);
  const isOnline = useGetState($gs.$session.$isOnline);

  const modalWarning = useMemo(() => {
    // If there is a form warning
    if (formWarning !== undefined) {
      return formWarning;
    }
    // If no form warning exist, check that we're not offline
    if (!isOnline) {
      return t('tabs.marketplace.editAdModalDialog.warnings.offline');
    }
    return undefined;
  }, [formWarning, isOnline, t]);

  return (
    <ModalCardDialog
      $active={$.$active}
      title={t('tabs.marketplace.equipments.subtitle')}
      onOkClicked={onFormSubmit}
      warning={modalWarning}
      size="max-desktop"
    >
      <>
        <strong className="is-size-5 mx-2">{t('tabs.marketplace.equipments.security')}</strong>
        <AvailableEquipmentsSelection
          $form={$.$formData.$security}
          $availableEquipments={$gs.$siteConfiguration.$marketplaceEquipments.$security}
        />
        <AdditionalEquipments $form={$.$formData.$security} />
        <strong className="is-size-5 mx-2">{t('tabs.marketplace.equipments.comfort')}</strong>
        <AvailableEquipmentsSelection
          $form={$.$formData.$comfort}
          $availableEquipments={$gs.$siteConfiguration.$marketplaceEquipments.$comfort}
        />
        <AdditionalEquipments $form={$.$formData.$comfort} />
        <strong className="is-size-5 mx-2">{t('tabs.marketplace.equipments.multimedia')}</strong>
        <AvailableEquipmentsSelection
          $form={$.$formData.$multimedia}
          $availableEquipments={$gs.$siteConfiguration.$marketplaceEquipments.$multimedia}
        />
        <AdditionalEquipments $form={$.$formData.$multimedia} />
        <strong className="is-size-5 mx-2">{t('tabs.marketplace.equipments.others')}</strong>
        <AvailableEquipmentsSelection
          $form={$.$formData.$others}
          $availableEquipments={$gs.$siteConfiguration.$marketplaceEquipments.$others}
        />
        <AdditionalEquipments $form={$.$formData.$others} />
      </>
    </ModalCardDialog>
  );
}
