import { lazy } from 'react';

// @ts-ignore
export const LazyAceEditor = lazy(async () => {
  // Load AceEditor
  const aceModule = await import('react-ace');
  // Load brace after AceEditor
  await import('brace/mode/json');
  await import('brace/theme/github');
  return aceModule;
});
