/* eslint-disable jsx-a11y/control-has-associated-label */
import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import { marked } from 'marked';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban, KanbanMessage, Workflow } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { enumerate, kanbanHelpers, sortingHelpers } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  FaIcon,
  ModalCardDialog,
  ScrollableTableComponent,
  TableActionCell,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../state/typings/store.js';
import { TableActionHeaderCell } from '../../lib/bulma/elements/table/TableHeaderActionCell.js';
import { Checkbox } from '../../lib/bulma/form/Checkbox.js';
import { KanbanMessageComponent } from '../../lib/bulmalegacy/components/KanbanMessageBoxComponent.js';
import { GraphicWorkflowWithProgress } from '../../lib/components/GraphicWorkflowWithProgress.js';
import { DisplayContentOrPlaceholder } from '../../lib/components/misc/DisplayContentOrPlaceholder.js';
import { TruncableLi } from '../../lib/components/misc/TruncableLi.js';
import { ShowHideBoxContainer } from '../../lib/components/ShowHideContainer.js';
import { TableSortableHeaderComponent } from '../../lib/components/TableSortableHeaderComponent.js';
import './DisplayHistoryComponent.scss';
import type {
  CloseHandlingConfirmDialogState,
  EmptyHandlingDetailsInternalDataStructure,
  HistoryDetailsInternalDataStructure,
  HistoryTabState,
  IntervalDetailsInternalDataStructure,
  MessageDetailsInternalDataStructure,
  MobileDetailsSubPartState,
} from './typings/store.js';
import {
  computeDateLabelForTimespamp,
  computeHandlingDuration,
  computeShortDateLabelForTimespamp,
  createSortHistoryByDurationFunction,
  createSortHistoryByStandFunction,
  createSortHistoryByUsersFunction,
  isHistoryElementAMessage,
  isHistoryElementAnAnEmptyHandling,
  isHistoryElementAnInterval,
  isHistoryElementAnIntervalOrAnEmptyHandling,
  kanbanToDetailsInternalHistoryStructure,
} from './kanbanDetailsUtils.js';
import { MobileDetailsModalMessageDialog } from './MobileDetailsModalMessageDialog.js';

async function closeHandlingAction(
  { getState, kanbanRepository, actionDispatch }: ActionContext<Store, HistoryTabState>,
  kanban: Kanban
): Promise<void> {
  const { closeHandlingConfirmDialog } = getState();
  const { handlingId, intervalId } = closeHandlingConfirmDialog;

  if (isTruthyAndNotEmpty(intervalId)) {
    const otherOpenedIntervals =
      nonnull(kanban.handlings.find(({ id }) => id === handlingId)).intervals.filter(
        ({ id, endDate }) => id !== intervalId && !endDate
      ) ?? [];
    const hasOtherOpenedIntervals = otherOpenedIntervals.length > 0;
    await kanbanRepository.updateEntityFromPayload({
      entityId: kanban.id,
      payload: {
        handlings: [
          {
            id: handlingId,
            endDate: hasOtherOpenedIntervals ? undefined : Date.now(),
            intervals: [{ id: intervalId, endDate: Date.now() }],
          },
        ],
      },
    });
  } else {
    await kanbanRepository.updateEntityFromPayload({
      entityId: kanban.id,
      payload: {
        handlings: [
          {
            id: handlingId,
            endDate: Date.now(),
          },
        ],
      },
    });
  }

  actionDispatch.scopeProperty('closeHandlingConfirmDialog').setProperty('active', false);
}

function getPostAndCommentForInterval(
  historyElement: IntervalDetailsInternalDataStructure | EmptyHandlingDetailsInternalDataStructure
): string {
  if (isHistoryElementAnInterval(historyElement) && isTruthyAndNotEmpty(historyElement.comment)) {
    return `${historyElement.post} / ${historyElement.comment}`;
  }
  return historyElement.post;
}

function computePostCommentPositionTextForHistoryElement(
  historyElement: HistoryDetailsInternalDataStructure
): string {
  if (isHistoryElementAnIntervalOrAnEmptyHandling(historyElement)) {
    return getPostAndCommentForInterval(historyElement);
  }
  if (isHistoryElementAMessage(historyElement)) {
    marked.use({ async: false });
    return marked.parse(historyElement.content) as string;
  }
  return '';
}

function getUsers(historyElement: HistoryDetailsInternalDataStructure): string {
  if (isTruthy(historyElement.users)) {
    return enumerate(historyElement.users);
  }
  return '';
}

interface HistoryItemProps {
  readonly showTechnicalId: boolean;
  readonly historyElement: HistoryDetailsInternalDataStructure;
  readonly $: StoreStateSelector<Store, CloseHandlingConfirmDialogState>;
  readonly isEditable: boolean;
  readonly t: TFunction;
}

function HistoryItem({
  showTechnicalId,
  historyElement,
  $,
  isEditable,
  t,
}: HistoryItemProps): JSX.Element {
  const showDialog = useActionCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async ({ actionDispatch }) => {
      if (isHistoryElementAnAnEmptyHandling(historyElement)) {
        actionDispatch.setProperty('active', true);
        actionDispatch.setProperty('handlingId', historyElement.id);
      } else if (isHistoryElementAnInterval(historyElement)) {
        actionDispatch.setProperty('active', true);
        actionDispatch.setProperty('handlingId', historyElement.handlingId);
        actionDispatch.setProperty('intervalId', historyElement.id);
      }
    },
    [historyElement],
    $
  );

  const displayedDateLabel = useMemo((): string => {
    let value = computeDateLabelForTimespamp(historyElement.date);
    if (isHistoryElementAnIntervalOrAnEmptyHandling(historyElement)) {
      value += ` - ${
        historyElement.isCurrent
          ? t('tabs.inProgress')
          : computeShortDateLabelForTimespamp(historyElement.endDate)
      }`;
    }
    return value;
  }, [historyElement, t]);

  return (
    <tr
      className={
        isHistoryElementAnIntervalOrAnEmptyHandling(historyElement) && historyElement.isCurrent
          ? 'has-text-weight-semibold'
          : ''
      }
    >
      {showTechnicalId && <td>{historyElement.id}</td>}
      <td>
        <FaIcon id={historyElement.iconId} />
      </td>
      <td>{displayedDateLabel}</td>
      <td>
        {isHistoryElementAnAnEmptyHandling(historyElement)
          ? t('tabs.history.waitingLine')
          : getUsers(historyElement)}
      </td>
      <td className="has-text-right">
        {isHistoryElementAnIntervalOrAnEmptyHandling(historyElement)
          ? computeHandlingDuration(historyElement.date, historyElement.endDate, t)
          : ''}
      </td>
      <td>
        {isHistoryElementAnIntervalOrAnEmptyHandling(historyElement) ? historyElement.stand : ''}
      </td>
      <td
        className="multiline-markdown-content"
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{
          __html: computePostCommentPositionTextForHistoryElement(historyElement),
        }}
      />
      {isEditable && (
        <>
          {isHistoryElementAnIntervalOrAnEmptyHandling(historyElement) &&
          historyElement.isCurrent ? (
            <TableActionCell onClick={showDialog} iconId="times" tooltip={t('table.editButton')} />
          ) : (
            <td />
          )}
        </>
      )}
    </tr>
  );
}

interface Props extends AppProps<Store> {
  readonly kanban: Kanban;
  readonly isEditable: boolean;
  readonly $: StoreStateSelector<Store, HistoryTabState>;
}

export function DisplayHistoryComponent({ kanban, $, $gs, isEditable }: Props): JSX.Element {
  const [t] = useTranslation('details');
  const { $closeHandlingConfirmDialog } = $;

  const siteConfiguration = useGetState($gs.$siteConfiguration);

  const internalDatas = useMemo(() => {
    return kanbanToDetailsInternalHistoryStructure(t, siteConfiguration, kanban);
  }, [kanban, siteConfiguration, t]);

  const sortBy = useGetState($.$sort.$by);
  const sortDirection = useGetState($.$sort.$direction);

  const sortedHistory = useMemo((): readonly HistoryDetailsInternalDataStructure[] => {
    const sortedDatas = internalDatas.slice();
    let sortFunction: (
      c1: HistoryDetailsInternalDataStructure,
      c2: HistoryDetailsInternalDataStructure
    ) => number;
    switch (sortBy) {
      case 'stand':
        sortFunction = createSortHistoryByStandFunction(sortDirection);
        break;
      case 'users':
        sortFunction = createSortHistoryByUsersFunction(sortDirection);
        break;
      case 'date':
        sortFunction = sortingHelpers.createSortByNumericField(sortDirection, sortBy);
        break;
      case 'duration':
        sortFunction = createSortHistoryByDurationFunction(sortDirection);
        break;
      default:
        return sortedDatas;
    }
    return sortedDatas.sort(sortFunction);
  }, [internalDatas, sortBy, sortDirection]);

  const showTechnicalId = useGetState($.$showTechnicalId);
  const closeHandlingConfirmDialogActive = useGetState($.$closeHandlingConfirmDialog.$active);
  const closeHandlingConfirmDialogActionCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.exec(closeHandlingAction, kanban);
    },
    [kanban],
    $
  );
  const workflow = useMemo((): Workflow => {
    return nonnull(siteConfiguration.workflows.find((w) => w.id === kanban.workflowId));
  }, [kanban.workflowId, siteConfiguration.workflows]);

  return (
    <>
      <div className="columns">
        <div className="column">
          <DisplayContentOrPlaceholder
            displayCondition={internalDatas.length > 0}
            placeholder={t('tabs.history.emptyPlaceholder')}
            isScrollable
          >
            <>
              <Checkbox $={$.$showTechnicalId} text={t('tabs.history.id')} />
              <ScrollableTableComponent tableClassName="table is-narrow is-striped is-hoverable is-fullwidth">
                <thead>
                  <tr>
                    {showTechnicalId && <th>{t('tabs.idTitle')}</th>}
                    <TableActionHeaderCell />
                    <TableSortableHeaderComponent
                      content={t('tabs.history.date')}
                      centerLabel={false}
                      sortedField="date"
                      isTruncable
                      $sort={$.$sort}
                    />
                    <TableSortableHeaderComponent
                      content={t('tabs.history.users')}
                      centerLabel={false}
                      isTruncable
                      sortedField="users"
                      $sort={$.$sort}
                    />
                    <TableSortableHeaderComponent
                      content={t('tabs.history.duration')}
                      className="has-text-right"
                      isTruncable
                      centerLabel={false}
                      sortedField="duration"
                      $sort={$.$sort}
                    />
                    <TableSortableHeaderComponent
                      content={t('tabs.history.stand')}
                      centerLabel={false}
                      isTruncable
                      sortedField="stand"
                      $sort={$.$sort}
                    />
                    <th style={{ width: '50%' }}>{t('tabs.history.postComment')}</th>
                    {isEditable && <TableActionHeaderCell />}
                  </tr>
                </thead>
                <tbody>
                  {sortedHistory.map((h): JSX.Element => {
                    return (
                      <HistoryItem
                        key={h.id}
                        showTechnicalId={showTechnicalId}
                        historyElement={h}
                        isEditable={isEditable}
                        $={$closeHandlingConfirmDialog}
                        t={t}
                      />
                    );
                  })}
                </tbody>
              </ScrollableTableComponent>
            </>
          </DisplayContentOrPlaceholder>
        </div>
        <div className="column is-narrow">
          <GraphicWorkflowWithProgress kanban={kanban} workflow={workflow} />
        </div>
      </div>
      {closeHandlingConfirmDialogActive && (
        <ModalCardDialog
          $active={$closeHandlingConfirmDialog.$active}
          titleIconId="exclamation-triangle"
          title={t('tabs.history.closeIntervalConfirmationDialog.title')}
          onOkClicked={closeHandlingConfirmDialogActionCallback}
        >
          <p>{t('tabs.history.closeIntervalConfirmationDialog.message')}</p>
        </ModalCardDialog>
      )}
    </>
  );
}

interface MobileHistoryComponentProps extends AppProps<Store> {
  readonly $: StoreStateSelector<
    Store,
    MobileDetailsSubPartState<HistoryDetailsInternalDataStructure>
  >;
  readonly kanban: Kanban;
}

export function MobileHistoryComponent({
  $,
  $gs,
  kanban,
}: MobileHistoryComponentProps): JSX.Element {
  const [t] = useTranslation('details');

  const siteConfiguration = useGetState($gs.$siteConfiguration);

  const historyInternalDatas = useMemo(() => {
    const datas = kanbanToDetailsInternalHistoryStructure(t, siteConfiguration, kanban);
    return datas.sort(sortingHelpers.createSortByNumericField('UP', 'date'));
  }, [kanban, siteConfiguration, t]);

  const details = useGetState($.$showDetailsFor);

  return (
    <>
      <ShowHideBoxContainer title={t('tabs.history.title')} $={$.$isUnfolded} isMobile>
        <DisplayContentOrPlaceholder
          displayCondition={historyInternalDatas.length > 0}
          placeholder={t('tabs.history.emptyPlaceholder')}
        >
          <ul className="hide-bullets">
            {historyInternalDatas.map(
              (h): JSX.Element => (
                <MobileHistoryLine historyElement={h} key={h.id} $={$} />
              )
            )}
          </ul>
        </DisplayContentOrPlaceholder>
      </ShowHideBoxContainer>
      <MobileDetailsModalMessageDialog $={$}>
        {isTruthy(details) ? <InternalModal details={details} /> : <></>}
      </MobileDetailsModalMessageDialog>
    </>
  );
}

function convertMessageFromHistory(element: MessageDetailsInternalDataStructure): KanbanMessage {
  return kanbanHelpers.createKanbanComment(element.id, element.users[0], element.content);
}
interface InternalModalProps {
  readonly details: HistoryDetailsInternalDataStructure;
}

function InternalModal({ details }: InternalModalProps): JSX.Element {
  const [t] = useTranslation('details');

  if (isHistoryElementAnInterval(details)) {
    return (
      <>
        <p>
          <strong>{`${t('tabs.history.detailModalSpecific.startDate')}: `}</strong>
          {computeDateLabelForTimespamp(details?.date)}
        </p>
        <p>
          <strong>{`${t('tabs.history.detailModalSpecific.endDate')}: `}</strong>
          {details?.isCurrent
            ? t('tabs.inProgress')
            : computeDateLabelForTimespamp(details?.endDate)}
        </p>
        <p>
          <strong>{`${t('tabs.history.duration')}: `}</strong>
          {computeHandlingDuration(details?.date ?? 0, details?.endDate ?? 0, t)}
        </p>
        <p>
          <strong>{`${t('tabs.history.stand')}: `}</strong>
          {details?.stand}
        </p>
        <p>
          <strong>{`${t('tabs.history.post')}: `}</strong>
          {details?.post}
        </p>
        <p>
          <strong>{`${t('tabs.history.users')}: `}</strong>
          {enumerate(details?.users)}
        </p>
      </>
    );
  }

  if (isHistoryElementAMessage(details)) {
    return <KanbanMessageComponent message={convertMessageFromHistory(details)} />;
  }

  return <p>{t('tabs.history.unknownHistoryElement')}</p>;
}

interface MobileHandlingLineProps {
  readonly historyElement: HistoryDetailsInternalDataStructure;
  readonly $: StoreStateSelector<
    Store,
    MobileDetailsSubPartState<HistoryDetailsInternalDataStructure>
  >;
}

function MobileHistoryLine({ historyElement, $ }: MobileHandlingLineProps): JSX.Element {
  const actionCallback = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setProperty('showDetailsFor', historyElement);
    },
    [historyElement],
    $
  );
  return (
    <TruncableLi
      key={historyElement.id}
      style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}
      actionToolbox={{ action: actionCallback, className: 'is-rounded is-small is-text' }}
    >
      <>
        <FaIcon id={historyElement.iconId} />
        <MobileHistoryLineContent historyElement={historyElement} />
      </>
    </TruncableLi>
  );
}

interface MobileHistoryLineContentProps {
  readonly historyElement: HistoryDetailsInternalDataStructure;
}

function MobileHistoryLineContent({ historyElement }: MobileHistoryLineContentProps): JSX.Element {
  const [t] = useTranslation('details');
  if (isHistoryElementAnInterval(historyElement)) {
    return <MobileIntervalHistoryLine interval={historyElement} />;
  }
  if (isHistoryElementAMessage(historyElement)) {
    return <MobileCommentHistoryLine comment={historyElement} />;
  }
  return <span>{t('tabs.history.unknownHistoryElement')}</span>;
}

interface MobileIntervalHistoryLineProps {
  readonly interval: IntervalDetailsInternalDataStructure;
}

function MobileIntervalHistoryLine({ interval }: MobileIntervalHistoryLineProps): JSX.Element {
  const [t] = useTranslation('details');
  return (
    <span className={interval.isCurrent ? 'has-text-weight-semibold' : ''}>
      {`${interval.stand} / ${interval.users} (${
        interval.isCurrent
          ? t('tabs.inProgress')
          : computeShortDateLabelForTimespamp(interval.endDate)
      })`}
    </span>
  );
}

interface MobileCommentHistoryLineProps {
  readonly comment: MessageDetailsInternalDataStructure;
}

function MobileCommentHistoryLine({ comment }: MobileCommentHistoryLineProps): JSX.Element {
  const firstCommentLine = useMemo(() => {
    const lines = comment.content.split('\n');
    marked.use({ async: false });
    const firstLine = marked.parse(lines[0]) as string;
    if (lines.length > 1) {
      return `${firstLine} ...`;
    }
    return firstLine;
  }, [comment.content]);

  return (
    <>
      {comment.users.length > 0 && <strong>{`${enumerate(comment.users)}: `}</strong>}
      <div
        className="pl-1 multiline-markdown-content mobile-markdown-comment-container "
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: firstCommentLine }}
      />
    </>
  );
}
