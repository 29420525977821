import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { FormFieldEntry } from '@stimcar/libs-uitoolkit';
import { useGetState, useSetCallback } from '@stimcar/libs-uikernel';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { MultiListSelectProps } from './MultiListSelect.js';
import { MultiListSelect } from './MultiListSelect.js';

export interface MultiListSelectModalAndListResultProps<SD extends AnyStoreDef>
  extends MultiListSelectProps<SD> {
  readonly placeholder?: string;
  readonly actionLabel?: string;
  readonly modalTitle: string;
  readonly $modalIsActive: StoreStateSelector<SD, boolean>;
}

export function MultiListSelectModalAndListResult<SD extends AnyStoreDef>({
  $selection,
  $preSelection,
  $preUnselect,
  $modalIsActive,
  entries,
  sortingFunction,
  placeholder,
  actionLabel,
  modalTitle,
}: MultiListSelectModalAndListResultProps<SD>): JSX.Element {
  const [t] = useTranslation('libBulma');

  const value = useGetState($selection);

  const sortedEntries = useMemo(() => {
    let selectedEntries: FormFieldEntry<string>[] = [];
    value.forEach((v) => {
      const entry = entries.find((e) => e.id === v);
      if (entry) {
        selectedEntries.push(entry);
      }
    });
    if (sortingFunction) {
      selectedEntries = selectedEntries.sort(sortingFunction);
    }
    return selectedEntries.map((e): string => e.label);
  }, [value, entries, sortingFunction]);

  const openModalHandler = useSetCallback($modalIsActive, true);

  return (
    <>
      <div className="columns m-l-none m-r-none">
        <div className="column" style={{ paddingLeft: '0px' }}>
          <div
            className="mimicInput"
            style={{
              overflow: 'auto',
              maxHeight: '8em',
            }}
          >
            {sortedEntries.length === 0 && <div className="placeholder">{placeholder}</div>}
            {sortedEntries.map((e, i): JSX.Element => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`${e}-${i}`}>{`- ${e}`}</div>
              );
            })}
          </div>
        </div>
        <div className="column is-narrow" style={{ paddingRight: '0px' }}>
          <button type="button" className="button is-primary" onClick={openModalHandler}>
            {!actionLabel ? t('buttons.change') : actionLabel}
          </button>
        </div>
      </div>
      <ModalCardDialog title={modalTitle} $active={$modalIsActive}>
        <MultiListSelect
          $selection={$selection}
          $preSelection={$preSelection}
          $preUnselect={$preUnselect}
          entries={entries}
          sortingFunction={sortingFunction}
        />
      </ModalCardDialog>
    </>
  );
}
