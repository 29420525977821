export const isTruthy = <T extends object | number | string | boolean>(
  arg: null | undefined | T
): arg is T => arg !== undefined && arg !== null;

export const isTruthyAndNotEmpty = (arg: string | undefined | null): arg is string =>
  isTruthy(arg) && arg.trim() !== '';

/**
 * Return a function that compares a given objects to other ones.
 * @param value the value to compare to.
 */
export function equals<T>(value: T): (other: T) => boolean {
  return (other): boolean => value === other;
}

export function nonnull<T>(value: T | null | undefined, message?: string): T {
  if (value === undefined || value === null) {
    throw new Error(`Unexpected null or undefined value${message ? ` (${message})` : ''}`);
  }
  return value;
}
