import i18next from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  Attachment,
  AttachmentDesc,
  AttachmentFolder,
  AttachmentMetadata,
  StorageCategories,
} from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type {
  BaseStoreDef,
  BaseStoreDefWithHttpClient,
  BaseStoreState,
} from '@stimcar/libs-uitoolkit';
import {
  contractHelpers,
  CoreBackendRoutes,
  EXPERTISE_ATTACHMENTS_FOLDER,
  HttpErrorCodes,
} from '@stimcar/libs-base';
import { isTruthy, keysOf, Logger, nonnull } from '@stimcar/libs-kernel';
import type {
  ConvertToPdfHttpRequestAction,
  RemovePdfPageHttpRequestAction,
} from '../../lib/components/attachments/PdfCreationAndUploadModal.js';
import type {
  AttachmentsBaseState,
  AttachmentsTabState,
} from '../../lib/components/attachments/typings/store.js';
import type { EstimateViewState } from '../../lib/components/documentGeneration/estimate/typings/store.js';
import type { SubcontractorStore } from '../../subcontractor/state/typings/store.js';
import type { Store, StoreState } from '../state/typings/store.js';
import { PDF_EXTENSION } from '../../lib/components/attachments/PdfCreationAndUploadModal.js';
import { appendRegisteredBrowserSessionToken } from './security.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

export const EXPERTISE_ATTACHMENT_CATEGORY = 'kanban';

export function useGetExpertiseAttachmentFolder(): AttachmentFolder {
  const [t] = useTranslation('globals');

  return useMemo(
    () => ({
      id: EXPERTISE_ATTACHMENTS_FOLDER.id,
      label: t('expertiseFolderUploadLabel'),
      isShareable: EXPERTISE_ATTACHMENTS_FOLDER.isShareable,
    }),
    [t]
  );
}

function useGetCommonAttachmentsFolders(): readonly AttachmentFolder[] {
  const [t] = useTranslation('globals');
  return useMemo(() => {
    const mandatoryAttachmentsFoldersIds = contractHelpers.getCommonAttachmentsFolders();
    return mandatoryAttachmentsFoldersIds.map((folder): AttachmentFolder => {
      return {
        id: folder.id,
        label: t(`${folder.id}FolderUploadLabel`),
        isShareable: folder.isShareable,
      };
    });
  }, [t]);
}

export function useGetAllAttachmentFoldersForContractDocuments(
  contractDocuments: readonly AttachmentFolder[]
): readonly AttachmentFolder[] {
  const folders = useGetCommonAttachmentsFolders();
  return useMemo(() => {
    const allFolders = [...folders];
    contractDocuments.forEach((f) => {
      const found = allFolders.find((folder) => folder.id === f.id);
      if (!found) {
        allFolders.push({
          id: f.id,
          label: f.label,
          isShareable: f.isShareable,
        });
      }
    });
    return allFolders;
  }, [contractDocuments, folders]);
}

export function showRemoveAttachmentConfirmDialogAction<SD extends BaseStoreDef>(
  { actionDispatch }: ActionContext<SD, AttachmentsTabState>,
  folder: string,
  name: string,
  id: string
) {
  actionDispatch.applyPayload({
    confirmAttachmentRemovalDialog: {
      active: true,
      folder,
      name,
      id,
    },
  });
}

export async function loadEstimateAttachmentsAction(
  { actionDispatch, httpClient }: ActionContext<Store, EstimateViewState>,
  category: StorageCategories,
  objectId: string
): Promise<void> {
  const attachments = await httpClient.httpGetAsJson<readonly AttachmentDesc[]>(
    CoreBackendRoutes.ATTACHMENT_FOLDER(category, objectId, 'expertise')
  );
  actionDispatch.setProperty(
    'availableAttachments',
    attachments.map(({ folder, name }): Attachment => {
      return { id: name, folder, name };
    })
  );
}

export const convertToPdfAction: ConvertToPdfHttpRequestAction<Store> = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { httpClient }: ActionContext<Store, any>,
  category: StorageCategories,
  objectId: string,
  sourceFolderId: string,
  targetFolderId: string,
  attachmentNames: readonly string[],
  filename: string
): Promise<void> => {
  let pdfName = filename;
  if (!pdfName.endsWith(PDF_EXTENSION)) {
    pdfName += PDF_EXTENSION;
  }
  await httpClient.httpPostAsJSON(
    CoreBackendRoutes.CONVERT_ATTACHMENTS_TO_PDF(category, objectId, targetFolderId),
    {
      attachments: attachmentNames,
      filename: pdfName,
      sourceFolderId,
    }
  );
};

export const removePdfPageAction: RemovePdfPageHttpRequestAction<Store> = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { httpClient, getGlobalState }: ActionContext<Store, any>,
  category: StorageCategories,
  objectId: string,
  folder: string,
  filename: string
): Promise<void> => {
  await httpClient.httpGet(
    appendRegisteredBrowserSessionToken(
      CoreBackendRoutes.ATTACHMENT(category, objectId, folder, filename),
      nonnull(getGlobalState().session.infos).sessionToken
    ),
    'DELETE'
  );
};

interface ReloadElementAdditionalDataType {
  readonly currentAttachments: readonly Attachment[];
  readonly currentMetadata: Record<string, AttachmentMetadata>;
}

interface LoadGalleryAttachmentsActionResult {
  readonly attachments: readonly Attachment[];
  readonly metadata: Record<string, AttachmentMetadata>;
}

function getAttachmentsAndMetadata(
  attachmentDescs: readonly AttachmentDesc[],
  reloadElements?: ReloadElementAdditionalDataType
): LoadGalleryAttachmentsActionResult {
  if (isTruthy(reloadElements)) {
    const difference: Attachment[] = [];
    const metadataDifference: Record<string, AttachmentMetadata> = {};
    attachmentDescs
      .filter(
        (a) =>
          reloadElements.currentAttachments.find(
            (ca) => ca.folder === a.folder && ca.name === a.name
          ) === undefined
      )
      .forEach(({ etag, folder: theFolder, name, size, lastModified }) => {
        difference.push({ id: name, folder: theFolder, name });
        metadataDifference[name] = { etag, lastModified, size };
      });
    return {
      attachments: [...reloadElements.currentAttachments, ...difference],
      metadata: { ...reloadElements.currentMetadata, ...metadataDifference },
    };
  }

  const attachments: Attachment[] = [];
  const metadata: Record<string, AttachmentMetadata> = {};
  attachmentDescs.forEach(({ etag, folder: theFolder, name, size, lastModified }) => {
    attachments.push({ id: name, folder: theFolder, name });
    metadata[name] = { etag, lastModified, size };
  });
  return { attachments, metadata };
}

export async function loadAttachmentsGalleryAction<
  SD extends Store | SubcontractorStore,
  S extends AttachmentsBaseState,
>(
  { actionDispatch, httpClient, getState }: ActionContext<SD, S>,
  attachmentsUrl: string,
  reloadElements = false
): Promise<void> {
  let elementsToReload: ReloadElementAdditionalDataType | undefined;
  if (reloadElements) {
    const { attachments: currentAttachments, attachmentsMetadata } = getState();
    elementsToReload = {
      currentAttachments,
      currentMetadata: attachmentsMetadata,
    };
  }

  const attachmentDescs = await httpClient.httpGetAsJson<readonly AttachmentDesc[]>(attachmentsUrl);

  const { attachments, metadata } = getAttachmentsAndMetadata(attachmentDescs, elementsToReload);
  actionDispatch.reduce((initial) => {
    return {
      ...initial,
      attachments,
      attachmentsMetadata: metadata,
    };
  });
}

async function doImportAttachmentsAction<
  SD extends BaseStoreDefWithHttpClient,
  S extends BaseStoreState,
>(
  { httpClient, runWithProgressBar }: ActionContext<SD, S>,
  category: StorageCategories,
  objectId: string,
  folder: string,
  files: readonly File[],
  overwrite: boolean,
  callback?: (fileNamesMap: Record<string, string>) => Promise<void>,
  alternativeRoute?: string
): Promise<void> {
  const result: Record<string, string> = {};
  let totalBytesToUpload = files.reduce((p, c) => p + c.size, 0);
  await runWithProgressBar(totalBytesToUpload, async (monitor): Promise<void> => {
    let uploadedBytes = 0;
    let uploadedFilesCount = 0;
    let turnIntoIndeterminateTimeout: NodeJS.Timeout | undefined;
    const updateProgressBar = (bytesUpploadedIncrement: number) => {
      // If the upload process is not complete...
      if (uploadedFilesCount < files.length) {
        monitor.setLabel(
          i18next.t('refititCommonComponents:uploadFile.uploadProgress', {
            uploadedFilesCount,
            totalFilesCount: files.length,
            uploadedSize: (uploadedBytes / 1024 / 1024).toFixed(1),
            totalUploadSize: (totalBytesToUpload / 1024 / 1024).toFixed(1),
            // To compute the progress, we sum the bytes to upload and the files count
            // so that even very small files contribute to the progress
            // (if we only consider the uploaded bytes, small files may not contribute
            // to the progress percentage)
            progress: Math.round(
              ((uploadedBytes + uploadedFilesCount) / (totalBytesToUpload + files.length)) * 100
            ),
          }),
          bytesUpploadedIncrement
        );
      }
      // If the upload is complete but the server is long to respond, it may
      // mean that the server is processing the files (which occurs with videos
      // for example). After 1 second of wait, turn in indeterminate progress mode.
      // (see : https://bulma.io/documentation/elements/progress/#indeterminate)
      else if (turnIntoIndeterminateTimeout === undefined) {
        turnIntoIndeterminateTimeout = setTimeout(() => {
          monitor.setIndeterminateProgress();
          monitor.setLabel(
            i18next.t('refititCommonComponents:uploadFile.uploadCompleteWaitingForServer')
          );
        }, 1000 /* 1 second */);
      }
    };
    // Start upload... (parallel mode)
    await Promise.all(
      files.map(async (f) => {
        const xhr = new XMLHttpRequest();
        xhr.open(
          'PUT',
          alternativeRoute ??
            CoreBackendRoutes.ATTACHMENT_FOLDER(category, objectId, folder, overwrite)
        );
        httpClient.copyHttpHeadersTo(xhr);
        // Upload images
        return new Promise<void>((resolve, reject) => {
          let totalUploadSizeFixed = false;
          let lastLoaded = 0;
          xhr.onload = (ev): void => {
            if (xhr.status !== HttpErrorCodes.OK) {
              reject(Error(`HTTP error ${xhr.status} (${xhr.statusText}) : ${xhr.response}`));
            } else {
              const { response } = nonnull(ev.target) as XMLHttpRequest;
              const filesMap = JSON.parse(response) as Record<string, string>;
              // Copy file names
              keysOf(filesMap).forEach((k) => {
                result[k] = filesMap[k];
              });
              resolve();
            }
          };
          xhr.onerror = (ev): void => {
            log.error('Unexpected error while uploading resource', ev);
            reject(Error(`Unexpected error while uploading resource`));
          };
          xhr.upload.onprogress = (ev): void => {
            // Total upload file is sometimes more than the file size. The only way to be sure
            // about the amount of data to upload is to rely on the event's total field.
            // When a difference is detected, we increase the total size to upload.
            // https://stackoverflow.com/questions/49039383/xhr-upload-progress-event-total-different-from-target-files-size
            if (!totalUploadSizeFixed && ev.total > f.size) {
              totalBytesToUpload += ev.total - f.size;
              totalUploadSizeFixed = true;
            }
            const bytesUpploadedIncrement = ev.loaded - lastLoaded;
            // Increment the uploaded amount
            uploadedBytes += ev.loaded - lastLoaded;
            // Keep track of the last loaded amount for the current file
            lastLoaded = ev.loaded;
            // If the upload is complete...
            if (ev.loaded === ev.total) {
              // Update the file uploaded count
              uploadedFilesCount += 1;
            }
            // Update the progress status bar
            updateProgressBar(bytesUpploadedIncrement);
          };
          const formData = new FormData();
          formData.append(f.name, f);
          xhr.send(formData);
        });
      })
    );
    // If a timeout had been triggered, cancel it
    if (turnIntoIndeterminateTimeout !== undefined) {
      clearTimeout(turnIntoIndeterminateTimeout);
    }
  });
  if (callback) {
    await callback(result);
  }
}

export async function importAttachmentsAndOverrideAction(
  ctx: ActionContext<Store, StoreState>,
  category: StorageCategories,
  objectId: string,
  folder: string,
  files: readonly File[],
  callback?: (fileNamesMap: Record<string, string>) => Promise<void>
): Promise<void> {
  await doImportAttachmentsAction(ctx, category, objectId, folder, files, true, callback);
}

export async function importAttachmentsAction<
  SD extends BaseStoreDefWithHttpClient,
  S extends BaseStoreState,
>(
  ctx: ActionContext<SD, S>,
  category: StorageCategories,
  objectId: string,
  folder: string,
  files: readonly File[],
  callback?: (fileNamesMap: Record<string, string>) => Promise<void>,
  alternativeRoute?: string
): Promise<void> {
  await doImportAttachmentsAction(
    ctx,
    category,
    objectId,
    folder,
    files,
    false,
    callback,
    alternativeRoute
  );
}
