export type SequencePrefixProvider = () => string;

/**
 * Generates a unique identifier.
 *
 * The identifier is composed by :
 * - the timestamp
 * - a counter of identifiers for the same millisecond : this
 * value is padded to 2 characters, and if the counter becomes bigger than
 * the max value (ff in hex, zz in radix 36), an error is raised.
 *
 * With low radix values (decimal, hexadecimal), the counter may quickly
 * overflow if it is called really intensively (we're talking about generating
 * more than 100 or 256 identifiers in a single millisecond).
 *
 * With high radix (like 36), even if we do nothing else than generating
 * identifiers, we didn't manage to reach the max counter value for a single
 * millisecond (we reached value 'on' (which is 893), and the max value is
 * 1295).
 *
 * This component was partially inspired from https://www.npmjs.com/package/flake-idgen.
 * flake-idgen wasn't chosen because of the limitations of datacenter and workers.
 */
export class Sequence {
  /** The last generation time */
  private lastTime = 0;

  /** The counter (identifiers of the same millisecond) */
  private count = 0;

  /** The radix to use */
  private readonly radix: number;

  /** The maximum counter value */
  private readonly maxCounter: number;

  /** The suffix of the sequence ('c' for client side, 's' for server side) */
  private readonly suffix: string;

  /** The sequence prefix provider */
  private readonly prefixProvider: SequencePrefixProvider;

  /**
   * Default constructor.
   * @param prefix the prefix to use (c for client, s for server).
   * @param radix the radix to use for the string conversion (defaults to 36).
   */
  public constructor(suffix: 'c' | 's', prefix: string | SequencePrefixProvider = '', radix = 36) {
    if (typeof prefix !== 'function') {
      this.prefixProvider = (): string => prefix;
    } else {
      this.prefixProvider = prefix;
    }
    this.radix = radix;
    this.maxCounter = radix * radix - 1;
    this.suffix = suffix;
  }

  /**
   * Next identifier.
   */
  public next(): string {
    const time = new Date().getTime();
    if (time > this.lastTime) {
      this.lastTime = time;
      this.count = 0;
    } else if (this.count >= this.maxCounter) {
      throw Error(
        `Max identifier generation count reached for ${time} ms (radix: ${this.radix}, count:${this.count})`
      );
    } else {
      // Increment count for the same second
      this.count += 1;
    }
    const pad = this.count < this.radix ? '0' : '';
    /**
     * The recover the date from the ID, the two last digits must be deleted.
     * the count is concatenate after the date with the same conversion radix.
     *
     * It's max value is radix * radix - 1 which is equal to 1295 with the default value.
     * If converted with the radix, its max value is zz
     * The value 35 converted is equal to z, all biggest values will be converted with something
     * with two digits. And 0z equals z
     *
     * So the pad value is only here to ensure that the id will always have the save digit
     * count and the two last digits can always be removed safely to find creation date
     */
    return `${this.prefixProvider()}${time.toString(this.radix)}${pad}${this.count.toString(
      this.radix
    )}:${this.suffix}`;
  }
}
