import type {
  DatabaseConfigurator,
  DatabaseIndex,
} from '../repository/dao/typings/repository-dao.js';

export abstract class AbstractRepositoryExtender implements DatabaseConfigurator {
  private extenderVersion!: number;

  constructor(extenderVersion: number) {
    this.extenderVersion = extenderVersion;
  }

  public getExtenderVersion(): number {
    return this.extenderVersion;
  }

  abstract getDataBaseIndexesToCreateOrDelete(oldVersion: number): DatabaseIndex[];
}
