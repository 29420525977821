import type { WithFormValidationWarnings } from '@stimcar/libs-uikernel';
import type { FormWithValidationState } from '@stimcar/libs-uitoolkit';

export type EditDueDateFormData = {
  readonly dueDate: number;
};

export const EMPTY_EDIT_DUEDATE_FORM: WithFormValidationWarnings<EditDueDateFormData> = {
  dueDate: NaN,
  warnings: {},
};
export type EditDueDateDialogState = FormWithValidationState<EditDueDateFormData> & {
  readonly active: boolean;
  readonly deletionActive: boolean;
  readonly initialDueDate: number | null;
  readonly initialRefitEndDate: number | null;
};

export const EMPTY_EDIT_DUE_DATE_DIALOG_STATE: EditDueDateDialogState = {
  active: false,
  deletionActive: false,
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formWarning: undefined,
  initialDueDate: null,
  initialRefitEndDate: null,
  formData: EMPTY_EDIT_DUEDATE_FORM,
};

export type EditRefitEndDateFormData = {
  readonly refitEndDate: number;
};

export const EMPTY_EDIT_REFITENDDATE_FORM: WithFormValidationWarnings<EditRefitEndDateFormData> = {
  refitEndDate: NaN,
  warnings: {},
};

export type EditRefitEndDateDialogState = FormWithValidationState<EditRefitEndDateFormData> & {
  readonly active: boolean;
  readonly deletionActive: boolean;
  readonly initialDueDate: number | null;
  readonly initialRefitEndDate: number | null;
};

export const EMPTY_EDIT_REFIT_END_DATE_DIALOG_STATE: EditRefitEndDateDialogState = {
  active: false,
  deletionActive: false,
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formWarning: undefined,
  initialDueDate: null,
  initialRefitEndDate: null,
  formData: EMPTY_EDIT_REFITENDDATE_FORM,
};

export type EditMileageFormData = {
  readonly mileage: string;
};

export const EMPTY_EDIT_MILEAGE_FORM: WithFormValidationWarnings<EditMileageFormData> = {
  mileage: '',
  warnings: {},
};
export type EditMileageDialogState = FormWithValidationState<EditMileageFormData> & {
  readonly active: boolean;
  readonly initialMileage: number;
};

export const EMPTY_EDIT_MILEAGE_DIALOG_STATE: EditMileageDialogState = {
  active: false,
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formWarning: undefined,
  initialMileage: 0,
  formData: EMPTY_EDIT_MILEAGE_FORM,
};
