/* eslint-disable @typescript-eslint/no-explicit-any */
import { type TFunction } from 'i18next';
import { useCallback, useMemo } from 'react';
import type {
  ActionContext,
  AnyStoreDef,
  NoArgActionCallback,
  ObjectStateType,
  StoreStateSelector,
  WithFormValidationWarnings,
} from '@stimcar/libs-uikernel';
import { applyAsyncCallbackSequentially, keysOf } from '@stimcar/libs-kernel';
import { useActionCallback, useSelectorWithChangeTrigger } from '@stimcar/libs-uikernel';

export type FormWithValidationState<S extends ObjectStateType> = {
  // Allow to know if the submit button has been clicked once : the form warnings will remain hidden
  // until first submit
  readonly formSubmitClickedOnce: boolean;
  // Helps to avoid re-submitting a form that has already been submitted
  readonly formSubmitted: boolean;
  // The form data containing all fields data (with the corresponding warnings)
  // Field warnings are stored in a field named 'warning' ; under this object
  // we potentially find one warning per field indexed by the field name
  // Example :
  // {
  //    id: '',
  //    name : 'the name',
  //    count: -1,
  //    warnings: {
  //      id: "The identifier must be set",
  //      count: "Count must be positive",
  //    }
  // }
  readonly formData: WithFormValidationWarnings<S>;
  // The form global warning (that complete field specific warnings)
  readonly formWarning?: string;
};

export const FORM_WITH_VALIDATION_STATE_PICK_KEYS: (keyof FormWithValidationState<ObjectStateType>)[] =
  ['formSubmitClickedOnce', 'formSubmitted', 'formWarning', 'formData'];

export const EMPTY_FORM_WITH_VALIDATION_STATE: Omit<
  FormWithValidationState<ObjectStateType>,
  'formData'
> = {
  formSubmitClickedOnce: false,
  formSubmitted: false,
};

export type CheckFormConsistencyInput<
  SD extends AnyStoreDef,
  S extends FormWithValidationState<ObjectStateType>,
> = SD['actionContext'] & {
  readonly formState: S;
  readonly t: TFunction;
};

export type CheckFormFieldInput<
  SD extends AnyStoreDef,
  S extends FormWithValidationState<ObjectStateType>,
  K extends keyof ExtractFormData<S>,
> = SD['actionContext'] &
  CheckFormConsistencyInput<SD, S> & {
    readonly value: S['formData'][K];
  };

export type ExtractFormData<S extends FormWithValidationState<ObjectStateType>> = Omit<
  S['formData'],
  'warnings'
>;

export type CheckFormFieldContentActions<
  SD extends AnyStoreDef,
  S extends FormWithValidationState<ObjectStateType>,
  ARGS extends any[] = [],
> = {
  [K in keyof ExtractFormData<S>]?: (
    input: CheckFormFieldInput<SD, S, K>,
    ...args: ARGS
  ) => Promise<string | undefined> | (string | undefined);
};

export const useMergedCheckFormFieldContentActions = <
  SD extends AnyStoreDef,
  S extends FormWithValidationState<ObjectStateType>,
  ARGS extends any[] = [],
>(
  ...actionsList: (CheckFormFieldContentActions<SD, S, ARGS> | undefined)[]
): CheckFormFieldContentActions<SD, S, ARGS> => {
  return useMemo(() => {
    // Collect keys
    const keys: (keyof CheckFormFieldContentActions<SD, S, ARGS>)[] = [];
    actionsList.forEach((actions) => {
      if (actions) {
        keysOf(actions).forEach((k) => {
          if (!keys.includes(k)) {
            keys.push(k);
          }
        });
      }
    });
    const merged: CheckFormFieldContentActions<SD, S, ARGS> = {};
    keys.forEach((k) => {
      merged[k] = async (
        input: CheckFormFieldInput<SD, S, any>,
        ...args: ARGS
      ): Promise<string | undefined> => {
        let warning: string | undefined;
        await applyAsyncCallbackSequentially(actionsList, async (actions) => {
          if (actions) {
            const check = actions[k];
            if (warning === undefined && check) {
              warning = await check(input, ...args);
            }
          }
        });
        return warning;
      };
    });
    return merged;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...actionsList]);
};

export type CheckFormConsistencyAction<
  SD extends AnyStoreDef,
  S extends FormWithValidationState<ObjectStateType>,
  ARGS extends any[] = [],
> = (
  input: CheckFormConsistencyInput<SD, S>,
  ...args: ARGS
) => Promise<string | undefined> | (string | undefined);

export const useMergedCheckFormConsistencyAction = <
  SD extends AnyStoreDef,
  S extends FormWithValidationState<ObjectStateType>,
  ARGS extends any[] = [],
>(
  ...actions: (CheckFormConsistencyAction<SD, S, ARGS> | undefined)[]
): CheckFormConsistencyAction<SD, S, ARGS> => {
  return useCallback(
    async (input: CheckFormConsistencyInput<SD, S>, ...args: ARGS): Promise<string | undefined> => {
      let warning: string | undefined;
      await applyAsyncCallbackSequentially(actions, async (action) => {
        if (warning === undefined && action) {
          warning = await action(input, ...args);
        }
      });
      return warning;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...actions]
  );
};

export type FormWithValidationProps<
  SD extends AnyStoreDef,
  S extends FormWithValidationState<ObjectStateType>,
  ARGS extends any[],
> = {
  readonly $: StoreStateSelector<SD, S>;
  // Give the list of mandatory fields among the form
  readonly mandatoryFields:
    | readonly (keyof ExtractFormData<S>)[]
    | ((formData: S['formData'], ...args: ARGS) => readonly (keyof ExtractFormData<S>)[]);
  // Checks the form fields content action. The given object contains the
  // check methods indexed by the form field names. For example in a login
  // form that contains two fields : login and password, the check object
  // may contain two fields named login and password, each of them containing
  // a check method.
  readonly checkFieldContentActions?: CheckFormFieldContentActions<SD, S, ARGS>;
  // This is a function that is intended to check the global form consistency.
  // This function is only called if all mandatory fields are present and
  // all fields content have been successfully checked
  readonly checkFormConsistencyAction?: CheckFormConsistencyAction<SD, S, ARGS>;
  // This action is the one that is supposed to be called when all rules
  // have been successfully checked
  readonly submitValidDataAction: NoArgActionCallback<SD>;
  // Translation function
  readonly t: TFunction;
};

function isEmpty(value: any): boolean {
  let result: boolean;
  if (typeof value === 'boolean' || typeof value === 'number') {
    // boolean and number cannot be empty
    result = false;
  } else if (typeof value === 'string') {
    result = value.trim() === '';
  } else if (Array.isArray(value)) {
    result = value.length === 0;
  } else {
    throw new Error(`Unexpected form data attribute format : ${value}`);
  }
  return result;
}

export function useFormWithValidation<
  SD extends AnyStoreDef,
  S extends FormWithValidationState<ObjectStateType>,
  ARGS extends any[] = [],
>(
  props: FormWithValidationProps<SD, S, ARGS>,
  ...args: ARGS
): [
  /* onSubmit */ NoArgActionCallback<SD>,
  /* onChange */ NoArgActionCallback<SD>,
  /* Selector with onChangeTrigger */ StoreStateSelector<SD, S['formData']>,
] {
  const {
    $,
    mandatoryFields,
    checkFieldContentActions,
    checkFormConsistencyAction,
    submitValidDataAction,
    t,
  } = props;

  const onChangeActionCallback = useActionCallback(
    async function onFormChangeAction({
      actionDispatch,
      getState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      globalActionDispatch,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      getGlobalState,
      ...ctx
    }: ActionContext<SD, S>): Promise<void> {
      const formState = getState();
      const { formData, formSubmitClickedOnce } = formState;
      const formDispatch = actionDispatch.scopeProperty('formData');
      const wDispatch = formDispatch.scopeProperty('warnings');
      actionDispatch.setProperty('formWarning', undefined);
      formDispatch.setProperty('warnings', {} as any);

      if (formSubmitClickedOnce) {
        /**
         * Check mandatory fields
         */
        let hasMissingRequiredField = false;
        const mandatoryFieldsList: readonly (keyof ExtractFormData<S>)[] =
          typeof mandatoryFields === 'function'
            ? mandatoryFields(formData, ...args)
            : mandatoryFields;

        mandatoryFieldsList.forEach((f): void => {
          if (isEmpty(formData[f])) {
            wDispatch.setProperty(
              f as string,
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
              t('reactglobals:warnings.missingRequiredField') as any
            );
            hasMissingRequiredField = true;
          }
        });

        /**
         * Check formats.
         */
        let hasFieldWithInvalidContent = false;
        if (checkFieldContentActions) {
          await applyAsyncCallbackSequentially(Object.keys(formData), async (k): Promise<void> => {
            const value = formData[k];
            // Only check the format if it is non empty
            if (
              (typeof value === 'string' ||
                typeof value === 'boolean' ||
                typeof value === 'number' ||
                Array.isArray(value)) &&
              !isEmpty(value) &&
              k !== 'warnings'
            ) {
              const check = checkFieldContentActions[k];
              if (check) {
                const warning = await check(
                  {
                    ...ctx,
                    formState,
                    value: value as S['formData'][string],
                    t,
                  },
                  ...args
                );
                if (warning) {
                  hasFieldWithInvalidContent = true;
                  wDispatch.setProperty(k, warning as S['formData']['warnings'][string]);
                }
              }
            }
          });
        }

        if (hasMissingRequiredField) {
          actionDispatch.setProperty(
            'formWarning',
            t('reactglobals:warnings.missingRequiredFields')
          );
        } else if (hasFieldWithInvalidContent) {
          actionDispatch.setProperty(
            'formWarning',
            t('reactglobals:warnings.invalidFieldsContent')
          );
        } else if (checkFormConsistencyAction) {
          const warning = await checkFormConsistencyAction(
            {
              ...ctx,
              formState: getState(),
              t,
            },
            ...args
          );
          if (warning) {
            actionDispatch.setProperty('formWarning', warning);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, mandatoryFields, checkFieldContentActions, checkFormConsistencyAction, ...args],
    $
  );

  const onSubmit = useActionCallback(
    async function onSubmitFormAction({
      actionDispatch,
      getState,
    }: ActionContext<SD, S>): Promise<void> {
      actionDispatch.setProperty('formSubmitClickedOnce', true);
      await actionDispatch.execCallback(onChangeActionCallback);
      if (!getState().formWarning && !getState().formSubmitted) {
        actionDispatch.setProperty('formSubmitted', true);
        await actionDispatch.execCallback(submitValidDataAction);
      }
    },
    [onChangeActionCallback, submitValidDataAction],
    $
  );
  const $formDataWithChangeTrigger = useSelectorWithChangeTrigger(
    $.$formData,
    onChangeActionCallback
  ) as StoreStateSelector<SD, S['formData']>;
  return useMemo(
    () => [onSubmit, onChangeActionCallback, $formDataWithChangeTrigger],
    [onChangeActionCallback, onSubmit, $formDataWithChangeTrigger]
  );
}
