import type { JSX } from 'react';
import React from 'react';

interface ScrollableContainerContentForStoryBooktProps {
  readonly prefix: string;
}

export function ScrollableContainerContentForStoryBook({
  prefix,
}: ScrollableContainerContentForStoryBooktProps): JSX.Element {
  return (
    <>
      {
        /* Array of 1, 2, 3, .... , 30 */ [...Array(30).keys()].map((index) => (
          <div className="box" key={`${prefix}-${index}`}>{`Scrollable element #${index}`}</div>
        ))
      }
    </>
  );
}
