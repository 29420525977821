import { isTruthy } from '../asserts.js';
import type { Entity } from './kernel.js';

export const MAIN_EXTERNAL_STANDARD_PICTURE_KEYS: string[] = [
  'LEFT_SIDE',
  'FRONT',
  'RIGHT_SIDE',
  'REAR',
];

export const THREE_QUARTERS_EXTERNAL_STANDARD_PICTURE_KEYS: string[] = [
  'FRONT_LEFT_THREE_QUARTERS',
  'FRONT_RIGHT_THREE_QUARTERS',
  'REAR_RIGHT_THREE_QUARTERS',
  'REAR_LEFT_THREE_QUARTERS',
];

export const OTHER_EXTERNAL_STANDARD_PICTURE_KEYS: string[] = [
  'TRUNK_INTERIOR',
  'ENGINE_COMPARTMENT',
];

export const EXTERNAL_STANDARD_PICTURE_KEYS: string[] = [
  ...MAIN_EXTERNAL_STANDARD_PICTURE_KEYS,
  ...THREE_QUARTERS_EXTERNAL_STANDARD_PICTURE_KEYS,
  ...OTHER_EXTERNAL_STANDARD_PICTURE_KEYS,
];

export const INTERNAL_STANDARD_PICTURE_KEYS: string[] = [
  'FRONT_RIGHT_DOOR',
  'REAR_SEAT',
  'COMPLETE_DASHBOARD',
  'STEERING_WHEEL_AND_METER',
  'GEARBOX',
  'CENTRAL_DASHBOARD',
  'CONTROL_SCREEN_GPS',
];

export const STANDARD_PICTURE_KEYS: string[] = [
  ...EXTERNAL_STANDARD_PICTURE_KEYS,
  ...INTERNAL_STANDARD_PICTURE_KEYS,
];

export type MainExternalStandardPictureKey = (typeof MAIN_EXTERNAL_STANDARD_PICTURE_KEYS)[number];
export type ThreeQuartersExternalStandardPictureKey =
  (typeof THREE_QUARTERS_EXTERNAL_STANDARD_PICTURE_KEYS)[number];
export type OtherExternalStandardPictureKey = (typeof OTHER_EXTERNAL_STANDARD_PICTURE_KEYS)[number];
export type ExternalStandardPictureKey = (typeof EXTERNAL_STANDARD_PICTURE_KEYS)[number];
export type InternalStandardPictureKey = (typeof INTERNAL_STANDARD_PICTURE_KEYS)[number];
export type StandardPictureKey = (typeof STANDARD_PICTURE_KEYS)[number];

export type StandardPictureConfEntry = Entity & {
  readonly key: StandardPictureKey;
  readonly category: StandardPictureCategory;
  readonly displayed: boolean;
  readonly improveImage: boolean;
};

export type StandardPictureInfo = {
  readonly id: string;
  readonly index: number;
};

export type StandardPictures = Record<string, StandardPictureInfo>;

export type StandardPictureCategory = 'external' | 'internal';

export type StandardPicturesByCategory = Record<StandardPictureCategory, StandardPictures>;

export const MAIN_EXTERNAL_STANDARD_PICTURES: Record<
  MainExternalStandardPictureKey,
  StandardPictureInfo
> = {
  LEFT_SIDE: { id: 'leftSide.webp', index: 7 },
  FRONT: { id: 'front.webp', index: 1 },
  RIGHT_SIDE: { id: 'rightSide.webp', index: 3 },
  REAR: { id: 'rear.webp', index: 5 },
} as const;

export const THREE_QUARTERS_EXTERNAL_STANDARD_PICTURES: Record<
  ThreeQuartersExternalStandardPictureKey,
  StandardPictureInfo
> = {
  FRONT_LEFT_THREE_QUARTERS: {
    id: 'frontLeftThreeQuarters.webp',
    index: 0,
  },
  FRONT_RIGHT_THREE_QUARTERS: {
    id: 'frontRightThreeQuarters.webp',
    index: 2,
  },
  REAR_RIGHT_THREE_QUARTERS: {
    id: 'rearRightThreeQuarters.webp',
    index: 4,
  },
  REAR_LEFT_THREE_QUARTERS: {
    id: 'rearLeftThreeQuarters.webp',
    index: 6,
  },
} as const;

export const OTHER_EXTERNAL_STANDARD_PICTURES: Record<
  OtherExternalStandardPictureKey,
  StandardPictureInfo
> = {
  TRUNK_INTERIOR: { id: 'trunkInterior.webp', index: 8 },
  ENGINE_COMPARTMENT: {
    id: 'engineCompartment.webp',
    index: 9,
  },
};

export const EXTERNAL_STANDARD_PICTURES: Record<ExternalStandardPictureKey, StandardPictureInfo> = {
  ...MAIN_EXTERNAL_STANDARD_PICTURES,
  ...THREE_QUARTERS_EXTERNAL_STANDARD_PICTURES,
  ...OTHER_EXTERNAL_STANDARD_PICTURES,
};

export const INTERNAL_STANDARD_PICTURES: Record<InternalStandardPictureKey, StandardPictureInfo> = {
  FRONT_RIGHT_DOOR: {
    id: 'frontRightDoorInternal.webp',
    index: 10,
  },
  REAR_SEAT: { id: 'rearSeat.webp', index: 11 },
  COMPLETE_DASHBOARD: {
    id: 'completeDashboard.webp',
    index: 12,
  },
  STEERING_WHEEL_AND_METER: {
    id: 'steeringWheelAndMeter.webp',
    index: 13,
  },
  GEARBOX: { id: 'gearbox.webp', index: 14 },
  CENTRAL_DASHBOARD: {
    id: 'centralDashboard.webp',
    index: 15,
  },
  CONTROL_SCREEN_GPS: {
    id: 'controlScreenGps.webp',
    index: 16,
  },
} as const;

export const STANDARD_PICTURES: Record<StandardPictureKey, StandardPictureInfo> = {
  ...EXTERNAL_STANDARD_PICTURES,
  ...INTERNAL_STANDARD_PICTURES,
};

export const STANDARD_PICTURES_BY_CATEGORY: StandardPicturesByCategory = {
  external: {
    ...EXTERNAL_STANDARD_PICTURES,
  },
  internal: {
    ...INTERNAL_STANDARD_PICTURES,
  },
} as const;

export type MainExternalStandardPictureId =
  (typeof MAIN_EXTERNAL_STANDARD_PICTURES)[keyof typeof MAIN_EXTERNAL_STANDARD_PICTURES]['id'];
export type ExternalStandardPictureId =
  (typeof EXTERNAL_STANDARD_PICTURES)[keyof typeof EXTERNAL_STANDARD_PICTURES]['id'];
export type InternalStandardPictureId =
  (typeof INTERNAL_STANDARD_PICTURES)[keyof typeof INTERNAL_STANDARD_PICTURES]['id'];
export type StandardPictureId = (typeof STANDARD_PICTURES)[keyof typeof STANDARD_PICTURES]['id'];

function getSortedPicturesIds<T>(picturesData: Record<string, StandardPictureInfo>): readonly T[] {
  const ids: readonly string[] = Object.values(picturesData)
    .sort(({ index: index1 }, { index: index2 }) => index1 - index2)
    .map(({ id }) => id);
  return ids as readonly T[];
}

export function getMainExternalPictureIds(): readonly MainExternalStandardPictureId[] {
  return getSortedPicturesIds<MainExternalStandardPictureId>(MAIN_EXTERNAL_STANDARD_PICTURES);
}

export function getExternalPictureIds(): readonly ExternalStandardPictureId[] {
  return getSortedPicturesIds<ExternalStandardPictureId>(EXTERNAL_STANDARD_PICTURES);
}

export function getAllStandardPictureIds(): readonly StandardPictureId[] {
  return getSortedPicturesIds<StandardPictureId>(STANDARD_PICTURES);
}

/**
 * Return the key corresponding to a standard picture ID
 * Example with : 'leftSide.webp' returns 'LEFT_SIDE'
 * given that the structure is STANDARD_PICTURES[LEFT_SIDE] = {id : 'leftSide.webp', index: 0}
 * @param standardPictureId
 * @returns corresponding key or null if Id does not correspond to any entry
 */
export function getStandardPictureKeyFromId(standardPictureId: string): string | null {
  const foundEntry = Object.entries(STANDARD_PICTURES).find(
    ([, { id }]) => id === standardPictureId
  );
  if (isTruthy(foundEntry)) {
    return foundEntry[0];
  }
  return null;
}

export function getOrderedMarketplacePictureIds(): readonly StandardPictureId[] {
  return [
    EXTERNAL_STANDARD_PICTURES.FRONT_LEFT_THREE_QUARTERS.id,
    EXTERNAL_STANDARD_PICTURES.FRONT.id,
    EXTERNAL_STANDARD_PICTURES.FRONT_RIGHT_THREE_QUARTERS.id,
    EXTERNAL_STANDARD_PICTURES.RIGHT_SIDE.id,
    EXTERNAL_STANDARD_PICTURES.REAR_RIGHT_THREE_QUARTERS.id,
    EXTERNAL_STANDARD_PICTURES.REAR.id,
    EXTERNAL_STANDARD_PICTURES.REAR_LEFT_THREE_QUARTERS.id,
    EXTERNAL_STANDARD_PICTURES.LEFT_SIDE.id,
    EXTERNAL_STANDARD_PICTURES.TRUNK_INTERIOR.id,
    EXTERNAL_STANDARD_PICTURES.ENGINE_COMPARTMENT.id,
    INTERNAL_STANDARD_PICTURES.COMPLETE_DASHBOARD.id,
    INTERNAL_STANDARD_PICTURES.STEERING_WHEEL_AND_METER.id,
    INTERNAL_STANDARD_PICTURES.CENTRAL_DASHBOARD.id,
    INTERNAL_STANDARD_PICTURES.CONTROL_SCREEN_GPS.id,
    INTERNAL_STANDARD_PICTURES.GEARBOX.id,
    INTERNAL_STANDARD_PICTURES.FRONT_RIGHT_DOOR.id,
    INTERNAL_STANDARD_PICTURES.REAR_SEAT.id,
  ];
}
