/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { DisplayableSparePart, WorkshopOperation } from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { globalHelpers, shortDayMonthYearDateFormatOptions } from '@stimcar/libs-base';
import { isTruthy, keysOf } from '@stimcar/libs-kernel';
import { useGetState } from '@stimcar/libs-uikernel';
import { FaIcon, ToggleNestedButton } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../app/state/typings/store.js';
import type { ComputeAttachmentUrlCallback } from '../../attachments/typings/attachment.js';
import { canCompleteOperation } from '../../../../app/utils/operatorUtils.js';
import { ColumnedCarviewAndAttachmentThumbnailsDisplayer } from '../../photosdisplayer/ColumnedCarviewAndAttachmentThumbnailsDisplayer.js';

interface Props {
  readonly receivedSpareParts: readonly DisplayableSparePart[];
  readonly missingSpareParts: readonly DisplayableSparePart[];
}

const SORTER = (s1: DisplayableSparePart, s2: DisplayableSparePart): number =>
  s1.displayLabel.localeCompare(s2.displayLabel);

export function WorkshopDisplaySparePartAsTextWithEmphaseOnMissing({
  receivedSpareParts,
  missingSpareParts,
}: Props): JSX.Element {
  const [t] = useTranslation('libComponents');

  const anomalySpareParts = useMemo(() => {
    return missingSpareParts.filter((sp) => sp.estimatedDateOfReception === -1).sort(SORTER);
  }, [missingSpareParts]);

  const lateMissingSpareParts = useMemo(() => {
    return missingSpareParts
      .slice()
      .filter(
        (sp) =>
          sp.estimatedDateOfReception !== null &&
          sp.estimatedDateOfReception > 0 &&
          sp.estimatedDateOfReception < Date.now()
      )
      .sort(SORTER);
  }, [missingSpareParts]);

  const otherMissingSpareParts = useMemo(() => {
    return missingSpareParts
      .slice()
      .filter((sp) => !anomalySpareParts.includes(sp) && !lateMissingSpareParts.includes(sp))
      .sort(SORTER);
  }, [anomalySpareParts, lateMissingSpareParts, missingSpareParts]);
  return (
    <>
      {missingSpareParts.length > 0 && (
        <FaIcon
          id="exclamation-triangle"
          iconColor={
            anomalySpareParts.length + lateMissingSpareParts.length > 0 ? 'brown' : 'orange'
          }
          tooltip={t('workshopPost.missingSparePartTooltip')}
        />
      )}
      {[...anomalySpareParts, ...lateMissingSpareParts, ...otherMissingSpareParts].map(
        (sp, i, array): JSX.Element => {
          return (
            <React.Fragment key={sp.id}>
              <span
                style={{
                  color:
                    anomalySpareParts.includes(sp) || lateMissingSpareParts.includes(sp)
                      ? 'brown'
                      : 'orange',
                  fontWeight: 'bold',
                }}
                title={t('workshopPost.missingSparePartTooltip')}
              >
                {`${sp.displayLabel}${
                  sp.estimatedDateOfReception !== null && sp.estimatedDateOfReception !== undefined
                    ? ` [${
                        sp.estimatedDateOfReception === -1
                          ? t('workshopPost.noDelayMissingSparePart')
                          : ''
                      }${
                        sp.estimatedDateOfReception >= 0
                          ? globalHelpers.convertTimestampToDateString(
                              sp.estimatedDateOfReception,
                              shortDayMonthYearDateFormatOptions
                            )
                          : ''
                      }]`
                    : ''
                }`}
              </span>
              {sp.commentForWorkshop && (
                <span
                  style={{
                    color:
                      anomalySpareParts.includes(sp) || lateMissingSpareParts.includes(sp)
                        ? 'brown'
                        : 'orange',
                    fontWeight: 'bold',
                  }}
                >
                  {`: ${sp.commentForWorkshop}`}
                </span>
              )}
              {array.length > 0 && i < array.length - 1 ? ', ' : ''}
            </React.Fragment>
          );
        }
      )}
      {receivedSpareParts.map(
        (sp, i): JSX.Element => (
          <React.Fragment key={sp.id}>
            {sp.displayLabel}
            {i < receivedSpareParts.length - 1 ? ', ' : ''}
          </React.Fragment>
        )
      )}
    </>
  );
}

interface SingleOperationLineProps extends AppProps<Store> {
  readonly operation: WorkshopOperation;
  readonly $expandedOperationIds: StoreStateSelector<Store, readonly string[]>;
  readonly computeAttachmentUrlCallback: ComputeAttachmentUrlCallback;
  readonly kanbanId: string;
  readonly toggleOperationFinished?: (operationId: string) => Promise<void>;
}
function SingleOperationLine({
  operation,
  $expandedOperationIds,
  $gs,
  computeAttachmentUrlCallback,
  kanbanId,
  toggleOperationFinished,
}: SingleOperationLineProps): JSX.Element {
  const [t] = useTranslation('workshop');
  const toggle = async (): Promise<void> => {
    if (isTruthy(toggleOperationFinished)) {
      await toggleOperationFinished(operation.id);
    }
  };

  const permissions = useGetState($gs.$session.$user.optChaining().$permissions) ?? {};
  const canBeToggled = canCompleteOperation(operation, permissions);

  const expandedOperationIds = useGetState($expandedOperationIds);
  return (
    <React.Fragment key={operation.id}>
      <tr className={operation.completionDate ? ' is-green-light' : ''}>
        <td>
          <ToggleNestedButton
            id={operation.id}
            hasChildren={operation.packageDealAttachments.length > 0}
            $expandedIds={$expandedOperationIds}
            label={operation.carElement.label ?? t('post.miscCarElementLabel')}
          />
        </td>
        <td style={{ textAlign: 'right' }}>{operation.workload.toFixed(2)}</td>
        <td>
          <WorkshopDisplaySparePartAsTextWithEmphaseOnMissing
            receivedSpareParts={operation.receivedSpareParts}
            missingSpareParts={operation.missingSpareParts}
          />
        </td>
        <td>
          {isTruthy(operation.completionDate) && operation.subcontractor !== null
            ? t('post.realizedBySubcontractor', operation)
            : operation.packageDealComment}
        </td>
        <td>
          {isTruthy(toggleOperationFinished) && (
            <input
              id={operation.id}
              type="checkbox"
              className="m-r-sm switch"
              checked={!!operation.completionDate}
              disabled={!canBeToggled}
              onChange={toggle}
            />
          )}
          <label htmlFor={operation.id}>&nbsp;</label>
        </td>
      </tr>
      {expandedOperationIds.includes(operation.id) && (
        <tr>
          <td colSpan={5}>
            <ColumnedCarviewAndAttachmentThumbnailsDisplayer
              category={operation.carElement.category}
              selectedShapes={operation.carElement.shapes}
              attachments={operation.packageDealAttachments}
              computeAttachmentUrl={computeAttachmentUrlCallback}
              $imageModal={$gs.$imageModal}
              kanbanId={kanbanId}
              photoHeight={125}
            />
          </td>
        </tr>
      )}
    </React.Fragment>
  );
}
export interface WorkshopPostOperationsProps extends AppProps<Store> {
  readonly kanbanId: string;
  readonly operations: Record<string, readonly WorkshopOperation[]>;
  readonly toggleOperationFinished?: (operationId: string) => Promise<void>;
  readonly $expandedOperationIds: StoreStateSelector<Store, readonly string[]>;
  readonly computeAttachmentUrlCallback: ComputeAttachmentUrlCallback;
  readonly postLabel: string | undefined;
}

export function WorkshopPostOperations({
  operations,
  kanbanId,
  toggleOperationFinished,
  $expandedOperationIds,
  postLabel,
  $gs,
  computeAttachmentUrlCallback,
}: WorkshopPostOperationsProps): JSX.Element {
  const [t] = useTranslation('workshop');

  const operationLabels = keysOf(operations).slice().sort();
  if (operationLabels.length === 0) {
    return <p className="title is-5">{t('post.noOperationsOnKanban', { postLabel })}</p>;
  }

  return (
    <>
      {operationLabels.map((operationLabel): JSX.Element => {
        const operationItems = operations[operationLabel];
        let areAllOperationsFinished = true;
        operationItems.forEach(({ completionDate }) => {
          areAllOperationsFinished = areAllOperationsFinished && !!completionDate;
        });
        return (
          <div
            key={operationLabel}
            className={`box${areAllOperationsFinished ? ' is-green-light' : ''}`}
          >
            <p className="title is-4" style={{ marginBottom: 0 }}>
              {operationLabel}
            </p>
            <table
              className={`table is-fullwidth${areAllOperationsFinished ? ' is-green-light' : ''}`}
            >
              <thead>
                <tr>
                  <th style={{ width: '30%' }}>{t('post.table.carElement')}</th>
                  <th style={{ width: '4%' }}>{t('post.table.workload')}</th>
                  <th style={{ width: '30%' }}>{t('post.table.spareParts')}</th>
                  <th style={{ width: '35%' }}>{t('post.table.comment')}</th>
                  {isTruthy(toggleOperationFinished) && (
                    <th style={{ width: '1%' }}>{t('post.table.status')}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {operationItems.map(
                  (operation): JSX.Element => (
                    <SingleOperationLine
                      $gs={$gs}
                      computeAttachmentUrlCallback={computeAttachmentUrlCallback}
                      $expandedOperationIds={$expandedOperationIds}
                      kanbanId={kanbanId}
                      operation={operation}
                      toggleOperationFinished={toggleOperationFinished}
                      key={operation.id}
                    />
                  )
                )}
              </tbody>
            </table>
          </div>
        );
      })}
    </>
  );
}
