import type { JSX } from 'react';
import React from 'react';
import type { ActionContext, AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { FormFieldEntry } from '@stimcar/libs-uitoolkit';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { useSortableFormFielEntries } from './useSortedFormFielEntries.js';

interface RadioButtonProps<T extends string | number | boolean> extends FormFieldEntry<T> {
  readonly groupId: string;
  readonly disabled?: boolean;
  readonly onChangeHandlerCallback: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  readonly checked: boolean;
  readonly withMargin: boolean;
}

function RadioButton<T extends string | number | boolean>({
  groupId,
  id: buttonId,
  label: buttonLabel,
  disabled,
  onChangeHandlerCallback,
  checked,
  withMargin,
}: RadioButtonProps<T>): JSX.Element {
  const fieldId = `${groupId}_${String(buttonId)}`;
  return (
    <div key={fieldId} className={withMargin ? 'm-r-md' : ''}>
      <input
        type="radio"
        disabled={disabled}
        name={groupId}
        id={fieldId}
        value={String(buttonId)}
        onChange={onChangeHandlerCallback}
        checked={checked}
      />
      <label htmlFor={fieldId} className="radio p-l-sm">
        {buttonLabel}
      </label>
    </div>
  );
}

function onChangeAction<SD extends AnyStoreDef, T extends string | number | boolean>(
  { actionDispatch, getState }: ActionContext<SD, T>,
  event: React.ChangeEvent<HTMLInputElement>
) {
  const selectedButtonId = getState();
  switch (typeof selectedButtonId) {
    case 'boolean':
      actionDispatch.setValue((event.target.value.trim().toLowerCase() === 'true') as T);
      break;
    case 'number':
      actionDispatch.setValue(Number.parseFloat(event.target.value) as T);
      break;
    default:
      actionDispatch.setValue(event.target.value as T);
  }
}
export type RadioButtonsProps<SD extends AnyStoreDef, T extends string | number | boolean> = {
  readonly id: string;
  readonly disabled?: boolean;
  readonly entries: readonly (FormFieldEntry<T> | T)[];
  readonly $: StoreStateSelector<SD, T>;
  readonly sortEntries?: boolean;
  readonly radioGroupLayout: 'horizontal' | 'vertical';
};

export function RadioButtons<SD extends AnyStoreDef, T extends string | number | boolean>({
  id,
  entries,
  disabled = false,
  $,
  sortEntries = false,
  radioGroupLayout = 'horizontal',
}: RadioButtonsProps<SD, T>): JSX.Element {
  const selectedButtonId = useGetState($);
  const onChangeActionCallback = useActionCallback(onChangeAction, [], $);

  const sortableEntries = useSortableFormFielEntries({ entries, sortEntries });

  return (
    <>
      {radioGroupLayout === 'horizontal' ? (
        <div
          style={{
            border: 'none',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            breakBefore: 'always',
          }}
        >
          {sortableEntries.map(
            ({ id: buttonId, label: buttonLabel }): JSX.Element => (
              <RadioButton
                key={String(buttonId)}
                groupId={id}
                id={buttonId}
                label={buttonLabel}
                checked={selectedButtonId === buttonId}
                onChangeHandlerCallback={onChangeActionCallback}
                disabled={disabled}
                withMargin
              />
            )
          )}
        </div>
      ) : (
        <>
          {sortableEntries.map(
            ({ id: buttonId, label: buttonLabel }): JSX.Element => (
              <RadioButton
                key={String(buttonId)}
                groupId={id}
                id={buttonId}
                label={buttonLabel}
                checked={selectedButtonId === buttonId}
                onChangeHandlerCallback={onChangeActionCallback}
                disabled={disabled}
                withMargin={false}
              />
            )
          )}
        </>
      )}
    </>
  );
}
