import type { TFunction } from 'i18next';
import React from 'react';
import type { Environment, Repository } from '@stimcar/core-libs-repository';
import type { KnownKeysOf, RepositoryEntities, SiteConfiguration } from '@stimcar/libs-base';
import type { ActionContext, GlobalDispatch } from '@stimcar/libs-uikernel';
import type { AppComponent, LaunchAppArgs, ProgressBarMonitor } from '@stimcar/libs-uitoolkit';
import { CoreBackendSSEMessages } from '@stimcar/libs-base';
import { computePayload, keysOf, Logger } from '@stimcar/libs-kernel';
import { getWindowState } from '@stimcar/libs-uikernel';
import { launchApp } from '@stimcar/libs-uitoolkit';
import type {
  BrowserEnvironment,
  LightBrowserEnvironment,
} from './environment/BrowserEnvironment.js';
import type {
  FullRegisteredActionContext,
  RegisteredAppStore,
  RegisteredAppStoreState,
} from './state/typings/store.js';
import {
  activateCurrentWindowSessionAction,
  deactivateCurrentWindowSessionAction,
} from './actions/windowSessionActivation.js';
import { RegisteredAppWrapper } from './components/RegisteredAppWrapper.js';
import { newBrowserEnvironment } from './environment/BrowserEnvironment.js';
import {
  registerBrowserRegistrationListenerAction,
  updateOnlineStatusAction,
  userDisconnectedListenerAction,
} from './httpListenerActions.js';
import { handleModelMigrationUpgrades } from './networkStatusListener.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

type SSEMessageListenerAction<
  IS_LIGHT_ENVIRONMENT extends boolean,
  SD extends RegisteredAppStore<IS_LIGHT_ENVIRONMENT>,
> = (
  ctx: ActionContext<SD, SD['globalState']>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sseData: any
) => Promise<void> | void;

export type RepositoryEventListenerAction<
  IS_LIGHT_ENVIRONMENT extends boolean,
  SD extends RegisteredAppStore<IS_LIGHT_ENVIRONMENT>,
  ENAME extends keyof RepositoryEntities,
> = (
  ctx: ActionContext<SD, SD['globalState']>,
  updatedEntities: readonly RepositoryEntities[ENAME][],
  addedEntities: readonly RepositoryEntities[ENAME][],
  archivedEntityIds: readonly string[],
  closedEntityIds: readonly string[]
) => Promise<void>;

export interface LaunchRegisteredApp<
  IS_LIGHT_ENVIRONMENT extends boolean,
  SD extends RegisteredAppStore<IS_LIGHT_ENVIRONMENT>,
> extends Pick<
    LaunchAppArgs<
      SD,
      IS_LIGHT_ENVIRONMENT extends false ? BrowserEnvironment : LightBrowserEnvironment
    >,
    'appDecoratorComponent' | 'initialStoreState' | 'onStoreReadyAction' | 't'
  > {
  readonly registeredAppComponent: AppComponent<SD>;
  readonly registeredAppRoute: string;
  readonly lightMode: IS_LIGHT_ENVIRONMENT;
  readonly localStorageKeysToKeepDuringSoftReset?: readonly string[];
  readonly localStorageKeysToRemoveWhenAppIsUpdated?: readonly string[];
  readonly sseMessageListenersActions?: Record<
    string,
    SSEMessageListenerAction<IS_LIGHT_ENVIRONMENT, SD>
  >;
  readonly repositoryEventListenerActions: {
    [ENAME in KnownKeysOf<RepositoryEntities> as `${string &
      ENAME}RepositoryEventListenerAction`]?: RepositoryEventListenerAction<
      IS_LIGHT_ENVIRONMENT,
      SD,
      ENAME
    >;
  };
}

function updateSiteConfigurationAction<
  IS_LIGHT_ENVIRONMENT extends boolean,
  SD extends RegisteredAppStore<IS_LIGHT_ENVIRONMENT>,
>(
  { actionDispatch, getState }: ActionContext<SD, RegisteredAppStoreState>,
  siteConfiguration: SiteConfiguration
) {
  try {
    // Try a minimalistic state update
    const payload = computePayload(getState().siteConfiguration, siteConfiguration);
    actionDispatch.scopeProperty('siteConfiguration').applyPayload(payload);
  } catch {
    // Otherwise, overwrite the state
    actionDispatch.setProperty('siteConfiguration', siteConfiguration);
  }
}

export function launchRegisteredApp<
  IS_LIGHT_ENVIRONMENT extends boolean,
  SD extends RegisteredAppStore<IS_LIGHT_ENVIRONMENT>,
>({
  localStorageKeysToKeepDuringSoftReset,
  localStorageKeysToRemoveWhenAppIsUpdated,
  initialStoreState,
  sseMessageListenersActions,
  repositoryEventListenerActions,
  onStoreReadyAction,
  appDecoratorComponent,
  registeredAppComponent,
  registeredAppRoute,
  lightMode,
  t,
}: LaunchRegisteredApp<IS_LIGHT_ENVIRONMENT, SD>): void {
  // Environment factory
  const environment: IS_LIGHT_ENVIRONMENT extends false
    ? BrowserEnvironment
    : LightBrowserEnvironment = newBrowserEnvironment<IS_LIGHT_ENVIRONMENT>(
    localStorageKeysToKeepDuringSoftReset ?? [],
    lightMode
  );

  async function bindEnvironmentToStore(
    env: IS_LIGHT_ENVIRONMENT extends false ? BrowserEnvironment : LightBrowserEnvironment,
    dispatch: GlobalDispatch<SD>,
    progressBarMonitor: ProgressBarMonitor,
    t: TFunction
  ) {
    progressBarMonitor.setLabel(t('refitit:boot.loading'));

    // Register listeners
    const httpClient = env.getHttpClient();

    // If a session gets open anywhere else, disable the current session
    // eslint-disable-next-line no-param-reassign
    env.getRegisteredSessionsBroadcastChannel().onmessage = async () => {
      await dispatch.exec(deactivateCurrentWindowSessionAction);
    };

    // Retrieve the provided site configuration listener
    const actualSiteConfigurationListener = sseMessageListenersActions
      ? sseMessageListenersActions[CoreBackendSSEMessages.SITE_CONFIGURATION]
      : undefined;
    const mergedSseMessageListenersActions: Record<
      string,
      SSEMessageListenerAction<IS_LIGHT_ENVIRONMENT, SD>
    > = { ...sseMessageListenersActions };
    // Override the site configuration trigger (in order to inject the default implementation)
    mergedSseMessageListenersActions[CoreBackendSSEMessages.SITE_CONFIGURATION] = async (
      { actionDispatch }: ActionContext<SD, RegisteredAppStoreState>,
      siteConfiguration: SiteConfiguration
    ) => {
      // Call default action (that will update the state)
      await actionDispatch.exec(updateSiteConfigurationAction, siteConfiguration);
      // And then call the provided action (if provided)
      if (
        actualSiteConfigurationListener !== undefined &&
        actualSiteConfigurationListener !== null
      ) {
        await actionDispatch.exec(actualSiteConfigurationListener, siteConfiguration);
      }
    };
    // Bind SSE message listeners
    keysOf(mergedSseMessageListenersActions).forEach((eventName) => {
      httpClient.registerServerMessageListener(
        eventName,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async function sseMessageListener(data: any) {
          await dispatch.exec(mergedSseMessageListenersActions[eventName], data);
        }
      );
    });

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    httpClient.registerNetworkStatusListener(async (online) => {
      // If no version mismatch has been detected, update the global state
      await dispatch.exec(updateOnlineStatusAction, online);
      // Check if there is a model migration upgrade
      await handleModelMigrationUpgrades(env, localStorageKeysToRemoveWhenAppIsUpdated ?? [], t);
    });

    httpClient.registerUserDisconnectedListener(async () =>
      dispatch.exec(userDisconnectedListenerAction)
    );

    httpClient.registerBrowserRegistrationListener(async (becomes) =>
      dispatch.exec(registerBrowserRegistrationListenerAction, becomes, t)
    );

    const updateLocaleChangesCountAction = async (
      ctx: ActionContext<RegisteredAppStore<IS_LIGHT_ENVIRONMENT>, RegisteredAppStoreState>
    ): Promise<void> => {
      const { actionDispatch, getState, kanbanRepository } = ctx;
      let count = await kanbanRepository.getLocalChangesCount();
      if (!lightMode) {
        const { packageDealDescRepository, carElementRepository, customerRepository } =
          ctx as FullRegisteredActionContext;
        count += await packageDealDescRepository.getLocalChangesCount();
        count += await carElementRepository.getLocalChangesCount();
        count += await customerRepository.getLocalChangesCount();
      }
      if (count !== getState().session.localRepositoryChangesCount) {
        actionDispatch.scopeProperty('session').setProperty('localRepositoryChangesCount', count);
      }
    };

    const handleUnexpectedServerPushMessageErrorsAction = async (
      {
        actionDispatch,
      }: ActionContext<RegisteredAppStore<IS_LIGHT_ENVIRONMENT>, RegisteredAppStoreState>,
      unexpectedServerPushMessageErrors: readonly Error[]
      // eslint-disable-next-line @typescript-eslint/require-await
    ): Promise<void> => {
      if (unexpectedServerPushMessageErrors.length > 0) {
        actionDispatch.setProperty('message', {
          type: 'error',
          title: t('refitit:boot.repositoryPotentialCorruptionWarning.title'),
          content: t('refitit:boot.repositoryPotentialCorruptionWarning.message'),
        });
      }
    };

    function loadRepositoryAndRegisterEventListener<ENAME extends keyof RepositoryEntities>(
      repository: Repository<ENAME>,
      repositoryEventListenerAction?: RepositoryEventListenerAction<IS_LIGHT_ENVIRONMENT, SD, ENAME>
    ) {
      repository.registerUINotifier(async function repositoryUINotifier(
        unexpectedServerPushMessageErrors: readonly Error[],
        updatedEntities: readonly RepositoryEntities[ENAME][],
        addedEntities: readonly RepositoryEntities[ENAME][],
        archivedEntities: readonly string[],
        closedEntities: readonly string[]
      ): Promise<void> {
        await dispatch.exec(async function kanbanRepositoryUINotificationAction({
          actionDispatch,
        }: ActionContext<SD, SD['globalState']>): Promise<void> {
          // Handle unexpected server push message errors
          await actionDispatch.exec(
            handleUnexpectedServerPushMessageErrorsAction,
            unexpectedServerPushMessageErrors
          );
          // Update local changes count
          await actionDispatch.exec(updateLocaleChangesCountAction);
          if (repositoryEventListenerAction) {
            await actionDispatch.exec(
              repositoryEventListenerAction,
              updatedEntities,
              addedEntities,
              archivedEntities,
              closedEntities
            );
          }
        });
      });
    }

    // Load repositories & register event listeners
    progressBarMonitor.setLabel(t('refitit:boot.loadKanbans'));
    loadRepositoryAndRegisterEventListener(
      await env.getKanbanRepository(),
      repositoryEventListenerActions.kanbanRepositoryEventListenerAction
    );
    progressBarMonitor.incrementProgress(lightMode ? 75 : 20);
    if (!lightMode) {
      const fullEnv = env as Environment;
      progressBarMonitor.setLabel(t('refitit:boot.loadPackageDealDescs'));
      loadRepositoryAndRegisterEventListener(
        await fullEnv.getPackageDealDescRepository(),
        repositoryEventListenerActions.packageDealDescRepositoryEventListenerAction
      );
      progressBarMonitor.incrementProgress(20);
      progressBarMonitor.setLabel(t('refitit:boot.loadCarElements'));
      loadRepositoryAndRegisterEventListener(
        await fullEnv.getCarElementRepository(),
        repositoryEventListenerActions.carElementRepositoryEventListenerAction
      );
      progressBarMonitor.incrementProgress(20);
      progressBarMonitor.setLabel(t('refitit:boot.loadCustomers'));
      loadRepositoryAndRegisterEventListener(
        await fullEnv.getCustomerRepository(),
        repositoryEventListenerActions.customerRepositoryEventListenerAction
      );
      progressBarMonitor.incrementProgress(20);
    }
  }

  async function createStoreActionContext(
    env: IS_LIGHT_ENVIRONMENT extends false ? BrowserEnvironment : LightBrowserEnvironment
  ): Promise<Partial<SD['actionContext']>> {
    // Create action context
    let actionContext: Partial<SD['actionContext']> = {
      keyValueStorage: env.getKeyValueStorage(),
      httpClient: env.getHttpClient(),
      loggerClient: env.getLoggerClient(),
      registeredSessionsBroadcastChannel: env.getRegisteredSessionsBroadcastChannel(),
      kanbanRepository: await env.getKanbanRepository(),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;
    if (!lightMode) {
      const fullEnv = env as Environment;
      // Enrich action context
      actionContext = {
        ...actionContext,
        packageDealDescRepository: await fullEnv.getPackageDealDescRepository(),
        customerRepository: await fullEnv.getCustomerRepository(),
        carElementRepository: await fullEnv.getCarElementRepository(),
      };
    }
    // Return the action context
    return actionContext;
  }

  async function onStoreReadyWrapperAction({
    actionDispatch,
    httpClient,
  }: ActionContext<SD, SD['globalState']>) {
    if (httpClient.isBrowserRegistered()) {
      await actionDispatch.exec(activateCurrentWindowSessionAction);
    }
    if (onStoreReadyAction) {
      await actionDispatch.exec(onStoreReadyAction);
    }
  }

  launchApp<SD, IS_LIGHT_ENVIRONMENT extends false ? BrowserEnvironment : LightBrowserEnvironment>({
    environment,
    initialStoreState: {
      ...initialStoreState,
      // Initialize state with real window size
      window: getWindowState(),
    },
    bindEnvironmentToStore,
    createStoreActionContext,
    onStoreReadyAction: onStoreReadyWrapperAction,
    appDecoratorComponent,
    appComponent: ({ $gs }) => (
      <RegisteredAppWrapper
        $gs={$gs}
        registeredAppComponent={registeredAppComponent}
        registeredAppRoute={registeredAppRoute}
      />
    ),
    t,
  });
}
