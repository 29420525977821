import type { JSX } from 'react';
import React from 'react';

export interface DeleteClickableIconProps {
  readonly handler: () => void | Promise<void>;
  readonly customStyle?: React.CSSProperties;
  readonly isSmall?: boolean;
}

export function DeleteClickableIcon({
  handler,
  customStyle,
  isSmall = false,
}: DeleteClickableIconProps): JSX.Element {
  return (
    <button
      className={`delete ${isSmall ? 'is-small' : ''}`}
      type="button"
      aria-label="close or clean"
      style={customStyle}
      onClick={handler}
    />
  );
}
