import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { BaseKanban, ContractAttributeDesc, SiteInfos } from '@stimcar/libs-base';
import { globalHelpers, i18nHelpers, shortDateFormatOptions } from '@stimcar/libs-base';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { Button, ScrollableContainer } from '@stimcar/libs-uitoolkit';

interface PrintableDocumentContainerProps extends PrintableHeaderComponentProps {
  readonly children: JSX.Element;
  readonly bottomPlaceholderSize?: number;
}

function PrintableDocumentContainer({
  children,
  companyName,
  address,
  logoUrl,
  bottomPlaceholderSize,
}: PrintableDocumentContainerProps): JSX.Element {
  return (
    <ScrollableContainer isPrintable bottomPlaceholderSize={bottomPlaceholderSize}>
      <div id="printable" className="box">
        <PrintableHeaderComponent companyName={companyName} address={address} logoUrl={logoUrl} />
        {children}
      </div>
    </ScrollableContainer>
  );
}

export interface PrintableDocumentViewProps extends PrintableDocumentContainerProps {
  readonly returnAction?: () => Promise<void>;
  readonly printDocumentTitle?: string;
  readonly kanban: BaseKanban;
  readonly toolbarContributions?: JSX.Element[];
  readonly hidePrintAction?: boolean;
}

export function PrintableDocumentView({
  returnAction,
  kanban,
  printDocumentTitle,
  companyName,
  address,
  logoUrl,
  children,
  toolbarContributions,
  hidePrintAction = false,
  bottomPlaceholderSize,
}: PrintableDocumentViewProps): JSX.Element {
  const [t] = useTranslation('libComponents');

  const printAction = (): void => {
    const tempTitle = document.title;
    if (isTruthyAndNotEmpty(printDocumentTitle)) {
      document.title = printDocumentTitle;
    } else {
      document.title = t('documentGeneration.defaultDocumentTitle', {
        contract: kanban.contract.code,
        license: kanban.infos.license,
      });
    }
    window.print();
    document.title = tempTitle;
  };

  return (
    <>
      <div className="columns is-multiline no-printing is-vcentered">
        <div className="column is-narrow">
          <div className="buttons">
            {returnAction && (
              <Button
                iconId="step-backward"
                onClick={returnAction}
                label={t('documentGeneration.actions.return')}
              />
            )}
            {!hidePrintAction && (
              <Button
                iconId="print"
                onClick={printAction}
                additionalClass="is-primary"
                tooltip={t('documentGeneration.actions.printTooltip')}
              />
            )}
          </div>
        </div>
        {toolbarContributions &&
          toolbarContributions.map(
            (c, index): JSX.Element => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className="column is-narrow">
                {c}
              </div>
            )
          )}
      </div>
      <PrintableDocumentContainer
        companyName={companyName}
        address={address}
        logoUrl={logoUrl}
        bottomPlaceholderSize={bottomPlaceholderSize}
      >
        {children}
      </PrintableDocumentContainer>
    </>
  );
}
type PrintableHeaderComponentProps = Omit<
  SiteInfos,
  'invoiceFirmId' | 'invoiceSiteId' | 'billingCodePrefix' | 'workshopAddress'
>;

function PrintableHeaderComponent({
  companyName,
  address,
  logoUrl,
}: PrintableHeaderComponentProps): JSX.Element {
  return (
    <div className="columns">
      <div className="column">
        <p className="is-size-3 has-text-weight-bold has-text-black">{companyName}</p>
        {address.split('\n').map(
          (line, i): JSX.Element => (
            // We don't have better informations for the keys
            // eslint-disable-next-line react/no-array-index-key
            <p key={i}>
              {line}
              <br />
            </p>
          )
        )}
      </div>
      <div className="column has-text-right">{logoUrl && <img src={logoUrl} alt="logo" />}</div>
    </div>
  );
}

interface CarInfoProps {
  readonly kanban: BaseKanban;
  readonly attributeDescs: readonly ContractAttributeDesc[];
}

export const CarInfo = ({ kanban, attributeDescs }: CarInfoProps): JSX.Element => {
  const [t] = useTranslation('libComponents');

  const availableAttributes = useMemo(
    () =>
      attributeDescs.filter((ad) => {
        const attributeValue = kanban.attributes[ad.id];
        return (
          ad.showInEstimate &&
          attributeValue !== undefined &&
          (typeof attributeValue !== 'string' || isTruthyAndNotEmpty(attributeValue))
        );
      }),
    [attributeDescs, kanban.attributes]
  );
  return (
    <table className="table is-narrow">
      <tbody>
        <tr>
          <td style={{ width: '30%' }}>
            <strong>{`${t('documentGeneration.estimate.date')} :`}</strong>
          </td>
          <td>{globalHelpers.convertTimestampToDateString(Date.now(), shortDateFormatOptions)}</td>
        </tr>
        <tr>
          <td>
            <strong>{`${t('documentGeneration.carInformations.model')} :`}</strong>
          </td>
          <td>
            {`${i18nHelpers.displayStringOrPlaceholder(t, kanban.infos.brand)} - ${
              kanban.infos.model
            }`}
          </td>
        </tr>
        <tr>
          <td>
            <strong>
              {`${t('documentGeneration.carInformations.license')}/${t(
                'documentGeneration.carInformations.vin'
              )} :`}
            </strong>
          </td>
          <td>{`${kanban.infos.license} / ${kanban.infos.vin}`}</td>
        </tr>
        <tr>
          <td>
            <strong>{`${t('documentGeneration.carInformations.dateOfRegistration')} :`}</strong>
          </td>
          <td>
            {globalHelpers.getDDmmYYYYDateOrPlaceholderFromTimestamp(
              kanban.infos.dateOfRegistration,
              t('libUtils:noValue')
            )}
          </td>
        </tr>
        <tr>
          <td>
            <strong>{`${t('documentGeneration.carInformations.mileage')} :`}</strong>
          </td>
          <td>{kanban.infos.mileage}</td>
        </tr>
        {availableAttributes.map(
          (attribute): JSX.Element => (
            <tr key={attribute.id}>
              <td style={{ width: '30%' }}>
                <strong>{`${attribute.id} :`}</strong>
              </td>
              <td>{kanban.attributes[attribute.id]}</td>
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};
