import type { CSSProperties } from 'react';
import type { CarViewCategory } from '@stimcar/libs-base';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { PackageDealTargets } from '@stimcar/libs-base';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';

export interface RawCarViewCanvasBaseProps {
  readonly backgroundColor?: string;
  readonly maxHeight?: number;
  readonly maxWidth?: number;
  readonly horizontal?: boolean;
}

export type CarViewCanvasSize = {
  readonly width: number;
  readonly height: number;
};

export interface CarViewBaseProps<SD extends AnyStoreDef> extends RawCarViewCanvasBaseProps {
  readonly shapeClicked?: (id: string) => void;
  readonly $size?: StoreStateSelector<SD, CarViewCanvasSize>;
}

export interface CarViewProps<SD extends AnyStoreDef> extends CarViewBaseProps<SD> {
  readonly selectedShapes: readonly string[];
  readonly highlightedShapes?: readonly string[];
  readonly isFocused?: boolean;
  readonly selectedShapesColor?: string;
}

interface StyleAndId {
  readonly id: string;
  readonly style: CSSProperties;
}

const defaultStrokeWidth = 1.85208333;
const defaultStyle: CSSProperties = {
  opacity: 1,
  fill: '#849bf1',
  fillOpacity: 1,
  stroke: '#000000',
  strokeWidth: defaultStrokeWidth,
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeMiterlimit: 4,
  strokeDasharray: 'none',
  strokeDashoffset: 0,
  strokeOpacity: 1,
  paintOrder: 'stroke fill markers',
};

const selectedAndFocusedStyle: CSSProperties = {
  ...defaultStyle,
  stroke: '#000000',
  strokeWidth: 3,
  fill: '#f69640',
};

const selectedStyle: CSSProperties = {
  ...selectedAndFocusedStyle,
  fill: '#d0d1cd',
};

const highlightedAndFocusedStyle: CSSProperties = {
  ...selectedAndFocusedStyle,
  fill: '#db8639',
};

const highlightedStyle: CSSProperties = {
  ...highlightedAndFocusedStyle,
  fill: '#bfbfbd',
};

export const TRANSPARENT_VIEW_NODE_STYLE: CSSProperties = {
  ...defaultStyle,
  fill: 'none',
  strokeWidth: 0.7,
};

export function createStyleAndIdBuilder<SD extends AnyStoreDef>(
  category: CarViewCategory,
  { selectedShapes, highlightedShapes, isFocused = true, selectedShapesColor }: CarViewProps<SD>
): (id: string, strokeWidth?: number) => StyleAndId {
  return (id: string, strokeWidth = defaultStrokeWidth): StyleAndId => {
    const array = Reflect.get(PackageDealTargets, category);
    if (!array.includes(id)) {
      throw Error(`Unknown '${category}' car element : ${id}`);
    }
    let style = defaultStyle;
    if (selectedShapes.includes(id)) {
      if (isFocused) {
        style = selectedAndFocusedStyle;
      } else {
        style = selectedStyle;
      }
      if (isTruthyAndNotEmpty(selectedShapesColor)) {
        style = { ...style, fill: selectedShapesColor };
      }
    } else if (highlightedShapes && highlightedShapes.includes(id)) {
      if (isFocused) {
        style = highlightedAndFocusedStyle;
      } else {
        style = highlightedStyle;
      }
    }
    const actualStrokeWidth =
      typeof style.strokeWidth === 'string'
        ? Number.parseFloat(style.strokeWidth)
        : style.strokeWidth;

    return {
      id,
      style: {
        ...style,
        strokeWidth:
          (strokeWidth / defaultStrokeWidth) *
          (!actualStrokeWidth ? defaultStrokeWidth : actualStrokeWidth),
      },
    };
  };
}
