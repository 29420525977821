import type { JSX } from 'react';
import React from 'react';
import type { Kanban } from '@stimcar/libs-base';
import { kanbanHelpers } from '@stimcar/libs-base';
import { FaIcon } from '@stimcar/libs-uitoolkit';

interface MarketplaceIconProps {
  readonly kanban: Kanban;
}

export function MarketplaceIcon({ kanban }: MarketplaceIconProps): JSX.Element {
  const isMarketplaceKanban = kanbanHelpers.isMarketplaceKanban(kanban);
  return <>{isMarketplaceKanban && <FaIcon id="globe" additionalClass="has-text-primary" />}</>;
}
