export function testDeviceUserAgent(
  predicate: (userAgent: string) => boolean
): boolean | undefined {
  if (navigator) {
    return predicate(navigator.userAgent);
  }
  return undefined;
}

export function isAndroidDevice(): boolean {
  const test = testDeviceUserAgent((userAgent) => /android/i.exec(userAgent) !== null);
  return test === true;
}
