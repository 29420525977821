/* eslint-disable jsx-a11y/control-has-associated-label */
import type { JSX } from 'react';
import { marked } from 'marked';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban, KanbanMessage } from '@stimcar/libs-base';
import type { NoArgActionCallback, StoreStateSelector } from '@stimcar/libs-uikernel';
import { shortDayMonthWithHourFormatOptions } from '@stimcar/libs-base';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { FaIcon, ScrollableContainerWithFooter } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../app/state/typings/store.js';
import { TextArea } from '../../bulma/form/TextArea.js';
import { kanbanPriorityLevelHelpers } from '../../utils/kanbanPriorityLevelHelpers.js';

interface KanbanMessageComponentProps {
  readonly message: KanbanMessage;
}

export function KanbanMessageComponent({ message }: KanbanMessageComponentProps): JSX.Element {
  const date = new Date(message.timestamp);

  const className = kanbanPriorityLevelHelpers.getKanbanMessageColorClassName(message, 'text');

  const markdownMessageContentAsHTML = useMemo(() => {
    marked.use({ async: false });
    return marked.parse(message.content) as string;
  }, [message.content]);

  return (
    <article className="media">
      <div className="media-content">
        <div className="content">
          {message.type === 'request' && (
            <FaIcon additionalClass={`${className} m-r-md`} id="exclamation-triangle" />
          )}
          <strong>
            <>
              {`${isTruthyAndNotEmpty(message.username) ? `${message.username} - ` : ''}
                  ${date.toLocaleDateString(undefined, shortDayMonthWithHourFormatOptions)}`}
            </>
          </strong>
          <div>
            <p
              className="multiline-markdown-content"
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: markdownMessageContentAsHTML }}
            />
          </div>
        </div>
      </div>
    </article>
  );
}

interface Props {
  readonly kanban: Kanban;
  readonly $: StoreStateSelector<Store, string>;
  readonly onSubmitButtonClicked: NoArgActionCallback<Store>;
}

export function KanbanMessagesBoxComponent({
  kanban,
  $,
  onSubmitButtonClicked,
}: Props): JSX.Element {
  const [t] = useTranslation('bulmaLegacy');
  const message = useGetState($);

  const disableKanbanMessageButtons = (): boolean => {
    return message === undefined || message === '';
  };

  const sendButtonClickHandler = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.execCallback(onSubmitButtonClicked);
      actionDispatch.setValue('');
    },
    [onSubmitButtonClicked],
    $
  );

  const computeFooter = (): JSX.Element => {
    return (
      <div className="columns p-t-md">
        <div className="column">
          <TextArea placeholder={t('kanbanMessage.label')} $={$} />
        </div>
        <div className="column is-narrow">
          <button
            type="button"
            className="button is-primary"
            onClick={sendButtonClickHandler}
            disabled={disableKanbanMessageButtons()}
          >
            <FaIcon tooltip="save" id="paper-plane" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <ScrollableContainerWithFooter footerComponent={computeFooter()}>
      {kanban.messages.length === 0 ? (
        <div className="has-text-centered">{t('kanbanMessage.noMessages')}</div>
      ) : (
        kanban.messages.map((c): JSX.Element => <MessageBoxElement chatMessage={c} />)
      )}
    </ScrollableContainerWithFooter>
  );
}

interface MessageBoxElementProps {
  readonly chatMessage: KanbanMessage;
}

function MessageBoxElement({ chatMessage }: MessageBoxElementProps): JSX.Element {
  return (
    <div key={chatMessage.id} className="box m-t-xs m-b-md m-l-md m-r-md">
      <KanbanMessageComponent message={chatMessage} />
    </div>
  );
}
