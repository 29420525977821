import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import type { ShareContext } from '@stimcar/libs-base';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes, HttpErrorCodes } from '@stimcar/libs-base';
import { ensureError, getHttpStatusCode, Logger, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { ShareStore } from './state/typings/store.js';
import { KanbanShare } from './KanbanShare.js';
import { NavBar } from './NavBar.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

export function Share({ $gs }: AppProps<ShareStore>): JSX.Element {
  const { shareId, defaultTab } = useParams();
  const [t] = useTranslation('share');

  const initActionCallback = useActionCallback(
    async ({ actionDispatch, httpClient, runWithProgressBar }) => {
      await runWithProgressBar(10, async () => {
        try {
          // Load sharing context
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const context = await httpClient.httpGetAsJson<ShareContext<any>>(
            CoreBackendRoutes.SHARE_CONTEXT(nonnull(shareId))
          );

          // Update state
          actionDispatch.setProperty('context', {
            ...context,
            site: {
              ...context.site,
              configuration: context.site.configuration,
            },
          });
        } catch (e) {
          const error = ensureError(e);
          const statusCode = getHttpStatusCode(error);
          if (
            statusCode !== undefined &&
            [HttpErrorCodes.GONE, HttpErrorCodes.NOT_FOUND].includes(statusCode)
          ) {
            actionDispatch.setProperty(
              'status',
              statusCode === HttpErrorCodes.GONE ? 'expired' : 'notfound'
            );
          } else {
            log.error(error);
            actionDispatch.setProperty('message', 'Unexpected error while loading share context');
          }
        }
      });
    },
    [shareId],
    $gs
  );

  useEffect((): void => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initActionCallback();
  }, [initActionCallback]);

  const status = useGetState($gs.$status);
  const type = useGetState($gs.$context.$type);
  return (
    <>
      <NavBar $gs={$gs} shareId={nonnull(shareId)} />
      <div className="container is-fluid app">
        {status === 'notfound' && <h3>{t('status.notfound')}</h3>}
        {status === 'expired' && <h3>{t('status.expired')}</h3>}
        {status === 'ok' && type === 'kanban' && (
          <KanbanShare $gs={$gs} shareId={nonnull(shareId)} defaultTab={defaultTab} />
        )}
        {status === 'ok' && type !== 'kanban' && <p>{t('status.unknownShareType', { type })}</p>}
      </div>
    </>
  );
}
