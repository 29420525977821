import type { Stand, UserIdentity } from '../../model/index.js';
import { Role } from '../../model/index.js';
import { globalHelpers } from './globalHelpers.js';

function hasExpertRole(role: string, standIds: string[], stands: Stand[]): boolean {
  if (role === Role.Operator && standIds.length > 0) {
    for (const standId of standIds) {
      const stand = stands.find((s: Stand): boolean => s.id === standId);
      if (
        stand !== undefined &&
        stand.expertiseCategories !== undefined &&
        stand.expertiseCategories.length > 0
      ) {
        return true;
      }
    }
  }
  return false;
}

function getDisplayName<T extends UserIdentity>(user: T): string {
  return `${user.firstName.charAt(0).toUpperCase()}. ${user.lastName}`;
}

function isWorkshopPostOperator(role: string | undefined, label: string | undefined): boolean {
  if (!role || !label) {
    return false;
  }
  return role === Role.WorkshopOperator && globalHelpers.isQualifiedWorkshopPostId(label);
}

function isCorrectPINCode(value: string): boolean {
  if (Number.isNaN(Number.parseInt(value, 10))) {
    return false;
  }

  if (value.length < 4 || value.length > 8) {
    return false;
  }

  return true;
}

export const userHelpers = {
  hasExpertRole,
  getDisplayName,
  isWorkshopPostOperator,
  isCorrectPINCode,
};
