export const frenchLayout = {
  default: [
    '² & é " \' ( - è _ ç à ) = {bksp}',
    '{tab} a z e r t y u i o p ^ $',
    '{lock} q s d f g h j k l m ù * {enter}',
    '{shift} < w x c v b n , ; : ! {shift}',
    '.com @ {space}',
  ],
  shift: [
    '{//} 1 2 3 4 5 6 7 8 9 0 ° + {bksp}',
    '{tab} A Z E R T Y U I O P ¨ £',
    '{lock} Q S D F G H J K L M % µ {enter}',
    '{shift} > W X C V B N ? . / § {shift}',
    '.com @ {space}',
  ],
};
