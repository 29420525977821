import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import type { AuthenticatedUser } from '@stimcar/libs-base';
import { AvailablePermissionPaths, hasAccessPermission } from '@stimcar/libs-base';

export const getNavigatorLanguage = (): string =>
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : // @ts-ignore userLanguage and browserLanguage are IE specific
      navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en-US';

export function userAvailableAccessPaths(user: AuthenticatedUser | undefined): {
  hasCorporateAccess: boolean;
  hasSubcontractorAccess: boolean;
} {
  let hasCorporateAccess = false;
  let hasSubcontractorAccess = false;
  if (user) {
    hasCorporateAccess = hasAccessPermission(
      user.permissions,
      AvailablePermissionPaths.INTERNAL_ACCESS
    );
    hasSubcontractorAccess = hasAccessPermission(
      user.permissions,
      AvailablePermissionPaths.SUBCONTRACTOR_ACCESS
    );
  }
  return { hasCorporateAccess, hasSubcontractorAccess };
}

export function useQuery(): URLSearchParams {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
