import type { RefObject } from 'react';
import React, { useEffect } from 'react';

export function useHTMLElementSize<T extends HTMLElement>(
  ref: RefObject<T | null>
): [number, number] {
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  useEffect((): (() => void) => {
    function eventListener(): void {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
        setHeight(ref.current.offsetHeight);
      }
    }
    // Register a click listener
    window.addEventListener('resize', eventListener, false);
    // Call one to initialize with the initial size
    eventListener();
    return (): void => {
      // Unregister the click listener
      window.removeEventListener('resize', eventListener, false);
    };
  }, [ref]);
  return [width, height];
}
