import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { StoreStateSelector, WithFormValidationWarnings } from '@stimcar/libs-uikernel';
import type { HorizontalFormFieldProps } from '@stimcar/libs-uitoolkit';
import {
  ALL_DAYS_OF_WEEK_ALIAS,
  MONDAY_TO_FRIDAY_ALIAS,
  MONDAY_TO_SATURDAY_ALIAS,
  scheduledTaskHelpers,
  SUNDAY_TO_THURSDAY_ALIAS,
  TUESDAY_TO_SATURDAY_ALIAS,
} from '@stimcar/libs-base';
import { useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../../../state/typings/store.js';
import type { ScheduledTaskScheduleData } from '../typings/store.js';
import { CheckboxFormField } from '../../../../lib/bulma/form/CheckboxFormField.js';
import { ReactSelectMultiFormField } from '../../../../lib/bulma/form/custom/ReactSelectMultiFormField.js';

export interface ScheduledTaskScheduleInputsProps {
  readonly $formData: StoreStateSelector<
    Store,
    WithFormValidationWarnings<ScheduledTaskScheduleData>
  >;
  readonly horizontalFormFields?: boolean | HorizontalFormFieldProps;
}
export function ScheduledTaskScheduleInputs({
  $formData,
  horizontalFormFields,
}: ScheduledTaskScheduleInputsProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');
  const asOftenAsPossibleWithinADay = useGetState($formData.$asOftenAsPossibleWithinADay);
  const weekDaySuggestions = scheduledTaskHelpers
    .getActualDaysOfWeek(ALL_DAYS_OF_WEEK_ALIAS)
    .map((day) => {
      return {
        value: day,
        label: t(`scheduleInputs.weekday.${day}`),
      };
    });
  const weekDaySuggestionsCompletedWithAliases = [
    {
      value: ALL_DAYS_OF_WEEK_ALIAS,
      label: t(`scheduleInputs.weekday.allDays`),
    },
    {
      value: MONDAY_TO_FRIDAY_ALIAS,
      label: t(`scheduleInputs.weekday.mondayToFriday`),
    },
    {
      value: MONDAY_TO_SATURDAY_ALIAS,
      label: t(`scheduleInputs.weekday.mondayToSaturday`),
    },
    {
      value: SUNDAY_TO_THURSDAY_ALIAS,
      label: t(`scheduleInputs.weekday.sundayToThursday`),
    },
    {
      value: TUESDAY_TO_SATURDAY_ALIAS,
      label: t(`scheduleInputs.weekday.tuesdayToSaturday`),
    },
    ...weekDaySuggestions,
  ];
  const hoursSuggestions = Array.from(Array(24).keys()).map((h) => {
    return {
      value: h,
      label: `${h}H`,
    };
  });
  const minutesSuggestions = Array.from(Array(60).keys()).filter((minute) => minute % 5 === 0);

  return (
    <>
      <ReactSelectMultiFormField
        label={t('scheduleInputs.scheduledWeekDays')}
        $={$formData.$scheduledWeekDays}
        suggestions={weekDaySuggestionsCompletedWithAliases}
        horizontal={horizontalFormFields}
        isClearable
        creation={false}
      />
      <CheckboxFormField
        label={t('scheduleInputs.asOftenAsPossibleWithinADay')}
        $={$formData.$asOftenAsPossibleWithinADay}
        horizontal={horizontalFormFields}
        style={{ verticalAlign: 'bottom' }}
      />
      <ReactSelectMultiFormField
        label={t('scheduleInputs.scheduledHours')}
        $={$formData.$scheduledHours}
        suggestions={hoursSuggestions}
        horizontal={horizontalFormFields}
        isClearable
        creation={false}
        isDisabled={asOftenAsPossibleWithinADay}
      />
      <ReactSelectMultiFormField
        label={t('scheduleInputs.scheduledMinutes')}
        $={$formData.$scheduledMinutes}
        suggestions={minutesSuggestions}
        horizontal={horizontalFormFields}
        isClearable
        creation={false}
        isDisabled={asOftenAsPossibleWithinADay}
      />
    </>
  );
}
