import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps, FormFieldEntry, HorizontalFormFieldProps } from '@stimcar/libs-uitoolkit';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import { InputFormField, ReactSelectFormField } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type {
  ColumnsToDestinationsState,
  CustomColumnDialogState,
  CustomerSummary,
  UpdateImportAirtableDialogState,
} from '../typings/store.js';
import { ReactSelectMultiFormField } from '../../../../lib/bulma/form/custom/ReactSelectMultiFormField.js';
import { LabelledEntityListFormField } from '../../../../lib/components/LabelledEntityListFormField.js';
import { useGetContractCodes } from '../../../utils/useGetContract.js';
import {
  EMPTY_COLUMN_TO_DESTINATION_DIALOG_STATE,
  EMPTY_CUSTOM_COLUMN_DIALOG_STATE,
  EMPTY_SINGLE_OPTION_DIALOG_STATE,
} from '../typings/store.js';
import { CustomColumnModal } from './AirtableImportCustomColumnDef.js';
import { ColumnToDestinationModal } from './ColumnToDestinationModal.js';
import { MailTypedScheduledTaskInputs } from './MailTypedScheduledTaskInputs.js';

function openCreateNewCustomColumnModalAction(
  { actionDispatch }: ActionContext<Store, CustomColumnDialogState>,
  workflowId: string
): void {
  actionDispatch.reduce((initial: CustomColumnDialogState): CustomColumnDialogState => {
    return {
      ...EMPTY_CUSTOM_COLUMN_DIALOG_STATE,
      active: true,
      mode: 'create',
      customColumnNameToUpdate: undefined,
      customColumns: initial.customColumns,
      workflowId,
      allPkgCodesWithinContract: initial.allPkgCodesWithinContract,
    };
  });
}

function openUpdateCustomColumnModalAction(
  { actionDispatch, getState }: ActionContext<Store, UpdateImportAirtableDialogState>,
  workflowId: string
): void {
  const { customColumnDialogState, selectedPackageDealColumnToHandleId } = getState();
  const { customColumns } = customColumnDialogState;
  const customColumnToUpdate = customColumns.find(
    (cc) => cc.id === selectedPackageDealColumnToHandleId
  );
  const customColumnNameToUpdate = customColumnToUpdate?.id ?? '';
  actionDispatch
    .scopeProperty('customColumnDialogState')
    .reduce((initial: CustomColumnDialogState): CustomColumnDialogState => {
      const currentCustomColumn = initial.customColumns.find(
        (o) => o.id === customColumnNameToUpdate
      );
      return {
        ...EMPTY_CUSTOM_COLUMN_DIALOG_STATE,
        active: true,
        mode: 'update',
        customColumns: initial.customColumns,
        customColumnNameToUpdate,
        formData: {
          columnName: customColumnNameToUpdate,
          warnings: {},
        },
        singleOptionInputDialogState: {
          ...EMPTY_SINGLE_OPTION_DIALOG_STATE,
          options: currentCustomColumn?.options ?? [],
        },
        workflowId,
        allPkgCodesWithinContract: initial.allPkgCodesWithinContract,
      };
    });
}

function deleteCustomColumnModalAction({
  actionDispatch,
  getState,
}: ActionContext<Store, UpdateImportAirtableDialogState>): void {
  const { customColumnDialogState, selectedPackageDealColumnToHandleId } = getState();
  const { customColumns } = customColumnDialogState;
  const selectedOption = customColumns.find((cc) => cc.id === selectedPackageDealColumnToHandleId);
  const customColumnNameToDelete = selectedOption?.id ?? '';
  actionDispatch
    .scopeProperty('customColumnDialogState')
    .reduce((initial: CustomColumnDialogState): CustomColumnDialogState => {
      return {
        ...initial,
        customColumns: initial.customColumns.filter((cc) => cc.id !== customColumnNameToDelete),
      };
    });
  actionDispatch.setProperty('selectedPackageDealColumnToHandleId', '');
}

function openCreateNewColumnToDestinationModalAction({
  actionDispatch,
}: ActionContext<Store, ColumnsToDestinationsState>): void {
  actionDispatch.scopeProperty('columnsToDestinationsDialogState').applyPayload({
    ...EMPTY_COLUMN_TO_DESTINATION_DIALOG_STATE,
    active: true,
    mode: 'create',
  });
}

function openUpdateColumnToDestinationModalAction({
  actionDispatch,
  getState,
}: ActionContext<Store, ColumnsToDestinationsState>): void {
  const { columnsToDestinations, selectedColumnId } = getState();
  const selectedColumn = columnsToDestinations.find(({ id }) => id === selectedColumnId);
  if (selectedColumn) {
    actionDispatch.scopeProperty('columnsToDestinationsDialogState').applyPayload({
      ...EMPTY_COLUMN_TO_DESTINATION_DIALOG_STATE,
      active: true,
      mode: 'update',
      formData: {
        column: selectedColumn.id,
        destination: selectedColumn.destination,
      },
    });
  }
}

function deleteColumnToDestinationModalAction({
  actionDispatch,
  getState,
}: ActionContext<Store, ColumnsToDestinationsState>): void {
  const { columnsToDestinations, selectedColumnId } = getState();
  const selectedColumn = columnsToDestinations.find(({ id }) => id === selectedColumnId);
  if (selectedColumn) {
    actionDispatch.reduce((initial: ColumnsToDestinationsState) => ({
      ...initial,
      selectedColumnId: '',
      columnsToDestinations: initial.columnsToDestinations.filter(
        ({ id }) => id !== selectedColumn.id
      ),
    }));
  }
}

export interface ImportAirtableInputsProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, UpdateImportAirtableDialogState>;
  readonly $formData: StoreStateSelector<Store, UpdateImportAirtableDialogState['formData']>;
  readonly allCustomerSummaries: readonly CustomerSummary[];
  readonly allWorkflowIds: readonly string[];
  readonly packageDealColumnsToHandleEntries: FormFieldEntry<string>[];
  readonly documentColumnsToDownloadEntries: FormFieldEntry<string>[];
  readonly textToDocColumnsToDownloadEntries: FormFieldEntry<string>[];
  readonly textColumnsToAttributesEntries: FormFieldEntry<string>[];
  readonly horizontalFormFields?: boolean | HorizontalFormFieldProps;
}

export function ImportAirtableInputs({
  $,
  $formData,
  $gs,
  allCustomerSummaries,
  allWorkflowIds,
  packageDealColumnsToHandleEntries,
  documentColumnsToDownloadEntries,
  textToDocColumnsToDownloadEntries,
  textColumnsToAttributesEntries,
  horizontalFormFields,
}: ImportAirtableInputsProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');
  const allContracts = useGetContractCodes($gs);

  const onContractChangeTrigger = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setProperty('tableNames', []);
    },
    [],
    $formData
  );

  const {
    $customColumnDialogState,
    $documentColumnsToDownloadState,
    $textToDocumentColumnsToDownloadState,
    $textColumnsToAttributesState,
  } = $;
  const $contractCodeWithChangeTrigger = useSelectorWithChangeTrigger(
    $formData.$contractCode,
    onContractChangeTrigger
  );
  const contractCode = useGetState($contractCodeWithChangeTrigger);
  const workflowId = useGetState($formData.$workflowId);

  const createLabelledEntity = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.exec(openCreateNewCustomColumnModalAction, workflowId);
    },
    [workflowId],
    $customColumnDialogState
  );
  const editSelectedLabelledEntity = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.exec(openUpdateCustomColumnModalAction, workflowId);
    },
    [workflowId],
    $
  );
  const deleteSelectedLabelledEntity = useActionCallback(deleteCustomColumnModalAction, [], $);

  const createDocumentColumnToDownload = useActionCallback(
    openCreateNewColumnToDestinationModalAction,
    [],
    $documentColumnsToDownloadState
  );

  const editSelectedDocumentColumnToDownload = useActionCallback(
    openUpdateColumnToDestinationModalAction,
    [],
    $documentColumnsToDownloadState
  );

  const deleteSelectedDocumentColumnToDownload = useActionCallback(
    deleteColumnToDestinationModalAction,
    [],
    $documentColumnsToDownloadState
  );

  const createTextToDocColumnToDownload = useActionCallback(
    openCreateNewColumnToDestinationModalAction,
    [],
    $textToDocumentColumnsToDownloadState
  );

  const editSelectedTextToDocColumnToDownload = useActionCallback(
    openUpdateColumnToDestinationModalAction,
    [],
    $textToDocumentColumnsToDownloadState
  );

  const deleteSelectedTextToDocColumnToDownload = useActionCallback(
    deleteColumnToDestinationModalAction,
    [],
    $textToDocumentColumnsToDownloadState
  );

  const createTextColumnToAttribute = useActionCallback(
    openCreateNewColumnToDestinationModalAction,
    [],
    $textColumnsToAttributesState
  );

  const editSelectedTextColumnsToAttributes = useActionCallback(
    openUpdateColumnToDestinationModalAction,
    [],
    $textColumnsToAttributesState
  );

  const deleteSelectedTextColumnToAttribute = useActionCallback(
    deleteColumnToDestinationModalAction,
    [],
    $textColumnsToAttributesState
  );

  const contractCustomerShortnames = useMemo(() => {
    return allCustomerSummaries.filter((c) => c.contract === contractCode).map((c) => c.shortName);
  }, [allCustomerSummaries, contractCode]);

  return (
    <>
      <ReactSelectFormField
        label={t('argumentsInputs.contractCode')}
        suggestions={allContracts}
        $={$contractCodeWithChangeTrigger}
        creation={false}
        horizontal={horizontalFormFields}
      />
      <ReactSelectFormField
        label={t('argumentsInputs.workflowId')}
        suggestions={allWorkflowIds}
        $={$formData.$workflowId}
        creation={false}
        horizontal={horizontalFormFields}
      />
      <ReactSelectMultiFormField
        label={t('argumentsInputs.customerShortNames')}
        $={$formData.$tableNames}
        suggestions={contractCustomerShortnames}
        horizontal={horizontalFormFields}
        isClearable
      />
      <InputFormField
        label={t('argumentsInputs.token')}
        $={$formData.$token}
        horizontal={horizontalFormFields}
      />
      <InputFormField
        label={t('argumentsInputs.databaseId')}
        $={$formData.$databaseId}
        horizontal={horizontalFormFields}
      />
      <InputFormField
        label={t('argumentsInputs.databaseName')}
        $={$formData.$databaseName}
        horizontal={horizontalFormFields}
      />
      <LabelledEntityListFormField
        label={t('argumentsInputs.packageDealColumnsToHandle')}
        labelledEntities={packageDealColumnsToHandleEntries}
        editSelectedLabelledEntity={editSelectedLabelledEntity}
        deleteSelectedLabelledEntity={deleteSelectedLabelledEntity}
        createLabelledEntity={createLabelledEntity}
        creationDisabled={!isTruthyAndNotEmpty(contractCode) || !isTruthyAndNotEmpty(workflowId)}
        $selectedEntityId={$.$selectedPackageDealColumnToHandleId}
        horizontal={horizontalFormFields}
      />
      <LabelledEntityListFormField
        label={t('argumentsInputs.documentColumnsToDownload')}
        labelledEntities={documentColumnsToDownloadEntries}
        editSelectedLabelledEntity={editSelectedDocumentColumnToDownload}
        deleteSelectedLabelledEntity={deleteSelectedDocumentColumnToDownload}
        createLabelledEntity={createDocumentColumnToDownload}
        creationDisabled={!isTruthyAndNotEmpty(contractCode) || !isTruthyAndNotEmpty(workflowId)}
        $selectedEntityId={$documentColumnsToDownloadState.$selectedColumnId}
        horizontal={horizontalFormFields}
      />
      <LabelledEntityListFormField
        label={t('argumentsInputs.textColumnsToDownload')}
        labelledEntities={textToDocColumnsToDownloadEntries}
        editSelectedLabelledEntity={editSelectedTextToDocColumnToDownload}
        deleteSelectedLabelledEntity={deleteSelectedTextToDocColumnToDownload}
        createLabelledEntity={createTextToDocColumnToDownload}
        creationDisabled={!isTruthyAndNotEmpty(contractCode) || !isTruthyAndNotEmpty(workflowId)}
        $selectedEntityId={$textToDocumentColumnsToDownloadState.$selectedColumnId}
        horizontal={horizontalFormFields}
      />
      <LabelledEntityListFormField
        label={t('argumentsInputs.textColumnsToAttributes')}
        labelledEntities={textColumnsToAttributesEntries}
        editSelectedLabelledEntity={editSelectedTextColumnsToAttributes}
        deleteSelectedLabelledEntity={deleteSelectedTextColumnToAttribute}
        createLabelledEntity={createTextColumnToAttribute}
        creationDisabled={!isTruthyAndNotEmpty(contractCode) || !isTruthyAndNotEmpty(workflowId)}
        $selectedEntityId={$textColumnsToAttributesState.$selectedColumnId}
        horizontal={horizontalFormFields}
      />
      <CustomColumnModal
        $={$customColumnDialogState}
        $gs={$gs}
        contractCode={contractCode}
        horizontalFormFields={horizontalFormFields}
      />
      <ColumnToDestinationModal
        translationKey="columnToDownload"
        $={$documentColumnsToDownloadState}
        horizontalFormFields={horizontalFormFields}
      />
      <ColumnToDestinationModal
        translationKey="columnToDownload"
        $={$textToDocumentColumnsToDownloadState}
        horizontalFormFields={horizontalFormFields}
      />
      <ColumnToDestinationModal
        translationKey="textColumnToAttribute"
        $={$textColumnsToAttributesState}
        horizontalFormFields={horizontalFormFields}
      />
      <MailTypedScheduledTaskInputs
        $formData={$formData}
        horizontalFormFields={horizontalFormFields}
      />
    </>
  );
}
