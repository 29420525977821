/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Deletable, Entity, Labelled } from '@stimcar/libs-kernel';
import { keysOf } from '@stimcar/libs-kernel';
import {
  MKTP_PKG_CATEGORY_BUY_FIXED_FEES,
  MKTP_PKG_CATEGORY_NA,
  MKTP_PKG_CATEGORY_REGISTRATION_FEES,
  MKTP_PKG_CATEGORY_SALE_FIXED_FEES,
} from '../globalConstants.js';
import type { AttributesHolder, Ordered } from './general.js';
import type { RepositoryEntity } from './repository.js';
import type { KnownKeysOf } from './typescript.js';
import type { DecoratorDefinition } from './ui.js';

export type FileUploadResult = {
  readonly success: string | undefined;
  readonly warnings: readonly string[];
  readonly errors: readonly string[];
};

export type PddOrODExpressionType = { [key: string]: string | PddOrODExpressionType };

export type SparePartDesc = Entity & Deletable & Labelled;

export type OperationDesc = AttributesHolder &
  Entity &
  Deletable &
  Ordered &
  Labelled & {
    readonly workloadExpression: string;
    readonly standId: string;
  };

export type DocumentDesc = Entity &
  Deletable &
  Labelled & {
    readonly targetFolder: string;
    readonly standId: string;
  };

type PackageDealVariableCommonDefinition<T = number | string | boolean> = {
  readonly type: 'numeric' | 'boolean' | 'text';
  readonly defaultValue: T | null;
  readonly label: string;
  readonly index?: number;
};

type PackageDealNumericVariableDefinition = PackageDealVariableCommonDefinition<number> & {
  readonly type: 'numeric';
  readonly availableValues: readonly number[];
  readonly unit: string;
};

export type PackageDealNumericVariableBaseType = Omit<
  PackageDealNumericVariableDefinition,
  'defaultValue'
>;

export function isNumericVariable(a: any): a is PackageDealNumericVariableBaseType {
  return a.type === 'numeric';
}

type PackageDealTextualVariableDefinition = PackageDealVariableCommonDefinition<string> & {
  readonly type: 'text';
  readonly availableValues: readonly string[];
};

export type PackageDealTextualVariableBaseType = Omit<
  PackageDealTextualVariableDefinition,
  'defaultValue'
>;

export function isTextualVariable(a: any): a is PackageDealTextualVariableBaseType {
  return a.type === 'text';
}

type PackageDealBooleanVariableDefinition = PackageDealVariableCommonDefinition<boolean> & {
  readonly type: 'boolean';
};

export type PackageDealBooleanVariableBaseType = Omit<
  PackageDealBooleanVariableDefinition,
  'defaultValue'
>;

export function isBooleanVariable(a: any): a is PackageDealBooleanVariableBaseType {
  return a.type === 'boolean';
}

export type PackageDealVariableBaseType =
  | PackageDealNumericVariableBaseType
  | PackageDealBooleanVariableBaseType
  | PackageDealTextualVariableBaseType;

export type PackageDealVariableDefinition =
  | PackageDealNumericVariableDefinition
  | PackageDealBooleanVariableDefinition
  | PackageDealTextualVariableDefinition;

export const MARKETPLACE_CATEGORIES = [
  MKTP_PKG_CATEGORY_NA,
  MKTP_PKG_CATEGORY_REGISTRATION_FEES,
  MKTP_PKG_CATEGORY_BUY_FIXED_FEES,
  MKTP_PKG_CATEGORY_SALE_FIXED_FEES,
] as const;
export type MarketplaceCategory = (typeof MARKETPLACE_CATEGORIES)[number];

export type PackageDealDesc = AttributesHolder &
  RepositoryEntity & {
    readonly code: string;
    readonly label: string;
    readonly carElementIds: readonly string[];
    readonly hint: string;
    readonly durationExpression: string;
    readonly priceExpression: string;
    readonly sparePartDescs: readonly SparePartDesc[];
    readonly operationDescs: readonly OperationDesc[];
    readonly documentDescs: readonly DocumentDesc[];
    readonly database: string;
    readonly overridablePrice: boolean;
    readonly ignoreVAT: boolean;
    readonly category: PackageDealCategory;
    readonly isMarketplace: boolean;
    readonly marketplaceBuyCategory: MarketplaceCategory;
    readonly marketplaceSaleCategory: MarketplaceCategory;
    readonly tags: readonly string[];
    readonly variableDescs: Record<string, PackageDealVariableDefinition | null>;
    readonly isSubcontractable: boolean;
  };

// General Configuration types
export type CarElement = RepositoryEntity & {
  readonly index: number;
  readonly label: string;
  readonly category: CarViewCategory;
  readonly shapes: readonly string[];
};

export function isCarElement(ce: any): ce is CarElement {
  return ce && ce.id && typeof ce.id === 'string' && ce.shapes && ce.shapes instanceof Array;
}

export const PACKAGEDEAL_CATEGORY_IDS = ['AUTRE', 'CARRO', 'EXP', 'MECA', 'PR'] as const;

export type PackageDealCategory = (typeof PACKAGEDEAL_CATEGORY_IDS)[number];

export const PackageDealTargets = {
  // External
  BUMP: [
    'ANTI_FOG_BL',
    'ANTI_FOG_BR',
    'ANTI_FOG_FL',
    'ANTI_FOG_FR',
    'CANNULA_BL',
    'CANNULA_BR',
    'COUPLING',
    'DEFLECTOR_B',
    'DEFLECTOR_F',
    'HEADLIGHT_BL',
    'HEADLIGHT_BR',
    'HEADLIGHT_FL',
    'HEADLIGHT_FR',
    'LOWER_BUMPER_B',
    'LOWER_BUMPER_F',
    'LOWER_GRID_F',
    'MIDDLE_BUMPER_B',
    'MIDDLE_GRID_F',
    'NUMBERPLATE_B',
    'NUMBERPLATE_F',
    'UPPER_BUMPER_BL',
    'UPPER_BUMPER_BR',
    'UPPER_BUMPER_F',
    'UPPER_GRID_F',
  ],
  // External
  EXTE: [
    'BUMPER_B',
    'BUMPER_F',
    'TRUNK_LEFT',
    'TRUNK_RIGHT',
    'DOOR_BL',
    'DOOR_BR',
    'DOOR_FL',
    'DOOR_FR',
    'FENDER_BL',
    'FENDER_BR',
    'FENDER_FL',
    'FENDER_FR',
    'HEADLIGHT_BL',
    'HEADLIGHT_BR',
    'HEADLIGHT_FL',
    'HEADLIGHT_FR',
    'HOOD',
    'ROOF',
    'WHEEL_BL',
    'WHEEL_BR',
    'WHEEL_FL',
    'WHEEL_FR',
    'WINDSHIELD',
  ],
  // Interior
  INTE: [
    'TRUNK',
    'CONSOLE',
    'DASHBOARD',
    'DOOR_BL',
    'DOOR_BR',
    'DOOR_FL',
    'DOOR_FR',
    'ROOF',
    'SEAT_BC', // Seat Back Center
    'SEAT_BL',
    'SEAT_BR',
    'TRUNK_SEAT_L', // Left trunk seat
    'TRUNK_SEAT_R',
    'SEAT_FL',
    'SEAT_FR',
  ],
  // Mechanical
  MECA: [
    'AXLE_BL',
    'AXLE_BR',
    'AXLE_FL',
    'AXLE_FR',
    'DRIVE_SHAFT',
    'EXHAUST',
    'GEARBOX',
    'MOTOR',
    'SPARE_WHEEL',
    'WHEEL_BL',
    'WHEEL_BR',
    'WHEEL_FL',
    'WHEEL_FR',
  ],
  // Miscellaneous
  MISC: [] as string[],
};

export type CarViewCategory = KnownKeysOf<typeof PackageDealTargets>;
export const CAR_VIEW_CATEGORIES = keysOf(PackageDealTargets);

export const BODY_CATEGORY_CODE = 'BODY';
export type MergedCarViewCategory = 'BODY' | 'INTE' | 'MECA' | 'MISC';

export type WorkflowNode<NOTATION extends 'concise' | 'standard' = 'standard'> =
  | (NOTATION extends 'concise' ? string : never)
  | {
      readonly id: string;
      readonly fork?: readonly WorkflowNode<NOTATION>[];
      readonly join?: WorkflowNode<NOTATION>;
    };

export type Workflow<NOTATION extends 'concise' | 'standard' = 'standard'> = {
  readonly id: string;
  readonly description: string;
  readonly definition: WorkflowNode<NOTATION>;
};

function isWorkflow(w: any): w is Workflow<'concise'> {
  return (
    w &&
    w.id &&
    typeof w.id === 'string' &&
    w.description &&
    typeof w.description === 'string' &&
    w.definition &&
    w.definition instanceof Object
  );
}

export type WorkshopPost = Entity & {
  readonly isOptional: boolean;
};

export type WorkshopPostCategory<NOTATION extends 'concise' | 'standard' = 'standard'> = {
  readonly id: string;
  readonly posts: readonly ((NOTATION extends 'concise' ? string : never) | WorkshopPost)[];
  readonly toggleOperationsFromAnywhere: boolean;
};

export type WorkshopPostLevel<NOTATION extends 'concise' | 'standard' = 'standard'> =
  WorkshopPostCategory<NOTATION> & {
    readonly ancestors?: readonly WorkshopPostLevel<NOTATION>[];
  };

export type WorkshopStandImplantation<NOTATION extends 'concise' | 'standard' = 'standard'> = {
  readonly id: string;
  readonly definition: readonly WorkshopPostLevel<NOTATION>[];
};

export type StandType =
  | 'expertise'
  | 'sparepartsReference'
  | 'expertiseValidation'
  | 'sparePartsOrder';

export type Stand<NOTATION extends 'concise' | 'standard' = 'standard'> = {
  readonly id: string;
  // The full classes to display the icon. For instance "fas fa-car"
  readonly iconClass: string;
  readonly noReallocationFrom?: boolean;
  readonly noReallocationTo?: boolean;
  // TODO is an enum the best choice?
  readonly expertiseCategories?: readonly CarViewCategory[];
  readonly shouldKanbanBeFrozenPastThisStand?: boolean;
  readonly implantations?: readonly WorkshopStandImplantation<NOTATION>[];
  readonly type?: StandType;
  readonly hideFromDashboard?: 'ifEmpty' | 'always';
};

export function isStand(s: any): s is Stand {
  return (
    s && s.id && typeof s.id === 'string'
    // TODO test enum value
  );
}

export type SiteInfos = {
  readonly companyName: string;
  readonly address: string;
  readonly workshopAddress: string;
  readonly logoUrl?: string;
  readonly invoiceFirmId: number;
  readonly invoiceSiteId: number; // Id of the category in facturation.pro corresponding to this site
  readonly billingCodePrefix: string;
};

export type DisplayConfiguration = {
  readonly kanbanColorationCharter: KanbanColorationCharter;
};

export type KanbanColorationCharter = {
  readonly ageUnderWhichKanbansAreYoung: number;
  readonly ageAboveWhichKanbansAreOld: number;
  readonly dueDateThreshold: number;
};

export type ShiftsConfiguration = {
  readonly startHour: number;
  readonly startMinute: number;
  readonly expectedWorkloadByCollaborator: number;
  readonly factor: number;
  readonly collaboratorsCount: {
    readonly shift1: number;
    readonly shift2: number;
    readonly shift3: number;
  };
};

export type MarketplaceEquipmentsConfiguration = {
  readonly security: readonly string[];
  readonly comfort: readonly string[];
  readonly multimedia: readonly string[];
  readonly others: readonly string[];
};

// NOTATION = concise : allows to use a concise grammar
// For example, a workflow node can be represented by
// a simple string (the identifier) when it has no
// 'join' of 'fork' node.
export type SiteConfiguration<NOTATION extends 'concise' | 'standard' = 'standard'> = {
  readonly shiftsConfiguration: {
    readonly expertise: ShiftsConfiguration;
    readonly workshop: ShiftsConfiguration;
  };
  readonly stands: readonly Stand<NOTATION>[];
  readonly iconDictionary: Record<string, string>;
  readonly workflows: readonly Workflow<NOTATION>[];
  readonly packageDealDatabases: readonly string[];
  readonly infos: SiteInfos;
  readonly displayConfiguration: DisplayConfiguration;
  readonly computeIconFunction: string;
  readonly packageDealDecorators: readonly DecoratorDefinition<NOTATION>[];
  readonly subcontractors: readonly string[];
  readonly marketplaceEquipments: MarketplaceEquipmentsConfiguration;
};

export enum KanbanPriorityLevel {
  'none' = 'none',
  'finished' = 'green-light',
  'dueDateIsPast' = 'red-light',
  'dueDateIsWithinThreshold' = 'yellow',
  'age0' = 'kanban-age-0',
  'age1' = 'kanban-age-1',
  'age2' = 'kanban-age-2',
  'age3' = 'kanban-age-3',
  'age4' = 'kanban-age-4',
  'age5' = 'kanban-age-5',
  'age6' = 'kanban-age-6',
  'age7' = 'kanban-age-7',
  'age8' = 'kanban-age-8',
  'age9' = 'kanban-age-9',
}

/**
 * This is a type guard that allow to verify that the given data respect the type SiteConfiguration.
 * This is used to verify that the data stored are at least type correct.
 *
 * @param c The given element, expected to be a SiteConfiguration
 */
export function isSiteConfiguration(c: any): c is SiteConfiguration {
  return (
    c &&
      c.stands &&
      c.stands instanceof Array &&
      c.stands.filter((s: any): boolean => !isStand(s)).length === 0 &&
      c.workflows &&
      c.workflows instanceof Array &&
      c.workflows.filter((w: any): boolean => !isWorkflow(w)).length === 0 &&
      c.contracts === undefined,
    c.sparepartsProviders === undefined
  );
}
