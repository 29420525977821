import type { Kanban, WithProgress } from '@stimcar/libs-base';
import type { TableState } from '../../../lib/bulma/elements/table/typings/store.js';
import type { PdfCreationAndUploadModalState } from '../../../lib/components/attachments/typings/store.js';
import type { ExpandedKanbanStatuses } from '../../../lib/components/kanbanList/KanbanListItem.js';
import { TABLE_EMPTY_STATE } from '../../../lib/bulma/elements/table/typings/store.js';
import { PDF_CREATION_AND_UPLOAD_MODAL_EMPTY_STATE } from '../../../lib/components/attachments/typings/store.js';

export type SelectKanbanState = TableState<WithProgress<Kanban>> & {
  readonly handleKanbanMenuActive: boolean;
  readonly selectedStands: readonly string[];
  readonly pdfCreationAndUploadModal: PdfCreationAndUploadModalState;
  readonly expandedKanbanStatuses: ExpandedKanbanStatuses;
};

export const SELECT_KANBAN_EMPTY_STATE: SelectKanbanState = {
  ...TABLE_EMPTY_STATE,
  handleKanbanMenuActive: false,
  selectedStands: [],
  pdfCreationAndUploadModal: PDF_CREATION_AND_UPLOAD_MODAL_EMPTY_STATE,
  expandedKanbanStatuses: {},
};
