import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { AttributeType, CarViewCategory, MemoDesc, Stand } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { CAR_VIEW_CATEGORIES, WORK_SHEET_ATTRIBUTE_NAME } from '@stimcar/libs-base';
import { isTruthyAndNotEmpty, keysOf, nonnull } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useRecordItemSelector,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import type { Store } from '../../state/typings/store.js';
import type {
  LowerExpertiseTab,
  OperatorExpertViewState,
  OperatorViewState,
  UpperExpertiseHintsSubTab,
  UpperExpertiseSubTab,
  UpperExpertiseTab,
  UpperExpertiseTabsState,
} from '../typings/store.js';
import { useGetAllCarViewCategoryLabels } from '../../../utils/carViewCategoriesTranslationHooks.js';
import { lowerExpertiseTabs } from '../typings/store.js';

function hasWorkSheet(kanbanAttributes: Record<string, AttributeType>): boolean {
  return isTruthyAndNotEmpty(kanbanAttributes[WORK_SHEET_ATTRIBUTE_NAME] as string);
}

export function selectUpperExpertiseTabAction({
  actionDispatch,
  getState,
}: ActionContext<Store, OperatorExpertViewState>): void {
  const newUpperExpertiseTab = getState().selectedUpperExpertiseTab;

  // Ensure memos and pkg deals section is visible
  actionDispatch.setProperty('memosPackageDealsSectionIsExpanded', true);

  actionDispatch.setProperty('selectedCarViewShapes', []);
  actionDispatch.setProperty('highlightedCarViewShapes', []);
  actionDispatch.setProperty('availableCarElementIds', []);
  actionDispatch.setProperty('selectedCarElementId', '');
  actionDispatch.setProperty('availablePackageDealDescs', []);
  actionDispatch.setProperty('selectedPackageDealDescId', '');
  if (newUpperExpertiseTab === 'MISC') {
    const availableOperationDescs = getState().allPackageDealDescs.filter(
      ({ carElementIds }) => carElementIds.length === 0
    );
    actionDispatch.setProperty('availablePackageDealDescs', availableOperationDescs);
    actionDispatch.setProperty('selectedPackageDealDescId', '');
  }
}

export function getDefaultSelectedUpperExpertiseTab(
  kanbanAttributes: Record<string, AttributeType>
): UpperExpertiseTab {
  if (hasWorkSheet(kanbanAttributes)) {
    return 'HINTS';
  }
  return 'MISC';
}

export function getUpperExpertiseTabsStateFromMemoDescs(
  upperExpertiseTabsState: UpperExpertiseTabsState,
  memoDescs: Record<CarViewCategory, readonly MemoDesc[]>,
  kanbanAttributes: Record<string, AttributeType>,
  isOnline: boolean
): UpperExpertiseTabsState {
  const keys = keysOf(upperExpertiseTabsState);
  return keys.reduce((accumulator, currentKey) => {
    if (currentKey === 'HINTS') {
      if (isOnline && hasWorkSheet(kanbanAttributes)) {
        return { ...accumulator, [currentKey]: 'WORK_SHEET_HINTS' };
      }
      return { ...accumulator, [currentKey]: 'PREDICTIONS' };
    }
    const upperExpertiseSubTab: UpperExpertiseSubTab =
      memoDescs[currentKey].length === 0 ? 'PACKAGE_DEALS' : 'MEMOS';
    return {
      ...accumulator,
      [currentKey]: upperExpertiseSubTab,
    };
  }, {} as UpperExpertiseTabsState);
}

function getDisabledUpperExpertiseSubTab(
  selectedUpperExpertiseTab: UpperExpertiseTab,
  memoDescs: Record<CarViewCategory, readonly MemoDesc[]>,
  kanbanAttributes: Record<string, AttributeType>,
  isOnline: boolean
): UpperExpertiseSubTab | UpperExpertiseHintsSubTab | null {
  if (selectedUpperExpertiseTab === 'HINTS') {
    if (!isOnline || !hasWorkSheet(kanbanAttributes)) {
      return 'WORK_SHEET_HINTS';
    }
  }

  if (CAR_VIEW_CATEGORIES.includes(selectedUpperExpertiseTab as CarViewCategory)) {
    if (memoDescs[selectedUpperExpertiseTab as CarViewCategory].length === 0) {
      return 'MEMOS';
    }
  }

  return null;
}

interface UseUpperExpertiseTabsReturnType {
  upperExpertiseTabLabels: Record<string, string>;
  $selectedUpperExpertiseTabWithChangeTrigger: StoreStateSelector<Store, UpperExpertiseTab>;
}

export function useUpperExpertiseTabs(
  $: StoreStateSelector<Store, OperatorExpertViewState>,
  stands: readonly Stand<'standard'>[],
  standId: string
): UseUpperExpertiseTabsReturnType {
  const [t] = useTranslation('operators');

  const carViewCategoryLabels = useGetAllCarViewCategoryLabels();

  const expertiseCategories = useMemo(() => {
    const stand = nonnull(stands.find(({ id }) => id === standId));
    return stand.expertiseCategories ?? [];
  }, [stands, standId]);

  const upperExpertiseTabLabels = useMemo(
    () =>
      [
        'HINTS',
        ...expertiseCategories,
        'ATTACHMENTS',
        'ATTRIBUTES',
        'GENERAL_INFOS',
        'DEFECTS',
      ].reduce(
        (record, key) => ({
          ...record,
          [key]: expertiseCategories.includes(key as CarViewCategory)
            ? carViewCategoryLabels[key as CarViewCategory]
            : t(`expertiseView.tabs.${key}`),
        }),
        {} as Record<UpperExpertiseTab, string>
      ),
    [expertiseCategories, carViewCategoryLabels, t]
  );

  const selectUpperExpertiseTabActionCallback = useActionCallback(
    selectUpperExpertiseTabAction,
    [],
    $
  );
  const $selectedUpperExpertiseTabWithChangeTrigger = useSelectorWithChangeTrigger(
    $.$selectedUpperExpertiseTab,
    selectUpperExpertiseTabActionCallback
  );

  return {
    upperExpertiseTabLabels,
    $selectedUpperExpertiseTabWithChangeTrigger,
  };
}

interface UseLowerExpertiseTabsReturnType {
  lowerExpertiseTabsLabels: Record<string, string>;
  disabledLowerExpertiseTabs: readonly LowerExpertiseTab[];
  $selectedLowerExpertiseTabWithChangeTrigger: StoreStateSelector<Store, LowerExpertiseTab>;
  selectedLowerExpertiseTab: LowerExpertiseTab;
}

export function useLowerExpertiseTabs(
  $: StoreStateSelector<Store, OperatorViewState>
): UseLowerExpertiseTabsReturnType {
  const [t] = useTranslation('operators');
  const operationsToDoIds = useGetState($.$operationsToDoIds);
  const standRelatedPackageDeals = useGetState($.$generalOperatorState.$standRelatedPackageDeals);

  const disabledLowerExpertiseTabs = useGetState(
    $.$expertOperatorState.$disabledLowerExpertiseTabs
  );

  const lowerExpertiseTabsLabels = useMemo(
    () =>
      lowerExpertiseTabs.reduce(
        (record, key) => ({ ...record, [key]: t(`expertiseView.tabs.${key}`) }),
        {} as Record<LowerExpertiseTab, string>
      ),
    [t]
  );

  const selectedLowerExpertiseTab = useGetState($.$expertOperatorState.$selectedLowerExpertiseTab);

  const selectLowerExpertiseTabActionCallback = useActionCallback(
    () => {},
    [],
    $.$expertOperatorState
  );
  const $selectedLowerExpertiseTabWithChangeTrigger = useSelectorWithChangeTrigger(
    $.$expertOperatorState.$selectedLowerExpertiseTab,
    selectLowerExpertiseTabActionCallback
  );

  const setDisabledLowerExpertiseTabsAsyncEffect = useActionCallback(
    ({ actionDispatch }): void => {
      const operations = standRelatedPackageDeals.flatMap(({ operations }) =>
        operations.filter(({ id }) => operationsToDoIds.includes(id))
      );
      actionDispatch.setProperty(
        'disabledLowerExpertiseTabs',
        operations.length === 0 ? ['OPERATIONS'] : []
      );
    },
    [operationsToDoIds, standRelatedPackageDeals],
    $.$expertOperatorState
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setDisabledLowerExpertiseTabsAsyncEffect();
  }, [setDisabledLowerExpertiseTabsAsyncEffect]);

  return {
    lowerExpertiseTabsLabels,
    disabledLowerExpertiseTabs,
    $selectedLowerExpertiseTabWithChangeTrigger,
    selectedLowerExpertiseTab,
  };
}

interface useUpperExpertiseSubTabsReturnType {
  selectedUpperExpertiseTab: UpperExpertiseTab;
  upperExpertiseSubTabLabels:
    | Record<UpperExpertiseSubTab, string>
    | Record<UpperExpertiseHintsSubTab, string>;
  $selectedUpperExpertiseSubTab: StoreStateSelector<
    Store,
    UpperExpertiseSubTab | UpperExpertiseHintsSubTab
  >;
  selectedUpperExpertiseSubTab: UpperExpertiseSubTab | UpperExpertiseHintsSubTab;
  disabledUpperExpertiseSubTabs: readonly (UpperExpertiseSubTab | UpperExpertiseHintsSubTab)[];
}

export function useUpperExpertiseSubTabs(
  $: StoreStateSelector<Store, OperatorExpertViewState>,
  memoDescs: Record<CarViewCategory, readonly MemoDesc[]>,
  kanbanAttributes: Record<string, AttributeType>,
  isOnline: boolean
): useUpperExpertiseSubTabsReturnType {
  const [t] = useTranslation('operators');

  const selectedUpperExpertiseTab = useGetState($.$selectedUpperExpertiseTab);

  const upperExpertiseSubTabLabels = useMemo(() => {
    if (selectedUpperExpertiseTab === 'HINTS') {
      return {
        WORK_SHEET_HINTS: t(`expertiseView.subTabs.workSheetHints`),
        PREDICTIONS: t(`expertiseView.subTabs.predictions`),
      } as Record<UpperExpertiseHintsSubTab, string>;
    }
    return {
      MEMOS: t(`expertiseView.subTabs.memos`),
      PACKAGE_DEALS: t(`expertiseView.subTabs.packageDeals`),
    } as Record<UpperExpertiseSubTab, string>;
  }, [t, selectedUpperExpertiseTab]);

  const $selectedUpperExpertiseSubTab = useRecordItemSelector(
    $.$upperExpertiseTabsState,
    selectedUpperExpertiseTab as CarViewCategory
  );
  const selectedUpperExpertiseSubTab = useGetState($selectedUpperExpertiseSubTab);

  const disabledUpperExpertiseSubTabs = useMemo(() => {
    const disabledUpperExpertiseSubTab = getDisabledUpperExpertiseSubTab(
      selectedUpperExpertiseTab,
      memoDescs,
      kanbanAttributes,
      isOnline
    );
    if (disabledUpperExpertiseSubTab) {
      return [disabledUpperExpertiseSubTab];
    }
    return [];
  }, [isOnline, kanbanAttributes, memoDescs, selectedUpperExpertiseTab]);

  return {
    selectedUpperExpertiseTab,
    upperExpertiseSubTabLabels,
    $selectedUpperExpertiseSubTab,
    selectedUpperExpertiseSubTab,
    disabledUpperExpertiseSubTabs,
  };
}
