import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Attachment } from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { ImageModalState } from '@stimcar/libs-uitoolkit';
import type { ComputeAttachmentUrlCallback } from '../../lib/components/attachments/typings/attachment.js';
import type { ShareStore } from '../state/typings/store.js';
import { AttachmentThumbnail } from '../../lib/components/attachments/AttachmentThumbnail.js';
import { DisplayContentOrPlaceholder } from '../../lib/components/misc/DisplayContentOrPlaceholder.js';

const PHOTO_HEIGHT = 200;

interface Props {
  readonly attachments: readonly Attachment[];
  readonly $imageModal: StoreStateSelector<ShareStore, ImageModalState>;
  readonly computeAttachmentUrlCallback: ComputeAttachmentUrlCallback;
  readonly kanbanId: string;
  readonly folderLabel: string;
}

export function KanbanShareAttachmentsTab({
  attachments,
  $imageModal,
  computeAttachmentUrlCallback,
  kanbanId,
  folderLabel,
}: Props): JSX.Element {
  const [t] = useTranslation('share');
  return (
    <DisplayContentOrPlaceholder
      displayCondition={attachments.length > 0}
      placeholder={t('attachmentsTab.warning.noAttachment', { folderLabel })}
    >
      <>
        <div className="columns is-multiline">
          {attachments.map(
            (att, index): JSX.Element => (
              <div className="column is-narrow" key={att.id}>
                <AttachmentThumbnail
                  category="kanban"
                  objectId={kanbanId}
                  computeAttachmentUrl={computeAttachmentUrlCallback}
                  size={PHOTO_HEIGHT}
                  attachments={attachments}
                  $imageModal={$imageModal}
                  attachmentIndex={index}
                />
              </div>
            )
          )}
        </div>
      </>
    </DisplayContentOrPlaceholder>
  );
}
