import type { MattermostLogger } from '@stimcar/core-libs-repository';
import type { Kanban, KanbanHandling, SparePart } from '@stimcar/libs-base';
import {
  filterRecordEntries,
  forEachRecordValues,
  getKanbanUrl,
  groupBy,
} from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';

export function logErrorIfMultipleOpenedHandlings(
  companyId: string,
  kanbanId: string,
  kanbanLicense: string,
  kanbanHandlings: readonly KanbanHandling[],
  loggerClient: MattermostLogger
): void {
  const openedHandlings = kanbanHandlings.filter(({ endDate }) => !isTruthy(endDate));
  const openedHandlingsByPostId = groupBy(openedHandlings, (t: KanbanHandling) => t.postId);
  const openedHandlingsWithMultiplePost = filterRecordEntries(
    openedHandlingsByPostId,
    (handlings) => handlings.length > 1
  );

  forEachRecordValues(openedHandlingsWithMultiplePost, (val, key) => {
    const standIds = val
      .map((val) => val.standId)
      .reduce((result, elem) => {
        return result.concat(` / ${elem}`);
      });

    loggerClient.error(
      `:warning: Multiple opened kanbanHandlings`,
      `Investigate why this kanban has been opened multiple times...`,
      companyId,
      [
        { key: 'kanbanLink', value: getKanbanUrl(companyId, kanbanId) },
        { key: 'kanbanId', value: kanbanId },
        { key: 'license', value: kanbanLicense },
        { key: 'standId', value: standIds },
        { key: 'postId', value: key },
      ]
    );
  });
}

export function logErrorIfSparePartsInExpertiseStand(
  companyId: string,
  kanban: Kanban,
  standId: string,
  spareParts: readonly SparePart[],
  loggerClient: MattermostLogger
): void {
  loggerClient.error(
    `:warning: Spare parts in expertise stand`,
    `Investigate why this kanban has spare parts in the expertise stand...`,
    companyId,
    [
      { key: 'kanbanId', value: kanban.id },
      { key: 'license', value: kanban.infos.license },
      { key: 'standId', value: standId },
      {
        key: 'sparePartsIds',
        value: `[${spareParts.map(({ id }) => id).join(', ')}]`,
      },
      {
        key: 'sparePartsLabels',
        value: `[${spareParts.map(({ label }) => label).join(', ')}]`,
      },
    ]
  );
}
