import type { JSX } from 'react';
import React from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import type { FormFieldProps, ListSelectProps } from '@stimcar/libs-uitoolkit';
import { useFormFieldWarning } from '@stimcar/libs-uikernel';
import { FormField, ListSelect } from '@stimcar/libs-uitoolkit';

export interface ListSelectFormFieldProps<
  SD extends AnyStoreDef,
  T extends string | readonly string[],
> extends ListSelectProps<SD, T>,
    Omit<FormFieldProps, 'children' | 'warning' | 'noExclamationTriangleIfWarning'> {}

export function ListSelectFormField<SD extends AnyStoreDef, T extends string | readonly string[]>({
  label,
  horizontal,
  $,
  ...props
}: ListSelectFormFieldProps<SD, T>): JSX.Element {
  const warning = useFormFieldWarning($);
  return (
    <FormField label={label} warning={warning} horizontal={horizontal}>
      <ListSelect {...props} $={$} />
    </FormField>
  );
}
