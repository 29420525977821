import type { JSX } from 'react';
import i18next from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Attachment, AttachmentFolder, StorageCategories } from '@stimcar/libs-base';
import type {
  ActionCallbackFromFunction,
  ActionContext,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { SubcontractorBackendRoutes, URL_LIST_ELEMENTS_SEPARATOR } from '@stimcar/libs-base';
import { asyncForEach, isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { SubcontractorStore } from '../state/typings/store.js';
import {
  importAttachmentsAction,
  loadAttachmentsGalleryAction,
  showRemoveAttachmentConfirmDialogAction,
} from '../../app/utils/attachmentGalleryActions.js';
import { AttachmentsGallery } from '../../lib/components/attachments/AttachmentsGallery.js';
import { ConfirmAttachmentRemovalDialog } from '../../lib/components/attachments/ConfirmAttachmentRemovalDialog.js';
import { useComputeSubcontractorAttachmentUrl } from '../utils/useSubcontractorComputeAttachmentUrl.js';
import type { SubcontractorUploadDocumentForCompletionModalState } from './typings/store.js';
import { EMPTY_SUBCONTRACTOR_UPLOAD_DOCUMENT_FOR_COMPLETION_MODAL_STATE } from './typings/store.js';

type SubcontractorToggleOperationActionCallback = ActionCallbackFromFunction<
  SubcontractorStore,
  (operationId: string, selectedFiles?: readonly string[]) => Promise<void>
>;

async function removeAttachmentAction({
  actionDispatch,
  getState,
  httpClient,
  getGlobalState,
}: ActionContext<
  SubcontractorStore,
  SubcontractorUploadDocumentForCompletionModalState
>): Promise<void> {
  const siteId = getGlobalState().session.selectedSite;
  const { kanbanId, attachmentGallery, documentsInfo } = getState();
  const { folder, name, id } = attachmentGallery.confirmAttachmentRemovalDialog;
  await httpClient.httpGet(
    SubcontractorBackendRoutes.SUBCONTRACTOR_ATTACHMENT(siteId, 'kanban', kanbanId, folder, name),
    'DELETE'
  );
  actionDispatch
    .scopeProperty('attachmentGallery')
    .scopeProperty('confirmAttachmentRemovalDialog')
    .setProperty('active', false);
  actionDispatch.scopeProperty('attachmentGallery').setProperty(
    'attachments',
    attachmentGallery.attachments.filter(
      ({ id: currentId, folder: currentFolder }) => currentId !== id || currentFolder !== folder
    )
  );
  actionDispatch.scopeProperty('documentsInfo').setProperty(
    'documentsFileNames',
    documentsInfo.documentsFileNames.filter((fileName) => fileName !== name)
  );
}

async function uploadOperationCompletionAction(
  {
    actionDispatch,
    getState,
  }: ActionContext<SubcontractorStore, SubcontractorUploadDocumentForCompletionModalState>,
  toggleOperationActionCallback: SubcontractorToggleOperationActionCallback
): Promise<void> {
  const { operation, documentsInfo } = getState();
  const { documentsFileNames, documentLabel } = documentsInfo;
  const isOperationCompleted = isTruthy(operation.completionDate);
  if (documentsFileNames.length === 0 && !isOperationCompleted) {
    actionDispatch.setProperty(
      'warning',
      i18next.t('operators:uploadDocumentForOperationCompletionModal.documentRequiredWarning', {
        documentLabel,
      })
    );
  } else {
    await actionDispatch.execCallback(
      toggleOperationActionCallback,
      operation.id,
      documentsFileNames
    );
    actionDispatch.setValue(EMPTY_SUBCONTRACTOR_UPLOAD_DOCUMENT_FOR_COMPLETION_MODAL_STATE);
  }
}

async function loadAttachmentsAction(
  {
    actionDispatch,
    getState,
    getGlobalState,
  }: ActionContext<SubcontractorStore, SubcontractorUploadDocumentForCompletionModalState>,
  category: StorageCategories,
  objectId: string,
  folders: readonly string[],
  reloadElements?: boolean
): Promise<void> {
  const { selectedSite } = getGlobalState().session;
  await actionDispatch
    .scopeProperty('attachmentGallery')
    .exec(
      loadAttachmentsGalleryAction,
      SubcontractorBackendRoutes.SUBCONTRACTOR_ATTACHMENT_FOLDER(
        selectedSite,
        category,
        objectId,
        folders.join(URL_LIST_ELEMENTS_SEPARATOR)
      ),
      reloadElements
    );
  const { attachments } = getState().attachmentGallery;
  const { documentsFileNames } = getState().documentsInfo;
  const attachmentsFileNames = attachments.map(({ name }) => name);
  actionDispatch
    .scopeProperty('documentsInfo')
    .scopeProperty('documentsFileNames')
    .setValue(documentsFileNames.filter((fileName) => attachmentsFileNames.includes(fileName)));

  actionDispatch.scopeProperty('attachmentGallery').setProperty('loadingStatus', undefined);
}

function onImportSuccessAction(
  {
    actionDispatch,
    getState,
  }: ActionContext<SubcontractorStore, SubcontractorUploadDocumentForCompletionModalState>,
  importedFiles: readonly string[]
): void {
  const { documentsFileNames } = getState().documentsInfo;
  actionDispatch.setProperty('warning', undefined);
  actionDispatch
    .scopeProperty('documentsInfo')
    .setProperty('documentsFileNames', [...documentsFileNames, ...importedFiles]);
}

async function closeModalAction(
  {
    actionDispatch,
    httpClient,
    getState,
    getGlobalState,
  }: ActionContext<SubcontractorStore, SubcontractorUploadDocumentForCompletionModalState>,
  toggleOperationActionCallback: SubcontractorToggleOperationActionCallback
): Promise<void> {
  const { operation, documentsInfo, kanbanId } = getState();
  if (!isTruthy(operation.completionDate)) {
    const siteId = getGlobalState().session.selectedSite;
    await asyncForEach(documentsInfo.documentsFileNames, async (fileName) => {
      await httpClient.httpGet(
        SubcontractorBackendRoutes.SUBCONTRACTOR_ATTACHMENT(
          siteId,
          'kanban',
          kanbanId,
          documentsInfo.targetFolder,
          fileName
        ),
        'DELETE'
      );
    });
  } else {
    await actionDispatch.execCallback(
      toggleOperationActionCallback,
      operation.id,
      documentsInfo.documentsFileNames
    );
  }
  actionDispatch.setValue(EMPTY_SUBCONTRACTOR_UPLOAD_DOCUMENT_FOR_COMPLETION_MODAL_STATE);
}

interface SubcontractorUploadDocumentForCompletionModalDialogProps
  extends AppProps<SubcontractorStore> {
  readonly $: StoreStateSelector<
    SubcontractorStore,
    SubcontractorUploadDocumentForCompletionModalState
  >;
  readonly toggleOperationActionCallback: SubcontractorToggleOperationActionCallback;
}

export function SubcontractorUploadDocumentForCompletionModalDialog({
  $,
  $gs,
  toggleOperationActionCallback,
}: SubcontractorUploadDocumentForCompletionModalDialogProps): JSX.Element {
  const [t] = useTranslation('subcontractor');
  const warning = useGetState($.$warning);
  const kanbanId = useGetState($.$kanbanId);
  const documentsInfo = useGetState($.$documentsInfo);
  const computeAttachmentUrl = useComputeSubcontractorAttachmentUrl($gs);

  const attachmentFolder = useMemo(
    (): AttachmentFolder => ({ id: documentsInfo.targetFolder, isShareable: false }),
    [documentsInfo.targetFolder]
  );

  const attachmentFilter = useCallback(
    (attachment: Attachment) => documentsInfo.documentsFileNames.includes(attachment.name),
    [documentsInfo.documentsFileNames]
  );

  const uploadOperationCompletionActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(uploadOperationCompletionAction, toggleOperationActionCallback);
    },
    [toggleOperationActionCallback],
    $
  );

  const closeModalActionCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.exec(closeModalAction, toggleOperationActionCallback);
    },
    [toggleOperationActionCallback],
    $
  );

  const removeAttachmentActionCallback = useActionCallback(removeAttachmentAction, [], $);

  const showRemoveAttachmentConfirmDialogCallback = useActionCallback(
    showRemoveAttachmentConfirmDialogAction,
    [],
    $.$attachmentGallery
  );

  const onImportSuccessActionCallback = useActionCallback(
    async ({ actionDispatch }, importedFiles: readonly string[]) => {
      await actionDispatch.exec(onImportSuccessAction, importedFiles);
    },
    [],
    $
  );

  const loadAttachmentsActionCallback = useActionCallback(
    async (
      { actionDispatch },
      category: StorageCategories,
      objectId: string,
      folders: readonly string[],
      reloadElements?: boolean
    ) => {
      await actionDispatch.exec(loadAttachmentsAction, category, objectId, folders, reloadElements);
    },
    [],
    $
  );

  const importAttachmentsActionCallback = useActionCallback(
    async (
      { actionDispatch, getGlobalState },
      category: StorageCategories,
      objectId: string,
      folder: string,
      files: readonly File[],
      postImportCallback?: ActionCallbackFromFunction<
        SubcontractorStore,
        (fileNamesMap: Record<string, string>) => Promise<void>
      >
    ) => {
      const siteId = getGlobalState().session.selectedSite;
      const onImportSuccess = async (fileNamesMap: Record<string, string>) => {
        if (postImportCallback) {
          await postImportCallback(fileNamesMap);
        }
        await actionDispatch.execCallback(
          onImportSuccessActionCallback,
          Object.values(fileNamesMap)
        );
      };
      await actionDispatch.exec(
        importAttachmentsAction,
        category,
        objectId,
        folder,
        files,
        onImportSuccess,
        SubcontractorBackendRoutes.SUBCONTRACTOR_ATTACHMENT_FOLDER(
          siteId,
          category,
          objectId,
          folder
        )
      );
    },
    [onImportSuccessActionCallback],
    $gs
  );

  return (
    <>
      <ModalCardDialog
        warning={warning}
        $active={$.$active}
        onCancelClicked={closeModalActionCallback}
        onOkClicked={uploadOperationCompletionActionCallback}
        title={t('uploadDocumentForOperationCompletionModal.title')}
      >
        <p>
          {t('uploadDocumentForOperationCompletionModal.rationale', {
            documentLabel: documentsInfo.documentLabel,
          })}
        </p>
        <AttachmentsGallery
          isOnline
          category="kanban"
          objectId={kanbanId}
          $window={$gs.$window}
          $={$.$attachmentGallery}
          folders={attachmentFolder}
          $imageModal={$gs.$imageModal}
          attachmentFilter={attachmentFilter}
          computeAttachmentUrl={computeAttachmentUrl}
          loadAttachmentsActionCallback={loadAttachmentsActionCallback}
          uploadToolkit={{
            importAttachmentsActionCallback,
          }}
          removeToolkit={{
            onRemoveCallback: showRemoveAttachmentConfirmDialogCallback,
          }}
        />
      </ModalCardDialog>
      <ConfirmAttachmentRemovalDialog
        category="kanban"
        objectId={kanbanId}
        computeAttachmentUrl={computeAttachmentUrl}
        onOkClicked={removeAttachmentActionCallback}
        $={$.$attachmentGallery.$confirmAttachmentRemovalDialog}
        okLabel={t(
          'uploadDocumentForOperationCompletionModal.confirmAttachmentRemovalDialog.okLabel'
        )}
      >
        <p>
          {t('uploadDocumentForOperationCompletionModal.confirmAttachmentRemovalDialog.message')}
        </p>
      </ConfirmAttachmentRemovalDialog>
    </>
  );
}
