import type { AuthenticationTypes, Site } from '@stimcar/libs-base';
import type { BusinessObjectToFormData, WithFormValidationWarnings } from '@stimcar/libs-uikernel';
import type { FormWithValidationState } from '@stimcar/libs-uitoolkit';
import { EMPTY_FORM_WITH_VALIDATION_STATE } from '@stimcar/libs-uitoolkit';

export type RegisterForm = BusinessObjectToFormData<{
  readonly siteId: string;
  readonly label: string;
  readonly role: string;
  readonly standId: string;
  readonly forceLabel: boolean;
}>;

export const EMPTY_REGISTER_FORM_STATE: WithFormValidationWarnings<RegisterForm> = {
  siteId: '',
  label: '',
  role: '',
  standId: '',
  forceLabel: false,
  warnings: {},
};

export type RegisterViewState = FormWithValidationState<RegisterForm> & {
  readonly availableSites: readonly Site[];
  readonly labelIsInUse: boolean;
};

export const EMPTY_REGISTER_VIEW_STATE: RegisterViewState = {
  ...EMPTY_FORM_WITH_VALIDATION_STATE,
  formData: EMPTY_REGISTER_FORM_STATE,
  availableSites: [],
  labelIsInUse: false,
};

export type UpdatePasswordData = BusinessObjectToFormData<{
  readonly newPassword: string;
  readonly repeatedNewPassword: string;
  readonly existingPassword: string;
}> & {
  readonly updatedAuthenticationType: AuthenticationTypes;
};

export const EMPTY_EDIT_PASSWORD_DATA: WithFormValidationWarnings<UpdatePasswordData> = {
  newPassword: '',
  repeatedNewPassword: '',
  existingPassword: '',
  updatedAuthenticationType: 'password',
  warnings: {},
};

export type UpdatePasswordDialogState = FormWithValidationState<UpdatePasswordData> & {
  readonly active: boolean;
  readonly userLogin: string;
};

export const EMPTY_EDIT_PASSWORD_DIALOG_STATE: UpdatePasswordDialogState = {
  active: false,
  userLogin: '',
  formSubmitted: false,
  formSubmitClickedOnce: false,
  formWarning: undefined,
  formData: {
    ...EMPTY_EDIT_PASSWORD_DATA,
    warnings: {},
  },
};
