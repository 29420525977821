import type { JSX } from 'react';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PackageDeal } from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { useActionCallback } from '@stimcar/libs-uikernel';
import type { Store } from '../../state/typings/store.js';
import { CarBumpersView } from '../../../lib/components/carviews/CarBumpersView.js';
import { CarExternalView } from '../../../lib/components/carviews/CarExternalView.js';
import { CarInteriorView } from '../../../lib/components/carviews/CarInteriorView.js';
import { CarMechanicalView } from '../../../lib/components/carviews/CarMechanicalView.js';
import type { QualityControlState } from './typings/store.js';
import { CarViewWrapper } from './QualityControlCarViewWrapper.js';
import { QualityControlNotOkReportModal } from './QualityControlNotOkReportModal.js';
import {
  getPackageDealsByCarElementCategory,
  getQualityControlStepsFromPackageDeals,
} from './utils/qualityControlUtils.js';

interface QualityControlProps extends AppProps<Store> {
  readonly kanbanId: string;
  readonly packageDeals: readonly PackageDeal[];
  readonly $: StoreStateSelector<Store, QualityControlState>;
}

export function QualityControl({
  $,
  $gs,
  kanbanId,
  packageDeals,
}: QualityControlProps): JSX.Element {
  const [t] = useTranslation('workshop');

  const initializeQualityControlStepsAsyncEffect = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setValue(getQualityControlStepsFromPackageDeals(packageDeals));
    },
    [packageDeals],
    $.$steps
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initializeQualityControlStepsAsyncEffect();
  }, [initializeQualityControlStepsAsyncEffect]);

  const packageDealsByCategory = useMemo(
    () => getPackageDealsByCarElementCategory(packageDeals),
    [packageDeals]
  );

  return (
    <div className="quality-control">
      <CarViewWrapper
        $={$}
        $gs={$gs}
        category="BUMP"
        kanbanId={kanbanId}
        packageDeals={packageDealsByCategory.BUMP}
      >
        <CarBumpersView selectedShapes={[]} />
      </CarViewWrapper>
      <CarViewWrapper
        $={$}
        $gs={$gs}
        category="EXTE"
        kanbanId={kanbanId}
        packageDeals={packageDealsByCategory.EXTE}
      >
        <CarExternalView selectedShapes={[]} />
      </CarViewWrapper>
      <CarViewWrapper
        $={$}
        $gs={$gs}
        category="INTE"
        kanbanId={kanbanId}
        packageDeals={packageDealsByCategory.INTE}
      >
        <CarInteriorView selectedShapes={[]} />
      </CarViewWrapper>
      <CarViewWrapper
        $={$}
        $gs={$gs}
        category="MECA"
        kanbanId={kanbanId}
        packageDeals={packageDealsByCategory.MECA}
      >
        <CarMechanicalView selectedShapes={[]} />
      </CarViewWrapper>
      <button type="button" className="button is-primary is-fullwidth my-3">
        {t('qualityControl.validate')}
      </button>
      <QualityControlNotOkReportModal $gs={$gs} $qualityControl={$} kanbanId={kanbanId} />
    </div>
  );
}
