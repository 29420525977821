import type { Fetch, FormDataFactory, HttpLogFormatter, JsonParser } from '@stimcar/libs-kernel';
import {
  HTTPClientImpl,
  isTruthy,
  Logger,
  removeAuthenticationParamsFromUrl,
} from '@stimcar/libs-kernel';
import { PING_SERVER_MESSAGE } from '../../model/index.js';
import type {
  HTTPClientWithSSE,
  ListenerUnregisterer,
  NetworkStatusListener,
  ServerMessageListener,
} from './typings/index.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

// Delay before reconnection when SSE connection is lost
const SSE_AUTORECONNECT_DELAY = 3000;

export type EventSourceFactory = (url: string) => EventSource;

const SSE_MESSAGE_LOG_MAX_LENGTH = 40;

/**
 * SSE client implementation.
 */
export class HTTPClientWithSSEImpl extends HTTPClientImpl implements HTTPClientWithSSE {
  /**
   * Messages listeners.
   */
  private readonly messageListeners: Map<string, ServerMessageListener> = new Map();

  private readonly eventSourceListenersMap: Map<
    ServerMessageListener,
    EventListenerOrEventListenerObject
  > = new Map();

  /**
   * Network status listeners.
   */
  private networkStatusListeners: NetworkStatusListener[] = [];

  /**
   * The current event source.
   */
  private eventSource!: EventSource;

  private readonly evFactory: EventSourceFactory;

  private isClosed = false;

  private restartSSEIntervalId: NodeJS.Timeout | undefined;

  /**
   * Default constructor.
   */
  public constructor(
    fetch: Fetch,
    formDataFactory: FormDataFactory,
    evFactory: EventSourceFactory,
    baseUrl?: string,
    logFormatter?: HttpLogFormatter,
    jsonParser?: JsonParser
  ) {
    super(fetch, formDataFactory, baseUrl, logFormatter, jsonParser);
    this.evFactory = evFactory;
    // Register ping listener (No op)
    this.registerServerMessageListener(PING_SERVER_MESSAGE, async (): Promise<void> => {
      // no op
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async shutdown(timeout?: number): Promise<void> {
    log.info(`Shutdowning HTTPClient [${removeAuthenticationParamsFromUrl(this.baseUrl)}]...`);
    this.isClosed = true;
    this.closeSSE();
    await this.notifyNetworkStatusIsOnline(false);
    this.networkStatusListeners = [];
    this.messageListeners.clear();
  }

  public isShutdown = (): boolean => {
    return this.isClosed;
  };

  public registerServerMessageListener(
    id: string,
    listener: ServerMessageListener
  ): ListenerUnregisterer {
    log.info('Register SSE listener:', id);
    if (this.messageListeners.has(id)) {
      throw Error(`Duplicate listener for key ${id}`);
    }
    // Register in the list to be able to recreate in future recreated EventSources
    this.messageListeners.set(id, listener);
    // And activate it in the currently opened EventSource
    if (this.eventSource) {
      this.activateMessageListener(id, listener);
    }
    // Create unregister callback
    return (): void => {
      log.debug('Unregistering server message listener for', id);
      this.messageListeners.delete(id);
      const eventSourceListener = this.eventSourceListenersMap.get(listener);
      if (eventSourceListener) {
        this.eventSource.removeEventListener(id, eventSourceListener);
        this.eventSourceListenersMap.delete(listener);
      }
    };
  }

  public isOnline(): boolean {
    return isTruthy(this.eventSource) && this.eventSource.readyState === this.eventSource.OPEN;
  }

  public registerNetworkStatusListener(listener: NetworkStatusListener): ListenerUnregisterer {
    this.networkStatusListeners.push(listener);
    // Tell the listener if the network is online (no need to await)
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    listener(this.isOnline());
    // Create unregister callback
    return (): void => {
      log.debug('Unregistering network status listener');
      this.networkStatusListeners = this.networkStatusListeners.filter((l) => l !== listener);
    };
  }

  public closeSSE = (): void => {
    // Stop existing interval if it exists (don't try to reconnect automatically)
    if (this.restartSSEIntervalId) {
      clearInterval(this.restartSSEIntervalId);
    }
    // Close the eventsource
    if (this.eventSource) {
      this.eventSource.close();
    }
  };

  /**
   * Starts the SSE client (not intended to be called outside of this class).
   */
  protected restartSSE = (ssePath: string): void => {
    this.checkClosedStatus();
    // Close the current Event Source if it exists
    this.closeSSE();

    // Create the new EventSource (using the session token if available)
    log.debug(`Opening event source with baseUrl:'${this.baseUrl}', ssePath='${ssePath}'`);
    this.eventSource = this.evFactory(`${!this.baseUrl ? '' : this.baseUrl}${ssePath}`);

    // Clean event source listeners map
    this.eventSourceListenersMap.clear();

    /**
     * Connection opened handling.
     */
    this.eventSource.onopen = async (): Promise<void> => {
      // Switch to online mode
      await this.notifyNetworkStatusIsOnline(true);
    };

    /**
     * Connection error handling.
     */
    this.eventSource.onerror = async (event): Promise<void> => {
      // Switch to offline mode
      log.error(
        `[SSE] Error in server sent event, will try to reconnect in ${SSE_AUTORECONNECT_DELAY} ms`,
        event
      );
      // Close the event source and restart it after a delay
      this.eventSource.close();
      this.restartSSEIntervalId = setTimeout((): void => {
        this.restartSSE(ssePath);
      }, SSE_AUTORECONNECT_DELAY);
      // Notify UI
      await this.notifyNetworkStatusIsOnline(false);
    };

    // Activate message listeners
    log.info('Activate SSE listeners:', [...this.messageListeners.keys()].sort().join(', '));
    this.messageListeners.forEach((value, id): void => {
      this.activateMessageListener(id, value);
    });

    // Message handler.
    this.eventSource.onmessage = (event): void => {
      log.error('Unexpected error in SSE message reception', event);
    };
  };

  /**
   * Creates an EventListener that encapsulates the message listener and register it
   * in the EventSource.
   *
   * The EventListener is responsible to parse the message as JSON.
   */
  private activateMessageListener(id: string, sseMessageListener: ServerMessageListener): void {
    // JS event loop handle listeners like asynchronous notifications. The signature of the callback is
    // () => void but it can handle async callbacks
    const listener = (event: unknown): void => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      (async (): Promise<void> => {
        const dataStr = (event as MessageEvent).data;
        const data = JSON.parse(dataStr);
        // Log message only if it's not a ping
        if (id !== PING_SERVER_MESSAGE) {
          log.info(
            'sseMessage:',
            id,
            `${dataStr.slice(0, SSE_MESSAGE_LOG_MAX_LENGTH)}${
              dataStr.length > SSE_MESSAGE_LOG_MAX_LENGTH
                ? `... (${dataStr.length - SSE_MESSAGE_LOG_MAX_LENGTH} more chars)`
                : ''
            }`
          );
        }
        await sseMessageListener(data);
      })();
    };
    this.eventSource.addEventListener(id, listener, false);
    this.eventSourceListenersMap.set(sseMessageListener, listener);
  }

  /**
   * Notifies network status listener when if the network becomes online or offline.
   * @param isOnline true if the network is online.
   */
  private async notifyNetworkStatusIsOnline(isOnline: boolean): Promise<void> {
    await Promise.all(
      this.networkStatusListeners.map(async (networkStatusIsOnline): Promise<void> => {
        await networkStatusIsOnline(isOnline);
      })
    );
  }

  protected async fetchWithSessionToken(path: string, init: RequestInit = {}): Promise<Response> {
    this.checkClosedStatus();
    return await super.fetchWithSessionToken(path, init);
  }

  private checkClosedStatus = (): void => {
    if (this.isClosed) {
      throw new Error('HttpClient is closed');
    }
  };
}
