import type { i18n, TFunction } from 'i18next';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import commonFR from './common.fr.json' with { type: 'json' };

/**
 * Must be called only once at initialization
 * @param i18next
 */
function loadCommonI18NResourcesBundle(i18next: i18n): void {
  i18next.addResourceBundle('fr', 'common', commonFR);
}

function displayStringOrPlaceholder(t: TFunction, value: string | null | undefined): string {
  return isTruthyAndNotEmpty(value) ? value : t('common:noValue');
}

export const i18nHelpers = { displayStringOrPlaceholder, loadCommonI18NResourcesBundle };
