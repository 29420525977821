import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  PackageDealColumToHandle,
  PackageDealColumToHandleOption,
  PackageDealDesc,
} from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type {
  AppProps,
  CheckFormConsistencyAction,
  CheckFormFieldContentActions,
  HorizontalFormFieldProps,
} from '@stimcar/libs-uitoolkit';
import { PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX } from '@stimcar/core-libs-repository';
import { enumerate, packageDealDescHelpers, sortingHelpers } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import { InputFormField, ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { LabelledEntity } from '../../../../lib/components/LabelledEntityList.js';
import type { Store } from '../../../state/typings/store.js';
import type {
  CustomColumnData,
  CustomColumnDialogState,
  PackageDealColumToHandleOptionData,
  SingleOptionInputDialogState,
} from '../typings/store.js';
import { ReactSelectMultiFormField } from '../../../../lib/bulma/form/custom/ReactSelectMultiFormField.js';
import { LabelledEntityListFormField } from '../../../../lib/components/LabelledEntityListFormField.js';
import { useGetPackageDealDatabase } from '../../../utils/useGetContract.js';
import { EMPTY_SINGLE_OPTION_DIALOG_STATE } from '../typings/store.js';

function openCreateNewOptionModalAction({
  actionDispatch,
}: ActionContext<Store, SingleOptionInputDialogState>): void {
  actionDispatch.reduce((initial: SingleOptionInputDialogState): SingleOptionInputDialogState => {
    return {
      ...EMPTY_SINGLE_OPTION_DIALOG_STATE,
      active: true,
      mode: 'create',
      options: initial.options,
    };
  });
}

function openUpdateOptionModalAction({
  actionDispatch,
  getState,
}: ActionContext<Store, CustomColumnDialogState>): void {
  const { selectedOptionId, singleOptionInputDialogState } = getState();
  const selectedOption = singleOptionInputDialogState.options.find(
    (o) => o.id === selectedOptionId
  );
  const optionNameToUpdate = selectedOption?.id ?? '';
  actionDispatch
    .scopeProperty('singleOptionInputDialogState')
    .reduce((initial: SingleOptionInputDialogState): SingleOptionInputDialogState => {
      return {
        ...EMPTY_SINGLE_OPTION_DIALOG_STATE,
        active: true,
        mode: 'update',
        options: initial.options,
        optionNameToUpdate,
        optionToUpdate: initial.options.find((o) => o.id === optionNameToUpdate),
        formData: {
          optionName: optionNameToUpdate,
          pkgCodes:
            initial.options.find((o) => o.id === optionNameToUpdate)?.codesToCreateOrActivate ?? [],
          warnings: {},
        },
      };
    });
}

function deleteOptionModalAction({
  actionDispatch,
  getState,
}: ActionContext<Store, CustomColumnDialogState>): void {
  const { selectedOptionId, singleOptionInputDialogState } = getState();
  const selectedOption = singleOptionInputDialogState.options.find(
    (o) => o.id === selectedOptionId
  );
  const optionNameToDelete = selectedOption?.id ?? '';
  actionDispatch
    .scopeProperty('singleOptionInputDialogState')
    .reduce((initial: SingleOptionInputDialogState): SingleOptionInputDialogState => {
      return {
        ...initial,
        options: initial.options.filter((o) => o.id !== optionNameToDelete),
      };
    });
  actionDispatch.setProperty('selectedOptionId', '');
}

function createOrUpdateSingleOptionAction({
  getState,
  actionDispatch,
}: ActionContext<Store, SingleOptionInputDialogState>): void {
  const { formData, mode, optionNameToUpdate } = getState();
  const newOption: PackageDealColumToHandleOption = {
    id: formData.optionName,
    codesToCreateOrActivate: formData.pkgCodes,
  };
  if (mode === 'create') {
    actionDispatch.reduce((initial: SingleOptionInputDialogState) => {
      return {
        ...initial,
        options: [...initial.options, newOption],
        active: false,
      };
    });
  } else if (mode === 'update') {
    actionDispatch.reduce((initial: SingleOptionInputDialogState) => {
      const newOptions = initial.options.map((o): PackageDealColumToHandleOption => {
        if (o.id === optionNameToUpdate) {
          return newOption;
        }
        return o;
      });
      return {
        ...initial,
        options: newOptions,
        active: false,
      };
    });
  }
}

const SINGLE_OPTION_MANDATORY_FIELDS: (keyof PackageDealColumToHandleOptionData)[] = ['optionName'];

export interface SingleOptionInputProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, SingleOptionInputDialogState>;
  readonly pkgCodesSuggestions: readonly string[];
  readonly contractCode: string;
  readonly horizontalFormFields?: boolean | HorizontalFormFieldProps;
}

export function SingleOptionInputModal({
  $,
  $gs,
  pkgCodesSuggestions,
  contractCode,
  horizontalFormFields,
}: SingleOptionInputProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');
  const packageDealDatabase = useGetPackageDealDatabase($gs, contractCode);

  const checkFieldContentActions = useMemo((): CheckFormFieldContentActions<
    Store,
    SingleOptionInputDialogState
  > => {
    return {
      optionName: ({ value, formState }): string | undefined => {
        const { options, optionNameToUpdate } = formState;
        const existingOptionsLabels = options.map((option) => option.id.toLowerCase());
        if (value !== optionNameToUpdate && existingOptionsLabels.includes(value.toLowerCase())) {
          return t('warning.duplicatedOptionName', { optionName: value });
        }
        return undefined;
      },
      pkgCodes: async ({ value, packageDealDescRepository }): Promise<string | undefined> => {
        if (isTruthy(packageDealDatabase)) {
          const contractsPkgDescs = await packageDealDescRepository.getEntitiesFromIndex(
            PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX,
            packageDealDatabase
          );
          const optionsPDDs = contractsPkgDescs.filter((p) => value.includes(p.code));
          const foundExpertisePDD =
            packageDealDescHelpers.getExpertisePackageDealDescWithoutRaisingAnError(optionsPDDs);
          if (foundExpertisePDD === 'MORE_THAN_ONE_EXPERTISE_PACKAGE_DEAL') {
            return t('warning.multipleExpertisePackageDealsReferencedByOneOption');
          }
        }
        return undefined;
      },
    };
  }, [packageDealDatabase, t]);

  const submitValidDataAction = useActionCallback(createOrUpdateSingleOptionAction, [], $);
  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    SingleOptionInputDialogState
  >({
    $,
    mandatoryFields: SINGLE_OPTION_MANDATORY_FIELDS,
    checkFieldContentActions,
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });

  return (
    <ModalCardDialog
      $active={$.$active}
      titleIconId="exclamation-triangle"
      title={t('argumentsInputs.updateSingleOptionTitle')}
      onOkClicked={onFormSubmit}
    >
      <InputFormField
        label={t('argumentsInputs.optionLabel')}
        $={$formDataWithChangeTrigger.$optionName}
        horizontal={horizontalFormFields}
      />
      <ReactSelectMultiFormField
        label={t('argumentsInputs.pkgCodesToCreateOrActivate')}
        creation
        $={$formDataWithChangeTrigger.$pkgCodes}
        suggestions={pkgCodesSuggestions}
        isClearable
        horizontal={horizontalFormFields}
      />
    </ModalCardDialog>
  );
}

export function createOrUpdateCustomColumnAction({
  getState,
  actionDispatch,
}: ActionContext<Store, CustomColumnDialogState>): void {
  const { formData, mode, singleOptionInputDialogState, customColumnNameToUpdate } = getState();
  const newColumn: PackageDealColumToHandle = {
    id: formData.columnName,
    options: singleOptionInputDialogState.options,
  };
  if (mode === 'create') {
    actionDispatch.reduce((initial: CustomColumnDialogState) => {
      return {
        ...initial,
        customColumns: [...initial.customColumns, newColumn],
        active: false,
      };
    });
  } else if (mode === 'update') {
    actionDispatch.reduce((initial: CustomColumnDialogState) => {
      const newColumns = initial.customColumns.map((c): PackageDealColumToHandle => {
        if (c.id === customColumnNameToUpdate) {
          return newColumn;
        }
        return c;
      });
      return {
        ...initial,
        customColumns: newColumns,
        active: false,
      };
    });
  }
}

function separateOptionsWithExpertiseFromWithout(
  options: readonly PackageDealColumToHandleOption[],
  contractsPkgDescs: readonly PackageDealDesc[],
  t: TFunction
): {
  optionsWithExpertise: readonly string[];
  optionsWithoutExpertise: readonly string[];
} {
  const optionsWithExpertise: string[] = [];
  const optionsWithoutExpertise: string[] = [];
  options.forEach((option) => {
    const optionsPDDs = contractsPkgDescs.filter((p) =>
      option.codesToCreateOrActivate.includes(p.code)
    );
    const foundExpertisePDD =
      packageDealDescHelpers.getExpertisePackageDealDescWithoutRaisingAnError(optionsPDDs);
    if (foundExpertisePDD === 'MORE_THAN_ONE_EXPERTISE_PACKAGE_DEAL') {
      // theorically we are protected by checkFieldContentActions in SingleOptionInputModal
      throw new Error(t('warning.multipleExpertisePackageDealsReferencedByOneOption'));
    }
    if (foundExpertisePDD === 'NO_EXPERTISE_PACKAGE_DEAL') {
      optionsWithoutExpertise.push(option.id);
    } else {
      optionsWithExpertise.push(option.id);
    }
  });
  return { optionsWithExpertise, optionsWithoutExpertise };
}

const CUSTOM_COLUMN_MANDATORY_FIELDS: (keyof CustomColumnData)[] = ['columnName'];
const checkCustomColumnFieldContentActions: CheckFormFieldContentActions<
  Store,
  CustomColumnDialogState,
  [string]
> = {
  columnName: ({ value, formState, t }): string | undefined => {
    const { customColumns, customColumnNameToUpdate } = formState;
    const existingCustomColumnsNames = customColumns.map((column) => column.id.toLowerCase());
    if (
      value !== customColumnNameToUpdate &&
      existingCustomColumnsNames.includes(value.toLowerCase())
    ) {
      return t('warning.duplicatedColumnName', { columnName: value });
    }
    return undefined;
  },
};

async function initializeCustomColumnDialogState(
  { actionDispatch, packageDealDescRepository }: ActionContext<Store, CustomColumnDialogState>,
  packageDealDatabase: string
): Promise<void> {
  const packageDeals = await packageDealDescRepository.getEntitiesFromIndex(
    PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX,
    packageDealDatabase
  );
  const codes = packageDeals
    .map((pkg) => pkg.code)
    .sort((c1, c2) => sortingHelpers.compareStrings(c1, c2, 'UP'));
  actionDispatch.setProperty('allPkgCodesWithinContract', codes);
}

export interface CustomColumnProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, CustomColumnDialogState>;
  readonly contractCode: string;
  readonly horizontalFormFields?: boolean | HorizontalFormFieldProps;
}

export function CustomColumnModal({
  $,
  $gs,
  contractCode,
  horizontalFormFields,
}: CustomColumnProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');

  const packageDealDatabase = useGetPackageDealDatabase($gs, contractCode);

  const asyncEffect = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      if (isTruthy(packageDealDatabase)) {
        await actionDispatch.exec(initializeCustomColumnDialogState, packageDealDatabase);
      }
    },
    [packageDealDatabase],
    $
  );

  useEffect((): void => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
  }, [asyncEffect]);

  const pkgCodesSuggestions = useGetState($.$allPkgCodesWithinContract);

  const formWarning = useGetState($.$formWarning);

  const submitValidDataAction = useActionCallback(createOrUpdateCustomColumnAction, [], $);

  const checkOptionsConsistencyAction: CheckFormConsistencyAction<
    Store,
    CustomColumnDialogState,
    [string]
  > = useCallback(
    async (
      { formState, t, packageDealDescRepository },
      packageDealDatabase
    ): Promise<string | undefined> => {
      const { singleOptionInputDialogState } = formState;
      const { options } = singleOptionInputDialogState;
      if (!isTruthy(options) || options.length < 2) {
        return t('warning.lessThanTwoOptions');
      }
      const contractsPkgDescs = (await packageDealDescRepository.getAllEntities()).filter(
        (pdd) => pdd.database === packageDealDatabase
      );
      const { optionsWithExpertise, optionsWithoutExpertise } =
        separateOptionsWithExpertiseFromWithout(options, contractsPkgDescs, t);
      if (optionsWithExpertise.length > 0 && optionsWithoutExpertise.length > 0) {
        return t('warning.notAllOptionsReferenceAnExpertisePackageDealDesc', {
          optionsWithExpertise: enumerate(optionsWithExpertise),
          optionsWithoutExpertise: enumerate(optionsWithoutExpertise),
        });
      }
      return undefined;
    },
    []
  );

  const [onFormSubmit, genericOnFormChange, $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    CustomColumnDialogState,
    [string]
  >(
    {
      $,
      mandatoryFields: CUSTOM_COLUMN_MANDATORY_FIELDS,
      checkFieldContentActions: checkCustomColumnFieldContentActions,
      checkFormConsistencyAction: checkOptionsConsistencyAction,
      submitValidDataAction,
      t,
    },
    contractCode
  );

  // The genericOnFormChange must be manually bound because the selection field is
  // not within the formData
  const $selectedOptionIdWithChangeTrigger = useSelectorWithChangeTrigger(
    $.$selectedOptionId,
    genericOnFormChange
  );

  const createLabelledEntity = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch
        .scopeProperty('singleOptionInputDialogState')
        .exec(openCreateNewOptionModalAction);
      actionDispatch.setProperty('formWarning', undefined);
    },
    [],
    $
  );

  const editSelectedLabelledEntity = useActionCallback(openUpdateOptionModalAction, [], $);

  const deleteSelectedLabelledEntity = useActionCallback(deleteOptionModalAction, [], $);

  const options = useGetState($.$singleOptionInputDialogState.$options);

  const labelledEntities = useMemo((): LabelledEntity[] => {
    return options.map(({ id }): LabelledEntity => {
      return {
        id,
        label: id,
      };
    });
  }, [options]);

  return (
    <ModalCardDialog
      $active={$.$active}
      titleIconId="exclamation-triangle"
      title={t('argumentsInputs.updateCustomColumnTitle')}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <InputFormField
        label={t('argumentsInputs.airtableColumnName')}
        $={$formDataWithChangeTrigger.$columnName}
        horizontal={horizontalFormFields}
      />
      <LabelledEntityListFormField
        label={t('argumentsInputs.options')}
        labelledEntities={labelledEntities}
        editSelectedLabelledEntity={editSelectedLabelledEntity}
        deleteSelectedLabelledEntity={deleteSelectedLabelledEntity}
        createLabelledEntity={createLabelledEntity}
        $selectedEntityId={$selectedOptionIdWithChangeTrigger}
        horizontal={horizontalFormFields}
      />
      <SingleOptionInputModal
        $={$.$singleOptionInputDialogState}
        $gs={$gs}
        pkgCodesSuggestions={pkgCodesSuggestions}
        contractCode={contractCode}
        horizontalFormFields={horizontalFormFields}
      />
    </ModalCardDialog>
  );
}
