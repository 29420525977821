import type { BrowserHistory } from 'history';
import type { JSX } from 'react';
import React, { useCallback, useEffect } from 'react';
import type {
  ActionContext,
  DispatchableContextSetter,
  GlobalStoreStateSelector,
} from '@stimcar/libs-uikernel';
import {
  BrowserRouterWithExternalizedHistory,
  createDispatchableStore,
  useActionCallback,
  useGetState,
} from '@stimcar/libs-uikernel';
import type {
  BaseStoreDef,
  ProgressBarMonitor,
  ProgressRunnable,
} from '../../state/typings/store.js';
import type { BootMode } from '../../typings/index.js';
import { IsTrue } from '../../../react/index.js';
import { ResetContext } from '../../ResetContext.js';
import type { ProgressMonitorStoreDef, ProgressMonitorStoreState } from './state/typings/store.js';
import { LoadingProgressView } from './LoadingProgressView.js';
import { EMPTY_PROGRESS_MONITOR_STORE_STATE } from './state/store.js';

interface ProgressMonitorProps {
  readonly $progressMonitormGs: GlobalStoreStateSelector<ProgressMonitorStoreDef>;
}

function ProgressMonitor({ $progressMonitormGs }: ProgressMonitorProps): JSX.Element {
  const progressMaxValue = useGetState($progressMonitormGs.$maxValue);
  const progressValue = useGetState($progressMonitormGs.$value);
  const progressLabel = useGetState($progressMonitormGs.$label);
  return (
    <>
      {progressMaxValue > 0 && (
        <LoadingProgressView
          progressMaxValue={progressMaxValue}
          progressValue={progressValue}
          progressLabel={progressLabel}
        />
      )}
    </>
  );
}

interface Props<SD extends BaseStoreDef> {
  readonly contextSetter: DispatchableContextSetter<SD['actionContext']>;
  readonly history: BrowserHistory;
  readonly bootOrReset: (monitor: ProgressBarMonitor, mode: BootMode) => Promise<void> | void;
  readonly bootOrResetMaxProgress?: number;
  readonly children: React.ReactNode;
}

// Create the progress monitor store (cannot rely on a standard react state, otherwise
// every time the progress monitor is incremented, the whole app is re-rendered)
const [, $progressMonitormGs] = createDispatchableStore<ProgressMonitorStoreDef>(
  EMPTY_PROGRESS_MONITOR_STORE_STATE
);

function incrementProgressAction(
  { actionDispatch, getState }: ActionContext<ProgressMonitorStoreDef, ProgressMonitorStoreState>,
  increment: number
) {
  actionDispatch.setProperty('value', (getState().value ?? 0) + increment);
}

function setIndeterminateProgressAction({
  actionDispatch,
}: ActionContext<ProgressMonitorStoreDef, number | undefined>) {
  actionDispatch.setValue(undefined);
}

async function setProgressLabelAction(
  { actionDispatch }: ActionContext<ProgressMonitorStoreDef, ProgressMonitorStoreState>,
  label: string | undefined,
  incrementProgress?: number
) {
  actionDispatch.setProperty('label', label);
  if (incrementProgress !== undefined) {
    await actionDispatch.exec(incrementProgressAction, incrementProgress);
  }
}

function initProgressMonitorAction(
  { actionDispatch }: ActionContext<ProgressMonitorStoreDef, ProgressMonitorStoreState>,
  maxValue: number | undefined
) {
  actionDispatch.setProperty('maxValue', maxValue !== undefined ? maxValue : -1);
  actionDispatch.setProperty('label', undefined);
  // By default in indeterminate mode :
  actionDispatch.setProperty('value', undefined);
}

/**
 * This component is responsible for managing the progress bar (using a react native state
 * in order to be orthogonal to the store, so that it can be updated during long running
 * store actions).
 *
 * This component doesn't interact with the store. Until the reset or initialization process
 * is completed, no component using the store can be shown.
 */
export function WithProgressMonitorAppWrapper<SD extends BaseStoreDef>({
  contextSetter,
  history,
  bootOrReset,
  bootOrResetMaxProgress,
  children,
}: Props<SD>) {
  const initProgressMonitorActionCallback = useActionCallback(
    initProgressMonitorAction,
    [],
    $progressMonitormGs
  );

  const setIndeterminateProgressActionCallback = useActionCallback(
    setIndeterminateProgressAction,
    [],
    $progressMonitormGs.$value
  );

  const incrementProgressActionCallback = useActionCallback(
    incrementProgressAction,
    [],
    $progressMonitormGs
  );

  const setProgressLabelActionCallback = useActionCallback(
    setProgressLabelAction,
    [],
    $progressMonitormGs
  );

  const runWithProgressBar = useCallback(
    async (max: number | undefined, runnable: ProgressRunnable): Promise<void> => {
      await initProgressMonitorActionCallback(max);
      try {
        await runnable({
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          setIndeterminateProgress: setIndeterminateProgressActionCallback,
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          incrementProgress: incrementProgressActionCallback,
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          setLabel: setProgressLabelActionCallback,
        });
      } finally {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        initProgressMonitorActionCallback(-1);
      }
    },
    [
      incrementProgressActionCallback,
      initProgressMonitorActionCallback,
      setIndeterminateProgressActionCallback,
      setProgressLabelActionCallback,
    ]
  );

  const setAppIsInitializedCallback = useActionCallback(
    function setAppIsInitializedAction({ actionDispatch }, isInitialized: boolean) {
      actionDispatch.setValue(isInitialized);
    },
    [],
    $progressMonitormGs.$appIsInitialized
  );

  const bootOrResetWithProgressBar = useCallback(
    async (mode: BootMode) => {
      await setAppIsInitializedCallback(false);
      await runWithProgressBar(bootOrResetMaxProgress, async (progress) => {
        await bootOrReset(progress, mode);
      });
      await setAppIsInitializedCallback(true);
    },
    [bootOrReset, bootOrResetMaxProgress, runWithProgressBar, setAppIsInitializedCallback]
  );

  const resetWithProgressBar = useCallback(
    async (hardReset: boolean) => bootOrResetWithProgressBar(hardReset ? 'HardReset' : 'SoftReset'),
    [bootOrResetWithProgressBar]
  );

  useEffect(() => {
    // Execute the resect callback during bootstrap to initialize the application
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    bootOrResetWithProgressBar('Boot');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /**
     * Initialise common action context.
     */
    contextSetter({
      runWithProgressBar,
      // Inject navigate function using the shared router history (statically created before)
      navigate: (to) => history.push(to),
    });
  }, [contextSetter, history, runWithProgressBar]);

  return (
    <BrowserRouterWithExternalizedHistory history={history}>
      <ResetContext.Provider value={resetWithProgressBar}>
        <ProgressMonitor $progressMonitormGs={$progressMonitormGs} />
        {/** The store components are not loaded until the app is initialized */}
        <IsTrue $={$progressMonitormGs.$appIsInitialized}>
          <>{children}</>
        </IsTrue>
      </ResetContext.Provider>
    </BrowserRouterWithExternalizedHistory>
  );
}
