import type { JSX } from 'react';
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import type { ActionCallbackFromFunction, NoArgActionCallback } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import type { Store } from '../../../app/state/typings/store.js';
import { downloadAndSaveBlob } from '../../utils/download.js';
import './PictureInputCameraModal.scss';
import { usePreventZoom, useRemoveScreenOverflow } from './utils/pictureInputUtils.js';

const PICTURE_WIDTH = 1920;
const PICTURE_HEIGHT = 1080;

type CameraProps = Omit<PictureInputCameraModalProps, 'isPortrait'>;

function Camera({
  $gs,
  onClose,
  filename,
  kanbanId,
  onSelectFile,
  onImportAttachmentCallback,
}: CameraProps): JSX.Element {
  const [t] = useTranslation('libComponents');

  const videoElementRef = useRef<HTMLVideoElement>(null);
  const videoStreamRef = useRef<MediaStream | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  const imageCaptureRef = useRef<ImageCapture | null>(null);

  const initializeRefsAsyncEffect = useActionCallback(
    async ({ actionDispatch, globalActionDispatch }) => {
      try {
        const videoStream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: 'environment',
            width: { ideal: PICTURE_WIDTH },
            height: { ideal: PICTURE_HEIGHT },
          },
        });

        videoStreamRef.current = videoStream;

        if (isTruthy(videoElementRef.current)) {
          videoElementRef.current.srcObject = videoStream;
          imageCaptureRef.current = new ImageCapture(videoStream.getVideoTracks()[0]);
        }
      } catch (error) {
        globalActionDispatch.setProperty(
          'message',
          `${t('cameraModal.cantStartImageCapture')} : ${error}`
        );
        await actionDispatch.execCallback(onClose);
      }
    },
    [t, onClose],
    $gs
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initializeRefsAsyncEffect();
    return () => {
      if (videoStreamRef.current) {
        const tracks = videoStreamRef.current.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [initializeRefsAsyncEffect]);

  const takePictureActionCallback = useActionCallback(
    async ({ actionDispatch, globalActionDispatch }) => {
      if (imageCaptureRef.current) {
        try {
          const blob = await imageCaptureRef.current.takePhoto({
            imageWidth: PICTURE_WIDTH,
            imageHeight: PICTURE_HEIGHT,
          });
          await actionDispatch.execCallback(onImportAttachmentCallback, blob);
          await downloadAndSaveBlob(blob, `${kanbanId}_${filename}`);
        } catch {
          await actionDispatch.execCallback(onClose);
          globalActionDispatch.setProperty('message', t('cameraModal.cantTakePicture'));
        }
      }
    },
    [filename, kanbanId, onClose, onImportAttachmentCallback, t],
    $gs
  );

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video ref={videoElementRef} autoPlay playsInline className="camera-stream" />
      <img
        alt="mask"
        className="camera-stream-mask"
        src={`/img/standard-pictures/mask/mask_${filename}`}
        onError={({ currentTarget }) => {
          // eslint-disable-next-line no-param-reassign
          currentTarget.onerror = null; // prevents infinite loop
          // eslint-disable-next-line no-param-reassign
          currentTarget.style.display = 'none'; // hide if mask is not available
        }}
      />
      <button
        type="button"
        onClick={takePictureActionCallback}
        aria-label={t('cameraModal.pictureButtonAriaLabel')}
        className="camera-picture-button is-flex is-align-items-center is-justify-content-center has-background-white"
      />
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <span
        className="select-from-gallery-icon icon has-text-white is-size-1"
        onClick={onSelectFile}
      >
        <i className="fa-solid fa-folder-open" />
      </span>
    </>
  );
}

interface PictureInputCameraModalProps extends AppProps<Store> {
  readonly filename: string;
  readonly kanbanId: string;
  readonly isPortrait: boolean;
  readonly onSelectFile: VoidFunction;
  readonly onClose: NoArgActionCallback<Store>;
  readonly onImportAttachmentCallback: ActionCallbackFromFunction<Store, (blob: Blob) => void>;
}

export function PictureInputCameraModal({
  $gs,
  onClose,
  kanbanId,
  filename,
  isPortrait,
  onSelectFile,
  onImportAttachmentCallback,
}: PictureInputCameraModalProps): JSX.Element {
  usePreventZoom();
  useRemoveScreenOverflow();
  const [t] = useTranslation('libComponents');
  return createPortal(
    <div className="picture-input-camera-modal modal is-active">
      <div className="modal-background" />
      <div className="modal-content camera-container">
        {isPortrait ? (
          <div className="is-flex is-flex-direction-column is-align-items-center">
            <span className="icon has-text-white panorama-icon">
              <i className="fa-solid fa-panorama" />
            </span>
            <h1 className="title is-4 px-5 pt-5 has-text-white has-text-centered">
              {t('cameraModal.switchToLandscape')}
            </h1>
          </div>
        ) : (
          <Camera
            $gs={$gs}
            onClose={onClose}
            kanbanId={kanbanId}
            filename={filename}
            onSelectFile={onSelectFile}
            onImportAttachmentCallback={onImportAttachmentCallback}
          />
        )}
      </div>
      <button className="modal-close is-large" aria-label="close" type="button" onClick={onClose} />
    </div>,
    document.body
  );
}
