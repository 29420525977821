import { asyncTimeout, ensureError } from '@stimcar/libs-kernel';

export interface Poller {
  /**
   * Starts the poller and waits for the first execution to complete.
   *
   * The caller can choose not to wait for the first execution end just
   * by omitting the await keyword when calling the function.
   */
  start: () => Promise<void>;

  /**
   * Forces the poller to launch a run a soon as possible.
   */
  forceRunNow: () => Promise<void>;

  /**
   * Stops the poller.
   */
  stop: () => Promise<void>;
}

export type PollerRunnable = () => void | Promise<void>;

export type PollerErrorHandler = (error: Error) => Promise<void> | void;

export function newPoller(
  sleepInterval: number,
  runnable: PollerRunnable,
  errorHandler: PollerErrorHandler
): Poller {
  let status: 'STOPPED' | 'STARTED' | 'STOP SCHEDULED' = 'STOPPED';
  let nextTimeout: NodeJS.Timeout | undefined;
  const poller = async (): Promise<void> => {
    nextTimeout = undefined;
    const start = new Date().getTime();
    try {
      await runnable();
    } catch (e) {
      try {
        await errorHandler(ensureError(e));
      } catch {
        // The error handler is not supposed to raise an error.
        // If it raises an error, ignore it.
      }
    } finally {
      // Poller management
      // If a stop has been scheduled, stop the poller
      if (status === 'STOP SCHEDULED') {
        status = 'STOPPED';
      } else {
        // Otherwiser schedule a new call
        const now = new Date().getTime();
        let fixedSleepInterval = sleepInterval - (now - start);
        if (fixedSleepInterval < 1) {
          fixedSleepInterval = 1;
        }
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        nextTimeout = setTimeout(poller, fixedSleepInterval);
      }
    }
  };
  const forceRunNow = async (): Promise<void> => {
    if (status !== 'STARTED') {
      throw Error('Poller is not started');
    }
    if (nextTimeout === undefined) {
      // If the poller is running, wait until run is finished before relaunching
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      setTimeout(forceRunNow, 100);
    } else {
      // Remove timeout
      clearTimeout(nextTimeout);
      // Run now
      await poller();
    }
  };
  return {
    start: async (): Promise<void> => {
      if (status !== 'STOPPED') {
        throw Error('Poller is already started');
      }
      status = 'STARTED';
      await poller();
    },
    forceRunNow,
    stop: async (): Promise<void> => {
      if (status !== 'STARTED') {
        throw Error(`Poller is not started (status:${status})`);
      }
      if (nextTimeout) {
        clearTimeout(nextTimeout);
        status = 'STOPPED';
      } else {
        status = 'STOP SCHEDULED';
        while (String(status) !== 'STOPPED') {
          // eslint-disable-next-line no-await-in-loop
          await asyncTimeout(100);
        }
      }
    },
  };
}
