import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Customer, SpecificFields } from '@stimcar/libs-base';
import type { DeepPartial } from '@stimcar/libs-kernel';
import type {
  ActionContext,
  AnyStoreDef,
  NoArgActionCallback,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import type {
  CheckFormConsistencyAction,
  CheckFormFieldContentActions,
} from '@stimcar/libs-uitoolkit';
import { computePayload } from '@stimcar/libs-kernel';
import { useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { CustomerForm, EditCustomerDialogState } from './typings/store.js';
import {
  checkEditCustomerFormFieldContentActions,
  EditCustomerForm,
  useEditCustomerFormMandatoryFields,
} from './EditCustomerForm.js';
import { EMPTY_EDIT_CUSTOMER_DIALOG_STATE } from './typings/store.js';

export function convertBusinessCustomerToFormData({
  invoiceId,
  individual,
  contract,
  ...rest
}: SpecificFields<Customer>): CustomerForm {
  return {
    ...rest,
    contractCode: contract,
    invoiceId: String(invoiceId),
    type: individual ? 'individual' : 'professional',
    warnings: {},
  };
}

// eslint-disable-next-line @typescript-eslint/require-await
export async function openEditCustomerDialogAction<
  SD extends AnyStoreDef,
  S extends EditCustomerDialogState,
>(
  { actionDispatch }: ActionContext<SD, S>,
  initialCustomer?: SpecificFields<Customer>,
  mode?: S['mode']
): Promise<void> {
  let selectedMode: EditCustomerDialogState['mode'];
  if (mode) {
    selectedMode = mode;
  } else {
    selectedMode = initialCustomer ? 'update' : 'create';
  }
  actionDispatch.reduce(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (initial: S): S => {
      return {
        ...initial, // important to keep parent additions (the state may be a subclass)
        ...EMPTY_EDIT_CUSTOMER_DIALOG_STATE,
        active: true,
        initialCustomer,
        mode: selectedMode,
        formData: initialCustomer
          ? convertBusinessCustomerToFormData(initialCustomer)
          : EMPTY_EDIT_CUSTOMER_DIALOG_STATE.formData,
      };
    }
  );
}

export function computeCustomerFormDataPayload(
  initialCustomer: SpecificFields<Customer> | undefined,
  customer: CustomerForm
): undefined | DeepPartial<CustomerForm> {
  return initialCustomer === undefined
    ? customer
    : computePayload(convertBusinessCustomerToFormData(initialCustomer), customer);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const checkFormConsistencyAction: CheckFormConsistencyAction<any, EditCustomerDialogState> = ({
  formState,
  t,
}): string | undefined => {
  const { initialCustomer, formData } = formState;
  const formDataPayload = computeCustomerFormDataPayload(initialCustomer, formData);
  if (formDataPayload === undefined) {
    return t('editCustomerDialog.warnings.noChange');
  }
  return undefined;
};

export interface EditCustomerDialogProps<SD extends AnyStoreDef> {
  readonly $: StoreStateSelector<SD, EditCustomerDialogState>;
  readonly checkFieldContentActions?: CheckFormFieldContentActions<SD, EditCustomerDialogState>;
  readonly submitValidDataAction: NoArgActionCallback<SD>;
  readonly hideContractIdField?: boolean;
  readonly contractCodes: readonly string[];
}

export function EditCustomerDialog<SD extends AnyStoreDef>({
  $,
  checkFieldContentActions: providedCheckFieldContentActions,
  submitValidDataAction,
  hideContractIdField,
  contractCodes,
}: EditCustomerDialogProps<SD>): JSX.Element {
  const [t] = useTranslation('libComponents');
  const mode = useGetState($.$mode);

  // Aggregate check field content actions
  const checkFieldContentActions = useMemo((): CheckFormFieldContentActions<
    SD,
    EditCustomerDialogState,
    []
  > => {
    return {
      ...(!providedCheckFieldContentActions ? {} : providedCheckFieldContentActions),
      ...checkEditCustomerFormFieldContentActions,
    };
  }, [providedCheckFieldContentActions]);

  const mandatoryFields = useEditCustomerFormMandatoryFields($.$formData, hideContractIdField);

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    SD,
    EditCustomerDialogState
  >({
    $,
    mandatoryFields,
    checkFieldContentActions,
    checkFormConsistencyAction,
    submitValidDataAction,
    t,
  });

  return (
    <ModalCardDialog
      title={t(`editCustomerDialog.title.${mode}`)}
      $active={$.$active}
      okLabel={t(`editCustomerDialog.buttons.validate.${mode}`)}
      onOkClicked={onFormSubmit}
      warning={useGetState($.$formWarning)}
    >
      <div className="columns">
        <div className="column">
          <EditCustomerForm
            hideContractIdField={hideContractIdField}
            $formData={$formDataWithChangeTrigger}
            contractCodes={contractCodes}
          />
        </div>
      </div>
    </ModalCardDialog>
  );
}
