export const COLOR_PALETTE = [
  '#51574A',
  '#447C69',
  '#74C493',
  '#8E8C6D',
  '#E4BF80',
  '#E9D78E',
  '#E2975D',
  '#F19670',
  '#E16552',
  '#C94A53',
  '#BE5168',
  '#A34974',
  '#993767',
  '#65387D',
  '#4E2472',
  '#9163B6',
  '#E279A3',
  '#E0598B',
  '#7C9FB0',
  '#5698C4',
  '#9ABF88',
];
