// This is based on https://stackoverflow.com/a/32490603 for the extraction of the EXIF orientation value
// Add a fallback with the height and width of the image if something happen and wrap it in a promise
const getOrientation = async (file: File): Promise<number | 'vertical' | 'horizontal'> => {
  const reader = new FileReader();

  return new Promise((resolve) => {
    reader.onload = (event: ProgressEvent): void => {
      if (!event.target) {
        resolve(-2);
        return;
      }

      const fileContent = (event.target as FileReader).result as ArrayBuffer;
      const view = new DataView(fileContent);

      if (view.getUint16(0, false) === 0xffd8) {
        const length = view.byteLength;
        let offset = 2;
        while (offset < length) {
          if (view.getUint16(offset + 2, false) <= 8) {
            resolve(-1);
            return;
          }
          const marker = view.getUint16(offset, false);
          offset += 2;
          if (marker === 0xffe1) {
            offset += 2;
            if (view.getUint32(offset, false) !== 0x45786966) {
              resolve(-1);
              return;
            }
            const little = view.getUint16((offset += 6), false) === 0x4949;
            offset += view.getUint32(offset + 4, little);
            const tags = view.getUint16(offset, little);
            offset += 2;
            for (let i = 0; i < tags; i += 1) {
              if (view.getUint16(offset + i * 12, little) === 0x0112) {
                resolve(view.getUint16(offset + i * 12 + 8, little));
                return;
              }
            }
            // eslint-disable-next-line no-bitwise
          } else if ((marker & 0xff00) !== 0xff00) {
            break;
          } else {
            offset += view.getUint16(offset, false);
          }
        }
      }
      const image = new Image();

      const blob = new Blob([fileContent], { type: 'image/jpeg' });
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(blob);
      image.src = imageUrl;
      image.onload = (): void => {
        const orientation = image.width < image.height ? 'vertical' : 'horizontal';
        resolve(orientation);
      };
    };

    reader.readAsArrayBuffer(file);
  });
};

export const imageHelpers = {
  getOrientation,
};
