import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../state/typings/store.js';
import type { DefectsTabState, DeleteDefectDialogState } from '../../../typings/store.js';
import { EMPTY_DELETE_DEFECT_DIALOG_STATE } from '../../../typings/store.js';

export function openDeleteDefectModalAction(
  { actionDispatch }: ActionContext<Store, DeleteDefectDialogState>,
  defectId: string
): void {
  actionDispatch.setValue({
    ...EMPTY_DELETE_DEFECT_DIALOG_STATE,
    defectId,
    active: true,
  });
}

function deleteDefectAction({
  getState,
  actionDispatch,
}: ActionContext<Store, DefectsTabState>): void {
  const { deleteDefectDialogState } = getState();
  const { defectId } = deleteDefectDialogState;
  actionDispatch.scopeProperty('defects').applyPayload([{ id: defectId, deleted: true }]);

  // Then close dialog
  actionDispatch
    .scopeProperty('deleteDefectDialogState')
    .setValue(EMPTY_DELETE_DEFECT_DIALOG_STATE);
}

type DeleteDefectModalProps = {
  readonly $: StoreStateSelector<Store, DefectsTabState>;
};

export function DeleteDefectModal({ $ }: DeleteDefectModalProps): JSX.Element {
  const [t] = useTranslation('operators', { keyPrefix: 'defects.deleteDefect' });

  const deleteDefectActionCallback = useActionCallback(deleteDefectAction, [], $);

  return (
    <ModalCardDialog
      title={t('title')}
      $active={$.$deleteDefectDialogState.$active}
      okLabel={t('okButtonLabel')}
      onOkClicked={deleteDefectActionCallback}
    >
      <p>{t('confirmDeletionMsg')}</p>
    </ModalCardDialog>
  );
}
