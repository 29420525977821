import type { CSSProperties, JSX, SyntheticEvent } from 'react';
import React, { useCallback, useEffect, useRef } from 'react';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import type {
  CarViewBaseProps,
  CarViewCanvasSize,
  RawCarViewCanvasBaseProps,
} from './car-view-common.js';

const DEFAULT_VIEW_NODE_BG_COLOR = '#e0e0e0';

const SHADE_STYLE: CSSProperties = {
  fill: '#849bf1',
  stroke: 'none',
  strokeWidth: 0.265,
  strokeLinecap: 'butt',
  strokeLinejoin: 'miter',
  strokeOpacity: 1,
  fillOpacity: 1,
  strokeMiterlimit: 4,
  strokeDasharray: 'none',
  opacity: 0.5,
};

function getIdFromViewNode(rootSvg: SVGSVGElement, element: Node): string | undefined {
  const id = Reflect.get(element, 'id');
  if (!id) {
    const parent = Reflect.get(element, 'parentNode');
    if (parent === null || parent === rootSvg) {
      return undefined;
    }
    return getIdFromViewNode(rootSvg, parent);
  }
  return id;
}

interface CanvasProps {
  readonly showCarBackground: boolean;
  readonly children?: React.ReactNode;
}

interface RawProps extends RawCarViewCanvasBaseProps, CanvasProps {
  readonly rootSvgRef: React.RefObject<SVGSVGElement | null>;
  readonly onClickCallback?: (e: SyntheticEvent) => void;
}

export function RawCarViewCanvas({
  rootSvgRef,
  backgroundColor,
  onClickCallback,
  children,
  maxHeight,
  maxWidth,
  horizontal,
  showCarBackground,
}: RawProps): JSX.Element {
  return (
    <svg
      width="100%"
      viewBox="0 0 200 225"
      version="1.1"
      onClick={onClickCallback}
      ref={rootSvgRef}
      style={{ maxHeight, maxWidth }}
      transform={`rotate(${horizontal ? 90 : 0})`}
    >
      {/* background color */}
      <rect
        width="100%"
        height="100%"
        fill={!backgroundColor ? DEFAULT_VIEW_NODE_BG_COLOR : backgroundColor}
      />
      {showCarBackground && (
        <path
          style={SHADE_STYLE}
          d="m 62.450731,11.135417 -34.491679,29.360686 0.285055,142.812647 14.25276,18.24354 35.6319,13.9677 42.758273,-0.28506 36.77212,-13.9677 13.68265,-17.95848 L 170.7717,40.211046 137.13519,11.420472 Z"
        />
      )}
      {children}
    </svg>
  );
}

interface PropsWithSizeHandler<SD extends AnyStoreDef>
  extends Omit<Props<SD>, '$size' | 'shapeClicked'>,
    CanvasProps {
  readonly $size: StoreStateSelector<SD, CarViewCanvasSize>;
  readonly onClickCallback?: (e: SyntheticEvent) => void;
}

function CarViewCanvasWithSizeHandler<SD extends AnyStoreDef>({
  backgroundColor,
  onClickCallback,
  children,
  $size,
  maxHeight,
  maxWidth,
  horizontal,
  showCarBackground,
}: PropsWithSizeHandler<SD>): JSX.Element {
  const rootSvg = useRef<SVGSVGElement>(null);

  const eventHandlerActionCallback = useActionCallback(
    ({ actionDispatch }) => {
      if (rootSvg && rootSvg.current) {
        actionDispatch.setProperty('width', rootSvg.current.width.animVal.value);
        actionDispatch.setProperty('height', rootSvg.current.height.animVal.value);
      }
    },
    [],
    $size
  );
  useEffect((): (() => void) => {
    // Register a click listener
    // JS event loop handle listeners like asynchronous notifications. The signature of the callback is
    // () => void but it can handle async callbacks
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    window.addEventListener('resize', eventHandlerActionCallback, false);
    // Call one to initialize with the initial size
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    eventHandlerActionCallback();
    return (): void => {
      // Unregister the click listener
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      window.removeEventListener('resize', eventHandlerActionCallback, false);
    };
  }, [eventHandlerActionCallback]);

  return (
    <RawCarViewCanvas
      rootSvgRef={rootSvg}
      showCarBackground={showCarBackground}
      backgroundColor={backgroundColor}
      horizontal={horizontal}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      onClickCallback={onClickCallback}
    >
      {children}
    </RawCarViewCanvas>
  );
}

interface Props<SD extends AnyStoreDef> extends CarViewBaseProps<SD>, CanvasProps {}

export function CarViewCanvas<SD extends AnyStoreDef>({
  backgroundColor,
  shapeClicked,
  children,
  $size,
  maxHeight,
  maxWidth,
  horizontal,
  showCarBackground,
}: Props<SD>): JSX.Element {
  const rootSvg = useRef<SVGSVGElement>(null);

  const onClickCallback = useCallback(
    function onClickAction(e: SyntheticEvent) {
      e.preventDefault();
      const { target } = e.nativeEvent;
      if (target) {
        const id = getIdFromViewNode(rootSvg.current!, target as Node);
        if (id && shapeClicked) {
          shapeClicked(id);
        }
      }
    },
    [shapeClicked]
  );

  return (
    <>
      {$size !== undefined ? (
        <CarViewCanvasWithSizeHandler
          $size={$size}
          showCarBackground={showCarBackground}
          backgroundColor={backgroundColor}
          horizontal={horizontal}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          onClickCallback={onClickCallback}
        >
          {children}
        </CarViewCanvasWithSizeHandler>
      ) : (
        <RawCarViewCanvas
          rootSvgRef={rootSvg}
          showCarBackground={showCarBackground}
          backgroundColor={backgroundColor}
          horizontal={horizontal}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          onClickCallback={onClickCallback}
        >
          {children}
        </RawCarViewCanvas>
      )}
    </>
  );
}
