import type { JSX } from 'react';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { kanbanHelpers } from '@stimcar/libs-base';
import { isTruthy, keysOf, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Button, ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type {
  OperatorExpertViewValidationErrors,
  OperatorViewState,
  WorkStatus,
} from '../../typings/store.js';
import { ShowEstimateButton } from '../../../../lib/components/documentGeneration/estimate/EstimateView.js';
import {
  clearOperatorViewAndNavigateToSelectionAction,
  closeKanbanHandleOnPost,
  pauseWorkAction,
  startWorkAction,
  unpauseWorkAction,
} from '../../../utils/operatorUtils.js';
import { useGetContractByCode } from '../../../utils/useGetContract.js';
import { addValidationOperation } from '../../expertiseUtils.js';

interface ExpertStartPauseButtonProps {
  readonly workStatus: WorkStatus;
  readonly kanban: Kanban;
  readonly hasLocalChanges: boolean;
  readonly isButtonDisabled: boolean;
  readonly standId: string;
  readonly $: StoreStateSelector<Store, OperatorViewState>;
}

export function ExpertStartPauseButton({
  workStatus,
  hasLocalChanges,
  kanban,
  isButtonDisabled,
  standId,
  $,
}: ExpertStartPauseButtonProps): JSX.Element {
  const [t] = useTranslation('operators');

  const [buttonLabel, tooltip, iconId] = useMemo((): string[] => {
    switch (workStatus) {
      case 'paused':
        return [t('timer.unpause'), t('timer.unpauseTooltip'), 'play'];
      case 'started':
        if (hasLocalChanges) {
          return [t('timer.saveAndPause'), t('timer.saveAndPauseTooltip'), 'pause'];
        }
        return [t('timer.pause'), t('timer.pauseTooltip'), 'pause'];
      default:
        return [t('timer.start'), t('timer.startTooltip'), 'play'];
    }
  }, [workStatus, hasLocalChanges, t]);

  const startPauseButtonClickHandler = useActionCallback(
    async ({
      actionDispatch,
      getGlobalState,
      httpClient,
    }: ActionContext<Store, OperatorViewState>): Promise<void> => {
      const { session, siteConfiguration } = getGlobalState();
      const userLogin = session.user?.login ?? null;
      const sequence = httpClient.getBrowserSequence();

      const updatedKanban = hasLocalChanges
        ? kanbanHelpers.computeSparePartReferenceOperationState(
            kanban,
            userLogin,
            siteConfiguration,
            sequence
          )
        : kanban;

      if (workStatus === 'unstarted') {
        await actionDispatch.exec(startWorkAction, updatedKanban, standId);
      } else if (workStatus === 'started') {
        await actionDispatch.exec(pauseWorkAction, updatedKanban);
      } else if (workStatus === 'paused') {
        await actionDispatch.exec(unpauseWorkAction, updatedKanban);
      }
    },
    [hasLocalChanges, kanban, workStatus, standId],
    $
  );

  return (
    <Button
      tooltip={tooltip}
      label={buttonLabel}
      onClick={startPauseButtonClickHandler}
      disabled={isButtonDisabled}
      iconId={iconId}
      isFullWidth
    />
  );
}

interface ExpertInterruptButtonProps {
  readonly kanban: Kanban;
  readonly hasLocalChanges: boolean;
  readonly isButtonDisabled: boolean;
  readonly $: StoreStateSelector<Store, OperatorViewState>;
  readonly standId: string;
}

export function ExpertInterruptButton({
  hasLocalChanges,
  kanban,
  isButtonDisabled,
  $,
}: ExpertInterruptButtonProps): JSX.Element {
  const [t] = useTranslation('operators');

  const [buttonLabel, tooltip] = useMemo((): string[] => {
    if (hasLocalChanges) {
      return [t('timer.saveAndInterrupt'), t('timer.saveAndInterruptTooltip')];
    }
    return [t('timer.interrupt'), t('timer.interrupt')];
  }, [hasLocalChanges, t]);

  const interruptButtonClickHandler = useActionCallback(
    async ({
      actionDispatch,
      getGlobalState,
      kanbanRepository,
      httpClient,
    }: ActionContext<Store, OperatorViewState>): Promise<void> => {
      const { session, siteConfiguration } = getGlobalState();
      const userLogin = session.user?.login ?? null;
      const sequence = httpClient.getBrowserSequence();

      let updatedKanban = closeKanbanHandleOnPost(
        nonnull(session.infos?.id),
        kanban,
        {},
        undefined, // no operations will be finished on interrupt
        userLogin
      );

      if (hasLocalChanges) {
        updatedKanban = kanbanHelpers.computeSparePartReferenceOperationState(
          updatedKanban,
          userLogin,
          siteConfiguration,
          sequence
        );
      }

      await kanbanRepository.updateEntity(updatedKanban);
      await actionDispatch.exec(clearOperatorViewAndNavigateToSelectionAction);
    },
    [kanban, hasLocalChanges],
    $
  );

  return (
    <Button
      tooltip={tooltip}
      onClick={interruptButtonClickHandler}
      disabled={isButtonDisabled}
      label={buttonLabel}
      iconId="stop"
      isFullWidth
    />
  );
}

interface FinishButtonProps {
  readonly $: StoreStateSelector<Store, OperatorViewState>;
  readonly kanban: Kanban;
  readonly standId: string;
  readonly hasLocalChanges: boolean;
  readonly isButtonDisabled: boolean;
  readonly getValidationErrors: (k: Kanban) => OperatorExpertViewValidationErrors;
}

export function ExpertFinishButton({
  $,
  kanban,
  standId,
  hasLocalChanges,
  isButtonDisabled,
  getValidationErrors,
}: FinishButtonProps): JSX.Element {
  const [t] = useTranslation('operators');

  const [buttonLabel, tooltip] = useMemo((): string[] => {
    if (hasLocalChanges) {
      return [t('timer.saveAndFinish'), t('timer.saveAndFinishTooltip')];
    }
    return [t('timer.finish'), t('timer.finishTooltip')];
  }, [hasLocalChanges, t]);

  const finishButtonClickHandler = useActionCallback(
    async ({ actionDispatch, getGlobalState, httpClient, kanbanRepository }) => {
      const validationErrors = getValidationErrors(kanban);
      const numberOfErrors = Object.values(validationErrors).flat().length;
      if (numberOfErrors > 0) {
        actionDispatch.scopeProperty('expertOperatorState').reduce((initial) => {
          return {
            ...initial,
            validationErrors,
            showValidationErrorsModal: true,
          };
        });
      } else {
        const { siteConfiguration, session } = getGlobalState();
        const postId = nonnull(session.infos?.id);
        const userPermission = session.user?.permissions ?? {};
        const userLogin = session.user?.login ?? null;
        const sequence = httpClient.getBrowserSequence();

        let updatedKanban = addValidationOperation(sequence, kanban, siteConfiguration);
        updatedKanban = closeKanbanHandleOnPost(
          postId,
          updatedKanban,
          userPermission,
          standId,
          userLogin
        );
        if (hasLocalChanges) {
          updatedKanban = kanbanHelpers.computeSparePartReferenceOperationState(
            updatedKanban,
            userLogin,
            siteConfiguration,
            sequence
          );
        }

        await kanbanRepository.updateEntity(updatedKanban);
        await actionDispatch.exec(clearOperatorViewAndNavigateToSelectionAction);
      }
    },
    [getValidationErrors, hasLocalChanges, kanban, standId],
    $
  );

  return (
    <Button
      tooltip={tooltip}
      onClick={finishButtonClickHandler}
      disabled={isButtonDisabled}
      label={buttonLabel}
      iconId="check"
      isFullWidth
    />
  );
}

interface ExpertComponentButtonsProps extends AppProps<Store> {
  readonly kanban: Kanban;
  readonly hasLocalChanges: boolean;
  readonly postId: string;
  readonly getValidationErrors: (k: Kanban) => OperatorExpertViewValidationErrors;
  readonly standId: string;
  readonly showEstimateCallback: () => Promise<void> | void;
  readonly $: StoreStateSelector<Store, OperatorViewState>;
}

export function ExpertComponentButtons({
  $,
  $gs,
  kanban,
  hasLocalChanges,
  postId,
  getValidationErrors,
  standId,
  showEstimateCallback,
}: ExpertComponentButtonsProps): JSX.Element {
  const [t] = useTranslation('operators');

  const workStatus = useGetState($.$workStatus);
  const validationErrors = useGetState($.$expertOperatorState.$validationErrors);
  const contract = useGetContractByCode($gs, kanban.contract.code);

  const isStartButtonDisabled = useMemo(
    (): boolean =>
      workStatus === 'finished' &&
      kanbanHelpers.getOpenOperatorHandleForPost(kanban, postId) === undefined,
    [workStatus, kanban, postId]
  );

  const isFinishButtonDisabled = useMemo(
    (): boolean =>
      !isTruthy(kanban) ||
      isStartButtonDisabled ||
      (workStatus !== 'finished' &&
        kanbanHelpers.getOpenOperatorHandleForPost(kanban, postId) === undefined),
    [workStatus, kanban, postId, isStartButtonDisabled]
  );

  const displayContractHintsClickHandler = useActionCallback(
    ({ globalActionDispatch }) => {
      if (contract) {
        globalActionDispatch.setProperty('message', contract?.expertHints);
      }
    },
    [contract],
    $
  );

  return (
    <>
      <div className="columns is-gap-medium is-multiline">
        <div className="column is-half">
          <ShowEstimateButton onClick={showEstimateCallback} />
        </div>
        <div className="column is-half">
          <ExpertStartPauseButton
            $={$}
            kanban={kanban}
            standId={standId}
            workStatus={workStatus}
            hasLocalChanges={hasLocalChanges}
            isButtonDisabled={isStartButtonDisabled}
          />
        </div>
        <div className="column is-half">
          <ExpertInterruptButton
            $={$}
            kanban={kanban}
            standId={standId}
            hasLocalChanges={hasLocalChanges}
            isButtonDisabled={isFinishButtonDisabled}
          />
        </div>
        <div className="column is-half">
          <ExpertFinishButton
            $={$}
            kanban={kanban}
            standId={standId}
            hasLocalChanges={hasLocalChanges}
            isButtonDisabled={isFinishButtonDisabled}
            getValidationErrors={getValidationErrors}
          />
        </div>
        {contract && (
          <div className="column">
            <Button
              isFullWidth
              iconId="file-lines"
              label={t('timer.contractHints')}
              tooltip={t('timer.contractHints')}
              onClick={displayContractHintsClickHandler}
            />
          </div>
        )}
      </div>
      <ModalCardDialog
        $active={$.$expertOperatorState.$showValidationErrorsModal}
        title={t('timer.validationErrorModalTitle')}
      >
        <p>{t('timer.validationErrorModalMessage')}</p>
        {keysOf(validationErrors).map(
          (key) =>
            validationErrors[key].length > 0 && (
              <Fragment key={key}>
                <p className="is-underlined">{t(`timer.validationErrorCategoryTitles.${key}`)}</p>
                <ul>
                  {validationErrors[key].map(
                    (error): JSX.Element => (
                      <li key={error}>{error}</li>
                    )
                  )}
                </ul>
              </Fragment>
            )
        )}
      </ModalCardDialog>
    </>
  );
}
