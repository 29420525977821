import { useMemo } from 'react';
import type { CarViewCategory } from '@stimcar/libs-base';
import type { FormFieldEntry } from '@stimcar/libs-uitoolkit';
import { PackageDealTargets } from '@stimcar/libs-base';
import { useGetAllCarViewCategoryLabels } from '../../utils/carViewCategoriesTranslationHooks.js';

export function useCategoryEntries(filterMiscCategory = false): FormFieldEntry<string>[] {
  const categoryLabels = useGetAllCarViewCategoryLabels();

  return useMemo((): FormFieldEntry<string>[] => {
    return Object.keys(PackageDealTargets)
      .filter((k) => {
        if (filterMiscCategory && k === 'MISC') {
          return false;
        }
        return true;
      })
      .map((e): FormFieldEntry<string> => {
        return { id: e, label: categoryLabels[e as CarViewCategory] };
      });
  }, [filterMiscCategory, categoryLabels]);
}
