/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { JSX, MouseEvent } from 'react';
import React, { useRef } from 'react';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { FaIcon } from '@stimcar/libs-uitoolkit';
import { useDeactivateOnClickOutside } from '../../../../hooks/useDeactivateOnClickOutside.js';

export interface MenuProps<SD extends AnyStoreDef> {
  readonly label: string;
  readonly children: JSX.Element;
  readonly additionalClasses?: string;
  readonly $active: StoreStateSelector<SD, boolean>;
}

export interface FiltersMenuLinkProps<SD extends AnyStoreDef> extends MenuProps<SD> {
  readonly activateOnLabelClick?: boolean;
  readonly deactivateOnInternalClick?: boolean;
  readonly isDeactivateOnClickOutsideEnabled?: boolean;
}

export function FiltersMenuLink<SD extends AnyStoreDef>({
  label,
  children,
  activateOnLabelClick = false,
  deactivateOnInternalClick = false,
  additionalClasses = '',
  $active,
  isDeactivateOnClickOutsideEnabled,
}: FiltersMenuLinkProps<SD>): JSX.Element {
  const menuRef = useRef<HTMLDivElement | null>(null);

  const active = useGetState($active);

  useDeactivateOnClickOutside({
    domElementRef: menuRef,
    $active,
    deactivateOnInternalClick,
    isEnabled: isDeactivateOnClickOutsideEnabled,
  });

  const openCloseMenuActionCallback = useActionCallback(
    ({ actionDispatch, getState }, e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      actionDispatch.setValue(!getState());
    },
    [],
    $active
  );

  return (
    <div className={`dropdown is-active ${additionalClasses}`} ref={menuRef}>
      <div
        className="dropdown-trigger"
        role="button"
        onClick={activateOnLabelClick ? openCloseMenuActionCallback : undefined}
        style={{
          cursor: activateOnLabelClick ? 'pointer' : 'auto',
        }}
      >
        {label}
        <a
          role="button"
          className="dropdown-trigger"
          onClick={activateOnLabelClick ? undefined : openCloseMenuActionCallback}
        >
          <FaIcon id="sort-down" />
        </a>
      </div>
      {active && (
        <div
          className="dropdown-menu"
          style={{
            position: 'fixed',
            top: menuRef.current?.getBoundingClientRect().bottom,
            left: menuRef.current?.getBoundingClientRect().left,
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}
