import type { JSX } from 'react';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps, FormFieldEntry } from '@stimcar/libs-uitoolkit';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Button, ImportButton } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { Select } from '../../../lib/bulma/form/Select.js';
import { downloadFileAction, uploadFileAction } from '../../utils/downloadUploadAction.js';
import type { AdminKanbansImportState } from './typings/store.js';
import { EMPTY_ADMIN_KANBANS_IMPORT_STATE } from './typings/store.js';

export function initializeAdminKanbansImportState({
  getGlobalState,
  actionDispatch,
}: ActionContext<Store, AdminKanbansImportState>): void {
  const { contracts } = getGlobalState();
  actionDispatch.setValue({
    ...EMPTY_ADMIN_KANBANS_IMPORT_STATE,
    selectedContractCode: contracts[0].code,
  });
}

export function AdminKanbansImport({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation('kanbansImport');

  const { $adminKanbansImport } = $gs.$adminView;

  const contracts = useGetState($gs.$contracts);

  const asyncEffect = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.exec(initializeAdminKanbansImportState);
    },
    [],
    $adminKanbansImport
  );

  useEffect((): void => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
  }, [asyncEffect]);

  const contractValues = useMemo(() => {
    return contracts.map((c): FormFieldEntry<string> => {
      return {
        id: c.code,
        label: c.code,
      };
    });
  }, [contracts]);

  const selectedContractCode = useGetState($adminKanbansImport.$selectedContractCode);

  const downloadFileActionActionCallback = useActionCallback(
    async ({ actionDispatch }) =>
      actionDispatch.exec(downloadFileAction, 'kanban', selectedContractCode),
    [selectedContractCode],
    $gs
  );

  const uploadFileActionActionCallback = useActionCallback(
    async ({ actionDispatch }, files: readonly File[]) =>
      actionDispatch.exec(uploadFileAction, 'kanban', files),
    [],
    $gs
  );

  return (
    <>
      <div className="level">
        <div className="level-left">
          <div>
            <p className="title is-2">{t('title')}</p>
            <div className="subtitle is-4">
              <p>{t('subtitle')}</p>
            </div>
          </div>
        </div>
        <div className="level-right">
          <div className="columns">
            <div className="column is-narrow">
              <Select
                entries={contractValues}
                $={$adminKanbansImport.$selectedContractCode}
                isEmptyValueAllowed={false}
                sortEntries
                iconId="file-contract"
              />
            </div>
            <div className="column is-narrow">
              <Button
                label={t('downloadTitle')}
                iconId="download"
                onClick={downloadFileActionActionCallback}
              />
            </div>
            <div className="column is-narrow">
              <ImportButton
                onFilesReadyCallback={uploadFileActionActionCallback}
                label={t('uploadTitle')}
                accept="application/vnd.ms-excel"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
