import type { JSX } from 'react';
import React from 'react';

export interface TruncableProps {
  readonly className?: string;
  readonly style?: React.CSSProperties;
  readonly tooltip?: string;
  readonly children: string | number | JSX.Element;
}

export function TruncableP({ className, style, tooltip, children }: TruncableProps): JSX.Element {
  return (
    <p
      className={className}
      style={{
        width: '12.5rem',
        ...style,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      title={tooltip}
    >
      {children}
    </p>
  );
}
