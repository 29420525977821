/* eslint-disable class-methods-use-this */
import type { PollerErrorHandler } from '@stimcar/libs-base';
import type { KeyValueStorage } from '@stimcar/libs-kernel';
import type { DatabaseFactory } from '../../database/index.js';
import type { RepositoryHTTPClient } from '../../httpclient/typings/RepositoryHTTPClient.js';
import type { DatabaseIndex } from '../../repository/dao/typings/repository-dao.js';
import type { RepositoryExtender } from '../../repository/typings/repository.js';
import { newExtendedRepository } from '../../repository/RepositoryImpl.js';
import { AbstractRepositoryExtender } from '../AbstractRepositoryExtender.js';
import type {
  PackageDealDescExtender,
  PackageDealDescRepository,
} from './typings/packageDealDescExtender.js';

export const PDD_BY_PACKAGE_DEAL_DESC_CATEGORY_INDEX = 'byPackageDealCategory';
export const PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX = 'byPackageDealDatabase';

const PDD_BY_PACKAGE_DEAL_DESC_CONTRACT_INDEX = {
  name: 'byPackageDealContract',
  path: 'contract',
};

class PackageDealDescExtenderImpl
  extends AbstractRepositoryExtender
  implements RepositoryExtender<'packageDealDesc', PackageDealDescExtender>
{
  public getDataBaseIndexesToCreateOrDelete(oldVersion: number): DatabaseIndex[] {
    const indexes: DatabaseIndex[] = [];
    /* eslint-disable no-fallthrough */
    switch (oldVersion) {
      case 0:
        indexes.push({
          name: PDD_BY_PACKAGE_DEAL_DESC_CATEGORY_INDEX,
          path: 'category',
          action: 'create',
        });
        indexes.push({
          ...PDD_BY_PACKAGE_DEAL_DESC_CONTRACT_INDEX,
          action: 'create',
        });
      case 1:
        indexes.push({
          ...PDD_BY_PACKAGE_DEAL_DESC_CONTRACT_INDEX,
          action: 'delete',
        });
        indexes.push({
          name: PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX,
          path: 'database',
          action: 'create',
        });
      default:
      /* eslint-enable no-fallthrough */
    }
    return indexes;
  }
}

/**
 * Creates a package deal description manager instance.
 * @param httpClient the HTTP Client.
 */
export async function newPackageDealDescRepository(
  keyValueStorage: KeyValueStorage,
  httpClient: RepositoryHTTPClient,
  pollerErrorHandler: PollerErrorHandler,
  dbFactory: DatabaseFactory
): Promise<PackageDealDescRepository> {
  return newExtendedRepository<'packageDealDesc', PackageDealDescExtender>(
    'packageDealDesc',
    keyValueStorage,
    httpClient,
    pollerErrorHandler,
    dbFactory,
    new PackageDealDescExtenderImpl(2)
  );
}
