import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { StandardPictureCategory, StandardPictureConfEntry } from '@stimcar/libs-kernel';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { useArrayItemSelector, useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../../state/typings/store.js';
import { Checkbox } from '../../../lib/bulma/form/Checkbox.js';
import { SwitchFormField } from '../../../lib/bulma/form/SwitchFormField.js';

function shouldDisplayCategoryColumn(
  configurations: readonly StandardPictureConfEntry[],
  index: number,
  currentCategory: StandardPictureCategory
): boolean {
  return index === 0 || currentCategory !== configurations[index - 1].category;
}

export interface StandardPicturesTableRowProps {
  readonly $configuration: StoreStateSelector<Store, readonly StandardPictureConfEntry[]>;
  readonly configurationId: string;
  readonly showCategory: boolean;
}

export function StandardPicturesTableRow({
  $configuration,
  configurationId,
  showCategory,
}: StandardPicturesTableRowProps): JSX.Element {
  const [t] = useTranslation('adminStandardPictures');
  const $configurationEntry = useArrayItemSelector($configuration, configurationId);
  const configurationEntry = useGetState($configurationEntry);
  const configurations = useGetState($configuration);
  const configurationsWithSameCategory = configurations.filter(
    ({ category }) => category === configurationEntry.category
  ).length;
  return (
    <tr key={configurationEntry.id}>
      {showCategory && (
        <td rowSpan={configurationsWithSameCategory} className="has-text-weight-bold">
          {t(`categoryValues.${configurationEntry.category}`)}
        </td>
      )}
      <td>{t(`viewValues.${configurationEntry.key}`)}</td>
      <td aria-label="empty">
        <Checkbox $={$configurationEntry.$displayed} />
      </td>
      <td aria-label="empty">
        <Checkbox $={$configurationEntry.$improveImage} />
      </td>
    </tr>
  );
}

export interface StandardPicturesTableProps {
  readonly $configuration: StoreStateSelector<Store, readonly StandardPictureConfEntry[]>;
}

export function StandardPicturesTable({ $configuration }: StandardPicturesTableProps): JSX.Element {
  const [t] = useTranslation('adminStandardPictures');
  const configurations = useGetState($configuration);
  return (
    <table style={{ width: '100%' }}>
      <thead>
        <tr>
          <th>{t('tableHeaders.category')}</th>
          <th>{t('tableHeaders.key')}</th>
          <th>{t('tableHeaders.displayed')}</th>
          <th>{t('tableHeaders.improveImage')}</th>
        </tr>
      </thead>
      <tbody>
        {configurations.map((configuration, index): JSX.Element => {
          const showCategory = shouldDisplayCategoryColumn(
            configurations,
            index,
            configuration.category
          );
          return (
            <StandardPicturesTableRow
              showCategory={showCategory}
              $configuration={$configuration}
              configurationId={configuration.id}
            />
          );
        })}
      </tbody>
    </table>
  );
}

export interface StandardPicturesTableFormFieldProps {
  readonly $isEnabled: StoreStateSelector<Store, boolean>;
  readonly $configuration: StoreStateSelector<Store, readonly StandardPictureConfEntry[]>;
}

export function StandardPicturesTableFormField({
  $isEnabled,
  $configuration,
}: StandardPicturesTableFormFieldProps): JSX.Element {
  const [t] = useTranslation('adminStandardPictures');
  const isEnabled = useGetState($isEnabled);

  return (
    <div className="field">
      <SwitchFormField
        label={t('title')}
        switchId="editionMode"
        $={$isEnabled}
        horizontal
        className="is-align-items-center"
      />
      {isEnabled && <StandardPicturesTable $configuration={$configuration} />}
    </div>
  );
}
