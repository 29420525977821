import type { TFunction } from 'i18next';
import { useMemo } from 'react';
import type { FormFieldEntry } from '@stimcar/libs-uitoolkit';
import { Role } from '@stimcar/libs-base';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';

export function useRoleEntries(t: TFunction, filterRole?: string): FormFieldEntry<string>[] {
  let roleEntries = useMemo(
    (): FormFieldEntry<string>[] =>
      Object.values(Role).map((r): FormFieldEntry<string> => {
        return { id: r, label: t(`globals:roles.${r}`) };
      }),
    [t]
  );
  if (isTruthyAndNotEmpty(filterRole)) {
    roleEntries = roleEntries.filter((role) => role.id !== filterRole);
  }
  return roleEntries;
}
