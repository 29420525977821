import { useMemo } from 'react';
import type { SiteConfiguration, WorkshopStandImplantation } from '@stimcar/libs-base';
import type { GlobalStoreStateSelector } from '@stimcar/libs-uikernel';
import { transverseHelpers, userHelpers } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../state/typings/store.js';

export function getImplantation(
  postQualifiedId: string,
  configuration: SiteConfiguration,
  standId: string
): WorkshopStandImplantation | undefined {
  const postInformations =
    transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(postQualifiedId);
  let impl: WorkshopStandImplantation | undefined;

  if (isTruthy(postInformations)) {
    const stand = configuration.stands.find((s) => s.id === standId);
    if (stand) {
      impl = stand.implantations?.find((i) => i.id === postInformations.implantationId);
    }
  }
  return impl;
}

export function useGetCurrentWorkshopOperatorImplantation(
  $gs: GlobalStoreStateSelector<Store>
): WorkshopStandImplantation | undefined {
  const role = useGetState($gs.$session.$infos.optChaining().$role) ?? '';
  const standId = useGetState($gs.$session.$infos.optChaining().$standId) ?? '';
  const label = useGetState($gs.$session.$infos.optChaining().$label) ?? '';
  const configuration = useGetState($gs.$siteConfiguration);
  return useMemo(() => {
    if (userHelpers.isWorkshopPostOperator(role, label)) {
      // Workshop operators are still assigned to a stand

      return getImplantation(label, configuration, standId);
    }
    return undefined;
  }, [configuration, label, role, standId]);
}
