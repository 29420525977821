import { useMemo } from 'react';
import type { UIContract } from '@stimcar/libs-base';
import type { GlobalStoreStateSelector } from '@stimcar/libs-uikernel';
import { contractHelpers } from '@stimcar/libs-base';
import { useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../state/typings/store.js';

export function useGetContractCodes($gs: GlobalStoreStateSelector<Store>): readonly string[] {
  const contracts = useGetState($gs.$contracts);
  const contractCodes = useMemo((): readonly string[] => {
    return contracts.map((c) => c.code);
  }, [contracts]);
  return contractCodes;
}

export function useGetContractByCode(
  $gs: GlobalStoreStateSelector<Store>,
  contractCode: string | undefined
): UIContract | undefined {
  const contracts = useGetState($gs.$contracts);
  return useMemo(
    () => contractHelpers.findContractByCode(contracts, contractCode),
    [contractCode, contracts]
  );
}

/**
 * Returns the packageDeal Database for the given contract code
 * @param contractCode
 * @returns undefined if no contract code was provided
 * @throws an error if the contract cannot be found or if it reference a packageDeal database not present in the site configuration
 */
export function useGetPackageDealDatabase(
  $gs: GlobalStoreStateSelector<Store>,
  contractCode: string
): string | undefined {
  const contract = useGetContractByCode($gs, contractCode);
  const packageDealDatabases = useGetState($gs.$siteConfiguration.$packageDealDatabases);
  if (contract === undefined) {
    return undefined;
  }
  if (packageDealDatabases.includes(contract.packageDealDatabase)) {
    return contract.packageDealDatabase;
  }
  throw Error(
    `The packageDealDatabase ${contract.packageDealDatabase} defined in the contract ${contractCode} doesn't seem to exist in the site configuration`
  );
}
