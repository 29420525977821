import type { Kanban } from '@stimcar/libs-base';

export type DispatchOperationsState = {
  readonly kanbanWithOperationsDispatched: Kanban | undefined;
  readonly selectedOperationsIds: readonly string[];
};

export const DISPATCH_OPERATIONS_EMPTY_STATE: DispatchOperationsState = {
  kanbanWithOperationsDispatched: undefined,
  selectedOperationsIds: [],
};

export type InternalOperationsDispatchData = {
  readonly operationId: string;
  readonly packageDealId: string;
  readonly operationLabel: string;
  readonly packageDealLabel: string;
  readonly operationWorkload: number;
  readonly carElementLabel: string | undefined;
};

export type OperationColoredBlock = {
  readonly operationId: string;
  readonly operationLabel: string;
  readonly workload: number;
  readonly isFinished: boolean;
};

export type OperationsCell = {
  readonly categoryId: string;
  readonly blocks: readonly OperationColoredBlock[];
};

export const OPERATIONS_EMPTY_CELL: OperationsCell = {
  categoryId: '',
  blocks: [],
};

export type OperationsPerCategoryColumn = (OperationsCell | undefined)[];
