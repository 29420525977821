import type { JSX } from 'react';
import React from 'react';

export interface LabelProps {
  readonly disabled?: boolean;
  readonly tooltip?: string;
  readonly htmlFor?: string;
  readonly children: React.ReactNode;
  readonly className: string;
}

export function Label({
  disabled = false,
  tooltip,
  children,
  htmlFor,
  className,
}: LabelProps): JSX.Element {
  return (
    <label
      // @ts-ignore 2322 label is not expected to have a 'disabled' attribute, but bulma expects it
      disabled={disabled !== undefined ? disabled : false}
      title={tooltip}
      htmlFor={htmlFor}
      className={className}
    >
      {children}
    </label>
  );
}
