import type { JSX } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { StoreStateSelector, WithFormValidationWarnings } from '@stimcar/libs-uikernel';
import type { CreationToolkit, HorizontalFormFieldProps } from '@stimcar/libs-uitoolkit';
import { globalHelpers } from '@stimcar/libs-base';
import { Logger } from '@stimcar/libs-kernel';
import { useGetState } from '@stimcar/libs-uikernel';
import { InputFormField, ReactSelectFormField } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type {
  CarsExpectedAtStandMailNotificationArgsData,
  CustomersBasedMailNotificationArgsData,
  DailyProdMailNotificationArgsData,
  InvoiceMailNotificationArgsData,
  MailNotificationArgsData,
  SparePartsMailNotificationArgsData,
} from '../typings/store.js';
import { CheckboxFormField } from '../../../../lib/bulma/form/CheckboxFormField.js';
import { ReactSelectMultiFormField } from '../../../../lib/bulma/form/custom/ReactSelectMultiFormField.js';

const log: Logger = Logger.new(import.meta.url);

interface MailTypedScheduledTaskInputsProps {
  readonly $formData: StoreStateSelector<
    Store,
    WithFormValidationWarnings<MailNotificationArgsData>
  >;
  readonly horizontalFormFields?: boolean | HorizontalFormFieldProps;
}
export function MailTypedScheduledTaskInputs({
  $formData,
  horizontalFormFields,
}: MailTypedScheduledTaskInputsProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');

  const overrideDefaultSubject = useGetState($formData.$overrideDefaultSubject);
  const validationFunction: (value: string) => string | undefined = (value: string) => {
    return globalHelpers.isValidEmailAddressStructure(value.toLowerCase())
      ? undefined
      : t(`warning.malformedEmailAddress`);
  };

  const copyEmailToClipboard = useCallback(async (email: string): Promise<void> => {
    try {
      // Warning: does not work in dev, works only with secure context (HTTPS / localhost)
      await navigator.clipboard.writeText(email);
    } catch (error) {
      log.error(`Failed to copy text: `, error);
    }
  }, []);

  const creationToolkit: CreationToolkit = { validationFunction };
  return (
    <>
      <CheckboxFormField
        label={t('argumentsInputs.overrideDefaultSubject')}
        $={$formData.$overrideDefaultSubject}
        horizontal={horizontalFormFields}
        style={{ verticalAlign: 'bottom' }}
      />
      <InputFormField
        label={t('argumentsInputs.subject')}
        $={$formData.$subject}
        horizontal={horizontalFormFields}
        disabled={!overrideDefaultSubject}
      />
      <ReactSelectMultiFormField
        isClearable
        $={$formData.$to}
        hideDropdownIndicator
        creation={creationToolkit}
        label={t('argumentsInputs.to')}
        horizontal={horizontalFormFields}
        onValueLabelClick={copyEmailToClipboard}
      />
      <ReactSelectMultiFormField
        isClearable
        $={$formData.$cc}
        hideDropdownIndicator
        creation={creationToolkit}
        label={t('argumentsInputs.cc')}
        horizontal={horizontalFormFields}
        onValueLabelClick={copyEmailToClipboard}
      />
      <InputFormField
        label={t('argumentsInputs.replyTo')}
        $={$formData.$replyTo}
        horizontal={horizontalFormFields}
      />
    </>
  );
}

export interface CustomersBasedMailInputsProps {
  readonly $formData: StoreStateSelector<
    Store,
    WithFormValidationWarnings<CustomersBasedMailNotificationArgsData>
  >;
  readonly allCustomerShortnames: readonly string[];
  readonly horizontalFormFields?: boolean | HorizontalFormFieldProps;
}
export function CustomersBasedMailInputs({
  $formData,
  allCustomerShortnames,
  horizontalFormFields,
}: CustomersBasedMailInputsProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');

  const customerShortNames = useGetState($formData.$customerShortNames);
  const filteredSuggestions = useMemo((): { value: string; label: string }[] => {
    const selectAllCustomersOption = {
      value: '*',
      label: t('argumentsInputs.selectAllCustomersOption'),
    };
    return customerShortNames.includes('*')
      ? [selectAllCustomersOption]
      : [
          selectAllCustomersOption,
          ...allCustomerShortnames.map((cs) => {
            return { value: cs, label: cs };
          }),
        ];
  }, [allCustomerShortnames, customerShortNames, t]);

  return (
    <>
      <MailTypedScheduledTaskInputs
        $formData={$formData}
        horizontalFormFields={horizontalFormFields}
      />
      <ReactSelectMultiFormField
        label={t('argumentsInputs.customerShortNames')}
        $={$formData.$customerShortNames}
        suggestions={filteredSuggestions}
        horizontal={horizontalFormFields}
        isClearable
      />
      <InputFormField
        label={t('argumentsInputs.customersGroupName')}
        $={$formData.$customersGroupName}
        horizontal={horizontalFormFields}
      />
    </>
  );
}

export interface DailyProdMailInputsProps {
  readonly $formData: StoreStateSelector<
    Store,
    WithFormValidationWarnings<DailyProdMailNotificationArgsData>
  >;
  readonly allStandIds: readonly string[];
  readonly allCustomerShortnames: readonly string[];
  readonly horizontalFormFields?: boolean | HorizontalFormFieldProps;
}
export function DailyProdMailInputs({
  $formData,
  allStandIds,
  allCustomerShortnames,
  horizontalFormFields,
}: DailyProdMailInputsProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');

  return (
    <>
      <CustomersBasedMailInputs
        $formData={$formData}
        allCustomerShortnames={allCustomerShortnames}
        horizontalFormFields={horizontalFormFields}
      />
      <ReactSelectFormField
        label={t('argumentsInputs.exitStandId')}
        $={$formData.$standId}
        horizontal={horizontalFormFields}
        creation={false}
        suggestions={allStandIds}
      />
      <CheckboxFormField
        label={t('argumentsInputs.displayEstimateInfos')}
        $={$formData.$displayEstimateInfos}
        horizontal={horizontalFormFields}
        style={{ verticalAlign: 'bottom' }}
      />
      <CheckboxFormField
        label={t('argumentsInputs.displayAcceptanceInfos')}
        $={$formData.$displayAcceptanceInfos}
        horizontal={horizontalFormFields}
        style={{ verticalAlign: 'bottom' }}
      />
    </>
  );
}

export interface InvoiceMailInputsProps {
  readonly $formData: StoreStateSelector<
    Store,
    WithFormValidationWarnings<InvoiceMailNotificationArgsData>
  >;
  readonly allCustomerShortnames: readonly string[];
  readonly horizontalFormFields?: boolean | HorizontalFormFieldProps;
}
export function InvoiceMailInputs({
  $formData,
  allCustomerShortnames,
  horizontalFormFields,
}: InvoiceMailInputsProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');

  const customerShortNames = useGetState($formData.$customerShortNames);
  const filteredSuggestions = useMemo(() => {
    return customerShortNames.includes('*') ? [] : allCustomerShortnames;
  }, [allCustomerShortnames, customerShortNames]);
  return (
    <>
      <MailTypedScheduledTaskInputs
        $formData={$formData}
        horizontalFormFields={horizontalFormFields}
      />
      <ReactSelectMultiFormField
        label={t('argumentsInputs.customerShortNames')}
        $={$formData.$customerShortNames}
        suggestions={filteredSuggestions}
        horizontal={horizontalFormFields}
        isClearable
      />
    </>
  );
}

export interface SparePartsMailInputsProps {
  readonly $formData: StoreStateSelector<
    Store,
    WithFormValidationWarnings<SparePartsMailNotificationArgsData>
  >;
  readonly allCustomerShortnames: readonly string[];
  readonly horizontalFormFields?: boolean | HorizontalFormFieldProps;
}
export function SparePartsMailInputs({
  $formData,
  allCustomerShortnames,
  horizontalFormFields,
}: SparePartsMailInputsProps): JSX.Element {
  return (
    <>
      <CustomersBasedMailInputs
        $formData={$formData}
        allCustomerShortnames={allCustomerShortnames}
        horizontalFormFields={horizontalFormFields}
      />
    </>
  );
}

export interface CarsExpectedAtStandMailInputsProps {
  readonly $formData: StoreStateSelector<
    Store,
    WithFormValidationWarnings<CarsExpectedAtStandMailNotificationArgsData>
  >;
  readonly allStandIds: readonly string[];
  readonly allCustomerShortnames: readonly string[];
  readonly horizontalFormFields?: boolean | HorizontalFormFieldProps;
}
export function CarsExpectedAtStandMailInputs({
  $formData,
  allStandIds,
  allCustomerShortnames,
  horizontalFormFields,
}: CarsExpectedAtStandMailInputsProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');

  const modeSuggestions = useMemo(
    () => [
      {
        value: 'CarsList',
        label: t('argumentsInputs.CarsList'),
      },
      {
        value: 'CarsListWithStandOperations',
        label: t('argumentsInputs.CarsListWithStandOperations'),
      },
    ],
    [t]
  );

  return (
    <>
      <CustomersBasedMailInputs
        $formData={$formData}
        allCustomerShortnames={allCustomerShortnames}
        horizontalFormFields={horizontalFormFields}
      />
      <ReactSelectFormField
        label={t('argumentsInputs.currentStandId')}
        $={$formData.$standId}
        horizontal={horizontalFormFields}
        creation={false}
        suggestions={allStandIds}
      />
      <ReactSelectFormField
        label={t('argumentsInputs.mode')}
        creation
        $={$formData.$mode}
        suggestions={modeSuggestions}
        horizontal={horizontalFormFields}
        isClearable
      />
      <ReactSelectMultiFormField
        label={t('argumentsInputs.tagsFilteringPkgs')}
        $={$formData.$tagsFilteringPkgs}
        horizontal={horizontalFormFields}
        creation
      />
    </>
  );
}
