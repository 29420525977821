// Prefix that is used for routes that are publicly exposed. This allows to dinstinct it
// from URIs that are intended to ba called from within the LAN (LAN_API_ROUTE_PREFIX).
// In addition, the fact that we don't use /api anymore allows not to be exposed to
// hackers invocation intended to retrieve passwords or other private data like :
// - /api/vendor/phpunit/phpunit/src/Util/PHP/eval-stdin.php
// - /api/.env
// - /api/.git/config
// and so on
export const WAN_API_ROUTE_PREFIX = '/wan-api';

// URI prefix for LAN routes (routes that are not intended to be called from outside of
// the LAN. Such routes may not require authentication for example).
export const LAN_API_ROUTE_PREFIX = '/lan-api';
