export async function asyncTimeout(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export async function applyAsyncCallbackSequentially<T>(
  array: readonly T[],
  callback: (item: T, index: number) => Promise<void>
): Promise<void> {
  await array.reduce<Promise<void>>(async (previousPromise, item: T, index: number) => {
    await previousPromise;
    return await callback(item, index);
  }, Promise.resolve());
}

export async function asyncForEach<T>(
  array: readonly T[],
  callback: (item: T, index: number) => Promise<void>
): Promise<void> {
  await applyAsyncCallbackSequentially(array, callback);
}

export async function asyncMap<T, U>(
  array: readonly T[],
  callback: (item: T, index: number) => Promise<U>
): Promise<readonly U[]> {
  return await array.reduce<Promise<readonly U[]>>(
    async (previousPromise, item: T, index: number) => {
      return [...(await previousPromise), await callback(item, index)];
    },
    Promise.resolve([])
  );
}
