import type { Customer, SpecificFields } from '@stimcar/libs-base';
import type { BusinessObjectToFormData, WithFormValidationWarnings } from '@stimcar/libs-uikernel';
import type { FormWithValidationState } from '@stimcar/libs-uitoolkit';
import { EMPTY_CUSTOMER } from '@stimcar/libs-base';

export type CustomerFormData = BusinessObjectToFormData<
  Omit<SpecificFields<Customer>, 'individual' | 'contract'>
> & {
  readonly contractCode: string;
  readonly type: 'individual' | 'professional';
};
export type CustomerForm = WithFormValidationWarnings<CustomerFormData>;

const { individual, contract, ...EMPTY_CUSTOMER_WITHOUT_INDIVIDUAL_AND_CONTRACT } = EMPTY_CUSTOMER;
const EMPTY_CUSTOMER_FORM: CustomerForm = {
  ...EMPTY_CUSTOMER_WITHOUT_INDIVIDUAL_AND_CONTRACT,
  invoiceId: '0',
  contractCode: contract,
  type: individual ? 'individual' : 'professional',
  warnings: {},
};

export type EditCustomerDialogState = FormWithValidationState<CustomerFormData> & {
  readonly active: boolean;
  readonly initialCustomer: undefined | SpecificFields<Customer>;
  readonly mode: 'create' | 'update';
};

export const EMPTY_EDIT_CUSTOMER_DIALOG_STATE: EditCustomerDialogState = {
  active: false,
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formWarning: undefined,
  initialCustomer: undefined,
  formData: EMPTY_CUSTOMER_FORM,
  mode: 'create',
};
