import type {
  ActionToApply,
  RepositoryEntities,
  RepositoryEntity,
  RepositoryEntityStatus,
  SequencedObject,
  SpecificFields,
} from '@stimcar/libs-base';
import type { DatabaseStoreDesc } from '../../database/index.js';

export const HOLDER_LOCAL_ENTITY = 'localEntity';
export const HOLDER_SERVER_ENTITY = 'serverEntity';

export interface LocalEntityHolder<E extends RepositoryEntity> extends SequencedObject {
  readonly id: string;
  readonly status: RepositoryEntityStatus;
  readonly partitionKey: string | null;
  readonly [HOLDER_SERVER_ENTITY]?: SpecificFields<E>;
  readonly [HOLDER_LOCAL_ENTITY]?: SpecificFields<E>;
}

export type LocalDbAction<ENAME extends keyof RepositoryEntities> = ActionToApply<
  RepositoryEntities[ENAME]
> & {
  // Tells whether the server has acknowledged this action
  acknowledged: boolean;
};

export interface RepositoryStoreDesc<ENAME extends keyof RepositoryEntities>
  extends DatabaseStoreDesc {
  readonly entities: LocalEntityHolder<RepositoryEntities[ENAME]>;
  readonly localActionLog: LocalDbAction<ENAME>;
}

export interface Provider<T> {
  get(): T | null;
}

export class BasicProviderImpl<T> implements Provider<T> {
  private value: T | null = null;

  public get = (): T | null => {
    return this.value;
  };

  public set = (value: T | null): void => {
    this.value = value;
  };
}
