import { equals } from '../asserts.js';
import { asyncTimeout } from '../asyncUtils.js';
import { Logger } from '../logger.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

const BEGIN_TX = 'beginTx';
const COMMIT_TX = 'commit';
const ROLLBACK_TX = 'rollback';

export interface Tx {
  readonly [COMMIT_TX]: () => Promise<void> | void;
  readonly [ROLLBACK_TX]: (error: Error) => Promise<void> | void;
}

/**
 * Represents a component that runs under a transaction context.
 * The beginTx method is called to begin the transaction.
 * If no error is raised, commitTx is called, rollbackTx otherwise.
 */
export type TxAOP<T extends object, TX extends Tx, NIT = object> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly [P in keyof T]: T[P] extends (...args: any) => any
    ? (tx: TX, ...args: Parameters<T[P]>) => ReturnType<T[P]>
    : never;
} & NIT & {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    readonly noAOPFor?: Function[];
    readonly [BEGIN_TX]: () => Promise<TX> | TX;
  };

/**
 * Returns the parent hierarchy of a given object
 * @param object the object.
 */
function getObjectParentPrototypes(object: object): object[] {
  const result: object[] = [object];
  const prototype = Object.getPrototypeOf(object);
  if (prototype !== Object.prototype) {
    result.push(...getObjectParentPrototypes(prototype));
  }
  return result;
}

// We need to create a structure to keep the property name along
// with the function to be sure to cover anonymous functions.
interface MethodRef {
  readonly propertyKey: string;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  readonly method: Function;
}

/**
 * Transaction management method names.
 */
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
function preventDirectMethodCall(object: object, methodName: string): Function {
  const initial = Reflect.get(object, methodName);
  Reflect.set(object, methodName, (): void => {
    throw Error(`The ${methodName} method is not expected to be called programatically`);
  });
  return initial;
}

/**
 * Collects the given object's methods.
 * @param object the object's methods.
 */
export function collectObjectMethods(object: object): MethodRef[] {
  // Collect functions to instrument from the prototypes hierarchy
  let methods: MethodRef[] = [];
  const hierarchy = getObjectParentPrototypes(object);
  hierarchy.forEach((hierarchyElement): void => {
    Object.getOwnPropertyNames(hierarchyElement).forEach((key): void => {
      // TODO : écraser méthodes de même nom
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const value: any = Reflect.get(hierarchyElement, key);
      if (
        typeof value === 'function' && // Filter attributes
        value !== hierarchyElement.constructor // Filter constructors
      ) {
        // If a parent in the hierarchy contains a function with the same name,
        // it has to be replaced by the current one
        let overridesParentMethod = false;
        methods = methods.map((ref: MethodRef): MethodRef => {
          const { propertyKey } = ref;
          overridesParentMethod = propertyKey === key;
          return overridesParentMethod ? { propertyKey, method: value } : ref;
        });
        // If no parent contains a function with the same name,
        // simply add the current function to the list.
        if (!overridesParentMethod) {
          methods.push({
            propertyKey: key,
            method: value,
          });
        }
      }
    });
  });
  return methods;
}

/**
 * Instruments an objets that implements TxAOP interface.
 * The object is expected to implement beginTx method.
 * @param object the object to instrument.
 *
 * @template IT : Instrumented Type ; in other words, IT is the interface that is
 * instrumented. In the implementation, all IT's methods have an additional
 * input paramater argument : the transaction.
 * @template TX : the Transaction type ; in other words the type of object that
 * is passed as first argument in the instrumented methods.
 * @template NIT : Non Instrumented Type ; in other words NIT is the interface
 * that mustn't be instrumented and exposed "as is". NIT is optional, but once
 * declared, all its methods implementation must be referenced in the noAOPFor
 * field.
 */
export function instrumentTx<IT extends object, TX extends Tx, NIT = object>(
  object: TxAOP<IT, TX, NIT>
): IT & NIT {
  // Collect functions to instrument from the prototypes hierarchy
  const methods: MethodRef[] = collectObjectMethods(object);
  // Build functions list not to be instrumente
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  const noAOPFor: Function[] = [object[BEGIN_TX]];
  // Add user defined functions to ignore during instrumentation
  if (object.noAOPFor) {
    noAOPFor.push(...object.noAOPFor);
  }
  const wrapper = {};
  // Substitute beginTx method
  const beginTxMethod = preventDirectMethodCall(object, BEGIN_TX);
  methods.forEach(({ propertyKey, method }): void => {
    if (!noAOPFor.find(equals(method)) && method.name !== 'toString') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const override = async (...args: any): Promise<any> => {
        let tx: object | undefined;

        // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
        let rollbackTxMethod: Function | null = null;
        try {
          // Begin transaction
          tx = await Promise.resolve(Reflect.apply(beginTxMethod, object, []));

          // Substitute beginTx method
          const commitTxMethod = preventDirectMethodCall(tx as object, COMMIT_TX);
          rollbackTxMethod = preventDirectMethodCall(tx as object, ROLLBACK_TX);
          // Call real method (synchronously to check if the method is asynchronous)
          const result = Reflect.apply(method, object, [tx, ...args]);
          if (!result || !result.then || !result.catch || !result.finally) {
            throw Error(
              `Instrumented method is not asynchronous : ${method} (the method didn't return a promise)`
            );
          }
          await result;
          // Commit the transaction
          await Reflect.apply(commitTxMethod, tx, []);
          return result;
        } catch (e) {
          log.error('Unexpected error during AOP call :', e);
          try {
            // Rollback the transaction if possible
            if (tx && rollbackTxMethod) {
              await Reflect.apply(rollbackTxMethod, tx, [e]);
            }
          } catch {
            // This exception is ignored if raised, not to hide the original exception
          }
          throw e;
        }
      };
      // Bind the method on the given object
      Reflect.set(wrapper, propertyKey, override);
    } else {
      Reflect.set(wrapper, propertyKey, method.bind(object));
    }
  });
  return wrapper as IT & NIT;
}

/**
 * Ensures that one or more methods will not be called concurrently.
 * This helps to ensure that those method calls are called sequentially
 * in the end.
 * @param object the object to instrument.
 * @param functionNames the function names to instrument (optional). If missed, all
 * methods will be instrumented.
 */
export function ensureSequentialMethodCalls<T extends object>(
  object: T,
  timeout = 100,
  ...functionNames: (keyof T)[]
): T {
  // Collect functions to instrument from the prototypes hierarchy
  const methods: MethodRef[] = collectObjectMethods(object);
  let callIdSequence = 0;
  let authorizedCallId = 0;
  const wrapper = {};
  methods.forEach(({ propertyKey, method }): void => {
    if (functionNames.length === 0 || functionNames.includes(propertyKey as keyof T)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const override = async (...args: any): Promise<any> => {
        try {
          // Each call will have a unique call identifier
          const callId = callIdSequence;
          // The sequence is incremented for the next call
          callIdSequence += 1;
          // This is the replayable part of the call
          // This will be replayed until the authorizedCallId
          // becomes equal to the current call identifier.
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const replayable: () => Promise<any> = async (): Promise<any> => {
            // Wait until the authorized call is the current one
            if (authorizedCallId !== callId) {
              await asyncTimeout(timeout);
              return replayable();
            }
            // Then execute the real function
            const result = Reflect.apply(method, object, args);
            if (!result || !result.then || !result.catch || !result.finally) {
              throw Error(
                `Instrumented method is not asynchronous : ${method} (the method didn't return a promise)`
              );
            }
            return result;
          };
          // First replay call
          return await replayable();
        } finally {
          // Once all work is finished, the authorizedCallId is incremented
          // to allow the following job to be executed
          authorizedCallId += 1;
        }
      };
      Reflect.set(wrapper, propertyKey, override);
    } else {
      Reflect.set(wrapper, propertyKey, method.bind(object));
    }
  });
  return wrapper as T;
}

/**
 * Returns a wrapper of the object where all methods are bound to the object.
 * @param object the object to wrap.
 */
export function autoBind<T extends object>(object: T): T {
  // Collect functions to instrument from the prototypes hierarchy
  const methods: MethodRef[] = collectObjectMethods(object);
  const wrapper = {};
  methods.forEach(({ propertyKey, method }): void => {
    Reflect.set(wrapper, propertyKey, method.bind(object));
  });
  return wrapper as T;
}
