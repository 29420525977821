import type { JSX } from 'react';
import i18next from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Attachment, AttachmentFolder, StorageCategories } from '@stimcar/libs-base';
import type {
  ActionCallbackFromFunction,
  ActionContext,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes, URL_LIST_ELEMENTS_SEPARATOR } from '@stimcar/libs-base';
import { asyncForEach, isTruthy, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { AttachmentsGallery } from '../../../lib/components/attachments/AttachmentsGallery.js';
import { ConfirmAttachmentRemovalDialog } from '../../../lib/components/attachments/ConfirmAttachmentRemovalDialog.js';
import {
  convertToPdfAction,
  importAttachmentsAction,
  loadAttachmentsGalleryAction,
  removePdfPageAction,
  showRemoveAttachmentConfirmDialogAction,
} from '../attachmentGalleryActions.js';
import { appendRegisteredBrowserSessionToken } from '../security.js';
import { useComputeAttachmentUrl } from '../useComputeAttachmentUrl.js';
import type { ToggleOperationActionCallback } from './AdditionalStepsForOperationCompletionModal.js';
import type { UploadDocumentForCompletionModalState } from './typings/store.js';
import { EMPTY_UPLOAD_DOCUMENT_FOR_COMPLETION_MODAL_STATE } from './typings/store.js';

async function removeAttachmentAction({
  actionDispatch,
  getState,
  httpClient,
  getGlobalState,
}: ActionContext<Store, UploadDocumentForCompletionModalState>): Promise<void> {
  const { kanbanId, attachmentGallery, documentsInfo } = getState();
  const { folder, name, id } = attachmentGallery.confirmAttachmentRemovalDialog;
  await httpClient.httpGet(
    appendRegisteredBrowserSessionToken(
      CoreBackendRoutes.ATTACHMENT('kanban', kanbanId, folder, name),
      nonnull(getGlobalState().session.infos).sessionToken
    ),
    'DELETE'
  );
  actionDispatch
    .scopeProperty('attachmentGallery')
    .scopeProperty('confirmAttachmentRemovalDialog')
    .setProperty('active', false);
  actionDispatch.scopeProperty('attachmentGallery').setProperty(
    'attachments',
    attachmentGallery.attachments.filter(
      ({ id: currentId, folder: currentFolder }) => currentId !== id || currentFolder !== folder
    )
  );
  actionDispatch.scopeProperty('documentsInfo').setProperty(
    'documentsFileNames',
    documentsInfo.documentsFileNames.filter((fileName) => fileName !== name)
  );
}

async function uploadOperationCompletionAction(
  {
    actionDispatch,
    getState,
    getGlobalState,
  }: ActionContext<Store, UploadDocumentForCompletionModalState>,
  toggleOperationActionCallback: ToggleOperationActionCallback
): Promise<void> {
  const { isOnline } = getGlobalState().session;
  if (isOnline) {
    const { operation, documentsInfo } = getState();
    const { documentsFileNames, documentLabel } = documentsInfo;
    const isOperationCompleted = isTruthy(operation.completionDate);
    if (documentsFileNames.length === 0 && !isOperationCompleted) {
      actionDispatch.setProperty(
        'warning',
        i18next.t('operators:uploadDocumentForOperationCompletionModal.documentRequiredWarning', {
          documentLabel,
        })
      );
    } else {
      await actionDispatch.execCallback(
        toggleOperationActionCallback,
        operation.id,
        undefined,
        documentsFileNames
      );
      actionDispatch.setValue(EMPTY_UPLOAD_DOCUMENT_FOR_COMPLETION_MODAL_STATE);
    }
  }
}

async function loadAttachmentsAction(
  { actionDispatch, getState }: ActionContext<Store, UploadDocumentForCompletionModalState>,
  category: StorageCategories,
  objectId: string,
  folders: readonly string[],
  reloadElements?: boolean
): Promise<void> {
  await actionDispatch
    .scopeProperty('attachmentGallery')
    .exec(
      loadAttachmentsGalleryAction,
      CoreBackendRoutes.ATTACHMENT_FOLDER(
        category,
        objectId,
        folders.join(URL_LIST_ELEMENTS_SEPARATOR)
      ),
      reloadElements
    );

  const { attachments } = getState().attachmentGallery;
  const { documentsFileNames } = getState().documentsInfo;
  const attachmentsFileNames = attachments.map(({ name }) => name);
  actionDispatch
    .scopeProperty('documentsInfo')
    .scopeProperty('documentsFileNames')
    .setValue(documentsFileNames.filter((fileName) => attachmentsFileNames.includes(fileName)));

  actionDispatch.scopeProperty('attachmentGallery').setProperty('loadingStatus', undefined);
}

function onImportSuccessAction(
  { actionDispatch, getState }: ActionContext<Store, UploadDocumentForCompletionModalState>,
  importedFiles: readonly string[]
): void {
  const { documentsFileNames } = getState().documentsInfo;
  actionDispatch.setProperty('warning', undefined);
  actionDispatch
    .scopeProperty('documentsInfo')
    .setProperty('documentsFileNames', [...documentsFileNames, ...importedFiles]);
}

function onPdfUploadSuccessAction(
  { actionDispatch, getState }: ActionContext<Store, UploadDocumentForCompletionModalState>,
  filename: string
): void {
  const { documentsFileNames } = getState().documentsInfo;
  actionDispatch.setProperty('warning', undefined);
  actionDispatch
    .scopeProperty('documentsInfo')
    .setProperty('documentsFileNames', [...documentsFileNames, filename]);
}

async function closeModalAction(
  {
    actionDispatch,
    httpClient,
    getState,
    getGlobalState,
  }: ActionContext<Store, UploadDocumentForCompletionModalState>,
  toggleOperationActionCallback: ToggleOperationActionCallback
): Promise<void> {
  const { isOnline } = getGlobalState().session;
  if (isOnline) {
    const { operation, documentsInfo, kanbanId } = getState();
    if (!isTruthy(operation.completionDate)) {
      await asyncForEach(documentsInfo.documentsFileNames, async (fileName) => {
        await httpClient.httpGet(
          appendRegisteredBrowserSessionToken(
            CoreBackendRoutes.ATTACHMENT('kanban', kanbanId, documentsInfo.targetFolder, fileName),
            nonnull(getGlobalState().session.infos).sessionToken
          ),
          'DELETE'
        );
      });
    } else {
      await actionDispatch.execCallback(
        toggleOperationActionCallback,
        operation.id,
        undefined,
        documentsInfo.documentsFileNames
      );
    }
  }
  actionDispatch.setValue(EMPTY_UPLOAD_DOCUMENT_FOR_COMPLETION_MODAL_STATE);
}

interface UploadDocumentForCompletionModalDialogProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, UploadDocumentForCompletionModalState>;
  readonly toggleOperationActionCallback: ToggleOperationActionCallback;
}

export function UploadDocumentForCompletionModalDialog({
  $,
  $gs,
  toggleOperationActionCallback,
}: UploadDocumentForCompletionModalDialogProps): JSX.Element {
  const [t] = useTranslation('operators');
  const warning = useGetState($.$warning);
  const kanbanId = useGetState($.$kanbanId);
  const documentsInfo = useGetState($.$documentsInfo);
  const isOnline = useGetState($gs.$session.$isOnline);
  const computeAttachmentUrl = useComputeAttachmentUrl($gs);
  const browserLabel = useGetState($gs.$session.$infos.optChaining().$label);

  const attachmentFolder = useMemo(
    (): AttachmentFolder => ({ id: documentsInfo.targetFolder, isShareable: false }),
    [documentsInfo.targetFolder]
  );

  const attachmentFilter = useCallback(
    (attachment: Attachment) => documentsInfo.documentsFileNames.includes(attachment.name),
    [documentsInfo.documentsFileNames]
  );

  const uploadOperationCompletionActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(uploadOperationCompletionAction, toggleOperationActionCallback);
    },
    [toggleOperationActionCallback],
    $
  );

  const closeModalActionCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.exec(closeModalAction, toggleOperationActionCallback);
    },
    [toggleOperationActionCallback],
    $
  );

  const removeAttachmentActionCallback = useActionCallback(removeAttachmentAction, [], $);

  const showRemoveAttachmentConfirmDialogCallback = useActionCallback(
    showRemoveAttachmentConfirmDialogAction,
    [],
    $.$attachmentGallery
  );

  const onImportSuccessActionCallback = useActionCallback(
    async ({ actionDispatch }, importedFiles: readonly string[]) => {
      await actionDispatch.exec(onImportSuccessAction, importedFiles);
    },
    [],
    $
  );

  const onPdfUploadSuccessActionCallback = useActionCallback(
    async ({ actionDispatch }, filename: string) => {
      await actionDispatch.exec(onPdfUploadSuccessAction, filename);
    },
    [],
    $
  );

  const loadAttachmentsActionCallback = useActionCallback(
    async (
      { actionDispatch },
      category: StorageCategories,
      objectId: string,
      folders: readonly string[],
      reloadElements?: boolean
    ) => {
      await actionDispatch.exec(loadAttachmentsAction, category, objectId, folders, reloadElements);
    },
    [],
    $
  );

  const importAttachmentsActionCallback = useActionCallback(
    async (
      { actionDispatch },
      category: StorageCategories,
      objectId: string,
      folder: string,
      files: readonly File[],
      postImportCallback?: ActionCallbackFromFunction<
        Store,
        (fileNamesMap: Record<string, string>) => Promise<void>
      >
    ) => {
      const onImportSuccess = async (fileNamesMap: Record<string, string>) => {
        if (postImportCallback) {
          await postImportCallback(fileNamesMap);
        }
        await actionDispatch.execCallback(
          onImportSuccessActionCallback,
          Object.values(fileNamesMap)
        );
      };
      await actionDispatch.exec(
        importAttachmentsAction,
        category,
        objectId,
        folder,
        files,
        onImportSuccess
      );
    },
    [onImportSuccessActionCallback],
    $gs
  );

  const loadPdfAttachmentContentActionCallback = useActionCallback(
    async (
      { actionDispatch },
      category: StorageCategories,
      objectId: string,
      folders: readonly string[],
      reloadElements?: boolean
    ) => {
      await actionDispatch.exec(
        loadAttachmentsGalleryAction,
        CoreBackendRoutes.ATTACHMENT_FOLDER(
          category,
          objectId,
          folders.join(URL_LIST_ELEMENTS_SEPARATOR)
        ),
        reloadElements
      );
      actionDispatch.setProperty('loadingStatus', undefined);
    },
    [],
    $.$attachmentGallery.$pdfCreationAndUploadModal
  );

  return (
    <>
      <ModalCardDialog
        warning={warning}
        $active={$.$active}
        onCancelClicked={closeModalActionCallback}
        onOkClicked={uploadOperationCompletionActionCallback}
        title={t('uploadDocumentForOperationCompletionModal.title')}
      >
        <p>
          {t('uploadDocumentForOperationCompletionModal.rationale', {
            documentLabel: documentsInfo.documentLabel,
          })}
        </p>
        {isOnline ? (
          <AttachmentsGallery
            category="kanban"
            isOnline={isOnline}
            objectId={kanbanId}
            $window={$gs.$window}
            $={$.$attachmentGallery}
            folders={attachmentFolder}
            $imageModal={$gs.$imageModal}
            attachmentFilter={attachmentFilter}
            computeAttachmentUrl={computeAttachmentUrl}
            loadAttachmentsActionCallback={loadAttachmentsActionCallback}
            uploadToolkit={{
              importAttachmentsActionCallback,
              convertToPdfToolkit: {
                loadPdfAttachmentContentActionCallback,
                clientSpecificFolderId: nonnull(browserLabel),
                convertToPdfHttpRequestAction: convertToPdfAction,
                removePdfPageHttpRequestAction: removePdfPageAction,
                onPdfUploadSuccessCallback: onPdfUploadSuccessActionCallback,
              },
            }}
            removeToolkit={{
              onRemoveCallback: showRemoveAttachmentConfirmDialogCallback,
            }}
          />
        ) : (
          <p>{t('uploadDocumentForOperationCompletionModal.offline')}</p>
        )}
      </ModalCardDialog>
      <ConfirmAttachmentRemovalDialog
        category="kanban"
        objectId={kanbanId}
        computeAttachmentUrl={computeAttachmentUrl}
        onOkClicked={removeAttachmentActionCallback}
        $={$.$attachmentGallery.$confirmAttachmentRemovalDialog}
        okLabel={t(
          'uploadDocumentForOperationCompletionModal.confirmAttachmentRemovalDialog.okLabel'
        )}
      >
        <p>
          {t('uploadDocumentForOperationCompletionModal.confirmAttachmentRemovalDialog.message')}
        </p>
      </ConfirmAttachmentRemovalDialog>
    </>
  );
}
