import { EMPTY_KANBAN, EMPTY_SHARED_UI_CONTRACT, EMPTY_SITE } from '@stimcar/libs-base';
import { EMPTY_IMAGE_MODAL_STATE } from '@stimcar/libs-uitoolkit';
import type { ShareStoreState } from './typings/store.js';
import { KANBAN_SHARE_EMPTY_STATE } from './typings/store.js';

// Empty state
export const EMPTY_SHARE_STORE_STATE: ShareStoreState = {
  message: false,
  imageModal: EMPTY_IMAGE_MODAL_STATE,
  context: {
    object: EMPTY_KANBAN,
    site: EMPTY_SITE,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: '' as any,
    sharedUIContract: EMPTY_SHARED_UI_CONTRACT,
  },
  navBar: {
    burgerIsActive: false,
  },
  status: 'ok',
  kanbanShare: KANBAN_SHARE_EMPTY_STATE,
  window: {
    height: 0,
    width: 0,
    bulmaMode: 'Mobile',
  },
};
