import { useEffect, useState } from 'react';

export function useRemoveScreenOverflow() {
  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';
    return () => {
      document.documentElement.style.overflow = 'visible';
      document.body.style.overflow = 'visible';
    };
  }, []);
}

export function usePreventZoom(): VoidFunction | null {
  useEffect(() => {
    const viewport = document.querySelector('meta[name="viewport"]');
    let originalContent = '';

    if (viewport) {
      originalContent = viewport.getAttribute('content') ?? '';
      viewport.setAttribute(
        'content',
        'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'
      );
    }

    return () => {
      if (viewport) {
        viewport.setAttribute('content', originalContent);
      }
    };
  }, []);

  return null;
}

export function useIsPortrait(): boolean {
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia('(orientation: portrait)').matches
  );

  useEffect(() => {
    const windowOrientationListener = ({ matches }: MediaQueryListEvent) => setIsPortrait(matches);
    window
      .matchMedia('(orientation: portrait)')
      .addEventListener('change', windowOrientationListener);
    return () => {
      window
        .matchMedia('(orientation: portrait)')
        .removeEventListener('change', windowOrientationListener);
    };
  }, []);

  return isPortrait;
}
