import type { KeyValueStorage } from '@stimcar/libs-kernel';
import { Logger } from '@stimcar/libs-kernel';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

export class ComposedKeyValueStorageImpl implements KeyValueStorage {
  private durable: KeyValueStorage;

  private transient: KeyValueStorage;

  private durableKeys: readonly string[];

  constructor(
    durable: KeyValueStorage,
    transient: KeyValueStorage,
    ...durableKeys: readonly string[]
  ) {
    this.durable = durable;
    this.transient = transient;
    this.durableKeys = durableKeys;
  }

  public clear(): void {
    this.durable.clear();
    this.transient.clear();
  }

  public getItem(key: string): string | null {
    return this.getKeyValueStorage(key).getItem(key);
  }

  public getNumberItem(key: string): number | null {
    return this.getKeyValueStorage(key).getNumberItem(key);
  }

  public getObjectItem<T extends object>(key: string): T | null {
    return this.getKeyValueStorage(key).getObjectItem(key);
  }

  public removeItem(key: string): void {
    return this.getKeyValueStorage(key).removeItem(key);
  }

  public setItem(key: string, value: string | number | boolean): void {
    this.getKeyValueStorage(key).setItem(key, value);
  }

  public setObjectItem<T extends object>(key: string, value: T): void {
    this.getKeyValueStorage(key).setObjectItem(key, value);
  }

  public size(): number {
    // durable.size() cannot be used as the keyValueStorage may contain
    // non durable keys (for example if we have an existing key value
    // storage that contains old values)
    let durableSize = 0;
    for (let i = 0; i < this.durable.size(); i += 1) {
      const key = this.durable.key(i);
      if (key && this.durableKeys.includes(key)) {
        durableSize += 1;
      }
    }
    return durableSize + this.transient.size();
  }

  public key(index: number): string | null {
    const durableSize = this.durable.size();
    if (index < durableSize) {
      // durable.key(index) cannot be used as the keyValueStorage may contain
      // non durable keys (for example if we have an existing key value
      // storage that contains old values)
      let cursor = 0;
      for (let i = 0; i < this.durable.size(); i += 1) {
        const key = this.durable.key(i);
        if (key && this.durableKeys.includes(key)) {
          if (cursor === index) {
            return key;
          }
          cursor += 1;
        }
      }
      return this.durable.key(index);
    }
    return this.transient.key(index - durableSize);
  }

  private getKeyValueStorage(key: string): KeyValueStorage {
    return this.durableKeys.includes(key) ? this.durable : this.transient;
  }
}
