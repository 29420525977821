/* eslint-disable jsx-a11y/control-has-associated-label */
import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Attachment, PackageDeal, SparePart } from '@stimcar/libs-base';
import type { DeepPartial } from '@stimcar/libs-kernel';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps, ImageModalState } from '@stimcar/libs-uitoolkit';
import {
  globalHelpers,
  nonDeleted,
  packageDealHelpers,
  shortDayMonthWithHourFormatOptions,
  shortDayMonthYearHourFormatOptions,
  sparePartHelpers,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, keysOf, nonnull } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useArrayItemSelector,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import {
  ClickableIcon,
  IsTrue,
  ReactSelect,
  TableActionCell,
  ToggleNestedButton,
} from '@stimcar/libs-uitoolkit';
import type { ComputeAttachmentUrlCallback } from '../../../../../lib/components/attachments/typings/attachment.js';
import type { Store } from '../../../../state/typings/store.js';
import type { OperatorSparePartState, WorkStatus } from '../../../typings/store.js';
import { RawSwitch } from '../../../../../lib/bulma/form/Switch.js';
import { ColumnedCarviewAndAttachmentThumbnailsDisplayer } from '../../../../../lib/components/photosdisplayer/ColumnedCarviewAndAttachmentThumbnailsDisplayer.js';
import { EMPTY_MOVE_STAND_SPARE_PART_MODAL_STATE } from '../../../../details/typings/store.js';
import { MoveStandSparePartModal } from '../../../../globalModals/MoveStandSparePartModal.js';
import { useComputeAttachmentUrl } from '../../../../utils/useComputeAttachmentUrl.js';
import { EMPTY_EDIT_SPARE_PART_COMMENT_MODAL_STATE } from '../../../typings/store.js';
import { DisplaySparePartComment } from './DisplaySparePartComment.js';
import {
  EditSparePartCommentModal,
  openEditSparePartCommentModalAction,
} from './EditSparePartCommentModal.js';
import {
  openEstimatedDateOfReceptionModalSparePartAction,
  SparePartEstimatedDateOfReceptionModal,
} from './SparePartEstimatedDateOfReceptionModal.js';
import { SparePartEstimatedDateOfReceptionText } from './SparePartEstimatedDateOfReceptionText.js';
import { useSparePartEstimatedDateOfReceptionColorClass } from './useSparePartEstimatedDateOfReceptionColorClass.js';
import { useSparePartProviderIdOptions } from './useSparePartProviderIdOptions.js';

function openChangeSparePartStandModalAction(
  { actionDispatch }: ActionContext<Store, OperatorSparePartState>,
  sparePart: SparePart
): void {
  actionDispatch.applyPayload({
    moveStandSparePartModalState: {
      ...EMPTY_MOVE_STAND_SPARE_PART_MODAL_STATE,
      active: true,
      sparePartId: sparePart.id,
      sparePartLabel: sparePart.label,
      currentStandId: sparePart.standId,
    },
  });
}

interface Props {
  readonly kanbanId: string;
  readonly packageDealId: string;
  readonly sparePart: SparePart;
  readonly mode: 'order' | 'receive';
  readonly $: StoreStateSelector<Store, OperatorSparePartState>;
  readonly isDisabled: boolean;
  readonly dateFormat?: Intl.DateTimeFormatOptions;
}

function SparePartOrderOrReceiveDateSwitch({
  kanbanId,
  packageDealId,
  sparePart,
  mode,
  isDisabled,
  $,
  dateFormat = shortDayMonthYearHourFormatOptions,
}: Props): JSX.Element {
  const [t] = useTranslation('operators');

  const unknownModeError = `Unknown mode ${mode} for order or receive spare part switch`;

  const editValueAction = useActionCallback(
    async ({ kanbanRepository }, e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      const isChecked = e.target.checked;
      let sparePartPayload: DeepPartial<SparePart>;
      switch (mode) {
        case 'order':
          sparePartPayload = { id: sparePart.id, dateOfOrder: isChecked ? Date.now() : null };
          break;
        case 'receive':
          sparePartPayload = { id: sparePart.id, dateOfReception: isChecked ? Date.now() : null };
          break;
        default:
          throw Error(unknownModeError);
      }
      await kanbanRepository.updateEntityFromPayload({
        entityId: kanbanId,
        payload: {
          packageDeals: [{ id: packageDealId, spareParts: [sparePartPayload] }],
        },
      });
    },
    [kanbanId, mode, packageDealId, sparePart, unknownModeError],
    $
  );

  const isChecked = useMemo(() => {
    switch (mode) {
      case 'order':
        return sparePart.dateOfOrder !== null;
      case 'receive':
        return sparePart.dateOfReception !== null;
      default:
        throw Error(unknownModeError);
    }
  }, [mode, sparePart.dateOfOrder, sparePart.dateOfReception, unknownModeError]);

  const switchLabel = useMemo(() => {
    if (mode === 'order') {
      if (sparePart.managementType === 'fullyManagedByCustomer') {
        return t('generalView.spareParts.managedByCustomer');
      }
      return isTruthy(sparePart.dateOfOrder)
        ? globalHelpers.convertTimestampToDateString(sparePart.dateOfOrder, dateFormat)
        : undefined;
    }
    return undefined;
  }, [mode, sparePart.managementType, sparePart.dateOfOrder, dateFormat, t]);

  return (
    <RawSwitch
      switchId={`${sparePart.id}_${mode}`}
      text={switchLabel}
      disabled={isDisabled}
      isChecked={isChecked}
      toggleAction={editValueAction}
    />
  );
}

interface OrderAllSwitchProps {
  readonly kanbanId: string;
  readonly sparePartIdToPackageDealIdMap: Record<string, string>;
  readonly spareParts: readonly SparePart[];
  readonly $: StoreStateSelector<Store, OperatorSparePartState>;
  readonly isDisabled: boolean;
}
function OrderAllSwitch({
  kanbanId,
  sparePartIdToPackageDealIdMap,
  spareParts,
  isDisabled,
  $,
}: OrderAllSwitchProps): JSX.Element {
  const eligibleSpareParts = spareParts.filter(
    (sp) => sp.managementType !== 'fullyManagedByCustomer' && !isTruthy(sp.dateOfReception)
  );
  const editValueAction = useActionCallback(
    async ({ kanbanRepository }, e: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;
      const sparePartsPayloadPerPackageDealId: Record<string, DeepPartial<SparePart>[]> = {};
      eligibleSpareParts.forEach((sp) => {
        const packagedealId = sparePartIdToPackageDealIdMap[sp.id];
        if (!isTruthy(sparePartsPayloadPerPackageDealId[packagedealId])) {
          sparePartsPayloadPerPackageDealId[packagedealId] = [];
        }
        const newDateOfOrder = isTruthy(sp.dateOfOrder) ? sp.dateOfOrder : Date.now();
        sparePartsPayloadPerPackageDealId[packagedealId].push({
          id: sp.id,
          dateOfOrder: isChecked ? newDateOfOrder : null,
        });
      });
      const packageDealsPayload: DeepPartial<PackageDeal>[] = [];
      keysOf(sparePartsPayloadPerPackageDealId).forEach((pdId) => {
        packageDealsPayload.push({ id: pdId, spareParts: sparePartsPayloadPerPackageDealId[pdId] });
      });
      await kanbanRepository.updateEntityFromPayload({
        entityId: kanbanId,
        payload: {
          packageDeals: packageDealsPayload,
        },
      });
    },
    [eligibleSpareParts, kanbanId, sparePartIdToPackageDealIdMap],
    $
  );

  const isChecked = useMemo(
    () => eligibleSpareParts.find((sp) => !isTruthy(sp.dateOfOrder)) === undefined,
    [eligibleSpareParts]
  );

  return (
    <RawSwitch
      switchId={`${kanbanId}_spareParts_orderAll`}
      disabled={isDisabled || eligibleSpareParts.length === 0}
      isChecked={isChecked}
      toggleAction={editValueAction}
    />
  );
}

interface SparePartsTableProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, OperatorSparePartState>;
  readonly kanbanId: string;
  readonly workStatus: WorkStatus;
  readonly kanbanPackageDeals: readonly PackageDeal[];
}

export function OperatorSparePartManagementTable({
  $,
  $gs,
  kanbanId,
  workStatus,
  kanbanPackageDeals,
}: SparePartsTableProps): JSX.Element {
  const [t] = useTranslation('operators');

  const { $moveStandSparePartModalState, $editSparePartsCommentModal } = $;

  const submitMoveStandSparePartActionCallback = useActionCallback(
    async function submitMoveStandSparePartAction({ kanbanRepository, getState, actionDispatch }) {
      const { sparePartIdToPackageDealIdMap, moveStandSparePartModalState } = getState();
      const sparePartId = nonnull(moveStandSparePartModalState.sparePartId);
      await kanbanRepository.updateEntityFromPayload({
        entityId: kanbanId,
        payload: {
          packageDeals: [
            {
              id: sparePartIdToPackageDealIdMap[sparePartId],
              spareParts: [
                { id: sparePartId, standId: moveStandSparePartModalState.formData.standId },
              ],
            },
          ],
        },
      });
      actionDispatch.setProperty(
        'moveStandSparePartModalState',
        EMPTY_MOVE_STAND_SPARE_PART_MODAL_STATE
      );
    },
    [kanbanId],
    $
  );

  const submitEditCommentActionCallback = useActionCallback(
    async function submitEditCommentAction({ kanbanRepository, getState, actionDispatch }) {
      const { sparePartIdToPackageDealIdMap, editSparePartsCommentModal } = getState();
      const sparePartId = nonnull(editSparePartsCommentModal.sparePartId);
      const { comment, commentForWorkshop } = editSparePartsCommentModal.formData;
      await kanbanRepository.updateEntityFromPayload({
        entityId: kanbanId,
        payload: {
          packageDeals: [
            {
              id: sparePartIdToPackageDealIdMap[sparePartId],
              spareParts: [{ id: sparePartId, comment, commentForWorkshop }],
            },
          ],
        },
      });
      actionDispatch.setProperty(
        'editSparePartsCommentModal',
        EMPTY_EDIT_SPARE_PART_COMMENT_MODAL_STATE
      );
    },
    [kanbanId],
    $
  );

  const spareParts = useGetState($.$spareParts);

  const computeAttachmentUrl = useComputeAttachmentUrl($gs);

  const sparePartIdToPackageDealIdMap = useGetState($.$sparePartIdToPackageDealIdMap);

  return (
    <>
      {spareParts.length > 0 ? (
        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th style={{ width: '15%' }}>{t('generalView.spareParts.table.element')}</th>
              <th style={{ width: '15%' }}>{t('generalView.spareParts.table.label')}</th>
              <th style={{ width: '7%' }}>{t('generalView.spareParts.table.provider')}</th>
              <th
                title={t('generalView.spareParts.table.unitPriceTooltip')}
                style={{
                  width: '3%',
                }}
              >
                {t('generalView.spareParts.table.unitPrice')}
              </th>
              <th style={{ width: '3%' }}>{t('generalView.spareParts.table.quantity')}</th>
              <th style={{ width: '3%' }}>{t('generalView.spareParts.table.price')}</th>
              <th style={{ width: '20%' }}>{t('generalView.spareParts.table.comments')}</th>
              <th style={{ width: '15%' }}>
                <span className="p-l-sm">{t('generalView.spareParts.table.orderAction')}</span>
                <span style={{ float: 'right' }}>
                  <OrderAllSwitch
                    kanbanId={kanbanId}
                    sparePartIdToPackageDealIdMap={sparePartIdToPackageDealIdMap}
                    spareParts={spareParts}
                    $={$}
                    isDisabled={workStatus !== 'started'}
                  />
                </span>
              </th>
              <th style={{ width: '8%' }}>{t('generalView.spareParts.table.receptionDelay')}</th>
              <th style={{ width: '5%' }}>{t('generalView.spareParts.table.receivedAction')}</th>
              <th style={{ width: '1%' }}>
                <span title={t('generalView.spareParts.table.changeStandAction')} />
              </th>
            </tr>
          </thead>
          <tbody>
            {spareParts.map((sp) => (
              <SparePartRow
                key={sp.id}
                disableInput={workStatus !== 'started'}
                sparePartId={sp.id}
                $={$}
                $gs={$gs}
                kanbanId={kanbanId}
                sparePartIdToPackageDealIdMap={sparePartIdToPackageDealIdMap}
                kanbanPackageDeals={kanbanPackageDeals}
                $expandedSectionIds={$.$expandedSectionIds}
                computeAttachmentUrl={computeAttachmentUrl}
                $imageModal={$gs.$imageModal}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <p className="has-text-centered">{t('sparePartsReference.noSparePartsToDisplay')}</p>
      )}
      <MoveStandSparePartModal
        $={$moveStandSparePartModalState}
        $gs={$gs}
        submitValidDataAction={submitMoveStandSparePartActionCallback}
      />
      <EditSparePartCommentModal
        $={$editSparePartsCommentModal}
        submitValidDataAction={submitEditCommentActionCallback}
      />
    </>
  );
}

interface SparePartRowProps extends AppProps<Store> {
  readonly sparePartId: string;
  readonly $: StoreStateSelector<Store, OperatorSparePartState>;
  readonly sparePartIdToPackageDealIdMap: Record<string, string>;
  readonly disableInput: boolean;
  readonly kanbanId: string;
  readonly kanbanPackageDeals: readonly PackageDeal[];
  readonly $expandedSectionIds: StoreStateSelector<Store, readonly string[]>;
  readonly computeAttachmentUrl: ComputeAttachmentUrlCallback;
  readonly $imageModal: StoreStateSelector<Store, ImageModalState>;
}

function SparePartRow({
  sparePartId,
  $,
  $gs,
  disableInput,
  sparePartIdToPackageDealIdMap,
  kanbanId,
  kanbanPackageDeals,
  $expandedSectionIds,
  computeAttachmentUrl,
  $imageModal,
}: SparePartRowProps): JSX.Element {
  const [t] = useTranslation('operators');

  const $sparePart = useArrayItemSelector($.$spareParts, sparePartId);
  const sparePart = useGetState($sparePart);

  const openChangeSparePartStandModalActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openChangeSparePartStandModalAction, sparePart);
    },
    [sparePart],
    $
  );

  const openEditSparePartCommentModalActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openEditSparePartCommentModalAction, sparePart);
    },
    [sparePart],
    $
  );

  const containingPkgDeal = useMemo(
    () =>
      nonnull(
        sparePartHelpers.getPackageDealThatHasGivenSparePart(kanbanPackageDeals, sparePartId)
      ),
    [kanbanPackageDeals, sparePartId]
  );

  const openEstimatedDateOfReceptionModalSparePartActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(
        openEstimatedDateOfReceptionModalSparePartAction,
        sparePart,
        containingPkgDeal.id
      );
    },
    [containingPkgDeal.id, sparePart],
    $.$estimatedDateOfReceptionModal
  );

  const updateProviderActionCallback = useActionCallback(
    async ({ kanbanRepository, getState }) => {
      await kanbanRepository.updateEntityFromPayload({
        entityId: kanbanId,
        payload: {
          packageDeals: [
            {
              id: sparePartIdToPackageDealIdMap[sparePartId],
              spareParts: [
                {
                  id: sparePartId,
                  provider: getState(),
                },
              ],
            },
          ],
        },
      });
    },
    [kanbanId, sparePartId, sparePartIdToPackageDealIdMap],
    $sparePart.$provider
  );

  const $providerWithChangeTrigger = useSelectorWithChangeTrigger(
    $sparePart.$provider,
    updateProviderActionCallback
  );

  const attachments = useMemo(
    (): readonly Attachment[] => containingPkgDeal?.attachments?.filter(nonDeleted) ?? [],
    [containingPkgDeal?.attachments]
  );

  const [computedSparePartLabel, carElementLabel] = useMemo(() => {
    if (containingPkgDeal !== undefined) {
      return [
        packageDealHelpers.getSparePartDisplayedLabel(sparePart, containingPkgDeal),
        containingPkgDeal.carElement?.label ?? '',
      ];
    }
    return [sparePart.label, ''];
  }, [containingPkgDeal, sparePart]);

  const estimatedDateOfReceptionColorClass = useSparePartEstimatedDateOfReceptionColorClass(
    sparePart.estimatedDateOfReception
  );

  const windowWidth = useGetState($gs.$window.$width);
  const dateTimeFormat = useMemo(() => {
    return windowWidth > 1525
      ? shortDayMonthYearHourFormatOptions
      : shortDayMonthWithHourFormatOptions;
  }, [windowWidth]);

  const sparePartsProviderIds = useSparePartProviderIdOptions($gs);
  const expandedSectionIds = useGetState($expandedSectionIds);

  return (
    <>
      <tr>
        <td>
          <ToggleNestedButton
            $expandedIds={$expandedSectionIds}
            id={sparePart.id}
            hasChildren={attachments.length > 0}
            label={carElementLabel}
          />
        </td>
        <td>{computedSparePartLabel}</td>
        <td>
          <ReactSelect
            suggestions={sparePartsProviderIds}
            $={$providerWithChangeTrigger as StoreStateSelector<Store, string>}
            isClearable
          />
        </td>
        <td className="has-text-right">{sparePart.providerUnitPrice}</td>
        <td className="has-text-right">{sparePart.quantity}</td>
        <td className="has-text-right">{sparePart.price}</td>
        <td>
          <div className="columns">
            <div className="column">
              {isTruthyAndNotEmpty(sparePart.comment) && (
                <DisplaySparePartComment
                  prefix={t('generalView.spareParts.internalComment')}
                  value={sparePart.comment}
                />
              )}
              {isTruthyAndNotEmpty(sparePart.commentForWorkshop) && (
                <DisplaySparePartComment
                  prefix={t('generalView.spareParts.commentForWorkshop')}
                  value={sparePart.commentForWorkshop}
                />
              )}
            </div>
            <div className="column is-narrow">
              <ClickableIcon
                id="ellipsis-h"
                clickHandler={openEditSparePartCommentModalActionCallback}
                isDisabled={disableInput}
              />
            </div>
          </div>
        </td>
        <td>
          <SparePartOrderOrReceiveDateSwitch
            isDisabled={
              disableInput ||
              sparePart.managementType === 'fullyManagedByCustomer' ||
              isTruthy(sparePart.dateOfReception)
            }
            sparePart={sparePart}
            kanbanId={kanbanId}
            mode="order"
            packageDealId={sparePartIdToPackageDealIdMap[sparePart.id]}
            $={$}
            dateFormat={dateTimeFormat}
          />
        </td>
        <td
          className={!isTruthy(sparePart.dateOfReception) ? estimatedDateOfReceptionColorClass : ''}
        >
          {!isTruthy(sparePart.dateOfReception) && (
            <div className="columns m-t-xxs">
              <div className="column has-text-centered">
                <SparePartEstimatedDateOfReceptionText
                  estimatedDateOfReception={sparePart.estimatedDateOfReception}
                />
              </div>
              <div className="column is-narrow">
                <ClickableIcon
                  id="ellipsis-h"
                  isDisabled={isTruthy(sparePart.dateOfReception)}
                  clickHandler={openEstimatedDateOfReceptionModalSparePartActionCallback}
                />
              </div>
            </div>
          )}
        </td>
        <td>
          <SparePartOrderOrReceiveDateSwitch
            isDisabled={
              disableInput ||
              (!isTruthy(sparePart.dateOfReception) &&
                sparePart.managementType !== 'fullyManagedByCustomer' &&
                !isTruthy(sparePart.dateOfOrder))
            }
            sparePart={sparePart}
            kanbanId={kanbanId}
            mode="receive"
            packageDealId={sparePartIdToPackageDealIdMap[sparePart.id]}
            $={$}
            dateFormat={dateTimeFormat}
          />
        </td>
        <TableActionCell
          onClick={openChangeSparePartStandModalActionCallback}
          iconId="balance-scale"
          tooltip={t('generalView.spareParts.table.changeStandAction')}
        />
      </tr>
      {expandedSectionIds.includes(sparePart.id) && (
        <tr>
          <td colSpan={10}>
            <ColumnedCarviewAndAttachmentThumbnailsDisplayer
              category={containingPkgDeal.carElement?.category ?? 'MISC'}
              selectedShapes={containingPkgDeal.carElement?.shapes ?? []}
              attachments={attachments}
              computeAttachmentUrl={computeAttachmentUrl}
              $imageModal={$imageModal}
              kanbanId={kanbanId}
              photoHeight={125}
            />
          </td>
        </tr>
      )}
      <IsTrue $={$.$estimatedDateOfReceptionModal.$active}>
        <SparePartEstimatedDateOfReceptionModal $={$} kanbanId={kanbanId} />
      </IsTrue>
    </>
  );
}
