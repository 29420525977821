import type { JSX } from 'react';
import React from 'react';

interface Props {
  readonly clickHandler: () => Promise<void> | void;
  readonly elementClassName?: string;
  readonly additionalStyle?: React.CSSProperties;
  readonly isDisabled?: boolean;
  readonly isNotClickable?: boolean;
  readonly children: JSX.Element[] | JSX.Element;
  readonly centerContent?: boolean;
}

export function ClickableElement({
  children,
  clickHandler,
  isNotClickable = false,
  elementClassName,
  additionalStyle = {},
  isDisabled = false,
  centerContent = false,
}: Props): JSX.Element {
  const doHandleInteraction = async (): Promise<void> => {
    if (!isNotClickable && !isDisabled) {
      await clickHandler();
    }
  };

  const onKeyDownHandler = async (event: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (event.keyCode === 13) {
      await doHandleInteraction();
    }
  };

  const style: React.CSSProperties = isNotClickable
    ? {}
    : {
        ...additionalStyle,
        cursor: `${isDisabled ? 'not-allowed' : 'pointer'}`,
        outline: 'none',
        opacity: isDisabled ? 0.5 : undefined,
      };
  if (centerContent) {
    style.display = 'flex';
    style.justifyContent = 'center';
    style.flexGrow = 1;
  }

  return (
    <div
      className={elementClassName ?? ''}
      role="button"
      tabIndex={0}
      style={style}
      onKeyDown={onKeyDownHandler}
      onClick={doHandleInteraction}
    >
      {children}
    </div>
  );
}
