import type { JSX } from 'react';
import React from 'react';
import type {
  ActionDispatch,
  ArrayItemStateType,
  State,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { FaIcon } from '../icons/FaIcon.js';
import type { AnyTableStoreDef } from './typings/store.js';

export interface TableActionCellProps {
  readonly onClick: () => void | Promise<void>;
  readonly iconId: string;
  readonly tooltip: string;
  readonly label?: string;
  readonly disabled?: boolean;
  readonly additionalTdClassName?: string;
  readonly additionalButtonClassName?: string;
}

export function TableActionCell({
  iconId,
  tooltip,
  onClick,
  label,
  disabled,
  additionalTdClassName = '',
  additionalButtonClassName = '',
}: TableActionCellProps): JSX.Element {
  return (
    <td
      className={`has-text-centered ${additionalTdClassName}`}
      style={{ paddingRight: 0, paddingLeft: 0 }}
    >
      <button
        type="button"
        className={`button is-small is-transparent ${additionalButtonClassName}`}
        title="update"
        onClick={onClick}
        disabled={disabled}
      >
        <FaIcon id={iconId} tooltip={tooltip} label={label} />
      </button>
    </td>
  );
}

interface BoundedTableActionCellProps<
  SD extends AnyTableStoreDef,
  S extends State,
  O extends ArrayItemStateType,
> extends Omit<TableActionCellProps, 'onClick'> {
  readonly action: (dispatch: ActionDispatch<SD, S>, item: O) => void | Promise<void>;
  readonly item: O;
  readonly $: StoreStateSelector<SD, S>;
}

export function BoundTableActionCell<
  SD extends AnyTableStoreDef,
  SC extends State,
  O extends ArrayItemStateType,
>({
  item,
  $,
  action,
  iconId,
  tooltip,
  label,
  disabled,
  additionalTdClassName,
  additionalButtonClassName,
}: BoundedTableActionCellProps<SD, SC, O>): JSX.Element {
  const onClick = useActionCallback(
    async ({ actionDispatch }): Promise<void> => await action(actionDispatch, item),
    [action, item],
    $
  );
  return (
    <TableActionCell
      iconId={iconId}
      tooltip={tooltip}
      label={label}
      disabled={disabled}
      onClick={onClick}
      additionalTdClassName={additionalTdClassName}
      additionalButtonClassName={additionalButtonClassName}
    />
  );
}
