// eslint-disable-next-line max-classes-per-file
import { deepFreeze } from '@stimcar/libs-base';
import { isTruthy, keysOf } from '@stimcar/libs-kernel';
import type {
  Database,
  DatabaseFactory,
  DatabaseStoreDesc,
  DatabaseStoresConfigurator,
  DatabaseTx,
  DatabaseUpgrader,
} from './typings/database.js';

type InMemoryDatabaseState<DSD extends DatabaseStoreDesc> = {
  readonly [SN in keyof DSD]: readonly DSD[SN][];
};

type InMemoryDatabaseIndexes<DSD extends DatabaseStoreDesc> = {
  readonly [SN in keyof DSD]: { [name: string]: string };
};

type InMemoryDatabaseIdKeys<DSD extends DatabaseStoreDesc> = {
  readonly [SN in keyof DSD]: string;
};

class InMemoryTx<DSD extends DatabaseStoreDesc> implements DatabaseTx<DSD> {
  private state: InMemoryDatabaseState<DSD>;

  private indexes: InMemoryDatabaseIndexes<DSD>;

  private idKeys: InMemoryDatabaseIdKeys<DSD>;

  private active = true;

  public constructor(
    state: InMemoryDatabaseState<DSD>,
    indexes: InMemoryDatabaseIndexes<DSD>,
    idKeys: InMemoryDatabaseIdKeys<DSD>
  ) {
    this.state = state;
    this.indexes = indexes;
    this.idKeys = idKeys;
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/require-await
  public commit = async (): Promise<void> => {
    throw new Error('This method is not expected to be called explicitly');
  };

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/require-await
  public rollback = async (): Promise<void> => {
    throw new Error('This method is not expected to be called explicitly');
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  public async getFromIndex<SN extends keyof DSD>(
    storeName: SN,
    indexName: string,
    indexValue: string | number | boolean
  ): Promise<DSD[SN][]> {
    this.checkActive();
    const storeIndexHandlers = this.indexes[storeName];
    if (storeIndexHandlers) {
      const fieldName = storeIndexHandlers[indexName];
      if (fieldName) {
        // element variable seems no to be used, bu it is used in the eval call
        return (
          this.state[storeName]
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter((element): boolean => {
              try {
                // eslint-disable-next-line no-eval
                return eval(`element.${fieldName}`) === indexValue;
              } catch {
                return false;
              }
            })
            .map((k): DSD[SN] => Object.freeze(k) as DSD[SN])
        );
      }
    }
    throw new Error(`Unknown index '${indexName}' for store '${String(storeName)}'`);
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public async get<SN extends keyof DSD>(storeName: SN, id: string): Promise<DSD[SN] | undefined> {
    this.checkActive();
    const idKey = this.idKeys[storeName];
    return this.state[storeName].find((element): boolean => Reflect.get(element, idKey) === id);
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public async getN<SN extends keyof DSD>(storeName: SN, ...ids: string[]): Promise<DSD[SN][]> {
    this.checkActive();
    return this.state[storeName].filter((element): boolean => {
      const idKey = this.idKeys[storeName];
      return ids.includes(Reflect.get(element, idKey) as string);
    });
  }

  public async put<SN extends keyof DSD>(storeName: SN, obj: DSD[SN]): Promise<void> {
    this.checkActive();
    const idKey = this.idKeys[storeName];
    const objId = Reflect.get(obj, idKey) as string;
    const existing = await this.get(storeName, objId);
    const store = this.state[storeName];
    const frozen = deepFreeze(obj);
    if (existing) {
      this.state = {
        ...this.state,
        [storeName]: store.map((element): DSD[SN] => {
          return Reflect.get(element, idKey) === objId ? frozen : element;
        }),
      };
    } else {
      this.state = {
        ...this.state,
        [storeName]: [...store, frozen],
      };
    }
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public async getAll<SN extends keyof DSD>(storeName: SN): Promise<DSD[SN][]> {
    this.checkActive();
    const store = this.state[storeName];
    const result: DSD[SN][] = [];
    if (store) {
      store.forEach((element): void => {
        result.push(element);
      });
    }
    return result;
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public async delete<SN extends keyof DSD>(storeName: SN, id: string): Promise<void> {
    this.checkActive();
    const store = this.state[storeName];
    this.state = {
      ...this.state,
      [storeName]: store.filter((element): boolean => {
        const idKey = this.idKeys[storeName];
        return Reflect.get(element, idKey) !== id;
      }),
    };
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public async deleteAll<SN extends keyof DSD>(storeName: SN): Promise<void> {
    this.checkActive();
    this.state = {
      ...this.state,
      [storeName]: [],
    };
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public async count<SN extends keyof DSD>(storeName: SN): Promise<number> {
    this.checkActive();
    return this.state[storeName].length;
  }

  public async exists<SN extends keyof DSD>(storeName: SN, id: string): Promise<boolean> {
    this.checkActive();
    return isTruthy(await this.get(storeName, id));
  }

  public getState(): InMemoryDatabaseState<DSD> {
    this.checkActive();
    return this.state;
  }

  public checkActive(): void {
    if (!this.active) {
      throw new Error('Transaction is not active');
    }
  }

  public close(): void {
    this.active = false;
  }
}

class InMemoryDatabaseImpl<DSD extends DatabaseStoreDesc> implements Database<DSD> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private state: InMemoryDatabaseState<DSD> = {} as any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private indexes: InMemoryDatabaseIndexes<DSD> = {} as any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private idKeys: InMemoryDatabaseIdKeys<DSD> = {} as any;

  public constructor(
    state: InMemoryDatabaseState<DSD>,
    indexes: InMemoryDatabaseIndexes<DSD>,
    idKeys: InMemoryDatabaseIdKeys<DSD>
  ) {
    this.state = state;
    this.indexes = indexes;
    this.idKeys = idKeys;
  }

  public beginTx(): DatabaseTx<DSD> {
    const tx = new InMemoryTx<DSD>(this.state, this.indexes, this.idKeys);
    // eslint-disable-next-line @typescript-eslint/require-await
    tx.commit = async (): Promise<void> => {
      tx.checkActive();
      this.state = tx.getState();
      tx.close();
    };
    // eslint-disable-next-line @typescript-eslint/require-await
    tx.rollback = async (): Promise<void> => {
      tx.checkActive();
      tx.close();
    };
    return tx;
  }
}

/* eslint-disable class-methods-use-this */
export class InMemoryDatabaseFactoryImpl implements DatabaseFactory {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private databases: Record<string, Database<any>> = {};

  // eslint-disable-next-line @typescript-eslint/require-await
  public async create<DSD extends DatabaseStoreDesc>(
    databaseName: string,
    version: number,
    upgrade: DatabaseUpgrader<DSD>
  ): Promise<Database<DSD>> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const state: InMemoryDatabaseState<DSD> = {} as any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const indexes: InMemoryDatabaseIndexes<DSD> = {} as any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const idKeys: InMemoryDatabaseIdKeys<DSD> = {} as any;
    const configurator: DatabaseStoresConfigurator<DSD> = {
      createObjectStore: (storeName: keyof DSD, idKey: string): void => {
        Reflect.set(state, storeName, []);
        Reflect.set(indexes, storeName, {});
        Reflect.set(idKeys, storeName, idKey);
      },
      createIndex: (
        storeName: keyof DSD,
        indexName: string,
        keyPath: string
        // eslint-disable-next-line @typescript-eslint/require-await
      ): void => {
        Reflect.set(indexes[storeName], indexName, keyPath);
      },
      deleteIndex: (storeName: keyof DSD, indexName: string): void => {
        Reflect.deleteProperty(indexes[storeName], indexName);
      },
    };
    upgrade(configurator, 0, version);
    const db = new InMemoryDatabaseImpl(state, indexes, idKeys);
    this.databases[databaseName] = db;
    return db;
  }

  public removeAllData = async (): Promise<void> => {
    // Nothing to do, in memory database creates a new database
    // each time create method is called
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getDatabase(databaseName: string): Database<any> | undefined {
    return this.databases[databaseName];
  }

  public getDatabaseNames(): readonly string[] {
    return keysOf(this.databases);
  }
}
