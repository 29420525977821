/* eslint-disable jsx-a11y/control-has-associated-label */
import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  DisplayableSparePart,
  DisplayConfiguration,
  Kanban,
  ObjectComparator,
  PackageDeal,
} from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  enumerate,
  globalHelpers,
  i18nHelpers,
  kanbanHelpers,
  nonDeleted,
  packageDealHelpers,
  shortDayMonthFullYearDateFormatOptions,
  sortingHelpers,
  workflowProgressHelpers,
} from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  FaIcon,
  ScrollableTableComponent,
  ToggleNestedButton,
  TruncableTableTd,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../app/state/typings/store.js';
import type { KanbanItem, SelectKanbanWizardState } from '../typings/store.js';
import { ComputedKanbanIcon } from '../../../../app/display/KanbanBoardDisplay.js';
import { PackageDealDecorators } from '../../../../app/utils/PackageDealDecorators.js';
import { useComputeAttachmentUrl } from '../../../../app/utils/useComputeAttachmentUrl.js';
import { useGetCarViewCategoryLabel } from '../../../../utils/carViewCategoriesTranslationHooks.js';
import { kanbanPriorityLevelHelpers } from '../../../utils/kanbanPriorityLevelHelpers.js';
import { KanbanIdentityPictureComponent } from '../../attachments/KanbanIdentityPictureComponent.js';
import { TableSortableHeaderComponent } from '../../TableSortableHeaderComponent.js';
import { WorkshopDisplaySparePartAsTextWithEmphaseOnMissing } from '../post/WorkshopPostOperations.js';

export function selectKanbanAction(
  { actionDispatch }: ActionContext<Store, SelectKanbanWizardState>,
  selectedKanbanId: string
): void {
  actionDispatch.reduce((initial) => {
    return {
      ...initial,
      firstPageSelectedKanbanId: selectedKanbanId,
    };
  });
}

const getKanbanAgeing = (k: Kanban, t: TFunction): string => {
  const { days, hours } = kanbanHelpers.getKanbanAgeing(k);
  if (days !== 0) {
    return t('workshopSelectKanbanWizard.page1_table.time_days_hours', {
      days: days.toPrecision(2),
      hours: hours.toPrecision(2),
    });
  }
  return t('workshopSelectKanbanWizard.page1_table.time_hours', { hours: hours.toPrecision(2) });
};

const createSortKanbanByStandIdAndDueDate = (standId: string) => {
  return (k1: KanbanItem, k2: KanbanItem): number => {
    const eS1isTube = k1.expectedStandIds.includes(standId) ? 1 : 0;
    const eS2isTube = k2.expectedStandIds.includes(standId) ? 1 : 0;
    const d1 =
      kanbanHelpers.getMostRestrictiveDueDate(k1.dueDate, k1.refitEndDate) ?? Number.MAX_VALUE;
    const d2 =
      kanbanHelpers.getMostRestrictiveDueDate(k2.dueDate, k2.refitEndDate) ?? Number.MAX_VALUE;

    return eS2isTube - eS1isTube !== 0 ? eS2isTube - eS1isTube : d1 - d2;
  };
};

function getOrderedSortings(
  byWorkloadComparator: ObjectComparator<KanbanItem> | undefined,
  standId: string
): readonly ObjectComparator<KanbanItem>[] {
  return isTruthy(byWorkloadComparator)
    ? [
        workflowProgressHelpers.createSortKanbanByCreationDate('UP'),
        byWorkloadComparator,
        createSortKanbanByStandIdAndDueDate(standId),
      ]
    : [
        workflowProgressHelpers.createSortKanbanByCreationDate('UP'),
        createSortKanbanByStandIdAndDueDate(standId),
      ];
}

interface CurrentKanbanDetailsProps {
  readonly standId: string;
  readonly kanban: Kanban;
}

function CurrentKanbanDetails({ standId, kanban }: CurrentKanbanDetailsProps): JSX.Element {
  const [t] = useTranslation('libComponents');

  const [notReceivedSpareParts, packageDealsWithUnfinishedOperations] = useMemo(() => {
    const notReceivedSpareParts: DisplayableSparePart[] = [];
    const packagesWithUnfinishedOperations: PackageDeal[] = [];
    packageDealHelpers.getAvailablePackageDeals(kanban.packageDeals).forEach((pck) => {
      const { missing } = packageDealHelpers.getReceivedAndMissingSpareParts(pck);
      const operations = packageDealHelpers.getUnfinishedOperationsInPackageDealForStandId(
        pck,
        standId
      );
      notReceivedSpareParts.push(...missing);
      if (operations.length > 0) {
        packagesWithUnfinishedOperations.push(pck);
      }
    });
    return [notReceivedSpareParts, packagesWithUnfinishedOperations];
  }, [kanban.packageDeals, standId]);

  return (
    <>
      <tr>
        <td colSpan={12}>
          {notReceivedSpareParts.length > 0 && (
            <div className="m-b-sm">
              {`${t('workshopSelectKanbanWizard.page1_table.missingSpareParts')} : `}
              <WorkshopDisplaySparePartAsTextWithEmphaseOnMissing
                missingSpareParts={notReceivedSpareParts}
                receivedSpareParts={[]}
              />
            </div>
          )}
          {packageDealsWithUnfinishedOperations.length > 0 && (
            <ScrollableTableComponent height="365PX" isNarrowUntilMaxHeight>
              <thead>
                <tr className="is-fullwidth">
                  <th style={{ width: '2%' }}> </th>
                  <th style={{ width: '28%' }}>
                    {t('workshopSelectKanbanWizard.page1_table.operation_table.operation')}
                  </th>
                  <th style={{ width: '5%' }}>
                    {t('workshopSelectKanbanWizard.page1_table.operation_table.workload')}
                  </th>
                  <th style={{ width: '15%' }}>
                    {t('workshopSelectKanbanWizard.page1_table.operation_table.spareParts')}
                  </th>
                  <th style={{ width: '30%' }}>
                    {t('workshopSelectKanbanWizard.page1_table.operation_table.comment')}
                  </th>
                  <th style={{ width: '10%' }}>
                    {t('workshopSelectKanbanWizard.page1_table.operation_table.carElement')}
                  </th>
                  <th style={{ width: '5%' }}>
                    {t('workshopSelectKanbanWizard.page1_table.operation_table.category')}
                  </th>
                  <th style={{ width: '5%' }}> </th>
                </tr>
              </thead>
              <CurrentKanbanOperation
                pkgs={packageDealsWithUnfinishedOperations}
                standId={standId}
              />
            </ScrollableTableComponent>
          )}
        </td>
      </tr>
    </>
  );
}

interface CurrentKanbanOperationProps {
  readonly pkgs: readonly PackageDeal[];
  readonly standId: string;
}

function CurrentKanbanOperation({ pkgs, standId }: CurrentKanbanOperationProps): JSX.Element {
  const miscCategoryLabel = useGetCarViewCategoryLabel('MISC');

  return (
    <tbody>
      {packageDealHelpers.getAvailablePackageDeals(pkgs).map((pkg): JSX.Element[] => {
        const { missing, received } = packageDealHelpers.getReceivedAndMissingSpareParts(pkg);
        return packageDealHelpers
          .getUnfinishedOperationsInPackageDealForStandId(pkg, standId)
          .map((op): JSX.Element => {
            const carElement = pkg.carElement ? pkg.carElement.label : miscCategoryLabel;
            const category = pkg.carElement ? pkg.carElement.category : miscCategoryLabel;
            return (
              <tr className="is-fullwidth" key={op.id}>
                <td />
                <td>{packageDealHelpers.getOperationDisplayedLabel(op, pkg.variables)}</td>
                <td>{op.workload.toFixed(2)}</td>
                <td>
                  <WorkshopDisplaySparePartAsTextWithEmphaseOnMissing
                    receivedSpareParts={received}
                    missingSpareParts={missing}
                  />
                </td>
                <td>{pkg.comment}</td>
                <td>{carElement}</td>
                <td>{category}</td>
                <td> </td>
              </tr>
            );
          });
      })}
    </tbody>
  );
}

interface KanbanItemProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, SelectKanbanWizardState>;
  readonly kanban: KanbanItem;
  readonly isSelected: boolean;
  readonly standId: string;
  readonly t: TFunction;
  readonly displayConfiguration: DisplayConfiguration;
}

function KanbanItemComponent({
  $gs,
  $,
  kanban,
  isSelected,
  standId,
  t,
  displayConfiguration,
}: KanbanItemProps): JSX.Element {
  const { $imageModal } = $gs;
  const isOnline = useGetState($gs.$session.$isOnline);

  const selectKanbanActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(selectKanbanAction, kanban.id);
    },
    [kanban.id],
    $
  );
  const computeAttachmentUrlCallback = useComputeAttachmentUrl($gs);

  const licenseCssClassName = useMemo(() => {
    return `${kanbanPriorityLevelHelpers.getCssIdForPriorityLevelFromKanban(
      kanban,
      displayConfiguration.kanbanColorationCharter
    )} is-family-monospace`;
  }, [displayConfiguration.kanbanColorationCharter, kanban]);

  const ageing = useMemo((): string => getKanbanAgeing(kanban, t), [kanban, t]);

  const customerLabel = useMemo(
    (): string => kanbanHelpers.getCustomerLabel(kanban.customer),
    [kanban.customer]
  );

  const hasNotAvailableSpareParts = useMemo(() => {
    const pcks = packageDealHelpers.getPackageDealsRelatedToStandWithUnfinishedOperations(
      kanban.packageDeals,
      standId,
      true
    );
    for (const pck of pcks) {
      for (const sp of pck.spareParts.filter(nonDeleted)) {
        if (!isTruthy(sp.dateOfReception)) {
          return true;
        }
      }
    }
    return false;
  }, [kanban.packageDeals, standId]);

  const kanbanDisplayedLabel = (
    <>
      <span>
        {`${i18nHelpers.displayStringOrPlaceholder(t, kanban.infos.brand)} - ${kanban.infos.model}`}
      </span>
      {hasNotAvailableSpareParts && (
        <FaIcon
          id="exclamation-triangle"
          tooltip={t('workshopPost.missingSparePartTooltip')}
          iconColor="orange"
        />
      )}
    </>
  );

  const dueDate = kanbanHelpers.getMostRestrictiveDueDate(kanban.dueDate, kanban.refitEndDate);
  const dueDateLabel = dueDate
    ? globalHelpers.convertTimestampToDateString(dueDate, shortDayMonthFullYearDateFormatOptions)
    : '';

  const expandedKanbanIds = useGetState($.$expandedKanbanIds);

  return (
    <>
      <tr
        key={kanban.id}
        onClick={selectKanbanActionCallback}
        className={`${isSelected ? 'is-selected' : ''}${
          enumerate(kanban.expectedStandIds) === standId ? ' has-text-weight-semibold' : ''
        }`}
      >
        <td>
          <KanbanIdentityPictureComponent
            kanbanId={kanban.id}
            computeAttachmentUrl={computeAttachmentUrlCallback}
            $imageModal={$imageModal}
            thumbnailDisplayWidth={30}
            isOnline={isOnline}
          />
        </td>
        <TruncableTableTd>
          <ToggleNestedButton
            id={kanban.id}
            hasChildren
            $expandedIds={$.$expandedKanbanIds}
            label={kanbanDisplayedLabel}
          />
        </TruncableTableTd>
        <TruncableTableTd className={licenseCssClassName}>
          <>
            <ComputedKanbanIcon kanban={kanban} $gs={$gs} />
            <span style={{ paddingLeft: '5px' }}>{kanban.infos.license}</span>
            <PackageDealDecorators kanban={kanban} $gs={$gs} />
          </>
        </TruncableTableTd>
        <TruncableTableTd>{enumerate(kanban.expectedStandIds)}</TruncableTableTd>
        <td align="left">{`${kanban.mecaWorkload.toFixed(2)}h`}</td>
        <td align="left">{`${kanban.exteWorkload.toFixed(2)}h`}</td>
        <td align="left">{`${kanban.inteWorkload.toFixed(2)}h`}</td>
        <td align="left">{`${kanban.miscWorkload.toFixed(2)}h`}</td>
        <td align="left">{`${kanban.totalWorkload.toFixed(2)}h`}</td>
        <TruncableTableTd>{ageing}</TruncableTableTd>
        <TruncableTableTd>{customerLabel}</TruncableTableTd>
        <TruncableTableTd>{dueDateLabel}</TruncableTableTd>
      </tr>
      {expandedKanbanIds.includes(kanban.id) && (
        <CurrentKanbanDetails kanban={kanban} standId={standId} />
      )}
    </>
  );
}

interface Props extends AppProps<Store> {
  readonly standId: string;
  readonly displayConfiguration: DisplayConfiguration;
  readonly $: StoreStateSelector<Store, SelectKanbanWizardState>;
}

export function SelectKanbanWizardFirstPage({
  standId,
  displayConfiguration,
  $,
  $gs,
}: Props): JSX.Element {
  const [t] = useTranslation('libComponents');
  const sortBy = useGetState($.$sort.$by);
  const sortDirection = useGetState($.$sort.$direction);
  const selectableKanbans = useGetState($.$selectableKanbans);
  const firstPageSelectedKanbanId = useGetState($.$firstPageSelectedKanbanId);

  const sortedSelectableKanbans = useMemo((): readonly KanbanItem[] => {
    let byWorkloadComparator: ObjectComparator<KanbanItem> | undefined;
    switch (sortBy) {
      case 'mecaWorkload':
      case 'exteWorkload':
      case 'inteWorkload':
      case 'miscWorkload':
      case 'totalWorkload':
        byWorkloadComparator = sortingHelpers.createSortByNumericField(sortDirection, sortBy);
        break;
      default:
        break;
    }
    const orderedSortings: readonly ObjectComparator<KanbanItem>[] = getOrderedSortings(
      byWorkloadComparator,
      standId
    );
    return sortingHelpers.orderlyApplySortings(selectableKanbans, orderedSortings);
  }, [selectableKanbans, sortBy, sortDirection, standId]);

  return (
    <table className="table is-striped is-hoverable is-narrow is-fullwidth">
      <thead>
        <tr>
          <th className="is-narrow"> </th>
          <th>
            {`${t('workshopSelectKanbanWizard.page1_table.brand')} - ${t(
              'workshopSelectKanbanWizard.page1_table.model'
            )}`}
          </th>
          <th>{t('workshopSelectKanbanWizard.page1_table.license')}</th>
          <th>{t('workshopSelectKanbanWizard.page1_table.workflow')}</th>
          <TableSortableHeaderComponent
            content={t('workshopSelectKanbanWizard.page1_table.mecaWorkload')}
            isTruncable
            centerLabel={false}
            sortedField="mecaWorkload"
            $sort={$.$sort}
          />
          <TableSortableHeaderComponent
            content={t('workshopSelectKanbanWizard.page1_table.exteWorkload')}
            isTruncable
            centerLabel={false}
            sortedField="exteWorkload"
            $sort={$.$sort}
          />
          <TableSortableHeaderComponent
            content={t('workshopSelectKanbanWizard.page1_table.inteWorkload')}
            isTruncable
            centerLabel={false}
            sortedField="inteWorkload"
            $sort={$.$sort}
          />
          <TableSortableHeaderComponent
            content={t('workshopSelectKanbanWizard.page1_table.miscWorkload')}
            isTruncable
            centerLabel={false}
            sortedField="miscWorkload"
            $sort={$.$sort}
          />
          <TableSortableHeaderComponent
            content={t('workshopSelectKanbanWizard.page1_table.totalWorkload')}
            isTruncable
            centerLabel={false}
            sortedField="totalWorkload"
            $sort={$.$sort}
          />
          <th>{t('workshopSelectKanbanWizard.page1_table.ageing')}</th>
          <th>{t('workshopSelectKanbanWizard.page1_table.establishment')}</th>
          <th>{t('workshopSelectKanbanWizard.page1_table.dueDate')}</th>
        </tr>
      </thead>
      <tbody>
        {sortedSelectableKanbans.map(
          (k): JSX.Element => (
            <KanbanItemComponent
              key={k.id}
              isSelected={firstPageSelectedKanbanId === k.id}
              kanban={k}
              standId={standId}
              $={$}
              $gs={$gs}
              t={t}
              displayConfiguration={displayConfiguration}
            />
          )
        )}
      </tbody>
    </table>
  );
}
