import type { Kanban, SpecificFields } from '@stimcar/libs-base';
import type { KanbanInfos, MarketplaceMandate } from '@stimcar/libs-kernel';
import type { BusinessObjectToFormData, WithFormValidationWarnings } from '@stimcar/libs-uikernel';
import type { FormWithValidationState } from '@stimcar/libs-uitoolkit';
import { EMPTY_KANBAN_INFOS } from '@stimcar/libs-base';
import { MKTP_MANDATE_BUY } from '@stimcar/libs-kernel';

export type KanbanType = 'marketplace' | 'standard';

export type GeneralInformationsFormData = BusinessObjectToFormData<
  Pick<SpecificFields<Kanban>, 'workflowId'> & Omit<KanbanInfos, 'dateOfRegistration'>
> & {
  readonly kanbanType: KanbanType;
  readonly marketplaceMandate: MarketplaceMandate;
  readonly dueDate: number;
  readonly contractCode: string;
  readonly refitEndDate: number;
  readonly dateOfRegistration: number;
  readonly carLocation: string;
  readonly purchaseOrders: string;
};

export type GeneralInformationsForm = WithFormValidationWarnings<GeneralInformationsFormData>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { mileage, ...EMPTY_GENERAL_INFORMATIONS_WITHOUT_MILEAGE } = EMPTY_KANBAN_INFOS;
export const EMPTY_GENERAL_INFORMATIONS_FORM: GeneralInformationsForm = {
  ...EMPTY_GENERAL_INFORMATIONS_WITHOUT_MILEAGE,
  kanbanType: 'standard',
  marketplaceMandate: MKTP_MANDATE_BUY,
  contractCode: '',
  workflowId: '',
  dateOfRegistration: NaN,
  dueDate: NaN,
  refitEndDate: NaN,
  mileage: '',
  carLocation: '',
  purchaseOrders: '',
  warnings: {},
};

export type EditGeneralInformationsDialogState =
  FormWithValidationState<GeneralInformationsFormData> & {
    readonly active: boolean;
    readonly initialKanban?: Kanban;
  };

export const EMPTY_EDIT_GENERAL_INFORMATIONS_DIALOG_STATE: EditGeneralInformationsDialogState = {
  active: false,
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formWarning: undefined,
  initialKanban: undefined,
  formData: EMPTY_GENERAL_INFORMATIONS_FORM,
};
