import i18next from 'i18next';
import type { RegisteredBrowserInfos } from '@stimcar/core-libs-repository';
import type {
  Decorator,
  Kanban,
  KanbanSource,
  SiteConfiguration,
  StandHandlingStatus,
  UIContract,
  WithProgress,
  Workflow,
} from '@stimcar/libs-base';
import type { ActionContext, ReadOnlyActionContext } from '@stimcar/libs-uikernel';
import {
  AIRTABLE_SOURCE,
  CLONE_SOURCE,
  DELEGATION_SOURCE,
  enumerate,
  globalHelpers,
  handlingHelpers,
  i18nHelpers,
  kanbanHelpers,
  MANUAL_SOURCE,
  packageDealHelpers,
  shortDayMonthFullYearDateFormatOptions,
  shortDayMonthYearHourFormatOptions,
  sortingHelpers,
  VOLEAN_SOURCE,
  workflowHelpers,
  workflowProgressHelpers,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, keysOf } from '@stimcar/libs-kernel';
import type { ColumnDesc, TableCellIcon } from '../../lib/bulma/elements/table/typings/store.js';
import type { Store } from '../state/typings/store.js';
import { applyAndFilterTableItemsAction } from '../../lib/bulma/elements/table/filterAndSortUtils.js';
import { kanbanPriorityLevelHelpers } from '../../lib/utils/kanbanPriorityLevelHelpers.js';
import type { SelectKanbanState } from './typings/store.js';

const getContractAttributeDescColumns = (contracts: readonly UIContract[]): string[] => {
  const columns: string[] = [];
  contracts.forEach((c) => {
    c.attributeDescs.forEach((ad) => {
      if (columns.findIndex((d) => d === ad.id) === -1) {
        columns.push(ad.id);
      }
    });
  });
  return columns;
};

const defaultTextualSearch = (property: string, search: string): boolean => {
  const alphaNumLowerProperty = globalHelpers.replaceNonAlphaNumChar(property, '').toLowerCase();
  const alphaNumLowerSearch = globalHelpers.replaceNonAlphaNumChar(search, '').toLowerCase();
  return alphaNumLowerProperty.includes(alphaNumLowerSearch);
};

export const computeColumnDescs = (
  siteConfiguration: SiteConfiguration,
  contracts: readonly UIContract[],
  postInfos: RegisteredBrowserInfos | undefined,
  selectedStands: readonly string[]
): readonly ColumnDesc<Store, SelectKanbanState, WithProgress<Kanban>>[] => {
  if (!isTruthy(postInfos)) {
    return [];
  }
  const computeIconFromKanbanFunction = kanbanHelpers.getComputeIconFromKanbanFunction(
    siteConfiguration.computeIconFunction,
    siteConfiguration.workflows
  );
  const defaultColumnDescs: ColumnDesc<Store, SelectKanbanState, WithProgress<Kanban>>[] = [
    {
      columnLabel: i18next.t('selectKanban:list.handlingStatus'),
      columnIcon: { iconId: 'user', showText: false },
      columnType: 'display',
      id: 'handlingStatus',
      propertyType: 'string',
      columnStyle: { width: '2.5em' },
      getPropertyValue: (k): StandHandlingStatus => {
        const statusInfo = handlingHelpers.computeKanbanHandlingStatusInfo(k, selectedStands);
        return handlingHelpers.getStatusFromKanbanHandlingStatusInfo(statusInfo);
      },
      getDisplayedValue(k): string {
        const statusInfo = handlingHelpers.computeKanbanHandlingStatusInfo(k, selectedStands);
        const tooltips: string[] = [];
        if (statusInfo.isInAnomaly) {
          tooltips.push(i18next.t('selectKanban:tooltips.inAnomaly'));
        }
        if (statusInfo.currentlyHandledOnStands.length > 0) {
          tooltips.push(
            i18next.t('selectKanban:tooltips.currentlyHandled', {
              stands: statusInfo.currentlyHandledOnStands,
            })
          );
        }
        if (statusInfo.hasBeenHandledOrIsInPauseOnStands.length > 0) {
          tooltips.push(
            i18next.t('selectKanban:tooltips.hasBeenHandled', {
              stands: statusInfo.hasBeenHandledOrIsInPauseOnStands,
            })
          );
        }
        return enumerate(tooltips, '\n\n');
      },
      getCellIcon(k): TableCellIcon {
        const status = this.getPropertyValue(k) as StandHandlingStatus;
        let iconId = '';
        switch (status) {
          case 'currentlyHandled':
            iconId = 'user';
            break;
          case 'waitingForHandle':
          case 'hasBeenHandled':
            iconId = 'r/hourglass';
            break;
          case 'anomaly':
            iconId = 'exclamation-triangle';
            break;
          default:
            iconId = '';
        }
        return {
          iconId,
          showText: false,
        };
      },
      isNotHideable: true,
      isNotFilterable: true,
      isNotSortable: true,
    },
    {
      columnLabel: i18next.t('selectKanban:list.origin'),
      columnIcon: { iconId: 'plus-square', showText: false },
      columnType: 'display',
      id: 'origin',
      propertyType: 'string',
      columnStyle: { width: '4em' },
      getPropertyValue: (k): string => k.origin.source,
      getDisplayedValue(k): string {
        const origin = this.getPropertyValue(k) as KanbanSource;
        switch (origin) {
          case AIRTABLE_SOURCE:
            return i18next.t('selectKanban:list.automatedCreationOriginTooltip', {
              source: 'Airtable',
            });
          case VOLEAN_SOURCE:
            return i18next.t('selectKanban:list.automatedCreationOriginTooltip', {
              source: 'VOLean',
            });
          case CLONE_SOURCE:
            return i18next.t('selectKanban:list.cloneCreationOriginTooltip');
          case MANUAL_SOURCE:
            return i18next.t('selectKanban:list.manualCreationOriginTooltip');
          case DELEGATION_SOURCE:
            return i18next.t('selectKanban:list.delegationCreationOriginTooltip', {
              site: k.origin.id?.split('/')[1] ?? '<?>',
            });
          default:
            throw Error(`Unknown kanban origin ${origin}`);
        }
      },
      getCellIcon(k): TableCellIcon {
        const origin = this.getPropertyValue(k) as KanbanSource;
        let iconId = '';
        switch (origin) {
          case AIRTABLE_SOURCE:
          case VOLEAN_SOURCE:
            iconId = 'magic';
            break;
          case CLONE_SOURCE:
            iconId = 'clone';
            break;
          case MANUAL_SOURCE:
            iconId = 'hand-pointer';
            break;
          case DELEGATION_SOURCE:
            iconId = 'handshake';
            break;
          default:
            throw Error(`Unknown kanban origin ${origin}`);
        }
        return {
          iconId,
          showText: false,
        };
      },
    },
    {
      columnLabel: i18next.t('selectKanban:list.license'),
      columnType: 'display',
      id: 'license',
      propertyType: 'string',
      getPropertyValue: (k): string => k.infos.license,
      getDisplayedClassName: (k): string => {
        return `${kanbanPriorityLevelHelpers.getCssIdForPriorityLevelFromKanban(
          k,
          siteConfiguration.displayConfiguration.kanbanColorationCharter
        )} is-family-monospace`;
      },
      textualSearch(k, search): boolean {
        return defaultTextualSearch(this.getPropertyValue(k) as string, search);
      },
      getCellIcon: (k): TableCellIcon | undefined => {
        const iconId = computeIconFromKanbanFunction(k);
        return iconId
          ? {
              iconId,
              showText: true,
            }
          : undefined;
      },
      getDecorators: (k): readonly Decorator[] =>
        kanbanHelpers.getDecorators(k, siteConfiguration.packageDealDecorators),
      isDisplayedByDefault: true,
    },
    {
      columnLabel: i18next.t('selectKanban:list.position'),
      columnType: 'display',
      id: 'position',
      propertyType: 'string',
      getPropertyValue: (k): string | undefined => kanbanHelpers.getCurrentLocation(k),
      getDisplayedValue(k): string {
        const current = this.getPropertyValue(k) as string | undefined;
        if (isTruthyAndNotEmpty(current)) {
          if (current === 'workshop') {
            return i18next.t('globals:position.workshopLocation');
          }
          return current;
        }
        return i18next.t('globals:position.noLocation');
      },
      getCellIcon: (k): TableCellIcon => {
        const showText = true;
        const current = kanbanHelpers.getCurrentLocation(k);
        if (isTruthyAndNotEmpty(current)) {
          if (current === 'workshop') {
            return {
              iconId: 'wrench',
              showText,
            };
          }
          return {
            iconId: 'parking',
            showText,
          };
        }

        return {
          iconId: 'r/times-circle',
          showText,
        };
      },
      isDisplayedByDefault: true,
    },
    {
      columnLabel: i18next.t('selectKanban:list.contract'),
      columnType: 'display',
      id: 'contract',
      propertyType: 'string',
      getPropertyValue: (k): string => k.contract.code,
      isDisplayedByDefault: true,
    },
    {
      columnLabel: i18next.t('selectKanban:list.customer'),
      columnType: 'display',
      id: 'customer',
      propertyType: 'string',
      getPropertyValue: (k): string =>
        kanbanHelpers.getCustomerLabel(k.customer, i18next.t.bind(i18next)),
      isDisplayedByDefault: true,
    },
    {
      columnLabel: i18next.t('selectKanban:list.brand'),
      columnType: 'display',
      id: 'brand',
      propertyType: 'string',
      getPropertyValue: (k): string => k.infos.brand,
      getDisplayedValue(k): string {
        return i18nHelpers.displayStringOrPlaceholder(
          i18next.t.bind(i18next),
          this.getPropertyValue(k) as string
        );
      },
      isDisplayedByDefault: true,
      textualSearch(k, search): boolean {
        return defaultTextualSearch(this.getPropertyValue(k) as string, search);
      },
    },
    {
      columnLabel: i18next.t('selectKanban:list.model'),
      columnType: 'display',
      id: 'model',
      propertyType: 'string',
      getPropertyValue: (k): string => k.infos.model,
      isDisplayedByDefault: true,
      textualSearch(k, search): boolean {
        return defaultTextualSearch(this.getPropertyValue(k) as string, search);
      },
    },
    {
      columnLabel: i18next.t('selectKanban:list.motor'),
      columnType: 'display',
      id: 'motor',
      propertyType: 'string',
      getPropertyValue: (k): string => k.infos.motor,
      isDisplayedByDefault: true,
    },
    {
      columnLabel: i18next.t('selectKanban:list.mileage'),
      columnType: 'display',
      id: 'mileage',
      propertyType: 'number',
      getPropertyValue: (k): number => k.infos.mileage,
      getDisplayedValue: (k): string => {
        return i18next.t('selectKanban:list.mileageValue', { mileage: k.infos.mileage });
      },
      isDisplayedByDefault: true,
    },
    {
      columnLabel: i18next.t('selectKanban:list.vin'),
      columnType: 'display',
      id: 'vin',
      propertyType: 'string',
      getPropertyValue: (k): string => k.infos.vin,
      isDisplayedByDefault: true,
    },
    {
      columnLabel: i18next.t('selectKanban:list.dateOfRegistration'),
      columnType: 'display',
      id: 'dateOfRegistration',
      propertyType: 'number',
      getPropertyValue: (k): number | null => k.infos.dateOfRegistration,
      getDisplayedValue(k): string {
        const value = this.getPropertyValue(k) as number;
        return globalHelpers.getDDmmYYYYDateOrPlaceholderFromTimestamp(
          value,
          i18next.t('libUtils:noValue')
        );
      },
      isDisplayedByDefault: true,
    },
    {
      columnLabel: i18next.t('selectKanban:list.totalExcludingVAT'),
      columnType: 'display',
      id: 'totalExcludingVAT',
      propertyType: 'number',
      getPropertyValue: (k): number =>
        packageDealHelpers.getInvoiceablePackageDealsAndSparePartsPriceWithoutVAT(k.packageDeals),
      getDisplayedValue(k): string {
        const value = this.getPropertyValue(k) as number;
        return i18next.t('selectKanban:list.price', { price: value.toFixed(2) });
      },
    },
    {
      columnLabel: i18next.t('selectKanban:list.ageing'),
      columnType: 'display',
      id: 'ageing',
      propertyType: 'number',
      getPropertyValue: (k): number => kanbanHelpers.getKanbanAgeing(k).days,
      getDisplayedValue: (k): string => {
        const { days, hours } = kanbanHelpers.getKanbanAgeing(k);
        if (days !== 0) {
          return i18next.t('selectKanban:list.ageingTime', { hours, days });
        }
        return i18next.t('selectKanban:list.ageingTimeInHours', { hours });
      },
    },
    {
      columnLabel: i18next.t('selectKanban:list.dueDate'),
      columnType: 'display',
      id: 'dueDate',
      propertyType: 'date',
      getPropertyValue: (k): number | null =>
        kanbanHelpers.getMostRestrictiveDueDate(k.dueDate, k.refitEndDate),
      getDisplayedValue(k): string {
        return globalHelpers.convertTimestampToDateString(
          this.getPropertyValue(k) as number | null,
          shortDayMonthFullYearDateFormatOptions
        );
      },
    },
    {
      columnLabel: i18next.t('selectKanban:list.workflow'),
      columnType: 'display',
      id: 'workflow',
      propertyType: 'string',
      getPropertyValue: (k): string => k.workflowId,
    },
    {
      columnLabel: i18next.t('selectKanban:list.lastModified'),
      columnType: 'display',
      id: 'timestamp',
      propertyType: 'date',
      getPropertyValue: (k): number => k.timestamp,
      getDisplayedValue(k): string {
        return globalHelpers.convertTimestampToDateString(
          this.getPropertyValue(k) as number,
          shortDayMonthYearHourFormatOptions
        );
      },
    },
    {
      columnLabel: i18next.t('selectKanban:list.expectedStands'),
      columnType: 'display',
      id: 'expectedStands',
      propertyType: 'string',
      getPropertyValue: (k): string => enumerate(k.expectedStandIds),
    },
    {
      columnLabel: i18next.t('selectKanban:list.keyNumber'),
      columnType: 'display',
      id: 'keyNumber',
      propertyType: 'string',
      getPropertyValue: (k): string => k.logisticInfos.keyNumber,
    },
    {
      columnLabel: i18next.t('selectKanban:list.creationDate'),
      columnType: 'display',
      id: 'creationDate',
      propertyType: 'number',
      getPropertyValue: (k): number => k.creationDate,
      getDisplayedValue(k): string {
        return globalHelpers.convertTimestampToDateString(
          this.getPropertyValue(k) as number,
          shortDayMonthYearHourFormatOptions
        );
      },
    },
    {
      columnLabel: i18next.t('selectKanban:list.sparePartsAge'),
      columnType: 'display',
      id: 'sparePartsAge',
      propertyType: 'number',
      getPropertyValue: (k): number | null => {
        const age = kanbanHelpers.getOldestOrderedAndNotReceivedSparePartAge(k);

        if (age !== null) {
          // Put the 2 dates to midnight
          const now = new Date();
          now.setHours(0, 0, 0, 0);
          const ref = new Date(age);
          ref.setHours(0, 0, 0, 0);

          // Calculate the difference in number of days
          const difference = now.getTime() - ref.getTime();
          const diffInDays = Math.ceil(difference / (1000 * 3600 * 24));
          return diffInDays;
        }
        return null;
      },
      getDisplayedValue(k): string {
        const value = this.getPropertyValue(k);
        if (value !== null && value !== undefined) {
          return `${value.toString()} j`;
        }
        return '';
      },
    },
    {
      columnLabel: i18next.t('selectKanban:list.orderedAndReceivedParts'),
      columnType: 'display',
      id: 'orderedAndReceivedParts',
      propertyType: 'number',
      getPropertyValue: (k): number | null => {
        const { orderedParts, receivedParts } = kanbanHelpers.getOrderedAndReceivedSpareParts(k);
        if (orderedParts.length > 0) {
          return receivedParts.length / orderedParts.length;
        }
        return null;
      },
      getDisplayedValue(k): string {
        const { orderedParts, receivedParts } = kanbanHelpers.getOrderedAndReceivedSpareParts(k);
        if (orderedParts.length > 0) {
          const ratio = globalHelpers.roundTo(
            (100 * receivedParts.length) / orderedParts.length,
            1
          );
          return `${receivedParts.length}/${orderedParts.length} (${ratio}%)`;
        }
        return '';
      },
    },
  ];
  getContractAttributeDescColumns(contracts).forEach((ad) => {
    defaultColumnDescs.push({
      columnLabel: ad,
      columnType: 'display',
      id: ad,
      propertyType: 'string',
      getPropertyValue: (k): string | undefined => {
        const attributeValue = k.attributes[ad];
        return attributeValue !== undefined ? String(attributeValue) : undefined;
      },
    });
  });
  return defaultColumnDescs;
};

export function getWorkflow(
  workflows: readonly Workflow[],
  workflowId: string
): Workflow | undefined {
  return workflows.find((wf) => wf.id === workflowId);
}

export const kanbanTableContentProvider = async (
  ctx: ReadOnlyActionContext<Store, SelectKanbanState>
): Promise<readonly WithProgress<Kanban>[]> => {
  const kanbans = await ctx.kanbanRepository.getAllEntities();
  return filterTableKanbans(ctx, kanbans);
};

export function filterTableKanbans(
  { getState, getGlobalState }: ReadOnlyActionContext<Store, SelectKanbanState>,
  kanbans: readonly Kanban[]
) {
  const { siteConfiguration } = getGlobalState();
  const { selectedStands: selectedStandIds } = getState();
  const groupedKanbansByWorkflow: Record<string, Kanban[]> = {};
  kanbans.forEach((kanban) => {
    if (!groupedKanbansByWorkflow[kanban.workflowId]) {
      groupedKanbansByWorkflow[kanban.workflowId] = [];
    }
    groupedKanbansByWorkflow[kanban.workflowId].push(kanban);
  });
  const wProgressKanbans: WithProgress<Kanban>[] = [];
  keysOf(groupedKanbansByWorkflow).forEach((workflowId) => {
    const workflow = getWorkflow(siteConfiguration.workflows, workflowId);
    if (workflow) {
      const sortedKanbansAccordingToWorkflow =
        workflowProgressHelpers.sortKanbanAccordingToWorkflow(
          workflow.definition,
          groupedKanbansByWorkflow[workflowId]
        );

      const sortedStands = workflowHelpers.linearize(workflow.definition);
      const sortedSelectedStands = sortedStands.filter((s) => selectedStandIds.includes(s));
      const filteredKanbans = sortedKanbansAccordingToWorkflow.filter(
        (k) => k.expectedStandIds.filter((s) => sortedSelectedStands.includes(s)).length > 0
      );

      wProgressKanbans.push(
        ...sortingHelpers.sortKanbansByStandHandlingThenByDueDateThenByAge(
          filteredKanbans,
          sortedSelectedStands,
          siteConfiguration.displayConfiguration.kanbanColorationCharter?.dueDateThreshold,
          true
        )
      );
    }
  });

  return wProgressKanbans;
}

export async function reloadKanbanTableContentAction(
  ctx: ActionContext<Store, SelectKanbanState>
): Promise<void> {
  const { getGlobalState, getState } = ctx;
  const { contracts, siteConfiguration, session } = getGlobalState();
  const columnDescs = computeColumnDescs(
    siteConfiguration,
    contracts,
    session.infos,
    getState().selectedStands
  );
  const kanbans = await kanbanTableContentProvider(ctx);
  applyAndFilterTableItemsAction(ctx, kanbans, columnDescs);
}
