/**
 * npm_package_version will be substituted by its value at compilation time.
 * That way, BUILD_VERSION will contain a stable version that can be used to
 * detect client/server versions mismatches.
 */
export const BUILD_VERSION = `${process.env.npm_package_version}.${
  process.env.REFIT_IT_BUILD_TIMESTAMP ?? 'dev'
}`;

export const BUILD_VERSION_HEADER = 'RefitITBuildVersion';
