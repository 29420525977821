import type { KeyValueStorage } from '@stimcar/libs-kernel';

/* eslint-disable class-methods-use-this */

export class BrowserKeyValueStorageImpl implements KeyValueStorage {
  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  public setItem(key: string, value: string | number | boolean): void {
    localStorage.setItem(key, String(value));
  }

  public setObjectItem<T extends object>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  public getNumberItem(key: string): number | null {
    const value = this.getItem(key);
    return !value ? null : Number.parseInt(value, 10);
  }

  public getObjectItem<T extends object>(key: string): T | null {
    const stringValue = localStorage.getItem(key);
    return stringValue ? JSON.parse(stringValue) : null;
  }

  public clear(): void {
    localStorage.clear();
  }

  public size(): number {
    return localStorage.length;
  }

  public key(index: number): string | null {
    return localStorage.key(index);
  }
}
