/* eslint-disable @typescript-eslint/require-await */
import { isTruthy } from '../asserts.js';
import { BUILD_VERSION, BUILD_VERSION_HEADER } from '../buildVersion.js';
import { Logger } from '../logger.js';
import { ensureError } from '../misc.js';
import { keysOf } from '../typescript.js';
import type {
  HTTPClient,
  HttpLogFormatter,
  JsonParser,
  JsonReturnType,
  NodeFile,
} from './typings/HttpClient.js';
import type { Fetch } from './typings/index.js';
import { HttpError, nodeFileGuard } from './typings/HttpClient.js';

export type FormDataFactory = () => FormData;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

export const removeAuthenticationParamsFromUrl = (url: string | undefined): string =>
  url ? url.replace(/:\/\/[^@]+@/, '://') : '';

export const DEFAULT_HTTP_LOG_FORMATTER: HttpLogFormatter = (uri: string, init: RequestInit) =>
  `${init.method ?? 'GET'} ${uri}`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DEFAULT_REPLACER = (k: string, v: any): any => (v === undefined ? null : v);

/**
 * SSE client implementation.
 */
export class HTTPClientImpl implements HTTPClient {
  private readonly fetch: Fetch;

  protected readonly baseUrl?: string;

  private readonly formDataFactory: FormDataFactory;

  private readonly logFormatter: HttpLogFormatter;

  protected readonly jsonParser: JsonParser;

  /**
   * Default constructor.
   */
  public constructor(
    fetch: Fetch,
    formDataFactory: FormDataFactory,
    baseUrl?: string,
    logFormatter?: HttpLogFormatter,
    jsonParser?: JsonParser
  ) {
    log.info(`Create HTTPClient [${removeAuthenticationParamsFromUrl(baseUrl)}]...`);
    this.fetch = fetch;
    this.formDataFactory = formDataFactory;
    if (baseUrl && baseUrl.endsWith('/')) {
      this.baseUrl = baseUrl.substr(0, baseUrl.length - 1);
    } else {
      this.baseUrl = baseUrl;
    }
    this.logFormatter = !logFormatter ? DEFAULT_HTTP_LOG_FORMATTER : logFormatter;
    this.jsonParser = !jsonParser ? JSON.parse.bind(JSON) : jsonParser;
  }

  public async httpGet(path: string, method: 'GET' | 'DELETE' | 'HEAD' = 'GET'): Promise<Response> {
    const response = await this.fetchWithSessionToken(path, { method });
    return response;
  }

  public async httpGetAsText(
    path: string,
    method: 'GET' | 'DELETE' | 'HEAD' = 'GET'
  ): Promise<string> {
    const response = await this.httpGet(path, method);
    return response.text();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async httpGetAsJson<T extends JsonReturnType = any>(
    path: string,
    method: 'GET' | 'DELETE' | 'HEAD' = 'GET'
  ): Promise<T> {
    const text = await this.httpGetAsText(path, method);
    return this.jsonParser(text) as T;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async httpPost<R extends JsonReturnType | null = any>(
    path: string,
    body: string,
    method: 'POST' | 'PUT' | 'PATCH' = 'POST',
    contentType?: string
  ): Promise<R> {
    const response = await this.fetchWithSessionToken(path, {
      method,
      headers: contentType
        ? {
            'Content-Type': contentType,
          }
        : {},
      body,
    });
    const text = await response.text();
    return (
      (text && text.startsWith('{')) || text.startsWith('[') ? this.jsonParser(text) : text
    ) as R;
  }

  public async httpPostAsJSON<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    D extends JsonReturnType = any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    R extends JsonReturnType | null = any,
  >(
    path: string,
    data: D,
    method: 'POST' | 'PUT' | 'PATCH' = 'POST',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    replacer: (k: string, v: any) => any = DEFAULT_REPLACER
  ): Promise<R> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const replacerFunction = replacer || ((k: string, v: any): any => (v === undefined ? null : v));
    const response = await this.fetchWithSessionToken(path, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(data, replacerFunction),
    });
    const text = await response.text();
    return (
      (text && text.startsWith('{')) || text.startsWith('[') ? this.jsonParser(text) : text
    ) as R;
  }

  public async httpPostAsFormData<R extends JsonReturnType | null>(
    path: string,
    data: Record<string, NodeFile | File | string>,
    method: 'POST' | 'PUT' | 'PATCH' = 'POST'
  ): Promise<R> {
    // FormData is browser specific. If we need to upload files from server components
    // this will have to be replaced by a client/server compliant code (this can be done
    // through a factory like the EventSourceFactory).
    const formData = this.formDataFactory();

    keysOf(data).forEach((key) => {
      const value = data[key];
      if (nodeFileGuard(value)) {
        // Buffer is not supported in the browser's fetch implementation but is supported
        // in node-fetch implementation. In a node environment, Buffer is used instead
        // of File which is not available outside of a browser
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formData.append(key, value.buffer as any, value.filename);
      } else {
        formData.append(key, value);
      }
    });

    let headers = {
      Accept: 'application/json',
    };

    if (Reflect.has(formData, 'getHeaders')) {
      headers = {
        ...headers,
        /*
         * node-fetch headers fix to support multipart messages (getHeaders() is non-standard API):
         * https://www.npmjs.com/package/node-fetch#post-with-form-data-detect-multipart
         */
        ...Reflect.apply(Reflect.get(formData, 'getHeaders'), formData, []),
      };
    }

    const response = await this.fetchWithSessionToken(path, {
      method,
      headers,
      body: formData,
    });
    const text = await response.text();
    return (text ? this.jsonParser(text) : null) as R;
  }

  public async httpPostAsFile<R extends JsonReturnType | null>(
    path: string,
    data: Record<string, Buffer | Blob> | readonly File[] | File,
    method: 'POST' | 'PUT' | 'PATCH' = 'POST'
  ): Promise<R> {
    const computedData: Record<string, NodeFile | File | string> = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataAsAny = data as any;
    if (Array.isArray(dataAsAny)) {
      dataAsAny.forEach((f) => {
        const file = f as File;
        computedData[file.name] = file;
      });
    } else if (
      dataAsAny.name &&
      typeof dataAsAny.name === 'string' &&
      dataAsAny.size &&
      isTruthy(dataAsAny.type) &&
      dataAsAny.lastModified
    ) {
      computedData[dataAsAny.name] = dataAsAny as File;
    } else {
      keysOf(dataAsAny as Record<string, Buffer>).forEach((filename) => {
        const buffer = dataAsAny[filename];
        computedData[filename] = {
          buffer,
          filename,
        };
      });
    }
    return await this.httpPostAsFormData(path, computedData, method);
  }

  private static async checkResponseStatus(path: string, response: Response): Promise<void> {
    if (!response.ok) {
      let { statusText } = response;
      const text = await response.text();
      if (text) {
        try {
          // Try to parse the body
          const json = JSON.parse(text);
          if (json.message) {
            statusText = json.message;
          } else {
            statusText = text;
          }
        } catch {
          // Do nothing
        }
      }
      throw new HttpError(
        response.status,
        `Unexpected HTTP request error for '${path}' : ${statusText}`
      );
    } else if (!response.body) {
      throw Error(`Unexpected HTTP request error for '${path}' : empty response body`);
    }
  }

  protected async fetchWithSessionToken(path: string, init: RequestInit = {}): Promise<Response> {
    // Append sesion token header
    let newInit = init;
    const additionalHeaders = this.getAdditionalHeaders();
    if (!init) {
      newInit = {
        headers: {
          ...additionalHeaders,
          [BUILD_VERSION_HEADER]: BUILD_VERSION,
        },
      };
    } else {
      const { headers, ...rest } = init;
      newInit = {
        headers: {
          ...headers,
          ...additionalHeaders,
          [BUILD_VERSION_HEADER]: BUILD_VERSION,
        },
        ...rest,
      };
    }
    // Fetch...
    // If a base URL has been provided (usefull in nodejs mode in which URL have to be absolute)
    const uri = this.baseUrl ? `${this.baseUrl}${path.startsWith('/') ? '' : '/'}${path}` : path;
    const httpLog = this.logFormatter(uri, init);
    if (httpLog) {
      log.info(httpLog);
    }
    try {
      const response = await this.fetch(uri, newInit);
      // Check response
      await HTTPClientImpl.checkResponseStatus(path, response);
      return response;
    } catch (e) {
      await this.handleFetchError(ensureError(e), path, newInit);
      throw e;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected async handleFetchError(e: Error, path: string, init: RequestInit): Promise<void> {
    // Do nothing... (intended to be subclassed)
  }

  // eslint-disable-next-line class-methods-use-this
  protected getAdditionalHeaders(): Record<string, string> {
    return {};
  }

  public getBaseUrl = (): string | undefined => this.baseUrl;

  public copyHttpHeadersTo = (xhr: XMLHttpRequest): void => {
    // Append build version header
    xhr.setRequestHeader(BUILD_VERSION_HEADER, BUILD_VERSION);
    // Append additional headers
    const additionalHeaders = this.getAdditionalHeaders();
    keysOf(additionalHeaders).forEach((key) => {
      const value = additionalHeaders[key];
      xhr.setRequestHeader(key, value);
    });
  };
}
