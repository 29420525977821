export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type Compare<T> = (a: T, b: T) => number;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PartialRecord<K extends keyof any, T> = {
  readonly [P in K]?: T;
};

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type DeepRequired<T, U extends object | undefined = undefined> = T extends object
  ? {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
      [P in keyof T]-?: NonNullable<T[P]> extends NonNullable<U | Function | Date>
        ? NonNullable<T[P]>
        : DeepRequired<NonNullable<T[P]>, U>;
    }
  : T;

/* prettier-ignore */
export type KnownKeysOf<T> = ({
  [K in keyof T]: string extends K ? never : number extends K ? never : K;
}) extends { [_ in keyof T]: infer U }
  ? U
  : never;

export type FilterPropertyKeys<T, P> = {
  [K in keyof T]: T[K] extends P ? K : never;
}[keyof T];

export type AttributesKeys<T> = FilterPropertyKeys<T, string | number | boolean | undefined | null>;
export type ObjectKeys<T> = Exclude<keyof T, AttributesKeys<T>>;

export type Mutable<T> = T extends (infer U)[]
  ? Mutable<U>[]
  : T extends readonly (infer U)[]
    ? Mutable<U>[]
    : T extends object
      ? {
          -readonly [P in keyof T]: Mutable<T[P]>;
        }
      : T;

export function mutable<T>(t: T): Mutable<T> {
  return t as Mutable<T>;
}
