import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type {
  ActionContext,
  AnyStoreDef,
  NoArgActionCallback,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import 'react-simple-keyboard/build/css/index.css';
import { SelectListOrVirtualKeyboardInputFormField } from '../input/SelectListOrVirtualKeyboardInputFormField.js';
import type { LoginModalDialogState } from './typings/store.js';
import {
  LOGIN_FROM_WORKSHOP_MANDATORY_FIELDS,
  LOGIN_MANDATORY_FIELDS,
  LoginFields,
} from './LoginFields.js';

interface Props<SD extends AnyStoreDef> {
  readonly $: StoreStateSelector<SD, LoginModalDialogState>;
  readonly loginActionCallback: NoArgActionCallback<SD>;
  readonly cancelLoginActionCallback?: NoArgActionCallback<SD>;
  readonly isWorkshopLogin: boolean;
}

export function LoginModalDialog<SD extends AnyStoreDef>({
  $,
  loginActionCallback,
  cancelLoginActionCallback,
  isWorkshopLogin,
}: Props<SD>): JSX.Element {
  const [t] = useTranslation('libComponents');

  // Remove "password" from mandatory fields when connectin
  const loginMandatoryFields = isWorkshopLogin
    ? LOGIN_FROM_WORKSHOP_MANDATORY_FIELDS
    : LOGIN_MANDATORY_FIELDS;

  const [onLoginFormSubmitCallback, , $formDataWithChangeTrigger] = useFormWithValidation({
    $,
    mandatoryFields: loginMandatoryFields,
    checkFieldContentActions: undefined, // No field content check
    checkFormConsistencyAction: undefined, // No specific form consistency check
    submitValidDataAction: loginActionCallback,
    t,
  });

  const onLoginFormSubmitAction = useActionCallback(
    async ({ actionDispatch, getState }: ActionContext<SD, LoginModalDialogState>) => {
      // Authenticate
      await actionDispatch.execCallback(onLoginFormSubmitCallback);
      // Close the dialog if there is no error
      if (!getState().formWarning) {
        actionDispatch.setProperty('loginModalActive', false);
      }
    },
    [onLoginFormSubmitCallback],
    $
  );

  const onCancelFormSubmitAction = useActionCallback(
    async ({ actionDispatch }: ActionContext<SD, LoginModalDialogState>): Promise<void> => {
      if (cancelLoginActionCallback) {
        await actionDispatch.execCallback(cancelLoginActionCallback);
      }
      actionDispatch.setProperty('loginModalActive', false);
    },
    [cancelLoginActionCallback],
    $
  );

  const alreadyKnownUsers = useGetState($.$alreadyKnownUsers);
  const fixedLogin = useGetState($.$fixedLogin);

  return (
    <ModalCardDialog
      $active={$.$loginModalActive}
      onOkClicked={onLoginFormSubmitAction}
      onCancelClicked={onCancelFormSubmitAction}
      warning={useGetState($.$formWarning)}
      title={t('login.connect')}
      okLabel={t('login.connect')}
    >
      {isWorkshopLogin ? (
        <>
          <SelectListOrVirtualKeyboardInputFormField
            $useSelectForUser={$.$selectOrVirtualKeyboardInputScopedState.$usingSelect}
            selectEntries={alreadyKnownUsers}
            $={$formDataWithChangeTrigger.$login}
            horizontal={{ bodyFlexGrow: 2, labelFlexGrow: 1 }}
            disableSelect={false}
            label={t('login.login')}
          />
        </>
      ) : (
        <>
          <LoginFields
            loginIsFixed={fixedLogin}
            $login={$formDataWithChangeTrigger.$login}
            $password={$formDataWithChangeTrigger.$password}
            onEnterKeyPressHandler={onLoginFormSubmitAction}
          />
        </>
      )}
    </ModalCardDialog>
  );
}
