import type { JSX } from 'react';
import { t } from 'i18next';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { Attachment, StorageCategories } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  CoreBackendRoutes,
  QUALITY_CONTROL_ATTACHMENT_FOLDER,
  URL_LIST_ELEMENTS_SEPARATOR,
} from '@stimcar/libs-base';
import { asyncForEach, isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useArrayItemSelector, useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog, TextAreaFormField, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { AttachmentsTabState } from '../../../lib/components/attachments/typings/store.js';
import type { Store } from '../../state/typings/store.js';
import { AttachmentsGallery } from '../../../lib/components/attachments/AttachmentsGallery.js';
import { ConfirmAttachmentRemovalDialog } from '../../../lib/components/attachments/ConfirmAttachmentRemovalDialog.js';
import {
  importAttachmentsAction,
  loadAttachmentsGalleryAction,
  showRemoveAttachmentConfirmDialogAction,
} from '../../utils/attachmentGalleryActions.js';
import { appendRegisteredBrowserSessionToken } from '../../utils/security.js';
import { useComputeAttachmentUrl } from '../../utils/useComputeAttachmentUrl.js';
import type { QualityControlState } from './typings/store.js';
import { EMPTY_QUALITY_CONTROL_NOT_OK_REPORT_MODAL_STATE } from './typings/store.js';

async function loadAttachmentsAction(
  { actionDispatch }: ActionContext<Store, AttachmentsTabState>,
  category: StorageCategories,
  objectId: string,
  folders: readonly string[],
  reloadElements?: boolean
): Promise<void> {
  await actionDispatch.exec(
    loadAttachmentsGalleryAction,
    CoreBackendRoutes.ATTACHMENT_FOLDER(
      category,
      objectId,
      folders.join(URL_LIST_ELEMENTS_SEPARATOR)
    ),
    reloadElements
  );
  actionDispatch.setProperty('loadingStatus', undefined);
}

function onImportSuccessAction(
  { getState, actionDispatch }: ActionContext<Store, QualityControlState>,
  importedFiles: readonly string[]
): void {
  const { steps, qualityControlNotOkReportModalState } = getState();
  const { packageDealId, formWarning } = qualityControlNotOkReportModalState;
  const step = steps.find(({ id }) => id === packageDealId);
  if (isTruthy(step)) {
    actionDispatch
      .scopeProperty('steps')
      .scopeArrayItem(packageDealId)
      .setProperty('files', [...step.files, ...importedFiles]);
    if (isTruthyAndNotEmpty(formWarning)) {
      const modalDisptach = actionDispatch.scopeProperty('qualityControlNotOkReportModalState');
      modalDisptach.setProperty('formWarning', undefined);
      modalDisptach.setProperty('formSubmitted', false);
    }
  }
}

async function removeAttachmentAction(
  {
    actionDispatch,
    getState,
    httpClient,
    getGlobalState,
  }: ActionContext<Store, AttachmentsTabState>,
  kanbanId: string
): Promise<void> {
  const { attachments, confirmAttachmentRemovalDialog } = getState();
  const { id, folder, name } = confirmAttachmentRemovalDialog;

  await httpClient.httpGet(
    appendRegisteredBrowserSessionToken(
      CoreBackendRoutes.ATTACHMENT('kanban', kanbanId, folder, name),
      nonnull(getGlobalState().session.infos).sessionToken
    ),
    'DELETE'
  );
  actionDispatch.scopeProperty('confirmAttachmentRemovalDialog').setProperty('active', false);
  actionDispatch.setProperty(
    'attachments',
    attachments.filter(
      ({ id: currentId, folder: currentFolder }) => currentId !== id || currentFolder !== folder
    )
  );
}

function onQualityControlReportFormSubmitAction({
  getState,
  getGlobalState,
  actionDispatch,
}: ActionContext<Store, QualityControlState>) {
  const { isOnline } = getGlobalState().session;
  const { steps, qualityControlNotOkReportModalState } = getState();
  const { packageDealId } = qualityControlNotOkReportModalState;
  const step = steps.find(({ id }) => id === packageDealId);
  if (isTruthy(step)) {
    if (isOnline && step.files.length === 0) {
      actionDispatch
        .scopeProperty('qualityControlNotOkReportModalState')
        .setProperty(
          'formWarning',
          t('workshop: qualityControl.notOkReportModal.missingReportPicture')
        );
    } else {
      const { qualityControlNotOkReportModalState } = getState();
      const { packageDealId, formData } = qualityControlNotOkReportModalState;
      actionDispatch
        .scopeProperty('steps')
        .scopeArrayItem(packageDealId)
        .setProperty('comment', formData.comment);
      actionDispatch
        .scopeProperty('qualityControlNotOkReportModalState')
        .setValue(EMPTY_QUALITY_CONTROL_NOT_OK_REPORT_MODAL_STATE);
    }
  }
}

async function closeModalAction(
  {
    getState,
    getGlobalState,
    actionDispatch,
    httpClient,
  }: ActionContext<Store, QualityControlState>,
  kanbanId: string
): Promise<void> {
  const { qualityControlNotOkReportModalState, steps } = getState();
  if (qualityControlNotOkReportModalState.mode === 'create') {
    const { packageDealId } = qualityControlNotOkReportModalState;
    const step = steps.find(({ id }) => id === packageDealId);
    if (isTruthy(step)) {
      const { isOnline } = getGlobalState().session;
      if (isOnline) {
        await asyncForEach(step.files, async (fileName) => {
          await httpClient.httpGet(
            appendRegisteredBrowserSessionToken(
              CoreBackendRoutes.ATTACHMENT(
                'kanban',
                kanbanId,
                QUALITY_CONTROL_ATTACHMENT_FOLDER.id,
                fileName
              ),
              nonnull(getGlobalState().session.infos).sessionToken
            ),
            'DELETE'
          );
        });
      }
      const stepDispatch = actionDispatch.scopeProperty('steps').scopeArrayItem(packageDealId);
      stepDispatch.setProperty('check', 'ND');
      stepDispatch.setProperty('files', []);
    }
  }
  actionDispatch
    .scopeProperty('qualityControlNotOkReportModalState')
    .setValue(EMPTY_QUALITY_CONTROL_NOT_OK_REPORT_MODAL_STATE);
}

interface QualityControlNotOkReportModalProps extends AppProps<Store> {
  readonly kanbanId: string;
  readonly $qualityControl: StoreStateSelector<Store, QualityControlState>;
}

export function QualityControlNotOkReportModal({
  $gs,
  $qualityControl,
  kanbanId,
}: QualityControlNotOkReportModalProps): JSX.Element {
  const [t] = useTranslation('workshop');
  const $ = $qualityControl.$qualityControlNotOkReportModalState;
  const formWarning = useGetState($.$formWarning);
  const isOnline = useGetState($gs.$session.$isOnline);
  const computeAttachmentUrl = useComputeAttachmentUrl($gs);
  const packageDealId = useGetState($.$packageDealId);
  const packageDealLabel = useGetState($.$packageDealLabel);
  const $qualityControlStep = useArrayItemSelector($qualityControl.$steps, packageDealId);

  const submitValidDataActionCallback = useActionCallback(
    onQualityControlReportFormSubmitAction,
    [],
    $qualityControl
  );

  const [onFormSubmit, , $formWithChangeTrigger] = useFormWithValidation({
    $,
    mandatoryFields: ['comment'],
    checkFieldContentActions: {},
    checkFormConsistencyAction: undefined,
    submitValidDataAction: submitValidDataActionCallback,
    t,
  });

  const loadAttachmentsActionCallback = useActionCallback(
    loadAttachmentsAction,
    [],
    $.$attachmentGallery
  );

  const onImportSuccessActionCallback = useActionCallback(
    onImportSuccessAction,
    [],
    $qualityControl
  );

  const importAttachmentsActionCallback = useActionCallback(
    async (
      { actionDispatch },
      category: StorageCategories,
      objectId: string,
      folder: string,
      files: readonly File[]
    ) => {
      const onImportSuccess = async (fileNamesMap: Record<string, string>) => {
        await actionDispatch.execCallback(
          onImportSuccessActionCallback,
          Object.values(fileNamesMap)
        );
      };
      await actionDispatch.exec(
        importAttachmentsAction,
        category,
        objectId,
        folder,
        files,
        onImportSuccess
      );
    },
    [onImportSuccessActionCallback],
    $gs
  );

  const showRemoveAttachmentConfirmDialogCallback = useActionCallback(
    showRemoveAttachmentConfirmDialogAction,
    [],
    $.$attachmentGallery
  );

  const removeAttachmentActionCallback = useActionCallback(
    async ({ actionDispatch }) => actionDispatch.exec(removeAttachmentAction, kanbanId),
    [kanbanId],
    $.$attachmentGallery
  );

  const closeModalActionCallback = useActionCallback(
    async ({ actionDispatch }) => actionDispatch.exec(closeModalAction, kanbanId),
    [kanbanId],
    $qualityControl
  );

  const files = useGetState($qualityControlStep.optChaining().$files);
  const attachmentFilter = useCallback(
    (attachment: Attachment) => files.includes(attachment.name),
    [files]
  );

  return (
    <>
      <ModalCardDialog
        noCardMargin
        $active={$.$active}
        warning={formWarning}
        title={packageDealLabel}
        onOkClicked={onFormSubmit}
        onCancelClicked={closeModalActionCallback}
      >
        <TextAreaFormField
          rows={3}
          className="mb-3"
          $={$formWithChangeTrigger.$comment}
          label={t('qualityControl.notOkReportModal.missingReportPicture')}
        />
        <h6 className="title is-6 mb-2">{t('qualityControl.notOkReportModal.pictures')}</h6>
        {isOnline ? (
          <div className="p-1">
            <AttachmentsGallery
              category="kanban"
              thumbnailSize={84}
              isOnline={isOnline}
              openCameraOnMobile
              objectId={kanbanId}
              $window={$gs.$window}
              $={$.$attachmentGallery}
              $imageModal={$gs.$imageModal}
              attachmentFilter={attachmentFilter}
              folders={QUALITY_CONTROL_ATTACHMENT_FOLDER}
              computeAttachmentUrl={computeAttachmentUrl}
              loadAttachmentsActionCallback={loadAttachmentsActionCallback}
              uploadToolkit={{
                importAttachmentsActionCallback,
              }}
              removeToolkit={{
                onRemoveCallback: showRemoveAttachmentConfirmDialogCallback,
              }}
            />
          </div>
        ) : (
          t('qualityControl.notOkReportModal.noPicturesUploadOffline')
        )}
      </ModalCardDialog>
      <ConfirmAttachmentRemovalDialog
        noCardMargin
        category="kanban"
        objectId={kanbanId}
        computeAttachmentUrl={computeAttachmentUrl}
        onOkClicked={removeAttachmentActionCallback}
        $={$.$attachmentGallery.$confirmAttachmentRemovalDialog}
        okLabel={t('qualityControl.confirmAttachmentRemovalDialog.okLabel')}
      >
        <p>{t('qualityControl.confirmAttachmentRemovalDialog.message')}</p>
      </ConfirmAttachmentRemovalDialog>
    </>
  );
}
