import type { Kanban } from '@stimcar/libs-base';
import {
  globalHelpers,
  kanbanHelpers,
  OPERATION_ATTRIBUTES,
  packageDealHelpers,
  transverseHelpers,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import type {
  OperationColoredBlock,
  OperationsCell,
  OperationsPerCategoryColumn,
} from './typings/store.js';

export const getCellTotalCellWorkload = (
  cell: OperationsCell | undefined,
  filterDoneOperations: boolean
): number => {
  return (
    cell?.blocks.reduce((a, b) => {
      if (filterDoneOperations && b.isFinished) {
        return a;
      }
      return a + b.workload;
    }, 0) ?? 0
  );
};

function getOperationBlocksForCategory(
  kanban: Kanban,
  currentCategory: string,
  standId: string,
  levelIndex: number
): OperationColoredBlock[] {
  const result: OperationColoredBlock[] = [];

  packageDealHelpers.getAvailablePackageDeals(kanban.packageDeals).forEach((pck) => {
    const operations = packageDealHelpers.getUnfinishedOperationsInPackageDealForStandId(
      pck,
      standId
    );
    operations.forEach((o) => {
      const qualifiedCategory = o.attributes[OPERATION_ATTRIBUTES.WORKSHOP_POST];
      if (isTruthy(qualifiedCategory)) {
        const { categoryId } = transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(
          String(qualifiedCategory)
        );
        if (categoryId === currentCategory) {
          result.push({
            operationId: o.id,
            operationLabel: packageDealHelpers.getOperationDisplayedLabel(o, pck.variables),
            workload: o.workload,
            isFinished: !!o.completionDate,
          });
        }
      } else if (levelIndex === 0) {
        result.push({
          operationId: o.id,
          operationLabel: packageDealHelpers.getOperationDisplayedLabel(o, pck.variables),
          workload: o.workload,
          isFinished: !!o.completionDate,
        });
      }
    });
  });
  return result;
}

/**
 * Prepare the data to display in the UI.
 * Returns an column of cells, there will be one cell per categoryId and each cell contains the operations
 * that are currently dispatched to the corresponding categoryId
 * The content of the cells is an array representing the workload of each operations on the given category.
 *
 * @param standId the id of the stand in package deals
 * @param furtherCategoryIds the categories ids sorted as in the tube
 * @param kanban the kanban whose operations we are dispatching
 */
export function prepareDataForTubeBalancing(
  standId: string,
  furtherCategoryIds: readonly string[],
  kanban: Kanban | undefined
): OperationsPerCategoryColumn {
  if (!isTruthy(kanban)) {
    return [];
  }
  const column: OperationsPerCategoryColumn = [];

  furtherCategoryIds.forEach((categoryId, i) => {
    column[i] = {
      categoryId,
      blocks: getOperationBlocksForCategory(kanban, categoryId, standId, i),
    };
  });
  return column;
}

export const computeKanbanIdPerQualifiedPostMap = (
  implantationId: string,
  standId: string,
  sortedLevelIds: readonly string[],
  kanbansWorkingCopy: readonly Kanban[],
  actionOriginLevel: string | undefined,
  actionOriginKanbanId: string | undefined
): Record<string, string> => {
  const map: Record<string, string> = {};
  sortedLevelIds.forEach((p) => {
    const assignedQualifiedPostId = globalHelpers.computeQualifiedWorkshopPostId(implantationId, p);
    if (isTruthyAndNotEmpty(actionOriginLevel) && p === actionOriginLevel) {
      map[actionOriginLevel] = actionOriginKanbanId ?? '';
    } else {
      const kanban = kanbanHelpers.getKanbanCurrentlyOnWorkshopPost(
        kanbansWorkingCopy,
        standId,
        implantationId,
        p
      );
      if (isTruthy(kanban) && kanban.id !== actionOriginKanbanId) {
        map[assignedQualifiedPostId] = kanban.id;
      }
    }
  });
  return map;
};
