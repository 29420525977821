import type { JSX } from 'react';
import React from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import type { CarViewProps } from './car-view-common.js';
import { CarBumpersView } from './CarBumpersView.js';
import { CarExternalView } from './CarExternalView.js';
import { CarInteriorView } from './CarInteriorView.js';
import { CarMechanicalView } from './CarMechanicalView.js';
import { CarViewCanvas } from './CarViewCanvas.js';

interface Props<SD extends AnyStoreDef> extends CarViewProps<SD> {
  readonly category: string;
}

export function CarViewsSwitch<SD extends AnyStoreDef>({
  category,
  ...viewProps
}: Props<SD>): JSX.Element {
  return (
    <>
      {category === 'BUMP' && <CarBumpersView {...viewProps} />}
      {category === 'EXTE' && <CarExternalView {...viewProps} />}
      {category === 'INTE' && <CarInteriorView {...viewProps} />}
      {category === 'MECA' && <CarMechanicalView {...viewProps} />}
      {category === 'MISC' && <CarViewCanvas {...viewProps} showCarBackground />}
      {!['BUMP', 'EXTE', 'INTE', 'MECA', 'MISC'].includes(category) && (
        <>{`Unknown category ${category}`}</>
      )}
    </>
  );
}
