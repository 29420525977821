import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban } from '@stimcar/libs-base';
import type { GlobalStoreStateSelector, StoreStateSelector } from '@stimcar/libs-uikernel';
import { filterReject } from '@stimcar/libs-base';
import { MKTP_ENERGY_UNKNOWN, MKTP_GEARBOX_UNKNOWN } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Button } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { MarketplaceDetailsState } from '../typings/store.js';
import { MarketplaceEditEquipmentsModalDialog } from './MarketplaceEditEquipmentsModalDialog.js';
import { MarketplaceEditMainSpecsModalDialog } from './MarketplaceEditMainSpecsModalDialog.js';
import { MarketplaceEquipmentsPreview } from './MarketplaceEquipmentsPreview.js';
import { MarketplaceMainSpecsPreview } from './MarketplaceMainSpecsPreview.js';

type EquipmentsConfiguration = {
  readonly selected: readonly string[];
  readonly additional: readonly string[];
};

function getSelectedAndAdditionalEquipments(
  kanbanEquipments: readonly string[] | undefined,
  existingEquipmentsFromConf: readonly string[]
): EquipmentsConfiguration {
  const { filtered, rejected } = filterReject(kanbanEquipments ?? [], (value) =>
    existingEquipmentsFromConf.includes(value)
  );

  return {
    selected: filtered,
    additional: rejected,
  };
}

interface MarketplaceSpecsProps {
  readonly $: StoreStateSelector<Store, MarketplaceDetailsState>;
  readonly $gs: GlobalStoreStateSelector<Store>;
  readonly $selectedKanban: StoreStateSelector<Store, Kanban>;
  readonly isEditable: boolean;
}

export function MarketplaceSpecs({
  $,
  $gs,
  $selectedKanban,
  isEditable,
}: MarketplaceSpecsProps): JSX.Element {
  const [t] = useTranslation('details');
  const isOnline = useGetState($gs.$session.$isOnline);
  const selectedKanban = useGetState($selectedKanban);
  const kanbanId = useGetState($selectedKanban.$id);
  const marketplaceEquipmentsConfiguration = useGetState(
    $gs.$siteConfiguration.$marketplaceEquipments
  );

  const openEditAdModalDialogActionCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.applyPayload({
        editMainSpecsDialog: {
          active: true,
          formSubmitted: false,
          formSubmitClickedOnce: false,
          formWarning: undefined,
          formData: {
            energy: selectedKanban?.marketplaceInfos?.mainSpecs.energy ?? MKTP_ENERGY_UNKNOWN,
            gearbox: selectedKanban?.marketplaceInfos?.mainSpecs.gearbox ?? MKTP_GEARBOX_UNKNOWN,
            horsepower: String(selectedKanban.marketplaceInfos?.mainSpecs.horsepower ?? ''),
            taxHorsepower: String(selectedKanban.marketplaceInfos?.mainSpecs.taxHorsepower ?? ''),
            doors: String(selectedKanban.marketplaceInfos?.mainSpecs.doors ?? ''),
            seats: String(selectedKanban.marketplaceInfos?.mainSpecs.seats ?? ''),
            ecoSticker: String(selectedKanban.marketplaceInfos?.mainSpecs.ecoSticker ?? ''),
            warnings: {},
          },
        },
      });
    },
    [selectedKanban?.marketplaceInfos?.mainSpecs],
    $
  );

  const openEditEquipmentDialogActionCallback = useActionCallback(
    ({ actionDispatch }): void => {
      const securityEquipments = getSelectedAndAdditionalEquipments(
        selectedKanban.marketplaceInfos?.equipments.security,
        marketplaceEquipmentsConfiguration.security
      );
      const comfortEquipments = getSelectedAndAdditionalEquipments(
        selectedKanban.marketplaceInfos?.equipments.comfort,
        marketplaceEquipmentsConfiguration.comfort
      );
      const multimediaEquipments = getSelectedAndAdditionalEquipments(
        selectedKanban.marketplaceInfos?.equipments.multimedia,
        marketplaceEquipmentsConfiguration.multimedia
      );
      const othersEquipments = getSelectedAndAdditionalEquipments(
        selectedKanban.marketplaceInfos?.equipments.others,
        marketplaceEquipmentsConfiguration.others
      );
      actionDispatch.applyPayload({
        editEquipmentsDialog: {
          active: true,
          formSubmitted: false,
          formSubmitClickedOnce: false,
          formWarning: undefined,
          formData: {
            security: {
              selectedEquipments: securityEquipments.selected,
              additionalEquipments: securityEquipments.additional,
              newEquipmentValue: '',
            },
            comfort: {
              selectedEquipments: comfortEquipments.selected,
              additionalEquipments: comfortEquipments.additional,
              newEquipmentValue: '',
            },
            multimedia: {
              selectedEquipments: multimediaEquipments.selected,
              additionalEquipments: multimediaEquipments.additional,
              newEquipmentValue: '',
            },
            others: {
              selectedEquipments: othersEquipments.selected,
              additionalEquipments: othersEquipments.additional,
              newEquipmentValue: '',
            },
            warnings: {},
          },
        },
      });
    },
    [selectedKanban, marketplaceEquipmentsConfiguration],
    $
  );

  return (
    <>
      <div>
        <div className="is-flex is-align-items-center is-justify-content-space-between m-2">
          <div className="has-text-weight-bold is-size-5">
            <strong>{t('tabs.marketplace.mainSpecs.subtitle')}</strong>
          </div>
          {isEditable && (
            <Button
              iconId="edit"
              disabled={!isOnline}
              additionalClass="is-primary"
              label={t('tabs.marketplace.mainSpecs.editButton')}
              onClick={openEditAdModalDialogActionCallback}
            />
          )}
        </div>
        <MarketplaceMainSpecsPreview $selectedKanban={$selectedKanban} />
      </div>
      <div>
        <div className="is-flex is-align-items-center is-justify-content-space-between m-2">
          <div className="has-text-weight-bold is-size-5">
            <strong>{t('tabs.marketplace.equipments.subtitle')}</strong>
          </div>
          {isEditable && (
            <Button
              iconId="edit"
              disabled={!isOnline}
              additionalClass="is-primary"
              label={t('tabs.marketplace.equipments.editButton')}
              onClick={openEditEquipmentDialogActionCallback}
            />
          )}
        </div>
        <MarketplaceEquipmentsPreview $selectedKanban={$selectedKanban} />
      </div>
      <MarketplaceEditMainSpecsModalDialog
        $={$.$editMainSpecsDialog}
        $gs={$gs}
        kanbanId={kanbanId}
      />
      <MarketplaceEditEquipmentsModalDialog
        $={$.$editEquipmentsDialog}
        $gs={$gs}
        kanbanId={kanbanId}
      />
    </>
  );
}
