import type { JSX, RefObject } from 'react';
import * as React from 'react';

interface IProps {
  readonly style?: IScrollbarStyle;
  readonly containerRef: RefObject<HTMLDivElement | null>;
  readonly tableRef: RefObject<HTMLTableElement | null>;
  readonly scrollTo: number;
  readonly onScroll: (scrollTo: number) => void;
}

interface IState {
  readonly focused: boolean;
  readonly containerWidth: number;
  readonly tableWidth: number;
  readonly scrollbarWidth: number;
}

export interface IScrollbarStyle {
  readonly background: React.CSSProperties;
  readonly backgroundFocus: React.CSSProperties;
  readonly foreground: React.CSSProperties;
  readonly foregroundFocus: React.CSSProperties;
}

export class TableHorizontalScrollbar extends React.Component<IProps, IState> {
  private readonly minHeight = 15;

  private scrollbarRef = React.createRef<HTMLDivElement>();

  private isMoving = false;

  private previousMoveClientX = 0;

  constructor(props: IProps) {
    super(props);

    this.state = {
      focused: false,
      containerWidth: 0,
      tableWidth: 0,
      scrollbarWidth: 0,
    };
  }

  public componentDidMount(): void {
    this.calculateDimensions();
    const scrollbar = this.scrollbarRef.current;

    if (scrollbar) {
      scrollbar.addEventListener('mousedown', this.onMouseDown);
    }

    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
  }

  public componentDidUpdate(): void {
    const { containerRef, tableRef } = this.props;
    const { containerWidth, tableWidth } = this.state;

    const newContainerWidth = containerRef.current?.getBoundingClientRect().width;
    const newTableWidth = tableRef.current?.getBoundingClientRect().width;

    if (containerWidth !== newContainerWidth || tableWidth !== newTableWidth) {
      this.calculateDimensions();
    }
  }

  public componentWillUnmount(): void {
    const scrollbar = this.scrollbarRef.current;

    if (scrollbar) {
      scrollbar.removeEventListener('mousedown', this.onMouseDown);
    }
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
  }

  private onMouseDown = (event: MouseEvent): void => {
    event.preventDefault();

    this.isMoving = true;
    this.previousMoveClientX = event.clientX;
  };

  private onMouseMove = (event: MouseEvent): void => {
    let { scrollTo } = this.props;
    const { containerWidth, scrollbarWidth } = this.state;

    if (!this.isMoving) {
      return;
    }

    event.preventDefault();

    const currentMoveClientX = event.clientX;
    const deltaX = currentMoveClientX - this.previousMoveClientX;

    const scrollbarMoveableDistance = containerWidth - scrollbarWidth;

    scrollTo = scrollbarMoveableDistance
      ? (scrollbarMoveableDistance * scrollTo + deltaX) / scrollbarMoveableDistance
      : 0;

    scrollTo = Math.max(0, Math.min(scrollTo, 1));

    this.previousMoveClientX = currentMoveClientX;

    const { onScroll } = this.props;
    onScroll(scrollTo);
  };

  private onMouseUp = (event: MouseEvent): void => {
    if (!this.isMoving) {
      return;
    }

    event.preventDefault();

    this.isMoving = false;
    this.previousMoveClientX = 0;
  };

  private onMouseOver = (): void => {
    this.setState({ focused: true });
  };

  private onMouseOut = (): void => {
    this.setState({ focused: false });
  };

  private calculateDimensions(): void {
    const { containerRef, tableRef } = this.props;

    if (!containerRef.current || !tableRef.current) {
      return;
    }

    const containerWidth = containerRef.current?.getBoundingClientRect().width;
    const tableWidth = tableRef.current?.getBoundingClientRect().width;

    let scrollbarWidth = tableWidth ? containerWidth ** 2 / tableWidth : 0;

    scrollbarWidth = Math.max(this.minHeight, Math.min(scrollbarWidth, containerWidth));

    this.setState({
      containerWidth,
      tableWidth,
      scrollbarWidth,
    });
  }

  public render(): JSX.Element {
    const { style, tableRef, scrollTo } = this.props;
    const { focused, containerWidth, tableWidth, scrollbarWidth } = this.state;

    const isScrollable = tableRef ? containerWidth < tableWidth : false;

    let scrollbarContainerStyle: React.CSSProperties = {
      display: isScrollable ? 'block' : 'none',
      boxSizing: 'border-box',
      position: 'absolute',
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: '#E3E5EB',
      height: 8,
    };

    if (style) {
      if (focused) {
        scrollbarContainerStyle = { ...scrollbarContainerStyle, ...style.backgroundFocus };
      } else {
        scrollbarContainerStyle = { ...scrollbarContainerStyle, ...style.background };
      }
    }

    const scrollbarPositionLeft = (containerWidth - scrollbarWidth) * scrollTo;

    let scrollbarStyle: React.CSSProperties = {
      boxSizing: 'border-box',
      position: 'absolute',
      bottom: 0,
      left: scrollbarPositionLeft,
      backgroundColor: '#888C97',
      borderRadius: 4,
      width: scrollbarWidth,
      height: 8,
    };

    if (style) {
      if (focused) {
        scrollbarStyle = { ...scrollbarStyle, ...style.foregroundFocus };
      } else {
        scrollbarStyle = { ...scrollbarStyle, ...style.foreground };
      }
    }

    return (
      <div
        style={scrollbarContainerStyle}
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        onMouseOver={this.onMouseOver}
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        onMouseOut={this.onMouseOut}
      >
        <div ref={this.scrollbarRef} style={scrollbarStyle} />
      </div>
    );
  }
}
