import type { TFunction } from 'i18next';
import type { WorkshopPostLevel, WorkshopStandImplantation } from '@stimcar/libs-base';
import { enumerate } from '@stimcar/libs-base';

function retrieveAllCategoryIdsForBranch(level: WorkshopPostLevel): string[] {
  const categoryIds: string[] = [];
  categoryIds.push(level.id);
  (level.ancestors ?? []).forEach((a) => {
    categoryIds.push(...retrieveAllCategoryIdsForBranch(a));
  });
  return categoryIds;
}

export function assertStandImplantationLevelIdUnicity(
  t: TFunction,
  implantation: WorkshopStandImplantation
): string | undefined {
  const allCategoryIds: string[] = [];
  implantation.definition.forEach((l) => {
    allCategoryIds.push(...retrieveAllCategoryIdsForBranch(l));
  });
  const existingCategoryId: Set<string> = new Set();
  const duplicates: Set<string> = new Set();

  allCategoryIds.forEach((id) => {
    if (existingCategoryId.has(id)) {
      duplicates.add(id);
    } else {
      existingCategoryId.add(id);
    }
  });

  if (duplicates.size > 0) {
    return t('errors.duplicateWorkshopImplantationCategoriesIds', {
      implantation: implantation.id,
      categories: enumerate([...duplicates]),
    });
  }

  return undefined;
}
