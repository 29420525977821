import type { DAY_OF_WEEK, ScheduledTaskType } from '../../model/typings/scheduledTask.js';
import {
  ALL_DAYS_OF_WEEK_ALIAS,
  CROSS_SITES_SCHEDULED_TASK_TYPES,
  EMAIL_SCHEDULED_TASK_TYPES,
  FRIDAY,
  IMPORT_SCHEDULED_TASK_TYPES,
  MONDAY,
  MONDAY_TO_FRIDAY_ALIAS,
  MONDAY_TO_SATURDAY_ALIAS,
  SATURDAY,
  SUNDAY,
  SUNDAY_TO_THURSDAY_ALIAS,
  THURSDAY,
  TUESDAY,
  TUESDAY_TO_SATURDAY_ALIAS,
  WEDNESDAY,
} from '../../model/typings/scheduledTask.js';

const MONDAY_TO_FRIDAY: DAY_OF_WEEK[] = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY];
const MONDAY_TO_SATURDAY: DAY_OF_WEEK[] = [...MONDAY_TO_FRIDAY, SATURDAY];
const MONDAY_TO_SUNDAY: DAY_OF_WEEK[] = [...MONDAY_TO_SATURDAY, SUNDAY];
const SUNDAY_TO_THURSDAY: DAY_OF_WEEK[] = [SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY];
const TUESDAY_TO_SATURDAY: DAY_OF_WEEK[] = [TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY];

function isCrossSitesScheduledTaskType(type: ScheduledTaskType): boolean {
  for (const crossSitesType of CROSS_SITES_SCHEDULED_TASK_TYPES) {
    if (crossSitesType === type) {
      return true;
    }
  }
  return false;
}

function isEmailScheduledTaskType(type: ScheduledTaskType): boolean {
  for (const emailType of EMAIL_SCHEDULED_TASK_TYPES) {
    if (emailType === type) {
      return true;
    }
  }
  return false;
}

function isImportScheduledTaskType(type: ScheduledTaskType): boolean {
  for (const importType of IMPORT_SCHEDULED_TASK_TYPES) {
    if (importType === type) {
      return true;
    }
  }
  return false;
}

function getActualDaysOfWeek(aliasOrDayValue: number): readonly DAY_OF_WEEK[] {
  if ((MONDAY_TO_SUNDAY as number[]).includes(aliasOrDayValue)) {
    return [aliasOrDayValue as DAY_OF_WEEK];
  }
  switch (aliasOrDayValue) {
    case ALL_DAYS_OF_WEEK_ALIAS:
      return MONDAY_TO_SUNDAY;
    case MONDAY_TO_FRIDAY_ALIAS:
      return MONDAY_TO_FRIDAY;
    case MONDAY_TO_SATURDAY_ALIAS:
      return MONDAY_TO_SATURDAY;
    case SUNDAY_TO_THURSDAY_ALIAS:
      return SUNDAY_TO_THURSDAY;
    case TUESDAY_TO_SATURDAY_ALIAS:
      return TUESDAY_TO_SATURDAY;
    default:
      return [];
  }
}

export const scheduledTaskHelpers = {
  isCrossSitesScheduledTaskType,
  isEmailScheduledTaskType,
  isImportScheduledTaskType,
  getActualDaysOfWeek,
};
