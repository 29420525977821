import type { RefObject } from 'react';
import { useEffect } from 'react';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';

export interface UseClickInsideOutsideActivationRefProps<SD extends AnyStoreDef> {
  readonly $active: StoreStateSelector<SD, boolean>;
}

interface Props<SD extends AnyStoreDef> extends UseClickInsideOutsideActivationRefProps<SD> {
  readonly domElementRef: RefObject<HTMLElement | null>;
  readonly deactivateOnInternalClick: boolean;
  /**
   * cf https://developer.mozilla.org/fr/docs/Web/API/EventTarget/addEventListener
   * for information about the capture parameter
   */
  readonly capture?: boolean;
  // Allows to disable the behavior of the hook according to a condition
  // (for example, if the component opens a modal dialog, a click will
  // probably be triggered outside of the component and as long as the
  // modal is opened, we don't want the component to be closed).
  readonly isEnabled?: boolean;
}

/**
 * This hook is usefull for components like menus which we want to be
 * able to show when a click is performed on it, but that we want
 * to disappear if a click occurs anywhere else (which is hard to catch).
 */
export function useDeactivateOnClickOutside<SD extends AnyStoreDef>({
  domElementRef,
  deactivateOnInternalClick,
  $active,
  capture = false,
  isEnabled = true,
}: Props<SD>): void {
  const elementIsActive = useGetState($active);

  const eventListenerActionCallback = useActionCallback(
    function eventListenerAction({ actionDispatch }, e: MouseEvent) {
      if (isEnabled) {
        if (domElementRef && domElementRef.current) {
          const domElementBelongsToRef = domElementRef.current.contains(e.target as Node);
          if (deactivateOnInternalClick && domElementBelongsToRef) {
            actionDispatch.setValue(!elementIsActive);
          } else if (elementIsActive && !domElementBelongsToRef) {
            e.stopImmediatePropagation();
            actionDispatch.setValue(false);
          }
        }
      }
    },
    [deactivateOnInternalClick, domElementRef, elementIsActive, isEnabled],
    $active
  );
  // Create the effect that will register a click listener on the DOM
  // to capture clicks inside or outside of the menu component
  useEffect((): (() => void) => {
    // Register a click listener
    // JS event loop handle listeners like asynchronous notifications. The signature of the callback is
    // () => void but it can handle async callbacks
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    document.addEventListener('click', eventListenerActionCallback, capture);
    return (): void => {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      document.removeEventListener('click', eventListenerActionCallback, capture);
    };
  }, [capture, eventListenerActionCallback, isEnabled]);
}
