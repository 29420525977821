import type { JSX } from 'react';
import React from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import type { FormFieldProps } from '@stimcar/libs-uitoolkit';
import { useFormFieldWarning } from '@stimcar/libs-uikernel';
import { FormField } from '@stimcar/libs-uitoolkit';
import type { MultiListSelectModalAndListResultProps } from './MultiListSelectModalAndListResult.js';
import { MultiListSelectModalAndListResult } from './MultiListSelectModalAndListResult.js';

export interface MultiListSelectModalAndListResultFormFieldProps<SD extends AnyStoreDef>
  extends MultiListSelectModalAndListResultProps<SD>,
    Omit<FormFieldProps, 'children' | 'warning'> {}

export function MultiListSelectModalAndListResultFormField<SD extends AnyStoreDef>({
  $selection,
  $preSelection,
  $preUnselect,
  label,
  $modalIsActive: $active,
  entries,
  sortingFunction,
  noExclamationTriangleIfWarning,
  placeholder,
  actionLabel,
  modalTitle,
  horizontal,
}: MultiListSelectModalAndListResultFormFieldProps<SD>): JSX.Element {
  const dispatchWarning = useFormFieldWarning($selection);

  return (
    <FormField
      label={label}
      warning={dispatchWarning}
      horizontal={horizontal}
      noExclamationTriangleIfWarning={noExclamationTriangleIfWarning}
    >
      <MultiListSelectModalAndListResult
        modalTitle={modalTitle}
        entries={entries}
        $modalIsActive={$active}
        $selection={$selection}
        $preSelection={$preSelection}
        $preUnselect={$preUnselect}
        placeholder={!placeholder ? label : placeholder}
        actionLabel={actionLabel}
        sortingFunction={sortingFunction}
      />
    </FormField>
  );
}
