import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban, KanbanHandling, Stand } from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  AvailablePermissionPaths,
  expertiseHelpers,
  filterReject,
  hasModifyPermission,
  kanbanHelpers,
  workflowHelpers,
  workflowProgressHelpers,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { SplitButtonDropdownEntry } from '../../lib/bulma/elements/SplitButtonDropdown.js';
import type { Store } from '../state/typings/store.js';
import { SplitButtonDropdown } from '../../lib/bulma/elements/SplitButtonDropdown.js';
import { computeExpertiseValidationPath, computeOperatePath } from '../coreConstants.js';

const shouldOpenHandleKanbanWarningModal = (
  expectedStandIds: readonly string[],
  openHandlingsOnOtherPosts: readonly KanbanHandling[],
  requestedStandId: string
): boolean => {
  return !expectedStandIds.includes(requestedStandId) || openHandlingsOnOtherPosts.length > 0;
};

interface Props extends AppProps<Store> {
  readonly kanban: Kanban;
  readonly isDisabled: boolean;
  readonly isUpDropdownMenu?: boolean;
  readonly isFullwidth?: boolean;
  readonly $active: StoreStateSelector<Store, boolean>;
}

export function HandleKanbanSplitButtonDropdown({
  kanban,
  isUpDropdownMenu = false,
  isDisabled,
  isFullwidth,
  $gs,
  $active,
}: Props): JSX.Element {
  const [t] = useTranslation('refitit');

  const doNavigate = useActionCallback(
    ({ navigate }, route: string) => {
      navigate(route);
    },
    [],
    $gs
  );

  const openStartKanbanHandlingWarningModalActionCallback = useActionCallback(
    (
      { actionDispatch },
      requestedOnStand: string,
      alreadyHandledOnPosts: readonly string[],
      standsExpectedAt: readonly string[]
    ) => {
      actionDispatch.applyPayload({
        isActive: true,
        kanban,
        requestedOnStand,
        alreadyHandledOnPosts,
        standsExpectedAt,
      });
    },
    [kanban],
    $gs.$startKanbanHandlingWarningModal
  );

  const openKanbanInGivenStandActionCallback = useActionCallback(
    ({ actionDispatch, navigate }, standId: string) => {
      actionDispatch.applyPayload({ startHandling: true });
      navigate(computeOperatePath(kanban.id, standId));
    },
    [kanban.id],
    $gs.$operatorView
  );

  const user = useGetState($gs.$session.$user);
  const postInfos = useGetState($gs.$session.$infos);
  const configuration = useGetState($gs.$siteConfiguration);
  const handledKanbanId = useGetState($gs.$handledKanbanId);

  const entries = useMemo((): readonly SplitButtonDropdownEntry[] => {
    const computedEntries: SplitButtonDropdownEntry[] = [];
    const workflow = configuration.workflows.find((w) => w.id === kanban.workflowId);
    if (
      user?.active &&
      isTruthy(workflow) &&
      isTruthyAndNotEmpty(postInfos?.role) &&
      isTruthyAndNotEmpty(postInfos?.id)
    ) {
      const linearWorkflow = workflowHelpers.linearize(workflow?.definition);
      const stands = linearWorkflow.map(
        (s): Stand => nonnull(configuration.stands.find((st) => st.id === s))
      );

      const progress = workflowProgressHelpers.computeProgress(workflow.definition, kanban);
      const openHandlings = kanbanHelpers.getOpenOperatorHandles(kanban);
      const { filtered: openHandlingsOnPost, rejected: openHandlingsOnOtherPosts } = filterReject(
        openHandlings,
        (h) => h.postId === postInfos?.id
      );
      let hasAccessToExpertiseStand = false;

      let continueWorkOnStandEntry: string | undefined;
      let kanbanExpectedAtStandEntry: string | undefined;
      const tempMap: Record<string, SplitButtonDropdownEntry> = {};

      stands.forEach((stand) => {
        const hasOpenhandlingsForStand =
          openHandlingsOnPost.find((h) => h.standId === stand.id) !== undefined;

        const hasAccess = hasModifyPermission(
          user.permissions,
          AvailablePermissionPaths.STAND_ACCESS_PERMISSION(stand.id)
        );

        if (hasAccess) {
          if (hasOpenhandlingsForStand) {
            tempMap[stand.id] = {
              type: 'entry',
              label: t('operatorActions.handleKanban.labelAlreadyStarted', { stand: stand.id }),
              handler: async (): Promise<void> => {
                await doNavigate(computeOperatePath(kanban.id, stand.id));
              },
              disabled: false,
              tooltip: t('operatorActions.handleKanban.tooltipAlreadyStarted', { stand: stand.id }),
              iconId: stand.iconClass,
            };
            continueWorkOnStandEntry = stand.id;
          } else {
            const openHandlingsOnOtherPostNames = openHandlingsOnOtherPosts.map((h) =>
              kanbanHelpers.getPostLabelForHandling(h, true)
            );
            const standProgress = progress.progressByStand[stand.id];
            const isThereStandRelatedOperationsOrSpareParts =
              standProgress.operationCount > 0 || standProgress.sparePartCount > 0;

            let tooltip = '';
            if (isTruthyAndNotEmpty(handledKanbanId)) {
              tooltip = t('operatorActions.handleKanban.alreadyOpenHandleTooltip');
            } else if (!isThereStandRelatedOperationsOrSpareParts) {
              tooltip = t('operatorActions.handleKanban.noOperationsOnStand');
            } else {
              tooltip = t('operatorActions.handleKanban.tooltip', { stand: stand.id });
            }

            tempMap[stand.id] = {
              type: 'entry',
              label: t('operatorActions.handleKanban.label', { stand: stand.id }),
              disabled:
                isTruthyAndNotEmpty(handledKanbanId) ||
                kanban.status !== 'open' ||
                !isThereStandRelatedOperationsOrSpareParts,
              tooltip,
              iconId: stand.iconClass,
              handler: async (): Promise<void> => {
                if (
                  shouldOpenHandleKanbanWarningModal(
                    progress.expectedStandIds,
                    openHandlingsOnOtherPosts,
                    stand.id
                  )
                ) {
                  await openStartKanbanHandlingWarningModalActionCallback(
                    stand.id,
                    openHandlingsOnOtherPostNames,
                    progress.expectedStandIds
                  );
                } else {
                  await openKanbanInGivenStandActionCallback(stand.id);
                }
              },
            };
          }
          if (
            progress.expectedStandIds.includes(stand.id) &&
            !isTruthy(kanbanExpectedAtStandEntry)
          ) {
            kanbanExpectedAtStandEntry = stand.id;
          }

          if (workflowHelpers.isExpertiseStand(stand)) {
            hasAccessToExpertiseStand = true;
          }
        }
      });

      // If the kanban is handled on a stand, put it first to have it in direct button access, then respect workflow order
      if (isTruthy(continueWorkOnStandEntry)) {
        computedEntries.push(tempMap[continueWorkOnStandEntry]);
        linearWorkflow.forEach((s) => {
          if (s !== continueWorkOnStandEntry && tempMap[s]) {
            computedEntries.push(tempMap[s]);
          }
        });
        // If the kanban is expected at a stand, put it first to have it in direct button access. Then respect the workflow order
        // If the kanban is expected at multiple stands, use the first for direct access, respect workflow order for others
      } else if (isTruthy(kanbanExpectedAtStandEntry)) {
        computedEntries.push(tempMap[kanbanExpectedAtStandEntry]);
        linearWorkflow.forEach((s) => {
          if (s !== kanbanExpectedAtStandEntry && tempMap[s]) {
            computedEntries.push(tempMap[s]);
          }
        });
        // Else respect workflow order
      } else {
        linearWorkflow.forEach((s) => {
          if (tempMap[s]) {
            computedEntries.push(tempMap[s]);
          }
        });
      }

      if (hasAccessToExpertiseStand) {
        const isToValidate = expertiseHelpers.hasAnExpertiseToValidate(
          kanban.packageDeals,
          kanban.workflowId,
          configuration
        );
        computedEntries.push({ type: 'separator' });
        computedEntries.push({
          type: 'entry',
          label: t('operatorActions.validateExpertise.label'),
          handler: async (): Promise<void> => {
            await doNavigate(computeExpertiseValidationPath(kanban.id));
          },
          disabled: !isToValidate,
          tooltip: t('operatorActions.validateExpertise.tooltip'),
          iconId: 'clipboard-check',
        });
      }
    }
    return computedEntries;
  }, [
    configuration,
    user?.active,
    user?.permissions,
    postInfos?.role,
    postInfos?.id,
    kanban,
    t,
    doNavigate,
    handledKanbanId,
    openStartKanbanHandlingWarningModalActionCallback,
    openKanbanInGivenStandActionCallback,
  ]);

  return (
    <SplitButtonDropdown
      isPrimary
      isFullwidth={isFullwidth}
      isUpMenu={isUpDropdownMenu}
      entries={entries}
      disabled={isDisabled}
      $={$active}
    />
  );
}
