import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  CarElement,
  CoreFields,
  PackageDeal,
  PackageDealDesc,
  StorageCategories,
} from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps, HorizontalFormFieldProps } from '@stimcar/libs-uitoolkit';
import {
  CoreBackendRoutes,
  DASH_PREFIX,
  enumerate,
  forEachRecordValues,
  isBooleanVariable,
  nonDeleted,
  packageDealDescHelpers,
  packageDealHelpers,
  URL_LIST_ELEMENTS_SEPARATOR,
} from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { ScrollableContainer } from '@stimcar/libs-uitoolkit';
import type { AttachmentsState } from '../../../../lib/components/attachments/typings/store.js';
import type { Store } from '../../../state/typings/store.js';
import { CustomContentFormField } from '../../../../lib/bulma/form/CustomContentFormField.js';
import { AttachmentsPanel } from '../../../../lib/components/attachments/AttachmentsPanel.js';
import { DisplayMultilineString } from '../../../../lib/components/misc/DisplayMultilineString.js';
import {
  EXPERTISE_ATTACHMENT_CATEGORY,
  loadAttachmentsGalleryAction,
  useGetExpertiseAttachmentFolder,
} from '../../../utils/attachmentGalleryActions.js';
import { useComputeAttachmentUrl } from '../../../utils/useComputeAttachmentUrl.js';
import { useGetCarElementLabel } from '../../../utils/useGetCarElementLabel.js';
import { FORM_HORIZONTAL_BODY_FLEX_GROW, isAPackageDeal } from '../../expertiseUtils.js';

interface Props extends AppProps<Store> {
  readonly kanbanId: string;
  readonly globalVariablesValues: Record<string, number | string>;
  readonly entity: PackageDealDesc | PackageDeal;
  readonly $attachmentsState: StoreStateSelector<Store, AttachmentsState>;
  readonly carElement: CoreFields<CarElement> | undefined;
  readonly roundPriceTo: number;
}

const HORIZONTAL_PROPS: HorizontalFormFieldProps = {
  bodyFlexGrow: FORM_HORIZONTAL_BODY_FLEX_GROW,
};

export function PackageDealDetailsComponent({
  kanbanId,
  entity,
  globalVariablesValues,
  $attachmentsState,
  carElement,
  roundPriceTo,
  $gs,
}: Props): JSX.Element {
  const [t] = useTranslation('operators');

  const computeAttachmentUrl = useComputeAttachmentUrl($gs);
  const expertiseAttachmentFolder = useGetExpertiseAttachmentFolder();

  const valuePlaceholder = t('expertiseView.packageDealDetails.nothingToDisplay');

  const carElementLabel = useGetCarElementLabel(carElement);

  const displayedValues = useMemo(() => {
    if (isAPackageDeal(entity)) {
      const {
        estimateComment,
        comment: workshopComment,
        spareParts,
        operations,
        hint,
        price,
        duration,
      } = entity;

      const activeSparePartsLabels = spareParts.filter(nonDeleted).map(({ label }) => label);
      const activeOperationsLabels = operations
        .filter(nonDeleted)
        .map(({ label, workload }) => `${label} (${workload})`);

      return {
        estimateComment,
        workshopComment,
        spareParts: enumerate(activeSparePartsLabels, '\n', {
          prefix: DASH_PREFIX,
          suffix: '',
        }),
        operations: enumerate(activeOperationsLabels, '\n', {
          prefix: DASH_PREFIX,
          suffix: '',
        }),
        hint,
        price,
        duration,
      };
    }

    // Case of a PackageDealDesc
    const { hint, sparePartDescs, priceExpression, durationExpression, variableDescs } = entity;
    const allVariables: Record<string, boolean | number | string> = {};
    forEachRecordValues(globalVariablesValues, (value, key) => {
      if (
        !isAPackageDeal(entity) &&
        packageDealHelpers.isVariableCreatedFromPackageDealTag(entity.tags, key)
      ) {
        if (typeof value === 'number') {
          allVariables[key] = value + 1;
        } else {
          throw new Error('A tag value must always be a number');
        }
      } else {
        allVariables[key] = value;
      }
    });

    forEachRecordValues(variableDescs, (variableDesc, key) => {
      if (isTruthy(variableDesc)) {
        // eslint-disable-next-line prefer-destructuring
        allVariables[key] = isBooleanVariable(variableDesc)
          ? false
          : variableDesc.availableValues[0];
      }
    });

    const activeSparePartDescs = sparePartDescs.filter(nonDeleted);
    const activeSparePartsDescLabels = activeSparePartDescs.map(({ label }) => label);

    return {
      estimateComment: '',
      workshopComment: '',
      spareParts: enumerate(activeSparePartsDescLabels, '\n', { prefix: DASH_PREFIX, suffix: '' }),
      hint,
      price: packageDealHelpers.computePackageDealExpressionResult(
        priceExpression,
        allVariables,
        roundPriceTo
      ),
      duration: packageDealHelpers.computePackageDealExpressionResult(
        durationExpression,
        allVariables,
        0.01
      ),
    };
  }, [entity, globalVariablesValues, roundPriceTo]);

  const isInteractiveDurationExpression = useMemo(
    () => packageDealDescHelpers.isInteractiveExpression(entity.durationExpression),
    [entity.durationExpression]
  );

  const isInteractivePriceExpression = useMemo(
    () => packageDealDescHelpers.isInteractiveExpression(entity.priceExpression),
    [entity.priceExpression]
  );

  const loadAttachmentsActionCallback = useActionCallback(
    async (
      { actionDispatch },
      category: StorageCategories,
      objectId: string,
      folders: readonly string[],
      reloadElements?: boolean
    ) => {
      await actionDispatch.exec(
        loadAttachmentsGalleryAction,
        CoreBackendRoutes.ATTACHMENT_FOLDER(
          category,
          objectId,
          folders.join(URL_LIST_ELEMENTS_SEPARATOR)
        ),
        reloadElements
      );
    },
    [],
    $attachmentsState
  );

  return (
    <ScrollableContainer>
      <div className="p-b-sm">
        <CustomContentFormField
          addExtraTopMargin
          horizontal={HORIZONTAL_PROPS}
          label={`${t('expertiseView.packageDealDetails.element')}:`}
        >
          <DisplayMultilineString value={carElementLabel} />
        </CustomContentFormField>
        <CustomContentFormField
          label={`${t('expertiseView.packageDealDetails.priceLabel')}:`}
          addExtraTopMargin
          horizontal={HORIZONTAL_PROPS}
        >
          {isInteractivePriceExpression ? (
            <div title={t('expertiseView.packageDealDetails.estimationTooltip')}>
              {t('expertiseView.packageDealDetails.priceEstimation', {
                price: displayedValues?.price,
              })}
            </div>
          ) : (
            t('expertiseView.packageDealDetails.price', {
              price: displayedValues?.price,
            })
          )}
        </CustomContentFormField>
        <CustomContentFormField
          label={`${t('expertiseView.packageDealDetails.durationLabel')}:`}
          addExtraTopMargin
          horizontal={HORIZONTAL_PROPS}
        >
          {isInteractiveDurationExpression ? (
            <div title={t('expertiseView.packageDealDetails.estimationTooltip')}>
              {t('expertiseView.packageDealDetails.durationEstimation', {
                duration: displayedValues?.duration,
              })}
            </div>
          ) : (
            t('expertiseView.packageDealDetails.duration', {
              duration: displayedValues?.duration,
            })
          )}
        </CustomContentFormField>
        <CustomContentFormField
          label={`${t('expertiseView.packageDealDetails.indication')}:`}
          addExtraTopMargin
          horizontal={HORIZONTAL_PROPS}
        >
          <DisplayMultilineString value={displayedValues?.hint} placeholder={valuePlaceholder} />
        </CustomContentFormField>
        <CustomContentFormField
          label={`${t('expertiseView.packageDealDetails.attachments')}:`}
          addExtraTopMargin
          horizontal={HORIZONTAL_PROPS}
        >
          {isAPackageDeal(entity) ? (
            <AttachmentsPanel
              category={EXPERTISE_ATTACHMENT_CATEGORY}
              objectId={kanbanId}
              folder={expertiseAttachmentFolder}
              $={$attachmentsState}
              $window={$gs.$window}
              $imageModal={$gs.$imageModal}
              loadAttachmentsActionCallback={loadAttachmentsActionCallback}
              toolsAreEnabled={false}
              computeAttachmentUrl={computeAttachmentUrl}
              nothingToDisplayPlaceholder={valuePlaceholder}
            />
          ) : (
            valuePlaceholder
          )}
        </CustomContentFormField>
        <CustomContentFormField
          addExtraTopMargin
          horizontal={HORIZONTAL_PROPS}
          label={`${t('expertiseView.packageDealDetails.spareParts')}:`}
        >
          <DisplayMultilineString
            value={displayedValues.spareParts}
            placeholder={valuePlaceholder}
          />
        </CustomContentFormField>
        <CustomContentFormField
          addExtraTopMargin
          horizontal={HORIZONTAL_PROPS}
          label={`${t('expertiseView.packageDealDetails.operations')}:`}
        >
          <DisplayMultilineString
            value={displayedValues.operations}
            placeholder={valuePlaceholder}
          />
        </CustomContentFormField>
        <CustomContentFormField
          addExtraTopMargin
          horizontal={HORIZONTAL_PROPS}
          label={`${t('expertiseView.packageDealDetails.workshopComment')}:`}
        >
          <DisplayMultilineString
            value={displayedValues?.workshopComment}
            placeholder={valuePlaceholder}
          />
        </CustomContentFormField>
        <CustomContentFormField
          addExtraTopMargin
          horizontal={HORIZONTAL_PROPS}
          label={`${t('expertiseView.packageDealDetails.estimateComment')}:`}
        >
          <DisplayMultilineString
            value={displayedValues?.estimateComment}
            placeholder={valuePlaceholder}
          />
        </CustomContentFormField>
      </div>
    </ScrollableContainer>
  );
}
