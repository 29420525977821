/* eslint-disable @typescript-eslint/promise-function-async */
// eslint-disable-next-line max-classes-per-file
import type {
  Database,
  DatabaseFactory,
  DatabaseStoreDesc,
  DatabaseStoresConfigurator,
  DatabaseTx,
  DatabaseUpgrader,
} from '@stimcar/core-libs-repository';
import { mapRecordValues } from '@stimcar/libs-base';
import { ensureError, isTruthy } from '@stimcar/libs-kernel';

function checkEventTarget(eventTarget: EventTarget | null, reject: (error: Error) => void): void {
  if (!eventTarget) {
    reject(new Error('Missing target object in IndexedDb request event'));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function singlePromise<T>(request: IDBRequest, convert?: (queryResult: any) => any): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    request.onsuccess = ({ target }: Event): void => {
      checkEventTarget(target, reject);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { result } = target as any;
      resolve(convert ? convert(result) : result);
    };
    request.onerror = (ev: Event): void => {
      reject(ensureError(ev.target));
    };
  });
}

function arrayPromise<T>(request: IDBRequest): Promise<T[]> {
  return new Promise<T[]>((resolve, reject) => {
    request.onsuccess = ({ target }: Event): void => {
      checkEventTarget(target, reject);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { result } = target as any;
      resolve(!result ? [] : result);
    };
    request.onerror = (ev: Event): void => {
      reject(ensureError(ev.target));
    };
  });
}

class IndexedDbDatabaseTx<DSD extends DatabaseStoreDesc> implements DatabaseTx<DSD> {
  private tx: IDBTransaction;

  private completeOrAbortPromise: Promise<void>;

  private active = true;

  public constructor(tx: IDBTransaction) {
    this.tx = tx;
    this.completeOrAbortPromise = new Promise((resolve, reject) => {
      this.tx.oncomplete = (): void => {
        resolve();
      };
      this.tx.onabort = (): void => {
        resolve();
      };
      this.tx.onerror = (ev: Event): void => {
        reject(ensureError(ev));
      };
    });
  }

  public commit(): Promise<void> {
    this.checkActive();
    this.active = false;
    return this.completeOrAbortPromise;
  }

  public rollback(): Promise<void> {
    this.checkActive();
    this.active = false;
    this.tx.abort();
    return this.completeOrAbortPromise;
  }

  public get<SN extends keyof DSD>(storeName: SN, id: string): Promise<DSD[SN] | undefined> {
    this.checkActive();
    return singlePromise<DSD[SN] | undefined>(this.tx.objectStore(storeName as string).get(id));
  }

  public getFromIndex<SN extends keyof DSD>(
    storeName: SN,
    indexName: string,
    indexValue: string | number | boolean
  ): Promise<DSD[SN][]> {
    this.checkActive();
    return arrayPromise<DSD[SN]>(
      this.tx
        .objectStore(storeName as string)
        .index(indexName)
        .getAll(typeof indexValue === 'boolean' ? String(indexValue) : indexValue)
    );
  }

  public put<SN extends keyof DSD>(storeName: SN, obj: DSD[SN]): Promise<void> {
    this.checkActive();
    return singlePromise<void>(this.tx.objectStore(storeName as string).put(obj));
  }

  public getAll<SN extends keyof DSD>(storeName: SN): Promise<DSD[SN][]> {
    this.checkActive();
    return arrayPromise<DSD[SN]>(this.tx.objectStore(storeName as string).getAll());
  }

  public getN<SN extends keyof DSD>(storeName: SN, ...ids: string[]): Promise<DSD[SN][]> {
    this.checkActive();
    // Maybe should we have two implementation : the following one with few IDS, and
    // getAll + filter based one if the ids array is big
    return new Promise((resolve, reject) => {
      const objects: DSD[SN][] = [];
      const remainingIds = ids.slice().reverse();
      const process = (): void => {
        const id = remainingIds.pop();
        if (!id) {
          resolve(objects);
        } else {
          const request = this.tx.objectStore(storeName as string).get(id);
          request.onsuccess = ({ target }: Event): void => {
            checkEventTarget(target, reject);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { result } = target as any;
            if (result) {
              objects.push(result);
            }
            process();
          };
          request.onerror = (ev: Event): void => {
            reject(ensureError(ev.target));
          };
        }
      };
      process();
    });
  }

  public delete<SN extends keyof DSD>(storeName: SN, id: string): Promise<void> {
    this.checkActive();
    return singlePromise<void>(this.tx.objectStore(storeName as string).delete(id));
  }

  public deleteAll<SN extends keyof DSD>(storeName: SN): Promise<void> {
    this.checkActive();
    return singlePromise<void>(this.tx.objectStore(storeName as string).clear());
  }

  public count<SN extends keyof DSD>(storeName: SN): Promise<number> {
    this.checkActive();
    return singlePromise<number>(this.tx.objectStore(storeName as string).count());
  }

  public exists<SN extends keyof DSD>(storeName: SN, id: string): Promise<boolean> {
    this.checkActive();
    return singlePromise<boolean>(
      this.tx.objectStore(storeName as string).count(id),
      (count: number): boolean => count > 0
    );
  }

  public checkActive(): void {
    if (!this.active) {
      throw new Error('Transaction is not active');
    }
  }
}

class IndexedDbDatabaseImpl<DSD extends DatabaseStoreDesc> implements Database<DSD> {
  private idb: IDBDatabase;

  private dbName: string;

  public static async asyncConstructor<DSD extends DatabaseStoreDesc>(
    databaseName: string,
    version: number,
    upgrade: DatabaseUpgrader<DSD>
  ): Promise<IndexedDbDatabaseImpl<DSD>> {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open(databaseName, version);
      request.onerror = (ev: Event): void => {
        reject(ensureError(ev));
      };
      request.onblocked = request.onerror;
      request.onsuccess = ({ target }: Event): void => {
        checkEventTarget(target, reject);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { result } = target as any;
        resolve(new IndexedDbDatabaseImpl(databaseName, result));
      };
      request.onupgradeneeded = ({
        target,
        oldVersion,
        newVersion,
      }: IDBVersionChangeEvent): void => {
        checkEventTarget(target, reject);
        const { transaction } = request;
        if (!isTruthy(transaction)) {
          throw Error('Missing transaction');
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { result } = target as any;
        const db = result as IDBDatabase;
        const configurator: DatabaseStoresConfigurator<DSD> = {
          createObjectStore: (storeName: keyof DSD, idKey: string): void => {
            db.createObjectStore(storeName as string, { keyPath: idKey });
          },
          createIndex: (storeName: keyof DSD, indexName: string, keyPath: string): void => {
            const store = transaction.objectStore(storeName as string);
            store.createIndex(indexName, keyPath);
          },
          deleteIndex: (storeName: keyof DSD, indexName: string): void => {
            const store = transaction.objectStore(storeName as string);
            store.deleteIndex(indexName);
          },
        };
        upgrade(configurator, oldVersion, newVersion);
      };
    });
  }

  private constructor(databaseName: string, idb: IDBDatabase) {
    this.dbName = databaseName;
    this.idb = idb;
  }

  public beginTx(): DatabaseTx<DSD> {
    const tx = this.idb.transaction(
      [...this.idb.objectStoreNames].map((storeName): string => String(storeName)),
      'readwrite'
    );
    return new IndexedDbDatabaseTx<DSD>(tx);
  }

  public getStoreNames = (): readonly (keyof DSD)[] => [...this.idb.objectStoreNames];
}

export class IndexedDbDatabaseFactoryImpl implements DatabaseFactory {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private databases: Record<string, IndexedDbDatabaseImpl<any>> = {};

  public async create<DSD extends DatabaseStoreDesc>(
    databaseName: string,
    version: number,
    upgrade: DatabaseUpgrader<DSD>
  ): Promise<Database<DSD>> {
    const database = await IndexedDbDatabaseImpl.asyncConstructor(databaseName, version, upgrade);
    this.databases[databaseName] = database;
    return database;
  }

  public removeAllData = async (): Promise<void> => {
    await Promise.all(
      mapRecordValues(
        this.databases,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (database: IndexedDbDatabaseImpl<any>): Promise<void> => {
          const tx = database.beginTx();
          await Promise.all(
            database
              .getStoreNames()
              .map(async (storeName): Promise<void> => tx.deleteAll(storeName))
          );
        }
      )
    );
  };
}
