/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import type { JSX } from 'react';
import React, { useCallback, useMemo } from 'react';
import type { Attachment, StorageCategories } from '@stimcar/libs-base';
import type {
  ActionCallbackFromFunction,
  ActionContext,
  AnyStoreDef,
  NoArgActionCallback,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import type { ImageModalState, LoadingObjectProps } from '@stimcar/libs-uitoolkit';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { LoadingObject } from '@stimcar/libs-uitoolkit';
import { TruncableP } from '../misc/TruncableP.js';
import './AttachmentThumbnail.scss';
import type { ComputeAttachmentUrlCallback } from './typings/attachment.js';

export type OnRemoveActionCallback<SD extends AnyStoreDef> = ActionCallbackFromFunction<
  SD,
  (folder: string, filename: string, id: string) => Promise<void>
>;

function selectAttachmentPathAction<SD extends AnyStoreDef>(
  { actionDispatch, getState }: ActionContext<SD, readonly string[]>,
  path: string,
  selectMode: 'multiple' | 'one'
) {
  const selectedPaths = getState();
  const isSelected = selectedPaths.includes(path);
  if (isSelected) {
    actionDispatch.setValue(selectedPaths.filter((aPath) => aPath !== path));
  } else if (selectMode === 'one') {
    actionDispatch.setValue([path]);
  } else {
    actionDispatch.setValue([...selectedPaths, path]);
  }
}

interface SelectableLoadingObjectProps<SD extends AnyStoreDef>
  extends Pick<LoadingObjectProps, 'src' | 'onRemoveCallback'>,
    Pick<AttachmentThumbnailProps<SD>, 'selectMode'> {
  readonly size: number;
  readonly $selectedPaths: StoreStateSelector<SD, readonly string[]>;
  readonly path: string;
  readonly selectMode: NonNullable<AttachmentThumbnailProps<SD>['selectMode']>;
  readonly uiSelectMode: 'checkbox' | 'onClick';
  readonly openImageModalDialogActionCallback: NoArgActionCallback<SD>;
}

function SelectableLoadingObject<SD extends AnyStoreDef>({
  $selectedPaths,
  src,
  size,
  selectMode,
  onRemoveCallback,
  path,
  uiSelectMode,
  openImageModalDialogActionCallback,
}: SelectableLoadingObjectProps<SD>): JSX.Element {
  const onSelectCheckboxChangeActionCallback = useActionCallback(
    async function onSelectCheckboxChangeAction({ actionDispatch }) {
      await actionDispatch.exec(selectAttachmentPathAction, path, selectMode);
    },
    [path, selectMode],
    $selectedPaths
  );

  const selectedPaths = useGetState($selectedPaths);
  const figureAdditionalClassname = selectedPaths.includes(path)
    ? ' attachment-thumbnail-selected'
    : ' attachment-thumbnail';

  return (
    <div className={`image${figureAdditionalClassname}`}>
      <LoadingObject
        src={src}
        height={size}
        width={size}
        objectFit="cover"
        onRemoveCallback={onRemoveCallback}
        onClickCallback={
          uiSelectMode === 'onClick'
            ? onSelectCheckboxChangeActionCallback
            : openImageModalDialogActionCallback
        }
      />
      {uiSelectMode === 'checkbox' && (
        <input
          type="checkbox"
          style={{ position: 'absolute', top: 4, left: 4 }}
          onChange={onSelectCheckboxChangeActionCallback}
          checked={selectedPaths.includes(path)}
        />
      )}
    </div>
  );
}

type AttachmentThumbnailProps<SD extends AnyStoreDef> = {
  readonly category: StorageCategories;
  readonly objectId: string;
  readonly $selectedPaths?: StoreStateSelector<SD, readonly string[]>;
  readonly onRemoveCallback?: OnRemoveActionCallback<SD>;
  readonly size: number;
  readonly thumbnailSize?: number;
  readonly computeAttachmentUrl: ComputeAttachmentUrlCallback;
  readonly caption?: string;
  readonly attachments: readonly Attachment[];
  readonly $imageModal: StoreStateSelector<SD, ImageModalState>;
  readonly attachmentIndex: number;
  readonly selectMode?: 'multiple' | 'one';
  readonly onClickBehavior?: 'openImageModal' | 'select';
};

export function AttachmentThumbnail<SD extends AnyStoreDef>({
  category,
  objectId,
  onRemoveCallback,
  $selectedPaths,
  size,
  computeAttachmentUrl,
  caption,
  attachments,
  $imageModal,
  attachmentIndex,
  selectMode = 'multiple',
  onClickBehavior = 'openImageModal',
}: AttachmentThumbnailProps<SD>): JSX.Element {
  // We use 64 or 256 as thumbnail size not to have many thumbnail sizes on the server side
  let thumbnailSize = 256;
  if (size > 256) {
    thumbnailSize = 512; // thumbnails are not expected to be bigger than 512
  }
  const { id, folder, name } = useMemo((): Omit<Attachment, 'deleted'> => {
    if (attachments[attachmentIndex]) {
      return {
        id: attachments[attachmentIndex].id,
        folder: attachments[attachmentIndex].folder,
        name: attachments[attachmentIndex].name,
      };
    }
    return {
      id: '',
      folder: '',
      name: '',
    };
  }, [attachments, attachmentIndex]);

  const thumbnailImageUrl = computeAttachmentUrl(category, folder, name, objectId, {
    mode: 'cover',
    size: `${thumbnailSize}x${thumbnailSize}`,
  });

  const path = `${folder}/${name}`;

  const onRemoveClickedCallback = useCallback(async (): Promise<void> => {
    if (onRemoveCallback) {
      await onRemoveCallback(folder, name, id);
    }
  }, [onRemoveCallback, folder, name, id]);

  const openImageModalDialogActionCallback = useActionCallback(
    function openImageModalDialogAction({ actionDispatch }): void {
      const allImagesUrls: string[] = [];
      if (attachments) {
        attachments.forEach((attachment: { folder: string; name: string }) => {
          const currentAttachmentUrl = computeAttachmentUrl(
            category,
            attachment.folder,
            attachment.name,
            objectId
          );
          allImagesUrls.push(currentAttachmentUrl);
        });
      }
      actionDispatch.applyPayload({
        active: true,
        imagesUrls: allImagesUrls,
        imageIndex: attachmentIndex,
      });
    },
    [attachments, attachmentIndex, computeAttachmentUrl, category, objectId],
    $imageModal
  );

  return (
    <div className="card no-border-radius">
      <div className="card-image">
        {$selectedPaths !== undefined ? (
          <SelectableLoadingObject
            src={thumbnailImageUrl}
            $selectedPaths={$selectedPaths}
            path={path}
            selectMode={selectMode}
            uiSelectMode={onClickBehavior === 'select' ? 'onClick' : 'checkbox'}
            size={size}
            onRemoveCallback={onRemoveCallback ? onRemoveClickedCallback : undefined}
            openImageModalDialogActionCallback={openImageModalDialogActionCallback}
          />
        ) : (
          <div className="image">
            <LoadingObject
              src={thumbnailImageUrl}
              height={size}
              width={size}
              objectFit="cover"
              onRemoveCallback={onRemoveCallback ? onRemoveClickedCallback : undefined}
              onClickCallback={
                onClickBehavior === 'openImageModal'
                  ? openImageModalDialogActionCallback
                  : undefined
              }
            />
          </div>
        )}
        {caption && (
          <figcaption>
            <TruncableP className="is-size-7" style={{ width: size }}>
              {caption}
            </TruncableP>
          </figcaption>
        )}
      </div>
    </div>
  );
}
