import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { PackageDeal } from '@stimcar/libs-base';
import type { RefurbishingType } from '@stimcar/libs-kernel';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { packageDealHelpers } from '@stimcar/libs-base';
import { nonnull } from '@stimcar/libs-kernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { FaIcon } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../state/typings/store.js';
import type { AddOrUpdateDefectDialogState } from '../../../typings/store.js';
import { openDeleteRefurbishingOptionModalAction } from './DeleteRefurbishingOptionModal.js';

interface RefurbishingOptionItemProps {
  readonly $: StoreStateSelector<Store, AddOrUpdateDefectDialogState>;
  readonly refurbishingType: RefurbishingType;
  readonly packageDeal: PackageDeal;
}

export function RefurbishingOptionItem({
  $,
  refurbishingType,
  packageDeal,
}: RefurbishingOptionItemProps): JSX.Element {
  const [t] = useTranslation('operators', {
    keyPrefix: 'defects.addOrUpdateDefect.refurbishingOptions',
  });

  const { id, carElement } = packageDeal;

  const packageDealLabel = packageDealHelpers.getPackageDealDisplayedLabel(packageDeal);

  const openDeleteRefurbishingOptionDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openDeleteRefurbishingOptionModalAction, refurbishingType, id);
    },
    [refurbishingType, id],
    $.$deleteRefurbishingOptionDialogState
  );

  return (
    <tr>
      <td>{t(`type.${refurbishingType}`)}</td>
      <td>{nonnull(carElement).label}</td>
      <td>{packageDealLabel}</td>
      <td className="has-text-right">
        {packageDealHelpers.getPackageDealAndSparePartsPriceWithVAT(packageDeal, 'all').toFixed(2)}
      </td>
      <td
        aria-label="actions"
        style={{ whiteSpace: 'nowrap' }}
        className="has-text-centered is-flex-wrap-nowrap px-0"
      >
        <button
          key="delete"
          aria-label="delete"
          type="button"
          className="button is-small is-transparent"
          title={t('delete')}
          onClick={openDeleteRefurbishingOptionDialogCallback}
        >
          <FaIcon id="trash" tooltip={t('delete')} />
        </button>
      </td>
    </tr>
  );
}
