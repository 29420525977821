import type { InvoiceInfo } from '@stimcar/libs-base';
import type { FormWithValidationState } from '@stimcar/libs-uitoolkit';
import { EMPTY_FORM_WITH_VALIDATION_STATE } from '@stimcar/libs-uitoolkit';
import type { EditCustomerDialogState } from '../../../../lib/components/customer/typings/store.js';
import type { SortState } from '../../../../lib/components/TableSortableHeaderComponent.js';
import type { ListWithTechnicalIdTabState } from '../../typings/store.js';
import { EMPTY_ADMIN_EDIT_CUSTOMER_DIALOG_STATE } from '../../../admin/customer/typings/store.js';

export type PackageDealCodeAndLabel = {
  readonly id: string;
  readonly code: string;
  readonly label: string;
};

export type PurchaseOrderFormData = {
  readonly purchaseNumber: string;
  readonly label: string;
};

export type PurchaseOrderAddEditModalState = FormWithValidationState<PurchaseOrderFormData> & {
  readonly isActive: boolean;
  readonly purchaseOrderId: string;
};

export type PurchaseOrderCustomerAddEditModalState = {
  readonly purchaseOrderId: string;
  readonly editCustomerDialogState: EditCustomerDialogState;
};

export type PurchaseOrderDeletionModalState = {
  readonly isActive: boolean;
  readonly purchaseOrderId: string;
  readonly allocatedPackageDeals: readonly PackageDealCodeAndLabel[];
};

export type InvoiceReferenceAddFormData = {
  readonly purchaseOrderId: string;
  readonly invoiceReference: string;
};

export type InvoiceReferenceAddModalState = FormWithValidationState<InvoiceReferenceAddFormData> & {
  readonly isActive: boolean;
};

export type InvoiceRefundModalState = {
  readonly invoiceId: string;
  readonly purchaseOrderId: string;
  readonly invoiceReference: string;
  readonly generateNewInvoice: boolean;
  readonly isActive: boolean;
};

export type InvoiceInfoDeletionModalState = {
  readonly purchaseOrderId: string;
  readonly invoiceInfoId: string;
  readonly deleteAssociatedFiles: boolean;
  readonly deleteAssociatedEmailOperations: boolean;
  readonly isActive: boolean;
};

export type KanbanInvoiceTabState = ListWithTechnicalIdTabState & {
  readonly purchaseOrderAddModalState: PurchaseOrderAddEditModalState;
  readonly purchaseOrderEditModalState: PurchaseOrderAddEditModalState;
  readonly purchaseOrderDeletionModalState: PurchaseOrderDeletionModalState;
  readonly purchaseOrderCustomerAddModalState: PurchaseOrderCustomerAddEditModalState;
  readonly purchaseOrderCustomerEditModalState: PurchaseOrderCustomerAddEditModalState;
  readonly invoiceReferenceAddModalState: InvoiceReferenceAddModalState;
  readonly invoiceRefundModalState: InvoiceRefundModalState;
  readonly invoiceInfoDeletionModalState: InvoiceInfoDeletionModalState;
  readonly sort: SortState<
    | 'purchaseOrderLabel'
    | 'purchaseOrderCustomerLabel'
    | 'isRefund'
    | 'reference'
    | 'amount'
    | 'refundedInvoiceReference'
    | 'link'
  >;
};

export const PURCHASE_ORDER_ADD_EDIT_MODAL_EMPTY_STATE: PurchaseOrderAddEditModalState = {
  ...EMPTY_FORM_WITH_VALIDATION_STATE,
  isActive: false,
  purchaseOrderId: '',
  formData: {
    purchaseNumber: '',
    label: '',
    warnings: {},
  },
};

export const PURCHASE_ORDER_CUSTOMER_ADD_EDIT_MODAL_EMPTY_STATE: PurchaseOrderCustomerAddEditModalState =
  {
    purchaseOrderId: '',
    editCustomerDialogState: EMPTY_ADMIN_EDIT_CUSTOMER_DIALOG_STATE,
  };

export const PURCHASE_ORDER_DELETION_MODAL_EMPTY_STATE: PurchaseOrderDeletionModalState = {
  isActive: false,
  purchaseOrderId: '',
  allocatedPackageDeals: [],
};

export const INVOICE_REFERENCE_ADD_MODAL_EMPTY_STATE: InvoiceReferenceAddModalState = {
  ...EMPTY_FORM_WITH_VALIDATION_STATE,
  isActive: false,
  formData: {
    purchaseOrderId: '',
    invoiceReference: '',
    warnings: {},
  },
};

export const INVOICE_REFUND_MODAL_EMPTY_STATE: InvoiceRefundModalState = {
  invoiceId: '',
  purchaseOrderId: '',
  invoiceReference: '',
  generateNewInvoice: false,
  isActive: false,
};

export const INVOICE_INFO_DELETION_MODAL_EMPTY_STATE: InvoiceInfoDeletionModalState = {
  purchaseOrderId: '',
  invoiceInfoId: '',
  deleteAssociatedFiles: false,
  deleteAssociatedEmailOperations: false,
  isActive: false,
};

export const EMPTY_KANBAN_INVOICE_TAB_STATE: KanbanInvoiceTabState = {
  showTechnicalId: false,
  purchaseOrderAddModalState: PURCHASE_ORDER_ADD_EDIT_MODAL_EMPTY_STATE,
  purchaseOrderEditModalState: PURCHASE_ORDER_ADD_EDIT_MODAL_EMPTY_STATE,
  purchaseOrderDeletionModalState: PURCHASE_ORDER_DELETION_MODAL_EMPTY_STATE,
  purchaseOrderCustomerAddModalState: PURCHASE_ORDER_CUSTOMER_ADD_EDIT_MODAL_EMPTY_STATE,
  purchaseOrderCustomerEditModalState: PURCHASE_ORDER_CUSTOMER_ADD_EDIT_MODAL_EMPTY_STATE,
  invoiceReferenceAddModalState: INVOICE_REFERENCE_ADD_MODAL_EMPTY_STATE,
  invoiceRefundModalState: INVOICE_REFUND_MODAL_EMPTY_STATE,
  invoiceInfoDeletionModalState: INVOICE_INFO_DELETION_MODAL_EMPTY_STATE,
  sort: {
    by: 'purchaseOrderLabel',
    direction: 'UP',
  },
};

export type InvoiceDetail = Pick<
  InvoiceInfo,
  'reference' | 'amount' | 'invoiceId' | 'invoiceFirmId' | 'isRefund'
> & {
  invoiceDetailId: string;
  purchaseOrderId: string;
  purchaseOrderLabel: string;
  purchaseOrderCustomerLabel: string | null;
  invoiceInfoId: string | null;
  link: string | null;
  refundedInvoiceReference: string | null; // For a refund, this is the reference of the refunded invoice
  refundingInvoiceId: number | null; // For an invoice which has been refunded, this is the ID of the refund
};
