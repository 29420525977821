import { useMemo } from 'react';
import type { GlobalStoreStateSelector } from '@stimcar/libs-uikernel';
import { globalHelpers, transverseHelpers } from '@stimcar/libs-base';
import { isTruthy, nonnull } from '@stimcar/libs-kernel';
import { useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../state/typings/store.js';
import { useGetCurrentWorkshopOperatorImplantation } from './useGetCurrentWorkshopOperatorImplantation.js';

export function useGetCurrentWorkshopPostDisplayedLabels($gs: GlobalStoreStateSelector<Store>): {
  qualifiedPostLabel: string | undefined;
  postLabel: string | undefined;
} {
  const postQualifiedId = useGetState($gs.$session.$infos.optChaining().$label) ?? '';

  const implantation = useGetCurrentWorkshopOperatorImplantation($gs);

  const returnValue = useMemo(() => {
    let qualifiedPostLabel: string | undefined;
    let postLabel: string | undefined;

    if (implantation) {
      const { categoryId, postName } =
        transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(postQualifiedId);
      const category = transverseHelpers.getCategoryFromImplantation(
        implantation,
        nonnull(categoryId)
      );
      if (isTruthy(category)) {
        qualifiedPostLabel = globalHelpers.computeQualifiedWorkshopPostId(
          implantation.id,
          category.id,
          postName
        );
        postLabel = globalHelpers.computeWorkshopPostId(category.id, nonnull(postName));
      }
    }
    return {
      postLabel,
      qualifiedPostLabel,
    };
  }, [implantation, postQualifiedId]);

  return returnValue;
}
