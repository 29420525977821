import type { KanbanHandling, SiteConfiguration } from '@stimcar/libs-base';
import type { GlobalStoreStateSelector } from '@stimcar/libs-uikernel';
import { globalHelpers, transverseHelpers } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../state/typings/store.js';
import { getIconForWorkshopPost } from '../../lib/components/workshop/implantation/useGetIconForWorkshopPost.js';

export const getLocationIconForHandling = (
  configurationState: SiteConfiguration,
  handling: KanbanHandling
): string => {
  const stand = configurationState.stands.find((s) => s.id === handling.standId);

  if (!isTruthy(stand)) {
    return '';
  }

  if (!isTruthy(stand.implantations)) {
    return stand.iconClass;
  }
  if (globalHelpers.isAnomalyPostQualifiedCategoryId(handling.postId)) {
    return 'exclamation-triangle';
  }
  const { categoryId } = transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(
    handling.postId
  );
  return getIconForWorkshopPost(configurationState, handling.standId, categoryId ?? '');
};

export const useGetLocationIconForHandling = (
  $gs: GlobalStoreStateSelector<Store>,
  handling: KanbanHandling
): string => {
  const configurationState = useGetState($gs.$siteConfiguration);
  return getLocationIconForHandling(configurationState, handling);
};
