import type { JSX } from 'react';
import React from 'react';

interface Props {
  readonly height?: string;
  readonly width?: string;
  readonly float?: 'left' | 'right';
  readonly marginTop?: string;
  readonly marginBottom?: string;
  readonly marginLeft?: string;
  readonly marginRight?: string;
  /**
   * Color must be an rgb color (`rgb(...)`) or an hex color (`#...`)
   * Or it will be handled as a CSS class
   */
  readonly colorOrCssClass: string;
  readonly tooltip?: string;
  readonly withBorder?: boolean;
}

export function ColorBoxSpan({
  height = '1.5em',
  width = '1.5em',
  float = 'left',
  marginBottom,
  withBorder = false,
  marginLeft,
  marginRight,
  marginTop,
  tooltip,
  colorOrCssClass,
}: Props): JSX.Element {
  let backgroundColor;
  let colorClasseName;
  if (colorOrCssClass.startsWith('#') || colorOrCssClass.startsWith('rgb(')) {
    backgroundColor = colorOrCssClass;
  } else {
    colorClasseName = colorOrCssClass;
  }
  return (
    <span
      title={tooltip}
      className={colorClasseName}
      style={{
        backgroundColor,
        boxShadow: '0 2px 3px 0 rgba(0,0,0,.1), inset 0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        borderRadius: '2px',
        float,
        height,
        width,
        marginTop,
        marginLeft,
        marginBottom,
        marginRight,
        border: withBorder ? '1px solid black' : undefined,
      }}
    />
  );
}
