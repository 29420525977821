import type { JSX } from 'react';
import { useCallback, useEffect, useState } from 'react';

export function useRemainingVerticalSpace(
  footerSize?: number,
  recomputeSizeHint?: number
): [number, (node: HTMLDivElement) => void] {
  const [size, setSize] = useState(0);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect((): (() => void) => {
    function eventListener(): void {
      setHeight(window.innerHeight);
    }
    // Register a click listener
    window.addEventListener('resize', eventListener, false);
    // Call one to initialize with the initial size
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    eventListener();
    return (): void => {
      // Unregister the click listener
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      window.removeEventListener('resize', eventListener, false);
    };
  }, []);

  // This callback is used to compute the size of element
  // it is based on the explanation available at :
  // https://legacy.reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const measuredRef = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        const internalFooterSize = footerSize ? footerSize + 40 : 40;
        const computedSize = height - (node.getBoundingClientRect().top + internalFooterSize);
        setSize(computedSize);
      }
    },
    // dependency to recomputesizeHint is require so that callback is called when its value changes
    // even though we do not use its value inside the callback
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [height, footerSize, recomputeSizeHint]
  );
  return [size, measuredRef];
}

/**
 * Compute the size of the referenced element. The measured element is given to  be able to react
 * to its changes. It is not really satisfactory but I haven't find a better solution:
 * - The node is given as a parameter inside a useCallback method so we cannot react to its changes
 *
 * @param measuredElement The measuredElement must be memoised
 */
export function useClientRect(
  measuredElement: JSX.Element
): [number | undefined, (node: HTMLDivElement) => void] {
  const [rect, setRect] = useState<number | undefined>();
  const [element, setElement] = useState(measuredElement);

  if (element !== measuredElement) {
    setElement(measuredElement);
  }
  const ref = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null && element) {
        setRect(node.scrollHeight);
      }
    },
    [element]
  );
  return [rect, ref];
}
