/* eslint-disable react/jsx-one-expression-per-line */
import type { JSX } from 'react';
import React from 'react';
import type { GNode, WorkflowNode } from '@stimcar/libs-base';
import { workflowHelpers } from '@stimcar/libs-base';
import { ensureError, keysOf } from '@stimcar/libs-kernel';

export interface DrawNodePrefs {
  readonly fillRatio: number;
  readonly fillColor: string;
  readonly borderColor: string;
  readonly borderWidth: number;
  readonly boldFont: boolean;
  readonly tooltip: string | undefined;
}

interface Props {
  readonly workflowNode: WorkflowNode | undefined;
  readonly getDrawPrefs?: (key: string) => DrawNodePrefs | undefined;
}

const WIDTH = 80;
const HEIGHT = 40;
const MARGIN = 5;

function computeXY(node: GNode): [number, number] {
  return [
    node.x * (WIDTH + WIDTH / 2) + WIDTH / 2 + MARGIN,
    node.y * (HEIGHT + HEIGHT / 2) + HEIGHT / 2 + MARGIN,
  ];
}

const defaultNodePrefs = (key: string): DrawNodePrefs => {
  return {
    fillColor: key === 'END' ? 'darkgrey' : 'white',
    borderWidth: 2,
    borderColor: 'grey',
    fillRatio: 1,
    boldFont: false,
    tooltip: undefined,
  };
};

export function GraphicWorkflow({ workflowNode, getDrawPrefs }: Props): JSX.Element {
  try {
    const { width, height, nodes } = workflowHelpers.computeLayout({
      id: 'BEGIN',
      fork: workflowNode ? [workflowNode] : [{ id: '' }],
      join: { id: 'END' },
    });
    return (
      <>
        <svg width={width * WIDTH * 1.5 + MARGIN * 2} height={height * HEIGHT * 1.5 + MARGIN * 2}>
          {keysOf(nodes).map((id): JSX.Element[] => {
            const gNode = nodes[id];
            const [x1, y1] = computeXY(gNode);
            return gNode.targets.map((target): JSX.Element => {
              const [x2, y2] = computeXY(nodes[target]);
              return (
                <line
                  key={`${x1}-${y1}_${x2}-${y2}`}
                  x1={x1}
                  y1={y1}
                  x2={x2}
                  y2={y2}
                  stroke="darkgrey"
                  strokeWidth={2}
                />
              );
            });
          })}
          {Object.keys(nodes).map((key): JSX.Element => {
            const node = nodes[key];
            const [x, y] = computeXY(node);
            const userDrawPrefs = getDrawPrefs ? getDrawPrefs(key) : undefined;
            const {
              fillColor,
              fillRatio,
              boldFont,
              borderWidth,
              borderColor,
              tooltip,
            }: DrawNodePrefs = !userDrawPrefs ? defaultNodePrefs(key) : userDrawPrefs;
            switch (key) {
              case 'BEGIN':
              case 'END':
                return (
                  <circle
                    key={key}
                    cx={x}
                    cy={y}
                    r={HEIGHT / 2}
                    stroke="grey"
                    strokeWidth="2"
                    fill={fillColor}
                  />
                );
              default:
                return (
                  <g key={key}>
                    <title>{tooltip}</title>
                    <rect
                      x={x - WIDTH / 2}
                      y={y - HEIGHT / 2}
                      width={WIDTH}
                      height={HEIGHT}
                      fill="white"
                    />
                    <rect
                      x={x - WIDTH / 2}
                      y={y - HEIGHT / 2}
                      width={WIDTH * Math.min(Math.abs(fillRatio), 1)}
                      height={HEIGHT}
                      fill={fillColor}
                    />
                    <rect
                      x={x - (WIDTH + borderWidth) / 2}
                      y={y - (HEIGHT + borderWidth) / 2}
                      width={WIDTH + borderWidth}
                      height={HEIGHT + borderWidth}
                      stroke={borderColor}
                      strokeWidth={borderWidth}
                      rx={7}
                      ry={7}
                      style={{ fillOpacity: 0 }}
                    />
                    <text
                      x={x}
                      y={y + 5}
                      textAnchor="middle"
                      style={{ fontWeight: boldFont ? 'bold' : 'normal' }}
                    >
                      {key}
                    </text>
                  </g>
                );
            }
          })}
        </svg>
      </>
    );
  } catch (e) {
    return <div>Unexpected error : {ensureError(e).toString()}</div>;
  }
}
