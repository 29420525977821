import type { JSX } from 'react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { Action, AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState, useSetCallback } from '@stimcar/libs-uikernel';
import { ModalCardDialogWithoutDispatch } from '@stimcar/libs-uitoolkit';
import { Checkbox } from '../../bulma/form/Checkbox.js';
import type { ResetDialogState } from './typings/store.js';

export type ResetCallback = (hard: boolean) => Promise<void>;

type Props<SD extends AnyStoreDef> = {
  readonly resetCallback: ResetCallback;
  readonly $: StoreStateSelector<SD, ResetDialogState>;
  readonly onResetTriggerAction?: Action<SD, ResetDialogState>;
};

export function ResetDialog<SD extends AnyStoreDef>({
  resetCallback,
  $,
  onResetTriggerAction,
}: Props<SD>): JSX.Element {
  const [t] = useTranslation('libComponents');
  const active = useGetState($.$active);
  const hardReset = useGetState($.$hardReset);

  const onOkClickedActionCallback = useActionCallback(
    async function onOkClickedAction({ actionDispatch }) {
      actionDispatch.setProperty('active', false);
      if (onResetTriggerAction !== undefined) {
        await actionDispatch.exec(onResetTriggerAction);
      }
    },
    [onResetTriggerAction],
    $
  );

  const onCancelClicked = useSetCallback($.$active, false);

  const doResetCallback = useCallback(async () => {
    // Close dialog & execute trigger
    await onOkClickedActionCallback();
    // Perform reset
    await resetCallback(hardReset);
  }, [hardReset, onOkClickedActionCallback, resetCallback]);

  return (
    <>
      {active && (
        <ModalCardDialogWithoutDispatch
          isActive={active}
          titleIconId="exclamation-triangle"
          title={t('confirmResetModal.title')}
          onOkClicked={doResetCallback}
          onCancelClicked={onCancelClicked}
        >
          <p>{t('confirmResetModal.content')}</p>
          <Checkbox $={$.$hardReset} text={t('confirmResetModal.hardReset')} />
        </ModalCardDialogWithoutDispatch>
      )}
    </>
  );
}
