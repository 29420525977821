import type { CarViewCategory } from '@stimcar/libs-base';
import type { FormWithValidationState } from '@stimcar/libs-uitoolkit';
import { EMPTY_FORM_WITH_VALIDATION_STATE } from '@stimcar/libs-uitoolkit';
import type { AttachmentsTabState } from '../../../../lib/components/attachments/typings/store.js';
import { ATTACHMENTS_TAB_EMPTY_STATE } from '../../../../lib/components/attachments/typings/store.js';

type NotOkReportFormData = {
  readonly comment: string;
};

const EMPTY_NOT_OK_REPORT_FORM_DATA: NotOkReportFormData = {
  comment: '',
};

export type QualityControlNotOkReportModalState = FormWithValidationState<NotOkReportFormData> & {
  readonly active: boolean;
  readonly packageDealId: string;
  readonly packageDealLabel: string;
  readonly mode: 'create' | 'update';
  readonly attachmentGallery: AttachmentsTabState;
};

export const EMPTY_QUALITY_CONTROL_NOT_OK_REPORT_MODAL_STATE: QualityControlNotOkReportModalState =
  {
    active: false,
    mode: 'create',
    packageDealId: '',
    packageDealLabel: '',
    ...EMPTY_FORM_WITH_VALIDATION_STATE,
    attachmentGallery: ATTACHMENTS_TAB_EMPTY_STATE,
    formData: { ...EMPTY_NOT_OK_REPORT_FORM_DATA, warnings: {} },
  };

export type SelectedShape = {
  readonly id: string;
  readonly category: CarViewCategory;
};

type DisplayAllPackageDealsForCategoryState = {
  readonly category: CarViewCategory;
  readonly displayAllPackagedeals: boolean;
};

export const EMPTY_DISPLAY_ALL_PACKAGE_DEALS_FOR_CATEGORY: DisplayAllPackageDealsForCategoryState =
  {
    category: 'BUMP',
    displayAllPackagedeals: false,
  };

export type QualityControlCheck = 'OK' | 'ND' | 'KO';

export type QualityControlStep = {
  readonly id: string;
  readonly comment?: string;
  readonly check: QualityControlCheck;
  readonly files: readonly string[];
};

export type QualityControlState = {
  readonly selectedShape?: SelectedShape;
  readonly steps: readonly QualityControlStep[];
  readonly qualityControlNotOkReportModalState: QualityControlNotOkReportModalState;
  readonly displayAllPackageDealsForCategory: DisplayAllPackageDealsForCategoryState;
};

export const EMPTY_QUALITY_CONTROL_STATE: QualityControlState = {
  steps: [],
  displayAllPackageDealsForCategory: EMPTY_DISPLAY_ALL_PACKAGE_DEALS_FOR_CATEGORY,
  qualityControlNotOkReportModalState: EMPTY_QUALITY_CONTROL_NOT_OK_REPORT_MODAL_STATE,
};
