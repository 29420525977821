import type { KanbanBiWorkloadRange } from '@stimcar/core-libs-common';
import type { Kanban, KanbanIdentity, WithProgress, WORKFLOW_ID_TYPE } from '@stimcar/libs-base';
import type { Entity } from '@stimcar/libs-kernel';
import type { WorkshopImplantationViewState } from '../../../lib/components/workshop/typings/store.js';
import type { ComponentWithMountTracking } from '../../../lib/hooks/useComponentWithMountTracking.js';
import { WORKSHOP_IMPLANTATION_VIEW_EMPTY_STATE } from '../../../lib/components/workshop/typings/store.js';

export type StandAchievements = {
  readonly kanbansDone: readonly KanbanIdentity[];
  readonly kanbansToRework: readonly KanbanIdentity[];
  readonly operationDoneWorkload: number;
  readonly kanbanProgressesSum: number;
  readonly operationDoneRevenue: number;
};

export type STAND_ID_TYPE = string;

export type StandDisplayState = {
  readonly kanbans: readonly WithProgress<Kanban>[];
  readonly achievements: StandAchievements;
};

export type WorkflowDisplayState = {
  readonly stands: Record<WORKFLOW_ID_TYPE, StandDisplayState>;
  readonly kanbansCount: number;
};

export type StandAchievementsDisplayState = {
  readonly workflowId: string;
  readonly standId: string;
  readonly size: number;
};

const EMPTY_STAND_ACHIEVEMENTS_DISPLAY_STATE: StandAchievementsDisplayState = {
  workflowId: '',
  standId: '',
  size: 2,
};

export type ShiftProgressRange =
  Entity /* an ID is inserted in the object to become compliant with state conventions */ &
    KanbanBiWorkloadRange;

export const SHIFT_PROGRESS_CATEGORIES = ['EXP', 'MECA', 'CARRO', 'AUTRE'] as const;

export type ShiftProgressCategory = (typeof SHIFT_PROGRESS_CATEGORIES)[number];

export type ShiftProgressDisplayState = {
  readonly lastShifts: readonly [ShiftProgressRange, ShiftProgressRange, ShiftProgressRange];
  readonly currentWorkload: number;
  readonly expectedWorkload: number;
  readonly size: number;
  readonly mode: 'EXP' | 'MECA_CARRO_AUTRE';
  readonly includeSubcontractors: boolean;
};

const EMPTY_SHIFT_WORKLOAD = (id: number): ShiftProgressRange => ({
  id: String(id),
  from: 0,
  workload: 0,
});

export const EMPTY_SHIFT_PROGRESS_DISPLAY_STATE: ShiftProgressDisplayState = {
  lastShifts: [EMPTY_SHIFT_WORKLOAD(0), EMPTY_SHIFT_WORKLOAD(1), EMPTY_SHIFT_WORKLOAD(2)],
  currentWorkload: 0,
  expectedWorkload: 0,
  size: 2,
  includeSubcontractors: false,
  mode: 'MECA_CARRO_AUTRE',
};

export type DisplayViewState = ComponentWithMountTracking & {
  readonly workflowsStates: Record<WORKFLOW_ID_TYPE, WorkflowDisplayState>;
  readonly menuIsActive: boolean;
  readonly standDisplay: StandAchievementsDisplayState;
  readonly workshopImplantationView: WorkshopImplantationViewState;
  readonly shiftProgressDisplay: ShiftProgressDisplayState;
};

export type DisplayType = 'KanbanBoard' | 'StandAchievements' | 'Workshop' | 'ShiftProgress';

export const EMPTY_DISPLAY_VIEW_STATE: DisplayViewState = {
  componentIsMounted: false,
  workflowsStates: {},
  menuIsActive: false,
  standDisplay: EMPTY_STAND_ACHIEVEMENTS_DISPLAY_STATE,
  workshopImplantationView: WORKSHOP_IMPLANTATION_VIEW_EMPTY_STATE,
  shiftProgressDisplay: EMPTY_SHIFT_PROGRESS_DISPLAY_STATE,
};
