import { Logger } from '@stimcar/libs-kernel';
import { retry } from '../misc.js';
import type { MariaDbConnection, MariaDbPool } from './typings/MariaDbDAO.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

export async function waitForMariadb(
  mariadbPool: MariaDbPool,
  timeoutInSeconds: number
): Promise<void> {
  const mariaDbConnectFn = async (): Promise<void> => {
    let tx: MariaDbConnection | undefined;
    try {
      tx = await mariadbPool.getConnection();
    } finally {
      tx?.release();
    }
  };

  await retry(mariaDbConnectFn, timeoutInSeconds, `Failed to contact mariadb server`);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MariaDbRunnable<ARGS extends readonly any[] = [], RESULT = void> = (
  tx: MariaDbConnection,
  ...args: ARGS
) => Promise<RESULT>;

export type BoundMariaDbRunnable<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  RUNNABLE extends MariaDbRunnable<any, any>,
> =
  RUNNABLE extends MariaDbRunnable<infer ARGS, infer RESULT>
    ? (...args: ARGS) => Promise<RESULT>
    : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function bindMariadbTx<ARGS extends readonly any[], RESULT = void>(
  mariadbPool: MariaDbPool,
  mariaDbRunnable: MariaDbRunnable<ARGS, RESULT>
): BoundMariaDbRunnable<MariaDbRunnable<ARGS, RESULT>> {
  return async (...args: ARGS): Promise<RESULT> => {
    let tx: MariaDbConnection | undefined;
    try {
      // Open the TX
      tx = await mariadbPool.getConnection();
      await tx.query('SET TRANSACTION ISOLATION LEVEL READ COMMITTED;');
      await tx.beginTransaction();
      // Check invoice folder
      const result = await mariaDbRunnable(tx, ...args);
      // Release the TX
      await tx.commit();
      tx.release();
      tx = undefined;
      return result;
    } finally {
      if (tx) {
        try {
          await tx.rollback();
          tx.release();
        } catch {
          // Ignore this exception not to hide the original error
        }
      }
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function runMariaDbRunnable<ARGS extends readonly any[], RESULT = void>(
  mariadbPool: MariaDbPool,
  mariaDbRunnable: MariaDbRunnable<ARGS, RESULT>,
  ...args: ARGS
): Promise<RESULT> {
  return bindMariadbTx(mariadbPool, mariaDbRunnable)(...args);
}
