import type { ActionContext } from '@stimcar/libs-uikernel';
import { kanbanHelpers } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import type { HandledKanbanActions, Store, StoreState } from '../state/typings/store.js';

export async function closeOrPauseHandledKanbansAction(
  { getGlobalState, globalActionDispatch, kanbanRepository }: ActionContext<Store, StoreState>,
  action: HandledKanbanActions
): Promise<void> {
  const { session, handledKanbanId } = getGlobalState();
  const { id: postId } = nonnull(session.infos);

  if (!isTruthyAndNotEmpty(handledKanbanId)) {
    return;
  }

  try {
    const kanban = await kanbanRepository.getEntity(handledKanbanId);
    if (isTruthy(kanban) && action === 'close') {
      const newKanban = kanbanHelpers.closeCurrentHandling(kanban, postId);
      await kanbanRepository.updateEntity(newKanban);
    } else if (isTruthy(kanban) && action === 'pause') {
      const newKanban = kanbanHelpers.pauseCurrentHandling(kanban, postId);
      await kanbanRepository.updateEntity(newKanban);
    }
  } catch (err) {
    let value = err;
    if (err instanceof Error) {
      value = err.message;
    }
    globalActionDispatch.reduce((initial: StoreState): StoreState => {
      return {
        ...initial,
        message: String(value),
      };
    });
  }
}
