import type {
  Attachment,
  PackageDealExpressionComputationResult,
  ShareContext,
  SharedPackageDeal,
} from '@stimcar/libs-base';
import type { HTTPClient, KeyValueStorage } from '@stimcar/libs-kernel';
import type { StoreDef } from '@stimcar/libs-uikernel';
import type {
  BaseStoreActionContext,
  BaseStoreState,
  FormWithValidationState,
} from '@stimcar/libs-uitoolkit';
import type { EstimateViewState } from '../../../lib/components/documentGeneration/estimate/typings/store.js';
import { EMPTY_ESTIMATE_VIEW_STATE } from '../../../lib/components/documentGeneration/estimate/typings/store.js';

export type KanbanCustomerActionStatus =
  | 'none'
  | 'engagement_currentlyHandled'
  | 'engagement_ok'
  | 'delivery_alreadyDone'
  | 'delivery_ok';

export type SharePreferencesType = {
  email: string;
  estimate: {
    showPrice: boolean;
    ccEmails: readonly string[];
  };
};

export const SHARE_PREFERENCE_EMPTY_VALUE: SharePreferencesType = {
  email: '',
  estimate: {
    showPrice: true,
    ccEmails: [],
  },
};

export type ShareEstimateMailPreferencesModalState = {
  readonly isActive: boolean;
  readonly ccEmails: readonly string[];
};

export const SHARE_ESTIMATE_MAIL_PREFERENCES_MODAL_EMPTY_STATE: ShareEstimateMailPreferencesModalState =
  {
    isActive: false,
    ccEmails: [],
  };

export type CustomerValidationModalFormData = {
  readonly validationCode: string;
};

export type CustomerValidationCodeModalState =
  FormWithValidationState<CustomerValidationModalFormData> & {
    readonly isActive: boolean;
  };

export const CUSTOMER_VALIDATION_CODE_MODAL_EMPTY_STATE: CustomerValidationCodeModalState = {
  isActive: false,
  formData: {
    validationCode: '',
    warnings: {},
  },
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formWarning: undefined,
};

export type NotAllocatedPackageDealsErrorModalState = {
  readonly isActive: boolean;
  readonly notAllocatedPackageDealsWithPricesDifferentFromZero: readonly SharedPackageDeal[];
};

export const NOT_ALLOCATED_PACKAGE_DEALS_ERROR_MODAL_EMPTY_STATE: NotAllocatedPackageDealsErrorModalState =
  {
    isActive: false,
    notAllocatedPackageDealsWithPricesDifferentFromZero: [],
  };

export type KanbanShareSecondFactorState = {
  readonly validationEmail: string;
  readonly customerValidationCodeModal: CustomerValidationCodeModalState;
  readonly notAllocatedPackageDealsErrorModal: NotAllocatedPackageDealsErrorModalState;
};

export const KANBAN_SHARE_SECOND_FACTOR_EMPTY_STATE: KanbanShareSecondFactorState = {
  validationEmail: '',
  customerValidationCodeModal: CUSTOMER_VALIDATION_CODE_MODAL_EMPTY_STATE,
  notAllocatedPackageDealsErrorModal: NOT_ALLOCATED_PACKAGE_DEALS_ERROR_MODAL_EMPTY_STATE,
};

export type KanbanShareEstimateTabState = EstimateViewState & {
  readonly showPrice: boolean;
  readonly updatedPackageDealsInformations: readonly PackageDealExpressionComputationResult[];
  readonly notReferencedSparePartIdsFromAvailablePackageDeals: readonly string[];
  readonly secondFactor: KanbanShareSecondFactorState;
  readonly mailPreferences: ShareEstimateMailPreferencesModalState;
};

export const KANBAN_SHARE_ESTIMATE_TAB_EMPTY_STATE: KanbanShareEstimateTabState = {
  ...EMPTY_ESTIMATE_VIEW_STATE,
  showPrice: true,
  updatedPackageDealsInformations: [],
  notReferencedSparePartIdsFromAvailablePackageDeals: [],
  secondFactor: KANBAN_SHARE_SECOND_FACTOR_EMPTY_STATE,
  mailPreferences: SHARE_ESTIMATE_MAIL_PREFERENCES_MODAL_EMPTY_STATE,
};

export type KanbanShareDeliveryTabState = {
  readonly secondFactor: KanbanShareSecondFactorState;
};

export const KANBAN_SHARE_DELIVERY_TAB_EMPTY_STATE: KanbanShareDeliveryTabState = {
  secondFactor: KANBAN_SHARE_SECOND_FACTOR_EMPTY_STATE,
};

export type KanbanShareSparePartsTabState = {
  readonly expandedSectionIdsDispatch: readonly string[];
};

export const KANBAN_SHARE_SPARE_PARTS_TAB_EMPTY_STATE: KanbanShareSparePartsTabState = {
  expandedSectionIdsDispatch: [],
};

export type KanbanShareState = {
  readonly isInitialized: boolean;
  readonly selectedMenuItem: string;
  readonly estimateTab: KanbanShareEstimateTabState;
  readonly deliveryTab: KanbanShareDeliveryTabState;
  readonly availableAttachments?: readonly Attachment[];
  readonly sparePartsTab: KanbanShareSparePartsTabState;
};

export const KANBAN_SHARE_EMPTY_STATE: KanbanShareState = {
  isInitialized: false,
  estimateTab: KANBAN_SHARE_ESTIMATE_TAB_EMPTY_STATE,
  deliveryTab: KANBAN_SHARE_DELIVERY_TAB_EMPTY_STATE,
  selectedMenuItem: 'estimate',
  sparePartsTab: KANBAN_SHARE_SPARE_PARTS_TAB_EMPTY_STATE,
};

export type NavBarState = {
  readonly burgerIsActive: boolean;
};

export type ShareStoreState = BaseStoreState & {
  readonly context: ShareContext<'kanban', 'standard'>;
  readonly status: 'ok' | 'notfound' | 'expired';
  readonly navBar: NavBarState;
  readonly kanbanShare: KanbanShareState;
};

export interface ShareStoreActionContext extends BaseStoreActionContext {
  readonly keyValueStorage: KeyValueStorage;
  readonly httpClient: HTTPClient;
}

export type ShareStore = StoreDef<ShareStoreState, ShareStoreActionContext>;
