import type { BrowserHistory } from 'history';
import type { BrowserRouterProps } from 'react-router-dom';
import React from 'react';
import { Router } from 'react-router-dom';
import { Logger } from '@stimcar/libs-kernel';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

type Props = BrowserRouterProps & { readonly history: BrowserHistory };

/**
 * This router implementation allows to use a shared instance of the history object (instead
 * of relying on the base BrowserRouter implementation which instantiates the history object
 * internally).
 *
 * This allows to share (and use) the history object from within store actions (through the
 * action context) in order to keep logic in the store and keep components as clean as
 * possible.
 *
 * https://stackoverflow.com/questions/69871987/react-router-v6-navigate-outside-of-components/70000286
 */
export function BrowserRouterWithExternalizedHistory({ basename, history, ...props }: Props) {
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(setState), [history, setState]);

  return (
    <Router
      {...props}
      basename={basename}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
}
