import type { ActionContext } from '@stimcar/libs-uikernel';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import { isTruthyAndNotEmpty, Logger, nonnull } from '@stimcar/libs-kernel';
import type { SessionState } from '../../../registeredapp/state/typings/store.js';
import type { Store } from '../typings/store.js';
import { DEFAULT_AFTER_LOGOUT_FULL_PATHS_FOR_ROLES } from '../../coreConstants.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

const LOGOUT_TIMEOUT = 3000;

export const logoutAction = async ({
  actionDispatch,
  globalActionDispatch,
  httpClient,
  getState,
  kanbanRepository,
  carElementRepository,
  customerRepository,
  packageDealDescRepository,
}: ActionContext<Store, SessionState>): Promise<void> => {
  try {
    await Promise.all([
      kanbanRepository.waitForEmptyActionLog(LOGOUT_TIMEOUT),
      carElementRepository.waitForEmptyActionLog(LOGOUT_TIMEOUT),
      customerRepository.waitForEmptyActionLog(LOGOUT_TIMEOUT),
      packageDealDescRepository.waitForEmptyActionLog(LOGOUT_TIMEOUT),
    ]);
  } catch (e) {
    log.info(e);
    globalActionDispatch.scopeProperty('navbar').setProperty('showPossibleDataLossModal', true);
    return;
  }

  const { infos } = getState();
  await httpClient.logout();
  actionDispatch.setProperty('user', undefined);
  if (!isTruthyAndNotEmpty(localStorage.getItem(LocalStorageKeys.LAST_DISPLAY_PATH))) {
    if (isTruthyAndNotEmpty(infos?.role)) {
      const defaultPath = nonnull(
        DEFAULT_AFTER_LOGOUT_FULL_PATHS_FOR_ROLES[
          nonnull(infos).role as keyof typeof DEFAULT_AFTER_LOGOUT_FULL_PATHS_FOR_ROLES
        ]
      );
      localStorage.setItem(LocalStorageKeys.LAST_DISPLAY_PATH, defaultPath);
    }
  }
};
