import type { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { CarViewCategory } from '@stimcar/libs-base';

export const getCarViewCategoryLabel = (t: TFunction, category: CarViewCategory): string => {
  return t(`globals:operationCategories.${category}`);
};

export const useGetCarViewCategoryLabel = (category: CarViewCategory): string => {
  const [t] = useTranslation('globals');
  return getCarViewCategoryLabel(t, category);
};

export function getAllCarViewCategoryLabels(t: TFunction): Record<CarViewCategory, string> {
  return {
    BUMP: t('globals:operationCategories.BUMP'),
    EXTE: t('globals:operationCategories.EXTE'),
    INTE: t('globals:operationCategories.INTE'),
    MECA: t('globals:operationCategories.MECA'),
    MISC: t('globals:operationCategories.MISC'),
  };
}

export const useGetAllCarViewCategoryLabels = (): Record<CarViewCategory, string> => {
  const [t] = useTranslation('globals');
  return useMemo(() => getAllCarViewCategoryLabels(t), [t]);
};
