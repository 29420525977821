import type { TFunction } from 'i18next';
import type {
  CarViewCategory,
  PackageDealDesc,
  PackageDealVariableDefinition,
  Sequence,
  SparePart,
  SparePartDesc,
  SparePartManagementType,
} from '@stimcar/libs-base';
import type { ReadOnlyActionContext } from '@stimcar/libs-uikernel';
import { PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX } from '@stimcar/core-libs-repository';
import {
  EMPTY_SPARE_PART_MANAGED_BY_CUSTOMER,
  EMPTY_SPARE_PART_MANAGED_BY_US as EMPTY_SPARE_PART_MANAGED_BY_STIMCAR,
  EMPTY_SPARE_PART_ORDERED_BY_STIMCAR_FROM_CUSTOMERS_CATALOG,
  packageDealDescHelpers,
} from '@stimcar/libs-base';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import type { Store } from '../../state/typings/store.js';
import type { AdminPackageDealDescsState, SparePartFormData } from './typings/store.js';

export function createSparePartFormDataFromSparePartDesc(
  spd: SparePartDesc,
  sequence: Sequence
): SparePartFormData {
  return {
    id: sequence.next(),
    label: spd.label,
    editMode: false,
    deleted: spd.deleted ?? false,
  };
}

export function convertSparePartFormDataToSparePart(
  formData: SparePartFormData,
  sparePartManagementType: SparePartManagementType
): SparePart {
  let emptySparePart: SparePart = EMPTY_SPARE_PART_MANAGED_BY_STIMCAR;
  if (sparePartManagementType === 'fullyManagedByStimcar') {
    emptySparePart = EMPTY_SPARE_PART_MANAGED_BY_STIMCAR;
  } else if (sparePartManagementType === 'fullyManagedByCustomer') {
    emptySparePart = EMPTY_SPARE_PART_MANAGED_BY_CUSTOMER;
  } else if (sparePartManagementType === 'orderedByStimcarFromCustomersCatalog') {
    emptySparePart = EMPTY_SPARE_PART_ORDERED_BY_STIMCAR_FROM_CUSTOMERS_CATALOG;
  }
  return {
    ...emptySparePart,
    id: formData.id,
    label: isTruthyAndNotEmpty(formData.label) ? formData.label : '',
    deleted: formData.deleted,
  };
}

export function validateExpression(
  t: TFunction,
  declaration: string,
  localVariables: Record<string, PackageDealVariableDefinition | null>,
  tags: readonly string[]
): string | undefined {
  try {
    const result = packageDealDescHelpers.validatePackageDealRelatedExpressionDeclaration(
      declaration,
      localVariables,
      tags
    );
    switch (result.code) {
      case 'numberInputWithCommaSeparator':
        return t('packageDealDescModal.form.warnings.numberInputWithCommaSeparator');
      case 'forbiddenStatement':
        return t('packageDealDescModal.form.warnings.forbiddenStatementInExpression', {
          variables: result.value,
        });
      case 'unknownVariables':
        return t('packageDealDescModal.form.warnings.unknownVariablesInExpression', {
          variables: result.value,
        });
      case 'incorrectCondition':
        return t('packageDealDescModal.form.warnings.incorrectConditionInExpression', {
          condition: result.value,
        });
      case 'incorrectExpression':
        return t('packageDealDescModal.form.warnings.incorrectMemberInExpression', {
          member: result.value,
        });
      case 'missingDefaultCase':
        return t('packageDealDescModal.form.warnings.missingDefaultCase');
      case 'empty':
        if (isTruthyAndNotEmpty(result.value)) {
          return t('packageDealDescModal.form.warnings.emptyExpressionWithConditionValue', {
            value: result.value,
          });
        }
        return t('packageDealDescModal.form.warnings.emptyExpression');
      case 'ok':
        return undefined;
      default:
        return t('packageDealDescModal.form.warnings.unknownError');
    }
  } catch {
    return t('packageDealDescModal.form.warnings.incorrectExpressionSyntax');
  }
}

export const packageDealDescTableContentProvider = async ({
  packageDealDescRepository,
  getState,
}: ReadOnlyActionContext<Store, AdminPackageDealDescsState>): Promise<
  readonly PackageDealDesc[]
> => {
  const { selectedPackageDealDatabase, searchFilter, carElements } = getState();
  const { category, shapeId } = searchFilter;
  // Filter by contract...
  const packageDeals = await packageDealDescRepository.getEntitiesFromIndex(
    PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX,
    selectedPackageDealDatabase
  );

  // Filter by category...
  const packageDealDescsByStand = isTruthyAndNotEmpty(category)
    ? packageDeals.filter(
        (pdd): boolean =>
          (category !== 'MISC' &&
            packageDealDescHelpers.hasPackageDealAnyCarElementIncludedInCategory(
              pdd,
              carElements,
              category as CarViewCategory
            )) ||
          pdd.carElementIds.length === 0
      )
    : packageDeals;

  // Filter by shape...
  let packageDealDescsByShape = packageDealDescsByStand;
  if (isTruthyAndNotEmpty(shapeId)) {
    const idsOfCarElementsThatContainsSelectedShape = carElements
      .filter((e): boolean => e.shapes.includes(shapeId))
      .map((e): string => e.id);
    packageDealDescsByShape = packageDealDescsByStand.filter((o): boolean => {
      let shapeIdFound = false;
      o.carElementIds.forEach((id): void => {
        shapeIdFound = shapeIdFound || idsOfCarElementsThatContainsSelectedShape.includes(id);
      });
      return shapeIdFound;
    });
  }

  return packageDealDescsByShape;
};
