type Listener<K extends keyof DocumentEventMap> = (ev: DocumentEventMap[K]) => void;

type ListenersMap = {
  [K in keyof Partial<DocumentEventMap>]: readonly Listener<K>[];
};

/**
 * This wrapper helps to ensure that the last registered DOM event listeners is executed
 * at first.
 *
 * By default, registering listeners on 'document' itself leads to listeners executed in
 * the registration order which can be a problem.
 *
 * Example : imagine you have a popup from which a sub-popup can be opened. Imagine we bind
 * a key event listener that closes the popup when "escape" is pressed. In such situation the
 * first listener to be executed is the one of the parent dialog. As a result the sub popup
 * remains open and the parent dialog is closed.
 *
 * By reversing listeners order, we manage to close the sub popup at first.
 */
class DocumentEventListenerWrapper {
  private readonly listeners: ListenersMap = {};

  public addEventListener<K extends keyof DocumentEventMap>(type: K, listener: Listener<K>): void {
    // Unregister existing listeners
    if (this.listeners[type] !== undefined) {
      this.listeners[type].forEach((listener) => document.removeEventListener(type, listener));
      // Append new listener (in first position)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.listeners[type] = [listener, ...this.listeners[type]] as any;
    } else {
      // Append new listener (in first position)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.listeners[type] = [listener] as any;
    }
    // Register all listeners (last registered is first registered)
    this.listeners[type]?.forEach((listener) => document.addEventListener(type, listener, false));
  }

  public removeEventListener<K extends keyof DocumentEventMap>(
    type: K,
    listener: Listener<K>
  ): void {
    if (this.listeners[type] !== undefined) {
      // Unregister existing listeners
      this.listeners[type].forEach((listener) => document.removeEventListener(type, listener));
      // Remove listener
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.listeners[type] = this.listeners[type].filter((l) => l !== listener) as any;
      // Register all listeners (last registered is first registered)
      this.listeners[type]?.forEach((listener) =>
        document.addEventListener(type, listener, { capture: true })
      );
    }
  }
}

export const documentEventListenerWrapper = new DocumentEventListenerWrapper();
