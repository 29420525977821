import type { JSX } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { Draggable } from '@hello-pangea/dnd';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../../../../app/state/typings/store.js';
import type { InternalOperationsDispatchData } from '../typings/store.js';
import { ColorBoxSpan } from '../../../bulma/elements/ColorBoxSpan.js';

interface OperationOnStandListProps {
  readonly operationDatas: Record<string, readonly InternalOperationsDispatchData[]>;
  readonly qualifiedPostId: string;
  readonly colorInfos: Map<string, string>;
  readonly $: StoreStateSelector<Store, readonly string[]>;
}

export function OperationOnStandList({
  operationDatas,
  qualifiedPostId,
  colorInfos,
  $,
}: OperationOnStandListProps): JSX.Element {
  const [t] = useTranslation('libComponents');

  const operations = useMemo(() => {
    return operationDatas[qualifiedPostId];
  }, [operationDatas, qualifiedPostId]);

  const selectedOperations = useGetState($);

  const isDragDisabled = selectedOperations.length > 0;

  const onSelectCheckboxChangeCallback = useActionCallback(
    ({ actionDispatch, getState }, operationId: string) => {
      const initiallySelectedOperations = getState();
      const index = initiallySelectedOperations.indexOf(operationId);
      if (index > -1) {
        // Operation is checked, we need to remove it
        actionDispatch.setValue(
          initiallySelectedOperations.filter((anOperationId) => anOperationId !== operationId)
        );
      } else {
        // operation is not checked, we have to add it
        actionDispatch.setValue([...initiallySelectedOperations, operationId]);
      }
    },
    [],
    $
  );

  if (operations.length === 0) {
    return <p>{t('operationsDispatch.noContent')}</p>;
  }

  return (
    <>
      {operations.map((od, ind): JSX.Element => {
        const isOperationChecked =
          selectedOperations && selectedOperations.includes(od.operationId);
        return (
          <OperationOnStandComponent
            key={od.operationId}
            operationData={od}
            elementIndex={ind}
            colorInfo={colorInfos}
            isDragDisabled={isDragDisabled}
            isOperationChecked={isOperationChecked}
            toggleOperationCheckboxCallback={onSelectCheckboxChangeCallback}
          />
        );
      })}
    </>
  );
}

interface OperationOnStandProps {
  readonly operationData: InternalOperationsDispatchData;
  readonly elementIndex: number;
  readonly colorInfo: Map<string, string>;
  readonly isDragDisabled: boolean;
  readonly isOperationChecked: boolean;
  readonly toggleOperationCheckboxCallback: (operationId: string) => Promise<void>;
}

function OperationOnStandComponent({
  operationData,
  elementIndex,
  colorInfo,
  isOperationChecked,
  isDragDisabled,
  toggleOperationCheckboxCallback,
}: OperationOnStandProps): JSX.Element {
  const [t] = useTranslation('libComponents');

  const operationLabel = useMemo((): string => {
    if (operationData.carElementLabel) {
      return t('operationsDispatch.operationLabelWithCarElement', {
        operation: operationData.operationLabel,
        workload: operationData.operationWorkload,
        carElement: operationData.carElementLabel,
      });
    }

    return t('operationsDispatch.operationLabelWithoutCarElements', {
      operation: operationData.operationLabel,
      workload: operationData.operationWorkload,
    });
  }, [
    operationData.operationLabel,
    operationData.operationWorkload,
    operationData.carElementLabel,
    t,
  ]);

  const { operationId } = operationData;

  const computeColor = (): string => {
    return colorInfo.get(operationId) ?? 'grey';
  };

  const toggleCheckboxCallback = useCallback(async (): Promise<void> => {
    await toggleOperationCheckboxCallback(operationId);
  }, [operationId, toggleOperationCheckboxCallback]);

  return (
    <Draggable
      draggableId={operationData.operationId}
      index={elementIndex}
      isDragDisabled={isDragDisabled}
    >
      {(provided): JSX.Element => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          // Beautiful-react-dnd API returns an any
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref={(e): any => provided.innerRef(e)}
          className="is-display-flex is-align-items-center"
        >
          <input
            type="checkbox"
            className="m-r-sm"
            onChange={toggleCheckboxCallback}
            checked={isOperationChecked}
          />
          <p title={operationLabel}>
            <ColorBoxSpan
              height="1em"
              width="1em"
              colorOrCssClass={computeColor()}
              marginTop="4px"
            />
            <span className="p-l-xs">{operationLabel}</span>
          </p>
        </div>
      )}
    </Draggable>
  );
}
