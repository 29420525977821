import type { ChangeEvent, ReactElement, ReactNode } from 'react';
import React, { cloneElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { CarViewCategory, PackageDeal } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { packageDealHelpers } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Label } from '@stimcar/libs-uitoolkit';
import type { CarViewProps } from '../../../lib/components/carviews/car-view-common.js';
import type { Store } from '../../state/typings/store.js';
import type { QualityControlState } from './typings/store.js';
import { QualityControlPackageDealDisplay } from './QualityControlPackageDealDisplay.js';
import { EMPTY_DISPLAY_ALL_PACKAGE_DEALS_FOR_CATEGORY } from './typings/store.js';
import {
  getPackageDealsToDisplayFromSelectedShape,
  getShapesWithRemainingChecks,
} from './utils/qualityControlUtils.js';

function onShapeClickedAction(
  { actionDispatch }: ActionContext<Store, QualityControlState>,
  shapeId: string,
  selectedShapeId: string | undefined,
  category: CarViewCategory
) {
  let shape;
  if (shapeId !== selectedShapeId) {
    shape = { id: shapeId, category };
  }
  actionDispatch.setProperty('selectedShape', shape);
  actionDispatch.setProperty(
    'displayAllPackageDealsForCategory',
    EMPTY_DISPLAY_ALL_PACKAGE_DEALS_FOR_CATEGORY
  );
}

function onAllShapesSelectedChangeAction(
  { actionDispatch }: ActionContext<Store, QualityControlState>,
  category: CarViewCategory,
  allShapesSelected: boolean
) {
  actionDispatch.setProperty('selectedShape', undefined);
  actionDispatch.setProperty('displayAllPackageDealsForCategory', {
    category,
    displayAllPackagedeals: allShapesSelected,
  });
}

interface CarViewWrapperProps extends AppProps<Store> {
  readonly kanbanId: string;
  readonly category: CarViewCategory;
  readonly packageDeals?: readonly PackageDeal[];
  readonly children: ReactElement<CarViewProps<Store>>;
  readonly $: StoreStateSelector<Store, QualityControlState>;
}

export function CarViewWrapper({
  $,
  $gs,
  category,
  children,
  kanbanId,
  packageDeals = [],
}: CarViewWrapperProps): ReactNode {
  const [t] = useTranslation('workshop');

  const qualityControlSteps = useGetState($.$steps);

  const shapesWithRemainingChecks = useMemo(
    () => getShapesWithRemainingChecks(packageDeals, qualityControlSteps),
    [packageDeals, qualityControlSteps]
  );

  const selectedShape = useGetState($.$selectedShape);

  const onShapeClickedActionCallback = useActionCallback(
    async ({ actionDispatch }, id: string) => {
      await actionDispatch.exec(onShapeClickedAction, id, selectedShape?.id, category);
    },
    [selectedShape?.id, category],
    $
  );

  const selectedShapes = useMemo(
    () =>
      isTruthy(selectedShape) && selectedShape.category === category ? [selectedShape.id] : [],
    [category, selectedShape]
  );

  const displayAllPackageDealsForCategory = useGetState($.$displayAllPackageDealsForCategory);

  const displayAllPackagesDeals = useMemo(
    () =>
      displayAllPackageDealsForCategory.category === category &&
      displayAllPackageDealsForCategory.displayAllPackagedeals,
    [
      category,
      displayAllPackageDealsForCategory.category,
      displayAllPackageDealsForCategory.displayAllPackagedeals,
    ]
  );

  const packageDealsToDisplay = useMemo(
    () =>
      getPackageDealsToDisplayFromSelectedShape(
        displayAllPackagesDeals,
        selectedShapes,
        packageDeals
      ),
    [displayAllPackagesDeals, packageDeals, selectedShapes]
  );

  const availablePackageDealsToDisplay = useMemo(
    () => packageDealHelpers.getAvailablePackageDeals(packageDealsToDisplay),
    [packageDealsToDisplay]
  );

  const canceledPackageDealsToDisplay = useMemo(
    () => packageDealHelpers.getCanceledPackageDeals(packageDealsToDisplay),
    [packageDealsToDisplay]
  );

  const onAllShapesSelectedChangeActionCallback = useActionCallback(
    async ({ actionDispatch }, event: ChangeEvent<HTMLInputElement>) => {
      await actionDispatch.exec(onAllShapesSelectedChangeAction, category, event.target.checked);
    },
    [category],
    $
  );

  return (
    <div className="mb-5">
      <div className="is-flex is-justify-content-space-between is-align-items-center mb-1">
        <h1 className="title is-4 mb-0">{t(`globals:operationCategories.${category}`)}</h1>
        {packageDeals.length > 0 ? (
          <Label className="checkbox">
            <input
              type="checkbox"
              className="m-r-sm"
              checked={displayAllPackagesDeals}
              onChange={onAllShapesSelectedChangeActionCallback}
            />
            {t('qualityControl.carView.allPackageDeals')}
          </Label>
        ) : (
          <p>{t('qualityControl.carView.noPackageDeal')}</p>
        )}
      </div>
      {cloneElement(children, {
        selectedShapes,
        selectedShapesColor: 'gold',
        highlightedShapes: shapesWithRemainingChecks,
        shapeClicked: onShapeClickedActionCallback as (id: string) => void,
      })}
      {availablePackageDealsToDisplay.length > 0 && (
        <div>
          <h2 className="title is-6 mt-0 mb-1">
            {t('qualityControl.carView.availablePackageDeals')}
          </h2>
          {availablePackageDealsToDisplay.map((packageDeal) => (
            <QualityControlPackageDealDisplay
              $={$}
              $gs={$gs}
              kanbanId={kanbanId}
              key={packageDeal.id}
              packageDeal={packageDeal}
            />
          ))}
        </div>
      )}
      {canceledPackageDealsToDisplay.length > 0 && (
        <div>
          <h2 className="title is-6 mt-0 mb-1">
            {t('qualityControl.carView.canceledPackageDeals')}
          </h2>
          {canceledPackageDealsToDisplay.map((packageDeal) => (
            <QualityControlPackageDealDisplay
              $={$}
              $gs={$gs}
              kanbanId={kanbanId}
              key={packageDeal.id}
              packageDeal={packageDeal}
            />
          ))}
        </div>
      )}
    </div>
  );
}
