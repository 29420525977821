import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { StoreStateSelector, WithFormValidationWarnings } from '@stimcar/libs-uikernel';
import type { AppProps, HorizontalFormFieldProps } from '@stimcar/libs-uitoolkit';
import { InputFormField, ReactSelectFormField } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { VOLeanConnectorArgsData } from '../typings/store.js';
import { CheckboxFormField } from '../../../../lib/bulma/form/CheckboxFormField.js';
import { useGetContractCodes } from '../../../utils/useGetContract.js';
import { MailTypedScheduledTaskInputs } from './MailTypedScheduledTaskInputs.js';

export interface ImportVOLeanInputsProps extends AppProps<Store> {
  readonly $formData: StoreStateSelector<
    Store,
    WithFormValidationWarnings<VOLeanConnectorArgsData>
  >;
  readonly allWorkflowIds: readonly string[];
  readonly horizontalFormFields?: boolean | HorizontalFormFieldProps;
}
export function ImportVOLeanInputs({
  $formData,
  $gs,
  allWorkflowIds,
  horizontalFormFields,
}: ImportVOLeanInputsProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');

  const allContracts = useGetContractCodes($gs);

  return (
    <>
      <ReactSelectFormField
        label={t('argumentsInputs.contractCode')}
        suggestions={allContracts}
        $={$formData.$contractCode}
        creation={false}
        horizontal={horizontalFormFields}
      />
      <ReactSelectFormField
        label={t('argumentsInputs.workflowId')}
        suggestions={allWorkflowIds}
        $={$formData.$workflowId}
        creation={false}
        horizontal={horizontalFormFields}
      />
      <InputFormField
        label={t('argumentsInputs.voLeanClientId')}
        $={$formData.$voLeanClientId}
        horizontal={horizontalFormFields}
      />
      <InputFormField
        label={t('argumentsInputs.voLeanPole')}
        $={$formData.$voLeanPole}
        horizontal={horizontalFormFields}
      />
      <CheckboxFormField
        label={t('argumentsInputs.enablePkgDealsModifications')}
        $={$formData.$enablePkgDealsModifications}
        horizontal={horizontalFormFields}
      />
      <CheckboxFormField
        label={t('argumentsInputs.qualityControlInVOLean')}
        $={$formData.$qualityControlInVOLean}
        horizontal={horizontalFormFields}
      />
      <MailTypedScheduledTaskInputs
        $formData={$formData}
        horizontalFormFields={horizontalFormFields}
      />
    </>
  );
}
