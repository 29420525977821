/* eslint-disable jsx-a11y/control-has-associated-label */
import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { FormFieldEntry } from '@stimcar/libs-uitoolkit';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { FaIcon, ListSelect } from '@stimcar/libs-uitoolkit';

function setStringArrayValueAction<SD extends AnyStoreDef>(
  { actionDispatch }: ActionContext<SD, readonly string[]>,
  value: readonly string[]
) {
  actionDispatch.setValue(value);
}

export interface MultiListSelectProps<SD extends AnyStoreDef> {
  readonly entries: readonly FormFieldEntry<string>[];
  readonly $selection: StoreStateSelector<SD, readonly string[]>;
  readonly $preSelection: StoreStateSelector<SD, readonly string[]>;
  readonly $preUnselect: StoreStateSelector<SD, readonly string[]>;
  readonly sortingFunction?: (a: FormFieldEntry<string>, b: FormFieldEntry<string>) => number;
}

export function MultiListSelect<SD extends AnyStoreDef>({
  $selection,
  $preSelection,
  $preUnselect,
  sortingFunction,
  entries,
}: MultiListSelectProps<SD>): JSX.Element {
  const [t] = useTranslation('refititCommonComponents');

  const selected = useGetState($selection);
  const preSelected = useGetState($preSelection);
  const preUnselected = useGetState($preUnselect);

  const setSelectedActionCallback = useActionCallback(setStringArrayValueAction, [], $selection);
  const setPreSelectedActionCallback = useActionCallback(
    setStringArrayValueAction,
    [],
    $preSelection
  );
  const setPreUnelectedActionCallback = useActionCallback(
    setStringArrayValueAction,
    [],
    $preUnselect
  );

  const selectAllActionCallback = useActionCallback(
    async function selectAllAction(
      { actionDispatch },
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ): Promise<void> {
      event.preventDefault();
      await actionDispatch.execCallback(setPreSelectedActionCallback, []);
      await actionDispatch.execCallback(
        setSelectedActionCallback,
        [...entries].map((e): string => e.id)
      );
    },
    [entries, setPreSelectedActionCallback, setSelectedActionCallback],
    $selection
  );

  const selectPreSelectedActionCallback = useActionCallback(
    async function selectPreSelectedAction(
      { actionDispatch, getState },
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ): Promise<void> {
      event.preventDefault();
      await actionDispatch.execCallback(setSelectedActionCallback, [...selected, ...getState()]);
      await actionDispatch.execCallback(setPreSelectedActionCallback, []);
    },
    [selected, setPreSelectedActionCallback, setSelectedActionCallback],
    $preSelection
  );

  const unselectPreUnselectedActionCallback = useActionCallback(
    async function unselectPreUnselectedAction(
      { actionDispatch, getState },
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ): Promise<void> {
      event.preventDefault();
      await actionDispatch.execCallback(
        setSelectedActionCallback,
        getState().filter((id): boolean => !preUnselected.includes(id))
      );
      await actionDispatch.execCallback(setPreUnelectedActionCallback, []);
    },
    [preUnselected, setPreUnelectedActionCallback, setSelectedActionCallback],
    $selection
  );

  const deselectAllActionCallback = useActionCallback(
    async function deselectAllAction(
      { actionDispatch },
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ): Promise<void> {
      event.preventDefault();
      await actionDispatch.execCallback(setPreUnelectedActionCallback, []);
      await actionDispatch.execCallback(setSelectedActionCallback, []);
    },
    [setPreUnelectedActionCallback, setSelectedActionCallback],
    $selection
  );

  const remainingAvailableEntries = useMemo((): FormFieldEntry<string>[] => {
    return entries.filter((e): boolean => {
      return !selected.includes(e.id);
    });
  }, [entries, selected]);

  const selectedElements = useMemo((): FormFieldEntry<string>[] => {
    return entries.filter((e): boolean => {
      return selected.includes(e.id);
    });
  }, [entries, selected]);

  /* eslint-disable jsx-a11y/anchor-is-valid */
  /* @ts-ignore */
  return (
    <table className="is-fullwidth no-padding">
      <tbody>
        <tr>
          <td style={{ width: '48%', padding: 0 }}>
            <ListSelect
              entries={remainingAvailableEntries}
              height={300}
              $={$preSelection}
              sortingFunction={sortingFunction}
            />
          </td>
          <td style={{ width: '4%', textAlign: 'center', padding: 0 }}>
            <a
              className="button"
              role="button"
              href="#"
              onClick={selectAllActionCallback}
              title={t('multilistSelection.addAllButton')}
              {...{ disabled: remainingAvailableEntries.length === 0 }}
            >
              <FaIcon id="angle-double-right" tooltip={t('multilistSelection.addAllButton')} />
            </a>
            <a
              className="button"
              role="button"
              href="#"
              onClick={selectPreSelectedActionCallback}
              title={t('multilistSelection.addButton')}
              {...{ disabled: preSelected.length === 0 }}
            >
              <FaIcon id="angle-right" tooltip={t('multilistSelection.addButton')} />
            </a>
            <a
              className="button"
              role="button"
              href="#"
              onClick={unselectPreUnselectedActionCallback}
              title={t('multilistSelection.removeButton')}
              {...{ disabled: preUnselected.length === 0 }}
            >
              <FaIcon id="angle-left" tooltip={t('multilistSelection.removeButton')} />
            </a>
            <a
              className="button"
              role="button"
              href="#"
              onClick={deselectAllActionCallback}
              title={t('multilistSelection.removeAllButton')}
              {...{ disabled: selected.length === 0 }}
            >
              <FaIcon id="angle-double-left" tooltip={t('multilistSelection.addAllButton')} />
            </a>
          </td>
          <td style={{ width: '48%', padding: 0 }}>
            <ListSelect
              entries={selectedElements}
              height={300}
              $={$preUnselect}
              sortingFunction={sortingFunction}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}
