import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { DeepPartial, KeyValueStorage } from '@stimcar/libs-kernel';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import { globalHelpers } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type {
  ShareEstimateMailPreferencesModalState,
  SharePreferencesType,
  ShareStore,
} from '../state/typings/store.js';
import { ReactSelectMultiFormField } from '../../lib/bulma/form/custom/ReactSelectMultiFormField.js';
import {
  SHARE_ESTIMATE_MAIL_PREFERENCES_MODAL_EMPTY_STATE,
  SHARE_PREFERENCE_EMPTY_VALUE,
} from '../state/typings/store.js';

export function updateSharePreferences(
  keyValueStorage: KeyValueStorage,
  value: DeepPartial<SharePreferencesType>
): void {
  const preferences =
    keyValueStorage.getObjectItem<SharePreferencesType>(LocalStorageKeys.SHARE_PREFERENCES) ??
    SHARE_PREFERENCE_EMPTY_VALUE;

  keyValueStorage.setObjectItem<SharePreferencesType>(LocalStorageKeys.SHARE_PREFERENCES, {
    email: isTruthyAndNotEmpty(value.email) ? value.email : preferences.email,
    estimate: {
      ccEmails: isTruthy(value.estimate?.ccEmails)
        ? nonnull(value.estimate?.ccEmails)
        : preferences.estimate.ccEmails,
      showPrice: isTruthy(value.estimate?.showPrice)
        ? nonnull(value.estimate?.showPrice)
        : preferences.estimate.showPrice,
    },
  });
}

interface Props {
  readonly $: StoreStateSelector<ShareStore, ShareEstimateMailPreferencesModalState>;
}

export function EstimateMailPreferencesModal({ $ }: Props): JSX.Element {
  const [t] = useTranslation('share');

  const savePreferencesAndCloseModaleModalCallback = useActionCallback(
    ({ getState, actionDispatch, keyValueStorage }) => {
      const { ccEmails } = getState();
      updateSharePreferences(keyValueStorage, { estimate: { ccEmails } });

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      actionDispatch.reduce((initial) => {
        return SHARE_ESTIMATE_MAIL_PREFERENCES_MODAL_EMPTY_STATE;
      });
    },
    [],
    $
  );

  return (
    <ModalCardDialog
      title={t('estimateTab.mailPreferencesModal.title')}
      zIndex={50}
      $active={$.$isActive}
      onOkClicked={savePreferencesAndCloseModaleModalCallback}
    >
      <ReactSelectMultiFormField
        label={t('estimateTab.mailPreferencesModal.ccEmailsLabel')}
        $={$.$ccEmails}
        hideDropdownIndicator
        creation={{
          validationFunction: (value: string): string | undefined => {
            if (globalHelpers.isValidEmailAddressStructure(value)) {
              return undefined;
            }
            return t('incorrectEmail');
          },
        }}
      />
    </ModalCardDialog>
  );
}
