import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { HorizontalFormFieldProps } from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes, STIMCAR_FIRM } from '@stimcar/libs-base';
import { asyncForEach, nonnull } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import { ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { ChoiceButtonDef } from '../../../lib/bulma/form/custom/ChoiceAddOnButtons.js';
import type { Store } from '../../state/typings/store.js';
import { ChoiceAddOnButtonsFormField } from '../../../lib/bulma/form/custom/ChoiceAddOnButtonsFormField.js';
import { RadioButtonsFormField } from '../../../lib/bulma/form/RadioButtonsFormField.js';
import { appendRegisteredBrowserSessionToken } from '../security.js';
import type { ToggleOperationActionCallback } from './AdditionalStepsForOperationCompletionModal.js';
import type {
  SelectSubcontractorFirmForm,
  SelectSubcontractorFirmModalState,
} from './typings/store.js';
import { EMPTY_SELECT_SUBCONTRACTOR_FIRM_MODAL_STATE } from './typings/store.js';

export const STIMCAR_FAKE_LOGIN = '$STIMCAR$';

function mandatoryFieldsProvider({
  realizedBy,
}: SelectSubcontractorFirmForm): readonly (keyof SelectSubcontractorFirmForm)[] {
  if (realizedBy === STIMCAR_FIRM) {
    return [];
  }
  return ['subcontractor'];
}

const HORIZONTAL_PROPS: HorizontalFormFieldProps = {
  labelFlexGrow: 1,
  bodyFlexGrow: 3,
};

export type SubcontractableInfo =
  | {
      readonly isSubcontracted: true;
      readonly subcontractor: string;
    }
  | {
      readonly isSubcontracted: false;
    };

interface Props {
  readonly $: StoreStateSelector<Store, SelectSubcontractorFirmModalState>;
  readonly toggleOperationActionCallback: ToggleOperationActionCallback;
}

export function SelectSubcontractorFirmModalDialog({
  $,
  toggleOperationActionCallback,
}: Props): JSX.Element {
  const [t] = useTranslation('operators');

  const submitValidDataActionCallback = useActionCallback(
    async function submitValidDataAction({ actionDispatch, getState }) {
      const { formData, operationId, documentsInfo } = getState();
      const { realizedBy, subcontractor } = formData;
      await actionDispatch.execCallback(
        toggleOperationActionCallback,
        operationId,
        realizedBy === STIMCAR_FIRM
          ? { isSubcontracted: false }
          : { isSubcontracted: true, subcontractor },
        documentsInfo?.documentsFileNames
      );
      actionDispatch.setProperty('active', false);
    },
    [toggleOperationActionCallback],
    $
  );

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    SelectSubcontractorFirmModalState
  >({
    $,
    mandatoryFields: mandatoryFieldsProvider,
    submitValidDataAction: submitValidDataActionCallback,
    t,
  });

  const realizedByOptions = useMemo((): readonly ChoiceButtonDef[] => {
    return [STIMCAR_FIRM, 'subcontractor'].map((id) => ({
      id,
      label: t(`selectSubcontractorFirmModal.form.realizedByOptions.${id}`),
    }));
  }, [t]);

  const realizedBy = useGetState($formDataWithChangeTrigger.$realizedBy);

  const subcontractors = useGetState($.$subcontractors);

  const clearSubcontractorActionCallback = useActionCallback(
    ({ actionDispatch, getState }) => {
      if (getState().realizedBy === STIMCAR_FIRM) {
        actionDispatch.setProperty('subcontractor', '');
      }
    },
    [],
    $formDataWithChangeTrigger
  );

  const $realizedByWithActionTrigger = useSelectorWithChangeTrigger(
    $formDataWithChangeTrigger.$realizedBy,
    clearSubcontractorActionCallback
  );

  const closeModalActionCallback = useActionCallback(
    async ({ actionDispatch, httpClient, getState, getGlobalState }): Promise<void> => {
      const { documentsInfo, kanbanId } = getState();
      if (documentsInfo && kanbanId) {
        await asyncForEach(documentsInfo.documentsFileNames, async (fileName) => {
          await httpClient.httpGet(
            appendRegisteredBrowserSessionToken(
              CoreBackendRoutes.ATTACHMENT(
                'kanban',
                kanbanId,
                documentsInfo.targetFolder,
                fileName
              ),
              nonnull(getGlobalState().session.infos).sessionToken
            ),
            'DELETE'
          );
        });
      }

      actionDispatch.setValue(EMPTY_SELECT_SUBCONTRACTOR_FIRM_MODAL_STATE);
    },
    [],
    $
  );

  return (
    <ModalCardDialog
      $active={$.$active}
      onOkClicked={onFormSubmit}
      onCancelClicked={closeModalActionCallback}
      title={t('selectSubcontractorFirmModal.title')}
    >
      <p>{t('selectSubcontractorFirmModal.rationale')}</p>
      <ChoiceAddOnButtonsFormField
        horizontal={HORIZONTAL_PROPS}
        buttonsDesc={realizedByOptions}
        $={$realizedByWithActionTrigger}
        label={t('selectSubcontractorFirmModal.form.realizedBy')}
      />
      <RadioButtonsFormField
        id="login"
        entries={subcontractors}
        radioGroupLayout="vertical"
        horizontal={HORIZONTAL_PROPS}
        disabled={realizedBy === STIMCAR_FIRM}
        $={$formDataWithChangeTrigger.$subcontractor}
        label={t('selectSubcontractorFirmModal.form.subcontractor')}
      />
    </ModalCardDialog>
  );
}
