import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { CarElement, CoreFields } from '@stimcar/libs-base';

export function useGetCarElementLabel(carElement: CoreFields<CarElement> | undefined): string {
  const [t] = useTranslation();
  return useMemo(() => {
    return carElement?.label ?? t('globals:operationCategories.MISC');
  }, [carElement, t]);
}
