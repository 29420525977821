export function getHttpStatusCode(error: Error): number | undefined {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errorAsAny = error as any;
  let statusCode: number | undefined;
  // Try status code getter
  if (typeof errorAsAny.getStatusCode === 'function') {
    statusCode = errorAsAny.getStatusCode();
  }
  if (!statusCode) {
    // Try status code property
    statusCode = errorAsAny.status;
  }
  return statusCode;
}
