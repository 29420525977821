import type { JSX } from 'react';
import React from 'react';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useStateMatches } from '@stimcar/libs-uikernel';

interface Props<SD extends AnyStoreDef> {
  readonly $: StoreStateSelector<SD, boolean>;
  readonly children: JSX.Element;
}

export function IsTrue<SD extends AnyStoreDef>({ children, $ }: Props<SD>): JSX.Element {
  const isTrue = useStateMatches($, true);
  return <>{isTrue && children}</>;
}
