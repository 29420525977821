import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import React from 'react';
import { globalHelpers, shortDayMonthYearHourFormatOptions } from '@stimcar/libs-base';
import { FaIcon } from '@stimcar/libs-uitoolkit';
import type { SparePartDetailStatus } from './typings/store.js';

interface SparePartStatusIconProps {
  readonly status: SparePartDetailStatus;
  readonly dateOfOrder: number | null;
  readonly dateOfReception: number | null;
  readonly t: TFunction;
}

export function SparePartStatusIcon({
  status,
  dateOfOrder,
  dateOfReception,
  t,
}: SparePartStatusIconProps): JSX.Element {
  switch (status) {
    case 'referenced':
      return <FaIcon id="search-plus" tooltip={t('tabs.spareParts.referencedSPTooltip')} />;
    case 'received':
      return (
        <FaIcon
          id="check-double"
          tooltip={t('tabs.spareParts.receivedSPTooltip', {
            orderedDisplayedDate: globalHelpers.convertTimestampToDateString(
              dateOfOrder,
              shortDayMonthYearHourFormatOptions
            ),
            receivedDisplayedDate: globalHelpers.convertTimestampToDateString(
              dateOfReception,
              shortDayMonthYearHourFormatOptions
            ),
          })}
        />
      );
    case 'ordered':
      return (
        <FaIcon
          id="check"
          tooltip={t('tabs.spareParts.orderedSPTooltip', {
            orderedDisplayedDate: globalHelpers.convertTimestampToDateString(
              dateOfOrder,
              shortDayMonthYearHourFormatOptions
            ),
          })}
        />
      );
    default:
      return <></>;
  }
}
