import type { CarViewCategory, PackageDeal } from '@stimcar/libs-base';
import { groupBy, nonDeleted, packageDealHelpers } from '@stimcar/libs-base';
import { isTruthy, nonnull } from '@stimcar/libs-kernel';
import type { QualityControlStep } from '../typings/store.js';

export function getQualityControlStepsFromPackageDeals(
  packageDeals: readonly PackageDeal[]
): readonly QualityControlStep[] {
  return packageDealHelpers
    .getAvailablePackageDeals(packageDeals)
    .filter(({ carElement }) => isTruthy(carElement?.category))
    .map(({ id }): QualityControlStep => ({ id, check: 'ND', files: [] }));
}

export function getPackageDealsByCarElementCategory(
  packageDeals: readonly PackageDeal[]
): Record<CarViewCategory, readonly PackageDeal[]> {
  return groupBy(
    packageDeals.filter(nonDeleted).filter(({ carElement }) => isTruthy(carElement?.category)),
    ({ carElement }) => nonnull(carElement).category
  );
}

export function getShapesWithRemainingChecks(
  packageDeals: readonly PackageDeal[],
  qualityControlSteps: readonly QualityControlStep[]
) {
  const shapesWithPackageDealsIds = packageDeals.flatMap(
    ({ id, carElement }) =>
      carElement?.shapes.map((shape) => ({
        shape,
        packageDealId: id,
      })) ?? []
  );
  const packageDealIdsGroupedByShapes = groupBy(shapesWithPackageDealsIds, ({ shape }) => shape);

  return Object.entries(packageDealIdsGroupedByShapes)
    .filter(([, shapeAndPackageDeals]) => {
      const packageDealIds = shapeAndPackageDeals.map(({ packageDealId }) => packageDealId);
      return qualityControlSteps
        .filter(({ id }) => packageDealIds.includes(id))
        .map(({ check }) => check)
        .some((check) => check === 'ND');
    })
    .map(([shape]) => shape);
}

export function getPackageDealsToDisplayFromSelectedShape(
  displayAllPackageDeals: boolean,
  selectedShapes: string[],
  packageDeals: readonly PackageDeal[]
): readonly PackageDeal[] {
  if (displayAllPackageDeals) {
    return packageDeals;
  }

  if (selectedShapes.length > 0) {
    const [selectedShape] = selectedShapes;
    return packageDeals.filter((packageDeal) =>
      packageDeal.carElement?.shapes.includes(selectedShape)
    );
  }

  return [];
}
