import { useMemo } from 'react';
import type {
  SiteConfiguration,
  WorkshopPostCategory,
  WorkshopPostLevel,
} from '@stimcar/libs-base';
import { workshopHelpers } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';

interface WorkshopPostCategoryAndUniqueId
  extends Omit<WorkshopPostCategory, 'posts' | 'toggleOperationsFromAnywhere'> {
  readonly postName: string;
}

function getAllWorkshopPostCategoriesAndUniqueIds(
  level: WorkshopPostLevel
): readonly WorkshopPostCategoryAndUniqueId[] {
  const allWorkshopPostIds: WorkshopPostCategoryAndUniqueId[] = [];
  if (level.ancestors !== undefined && level.ancestors.length > 0) {
    level.ancestors.forEach((ancestor) => {
      allWorkshopPostIds.push(...getAllWorkshopPostCategoriesAndUniqueIds(ancestor));
    });
  }
  level.posts.forEach((post) => {
    allWorkshopPostIds.push({
      id: level.id,
      postName: workshopHelpers.getImplantationPostName(post),
    });
  });
  return allWorkshopPostIds;
}

export function useGetWorkshopPostCategoriesAndUniqueIds(
  configuration: SiteConfiguration | undefined,
  standId: string
): readonly WorkshopPostCategoryAndUniqueId[] {
  const categories = useMemo((): WorkshopPostCategoryAndUniqueId[] => {
    const workshopPostCategories: WorkshopPostCategoryAndUniqueId[] = [];
    if (isTruthy(configuration) && isTruthyAndNotEmpty(standId)) {
      const foundStand = configuration.stands.find(
        (stand) =>
          stand.implantations &&
          stand.implantations
            .map((impl) => {
              return impl.id;
            })
            .includes(standId)
      );
      (foundStand?.implantations ?? []).forEach((impl) => {
        impl.definition.forEach((def) => {
          workshopPostCategories.push(...getAllWorkshopPostCategoriesAndUniqueIds(def));
        });
      });
    }
    return workshopPostCategories;
  }, [configuration, standId]);

  return categories;
}
