import type { JSX } from 'react';
import React from 'react';
import { DeleteClickableIcon } from '@stimcar/libs-uitoolkit';

export interface NotificationProps {
  readonly value: string;
  readonly closeHandler?: () => void;
}

interface Props extends NotificationProps {
  readonly type: 'warning' | 'error' | 'info';
  readonly blink?: boolean;
}

function typeToClassName(type: Props['type']): string {
  switch (type) {
    case 'error':
      return 'is-danger';
    case 'warning':
      return 'is-warning';
    case 'info':
      return 'is-success';
    default:
      throw Error(`Unknown notification type ${type}`);
  }
}

export function Notification({ type, value, closeHandler, blink }: Props): JSX.Element {
  return (
    <div className={`notification ${typeToClassName(type)} is-light${blink ? ' blink' : ''}`}>
      {closeHandler !== undefined && <DeleteClickableIcon handler={closeHandler} />}
      {value}
    </div>
  );
}
