import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PackageDeal } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  CoreBackendRoutes,
  nonDeleted,
  packageDealHelpers,
  QUALITY_CONTROL_ATTACHMENT_FOLDER,
} from '@stimcar/libs-base';
import { asyncForEach, isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useArrayItemSelector,
  useSelectorWithChangeTrigger,
  useStateIsDefined,
} from '@stimcar/libs-uikernel';
import { ChoiceAddOnButtons } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { AttachmentThumbnail } from '../../../lib/components/attachments/AttachmentThumbnail.js';
import { EditableCommentsComponent } from '../../commons/comments/EditableCommentsComponent.js';
import { appendRegisteredBrowserSessionToken } from '../../utils/security.js';
import { useComputeAttachmentUrl } from '../../utils/useComputeAttachmentUrl.js';
import type { QualityControlCheck, QualityControlState } from './typings/store.js';
import { EMPTY_QUALITY_CONTROL_NOT_OK_REPORT_MODAL_STATE } from './typings/store.js';

interface QualityControlStepButtonDesc {
  readonly id: QualityControlCheck;
  readonly selectedClass: string;
}

const QUALITY_CONTROL_STEP_BUTTONS_DESC: readonly QualityControlStepButtonDesc[] = [
  { id: 'OK', selectedClass: 'has-background-success' },
  { id: 'ND', selectedClass: 'has-background-warning' },
  { id: 'KO', selectedClass: 'has-background-danger' },
];

async function onQualityControlStepCheckChangeAction(
  {
    getState,
    getGlobalState,
    actionDispatch,
    httpClient,
  }: ActionContext<Store, QualityControlState>,
  kanbanId: string,
  packageDeal: PackageDeal
) {
  const step = getState().steps.find((step) => step.id === packageDeal.id);
  if (isTruthy(step)) {
    if (step.check === 'KO') {
      const modalDisptach = actionDispatch.scopeProperty('qualityControlNotOkReportModalState');
      modalDisptach.setValue({
        ...EMPTY_QUALITY_CONTROL_NOT_OK_REPORT_MODAL_STATE,
        active: true,
        packageDealId: packageDeal.id,
        packageDealLabel: packageDealHelpers.getPackageDealDisplayedLabel(packageDeal),
      });
      if (isTruthyAndNotEmpty(step.comment) || step.files.length > 0) {
        modalDisptach.scopeProperty('formData').setProperty('comment', step.comment ?? '');
        modalDisptach.setProperty('mode', 'update');
      }
    } else {
      const stepDispatch = actionDispatch.scopeProperty('steps').scopeArrayItem(packageDeal.id);
      if (isTruthyAndNotEmpty(step.comment)) {
        stepDispatch.setProperty('comment', undefined);
      }
      if (step.files.length > 0) {
        const { isOnline } = getGlobalState().session;
        if (isOnline) {
          await asyncForEach(step.files, async (fileName) => {
            await httpClient.httpGet(
              appendRegisteredBrowserSessionToken(
                CoreBackendRoutes.ATTACHMENT(
                  'kanban',
                  kanbanId,
                  QUALITY_CONTROL_ATTACHMENT_FOLDER.id,
                  fileName
                ),
                nonnull(getGlobalState().session.infos).sessionToken
              ),
              'DELETE'
            );
          });
        }
        stepDispatch.setProperty('files', []);
      }
    }
  }
}

interface QualityControlPackageDealDisplayProps extends AppProps<Store> {
  readonly kanbanId: string;
  readonly packageDeal: PackageDeal;
  readonly $: StoreStateSelector<Store, QualityControlState>;
}

export function QualityControlPackageDealDisplay({
  $,
  $gs,
  kanbanId,
  packageDeal,
}: QualityControlPackageDealDisplayProps): JSX.Element {
  const [t] = useTranslation('workshop');
  const computeAttachmentUrl = useComputeAttachmentUrl($gs);
  const $qualityControlStep = useArrayItemSelector($.$steps, packageDeal.id);

  const onSelectedOptionChanged = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(onQualityControlStepCheckChangeAction, kanbanId, packageDeal);
    },
    [kanbanId, packageDeal],
    $
  );

  const $selectedOptionWithChangeTrigger = useSelectorWithChangeTrigger(
    $qualityControlStep.$check,
    onSelectedOptionChanged
  );

  const hasComments = useMemo(
    () =>
      isTruthyAndNotEmpty(packageDeal.comment) ||
      isTruthyAndNotEmpty(packageDeal.estimateComment) ||
      isTruthyAndNotEmpty(packageDeal.commentForStore),
    [packageDeal.comment, packageDeal.commentForStore, packageDeal.estimateComment]
  );

  const hasQualityControlCheck = useStateIsDefined($qualityControlStep);

  const attachments = useMemo(
    () => (isTruthy(packageDeal.attachments) ? packageDeal.attachments.filter(nonDeleted) : []),
    [packageDeal.attachments]
  );

  return (
    <div key={packageDeal.id} className="card package-deal-card px-2 py-1 mb-3">
      <p className="mb-2">
        <strong>{t('qualityControl.carView.packageDeal')}</strong>
        {packageDealHelpers.getPackageDealDisplayedLabel(packageDeal)}
      </p>
      {hasComments && (
        <div className="mb-2">
          <h6 className="title is-6 mb-1">{t('qualityControl.carView.comments')}</h6>
          <div className="px-1">
            <EditableCommentsComponent
              disabled
              commentForWorkshop={packageDeal.comment}
              commentForCustomer={packageDeal.estimateComment}
              commentForStore={packageDeal.commentForStore ?? ''}
            />
          </div>
        </div>
      )}
      {attachments.length > 0 && (
        <div className="package-deal-attachments-container is-flex mb-2">
          {attachments.map((attachment, index) => (
            <AttachmentThumbnail
              size={64}
              category="kanban"
              key={attachment.id}
              objectId={kanbanId}
              attachmentIndex={index}
              attachments={attachments}
              $imageModal={$gs.$imageModal}
              computeAttachmentUrl={computeAttachmentUrl}
            />
          ))}
        </div>
      )}
      {hasQualityControlCheck && (
        <ChoiceAddOnButtons
          $={$selectedOptionWithChangeTrigger}
          buttonsDesc={QUALITY_CONTROL_STEP_BUTTONS_DESC}
        />
      )}
    </div>
  );
}
