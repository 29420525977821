import type { Fetch, FormDataFactory, HttpLogFormatter } from '@stimcar/libs-kernel';
import { getHttpStatusCode, Logger } from '@stimcar/libs-kernel';
import type { AuthenticationTypes } from '../../model/index.js';
import { CommonRoutes, CoreBackendRoutes, HttpErrorCodes } from '../../model/index.js';
import type { EventSourceFactory } from './HTTPClientWithSSEImpl.js';
import type {
  AuthenticatedUser,
  AuthenticatedUserWithExpiration,
  AuthenticateRequest,
  AuthenticateResponse,
  HTTPClientWithAuth,
  UpdateOwnPasswordRequest,
  UpdateOwnPasswordResponse,
  UserDisconnectedListener,
} from './typings/HttpClientWithAuth.js';
import type { ListenerUnregisterer } from './typings/index.js';
import { HTTPClientWithSSEImpl } from './HTTPClientWithSSEImpl.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

const CHECK_USER_AUTHENTICATED_INTERVAL = 10 * 60 * 1000;

export class HTTPClientWithAuthImpl extends HTTPClientWithSSEImpl implements HTTPClientWithAuth {
  private authenticatedUser: AuthenticatedUser | undefined;

  private authenticationExpiresAt: number | undefined;

  private userDisconnectedListeners: UserDisconnectedListener[] = [];

  private checkUserConnectionInterval: NodeJS.Timeout;

  private networkStatusListenerUnregisterer: ListenerUnregisterer;

  /**
   * Default constructor.
   */
  public constructor(
    fetch: Fetch,
    formDataFactory: FormDataFactory,
    evFactory: EventSourceFactory,
    baseUrl?: string,
    logFormatter?: HttpLogFormatter
  ) {
    super(fetch, formDataFactory, evFactory, baseUrl, logFormatter);

    // The following poller will periodically check that the user is authenticated
    this.checkUserConnectionInterval = setInterval(
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      this.checkUserConnection,
      CHECK_USER_AUTHENTICATED_INTERVAL
    );

    // When the connection gets down, as soon as it becoms back online, a check
    // must be performed (this allows not to wait for the poller interval)
    this.networkStatusListenerUnregisterer = this.registerNetworkStatusListener(
      async (online): Promise<void> => {
        if (online) {
          await this.checkUserConnection();
        }
      }
    );
  }

  public async shutdown(timeout?: number): Promise<void> {
    log.info('Shutdowning authentication HTTP client');
    await this.networkStatusListenerUnregisterer();
    clearInterval(this.checkUserConnectionInterval);
    await super.shutdown(timeout);
  }

  public async userAuthenticationExpiresIn(): Promise<number> {
    try {
      const { expiresInMillis } = await this.httpGetAsJson<AuthenticatedUserWithExpiration>(
        CommonRoutes.AUTHENTICATED_USER
      );
      return expiresInMillis;
    } catch (e) {
      if (e instanceof Error && getHttpStatusCode(e) === HttpErrorCodes.UNAUTHORIZED) {
        return 0;
      }
      throw e;
    }
  }

  private checkUserConnection = async (): Promise<void> => {
    try {
      // If the user is authenticated, check that the token is still valid
      if (this.authenticatedUser !== undefined && this.isOnline()) {
        log.info('check user token expiration');
        const expiresIn = await this.userAuthenticationExpiresIn();
        // If the session expires soon, forces logout
        // (otherwise during the last interval before the session
        // expires, the user may perform updates on repository
        // entities, even if its session is over).
        if (expiresIn < CHECK_USER_AUTHENTICATED_INTERVAL) {
          await this.handleDisconnectEvent();
        }
      }
    } catch {
      // No need to handle the error ; if a 401 code is
      // raised, the user disconnected listeners will be invoked
      // thanks to handleFetchError
    }
  };

  public async authenticate(login: string, password: string): Promise<AuthenticatedUser> {
    // Authenticate
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { expiresInMillis, ...authenticatedUser } = await this.httpPostAsJSON<
      AuthenticateRequest,
      AuthenticateResponse
    >(CommonRoutes.AUTHENTICATE, {
      login,
      password,
    });
    this.authenticationExpiresAt = Date.now() + expiresInMillis;
    this.authenticatedUser = authenticatedUser;
    return authenticatedUser;
  }

  public async logout(): Promise<void> {
    await this.httpGet(CommonRoutes.LOGOUT);
    this.authenticatedUser = undefined;
    this.authenticationExpiresAt = undefined;
  }

  public async getAuthenticatedUser(): Promise<AuthenticatedUser | undefined> {
    if (this.authenticationExpiresAt !== undefined) {
      return this.authenticationExpiresAt > Date.now() ? this.authenticatedUser : undefined;
    }
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { expiresInMillis, ...authenticatedUser } =
        await this.httpGetAsJson<AuthenticatedUserWithExpiration>(CommonRoutes.AUTHENTICATED_USER);
      this.authenticationExpiresAt = Date.now() + expiresInMillis;
      this.authenticatedUser = authenticatedUser;
      return authenticatedUser;
    } catch (e) {
      if (e instanceof Error && getHttpStatusCode(e) === HttpErrorCodes.UNAUTHORIZED) {
        return undefined;
      }
      throw e;
    }
  }

  public registerUserDisconnectedListener = (
    listener: UserDisconnectedListener
  ): ListenerUnregisterer => {
    this.userDisconnectedListeners.push(listener);
    // Create unregister callback
    return (): void => {
      log.debug('Unregistering user disconnected listener');
      this.userDisconnectedListeners = this.userDisconnectedListeners.filter((l) => l !== listener);
    };
  };

  private async handleDisconnectEvent(): Promise<void> {
    this.authenticationExpiresAt = undefined;
    this.authenticatedUser = undefined;
    // Notify user disconnected listeners
    await Promise.all(
      this.userDisconnectedListeners.map(async (userDisconnectedListener): Promise<void> => {
        await userDisconnectedListener();
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/require-await
  protected async handleFetchError(e: Error, path: string, init: RequestInit): Promise<void> {
    // If we get a 401 status code when a user is authenticated, it means that the user is not
    // authenticated anymore
    if (getHttpStatusCode(e) === HttpErrorCodes.UNAUTHORIZED) {
      // Schedule disconnect event as soon as possible
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      setTimeout(this.handleDisconnectEvent.bind(this), 0);
    }
  }

  public async updatePassword(
    existingPassword: string,
    newPassword: string,
    authenticationType: AuthenticationTypes
  ): Promise<void> {
    await this.httpPostAsJSON<UpdateOwnPasswordRequest, UpdateOwnPasswordResponse>(
      CoreBackendRoutes.UPDATE_OWN_PASSWORD,
      {
        password: existingPassword,
        newPassword,
        authenticationType,
      }
    );
  }
}
