import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { RefurbishingType } from '@stimcar/libs-kernel';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../state/typings/store.js';
import type {
  AddOrUpdateDefectDialogState,
  DeleteRefurbishingOptionDialogState,
} from '../../../typings/store.js';
import { EMPTY_DELETE_REFURBISHING_OPTION_DIALOG_STATE } from '../../../typings/store.js';

export function openDeleteRefurbishingOptionModalAction(
  { actionDispatch }: ActionContext<Store, DeleteRefurbishingOptionDialogState>,
  refurbishingType: RefurbishingType,
  packageDealId: string
): void {
  actionDispatch.setValue({
    ...EMPTY_DELETE_REFURBISHING_OPTION_DIALOG_STATE,
    refurbishingType,
    packageDealId,
    active: true,
  });
}

function deleteRefurbishingOptionAction({
  getState,
  actionDispatch,
}: ActionContext<Store, AddOrUpdateDefectDialogState>): void {
  const {
    deleteRefurbishingOptionDialogState,
    fullyRefurbishingPackageDealIds,
    partiallyRefurbishingPackageDealIds,
  } = getState();
  const { refurbishingType, packageDealId } = deleteRefurbishingOptionDialogState;

  // Given the type, update one of the 2 arrays by removing the selected packageDealId
  if (refurbishingType === 'fully') {
    const newFullyRefurbishingPackageDealIds = fullyRefurbishingPackageDealIds.filter(
      (id) => id !== packageDealId
    );
    actionDispatch.setProperty(
      'fullyRefurbishingPackageDealIds',
      newFullyRefurbishingPackageDealIds
    );
  } else {
    const newPartiallyRefurbishingPackageDealIds = partiallyRefurbishingPackageDealIds.filter(
      (id) => id !== packageDealId
    );
    actionDispatch.setProperty(
      'partiallyRefurbishingPackageDealIds',
      newPartiallyRefurbishingPackageDealIds
    );
  }

  // Then close dialog
  actionDispatch
    .scopeProperty('deleteRefurbishingOptionDialogState')
    .setValue(EMPTY_DELETE_REFURBISHING_OPTION_DIALOG_STATE);
}

type DeleteRefurbishingOptionModalProps = {
  readonly $: StoreStateSelector<Store, AddOrUpdateDefectDialogState>;
};

export function DeleteRefurbishingOptionModal({
  $,
}: DeleteRefurbishingOptionModalProps): JSX.Element {
  const [t] = useTranslation('operators', {
    keyPrefix: 'defects.addOrUpdateDefect.deleteRefurbishingOption',
  });

  const deleteRefurbishingOptionActionCallback = useActionCallback(
    deleteRefurbishingOptionAction,
    [],
    $
  );

  return (
    <ModalCardDialog
      title={t('title')}
      $active={$.$deleteRefurbishingOptionDialogState.$active}
      okLabel={t('okButtonLabel')}
      onOkClicked={deleteRefurbishingOptionActionCallback}
    >
      <p>{t('confirmDeletionMsg')}</p>
    </ModalCardDialog>
  );
}
