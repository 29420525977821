import type {
  Attachment,
  AttachmentFolder,
  AttachmentMetadata,
  StorageCategories,
} from '@stimcar/libs-base';
import type { WithFormValidationWarnings } from '@stimcar/libs-uikernel';
import type { FormWithValidationState } from '@stimcar/libs-uitoolkit';

export type ConfirmAttachmentDialogState = {
  readonly active: boolean;
  readonly folder: string;
  readonly name: string;
  readonly id: string;
  readonly metadata: AttachmentMetadata | undefined;
};

export const EMPTY_CONFIRM_ATTACHMENT_DIALOG_STATE: ConfirmAttachmentDialogState = {
  active: false,
  folder: '',
  name: '',
  id: '',
  metadata: undefined,
};

export type AttachmentsBaseState = {
  readonly attachments: readonly Attachment[];
  readonly attachmentsMetadata: Record<string, AttachmentMetadata>;
};

export type PictureEditionToolkitState = {
  readonly isIncorrectOrientationModalOpen: boolean;
  readonly base64: string | undefined;
  readonly hasIncorrectOrientation: boolean;
};

export const EMPTY_PICTURE_EDITION_TOOLKIT_STATE: PictureEditionToolkitState = {
  base64: undefined,
  hasIncorrectOrientation: false,
  isIncorrectOrientationModalOpen: false,
};

export type PdfCreationFormData = {
  readonly pdfName: string;
};
export type PdfCreationForm = WithFormValidationWarnings<PdfCreationFormData>;
export const PDF_CREATION_EMPTY_FORM: PdfCreationForm = {
  pdfName: '',
  warnings: {},
};

export type PdfCreationAndUploadModalState = FormWithValidationState<PdfCreationFormData> &
  AttachmentsBaseState & {
    readonly isActive: boolean;
    readonly loadingStatus?: string;
    readonly folder: AttachmentFolder | undefined;
    readonly objectId: string;
    readonly category: StorageCategories;
  };

export const PDF_CREATION_AND_UPLOAD_MODAL_EMPTY_STATE: PdfCreationAndUploadModalState = {
  isActive: false,
  attachments: [],
  attachmentsMetadata: {},
  folder: undefined,
  objectId: '',
  category: 'kanban',
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formWarning: undefined,
  formData: PDF_CREATION_EMPTY_FORM,
};

export type AttachmentGalleryState = AttachmentsBaseState & {
  readonly loadingStatus?: string;
  readonly selectedAttachmentTab: string;
  readonly sortByFolder: boolean;
  readonly pdfCreationAndUploadModal: PdfCreationAndUploadModalState;
  // To enable the selection feature, simply provide an empty array
  // (or an array containing the selected attachment paths)
  readonly selectedPaths?: readonly string[];
};

export const EMPTY_ATTACHMENT_GALLERY_STATE: AttachmentGalleryState = {
  attachments: [],
  attachmentsMetadata: {},
  selectedAttachmentTab: '',
  sortByFolder: true,
  pdfCreationAndUploadModal: PDF_CREATION_AND_UPLOAD_MODAL_EMPTY_STATE,
};

export type AttachmentGalleryDialogState = AttachmentGalleryState & {
  readonly active: boolean;
};

export const EMPTY_ATTACHMENT_GALLERY_DIALOG_STATE: AttachmentGalleryDialogState = {
  ...EMPTY_ATTACHMENT_GALLERY_STATE,
  active: false,
  selectedPaths: [],
};

export type AttachmentsState = AttachmentsBaseState & {
  readonly confirmRemoval: ConfirmAttachmentDialogState;
  readonly galleryModal: AttachmentGalleryDialogState;
};

export const EMPTY_ATTACHMENTS_STATE: AttachmentsState = {
  confirmRemoval: EMPTY_CONFIRM_ATTACHMENT_DIALOG_STATE,
  galleryModal: EMPTY_ATTACHMENT_GALLERY_DIALOG_STATE,
  attachments: [],
  attachmentsMetadata: {},
};
export type AttachmentsTabState = AttachmentGalleryState & {
  readonly confirmAttachmentRemovalDialog: ConfirmAttachmentDialogState;
};

export const ATTACHMENTS_TAB_EMPTY_STATE: AttachmentsTabState = {
  ...EMPTY_ATTACHMENT_GALLERY_STATE,
  confirmAttachmentRemovalDialog: EMPTY_CONFIRM_ATTACHMENT_DIALOG_STATE,
};
