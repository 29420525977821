/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import type { JSX } from 'react';
import React from 'react';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { FaIcon } from '../../bulma/elements/icons/FaIcon.js';

interface ToggleNestedButtonsProps<SD extends AnyStoreDef> {
  readonly id: string;
  readonly $expandedIds: StoreStateSelector<SD, readonly string[]>;
  readonly label?: string | JSX.Element;
  readonly hasChildren: boolean;
  readonly caretDirection?: 'right' | 'left';
}

export function ToggleNestedButton<SD extends AnyStoreDef>({
  id,
  $expandedIds,
  label,
  hasChildren,
  caretDirection = 'right',
}: ToggleNestedButtonsProps<SD>): JSX.Element {
  const toggleExpandedStatusActionCallback = useActionCallback(
    ({ actionDispatch, getState }): void => {
      const expandedIds = getState();
      if (expandedIds.includes(id)) {
        actionDispatch.setValue(expandedIds.filter((anId) => anId !== id));
      } else {
        actionDispatch.setValue([...expandedIds, id]);
      }
    },
    [id],
    $expandedIds
  );

  const expandedIds = useGetState($expandedIds);

  return !hasChildren ? (
    <>
      <FaIcon id={`caret-${caretDirection}`} additionalClass="has-text-grey-lighter" />
      {isTruthy(label) && label}
    </>
  ) : (
    <>
      <a onClick={toggleExpandedStatusActionCallback}>
        <FaIcon id={`caret-${expandedIds.includes(id) ? 'down' : caretDirection}`} />
      </a>
      {isTruthy(label) && label}
    </>
  );
}
