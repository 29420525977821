import type { JSX } from 'react';
import React, { useCallback, useEffect, useMemo } from 'react';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { isTruthy } from '@stimcar/libs-kernel';
import { useFormFieldWarning } from '@stimcar/libs-uikernel';
import { useTextFieldWithDelayedStateChange } from './useTextField.js';

/* eslint-disable jsx-a11y/control-has-associated-label */

const NUMBER_DOT_COMMA = /^[\d,.]*$/;

// Due to a chrome bug, we use text type even with numbers and inject
// a formatter to ensure the entry is a number
// See https://codepen.io/whazam/pen/oOWxPK
function formatNumberText(e: React.KeyboardEvent<HTMLInputElement>): void {
  const fieldValue = Reflect.get(e.target, 'value');
  const fieldHasCommaOrDot = fieldValue.includes('.') || fieldValue.includes(',');
  const keyIsCommaOrDot = e.key === '.' || e.key === ',';
  if (!NUMBER_DOT_COMMA.test(e.key) || (keyIsCommaOrDot && fieldHasCommaOrDot)) e.preventDefault();
  Reflect.set(e.target, 'value', fieldValue.replace(',', '.'));
}

export type InputProps<SD extends AnyStoreDef> = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'value' | 'type'
> & {
  readonly type?: 'text' | 'password' | 'number' | 'date' | 'search';
  readonly preSelectContent?: boolean;
  readonly $: StoreStateSelector<SD, string | undefined>;
  readonly warning?: boolean;
};

export function Input<SD extends AnyStoreDef>({
  type,
  $,
  className,
  placeholder,
  warning,
  preSelectContent = false,
  ...props
}: InputProps<SD>): JSX.Element {
  const dispatchWarning = useFormFieldWarning($);
  const { inputFieldRef, scheduleDelayedStateChange, scheduleImmediateStateChange, onKeyDown } =
    useTextFieldWithDelayedStateChange<SD, HTMLInputElement>({
      $,
    });
  const hasWarning = warning === true || (dispatchWarning !== undefined && dispatchWarning !== '');

  useEffect(() => {
    if (preSelectContent && isTruthy(inputFieldRef.current)) {
      inputFieldRef.current.focus();
      inputFieldRef.current.select();
    }
  }, [inputFieldRef, preSelectContent]);

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      onKeyDown(e);
      const { onKeyDown: specificOnKeyDown } = props;
      if (isTruthy(specificOnKeyDown)) {
        specificOnKeyDown(e);
      }
    },
    [onKeyDown, props]
  );

  const typeToUse = useMemo(() => {
    if (!type) {
      return 'text';
    }
    // Due to a chrome bug, we use text type even with numbers
    // See https://codepen.io/whazam/pen/oOWxPK
    if (type === 'number') {
      return 'text';
    }
    return type;
  }, [type]);

  return (
    <input
      placeholder={!placeholder ? '' : placeholder}
      ref={inputFieldRef}
      className={`input${hasWarning ? ' is-info' : ''}${className ? ` ${className}` : ''}`}
      type={typeToUse}
      onChange={scheduleDelayedStateChange}
      onBlur={scheduleImmediateStateChange}
      onKeyPress={type === 'number' ? formatNumberText : undefined}
      {...props}
      onKeyDown={handleOnKeyDown}
    />
  );
}
