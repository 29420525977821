import type { GlobalStoreStateSelector } from '@stimcar/libs-uikernel';
import { hasAccessPermission, hasModifyPermission, isForbidden } from '@stimcar/libs-base';
import { useGetState } from '@stimcar/libs-uikernel';
import type { RegisteredAppStore } from './state/typings/store.js';

export function useHasModifyPermission<
  IS_LIGHT_ENVIRONMENT extends boolean,
  SD extends RegisteredAppStore<IS_LIGHT_ENVIRONMENT>,
>($gs: GlobalStoreStateSelector<SD>, requestedPermissionPath: string): boolean {
  const permissions = useGetState($gs.$session.$user.optChaining().$permissions);
  return hasModifyPermission(permissions ?? {}, requestedPermissionPath);
}

export function useHasAccessPermission<
  IS_LIGHT_ENVIRONMENT extends boolean,
  SD extends RegisteredAppStore<IS_LIGHT_ENVIRONMENT>,
>($gs: GlobalStoreStateSelector<SD>, requestedPermissionPath: string): boolean {
  const permissions = useGetState($gs.$session.$user.optChaining().$permissions);
  return hasAccessPermission(permissions ?? {}, requestedPermissionPath);
}

export function useIsForbidden<
  IS_LIGHT_ENVIRONMENT extends boolean,
  SD extends RegisteredAppStore<IS_LIGHT_ENVIRONMENT>,
>($gs: GlobalStoreStateSelector<SD>, requestedPermissionPath: string): boolean {
  const permissions = useGetState($gs.$session.$user.optChaining().$permissions);
  return isForbidden(permissions ?? {}, requestedPermissionPath);
}
