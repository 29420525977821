import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { LoadingObject, ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { PictureEditionToolkitState } from './typings/store.js';
import { EMPTY_PICTURE_EDITION_TOOLKIT_STATE } from './typings/store.js';

interface Props {
  readonly $: StoreStateSelector<AnyStoreDef, PictureEditionToolkitState>;
}

export function IncorrectAttachmentOrientationDialog({ $ }: Props): JSX.Element {
  const [t] = useTranslation('libComponents');

  const base64Image = useGetState($.$base64);

  const onOkClickedActionCallback = useActionCallback(
    ({ actionDispatch }) => {
      /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
      actionDispatch.reduce((initial) => EMPTY_PICTURE_EDITION_TOOLKIT_STATE);
    },
    [],
    $
  );

  return (
    <ModalCardDialog
      $active={$.$isIncorrectOrientationModalOpen}
      title={t('attachments.incorrectOrientationModal.title')}
      onOkClicked={onOkClickedActionCallback}
      dontShowCancelButton
    >
      <div className="columns is-vcentered">
        <div className="column">
          <p>{t('attachments.incorrectOrientationModal.content')}</p>
          <div className="columns is-mobile">
            <div className="column" />
            <div className="column is-narrow">
              <LoadingObject
                src="img/changeOrientation.png"
                errorIcon="car"
                width={192}
                height={148}
                objectFit="contain"
                transparentBackground
              />
            </div>
            <div className="column" />
          </div>
        </div>
        <div className="column is-narrow">
          <LoadingObject
            src={base64Image}
            errorIcon="car"
            width={192}
            height={256}
            objectFit="cover"
          />
        </div>
      </div>
    </ModalCardDialog>
  );
}
