import { retrieveEntryPointInfo } from '@stimcar/libs-base';
import { ensureError, Logger } from '@stimcar/libs-kernel';
import { launchMainApp } from './app/index.js';
import { launchDisplayApp } from './display/index.js';
import { launchShareApp } from './share/index.js';
import { launchSubcontractorApp } from './subcontractor/index.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

try {
  // Parse entry point
  const { entryPointId } = retrieveEntryPointInfo(document.location.hostname);
  // Launch entry point app
  switch (entryPointId) {
    case 'display':
      launchDisplayApp();
      break;
    case 'sst':
      launchSubcontractorApp();
      break;
    case 'main':
      {
        const isShareContext = document.location.pathname.startsWith('/share');
        if (isShareContext) {
          launchShareApp();
        } else {
          launchMainApp();
        }
      }
      break;
    default:
      // eslint-disable-next-line no-alert
      alert(`Unexpected entry point point ${entryPointId}`);
  }
} catch (e) {
  log.error(`Unexpected error during application bootstrap (${ensureError(e).message})`, e);
  // eslint-disable-next-line no-alert
  document.body.innerHTML = `Unexpected error during application bootstrap (${
    ensureError(e).message
  })`;
}
