import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban, PreAllocationEntity, PreAllocationRequestResult } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  CoreBackendRoutes,
  globalHelpers,
  kanbanHelpers,
  OPERATION_ATTRIBUTES,
  transverseHelpers,
} from '@stimcar/libs-base';
import { computePayload, isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../../../../app/state/typings/store.js';
import type { WizardPage } from '../../ModalWizard.js';
import type { SelectKanbanWizardState } from '../typings/store.js';
import { ModalWizard } from '../../ModalWizard.js';
import { OperationsDispatchComponent } from '../../operationsDispatch/OperationsDispatchComponent.js';
import { EMPTY_SELECT_KANBAN_WIZARD_STATE } from '../typings/store.js';
import { SelectKanbanWizardFirstPage } from './SelectKanbanWizardFirstPage.js';
import {
  assignAllUnfinishedAndUnassignedTubeOperationsToCategory,
  convertFromKanbanSelectionWizardItem,
} from './workshopImplantationUtils.js';

export async function validateKanbanSelectionWizard({
  actionDispatch,
  getState,
  getGlobalState,
  kanbanRepository,
  httpClient,
}: ActionContext<Store, SelectKanbanWizardState>): Promise<void> {
  const {
    kanbanWithOperationsDispatched,
    preDispatchOriginKanban,
    standId,
    originPostForAction,
    implantation,
    users,
    fromLoadingPost,
    originCategoryForAction,
  } = getState();
  const { isOnline } = getGlobalState().session;
  const implantationId = nonnull(implantation).id;
  if (isTruthy(kanbanWithOperationsDispatched)) {
    const qualifiedPostId = !fromLoadingPost
      ? globalHelpers.computeQualifiedWorkshopPostId(implantationId, originPostForAction)
      : globalHelpers.computeQualifiedWorkshopPostId(implantationId, originCategoryForAction);
    let updatedKanban: Kanban;
    if (fromLoadingPost) {
      updatedKanban = kanbanHelpers.openNewHandlingWithoutInterval(
        httpClient.getBrowserSequence(),
        kanbanWithOperationsDispatched,
        standId,
        qualifiedPostId,
        users
      );
    } else {
      updatedKanban = kanbanHelpers.openNewHandlingWithInterval(
        httpClient.getBrowserSequence(),
        kanbanWithOperationsDispatched,
        standId,
        qualifiedPostId,
        users,
        'work'
      );
    }
    await kanbanRepository.updateEntity(updatedKanban);
    if (isOnline) {
      const payload = computePayload(
        nonnull(preDispatchOriginKanban),
        kanbanWithOperationsDispatched
      );
      const preAllocation: PreAllocationEntity[] = [];
      (payload?.packageDeals ?? []).forEach((pdPayload) => {
        (pdPayload.operations ?? []).forEach((oPayload) => {
          const attributes = oPayload.attributes ?? {};
          if (isTruthy(attributes[OPERATION_ATTRIBUTES.WORKSHOP_POST])) {
            const { categoryId } =
              transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(
                attributes[OPERATION_ATTRIBUTES.WORKSHOP_POST] as string
              );
            if (isTruthyAndNotEmpty(categoryId)) {
              const originPd = nonnull(
                nonnull(preDispatchOriginKanban).packageDeals.find((pd) => pd.id === pdPayload.id)
              );
              const originO = nonnull(originPd.operations.find((o) => o.id === oPayload.id));
              preAllocation.push({
                categoryId,
                operationLabel: originO.label,
                packageDealCode: originPd.code,
              });
            }
          }
        });
      });
      await httpClient.httpPostAsJSON<PreAllocationRequestResult, null>(
        CoreBackendRoutes.UPDATE_OPERATIONS_PRE_ALLOCATION_INFOS(implantationId),
        { preAllocation }
      );
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionDispatch.reduce((initial) => EMPTY_SELECT_KANBAN_WIZARD_STATE);
}

async function navigateToOperationsDispatchPage({
  actionDispatch,
  httpClient,
  getGlobalState,
  getState,
}: ActionContext<Store, SelectKanbanWizardState>): Promise<void> {
  const { isOnline } = getGlobalState().session;
  const {
    firstPageSelectedKanbanId,
    implantation,
    standId,
    selectableKanbans,
    originPostForAction,
  } = getState();

  const firstPageSelectedKanban = selectableKanbans.find((k) => k.id === firstPageSelectedKanbanId);
  const selectedKanban = convertFromKanbanSelectionWizardItem(nonnull(firstPageSelectedKanban));
  const destinationQualifiedPostId = globalHelpers.computeQualifiedWorkshopPostId(
    nonnull(implantation).id,
    originPostForAction
  );
  const { categoryId } = transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(
    destinationQualifiedPostId
  );

  const updatedSelectedKanban = await assignAllUnfinishedAndUnassignedTubeOperationsToCategory(
    httpClient,
    isOnline,
    selectedKanban,
    standId,
    nonnull(implantation),
    nonnull(categoryId)
  );

  actionDispatch.reduce((initial): SelectKanbanWizardState => {
    return {
      ...initial,
      wizardPage: 1,
      kanbanWithOperationsDispatched: updatedSelectedKanban,
      preDispatchOriginKanban: selectedKanban,
    };
  });
}

export const formatStringNumber = (s: string): string => {
  return s.replace(',', '.');
};

export function isFloatCheck(value: string, t: TFunction): string | undefined {
  const replacedvalue = value.replace(',', '.');
  const isValid = /^\d+\.\d+$/.test(replacedvalue) || /^\d+$/.test(replacedvalue);
  return isValid ? undefined : t('libUtils:warnings.floatField');
}

export function InternalSelectKanbanWizard({ $, $gs }: Props): JSX.Element {
  const [t] = useTranslation('libComponents');

  const displayConfiguration = useGetState($gs.$siteConfiguration.$displayConfiguration);

  const submitValidDataAction = useActionCallback(validateKanbanSelectionWizard, [], $);

  const okHandlerCallback = useActionCallback(
    async ({ actionDispatch, getState }) => {
      switch (getState().wizardPage) {
        case 0:
          await actionDispatch.exec(navigateToOperationsDispatchPage);
          break;
        case 1:
          await actionDispatch.execCallback(submitValidDataAction);
          break;
        default:
          break;
      }
    },
    [submitValidDataAction],
    $
  );

  const firstPageSelectedKanbanId = useGetState($.$firstPageSelectedKanbanId);
  const standId = useGetState($.$standId);
  const sortedCategories = useGetState($.$sortedCategories);
  const implantationId = useGetState($.$implantation.asDefined().$id);
  const originCategoryForAction = useGetState($.$originCategoryForAction);
  const preDispatchOriginKanban = useGetState($.$preDispatchOriginKanban);

  const isNavigationToSecondPageInactive = useMemo(() => {
    return !isTruthyAndNotEmpty(firstPageSelectedKanbanId);
  }, [firstPageSelectedKanbanId]);

  const cancelWizardCallback = useActionCallback(
    ({ actionDispatch }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      actionDispatch.reduce((initial) => {
        return EMPTY_SELECT_KANBAN_WIZARD_STATE;
      });
    },
    [],
    $
  );

  const wizardPages = useMemo((): WizardPage<Store>[] => {
    const pages: WizardPage<Store>[] = [];
    const page1: WizardPage<Store> = {
      nextAdditionalAction: okHandlerCallback,
      content: (
        <SelectKanbanWizardFirstPage
          displayConfiguration={displayConfiguration}
          standId={standId}
          $={$}
          $gs={$gs}
        />
      ),
      isNextInactive: isNavigationToSecondPageInactive,
    };
    pages.push(page1);

    const page2: WizardPage<Store> = {
      content: (
        <OperationsDispatchComponent
          $={$}
          $gs={$gs}
          sortedCategories={sortedCategories}
          implantationId={implantationId}
          filterDoneOperationsBlocksDisplay
          currentCategoryId={originCategoryForAction}
          standId={standId}
        />
      ),
      nextAdditionalAction: okHandlerCallback,
      isNextInactive: false,
    };
    pages.push(page2);

    return pages;
  }, [
    $,
    $gs,
    displayConfiguration,
    implantationId,
    isNavigationToSecondPageInactive,
    okHandlerCallback,
    originCategoryForAction,
    sortedCategories,
    standId,
  ]);
  return (
    <ModalWizard
      isNarrow
      pageTitleProvider={(index: number): string => {
        if (index === 0) {
          return t('workshopSelectKanbanWizard.page1_title');
        }
        return t('workshopSelectKanbanWizard.page3_title', {
          license: preDispatchOriginKanban?.infos.license ?? '',
          model: preDispatchOriginKanban?.infos.model ?? '',
        });
      }}
      zIndex={50}
      $active={$.$isActive}
      wizardPages={wizardPages}
      $activePage={$.$wizardPage}
      onCancelClicked={cancelWizardCallback}
      isFullWidth
    />
  );
}

interface Props extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, SelectKanbanWizardState>;
}

export function SelectKanbanWizard({ $, $gs }: Props): JSX.Element {
  const isActive = useGetState($.$isActive);

  return <>{isActive && <InternalSelectKanbanWizard $={$} $gs={$gs} />}</>;
}
