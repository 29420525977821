import type { JSX } from 'react';
import React from 'react';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { FaIcon } from '@stimcar/libs-uitoolkit';
import { ClickableElement } from './ClickableElement.js';

export interface ShowHideButtonProps<SD extends AnyStoreDef> {
  readonly $: StoreStateSelector<SD, boolean>;
  readonly iconPosition?: 'left' | 'right';
  readonly disabled?: boolean;
  readonly label?: string;
  readonly className?: string;
  readonly style?: React.CSSProperties;
}

export function ShowHideButton<SD extends AnyStoreDef>({
  $,
  iconPosition = 'left',
  disabled = false,
  label,
  className,
  style,
}: ShowHideButtonProps<SD>): JSX.Element {
  const show = useGetState($);
  const toggleActionCallback = useActionCallback(
    ({ actionDispatch, getState }) => {
      if (!disabled) {
        actionDispatch.setValue(!getState());
      }
    },
    [disabled],
    $
  );

  const closedIconDirection = iconPosition === 'left' ? 'right' : 'left';

  const icon = (
    <FaIcon
      id={`caret-${show ? 'down' : closedIconDirection}`}
      iconColor={disabled ? 'lightgrey' : 'orange'}
    />
  );

  return (
    <ClickableElement clickHandler={toggleActionCallback} isDisabled={disabled}>
      <>
        {iconPosition === 'left' && icon}
        {label && (
          <span
            className={`has-text-dark ${isTruthyAndNotEmpty(className) ? className : ''}`}
            style={style}
          >
            {label}
          </span>
        )}
        {iconPosition === 'right' && icon}
      </>
    </ClickableElement>
  );
}
