import type { JSX } from 'react';
import React, { useRef } from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useGetState } from '@stimcar/libs-uikernel';
import { FaIcon } from '@stimcar/libs-uitoolkit';
import type { UseClickInsideOutsideActivationRefProps } from '../hooks/useDeactivateOnClickOutside.js';
import { useDeactivateOnClickOutside } from '../hooks/useDeactivateOnClickOutside.js';

export interface DropDownMenuProps<SD extends AnyStoreDef>
  extends UseClickInsideOutsideActivationRefProps<SD> {
  readonly title: string;
  readonly iconId?: string;
  readonly additionalClassNames?: string;
  readonly iconTooltip?: string;
  readonly children: React.ReactNode;
  readonly isRight?: boolean;
}

export function DropDownMenu<SD extends AnyStoreDef>({
  $active,
  title,
  iconId,
  additionalClassNames,
  iconTooltip,
  children,
  isRight,
}: DropDownMenuProps<SD>): JSX.Element {
  // Create the hook that will handle clicks inside / outside of the menu
  // to show/hide it
  const menuButtonDomElementRef = useRef<HTMLDivElement>(null);
  useDeactivateOnClickOutside({
    domElementRef: menuButtonDomElementRef,
    deactivateOnInternalClick: true,
    $active,
  });

  const menuIsActive = useGetState($active);

  const computeMenuAdditionalClassName = (): string => {
    let innerAdditionalClassNames = '';
    if (menuIsActive) {
      innerAdditionalClassNames += ' is-active';
    }
    if (additionalClassNames) {
      innerAdditionalClassNames += ` ${additionalClassNames}`;
    }
    return innerAdditionalClassNames;
  };

  /* eslint-disable jsx-a11y/anchor-is-valid */
  return (
    <div
      className={`navbar-item is-boxed has-dropdown${computeMenuAdditionalClassName()}`}
      ref={menuButtonDomElementRef}
    >
      <a role="button" className="navbar-link">
        {iconId && (
          <FaIcon
            label={title}
            id={iconId}
            tooltip={isTruthyAndNotEmpty(iconTooltip) ? String(iconTooltip) : ''}
          />
        )}
        {!iconId && title}
      </a>
      {menuIsActive && (
        <div className={`navbar-dropdown${isRight ? ' is-right' : ''}`}>{children}</div>
      )}
    </div>
  );
}
