import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../state/typings/store.js';

export function NavigationWithWIPModal({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation('refitit');
  return (
    <ModalCardDialog
      $active={$gs.$showPreventNavigationModal}
      titleIconId="exclamation-triangle"
      title={t('navigationWIPModal.title')}
    >
      <p>{t('navigationWIPModal.content_line1')}</p>
      <p>{t('navigationWIPModal.content_line2')}</p>
    </ModalCardDialog>
  );
}
