/* eslint-disable jsx-a11y/control-has-associated-label */
import type { JSX } from 'react';
import { marked } from 'marked';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban, KanbanMessage, PackageDeal, RequestMessageStatus } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { packageDealHelpers, sortingHelpers } from '@stimcar/libs-base';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  ModalCardDialog,
  ScrollableTableComponent,
  TableActionCell,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../state/typings/store.js';
import { TableActionHeaderCell } from '../../lib/bulma/elements/table/TableHeaderActionCell.js';
import { Checkbox } from '../../lib/bulma/form/Checkbox.js';
import {
  AddKanbanMessageInput,
  KanbanMessageChatStyleItem,
} from '../../lib/bulmalegacy/components/KanbanMessageQuickviewComponent.js';
import { DisplayContentOrPlaceholder } from '../../lib/components/misc/DisplayContentOrPlaceholder.js';
import { ShowHideBoxContainer } from '../../lib/components/ShowHideContainer.js';
import { TableSortableHeaderComponent } from '../../lib/components/TableSortableHeaderComponent.js';
import './DisplayKanbanMessagesComponent.scss';
import type {
  KanbanMessageRequestAnswerModalState,
  KanbanMessageRequestAnswerResponseType,
  KanbanMessageTabState,
  MobileKanbanMessageState,
} from './typings/store.js';
import { computeDateLabelForTimespamp } from './kanbanDetailsUtils.js';
import { KANBAN_MESSAGE_REQUEST_ANSWER_MODAL_EMPTY_STATE } from './typings/store.js';

// eslint-disable-next-line @typescript-eslint/require-await
async function openRequestMessageResponseModalAction(
  { actionDispatch }: ActionContext<Store, KanbanMessageRequestAnswerModalState>,
  messageId: string,
  choice: KanbanMessageRequestAnswerResponseType
): Promise<void> {
  actionDispatch.applyPayload({
    active: true,
    choice,
    messageId,
  });
}

interface KanbanMessageLineProps {
  readonly showTechnicalId: boolean;
  readonly message: KanbanMessage;
  readonly packageDeals: readonly PackageDeal[];
  readonly $: StoreStateSelector<Store, KanbanMessageRequestAnswerModalState>;
}

function KanbanMessageLine({
  showTechnicalId,
  message,
  packageDeals,
  $,
}: KanbanMessageLineProps): JSX.Element {
  const [t] = useTranslation('details');

  const isARequestMessage = message.type === 'request';
  const isARequestMessageAndIsActive = isARequestMessage && message.status === 'pending';
  const isARequestMessageAndIsAccepted = isARequestMessage && message.status === 'accepted';
  const isARequestMessageAndIsRefused = isARequestMessage && message.status === 'rejected';
  const isARequestMessageAndIsCancelled = isARequestMessage && message.status === 'canceled';

  const relatedPackageDeal = useMemo(() => {
    return isARequestMessage
      ? packageDeals.find(
          (pck) => pck.operations.find((o) => o.id === message.elementId) !== undefined
        )
      : undefined;
  }, [isARequestMessage, message, packageDeals]);

  const openAcceptRequestModalAction = useActionCallback(
    async ({ actionDispatch }) =>
      await actionDispatch.exec(openRequestMessageResponseModalAction, message.id, 'accept'),
    [message.id],
    $
  );

  const openRejectRequestModalAction = useActionCallback(
    async ({ actionDispatch }) =>
      await actionDispatch.exec(openRequestMessageResponseModalAction, message.id, 'reject'),
    [message.id],
    $
  );

  function getButtonTooltip(buttonType: 'accept' | 'reject'): string {
    if (isARequestMessageAndIsCancelled) {
      return t('tabs.kanbanMessages.canceledRequestTooltip');
    }
    if (isARequestMessageAndIsAccepted) {
      return t('tabs.kanbanMessages.acceptedRequestTooltip');
    }
    if (isARequestMessageAndIsRefused) {
      return t('tabs.kanbanMessages.rejectedRequestTooltip');
    }
    switch (buttonType) {
      case 'accept':
        return t('tabs.kanbanMessages.acceptRequestTooltip');
      case 'reject':
        return t('tabs.kanbanMessages.rejectRequestTooltip');
      default:
        return '';
    }
  }

  const messageContent = useMemo(() => {
    let content = '';
    if (relatedPackageDeal) {
      content = `(${packageDealHelpers.getPackageDealDisplayedLabel(relatedPackageDeal)}) `;
    }
    marked.use({ async: false });
    content += marked.parse(message.content) as string;
    return content;
  }, [message.content, relatedPackageDeal]);

  return (
    <tr key={message.id} className={isARequestMessageAndIsActive ? 'has-text-weight-semibold' : ''}>
      {showTechnicalId && <td>{message.id}</td>}
      <td>{computeDateLabelForTimespamp(message.timestamp)}</td>
      <td>{message.username}</td>
      <td
        className="multiline-markdown-content"
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: messageContent }}
      />
      {isARequestMessage ? (
        <>
          <TableActionCell
            iconId="check"
            disabled={!isARequestMessageAndIsActive}
            additionalButtonClassName={
              isARequestMessageAndIsAccepted ? 'has-background-success' : ''
            }
            onClick={openAcceptRequestModalAction}
            tooltip={getButtonTooltip('accept')}
          />
          <TableActionCell
            iconId="times"
            disabled={!isARequestMessageAndIsActive}
            additionalButtonClassName={isARequestMessageAndIsRefused ? 'has-background-danger' : ''}
            onClick={openRejectRequestModalAction}
            tooltip={getButtonTooltip('reject')}
          />
        </>
      ) : (
        <>
          <td />
          <td />
        </>
      )}
    </tr>
  );
}

interface Props {
  readonly kanban: Kanban;
  readonly $: StoreStateSelector<Store, KanbanMessageTabState>;
  readonly isReadonly: boolean;
}

export function DisplayKanbanMessagesComponent({ kanban, $, isReadonly }: Props): JSX.Element {
  const [t] = useTranslation('details');

  const { $messageRequestAnswerModal } = $;

  const { messages } = kanban;

  const sortBy = useGetState($.$sort.$by);
  const sortDirection = useGetState($.$sort.$direction);

  const sortedMessages = useMemo((): readonly KanbanMessage[] => {
    const sortedDatas = messages.slice();
    let sortFunction: (c1: KanbanMessage, c2: KanbanMessage) => number;
    switch (sortBy) {
      case 'timestamp':
        sortFunction = sortingHelpers.createSortByNumericField(sortDirection, sortBy);
        break;
      case 'username':
        sortFunction = sortingHelpers.createSortByStringField(sortDirection, sortBy);
        break;
      default:
        return sortedDatas;
    }
    return sortedDatas.sort(sortFunction);
  }, [messages, sortBy, sortDirection]);

  const showTechnicalId = useGetState($.$showTechnicalId);

  return (
    <>
      <div className="columns">
        <div className="column">
          <DisplayContentOrPlaceholder
            displayCondition={messages.length > 0}
            placeholder={t('tabs.kanbanMessages.emptyPlaceholder')}
            isScrollable
          >
            <>
              <Checkbox $={$.$showTechnicalId} text={t('tabs.kanbanMessages.id')} />
              <ScrollableTableComponent tableClassName="table is-narrow is-striped is-hoverable is-fullwidth">
                <thead>
                  <tr>
                    {showTechnicalId && <th>{t('tabs.idTitle')}</th>}
                    <TableSortableHeaderComponent
                      content={t('tabs.kanbanMessages.date')}
                      centerLabel={false}
                      sortedField="timestamp"
                      isTruncable
                      $sort={$.$sort}
                    />
                    <TableSortableHeaderComponent
                      content={t('tabs.kanbanMessages.user')}
                      centerLabel={false}
                      isTruncable
                      sortedField="username"
                      $sort={$.$sort}
                    />
                    <th style={{ width: '70%' }}>{t('tabs.kanbanMessages.messageContent')}</th>
                    <TableActionHeaderCell />
                    <TableActionHeaderCell />
                  </tr>
                </thead>
                <tbody>
                  {sortedMessages.map(
                    (message): JSX.Element => (
                      <KanbanMessageLine
                        key={message.id}
                        message={message}
                        showTechnicalId={showTechnicalId}
                        packageDeals={kanban.packageDeals}
                        $={$messageRequestAnswerModal}
                      />
                    )
                  )}
                </tbody>
              </ScrollableTableComponent>
            </>
          </DisplayContentOrPlaceholder>
        </div>
        <div className="column is-3 m-t-md kanban-message-input-container">
          <AddKanbanMessageInput
            kanbanId={kanban.id}
            isReadonly={isReadonly}
            $={$.$addKanbanMessageInputState}
          />
        </div>
      </div>
      <RespondToRequestModal $={$messageRequestAnswerModal} kanbanId={kanban.id} />
    </>
  );
}

interface MobileKanbanMessageComponentProps {
  readonly $: StoreStateSelector<Store, MobileKanbanMessageState>;
  readonly kanbanMessages: readonly KanbanMessage[];
  readonly kanbanId: string;
}

export function MobileKanbanMessageComponent({
  $,
  kanbanMessages,
  kanbanId,
}: MobileKanbanMessageComponentProps): JSX.Element {
  const [t] = useTranslation('details');

  const { $messageRequestAnswerModal } = $;

  const sortedMessages = useMemo(() => {
    return [...kanbanMessages].sort(sortingHelpers.createSortByNumericField('UP', 'timestamp'));
  }, [kanbanMessages]);

  return (
    <>
      <ShowHideBoxContainer
        title={t('tabs.kanbanMessages.title', { count: kanbanMessages.length })}
        $={$.$isUnfolded}
        isMobile
      >
        <DisplayContentOrPlaceholder
          displayCondition={sortedMessages.length > 0}
          placeholder={t('tabs.kanbanMessages.emptyPlaceholder')}
        >
          <>
            {sortedMessages.map(
              (c): JSX.Element => (
                <KanbanMessageChatStyleItem key={c.id} message={c} />
              )
            )}
          </>
        </DisplayContentOrPlaceholder>
      </ShowHideBoxContainer>
      <RespondToRequestModal $={$messageRequestAnswerModal} kanbanId={kanbanId} />
    </>
  );
}

async function handleMessageRequestAction(
  {
    getState,
    kanbanRepository,
    actionDispatch,
  }: ActionContext<Store, KanbanMessageRequestAnswerModalState>,
  kanbanId: string
): Promise<void> {
  const { messageId, choice } = getState();

  let status: RequestMessageStatus;
  switch (choice) {
    case 'accept':
      status = 'accepted';
      break;
    case 'reject':
      status = 'rejected';
      break;
    default:
      throw Error(`Unknwown status ${choice} for request message`);
  }

  await kanbanRepository.updateEntityFromPayload({
    entityId: kanbanId,
    payload: {
      messages: [
        {
          id: messageId,
          status,
        },
      ],
    },
  });

  actionDispatch.applyPayload(KANBAN_MESSAGE_REQUEST_ANSWER_MODAL_EMPTY_STATE);
}

interface RespondToRequestModalProps {
  readonly $: StoreStateSelector<Store, KanbanMessageRequestAnswerModalState>;
  readonly kanbanId: string;
}

function RespondToRequestModal({ $, kanbanId }: RespondToRequestModalProps): JSX.Element {
  const [t] = useTranslation('details');
  const choice = useGetState($.$choice);

  const handledMessageRequestActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(handleMessageRequestAction, kanbanId);
    },
    [kanbanId],
    $
  );

  const isOpenForAcceptance = choice === 'accept';

  return (
    <ModalCardDialog
      title={
        isOpenForAcceptance
          ? t('tabs.kanbanMessages.respondToRequestModal.acceptTitle')
          : t('tabs.kanbanMessages.respondToRequestModal.rejectTitle')
      }
      $active={$.$active}
      onOkClicked={handledMessageRequestActionCallback}
      okLabel={
        isOpenForAcceptance
          ? t('tabs.kanbanMessages.respondToRequestModal.acceptButton')
          : t('tabs.kanbanMessages.respondToRequestModal.rejectButton')
      }
    >
      <p>
        {isOpenForAcceptance
          ? t('tabs.kanbanMessages.respondToRequestModal.acceptContent')
          : t('tabs.kanbanMessages.respondToRequestModal.rejectContent')}
      </p>
    </ModalCardDialog>
  );
}
