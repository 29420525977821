import { isTruthy, isTruthyAndNotEmpty, keysOf } from '@stimcar/libs-kernel';
import type {
  CarViewCategory,
  Kanban,
  MemoDesc,
  Operation,
  PackageDealVariable,
  PartialRecord,
  SiteConfiguration,
  Stand,
  WorkshopPostCategory,
  WorkshopPostLevel,
  WorkshopStandImplantation,
} from '../../model/index.js';
import { WORKSHOP_POST_ID_SEPARATOR } from '../../model/globalConstants.js';
import { globalHelpers } from './globalHelpers.js';
import { packageDealHelpers } from './packageDealHelpers.js';

export type OperationsStatus = 'allDone' | 'someDone' | 'allNotDone';

export type StandWorkInformation = {
  readonly stand: Stand;
  readonly operations: readonly Operation[];
  readonly operationsStatus: OperationsStatus;
  readonly packageDealVariables: Record<string, PackageDealVariable>;
  readonly expectedTime: number;
  readonly spentTime?: number | null;
  readonly firstStartTimestamp?: number | null;
  readonly lastStopTimestamp?: number | null;
};

export type MemoDescWithCategory = MemoDesc & {
  readonly category: CarViewCategory;
};

function getMemoDescWithCategory(
  memoDescs: PartialRecord<CarViewCategory, readonly MemoDesc[]>,
  memoId: string
): MemoDescWithCategory | undefined {
  for (const key of keysOf(memoDescs)) {
    const descs = memoDescs[key];
    if (isTruthy(descs)) {
      for (const memoDesc of descs) {
        if (memoDesc.id === memoId) {
          return {
            ...memoDesc,
            category: key,
          };
        }
      }
    }
  }
  return undefined;
}

function getAllPostInformationsFromQualifiedWorkshopPostId(qualifiedPostIdOrCategoryId: string): {
  readonly implantationId: string | undefined;
  readonly workshopPostId: string | undefined;
  readonly categoryId: string | undefined;
  readonly postName: string | undefined;
} {
  if (!globalHelpers.isQualifiedWorkshopPostId(qualifiedPostIdOrCategoryId)) {
    if (globalHelpers.isQualifiedCategory(qualifiedPostIdOrCategoryId)) {
      const values = qualifiedPostIdOrCategoryId.split(WORKSHOP_POST_ID_SEPARATOR);
      return {
        implantationId: values[0],
        workshopPostId: undefined,
        categoryId: values[1],
        postName: undefined,
      };
    }
    return {
      implantationId: undefined,
      workshopPostId: undefined,
      categoryId: undefined,
      postName: undefined,
    };
  }
  const values = qualifiedPostIdOrCategoryId.split(WORKSHOP_POST_ID_SEPARATOR);
  const workshopPostId = values[1] + WORKSHOP_POST_ID_SEPARATOR + values[2];
  return {
    implantationId: values[0],
    workshopPostId,
    categoryId: values[1],
    postName: values[2],
  };
}

function extractStandsWorkInformations(
  stands: readonly Stand[],
  kanban: Kanban
): readonly StandWorkInformation[] {
  const returnValue: StandWorkInformation[] = [];
  stands.forEach((stand) => {
    packageDealHelpers.getAvailablePackageDeals(kanban.packageDeals).forEach((pck) => {
      const operations = packageDealHelpers.getOperationsForStandId(pck, stand.id);
      if (operations.length === 0) {
        return;
      }
      const unfinishedOperationsCount = operations.filter((o) => !o.completionDate).length;
      let operationsStatus: OperationsStatus = 'someDone';
      if (unfinishedOperationsCount === operations.length) {
        operationsStatus = 'allNotDone';
      } else if (unfinishedOperationsCount === 0) {
        operationsStatus = 'allDone';
      }
      const expectedTime =
        operations.map((o): number => o.workload).reduce((acc, value) => acc + value, 0) * 3600000;

      let spentTime: number | undefined;
      let firstStartTimestamp: number | undefined | null;
      let lastStopTimestamp: number | undefined | null;
      const handlings = kanban.handlings.filter((h) => h.standId === stand.id);
      if (handlings.length > 0) {
        handlings.forEach((h) => {
          if (!isTruthy(firstStartTimestamp) || h.startDate < firstStartTimestamp) {
            firstStartTimestamp = h.startDate;
          }
          if (!isTruthy(lastStopTimestamp) || (h.endDate && h.endDate > lastStopTimestamp)) {
            lastStopTimestamp = h.endDate;
          }
          if (h.endDate) {
            if (spentTime === undefined) {
              spentTime = h.endDate - h.startDate;
            } else {
              spentTime += h.endDate - h.startDate;
            }
          }
        });
      }
      returnValue.push({
        stand,
        operations,
        operationsStatus,
        packageDealVariables: pck.variables,
        expectedTime,
        spentTime,
        firstStartTimestamp,
        lastStopTimestamp,
      });
    });
  });
  return returnValue;
}

function getIconForWorkshopPost(postId: string, siteConfiguration: SiteConfiguration): string {
  const values = postId.split(WORKSHOP_POST_ID_SEPARATOR);
  let category: string | undefined;
  if (values.length === 3) {
    [, category] = values;
  } else if (values.length === 2) {
    [category] = values;
  } else {
    throw Error(
      `Malformed workshop postId "${postId}". Should be of type "category":"postIndex" or "implantationId":"category":"postIndex"`
    );
  }

  const icon = siteConfiguration.iconDictionary[category];
  return isTruthyAndNotEmpty(icon) ? icon : 'hard-hat';
}

// FIXME remove this mutation of the given attribute
function doGetFurtherWorkshopCategories(
  level: WorkshopPostLevel,
  searchedCategoryId: string,
  nextCategories: WorkshopPostCategory[],
  includeSearchedPost: boolean
): boolean {
  if (level.id === searchedCategoryId) {
    if (includeSearchedPost) {
      nextCategories.push({
        id: level.id,
        posts: level.posts,
        toggleOperationsFromAnywhere: level.toggleOperationsFromAnywhere,
      });
    }
    return true;
  }
  const ancestors = level?.ancestors ?? [];
  let found = false;
  for (const ancestor of ancestors) {
    found = doGetFurtherWorkshopCategories(
      ancestor,
      searchedCategoryId,
      nextCategories,
      includeSearchedPost
    );
    if (found) {
      nextCategories.push({
        id: level.id,
        posts: level.posts,
        toggleOperationsFromAnywhere: level.toggleOperationsFromAnywhere,
      });
      break;
    }
  }

  return found;
}

function getFurtherWorkshopCategories(
  implantation: WorkshopStandImplantation,
  searchedCategoryId: string,
  includeSearchedPost = false
): readonly WorkshopPostCategory[] {
  const nextCategories: WorkshopPostCategory[] = [];
  for (const category of implantation.definition) {
    const found = doGetFurtherWorkshopCategories(
      category,
      searchedCategoryId,
      nextCategories,
      includeSearchedPost
    );
    if (found) {
      break;
    }
  }
  return nextCategories;
}

function getFurtherWorkshopCategory(
  implantation: WorkshopStandImplantation,
  initialCategoryId: string
): WorkshopPostCategory | undefined {
  const nextCategories: WorkshopPostCategory[] = [];
  for (const level of implantation.definition) {
    const found = doGetFurtherWorkshopCategories(level, initialCategoryId, nextCategories, false);
    if (found) {
      break;
    }
  }
  return nextCategories.length > 0 ? nextCategories[0] : undefined;
}

function doGetLevelFromImplantation(
  level: WorkshopPostLevel,
  categoryId: string
): WorkshopPostLevel | undefined {
  if (level.id === categoryId) {
    return level;
  }
  for (const ancestor of level.ancestors ?? []) {
    const found = doGetLevelFromImplantation(ancestor, categoryId);
    if (isTruthy(found)) {
      return found;
    }
  }
  return undefined;
}

function getCategoryFromImplantation(
  implantation: WorkshopStandImplantation,
  categoryId: string
): WorkshopPostCategory | undefined {
  for (const level of implantation.definition) {
    const found = doGetLevelFromImplantation(level, categoryId);
    if (isTruthy(found)) {
      return {
        id: found.id,
        posts: found.posts,
        toggleOperationsFromAnywhere: found.toggleOperationsFromAnywhere,
      };
    }
  }
  return undefined;
}

function getLevelFromImplantation(
  implantation: WorkshopStandImplantation,
  categoryId: string
): WorkshopPostLevel | undefined {
  for (const level of implantation.definition) {
    const found = doGetLevelFromImplantation(level, categoryId);
    if (isTruthy(found)) {
      return found;
    }
  }
  return undefined;
}

function getAncestors(
  currentLevel: WorkshopPostLevel,
  includeSelf?: boolean
): readonly WorkshopPostLevel[] {
  const ancestors: WorkshopPostLevel[] = includeSelf ? [currentLevel] : [];
  if (currentLevel.ancestors === undefined || currentLevel.ancestors.length === 0) {
    return ancestors;
  }
  currentLevel.ancestors.forEach((ancestor) => {
    ancestors.push(...getAncestors(ancestor, true));
  });
  return ancestors;
}

function getEarlierQualifiedWorkshopCategories(
  implantation: WorkshopStandImplantation,
  searchedCategoryId: string,
  includeSelf = false
): readonly string[] {
  const searchedLevel = getLevelFromImplantation(implantation, searchedCategoryId);
  if (!isTruthy(searchedLevel)) {
    return [];
  }
  const earlierLevels = getAncestors(searchedLevel, includeSelf);
  return earlierLevels.map((level): string => {
    return globalHelpers.computeQualifiedWorkshopPostId(implantation.id, level.id);
  });
}

function getAllQualifiedWorkshopCategories(
  implantation: WorkshopStandImplantation | undefined
): readonly string[] {
  if (
    !isTruthy(implantation) ||
    !isTruthy(implantation.definition) ||
    implantation.definition.length === 0
  ) {
    return [];
  }
  // categories are reversely written in configuration so this first category is the very end of the implantation
  const firstCategoryId = implantation.definition[0].id;
  return getEarlierQualifiedWorkshopCategories(implantation, firstCategoryId, true);
}

function computeQualifiedWorkshopCategoryFromQualifiedWorkshopPostId(
  qualifiedWorkshopPostId: string
): string | undefined {
  if (!globalHelpers.isQualifiedWorkshopPostId(qualifiedWorkshopPostId)) {
    return undefined;
  }
  const values = qualifiedWorkshopPostId.split(WORKSHOP_POST_ID_SEPARATOR);
  return globalHelpers.computeQualifiedWorkshopPostId(values[0], values[1]);
}

export class DateExpressionWrapper {
  private selectedDate: Date | null;

  private today: Date;

  private oneDay = 24 * 60 * 60 * 1000;

  private constructor(date: Date | null) {
    this.selectedDate = date;
    this.today = new Date();
  }

  getNumberOfDaysToToday(): number {
    if (isTruthy(this.selectedDate)) {
      return Math.round(
        Math.abs((this.selectedDate.getTime() - this.today.getTime()) / this.oneDay)
      );
    }
    return NaN;
  }

  getNumberOfDaysTo(dateTimestamp: number): number {
    if (isTruthy(this.selectedDate)) {
      return Math.round(Math.abs((this.selectedDate.getTime() - dateTimestamp) / this.oneDay));
    }
    return NaN;
  }

  isEmpty(): boolean {
    return !isTruthy(this.selectedDate);
  }

  static createDateExpressionWrapperFromFrenchStringDateFormat(
    stringDate: string
  ): DateExpressionWrapper {
    if (!globalHelpers.isStringCorrectDateFormat(stringDate)) {
      throw Error('Incorrect date format');
    }
    const parts = stringDate.split('/');
    return new DateExpressionWrapper(new Date(`${parts[1]}/${parts[0]}/${parts[2]}`));
  }

  static createDateExpressionWrapperFromTimestamp(timestamp: number): DateExpressionWrapper {
    return new DateExpressionWrapper(new Date(timestamp));
  }

  static createDateExpressionWrapperFromDate(date: Date): DateExpressionWrapper {
    return new DateExpressionWrapper(date);
  }

  static createEmptyDateExpressionWrapper(): DateExpressionWrapper {
    return new DateExpressionWrapper(null);
  }
}

export const transverseHelpers = {
  extractStandsWorkInformations,
  getCategoryFromImplantation,
  getMemoDescWithCategory,
  getAllPostInformationsFromQualifiedWorkshopPostId,
  getIconForWorkshopPost,
  getFurtherWorkshopCategories,
  getFurtherWorkshopCategory,
  computeQualifiedWorkshopCategoryFromQualifiedWorkshopPostId,
  getEarlierQualifiedWorkshopCategories,
  getAllQualifiedWorkshopCategories,
};
