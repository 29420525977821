import type { Repository } from '@stimcar/core-libs-repository';
import type { RepositoryEntities } from '@stimcar/libs-base';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';

export async function isUniqueEntityCheck<ENAME extends keyof RepositoryEntities>(
  repository: Repository<ENAME>,
  initial: RepositoryEntities[ENAME] | undefined,
  keyProp: keyof RepositoryEntities[ENAME],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  keyValue: any,
  indexKey?: string,
  indexValue?: string
): Promise<boolean> {
  // If the label has not changed, no need to check anything
  if (initial && initial[keyProp] === keyValue) {
    return true;
  }
  let entities;
  if (isTruthyAndNotEmpty(indexKey) && isTruthyAndNotEmpty(indexValue)) {
    entities = await repository.getEntitiesFromIndex(indexKey, indexValue);
  } else {
    entities = await repository.getAllEntities();
  }
  const entitiesWithSameName = entities.filter((e) => e[keyProp] === keyValue);
  switch (entitiesWithSameName.length) {
    case 0:
      return true;
    case 1: {
      const { id } = entitiesWithSameName[0];
      if (!initial || id !== initial.id) {
        return false;
      }
      return true;
    }
    default:
      return false;
  }
}
