import i18next from 'i18next';
import type { RegisteredBrowserInfos } from '@stimcar/core-libs-repository';
import type {
  FullPermissionConfiguration,
  Kanban,
  KanbanHandling,
  Operation,
  SiteConfiguration,
  WorkshopOperation,
} from '@stimcar/libs-base';
import type { ActionContext, ActionDispatch } from '@stimcar/libs-uikernel';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import {
  AvailablePermissionPaths,
  DEFAULT_VALIDATE_EXPERTISE_OPERATION,
  handlingHelpers,
  hasModifyPermission,
  kanbanHelpers,
  packageDealHelpers,
  Role,
  workflowProgressHelpers,
} from '@stimcar/libs-base';
import { ensureError, isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import type { OperatorViewState, WorkStatus } from '../operators/typings/store.js';
import type { Store, StoreState } from '../state/typings/store.js';
import { SELECT_KANBAN_FULL_PATH } from '../coreConstants.js';
import { EMPTY_OPERATOR_VIEW_STATE } from '../operators/typings/store.js';

export function canCompleteOperation(
  operation: Operation | WorkshopOperation,
  permissions: FullPermissionConfiguration
): boolean {
  if (!isTruthyAndNotEmpty(operation.type)) {
    return true;
  }
  return hasModifyPermission(
    permissions,
    AvailablePermissionPaths.CAN_COMPLETE_OPERATION(operation.type)
  );
}

export async function pauseWorkAction(
  {
    globalActionDispatch,
    getGlobalState,
    kanbanRepository,
    keyValueStorage,
  }: ActionContext<Store, OperatorViewState>,
  kanban: Kanban
): Promise<void> {
  const { session } = getGlobalState();
  const { id: postId } = nonnull(session.infos);
  let pausedKanban: Kanban | undefined;
  try {
    pausedKanban = kanbanHelpers.pauseCurrentHandling(kanban, postId);
  } catch (err) {
    globalActionDispatch.setProperty('message', ensureError(err).message);
  }
  if (pausedKanban) {
    await kanbanRepository.updateEntity(pausedKanban);
    keyValueStorage.removeItem(LocalStorageKeys.DESKTOP_EXPERTISE_STATE_DUMP);
    globalActionDispatch.setProperty('preventNavigation', false);
  }
}

export async function unpauseWorkAction(
  {
    kanbanRepository,
    getGlobalState,
    globalActionDispatch,
    httpClient,
  }: ActionContext<Store, OperatorViewState>,
  kanban: Kanban
): Promise<void> {
  const { session } = getGlobalState();
  const { id: postId } = nonnull(session.infos);
  const { login } = nonnull(session.user);

  let unpausedKanban: Kanban | undefined;
  try {
    unpausedKanban = kanbanHelpers.unpauseKanbanHandling(
      kanban,
      httpClient.getBrowserSequence(),
      postId,
      login
    );
  } catch (err) {
    globalActionDispatch.setProperty('message', ensureError(err).message);
  }
  if (unpausedKanban) {
    await kanbanRepository.updateEntity(unpausedKanban);
  }
}

export function closeKanbanHandleOnPost(
  postId: string,
  kanban: Kanban,
  userPermission: FullPermissionConfiguration,
  finishOperationOnStandIdIfProvided: string | undefined,
  userLogin: string | null
): Kanban {
  const stoppedKanban = kanbanHelpers.closeCurrentHandling(kanban, postId);

  if (isTruthyAndNotEmpty(finishOperationOnStandIdIfProvided)) {
    const standRelatedOperationIds = packageDealHelpers
      .getAllOperationsForStandId(stoppedKanban.packageDeals, finishOperationOnStandIdIfProvided)
      .filter((operation) => canCompleteOperation(operation, userPermission))
      .map(({ id }) => id);

    const updatedPackageDeals = stoppedKanban.packageDeals.map((packageDeal) => {
      if (isTruthy(packageDeal.operations)) {
        return {
          ...packageDeal,
          operations: packageDeal.operations.map((operation) => {
            const needsNewCompletionDate =
              operation.type !== DEFAULT_VALIDATE_EXPERTISE_OPERATION.type &&
              standRelatedOperationIds.includes(operation.id) &&
              !operation.completionDate;

            if (needsNewCompletionDate) {
              return {
                ...operation,
                completionDate: Date.now(),
                user: userLogin,
              };
            }
            return operation;
          }),
        };
      }
      return packageDeal;
    });

    return {
      ...stoppedKanban,
      packageDeals: updatedPackageDeals,
    };
  }

  return stoppedKanban;
}

export function clearOperatorViewAndNavigateToSelectionAction({
  globalActionDispatch,
  actionDispatch,
  keyValueStorage,
  navigate,
}: ActionContext<Store, OperatorViewState>): void {
  keyValueStorage.removeItem(LocalStorageKeys.DESKTOP_EXPERTISE_STATE_DUMP);
  globalActionDispatch.setProperty('preventNavigation', false);
  actionDispatch.setValue(EMPTY_OPERATOR_VIEW_STATE);
  navigate(SELECT_KANBAN_FULL_PATH);
}

export async function startWorkAction(
  {
    kanbanRepository,
    getGlobalState,
    globalActionDispatch,
    httpClient,
  }: ActionContext<Store, OperatorViewState>,
  kanban: Kanban,
  standId: string
): Promise<void> {
  const { session } = getGlobalState();
  const { id: postId } = nonnull(session.infos);
  const { login } = nonnull(session.user);

  let startedKanban: Kanban | undefined;
  try {
    startedKanban = kanbanHelpers.openNewHandlingWithInterval(
      httpClient.getBrowserSequence(),
      kanban,
      standId,
      postId,
      [login],
      'work'
    );
  } catch (err) {
    globalActionDispatch.setProperty('message', ensureError(err).message);
  }
  if (startedKanban) {
    await kanbanRepository.updateEntity(startedKanban);
  }
}

export function computeWorkStatus(
  globalActionDispatch: ActionDispatch<Store, StoreState>,
  openHandle: KanbanHandling | undefined,
  selectedKanban: Kanban
): WorkStatus {
  let currentWorkStatus: WorkStatus | undefined;
  let errorMessage: string | undefined;
  if (openHandle) {
    const filteredIntervals = openHandle.intervals.filter((i) => !isTruthy(i.endDate));
    if (filteredIntervals.length === 1) {
      currentWorkStatus = 'started';
    } else if (filteredIntervals.length === 0) {
      currentWorkStatus = 'paused';
    } else {
      errorMessage = 'operators:errors.forbiddenMultipleIntervals';
    }
  } else {
    const notDoneOperations = packageDealHelpers.getAllUnfinishedOperations(
      selectedKanban.packageDeals
    );
    if (notDoneOperations.length === 0) {
      currentWorkStatus = 'finished';
    } else {
      currentWorkStatus = 'unstarted';
    }
  }

  if (errorMessage) {
    const message = i18next.t(nonnull(errorMessage));
    globalActionDispatch.reduce((initial: StoreState): StoreState => {
      return {
        ...initial,
        message,
      };
    });
  }
  return nonnull(currentWorkStatus);
}

export const isKanbanNormallyAvailableForPosts = (
  kanban: Kanban | undefined,
  siteConfiguration: SiteConfiguration,
  browserInfos: RegisteredBrowserInfos | undefined,
  selectedStands: readonly string[]
): boolean => {
  if (!isTruthy(browserInfos) || !isTruthy(kanban)) {
    return false;
  }

  const { role } = browserInfos;

  const workflow = siteConfiguration.workflows.find((w) => w.id === kanban.workflowId);
  if (!isTruthy(workflow)) {
    return false;
  }

  if (role === Role.Operator) {
    const progress = workflowProgressHelpers.computeProgress(workflow.definition, kanban);
    return progress.expectedStandIds.filter((s) => selectedStands.includes(s)).length !== 0;
  }

  return true;
};

export const hasBeenHandledOnPost = (
  kanban: Kanban,
  browserInfos: RegisteredBrowserInfos | undefined
): boolean => {
  if (!isTruthy(browserInfos)) {
    return false;
  }
  const { id: postId } = browserInfos;
  const onPostHandling = kanban.handlings.find((h) => h.postId === postId);
  return isTruthy(onPostHandling);
};

export interface SelectionStatus {
  readonly openHandlingOnPost: KanbanHandling | undefined;
  readonly openHandlingsOnOtherPosts: readonly KanbanHandling[];
}

export const computeKanbanHandlingStatus = (
  k: Kanban | undefined,
  postId: string
): SelectionStatus => {
  if (!isTruthy(k)) {
    return {
      openHandlingOnPost: undefined,
      openHandlingsOnOtherPosts: [],
    };
  }

  const openHandlings = handlingHelpers.getOpenHandlingsFor(k);
  let openHandlingOnPost: KanbanHandling | undefined;
  const openHandlingsOnOtherPosts: KanbanHandling[] = [];

  if (openHandlings.length > 0) {
    openHandlings.forEach((h) => {
      if (h.postId === postId) {
        openHandlingOnPost = h;
      } else {
        openHandlingsOnOtherPosts.push(h);
      }
    });
  }
  return {
    openHandlingOnPost,
    openHandlingsOnOtherPosts,
  };
};

export function filterNotHandledOnPostKanbans(
  kanbans: readonly Kanban[],
  postId: string
): Kanban[] {
  return kanbans.filter((k) => {
    return kanbanHelpers.isHandledOnPost(k, postId);
  });
}

export async function retrieveHandledKanbansInitialization({
  getGlobalState,
  globalActionDispatch,
  kanbanRepository,
}: ActionContext<Store, StoreState>): Promise<void> {
  const { session } = getGlobalState();
  if (session.isRegistered) {
    const { id: postId } = nonnull(session.infos);
    const kanbans = await kanbanRepository.getAllEntities();
    const filteredKanbans = filterNotHandledOnPostKanbans(kanbans, postId).map((k) => k.id);

    globalActionDispatch.setProperty('handledKanbanId', filteredKanbans[0]);
    if (filteredKanbans.length > 1) {
      globalActionDispatch.setProperty(
        'message',
        'Only one kanban can be handled at a given time on a post. One of them has been selected has handled kanban. Close it to select another kanban'
      );
    }
  }
}
