import i18next from 'i18next';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { KanbanRepository, RepositoryHTTPClient } from '@stimcar/core-libs-repository';
import type { Kanban } from '@stimcar/libs-base';
import type { ActionContext, GlobalStoreStateSelector } from '@stimcar/libs-uikernel';
import { CoreBackendRoutes } from '@stimcar/libs-base';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useGetState } from '@stimcar/libs-uikernel';
import type { RawActionButtonDesc } from '../../lib/components/misc/ActionButtonComponent.js';
import type { Store, StoreState } from '../state/typings/store.js';
import { computeKanbanDetailsPath } from '../coreConstants.js';

export const loadKanbanLocallyOrFromServer = async (
  kanbanRepository: KanbanRepository,
  httpClient: RepositoryHTTPClient,
  isOnline: boolean,
  kanbanId: string
): Promise<Kanban | undefined | string> => {
  let kanban: Kanban | undefined;
  if (await kanbanRepository.hasEntity(kanbanId)) {
    kanban = await kanbanRepository.getEntity(kanbanId);
  } else if (!isOnline) {
    return i18next.t('refititCommonComponents:loadKanbanOfflineError');
  } else {
    kanban = await httpClient.httpGetAsJson<Kanban>(CoreBackendRoutes.KANBAN(kanbanId));
  }
  return kanban;
};

type ActionLocation = 'navbar' | 'selection';

export const useGetActionDescsProvider = (
  $gs: GlobalStoreStateSelector<Store>,
  // allow to provide different actions depending of the action location
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  location: ActionLocation
): ((kanban: Kanban) => RawActionButtonDesc<Store, StoreState>[]) => {
  const [t] = useTranslation('refitit');

  const postId = useGetState($gs.$session.$infos.optChaining().$id) ?? '';
  const role = useGetState($gs.$session.$infos.optChaining().$role) ?? '';
  const label = useGetState($gs.$session.$infos.optChaining().$label) ?? '';

  return useCallback(
    (kanban: Kanban): RawActionButtonDesc<Store, StoreState>[] => {
      if (
        !isTruthyAndNotEmpty(postId) ||
        !isTruthyAndNotEmpty(role) ||
        !isTruthyAndNotEmpty(label)
      ) {
        return [];
      }

      const actions: RawActionButtonDesc<Store, StoreState>[] = [];

      actions.push({
        id: 'detailsAction',
        label: t('operatorActions.details.label'),
        tooltip: t('operatorActions.details.tooltip'),
        disabled: false,
        action: ({ navigate }: ActionContext<Store, StoreState>) => {
          navigate(computeKanbanDetailsPath(kanban.id));
        },
      });

      return actions;
    },
    [postId, role, label, t]
  );
};
