import type { JSX } from 'react';
import React, { useCallback } from 'react';
import { iconHelpers } from '@stimcar/libs-uitoolkit';

export interface StandSelectionButtonProps {
  readonly iconId: string;
  readonly standId: string;
  readonly tooltip?: string;
  readonly clickHandler: (standId: string) => Promise<void> | void;
  readonly additionalClass?: string;
  readonly showLabel?: boolean;
}

export function StandSelectionButton({
  clickHandler,
  standId,
  tooltip,
  iconId,
  additionalClass,
  showLabel = false,
}: StandSelectionButtonProps): JSX.Element {
  const { style, icon } = iconHelpers.getFontAwesomeStyleAndIconFromIconId(iconId);

  const onClick = useCallback(async () => {
    await clickHandler(standId);
  }, [clickHandler, standId]);

  return (
    <>
      <button
        type="button"
        className={`button is-block is-fullwidth p-t-xs p-r-xs p-l-xs p-b-xxs ${
          additionalClass || ''
        }`}
        title={!tooltip ? standId : tooltip}
        onClick={onClick}
        style={{ height: '100%' }}
      >
        <div title={tooltip}>
          <i className={`fa${style} fa-${icon}`} />
        </div>
        {showLabel && (
          <div title={tooltip} className="is-size-7">
            {standId}
          </div>
        )}
      </button>
    </>
  );
}
