import type { JSX } from 'react';
import i18next from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ExtendedRepository, PackageDealDescExtender } from '@stimcar/core-libs-repository';
import type { Contract, ContractConfiguration, Sequence } from '@stimcar/libs-base';
import type {
  StandardPictureCategory,
  StandardPictureConfEntry,
  StandardPictureKey,
} from '@stimcar/libs-kernel';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type {
  CheckFormConsistencyAction,
  CheckFormFieldContentActions,
  CreationToolkit,
} from '@stimcar/libs-uitoolkit';
import { validatePackageDealDesc } from '@stimcar/core-libs-common';
import { PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX } from '@stimcar/core-libs-repository';
import {
  contractHelpers,
  CoreBackendRoutes,
  enumerate,
  globalHelpers,
  groupBy,
  PackageDealTargets,
  SPARE_PART_MANAGEMENT_TYPES,
  urlHelpers,
} from '@stimcar/libs-base';
import {
  EXTERNAL_STANDARD_PICTURE_KEYS,
  INTERNAL_STANDARD_PICTURE_KEYS,
  isTruthy,
  isTruthyAndNotEmpty,
  keysOf,
  nonnull,
} from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import {
  InputFormField,
  ModalCardDialog,
  ReactSelectFormField,
  ReadOnlyInputFormField,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { CheckboxFormField } from '../../../lib/bulma/form/CheckboxFormField.js';
import { CreatableElementListFormField } from '../../../lib/bulma/form/custom/CreatableElementListFormField.js';
import { ReactSelectMultiFormField } from '../../../lib/bulma/form/custom/ReactSelectMultiFormField.js';
import { TextAreaFormField } from '../../../lib/bulma/form/TextAreaFormField.js';
import { isPositiveFloatCheck } from '../../../lib/utils/commonFormChecks.js';
import { StandardPicturesTableFormField } from '../standardPictures/StandardPicturesTableFormField.js';
import type {
  AdminContractsState,
  AdminUIContract,
  ContractEditionModalMode,
  EditContractAttachmentFolderModalState,
  EditContractData,
  EditContractDialogState,
  OnContractsChangeActionCallback,
} from './typings/store.js';
import {
  EditContractAttachmentFolderModal,
  initializeEditContractAttachmentFolderModalState,
} from './EditContractAttachmentModal.js';
import { EMPTY_EDIT_CONTRACT_DIALOG_STATE, PRICE_ROUNDING_STEPS } from './typings/store.js';

const SPARE_PART_MARGIN_PERCENTAGE_MINIMAL_VALUE = 0;
const SPARE_PART_MARGIN_PERCENTAGE_MAXIMAL_VALUE = 1;

const MARGIN_MINIMAL_VALUE = SPARE_PART_MARGIN_PERCENTAGE_MINIMAL_VALUE * 100;
const MARGIN_MAXIMAL_VALUE = SPARE_PART_MARGIN_PERCENTAGE_MAXIMAL_VALUE * 100;

function getConfigurationEntriesForCategory(
  category: StandardPictureCategory,
  keys: readonly string[],
  currentConfigurationsByKey: Record<StandardPictureKey, readonly StandardPictureConfEntry[]>,
  sequence: Sequence
): readonly StandardPictureConfEntry[] {
  return keys.map((key) => {
    const configuration: StandardPictureConfEntry = currentConfigurationsByKey[key]?.[0];
    if (isTruthy(configuration)) {
      return configuration;
    }
    return {
      id: sequence.next(),
      key,
      category,
      displayed: false,
      improveImage: false,
    };
  });
}

function computeInitialStandardPictureConfiguration(
  currentConfigurations: readonly StandardPictureConfEntry[],
  sequence: Sequence
): readonly StandardPictureConfEntry[] {
  const currentConfigurationsByKey = groupBy(currentConfigurations ?? [], ({ key }) => key);
  const externalEntries = getConfigurationEntriesForCategory(
    'external',
    EXTERNAL_STANDARD_PICTURE_KEYS,
    currentConfigurationsByKey,
    sequence
  );
  const internalEntries = getConfigurationEntriesForCategory(
    'internal',
    INTERNAL_STANDARD_PICTURE_KEYS,
    currentConfigurationsByKey,
    sequence
  );
  return [...externalEntries, ...internalEntries];
}

export function openCreateContractModalAction({
  actionDispatch,
  getGlobalState,
  httpClient,
}: ActionContext<Store, EditContractDialogState>): void {
  const sequence = httpClient.getBrowserSequence();
  const { packageDealDatabases } = nonnull(getGlobalState().siteConfiguration);

  const initialStandardPicturesConfigurations = computeInitialStandardPictureConfiguration(
    [],
    sequence
  );

  actionDispatch.setValue({
    ...EMPTY_EDIT_CONTRACT_DIALOG_STATE,
    allpackageDealDatabases: packageDealDatabases,
    active: true,
    formData: {
      ...EMPTY_EDIT_CONTRACT_DIALOG_STATE.formData,
      standardPicturesConfiguration: initialStandardPicturesConfigurations,
    },
  });
}

export function deletedContractAttachmentFolderAction(
  { actionDispatch }: ActionContext<Store, EditContractAttachmentFolderModalState>,
  id: string
): void {
  actionDispatch.reduce((initial): EditContractAttachmentFolderModalState => {
    return {
      ...initial,
      attachmentFolders: initial.attachmentFolders.filter((f) => f.id !== id),
    };
  });
}

async function getAvailablePackageDealCodes(
  packageDealDescRepository: ExtendedRepository<'packageDealDesc', PackageDealDescExtender>,
  selectedPackageDealDatabase: string
): Promise<string[]> {
  const packageDealDesc = await packageDealDescRepository.getEntitiesFromIndex(
    PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX,
    selectedPackageDealDatabase
  );
  return packageDealDesc
    .filter((pdd) => validatePackageDealDesc(pdd).isValid)
    .map(({ code }) => code);
}

async function getCostFreePackageDealCodes(
  packageDealDescRepository: ExtendedRepository<'packageDealDesc', PackageDealDescExtender>,
  selectedPackageDealDatabase: string
): Promise<string[]> {
  const packageDealDesc = await packageDealDescRepository.getEntitiesFromIndex(
    PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX,
    selectedPackageDealDatabase
  );
  return packageDealDesc.filter((pdd) => pdd.priceExpression === '0').map((pdd) => pdd.code);
}

export async function openEditContractModalAction(
  {
    actionDispatch,
    getGlobalState,
    packageDealDescRepository,
    httpClient,
  }: ActionContext<Store, AdminContractsState>,
  contract: AdminUIContract
): Promise<void> {
  const { packageDealDatabases } = nonnull(getGlobalState().siteConfiguration);
  const availablePackageDealDescCodes = await getAvailablePackageDealCodes(
    packageDealDescRepository,
    contract.packageDealDatabase
  );

  const costFreePackageDealDescCodes = await getCostFreePackageDealCodes(
    packageDealDescRepository,
    contract.packageDealDatabase
  );

  // Collect available packages deals (for kanban / for purchase orders)
  // FIXME : Today we consider every workflow will have the same automatic package deal list as we only have "VO"
  //  workflow, so we merge all package deals in a same array
  const pkgDealDescCodesForKanban = keysOf(
    contract.configuration.pkgDealDescCodesForKanban
  ).flatMap((workflowId) => contract.configuration.pkgDealDescCodesForKanban[workflowId]);
  const pkgDealDescCodesForPurchaseOrder = keysOf(
    contract.configuration.pkgDealDescCodesForPurchaseOrder
  ).flatMap((workflowId) => contract.configuration.pkgDealDescCodesForPurchaseOrder[workflowId]);

  const initialStandardPicturesConfiguration: readonly StandardPictureConfEntry[] =
    computeInitialStandardPictureConfiguration(
      contract.configuration.standardPicturesConfiguration,
      httpClient.getBrowserSequence()
    );

  actionDispatch.reduce((initial: AdminContractsState): AdminContractsState => {
    return {
      ...initial,
      editContractDialogState: {
        ...EMPTY_EDIT_CONTRACT_DIALOG_STATE,
        mode: 'update',
        active: true,
        contractCode: contract.id,
        allpackageDealDatabases: packageDealDatabases,
        availablePackageDealDescCodes,
        costFreePackageDealDescCodes,
        editContractAttachmentFolderModal: {
          ...initial.editContractDialogState.editContractAttachmentFolderModal,
          attachmentFolders: contract.configuration.documents,
        },
        formData: {
          label: contract.label,
          packageDealDatabase: contract.packageDealDatabase,
          code: contract.id,
          hourlyRate: String(contract.configuration.hourlyRate),
          roundPriceTo: String(contract.configuration.roundPriceTo),
          sparePartMargin: String(contract.configuration.sparePartMarginPercentage * 100),
          estimateValidationMailingList: contract.configuration.estimateValidationMailingList ?? [],
          sparePartManagementType: contract.configuration.sparePartManagementType,
          pkgDealDescCodesForKanban: Array.from(pkgDealDescCodesForKanban),
          pkgDealDescCodesForPurchaseOrder: contract.configuration.handleMultiplePurchaseOrders
            ? Array.from(pkgDealDescCodesForPurchaseOrder)
            : [],
          upstreamTC: contract.configuration.upstreamTC,
          mandatoryFields: contract.configuration.mandatoryFields,
          handleMultiplePurchaseOrders: contract.configuration.handleMultiplePurchaseOrders,
          providerSpecificMargin: contract.configuration.providerSpecificMargin ?? false,
          estimateMention: contract.configuration.estimateMention,
          expertHints: contract.configuration.expertHints,
          standardPicturesEnabled: contract.configuration.standardPicturesEnabled,
          standardPicturesConfiguration: initialStandardPicturesConfiguration,
          attributeDescs: JSON.stringify(contract.configuration.attributeDescs, undefined, 2),
          memoDescs: JSON.stringify(contract.configuration.memoDescs, undefined, 2),
          warnings: {},
        },
      },
    };
  });
}

/** StandardPictureConfEntry are saved only if the following conditions are true :
 *   1. Standard pictures table is enabled
 *   2. The entry is selected
 */
function computeStandardPicturesConfigurationToSave(
  standardPicturesEnabled: boolean,
  standardPicturesConfiguration: readonly StandardPictureConfEntry[]
): readonly StandardPictureConfEntry[] {
  if (standardPicturesEnabled) {
    return standardPicturesConfiguration.filter(({ displayed }) => displayed);
  }
  return [];
}

async function updateContractAction(
  {
    getState,
    httpClient,
    actionDispatch,
    getGlobalState,
  }: ActionContext<Store, AdminContractsState>,
  onContractsChangeActionCallback: OnContractsChangeActionCallback
): Promise<void> {
  const { siteConfiguration } = nonnull(getGlobalState());
  const { formData, mode, editContractAttachmentFolderModal } = getState().editContractDialogState;
  const {
    label,
    packageDealDatabase,
    estimateValidationMailingList,
    sparePartManagementType,
    hourlyRate,
    sparePartMargin,
    pkgDealDescCodesForKanban,
    pkgDealDescCodesForPurchaseOrder,
    roundPriceTo,
    upstreamTC,
    mandatoryFields,
    handleMultiplePurchaseOrders,
    providerSpecificMargin,
    attributeDescs,
    estimateMention,
    standardPicturesEnabled,
    standardPicturesConfiguration,
    expertHints,
    memoDescs,
  } = formData;

  const { workflows } = siteConfiguration;
  // FIXME : We consider every workflow will have the same automatic package deal list as we only have "VO" workflow.
  // If we create other workflows in the future, this limitation should be revisited
  const pkgDealDescCodesForKanbanRecord: ContractConfiguration['pkgDealDescCodesForKanban'] = {};
  const pkgDealDescCodesForPurchaseOrderRecord: ContractConfiguration['pkgDealDescCodesForPurchaseOrder'] =
    {};
  workflows.forEach(({ id: workflowId }) => {
    pkgDealDescCodesForKanbanRecord[workflowId] = pkgDealDescCodesForKanban;
    pkgDealDescCodesForPurchaseOrderRecord[workflowId] = pkgDealDescCodesForPurchaseOrder;
  });

  const standardPicturesConfigurationToSave: readonly StandardPictureConfEntry[] =
    computeStandardPicturesConfigurationToSave(
      standardPicturesEnabled,
      standardPicturesConfiguration
    );

  const contractConfiguration: ContractConfiguration = {
    estimateValidationMailingList,
    roundPriceTo: Number.parseFloat(roundPriceTo),
    sparePartManagementType,
    documents: editContractAttachmentFolderModal.attachmentFolders,
    hourlyRate: Number.parseFloat(hourlyRate),
    sparePartMarginPercentage: Number.parseInt(sparePartMargin, 10) / 100,
    pkgDealDescCodesForKanban: pkgDealDescCodesForKanbanRecord,
    handleMultiplePurchaseOrders,
    providerSpecificMargin,
    pkgDealDescCodesForPurchaseOrder: pkgDealDescCodesForPurchaseOrderRecord,
    upstreamTC,
    mandatoryFields,
    estimateMention,
    expertHints,
    standardPicturesEnabled,
    standardPicturesConfiguration: standardPicturesConfigurationToSave,
    attributeDescs: JSON.parse(attributeDescs),
    memoDescs: JSON.parse(memoDescs),
  };
  if (mode === 'create') {
    const { code } = formData;
    const contractToCreate: Contract = {
      code,
      label,
      packageDealDatabase,
      configuration: contractConfiguration,
    };
    await httpClient.httpPostAsJSON(CoreBackendRoutes.CREATE_CONTRACT, contractToCreate);
    {
      // Update the UI (substitue code / id)
      const { code, ...rest } = contractToCreate;
      await actionDispatch.execCallback(
        onContractsChangeActionCallback,
        [{ ...rest, id: code }],
        []
      );
    }
  } else {
    const { contractCode } = getState().editContractDialogState;
    const contractToUpdate: Omit<Contract, 'code'> = {
      label,
      packageDealDatabase,
      configuration: contractConfiguration,
    };
    await httpClient.httpPostAsJSON(
      CoreBackendRoutes.UPDATE_CONTRACT(nonnull(contractCode)),
      contractToUpdate
    );
    // Update the UI
    await actionDispatch.execCallback(
      onContractsChangeActionCallback,
      [{ ...contractToUpdate, id: nonnull(contractCode) }],
      []
    );
  }
  actionDispatch.setProperty('editContractDialogState', EMPTY_EDIT_CONTRACT_DIALOG_STATE);
}

function checkIfPackageDealsAreAvailable(
  availablePackageDealDescCodes: readonly string[],
  testedPackageDealCodes: readonly string[]
): string | undefined {
  const forbiddenPackageDealCodes = testedPackageDealCodes //
    .filter((pkgDealCode) => !availablePackageDealDescCodes.includes(pkgDealCode));

  if (forbiddenPackageDealCodes.length > 0) {
    return i18next.t('adminContracts:warning.unknownPackageDealCode', {
      codes: enumerate([...new Set(forbiddenPackageDealCodes)]),
    });
  }
  return undefined;
}

function checkIfPackageDealsAreCostFree(
  costFreePackageDealDescCodes: readonly string[],
  testedPackageDealCodes: readonly string[]
): string | undefined {
  const forbiddenPackageDealCodes = testedPackageDealCodes.filter(
    (pkgDealCode) => !costFreePackageDealDescCodes.includes(pkgDealCode)
  );

  if (forbiddenPackageDealCodes.length > 0) {
    return i18next.t('adminContracts:warning.nonCostFreePackageDeals', {
      codes: enumerate([...forbiddenPackageDealCodes]),
    });
  }
  return undefined;
}

const checkFormConsistencyAction: CheckFormConsistencyAction<
  Store,
  EditContractDialogState,
  [readonly AdminUIContract[], ContractEditionModalMode]
> = ({ formState }): string | undefined => {
  const { availablePackageDealDescCodes, costFreePackageDealDescCodes, formData } = formState;
  const pkgDealDescCodesForKanbanError = checkIfPackageDealsAreAvailable(
    availablePackageDealDescCodes,
    formData.pkgDealDescCodesForKanban
  );
  if (isTruthy(pkgDealDescCodesForKanbanError)) {
    return pkgDealDescCodesForKanbanError;
  }

  const pkgDealDescCodesForPurchaseOrderError = checkIfPackageDealsAreAvailable(
    availablePackageDealDescCodes,
    formData.pkgDealDescCodesForPurchaseOrder
  );
  if (isTruthy(pkgDealDescCodesForPurchaseOrderError)) {
    return pkgDealDescCodesForPurchaseOrderError;
  }

  if (formData.handleMultiplePurchaseOrders) {
    const pkgDealDescCodesForPurchaseOrderError = checkIfPackageDealsAreCostFree(
      costFreePackageDealDescCodes,
      formData.pkgDealDescCodesForKanban
    );
    if (isTruthy(pkgDealDescCodesForPurchaseOrderError)) {
      return pkgDealDescCodesForPurchaseOrderError;
    }
  }

  return undefined;
};

const checkFieldContentActions: CheckFormFieldContentActions<
  Store,
  EditContractDialogState,
  [readonly AdminUIContract[], ContractEditionModalMode]
> = {
  code: ({ value, t }, existingContracts, mode): string | undefined => {
    if (mode === 'create') {
      const forbiddenChars = urlHelpers.getURLParametersRestrictedCharacters(value);
      if (forbiddenChars.length > 0) {
        return t('warning.forbiddenCharsInCode', { forbiddenChars: enumerate(forbiddenChars) });
      }
      const existingCodes = existingContracts.map(({ id }) => id);
      if (existingCodes.includes(value)) {
        return t('warning.codeAlreadyExists');
      }
    }
    return undefined;
  },
  sparePartMargin: ({ value, t }): string | undefined => {
    const numberValue = Number.parseInt(value, 10);
    // This is totally arbitrary and its main purpose is to ensure than we receive a fraction as margin value
    if (numberValue < MARGIN_MINIMAL_VALUE || numberValue > MARGIN_MAXIMAL_VALUE) {
      return t('warning.incorrectSparePartPriceMarginValue', {
        min: MARGIN_MINIMAL_VALUE,
        max: MARGIN_MAXIMAL_VALUE,
      });
    }
    return undefined;
  },
  roundPriceTo: ({ value, t }): string | undefined => isPositiveFloatCheck(value, t, false),
  hourlyRate: ({ value, t }): string | undefined => isPositiveFloatCheck(value, t, false),
  memoDescs: ({ value, t }): string | undefined => {
    if (isTruthyAndNotEmpty(value)) {
      try {
        const json = JSON.parse(value);
        if (typeof json === 'object') {
          const keys = keysOf(json);
          const allCategories = keysOf(PackageDealTargets);
          const missingCategory = allCategories.filter((x) => !keys.includes(x));
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const additionalCategories = keys.filter((x) => !allCategories.includes(x as any));
          if (missingCategory.length > 0) {
            return t('warning.missingMemoCategory', { allCategories: enumerate(allCategories) });
          }
          if (additionalCategories.length > 0) {
            return t('warning.missingMemoCategory', {
              allCategories: enumerate(additionalCategories),
            });
          }
          for (const k of keys) {
            const descs = json[k];
            if (!Array.isArray(descs)) {
              return t('warning.incorrectMemoDescCategoryFormat', {
                category: k,
              });
            }
            const incorrect = descs.filter((d) => !contractHelpers.isAMemoDesc(d));
            if (incorrect.length > 0) {
              return t('warning.incorrectMemoDesc');
            }
            const descIdWithDot = descs
              .filter((d) => contractHelpers.hasMemoIdWithDot(d))
              .map(({ id }) => id);
            if (descIdWithDot.length) {
              return t('warning.incorrectMemoDescIdWithDot', {
                incorrectValues: enumerate([...descIdWithDot]),
              });
            }
          }
        } else {
          return t('warning.syntaxError');
        }
      } catch {
        return t('warning.syntaxError');
      }
    }
    return undefined;
  },
  attributeDescs: ({ value, t }): string | undefined => {
    if (isTruthyAndNotEmpty(value)) {
      try {
        const json = JSON.parse(value);
        if (Array.isArray(json)) {
          const incorrect = json.filter((ad) => !contractHelpers.isAnAttributeDesc(ad));
          if (incorrect.length > 0) {
            return t('warning.incorrectAttributeDesc');
          }
        } else {
          return t('warning.syntaxError');
        }
      } catch {
        return t('warning.syntaxError');
      }
    }
    return undefined;
  },
};

const updateMandatoryFields: (keyof EditContractData)[] = [
  'label',
  'packageDealDatabase',
  'sparePartMargin',
  'hourlyRate',
  'sparePartManagementType',
];
const creationMandatoryFields: (keyof EditContractData)[] = [...updateMandatoryFields, 'code'];

interface EditContractModalProps {
  readonly $: StoreStateSelector<Store, AdminContractsState>;
  readonly onContractsChangeActionCallback: OnContractsChangeActionCallback;
}

export function EditContractModal({
  $,
  onContractsChangeActionCallback,
}: EditContractModalProps): JSX.Element {
  const [t] = useTranslation('adminContracts');
  const { $editContractDialogState } = $;
  const { $editContractAttachmentFolderModal } = $editContractDialogState;
  const existingContracts = useGetState($.$items);
  const formWarning = useGetState($editContractDialogState.$formWarning);
  const mode = useGetState($editContractDialogState.$mode);
  const contractCode = useGetState($editContractDialogState.$contractCode);
  const allPackageDealDatabases = useGetState($editContractDialogState.$allpackageDealDatabases);
  const availablePackageDealDescCodes = useGetState(
    $editContractDialogState.$availablePackageDealDescCodes
  );
  const editContractAttachmentFolderModal = useGetState(
    $editContractDialogState.$editContractAttachmentFolderModal
  );

  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(updateContractAction, onContractsChangeActionCallback);
    },
    [onContractsChangeActionCallback],
    $
  );

  const [onFormSubmit, genericOnFormChange, $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    EditContractDialogState,
    [readonly AdminUIContract[], ContractEditionModalMode]
  >(
    {
      $: $editContractDialogState,
      mandatoryFields: mode === 'create' ? creationMandatoryFields : updateMandatoryFields,
      checkFieldContentActions,
      checkFormConsistencyAction,
      submitValidDataAction,
      t,
    },
    existingContracts,
    mode
  );

  const changePackageDealDatabaseAction = useActionCallback(
    async ({
      actionDispatch,
      packageDealDescRepository,
      getState,
    }: ActionContext<Store, EditContractDialogState>): Promise<void> => {
      const { formData } = getState();
      const availablePackageDealDescCodes = await getAvailablePackageDealCodes(
        packageDealDescRepository,
        formData.packageDealDatabase
      );
      const costFreePackageDealDescCodes = await getCostFreePackageDealCodes(
        packageDealDescRepository,
        formData.packageDealDatabase
      );

      actionDispatch.reduce((initial) => {
        return {
          ...initial,
          availablePackageDealDescCodes,
          costFreePackageDealDescCodes,
        };
      });
      await actionDispatch.execCallback(genericOnFormChange);
    },
    [genericOnFormChange],
    $editContractDialogState
  );

  const $packageDealDatabaseWithChangeTrigger = useSelectorWithChangeTrigger(
    $formDataWithChangeTrigger.$packageDealDatabase,
    changePackageDealDatabaseAction
  );

  const emailAddressFormatValidationFunction: (value: string) => string | undefined = (
    value: string
  ) => {
    return globalHelpers.isValidEmailAddressStructure(value.toLowerCase())
      ? undefined
      : t(`warning.malformedEmailAddress`);
  };

  const creationToolkit: CreationToolkit = {
    validationFunction: emailAddressFormatValidationFunction,
  };

  const sparePartManagementTypeSuggestions = useMemo(
    () =>
      SPARE_PART_MANAGEMENT_TYPES.map(
        (
          type
        ): {
          value: string;
          label: string;
        } => {
          return {
            value: type,
            label: t(`contractForm.sparePartManagementType.${type}`),
          };
        }
      ),
    [t]
  );

  const priceRoundingStepSuggestions = useMemo(
    () =>
      PRICE_ROUNDING_STEPS.map(
        (
          s
        ): {
          value: string;
          label: string;
        } => {
          return {
            value: String(s),
            label: String(s),
          };
        }
      ),
    []
  );

  const isContractWithMultiplePurchaseOrders = useGetState(
    $formDataWithChangeTrigger.$handleMultiplePurchaseOrders
  );

  const createNewAttachmentFolderActionCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> =>
      await actionDispatch
        .scopeProperty('editContractAttachmentFolderModal')
        .exec(initializeEditContractAttachmentFolderModalState),
    [],
    $editContractDialogState
  );

  const updateAttachmentFolderActionCallback = useActionCallback(
    async ({ actionDispatch, getState }): Promise<void> => {
      const { selectedAttachmentFolderId } = getState();
      await actionDispatch
        .scopeProperty('editContractAttachmentFolderModal')
        .exec(initializeEditContractAttachmentFolderModalState, selectedAttachmentFolderId);
    },
    [],
    $editContractDialogState
  );

  const deletedContractAttachmentFolderActionCallback = useActionCallback(
    async ({ actionDispatch, getState }): Promise<void> => {
      const { selectedAttachmentFolderId } = getState();
      await actionDispatch
        .scopeProperty('editContractAttachmentFolderModal')
        .exec(deletedContractAttachmentFolderAction, selectedAttachmentFolderId);
    },
    [],
    $editContractDialogState
  );

  return (
    <>
      <ModalCardDialog
        $active={$editContractDialogState.$active}
        titleIconId="user-plus"
        title={t('editModal.title')}
        onOkClicked={onFormSubmit}
        warning={formWarning}
      >
        {mode === 'create' && (
          <InputFormField
            label={t('contractForm.code')}
            placeholder={t('contractForm.code')}
            $={$formDataWithChangeTrigger.$code}
            horizontal
          />
        )}
        {mode === 'update' && (
          <ReadOnlyInputFormField
            label={t('contractForm.code')}
            value={nonnull(contractCode)}
            horizontal
          />
        )}
        <InputFormField
          label={t('contractForm.label')}
          placeholder={t('contractForm.label')}
          $={$formDataWithChangeTrigger.$label}
          horizontal
        />
        <ReactSelectFormField
          label={t('contractForm.packageDealDatabase')}
          suggestions={allPackageDealDatabases}
          $={$packageDealDatabaseWithChangeTrigger}
          creation={false}
          horizontal
        />
        <InputFormField
          label={t('contractForm.hourlyRate')}
          $={$formDataWithChangeTrigger.$hourlyRate}
          type="number"
          min={MARGIN_MINIMAL_VALUE}
          max={MARGIN_MAXIMAL_VALUE}
          horizontal
        />
        <ReactSelectFormField
          label={t('contractForm.roundPriceTo')}
          suggestions={priceRoundingStepSuggestions}
          $={$formDataWithChangeTrigger.$roundPriceTo}
          horizontal
        />
        <InputFormField
          label={t('contractForm.sparePartsPriceMargin')}
          $={$formDataWithChangeTrigger.$sparePartMargin}
          type="number"
          min={MARGIN_MINIMAL_VALUE}
          max={MARGIN_MAXIMAL_VALUE}
          horizontal
        />
        <ReactSelectMultiFormField
          label={t('contractForm.estimateValidationMailingList')}
          $={$formDataWithChangeTrigger.$estimateValidationMailingList}
          creation={creationToolkit}
          isClearable
          horizontal
        />
        <ReactSelectFormField
          label={t('contractForm.sparePartManagementType.title')}
          suggestions={sparePartManagementTypeSuggestions}
          $={$formDataWithChangeTrigger.$sparePartManagementType}
          horizontal
        />
        <CreatableElementListFormField
          createElement={createNewAttachmentFolderActionCallback}
          deleteSelectedElement={deletedContractAttachmentFolderActionCallback}
          editSelectedElement={updateAttachmentFolderActionCallback}
          elements={editContractAttachmentFolderModal.attachmentFolders}
          label={t('contractForm.attachmentFolders')}
          $selectedElementId={$editContractDialogState.$selectedAttachmentFolderId}
          horizontal
        />
        <ReactSelectMultiFormField
          label={t('contractForm.pkgDealDescCodesForKanban')}
          suggestions={availablePackageDealDescCodes}
          $={$formDataWithChangeTrigger.$pkgDealDescCodesForKanban}
          isClearable
          horizontal
        />
        <CheckboxFormField
          label={t('contractForm.upstreamTC')}
          $={$formDataWithChangeTrigger.$upstreamTC}
          horizontal
          style={{ verticalAlign: 'bottom' }}
        />
        <CheckboxFormField
          label={t('contractForm.mandatoryPurchaseOrders')}
          $={$formDataWithChangeTrigger.$mandatoryFields.$purchaseOrders}
          horizontal
          style={{ verticalAlign: 'bottom' }}
        />
        <CheckboxFormField
          label={t('contractForm.handleMultiplePurchaseOrders')}
          $={$formDataWithChangeTrigger.$handleMultiplePurchaseOrders}
          horizontal
          style={{ verticalAlign: 'bottom' }}
        />
        <CheckboxFormField
          label={t('contractForm.providerSpecificMargin')}
          $={$formDataWithChangeTrigger.$providerSpecificMargin}
          horizontal
          style={{ verticalAlign: 'bottom' }}
        />
        {isContractWithMultiplePurchaseOrders && (
          <ReactSelectMultiFormField
            label={t('contractForm.pkgDealDescCodesForPurchaseOrder')}
            suggestions={availablePackageDealDescCodes}
            $={$formDataWithChangeTrigger.$pkgDealDescCodesForPurchaseOrder}
            isClearable
            horizontal
          />
        )}
        <StandardPicturesTableFormField
          $isEnabled={$formDataWithChangeTrigger.$standardPicturesEnabled}
          $configuration={$formDataWithChangeTrigger.$standardPicturesConfiguration}
        />
        <TextAreaFormField
          label={t('contractForm.expertHints')}
          $={$formDataWithChangeTrigger.$expertHints}
        />
        <TextAreaFormField
          label={t('contractForm.estimateMention')}
          $={$formDataWithChangeTrigger.$estimateMention}
        />
        <TextAreaFormField
          label={t('contractForm.attributeDescs')}
          $={$formDataWithChangeTrigger.$attributeDescs}
        />
        <TextAreaFormField
          label={t('contractForm.memoDescs')}
          $={$formDataWithChangeTrigger.$memoDescs}
        />
      </ModalCardDialog>
      <EditContractAttachmentFolderModal $={$editContractAttachmentFolderModal} />
    </>
  );
}
