import type { BusinessObjectToFormData, WithFormValidationWarnings } from '@stimcar/libs-uikernel';
import type { FormWithValidationState } from '@stimcar/libs-uitoolkit';
import { EMPTY_FORM_WITH_VALIDATION_STATE } from '@stimcar/libs-uitoolkit';
import type { SelectListOrVirtualKeyboardInputState } from '../../input/typings/store.js';
import { EMPTY_SELECT_LIST_OR_VIRTUAL_KEYBOARD_STATE } from '../../input/typings/store.js';

export type LoginForm = BusinessObjectToFormData<{
  login: string;
  password: string;
}>;

export const EMPTY_LOGIN_FORM_STATE: WithFormValidationWarnings<LoginForm> = {
  login: '',
  password: '',
  warnings: {},
};

export type LoginViewState = FormWithValidationState<LoginForm>;

export type LoginModalDialogState = LoginViewState & {
  readonly loginModalActive: boolean;
  readonly fixedLogin: boolean;
  readonly alreadyKnownUsers: readonly string[];
  readonly selectOrVirtualKeyboardInputScopedState: SelectListOrVirtualKeyboardInputState;
};

export const EMPTY_LOGIN_MODAL_DIALOG_STATE: LoginModalDialogState = {
  ...EMPTY_FORM_WITH_VALIDATION_STATE,
  formData: EMPTY_LOGIN_FORM_STATE,
  loginModalActive: false,
  fixedLogin: false,
  alreadyKnownUsers: [],
  selectOrVirtualKeyboardInputScopedState: EMPTY_SELECT_LIST_OR_VIRTUAL_KEYBOARD_STATE,
};
