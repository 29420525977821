import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';

interface StimcarLogoProps {
  readonly blackAndWhite?: boolean;
  readonly style?: React.CSSProperties;
  readonly tooltip?: string;
}

export function StimcarLogo({
  blackAndWhite = false,
  style,
  tooltip,
}: StimcarLogoProps): JSX.Element {
  return (
    <img
      src={`img/${blackAndWhite ? 'stimcar-logo-off-16' : 'stimcar-logo-on-16'}.png`}
      style={style}
      alt={isTruthyAndNotEmpty(tooltip) ? tooltip : 'Stimcar logo'}
      title={tooltip}
      width="16"
    />
  );
}

interface PackageDealRecommendationLogoProps {
  readonly recommendedByExpert: boolean;
  readonly style?: React.CSSProperties;
}
export function PackageDealRecommendationLogo({
  recommendedByExpert,
  style,
}: PackageDealRecommendationLogoProps): JSX.Element {
  const [t] = useTranslation('libComponents');

  return (
    <StimcarLogo
      blackAndWhite={!recommendedByExpert}
      style={style}
      tooltip={
        recommendedByExpert
          ? t('expertRecommendation.recommendedIconTooltip')
          : t('expertRecommendation.notRecommendedIconTooltip')
      }
    />
  );
}
