/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-console */

// The label is padded in order to preserve a global alignment
const CATEGORY_PADDING_LENGTH = 30;
const CATEGORY_PADDING = new Array(CATEGORY_PADDING_LENGTH + 1).join(' ');

interface ColorDef {
  readonly ansiVT100Code: number;
  readonly cssName: string;
}

function c(ansiVT100Code: number, cssName: string): ColorDef {
  return {
    ansiVT100Code,
    cssName,
  };
}

// A color is given to each javascript module
const colors: ColorDef[] = [
  // 30, // 	Black -> we don't use black color
  c(92, 'LightGreen '), // 	Light green
  c(93, 'LightYellow'), // 	Light yellow
  c(94, 'LightBlue'), // 	Light blue
  c(95, '#FF66FF'), // 	Light magenta
  c(96, 'LightCyan'), // 	Light cyan
  c(91, 'LightCoral'), // 	Light red
  c(37, 'LightGrey'), // 	Light gray
  c(90, 'DarkGrey'), // 	Dark gray
  c(34, 'Blue'), // 	Blue
  c(32, 'Green'), // 	Green
  c(35, 'Magenta'), // 	Magenta
  c(36, 'Cyan'), // 	Cyan
  c(33, 'Yellow'), // 	Yellow
  c(31, 'Red'), // 	Red
  c(97, 'White'), // 	White
  c(39, ''), // Default foreground color
];

/**
 * Log levels.
 */
export enum Level {
  debug = 'DEB',
  verbose = 'VER',
  info = 'INF',
  warn = 'WAR',
  error = 'ERR',
}

const LEVELS = {
  [Level.debug]: 0,
  [Level.verbose]: 1,
  [Level.info]: 2,
  [Level.warn]: 3,
  [Level.error]: 4,
};

const LevelsMap: Record<string | Level, Level> = {
  debug: Level.debug,
  [Level.debug.toLowerCase()]: Level.debug,
  verbose: Level.verbose,
  [Level.verbose.toLowerCase()]: Level.verbose,
  info: Level.info,
  [Level.info.toLowerCase()]: Level.info,
  warn: Level.warn,
  [Level.warn.toLowerCase()]: Level.warn,
  error: Level.error,
  [Level.error.toLowerCase()]: Level.error,
};

function getConsoleMethod(level: Level): (message?: any, ...optionalParams: any[]) => void {
  switch (level) {
    case Level.error:
      return console.error;
    case Level.warn:
      return console.warn;
    case Level.info:
      return console.info;
    case Level.verbose:
    case Level.debug:
    default:
      return console.debug;
  }
}

/**
 * Converts a string to a level.
 * @param level the level to convert.
 */
function toLogLevel(level: string | undefined): Level {
  if (level) {
    const result = LevelsMap[level.toLowerCase()];
    if (result) {
      return result;
    }
  }
  return Level.info;
}

/** Current level */
let defaultLevel = toLogLevel(process.env.LOG_LEVEL);
const rules = new Map<RegExp, Level>();
const levelsByCategory = new Map<string, Level>();
/**
 * Change the log level.
 * @param newLevel the new level.
 */
export function setLogLevel(newLevel: Level | string | undefined): void {
  defaultLevel = toLogLevel(newLevel);
  // Invalidate levels cache
  levelsByCategory.clear();
}

export const registerLogLevelRule = (regexp: RegExp, level: Level): void => {
  rules.set(regexp, level);
  // Invalidate levels cache
  levelsByCategory.clear();
};

export const getCategoryLogLevel = (category: string): Level => {
  let level = levelsByCategory.get(category);
  if (!level) {
    [...rules.keys()].forEach((regexp) => {
      const ruleLevel = rules.get(regexp);
      if (regexp.exec(category) && (!level || (ruleLevel && LEVELS[ruleLevel] > LEVELS[level]))) {
        level = ruleLevel;
      }
    });
    if (!level) {
      level = defaultLevel;
    }
    levelsByCategory.set(category, level);
  }
  return level;
};

interface InternalLogger {
  /**
   * Appends a log at debug level.
   * @param  {Array<any>} data the data to log.
   * @return {void}
   */
  log: (
    level: Level,
    time: string,
    categoryColorIdx: number,
    bold: boolean,
    category: string,
    ...data: any
  ) => void;
}

class NodeLogger implements InternalLogger {
  /**
   * Applies a given style to a string.
   * @see https://misc.flogisoft.com/bash/tip_colors_and_formatting
   * @param  {string} str the string to style.
   * @param  {number} styleId the style to apply.
   * @return {string}     the styled string.
   */
  private static style(str: string, styleId: number): string {
    return `\u001b[${styleId}m${str}\u001b[39m`;
  }

  public log = (
    level: Level,
    time: string,
    categoryColorIdx: number,
    bold: boolean,
    category: string,
    ...data: any
  ): void => {
    const log = `${time} ${NodeLogger.style(
      category,
      colors[categoryColorIdx].ansiVT100Code
    )} ${level}`;
    const logFunction = getConsoleMethod(level);
    if (bold) {
      Reflect.apply(logFunction, console, [`\u001b[1m${log}\u001b[0m`, ...data]);
    } else {
      Reflect.apply(logFunction, console, [log, ...data]);
    }
  };
}

class BrowserLogger implements InternalLogger {
  public log = (
    level: Level,
    time: string,
    categoryColorIdx: number,
    bold: boolean,
    category: string,
    ...data: any
  ): void => {
    const log = `%c${time} %c${category}%c ${level}`;
    const logFunction = getConsoleMethod(level);
    if (bold) {
      Reflect.apply(logFunction, console, [
        log,
        'font-weight: bold;',
        `font-weight: bold;color: ${colors[categoryColorIdx].cssName};`,
        'font-weight: bold;',
        ...data,
      ]);
    } else {
      Reflect.apply(logFunction, console, [
        log,
        '',
        `color: ${colors[categoryColorIdx].cssName}`,
        '',
        ...data,
      ]);
    }
  };
}

/**
 * Winston wrapper logger implementation.
 */
export class Logger {
  private static colorIdxCursor = 0;

  private impl: InternalLogger;

  private category: string;

  private colorId: number;

  private isBrowser: boolean;

  /**
   * Factory method.
   * @param  {string} metaUrl      the file name where the logger is created (supposed to be __file__).
   * @return {Logger}              the newly created logger.
   */
  public static new(metaUrl: string): Logger {
    const filename = new URL('', metaUrl).pathname;
    return new Logger(filename);
  }

  /**
   * Default constructor.
   * @param  {string} filename      the file name where the logger is created (supposed to be __file__).
   */
  private constructor(filename: string) {
    // eslint-disable-next-line no-new-func
    this.isBrowser = new Function('try {return this===window;}catch(e){ return false;}')();
    this.impl = this.isBrowser ? new BrowserLogger() : new NodeLogger();

    const filenameWithoutExtension = filename.replace(/.[jt]s[x]?$/, '');
    if (filenameWithoutExtension.length < CATEGORY_PADDING_LENGTH) {
      this.category = `${filenameWithoutExtension}${CATEGORY_PADDING}`.substring(
        0,
        CATEGORY_PADDING_LENGTH
      );
    } else {
      this.category = filenameWithoutExtension.substring(
        filenameWithoutExtension.length - CATEGORY_PADDING_LENGTH
      );
    }

    // Select category color & increment color cursor
    this.colorId = Logger.colorIdxCursor;
    // Reset cursor if required
    Logger.colorIdxCursor += 1;
    if (Logger.colorIdxCursor >= colors.length) {
      Logger.colorIdxCursor = 0;
    }

    // Logger creation notification
    this.debug('Logger created for', filename.replace(/^.*refitit(\/|\\)code(\/|\\)/, ''));
  }

  private log(level: Level, ...data: any): void {
    const categoryLevel = getCategoryLogLevel(this.category);
    if (LEVELS[categoryLevel] <= LEVELS[level]) {
      this.impl.log(
        level,
        this.isBrowser ? new Date().toISOString().replace(/^.*T/, '') : new Date().toISOString(),
        this.colorId,
        level === Level.warn || level === Level.error,
        this.category,
        ...data
      );
    }
  }

  /**
   * Appends a log at debug level.
   * @param  {Array<any>} data the data to log.
   * @return {void}
   */
  public debug(...data: any): void {
    this.log(Level.debug, ...data);
  }

  /**
   * Appends a log at verbose level.
   * @param  {Array<any>} data the data to log.
   * @return {void}
   */
  public verbose(...data: any): void {
    this.log(Level.verbose, ...data);
  }

  /**
   * Appends a log at info level.
   * @param  {Array<any>} data the data to log.
   * @return {void}
   */
  public info(...data: any): void {
    this.log(Level.info, ...data);
  }

  /**
   * Appends a log at warn level.
   * @param  {Array<any>} data the data to log.
   * @return {void}
   */
  public warn(...data: any): void {
    this.log(Level.warn, ...data);
  }

  /**
   * Appends a log at error level.
   * @param  {Array<any>} data the data to log.
   * @return {void}
   */
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  public error(...data: any): void {
    this.log(Level.error, ...data);
  }
}
