/* eslint-disable jsx-a11y/control-has-associated-label */
import type { JSX } from 'react';
import React, { useMemo } from 'react';
import type { Entity } from '@stimcar/libs-kernel';
import type { NoArgActionCallback, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { FormFieldEntry } from '@stimcar/libs-uitoolkit';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useGetState } from '@stimcar/libs-uikernel';
import { FaIcon, ListSelect } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../app/state/typings/store.js';

export interface LabelledEntity extends Entity {
  readonly label: string;
}

export interface LabelledEntityListProps<T extends LabelledEntity> {
  readonly labelledEntities: readonly T[];
  readonly editSelectedLabelledEntity: NoArgActionCallback<Store>;
  readonly deleteSelectedLabelledEntity: NoArgActionCallback<Store>;
  readonly createLabelledEntity: NoArgActionCallback<Store>;
  readonly $selectedEntityId: StoreStateSelector<Store, string>;
  readonly creationDisabled?: boolean;
}

export function LabelledEntityList<T extends LabelledEntity>({
  labelledEntities,
  editSelectedLabelledEntity,
  deleteSelectedLabelledEntity,
  createLabelledEntity,
  $selectedEntityId,
  creationDisabled,
}: LabelledEntityListProps<T>): JSX.Element {
  const selectedId = useGetState($selectedEntityId);

  const labelledEntitiesEntries = useMemo((): FormFieldEntry<string>[] => {
    return labelledEntities.map((le): FormFieldEntry<string> => {
      return { id: le.id, label: le.label };
    });
  }, [labelledEntities]);

  return (
    <div className="columns">
      <div className="column is-1">
        <button
          type="button"
          className="button is-small"
          onClick={createLabelledEntity}
          disabled={creationDisabled}
        >
          <FaIcon id="plus-square" />
        </button>
        <button
          type="button"
          className="button is-small"
          onClick={editSelectedLabelledEntity}
          disabled={!isTruthyAndNotEmpty(selectedId)}
        >
          <FaIcon id="edit" />
        </button>
        <button
          type="button"
          className="button is-small"
          onClick={deleteSelectedLabelledEntity}
          disabled={!isTruthyAndNotEmpty(selectedId)}
        >
          <FaIcon id="trash" />
        </button>
      </div>
      <div className="column">
        <ListSelect
          $={$selectedEntityId}
          entries={labelledEntitiesEntries}
          height={140}
          hasBorder
        />
      </div>
    </div>
  );
}
