import type { MattermostMessage, MattermostMessageContextDetail } from '@stimcar/libs-base';
import { CoreBackendRoutes } from '@stimcar/libs-base';
import { Logger } from '@stimcar/libs-kernel';
import type { RepositoryHTTPClient } from '../httpclient/index.js';
import type { MattermostLogger } from './typings/MattermostLogger.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

export class MattermostLoggerImpl implements MattermostLogger {
  private readonly httpClient;

  constructor(httpClient: RepositoryHTTPClient) {
    this.httpClient = httpClient;
  }

  public info(
    title: string,
    message: string,
    titleContext?: string,
    messageContext?: readonly MattermostMessageContextDetail[]
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.httpClient
      .httpPostAsJSON<MattermostMessage, null>(CoreBackendRoutes.MATTERMOST_LOGGER, {
        channel: 'LOG',
        titleContext,
        title,
        messageContext,
        message,
      })
      .catch((error) => log.error('Unexpected error during mattermost info log attempt:', error));
  }

  public error(
    title: string,
    message: string,
    titleContext?: string,
    messageContext?: readonly MattermostMessageContextDetail[]
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.httpClient
      .httpPostAsJSON<MattermostMessage, null>(CoreBackendRoutes.MATTERMOST_LOGGER, {
        channel: 'ERRORS',
        titleContext,
        title,
        messageContext,
        message,
      })
      .catch((error) => log.error('Unexpected error during mattermost error log attempt:', error));
  }
}
