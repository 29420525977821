/* eslint-disable jsx-a11y/control-has-associated-label */
import type { JSX } from 'react';
import React, { useMemo } from 'react';
import type { Entity } from '@stimcar/libs-kernel';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { FormFieldEntry, FormFieldProps } from '@stimcar/libs-uitoolkit';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useFormFieldWarning, useGetState } from '@stimcar/libs-uikernel';
import { Button, FormField, ListSelect } from '@stimcar/libs-uitoolkit';

interface ListType extends Entity {
  readonly label?: string;
}

export interface CreatableElementListFormFieldProps<SD extends AnyStoreDef, T extends ListType>
  extends Omit<FormFieldProps, 'children' | 'warning'> {
  readonly elements: readonly T[];
  readonly editSelectedElement: () => Promise<void>;
  readonly deleteSelectedElement: () => Promise<void>;
  readonly createElement: () => Promise<void>;
  readonly $selectedElementId: StoreStateSelector<SD, string>;
}

export function CreatableElementListFormField<SD extends AnyStoreDef, T extends ListType>({
  label,
  horizontal,
  elements,
  editSelectedElement,
  deleteSelectedElement,
  createElement,
  $selectedElementId,
  noExclamationTriangleIfWarning,
}: CreatableElementListFormFieldProps<SD, T>): JSX.Element {
  const selectedId = useGetState($selectedElementId);
  const warning = useFormFieldWarning($selectedElementId);

  const orderElementEntries = useMemo(() => {
    return elements.map((e): FormFieldEntry<string> => {
      return { id: e.id, label: isTruthyAndNotEmpty(e.label) ? e.label : e.id };
    });
  }, [elements]);

  return (
    <FormField
      label={label}
      warning={warning}
      horizontal={horizontal}
      noExclamationTriangleIfWarning={noExclamationTriangleIfWarning}
    >
      <div className="columns p-xs">
        <div className="column is-narrow">
          <div className="m-b-sm">
            <Button onClick={createElement} iconId="plus-square" tooltip="create" size="small" />
          </div>
          <div className="m-b-sm">
            <Button
              onClick={editSelectedElement}
              iconId="edit"
              tooltip="edit"
              size="small"
              disabled={selectedId === ''}
            />
          </div>
          <div className="m-b-sm">
            <Button
              onClick={deleteSelectedElement}
              iconId="trash"
              tooltip="up"
              size="small"
              disabled={selectedId === ''}
            />
          </div>
        </div>
        <div className="column">
          <ListSelect $={$selectedElementId} entries={orderElementEntries} height={140} hasBorder />
        </div>
      </div>
    </FormField>
  );
}
