import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './PageLoader.scss';

export interface PageLoaderProps {
  readonly loadingStatus?: string | boolean;
  readonly children: JSX.Element | JSX.Element[];
}

export function PageLoader({ loadingStatus, children }: PageLoaderProps): JSX.Element {
  const [t] = useTranslation('refititCommonComponents');
  return (
    <>
      {loadingStatus ? (
        <div className="pageloader">
          <div className="loadingio-spinner-spinner-drn3j8p43kb">
            <div className="ldio-ge5i8uze656">
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
          <br />
          <span className="is-size-4">
            {typeof loadingStatus === 'string' ? loadingStatus : t('loading')}
          </span>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
}
