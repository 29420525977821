import type { SiteConfiguration } from '@stimcar/libs-base';
import type { GlobalStoreStateSelector } from '@stimcar/libs-uikernel';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../../../../app/state/typings/store.js';

export function getIconForWorkshopPost(
  configurationState: SiteConfiguration,
  standId: string,
  postCategoryLabel: string
): string {
  const icon = configurationState.iconDictionary[postCategoryLabel];
  if (isTruthyAndNotEmpty(icon)) {
    return icon;
  }
  const standIcon = configurationState.stands.find((s) => s.id === standId)?.iconClass;
  return isTruthyAndNotEmpty(standIcon) ? standIcon : 'hard-hat';
}

export function useGetIconForWorkshopPost(
  $gs: GlobalStoreStateSelector<Store>,
  standId: string,
  postCategoryLabel: string
): string {
  const configurationState = useGetState($gs.$siteConfiguration);
  return getIconForWorkshopPost(configurationState, standId, postCategoryLabel);
}
