import type { KanbanRepository } from '@stimcar/core-libs-repository';
import type { PurchaseOrder } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';

export function extractMessageFromError(error: Error): string {
  // message will be in the form :
  // "Unexpected HTTP request error for '/wan-api/invoice/refund' : Erreur lors de la création ... "
  const { message } = error;

  // First we get what is following the ':'
  // Find the index of the first colon
  const firstColonIndex = message.indexOf(':');
  if (firstColonIndex === -1) {
    return message;
  }

  // Extract the real message starting from the first colon
  return message.substring(firstColonIndex + 1).trim();
}

export async function getExistingPurchaseOrder(
  kanbanRepository: KanbanRepository,
  givenKanbanId: string,
  givenPurchaseOrderId: string
): Promise<PurchaseOrder> {
  const currentKanban = await kanbanRepository.getEntity(givenKanbanId);
  const currentPurchaseOrder = currentKanban.purchaseOrders.find(
    ({ id }) => id === givenPurchaseOrderId
  );
  if (!isTruthy(currentPurchaseOrder)) {
    throw new Error(
      `Can not retrieve purchaseOrderId: ${givenPurchaseOrderId} for kanbanId: ${givenKanbanId}`
    );
  }
  return currentPurchaseOrder;
}
