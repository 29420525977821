export const SUNDAY = 0;
export const MONDAY = 1;
export const TUESDAY = 2;
export const WEDNESDAY = 3;
export const THURSDAY = 4;
export const FRIDAY = 5;
export const SATURDAY = 6;
export type DAY_OF_WEEK =
  | typeof SUNDAY
  | typeof MONDAY
  | typeof TUESDAY
  | typeof WEDNESDAY
  | typeof THURSDAY
  | typeof FRIDAY
  | typeof SATURDAY;

export const ALL_DAYS_OF_WEEK_ALIAS = 7;
export const MONDAY_TO_FRIDAY_ALIAS = 8;
export const MONDAY_TO_SATURDAY_ALIAS = 9;
export const SUNDAY_TO_THURSDAY_ALIAS = 10;
export const TUESDAY_TO_SATURDAY_ALIAS = 11;

export const CROSS_SITES_SCHEDULED_TASK_TYPES = [
  'archiveKanbans',
  'closeEntities',
  'createInvoices',
] as const;
export const EMAIL_SCHEDULED_TASK_TYPES = [
  'invoiceMail',
  'ctmail',
  'carsexpectedatstandmail',
  'sparepartsmail',
  'dailyprodmail',
] as const;
export const IMPORT_SCHEDULED_TASK_TYPES = ['voLeanImport', 'airtableImport'] as const;

type CrossSitesScheduledTaskType = (typeof CROSS_SITES_SCHEDULED_TASK_TYPES)[number];
type EmailScheduledTaskType = (typeof EMAIL_SCHEDULED_TASK_TYPES)[number];
type ImportScheduledTaskType = (typeof IMPORT_SCHEDULED_TASK_TYPES)[number];

export type ScheduledTaskType =
  | CrossSitesScheduledTaskType
  | EmailScheduledTaskType
  | ImportScheduledTaskType;

export type ScheduledTaskSchedule = {
  readonly min?: readonly number[];
  readonly hour?: readonly number[];
  readonly weekday: readonly DAY_OF_WEEK[];
};

export type ScheduledTaskBaseItem<K extends ScheduledTaskType, C = object> = {
  readonly type: K;
  readonly label: string;
  readonly schedule: ScheduledTaskSchedule;
} & C;

export type ConnectorArgs = {
  readonly contractCode: string;
  readonly workflowId: string;
  readonly importErrorMail?: MailNotificationArgs;
};

export type VOLeanConnectorArgs = ConnectorArgs & {
  readonly voLeanClientId: string;
  readonly voLeanPole: string;
  readonly enablePkgDealsModifications: boolean;
  readonly qualityControlInVOLean: boolean;
};

export type VOLeanUploadArgs = Omit<VOLeanConnectorArgs, 'workflowId'>;

export enum ImportPackageDealTargetStatus {
  'toCreateAndValidate',
  'toCancel',
}

export type AirtableConnectorArgs = ConnectorArgs & {
  readonly token: string;
  readonly databaseId: string;
  readonly tableNames: readonly string[];
  // Tells whether the customer must be extracted from a table's column of from the table name
  readonly customerAsFieldMode?: boolean;
  // That name is just used in mattermost messages (to help
  // to understand which airtable database is used)
  readonly databaseName: string;
  // Some clients may want to remove some default available package deals
  readonly packageDealColumnsToHandle?: readonly PackageDealColumToHandle[];
  // Some clients may want to send some documents from airtable to minio
  readonly documentColumnsToDownload?: readonly ColumnToDestinationDef[];
  readonly textToDocumentColumnsToDownload?: readonly ColumnToDestinationDef[];
  readonly textColumnsToAttributes?: readonly ColumnToDestinationDef[];
};

export type PackageDealColumToHandleOption = {
  // The id/label of the option
  readonly id: string;
  readonly codesToCreateOrActivate: readonly string[];
};

export type PackageDealColumToHandle = {
  // The name of the AirTable column
  readonly id: string;
  readonly options: readonly PackageDealColumToHandleOption[];
};

export type ClientPackageDealsCustomStatus = {
  readonly apiPropertyLabel: string;
  readonly codesToCreateOrActivate: readonly string[] | undefined;
  readonly codesToRemove: readonly string[] | undefined;
};

export type ColumnToDestinationDef = {
  // The name of the AirTable column
  readonly id: string;
  // The name of the destination which can be an attribute or a folder in the attachments gallery
  readonly destination: string;
};

export type MailNotificationArgs = {
  readonly subject?: string;
  readonly to: readonly string[];
  readonly cc: readonly string[];
  readonly replyTo?: string;
};

export type CustomersBasedMailNotificationArgs = MailNotificationArgs & {
  readonly customersGroupName: string;
  readonly customerShortNames: readonly string[] | '*';
};

export type SparePartsMailNotificationArgs = CustomersBasedMailNotificationArgs;

export type DailyProdMailNotificationArgs = CustomersBasedMailNotificationArgs & {
  readonly standId: string;
  readonly displayEstimateInfos: boolean;
  readonly displayAcceptanceInfos: boolean;
};

export type InvoiceMailNotificationArgs = Omit<
  CustomersBasedMailNotificationArgs,
  'customersGroupName'
>;

export type CarsExpectedAtStandMailNotificationArgs = CustomersBasedMailNotificationArgs & {
  readonly standId: string;
  readonly mode: 'CarsList' | 'CarsListWithStandOperations';
  readonly tagsFilteringPkgs?: readonly string[];
};

export type ScheduledTask = {
  readonly id: string;
  readonly type: ScheduledTaskType;
  readonly label: string;
  readonly schedule: ScheduledTaskSchedule;
  readonly scheduledTaskArgs?:
    | VOLeanConnectorArgs
    | AirtableConnectorArgs
    | InvoiceMailNotificationArgs
    | DailyProdMailNotificationArgs
    | SparePartsMailNotificationArgs
    // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
    | CustomersBasedMailNotificationArgs
    | CarsExpectedAtStandMailNotificationArgs;
  readonly active: boolean;
};
