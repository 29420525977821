import { keysOf, Logger } from '@stimcar/libs-kernel';
import type { FilterExpr } from '../typings/FilterExpr.js';
import { filterExprToSQL } from '../FilterExpr.js';
import { enumerate } from '../misc.js';
import { camelToSnake, convertToSQL } from '../string-helper.js';
import type {
  DbTableMetadata,
  JsonFunctionClause,
  MariaDbConnection,
  MariaDbDAO,
  OrderBy,
  TableConfig,
} from './typings/MariaDbDAO.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

interface FindParams<DBT extends object> {
  readonly filter: FilterExpr<DBT>;
  readonly forUpdate: boolean;
  readonly findOne: boolean;
  readonly orderBy?: OrderBy<DBT>;
  readonly limit?: number;
  readonly offset?: number;
}

interface SelectParams<DBT extends object> extends FindParams<DBT> {
  readonly columns: readonly (keyof DBT | JsonFunctionClause<DBT>)[];
}

export class MariaDbDAOImpl<T extends object, DBT extends T = T> implements MariaDbDAO<T, DBT> {
  protected readonly txPromise: Promise<MariaDbConnection>;

  protected readonly tableName: string;

  protected readonly parseConfig: TableConfig<T>;

  protected readonly columnNames: readonly (keyof T)[];

  protected readonly pk: readonly (keyof DBT)[];

  constructor(
    txPromise: Promise<MariaDbConnection> | MariaDbConnection,
    schema: string | undefined,
    tableNameCamelCase: string,
    parseConfig: TableConfig<T>,
    pk: readonly (keyof DBT)[]
  ) {
    this.txPromise = Reflect.get(txPromise, 'beginTransaction')
      ? Promise.resolve(txPromise)
      : (txPromise as Promise<MariaDbConnection>);
    this.tableName = `${!schema ? '' : `${schema}.`}${camelToSnake(
      tableNameCamelCase
    ).toUpperCase()}`;
    this.parseConfig = parseConfig;
    this.columnNames = keysOf(parseConfig);
    this.pk = pk;
  }

  public metadata = (): DbTableMetadata<DBT> => {
    return {
      tableName: this.tableName,
      columnNames: this.columnNames,
    };
  };

  public getParseConfig = (): TableConfig<T> => this.parseConfig;

  protected fixRows = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rows: any[],
    columns: readonly (keyof DBT | JsonFunctionClause<DBT>)[]
  ): void => {
    // Remove mariadb metadata (https://mariadb.com/kb/en/connector-nodejs-promise-api/#column-metadata)
    Reflect.deleteProperty(rows, 'meta');
    // Replace JSON fields by parsed values
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rows.forEach((r: any) => {
      keysOf(r).forEach((k, columnIndex) => {
        // If we have a standard field that belong to the object, convert
        // according to table configuration
        const columnConfig = this.parseConfig[k as keyof T];
        if (columnConfig) {
          if (columnConfig === 'json') {
            Reflect.set(r, k, JSON.parse(r[k]));
          }
          if (columnConfig === 'boolean') {
            Reflect.set(r, k, r[k] === 1 || r[k] === true);
          }
        } else {
          // Otherwise, convert if we meet a json_query function
          // With MariaDb 10.4.11, the result is a string and needs to be parsed
          // With MariaDb 10.11.17, the result is directly a javascript object and thus does not need to be parsed
          const column = columns[columnIndex];
          if (
            typeof column === 'object' &&
            column.type === 'json_query' &&
            typeof r[k] === 'string'
          ) {
            Reflect.set(r, k, JSON.parse(r[k]));
          }
        }
      });
    });
  };

  private doSelect = async ({
    filter,
    columns,
    findOne,
    forUpdate,
    orderBy,
    limit,
    offset,
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SelectParams<DBT>): Promise<readonly any[]> => {
    const { expression: whereClause, parameters: whereClauseParameters } = filterExprToSQL(filter);
    const sql = `select ${enumerate(
      columns.map((column) => {
        // json_value or json_query clause
        if (typeof column === 'object') {
          return `${column.type}(${String(column.column)}, '$.${column.path}')`;
        }
        // Standard column name clause
        return column;
      })
    )} from ${this.tableName}${whereClause ? ' where ' : ''}${whereClause}${
      orderBy
        ? ` order by ${enumerate(
            keysOf(orderBy).map((key): string => `${String(key)} ${orderBy[key]}`)
          )}`
        : ''
    }${limit ? ` limit ?${offset ? ' offset ?' : ''}` : ''}${forUpdate ? ' for update' : ''}`;
    const params = [
      ...whereClauseParameters,
      ...(limit ? [limit, ...(offset ? [offset] : [])] : []),
    ];
    log.debug('query:', sql, ', params:', params);
    const tx = await this.txPromise;
    const rows = await tx.query(sql, params);
    if (!rows) {
      throw Error(`Unexpected error, query returned no result : '${sql}'`);
    } else if (findOne && rows.length > 1) {
      throw Error(`Unexpected error, query returned more than one result: '${sql}'`);
    }
    this.fixRows(rows, columns);
    return rows;
  };

  public select = async <R extends readonly unknown[]>(
    filter: FilterExpr<DBT>,
    columns: readonly (keyof DBT | JsonFunctionClause<DBT>)[],
    orderBy?: OrderBy<DBT>,
    limit?: number,
    offset?: number
  ): Promise<readonly R[]> => {
    const rows = await this.doSelect({
      filter,
      columns,
      findOne: false,
      forUpdate: false,
      orderBy,
      limit,
      offset,
    });
    return rows.map((row): unknown => keysOf(row).map((k) => row[k])) as readonly R[];
  };

  public selectOne = async <R extends readonly unknown[]>(
    filter: FilterExpr<DBT>,
    columns: readonly (keyof DBT | JsonFunctionClause<DBT>)[]
  ): Promise<R | undefined> => {
    const rows = await this.doSelect({ filter, columns, findOne: true, forUpdate: false });
    if (rows.length === 0) {
      return undefined;
    }
    const firstRow = rows[0];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return keysOf(firstRow).map((k) => firstRow[k]) as unknown as R;
  };

  public selectForUpdate = async <R extends readonly unknown[]>(
    filter: FilterExpr<DBT>,
    columns: readonly (keyof DBT | JsonFunctionClause<DBT>)[],
    orderBy?: OrderBy<DBT>,
    limit?: number,
    offset?: number
  ): Promise<readonly R[]> => {
    const rows = await this.doSelect({
      filter,
      columns,
      findOne: false,
      forUpdate: true,
      orderBy,
      limit,
      offset,
    });
    return rows.map((row): unknown => keysOf(row).map((k) => row[k])) as readonly R[];
  };

  public selectOneForUpdate = async <R extends readonly unknown[]>(
    filter: FilterExpr<DBT>,
    columns: readonly (keyof DBT | JsonFunctionClause<DBT>)[]
  ): Promise<R | undefined> => {
    const rows = await this.doSelect({ filter, columns, findOne: true, forUpdate: true });
    if (rows.length === 0) {
      return undefined;
    }
    const firstRow = rows[0];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return keysOf(firstRow).map((k) => firstRow[k]) as unknown as R;
  };

  private doFind = async (findParams: FindParams<DBT>): Promise<readonly T[]> => {
    return (await this.doSelect({
      ...findParams,
      columns: keysOf(this.parseConfig),
    })) as any as readonly T[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  };

  public find = async (
    filter: FilterExpr<DBT>,
    orderBy?: OrderBy<DBT>,
    limit?: number,
    offset?: number
  ): Promise<readonly T[]> => {
    return this.doFind({ filter, findOne: false, forUpdate: false, orderBy, limit, offset });
  };

  public findOne = async (filter: FilterExpr<DBT>): Promise<T | undefined> => {
    const rows = await this.doFind({ filter, findOne: true, forUpdate: false });
    return rows.length > 0 ? rows[0] : undefined;
  };

  public findForUpdate = async (
    filter: FilterExpr<DBT>,
    orderBy?: OrderBy<DBT>,
    limit?: number,
    offset?: number
  ): Promise<readonly T[]> => {
    return this.doFind({ filter, findOne: false, forUpdate: true, orderBy, limit, offset });
  };

  public findOneForUpdate = async (filter: FilterExpr<DBT>): Promise<T | undefined> => {
    const rows = await this.doFind({ filter, findOne: true, forUpdate: true });
    return rows.length > 0 ? rows[0] : undefined;
  };

  public insert = async (...values: readonly DBT[]): Promise<void> => {
    if (values.length === 0) {
      return;
    }
    // Collect properties
    const properties: string[] = [];
    values.forEach((value) => {
      Object.keys(value).forEach((p) => {
        if (!properties.includes(p)) {
          properties.push(p);
        }
      });
    });
    const sql = `insert into ${this.tableName} (${enumerate(properties)}) values (${', ?'
      .repeat(properties.length)
      .substr(2)})`;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any[][] = [];
    values.forEach((value): void => {
      params.push(properties.map((p) => convertToSQL(Reflect.get(value, p))));
    });
    log.debug('query:', sql, ', params:', params);
    // The batch
    const affectedRows = await this.batch(sql, params);
    if (affectedRows !== values.length) {
      throw new Error(
        `Insertion mismatch : ${values.length} rows expected to be inserted, but ${affectedRows} have been inserted`
      );
    }
  };

  private doUpdate = async (
    filter: FilterExpr<DBT>,
    updateData: Partial<DBT>,
    checkUpdateOne: boolean
  ): Promise<number> => {
    const { expression: whereClause, parameters: whereClauseParameters } = filterExprToSQL(filter);
    const sql = `update ${this.tableName} set ${enumerate(
      keysOf(updateData).map((k): string => `${String(k)}=?`)
    )}${whereClause ? ' where ' : ''}${whereClause}`;
    const params = [
      ...keysOf(updateData).map((k) => Reflect.get(updateData, k)),
      ...whereClauseParameters,
    ];
    log.debug('query:', sql, ', params:', params);
    const tx = await this.txPromise;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { affectedRows, insertId, warningStatus } = await tx.query(sql, params);
    if (checkUpdateOne && affectedRows !== 1) {
      throw Error(
        `Update query ${
          affectedRows === 0 ? "didn't affect any row" : 'affected more than one row'
        }: '${sql}'`
      );
    }
    return affectedRows;
  };

  public update = async (filter: FilterExpr<DBT>, updateData: Partial<DBT>): Promise<number> => {
    return await this.doUpdate(filter, updateData, false);
  };

  public updateOne = async (
    filter: FilterExpr<DBT>,
    updateData: Partial<DBT>,
    failIfNoRowUpdated?: boolean
  ): Promise<number> => {
    return await this.doUpdate(filter, updateData, !failIfNoRowUpdated);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private batch = async (sql: string, params?: any): Promise<number> => {
    const tx = await this.txPromise;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { affectedRows } = (await tx.batch(sql, params)) as any;
    return affectedRows;
  };

  public updateByPK = async (...values: readonly Partial<DBT>[]): Promise<void> => {
    if (values.length === 0) {
      return;
    }
    // Collect properties
    const properties: (keyof DBT)[] = [];
    values.forEach((value) => {
      keysOf(value).forEach((p) => {
        if (!this.pk.includes(p) && !properties.includes(p)) {
          properties.push(p);
        }
      });
    });
    const sql = `update ${this.tableName} set ${enumerate(
      properties.map((k) => `${String(k)}=?`)
    )} where ${enumerate(
      this.pk.map((k) => `${String(k)}=?`),
      ' and '
    )}`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any[][] = [];
    values.forEach((value): void => {
      params.push([...properties, ...this.pk].map((p) => convertToSQL(Reflect.get(value, p))));
    });
    log.debug('query:', sql, ', params:', JSON.stringify(params));
    // The batch
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const affectedRows = await this.batch(sql, params);
    if (affectedRows !== values.length) {
      throw new Error(
        `Insertion mismatch : ${values.length} rows expected to be updated, but ${affectedRows} have been updated`
      );
    }
  };

  public delete = async (filter: FilterExpr<DBT>): Promise<number> => {
    return await this.doDelete(filter, false);
  };

  public deleteOne = async (
    filter: FilterExpr<DBT>,
    failIfNoRowDeleted?: boolean
  ): Promise<number> => {
    return await this.doDelete(filter, !failIfNoRowDeleted);
  };

  private doDelete = async (filter: FilterExpr<DBT>, checkDeleteOne: boolean): Promise<number> => {
    const { expression: whereClause, parameters: whereClauseParameters } = filterExprToSQL(filter);
    const sql = `delete from ${this.tableName}${whereClause ? ' where ' : ''}${whereClause}`;
    log.debug('query:', sql, ', params:', whereClauseParameters);
    const tx = await this.txPromise;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { affectedRows, insertId, warningStatus } = await tx.query(sql, whereClauseParameters);
    if (checkDeleteOne && affectedRows !== 1) {
      throw Error(
        `Delete query ${
          affectedRows === 0 ? "didn't delete any row" : 'deleted more than one row'
        }: '${sql}'`
      );
    }
    return affectedRows;
  };

  public count = async (filter: FilterExpr<DBT>): Promise<number> => {
    const { expression: whereClause, parameters: whereClauseParameters } = filterExprToSQL(filter);
    const sql = `select count(*) from ${this.tableName}${
      whereClause ? ' where ' : ''
    }${whereClause}`;
    log.debug('query:', sql, ', params:', whereClauseParameters);
    const tx = await this.txPromise;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const rows = await tx.query(sql, whereClauseParameters);
    // The result is in a filed named "count(*)" with MariaDb and "COUNT(*)" with AlaSQL
    // Instead of using rows[0]['count(*)'], we retrieve the field dynamically
    const keys = keysOf(rows[0]);
    return rows[0][keys[0]];
  };
}
