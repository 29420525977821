import { useMemo } from 'react';
import type { Entity } from '@stimcar/libs-kernel';
import type { ActionContext, NoArgAction, ReadOnlyActionContext } from '@stimcar/libs-uikernel';
import type { AnyTableStoreDef } from '@stimcar/libs-uitoolkit';
import { sortingHelpers } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, sanitizeValue } from '@stimcar/libs-kernel';
import type { TableToolbarConfiguration } from './Table.js';
import type {
  BooleanFilterOperators,
  ColumnDesc,
  DisplayColumnDesc,
  Filter,
  FilterCondition,
  NumberFilterOperators,
  PropertyTypes,
  Sort,
  TableItemsAndFiltersState,
  TableItemsState,
  TextFilterOperators,
} from './typings/store.js';

export function useGetDefaultTableToolbarConf(
  localStorageKey: string,
  csvDownloadBaseFileName: string
): TableToolbarConfiguration {
  return useMemo((): TableToolbarConfiguration => {
    return {
      csvDownloadBaseFileName,
      filters: { show: true },
      localStorageKey,
      showColumnSelection: true,
      showSorts: true,
      textualSearch: { show: true },
      itemCount: {
        show: true,
      },
    };
  }, [csvDownloadBaseFileName, localStorageKey]);
}

function isCorrectType(value: unknown, type: PropertyTypes): boolean {
  if (value !== undefined && value !== null) {
    switch (type) {
      case 'date':
      case 'number':
        return typeof value === 'number';
      case 'string':
        return typeof value === 'string';
      case 'boolean':
        return typeof value === 'boolean';
      default:
        return false;
    }
  }
  return true;
}

function stringValueFilter<O extends Entity>(
  entity: O,
  condition: FilterCondition,
  columnDesc: DisplayColumnDesc<O>
): boolean {
  let value: string;
  if (isTruthy(columnDesc.getDisplayedValue)) {
    const displayedValue = columnDesc.getDisplayedValue(entity);
    value =
      typeof displayedValue === 'string'
        ? displayedValue
        : String(columnDesc.getPropertyValue(entity));
  } else {
    value = String(columnDesc.getPropertyValue(entity));
  }

  const sanitizedValue = sanitizeValue(value);
  const sanitizedConditionValue = sanitizeValue(condition.value);

  switch (condition.operator as TextFilterOperators) {
    case 'contains':
      return sanitizedValue.includes(sanitizedConditionValue) ?? false;
    case 'doesNotContains':
      return !sanitizedValue.includes(sanitizedConditionValue);
    case 'empty':
      return !isTruthyAndNotEmpty(value);
    case 'notEmpty':
      return isTruthyAndNotEmpty(value);
    case 'is':
      return sanitizedValue === sanitizedConditionValue;
    case 'isNot':
      return sanitizedValue !== sanitizedConditionValue;
    default:
      return false;
  }
}

function numericValueFilter<O extends Entity>(
  entity: O,
  condition: FilterCondition,
  columnDesc: DisplayColumnDesc<O>
): boolean {
  const temp = columnDesc.getPropertyValue(entity);
  let value: number;
  if (isCorrectType(temp, 'number')) {
    value = (temp || 0) as number;
  } else {
    throw Error(
      `The column should contains numeric values, but the provided value is not numeric (${typeof temp})`
    );
  }

  const conditionValue = Number(condition.value.replace(',', '.'));
  if (Number.isNaN(conditionValue)) {
    return false;
  }

  switch (condition.operator as NumberFilterOperators) {
    case 'equal':
      return value === conditionValue;
    case 'notEqual':
      return value !== conditionValue;
    case 'greater':
      return value > conditionValue;
    case 'greaterOrEqual':
      return value >= conditionValue;
    case 'lower':
      return value < conditionValue;
    case 'lowerOrEqual':
      return value <= conditionValue;
    case 'empty':
      return !isTruthy(value);
    case 'notEmpty':
      return isTruthy(value);
    default:
      return false;
  }
}

function booleanValueFilter<O extends Entity>(
  entity: O,
  condition: FilterCondition,
  columnDesc: DisplayColumnDesc<O>
): boolean {
  const temp = columnDesc.getPropertyValue(entity);
  let value: boolean;
  if (isCorrectType(temp, 'boolean')) {
    value = (temp || false) as boolean;
  } else {
    throw Error(
      `The column should contains boolean values, but the provided value is not boolean (${typeof temp})`
    );
  }

  switch (condition.operator as BooleanFilterOperators) {
    case 'is':
      return value;
    case 'isNot':
      return !value;
    default:
      return false;
  }
}

function dateValueFilter<O extends Entity>(
  entity: O,
  condition: FilterCondition,
  columnDesc: DisplayColumnDesc<O>
): boolean {
  const temp = columnDesc.getPropertyValue(entity);
  let value: number;
  if (isCorrectType(temp, 'number')) {
    value = (temp || 0) as number;
  } else {
    throw Error(
      `The column should contains numeric date values, but the provided value is not a numeric date (${typeof temp})`
    );
  }

  const valueDate = new Date(value);
  valueDate.setHours(0);
  valueDate.setMinutes(0);
  valueDate.setSeconds(0);
  valueDate.setMilliseconds(0);

  const dateElements = condition.value.split('-');
  const conditionDate = new Date(
    Number(dateElements[0]),
    Number(dateElements[1]) - 1,
    Number(dateElements[2])
  );
  conditionDate.setHours(0);
  conditionDate.setMinutes(0);
  conditionDate.setSeconds(0);
  conditionDate.setMilliseconds(0);

  switch (condition.operator as NumberFilterOperators) {
    case 'equal':
      return valueDate.getTime() === conditionDate.getTime();
    case 'notEqual':
      return valueDate.getTime() !== conditionDate.getTime();
    case 'greater':
      return valueDate.getTime() > conditionDate.getTime();
    case 'greaterOrEqual':
      return valueDate.getTime() >= conditionDate.getTime();
    case 'lower':
      return valueDate.getTime() < conditionDate.getTime();
    case 'lowerOrEqual':
      return valueDate.getTime() <= conditionDate.getTime();
    case 'empty':
      return !isTruthy(value);
    case 'notEmpty':
      return isTruthy(value);
    default:
      return false;
  }
}

function doFilter<O extends Entity>(
  entities: readonly O[],
  condition: FilterCondition,
  columnDescs: readonly DisplayColumnDesc<O>[]
): readonly O[] {
  return entities.filter((e) => {
    const columnDesc = columnDescs.find((cd) => cd.id === condition.columnId);
    if (!isTruthy(columnDesc)) {
      throw Error(`The column ${condition.columnId} cannot be found`);
    }
    if (columnDesc.propertyType === 'string') {
      return stringValueFilter(e, condition, columnDesc);
    }
    if (columnDesc.propertyType === 'number') {
      return numericValueFilter(e, condition, columnDesc);
    }
    if (columnDesc.propertyType === 'date') {
      return dateValueFilter(e, condition, columnDesc);
    }
    if (columnDesc.propertyType === 'boolean') {
      return booleanValueFilter(e, condition, columnDesc);
    }
    throw Error(`The type of the column data is unknown: ${columnDesc.propertyType}`);
  });
}

function isEmptyStringCondition(condition: FilterCondition): boolean {
  if (
    condition.propertyType !== 'boolean' &&
    condition.operator !== 'empty' &&
    condition.operator !== 'notEmpty'
  ) {
    if (condition.value === '') {
      return true;
    }
  }
  return false;
}

export function filterEmptyStringConditions(
  conditions: readonly FilterCondition[]
): readonly FilterCondition[] {
  return conditions.filter((c) => !isEmptyStringCondition(c));
}

export function filterEntities<O extends Entity>(
  entities: readonly O[],
  filter: Filter,
  columnDescs: readonly DisplayColumnDesc<O>[]
): readonly O[] {
  const { associationOperator, filterConditions } = filter;

  const nonEmptyConditions = filterEmptyStringConditions(filterConditions);

  if (nonEmptyConditions.length === 0) {
    return entities;
  }

  let returnValue: readonly O[] = [];

  nonEmptyConditions.forEach((c, i) => {
    switch (associationOperator) {
      case 'and':
        returnValue = doFilter(i === 0 ? entities : returnValue, c, columnDescs);
        break;
      case 'or':
        returnValue = [...new Set([...returnValue, ...doFilter(entities, c, columnDescs)])];
        break;
      default:
        break;
    }
  });

  return returnValue;
}

export function sortEntities<O extends Entity>(
  entities: readonly O[],
  sorts: readonly Sort[],
  columnDescs: readonly DisplayColumnDesc<O>[]
): readonly O[] {
  const theEntities = entities.slice();

  theEntities.sort((e1, e2): number => {
    let result = 0;
    for (const sort of sorts) {
      const columnDesc = columnDescs.find((cd) => cd.id === sort.id);
      if (isTruthy(columnDesc)) {
        let value1 = columnDesc.getPropertyValue(e1);
        let value2 = columnDesc.getPropertyValue(e2);

        if (
          !isCorrectType(value1, columnDesc.propertyType) ||
          !isCorrectType(value2, columnDesc.propertyType)
        ) {
          throw Error(
            `At least one of the values don't have the correct type, ${columnDesc.propertyType} expected`
          );
        }

        switch (columnDesc.propertyType) {
          case 'string':
            if (isTruthy(columnDesc.getDisplayedValue)) {
              const displayedValue1 = columnDesc.getDisplayedValue(e1);
              value1 = typeof displayedValue1 === 'string' ? displayedValue1 : value1;
              const displayedValue2 = columnDesc.getDisplayedValue(e2);
              value2 = typeof displayedValue2 === 'string' ? displayedValue2 : value2;
            }

            result = sortingHelpers.compareStrings(
              value1 as string,
              value2 as string,
              sort.direction
            );
            break;
          case 'date':
          case 'number':
            result = sortingHelpers.compareNumbers(
              value1 as number,
              value2 as number,
              sort.direction
            );
            break;
          case 'boolean':
            result = sortingHelpers.compareBooleans(
              value1 as boolean,
              value2 as boolean,
              sort.direction
            );
            break;
          default:
            throw Error('Not handled');
        }
      }
      if (result !== 0) {
        return result;
      }
    }
    return result;
  });

  return theEntities;
}

export function getDisplayTypeColumnDescs<
  SD extends AnyTableStoreDef,
  SC extends TableItemsState<O>,
  O extends Entity,
  SO extends Entity,
>(columnDescs: readonly ColumnDesc<SD, SC, O, SO>[]): readonly DisplayColumnDesc<O>[] {
  return columnDescs.filter((c) => c.columnType === 'display') as DisplayColumnDesc<O>[];
}

function textualFilterEntities<O extends Entity>(
  items: readonly O[],
  textualFilter: string,
  displayedColumnDescs: readonly DisplayColumnDesc<O>[]
): readonly O[] {
  return items.filter((i) =>
    displayedColumnDescs.some((column) => {
      // If caller has specified a specific way of handle textual search, use it
      if (isTruthy(column.textualSearch)) {
        return column.textualSearch(i, textualFilter);
      }
      // Otherwise, fallback to the displayed value if provided or the property value if not.
      // If we use the property value, only try to match with strings
      let elementValue = column.getPropertyValue(i);
      if (isTruthy(column.getDisplayedValue)) {
        const displayedValue = column.getDisplayedValue(i);
        if (typeof displayedValue === 'string') {
          elementValue = displayedValue;
        }
      }
      if (
        typeof elementValue === 'string' &&
        elementValue.toLowerCase().includes(textualFilter.toLowerCase())
      ) {
        return true;
      }
      return false;
    })
  );
}

export type ContentProvider<
  SD extends AnyTableStoreDef,
  SC extends TableItemsAndFiltersState<O>,
  O extends Entity,
> = (ctx: ReadOnlyActionContext<SD, SC>) => Promise<readonly O[]> | readonly O[];

export function createLoadAndFilterTableItemsAction<
  SD extends AnyTableStoreDef,
  SC extends TableItemsAndFiltersState<O>,
  O extends Entity,
  SO extends Entity,
>(
  contentProvider: ContentProvider<SD, SC, O>,
  columnDescs: readonly ColumnDesc<SD, SC, O, SO>[]
): NoArgAction<SD, SC> {
  return async function loadAndFilterTableItems(ctx: ActionContext<SD, SC>): Promise<void> {
    const allItems = await contentProvider(ctx);
    applyAndFilterTableItemsAction(ctx, allItems, columnDescs);
  };
}

export function applyAndFilterTableItemsAction<
  SD extends AnyTableStoreDef,
  SC extends TableItemsAndFiltersState<O>,
  O extends Entity,
  SO extends Entity,
>(
  ctx: ActionContext<SD, SC>,
  content: readonly O[],
  columnDescs: readonly ColumnDesc<SD, SC, O, SO>[]
) {
  const { getState, actionDispatch } = ctx;
  const { filtersState, textualFilter, columnSelectionState } = getState();
  const displayTypeColumnDescs = getDisplayTypeColumnDescs(columnDescs);

  // Filter on all selected column even hidden ones. It makes working with registered filters
  // easier to understand
  const filteredItems = filterEntities(content, filtersState.filter, displayTypeColumnDescs);

  // This is really an easy access filter, people are expected to use data under there eyes, use
  // only displayed columns
  const displayedColumns = displayTypeColumnDescs.filter(
    (cd) => columnSelectionState.columns.find((c) => c.id === cd.id)?.isDisplayed
  );
  const items = textualFilterEntities(filteredItems, textualFilter, displayedColumns);
  actionDispatch.setProperty('items', items);
}
