import i18next from 'i18next';
import type { HTTPClient, KeyValueStorage } from '@stimcar/libs-kernel';
import type { AppEnvironment } from '@stimcar/libs-uitoolkit';
import { HTTPClientImpl } from '@stimcar/libs-kernel';
import { BrowserKeyValueStorageImpl } from '@stimcar/libs-uikernel';
import { BrowserFormDataFactory, launchApp } from '@stimcar/libs-uitoolkit';
import '../app/i18n.js';
import { ThemeSelector } from '../themes/ThemeSelector.js';
import type { ShareStore, ShareStoreActionContext } from './state/typings/store.js';
import { ShareApp } from './ShareApp.js';
import { EMPTY_SHARE_STORE_STATE } from './state/store.js';

interface ShareAppEnv extends AppEnvironment {
  readonly keyValueStorage: KeyValueStorage;
  readonly httpClient: HTTPClient;
}

export function launchShareApp() {
  const createStoreActionContext = ({
    httpClient,
    keyValueStorage,
  }: ShareAppEnv): Partial<ShareStoreActionContext> => {
    // Use the whole environment as action context
    return {
      httpClient,
      keyValueStorage,
    };
  };

  // Launch the share application
  launchApp<ShareStore, ShareAppEnv>({
    environment: {
      keyValueStorage: new BrowserKeyValueStorageImpl(),
      httpClient: new HTTPClientImpl(fetch.bind(window), BrowserFormDataFactory),
    },
    initialStoreState: EMPTY_SHARE_STORE_STATE,
    createStoreActionContext,
    appComponent: ShareApp,
    appDecoratorComponent: ThemeSelector,
    t: i18next.t,
  });
}
