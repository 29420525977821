export const BULMA = {
  // Bulma handles three display modes: touch devices (<1024px), desktop (1024<=W<1216px)
  // and widescreen (>=1216px)
  // https://bulma.io/documentation/layout/container/#overview
  // https://bulma.io/documentation/overview/responsiveness/#breakpoints
  TABLET_MODE_LOWER_WIDTH: 769,
  DESKTOP_MODE_LOWER_WIDTH: 1024,
  WIDESCREEN_MODE_LOWER_WIDTH: 1216,
  FULLHD_MODE_LOWER_WIDTH: 1408,
  DESKTOP_CONTAINER_MAX_WIDTH: 960,
  WIDESCREEN_CONTAINER_MAX_WIDTH: 1152,
};
