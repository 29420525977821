import type { Attachment, Sequence } from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import { nonDeleted } from '@stimcar/libs-base';
import { nonnull } from '@stimcar/libs-kernel';
import type { AttachmentsState } from '../../lib/components/attachments/typings/store.js';
import type { Store } from '../state/typings/store.js';

export const removeAttachmentOkClickedAction = async <S extends AttachmentsState>({
  actionDispatch,
  getState,
}: // eslint-disable-next-line @typescript-eslint/require-await
ActionContext<Store, S>): Promise<void> => {
  const actualAttachments = nonnull(getState().attachments);
  const idToRemove = nonnull(getState().confirmRemoval.id);
  actionDispatch.setProperty(
    'attachments',
    actualAttachments.map((a): Attachment => {
      return {
        ...a,
        deleted: a.id === idToRemove ? true : a.deleted,
      };
    })
  );
  actionDispatch.scopeProperty('confirmRemoval').setProperty('active', false);
};

export const attachDocumentOkClickedAction = async <S extends AttachmentsState>(
  context: ActionContext<Store, S>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sequenceProvider: (ctx: ActionContext<Store, any>) => Sequence
  // eslint-disable-next-line @typescript-eslint/require-await
): Promise<void> => {
  const { actionDispatch, getState } = context;
  actionDispatch.scopeProperty('galleryModal').setProperty('active', false);
  const { attachments: actualAttachments, galleryModal } = getState();
  const selectedPaths = galleryModal.selectedPaths ?? [];

  // Collect actual selected paths
  const alreadySelectedPaths: string[] = [];
  if (actualAttachments) {
    actualAttachments.filter(nonDeleted).forEach(({ folder, name }) => {
      const path = `${folder}/${name}`;
      if (!alreadySelectedPaths.includes(path)) {
        alreadySelectedPaths.push(path);
      }
    });
  }

  // Collect new paths
  const newSelectedPaths = selectedPaths.filter((path) => !alreadySelectedPaths.includes(path));

  // New list
  const newAttachments: Attachment[] = [];
  if (actualAttachments) {
    actualAttachments.forEach((attachment) => {
      const { deleted, folder, name } = attachment;
      const path = `${folder}/${name}`;
      if (deleted || selectedPaths.includes(path)) {
        // Leave unchanged
        newAttachments.push(attachment);
      } else {
        // Otherwise, the attachment must be removed
        newAttachments.push({
          ...attachment,
          deleted: true,
        });
      }
    });
  }
  // Append new attachments
  newSelectedPaths.forEach((path) => {
    const pathFragments = path.split('/');
    newAttachments.push({
      id: sequenceProvider(context).next(),
      folder: pathFragments[0],
      name: pathFragments[1],
    });
  });
  // Update state
  actionDispatch.setProperty('attachments', newAttachments);
};
