import i18next from 'i18next';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import '../app/i18n.js';
import { launchRegisteredApp } from '../registeredapp/launchRegisteredApp.js';
import { ThemeSelector } from '../themes/ThemeSelector.js';
import type { DisplayStore } from './state/typings/store.js';
import { DisplayApp } from './DisplayApp.js';
import { EMPTY_DISPLAY_STORE_STATE } from './state/store.js';

export function launchDisplayApp() {
  launchRegisteredApp<true, DisplayStore>({
    registeredAppComponent: DisplayApp,
    appDecoratorComponent: ThemeSelector,
    localStorageKeysToKeepDuringSoftReset: [
      LocalStorageKeys.LAST_DISPLAY_PATH,
      LocalStorageKeys.SHIFT_PROGRESS_DISPLAY,
    ],
    registeredAppRoute: 'display',
    initialStoreState: EMPTY_DISPLAY_STORE_STATE,
    repositoryEventListenerActions: {},
    lightMode: true,
    t: i18next.t,
  });
}
