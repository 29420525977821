/** Ensure the given parameter is an error or converts it to an error if needed */
export const ensureError = (e: unknown): Error =>
  e instanceof Error ? e : new Error(`Error raised: ${JSON.stringify(e)}`);

export function toChunks<T>(array: readonly T[], chunkSize: number): readonly T[][] {
  return array.reduce<T[][]>((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!resultArray[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      resultArray[chunkIndex] = []; // start a new chunk
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);
}
