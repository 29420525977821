import type { SyntheticEvent } from 'react';
import type { ActionCallbackFromFunction } from '@stimcar/libs-uikernel';
import { keysOf } from '@stimcar/libs-kernel';
import type { Store } from '../state/typings/store.js';

export interface MenuItem {
  readonly target:
    | string /* URI */
    | ActionCallbackFromFunction<Store, (e: SyntheticEvent) => void> /* or action */;
  readonly label: string;
}

export type MenuGroups = Readonly<Record<string, readonly MenuItem[]>>;

export interface MenuGroupBuilder {
  readonly append: (item: MenuItem) => void;
}

export class MenuBuilder {
  private groups: Record<string, MenuItem[]> = {};

  public getMenuGroupBuilder(groupId: string): MenuGroupBuilder {
    if (!keysOf(this.groups).includes(groupId)) {
      this.groups[groupId] = [];
    }
    const group = this.groups[groupId];
    return {
      append: (item) => {
        group.push(item);
      },
    };
  }

  public getMenu(sort = false): MenuGroups {
    const finalGroup: Record<string, MenuItem[]> = {};
    keysOf(this.groups).forEach((groupId) => {
      const group = this.groups[groupId];
      if (group.length > 0) {
        finalGroup[groupId] = sort
          ? group.sort((i1, i2) => i1.label.localeCompare(i2.label))
          : group;
      }
    });
    return finalGroup;
  }
}
