import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { AttachmentFolder } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type {
  CheckFormConsistencyAction,
  CheckFormFieldContentActions,
} from '@stimcar/libs-uitoolkit';
import { contractHelpers, enumerate, urlHelpers } from '@stimcar/libs-base';
import { computePayload, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { InputFormField, ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { SwitchFormField } from '../../../lib/bulma/form/SwitchFormField.js';
import { isReservedAttachmentFolder } from '../../../utils/folderUtils.js';
import type {
  ContractAttachmentFolderFormData,
  EditContractAttachmentFolderModalState,
} from './typings/store.js';
import {
  EDIT_CONTRACT_ATTACHMENT_FOLDER_MODAL_EMPTY_STATE,
  EMPTY_CONTRACT_ATTACHMENT_FOLDER_FORM_DATA,
} from './typings/store.js';

export function convertFormDataToAttachmentFolder(
  formData: ContractAttachmentFolderFormData
): AttachmentFolder {
  return {
    id: formData.id,
    label: isTruthyAndNotEmpty(formData.label) ? formData.label : undefined,
    isShareable: formData.shareable,
  };
}

const mandatoryFields: (keyof ContractAttachmentFolderFormData)[] = ['id', 'shareable'];

const checkFieldContentActions: CheckFormFieldContentActions<
  Store,
  EditContractAttachmentFolderModalState
> = {
  id: ({ t, value, formState }): string | undefined => {
    if (formState.mode === 'create') {
      if (isReservedAttachmentFolder(value)) {
        return t('attachmentFolderForm.errors.reservedAttachmentFolder');
      }
      const restrictedCharacters = urlHelpers.getURLParametersRestrictedCharacters(value);
      if (restrictedCharacters.length > 0) {
        return t('attachmentFolderForm.errors.restrictedCharactersInFolder', {
          characters: enumerate(restrictedCharacters),
        });
      }
      const folderIds = formState.attachmentFolders.map((f) => f.id);
      if (folderIds.includes(value)) {
        return t('attachmentFolderForm.errors.duplicatedId');
      }
      const commonFolderIds = contractHelpers.getCommonAttachmentsFolders().map((a) => a.id);
      if (commonFolderIds.includes(value)) {
        return t('attachmentFolderForm.errors.overrideCommonFolder');
      }
    }

    return undefined;
  },
};

const checkFormConsistencyAction: CheckFormConsistencyAction<
  Store,
  EditContractAttachmentFolderModalState
> = ({ formState, t }): string | undefined => {
  const { initialAttachmentFolder, formData } = formState;
  if (initialAttachmentFolder) {
    const payload = computePayload(
      initialAttachmentFolder,
      convertFormDataToAttachmentFolder(formData)
    );
    const updated = payload !== undefined && Reflect.ownKeys(payload).length !== 0;
    if (!updated) {
      return t('globals:warnings.noChange');
    }
  }
  return undefined;
};

function saveAttachmentFolderAction({
  getState,
  actionDispatch,
}: ActionContext<Store, EditContractAttachmentFolderModalState>): void {
  const { initialAttachmentFolder, formData, attachmentFolders } = getState();

  let newAttachmentFolders = [...attachmentFolders];
  if (initialAttachmentFolder) {
    newAttachmentFolders = attachmentFolders.map((a) => {
      if (a.id === formData.id) {
        return convertFormDataToAttachmentFolder(formData);
      }
      return a;
    });
  } else {
    newAttachmentFolders.push(convertFormDataToAttachmentFolder(formData));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionDispatch.reduce((initial) => {
    return {
      ...EDIT_CONTRACT_ATTACHMENT_FOLDER_MODAL_EMPTY_STATE,
      attachmentFolders: newAttachmentFolders,
    };
  });
}

export function initializeEditContractAttachmentFolderModalState(
  { actionDispatch }: ActionContext<Store, EditContractAttachmentFolderModalState>,
  id?: string
): void {
  let formData = EMPTY_CONTRACT_ATTACHMENT_FOLDER_FORM_DATA;
  let { mode } = EDIT_CONTRACT_ATTACHMENT_FOLDER_MODAL_EMPTY_STATE;
  let initialAttachmentFolder: AttachmentFolder | undefined;
  actionDispatch.reduce((initial): EditContractAttachmentFolderModalState => {
    if (isTruthyAndNotEmpty(id)) {
      mode = 'update';
      initialAttachmentFolder = nonnull(initial.attachmentFolders.find((a) => a.id === id));
      formData = {
        ...formData,
        id: initialAttachmentFolder?.id,
        label: initialAttachmentFolder?.label ?? '',
        shareable: initialAttachmentFolder?.isShareable,
      };
    }
    return {
      ...EDIT_CONTRACT_ATTACHMENT_FOLDER_MODAL_EMPTY_STATE,
      active: true,
      attachmentFolders: initial.attachmentFolders,
      mode,
      initialAttachmentFolder,
      formData,
    };
  });
}

interface EditContractAttachmentFolderModalProps {
  readonly $: StoreStateSelector<Store, EditContractAttachmentFolderModalState>;
}

export function EditContractAttachmentFolderModal({
  $,
}: EditContractAttachmentFolderModalProps): JSX.Element {
  const [t] = useTranslation('adminContracts');
  const formWarning = useGetState($.$formWarning);
  const mode = useGetState($.$mode);

  const submitValidDataAction = useActionCallback(saveAttachmentFolderAction, [], $);

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    EditContractAttachmentFolderModalState
  >({
    $,
    mandatoryFields,
    checkFieldContentActions,
    submitValidDataAction,
    checkFormConsistencyAction,
    t,
  });

  const isCreateMode = mode === 'create';

  return (
    <ModalCardDialog
      title={
        isCreateMode ? t('attachmentFolderForm.titleNew') : t('attachmentFolderForm.titleUpdate')
      }
      $active={$.$active}
      okLabel={
        isCreateMode ? t('attachmentFolderForm.newButton') : t('attachmentFolderForm.updateButton')
      }
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <InputFormField
        $={$formDataWithChangeTrigger.$id}
        label={t('attachmentFolderForm.id')}
        placeholder={t('attachmentFolderForm.idTooltip')}
        disabled={!isCreateMode}
        horizontal
      />
      <InputFormField
        $={$formDataWithChangeTrigger.$label}
        label={t('attachmentFolderForm.label')}
        placeholder={t('attachmentFolderForm.labelTooltip')}
        horizontal
      />
      <SwitchFormField
        $={$formDataWithChangeTrigger.$shareable}
        label={t('attachmentFolderForm.shareable')}
        switchId="isShareable"
        horizontal
      />
    </ModalCardDialog>
  );
}
