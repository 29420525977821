import type { JSX } from 'react';
import React from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import type { CarViewProps } from './car-view-common.js';
import { createStyleAndIdBuilder, TRANSPARENT_VIEW_NODE_STYLE } from './car-view-common.js';
import { CarViewCanvas } from './CarViewCanvas.js';

export function CarInteriorView<SD extends AnyStoreDef>(props: CarViewProps<SD>): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { selectedShapes, ...canvasProps } = props;
  const styleAndId = createStyleAndIdBuilder('INTE', props);
  return (
    <CarViewCanvas {...canvasProps} showCarBackground>
      <g
        transform="matrix(0.60730759,0.47293051,-0.32597251,0.88109973,24.162033,-35.625514)"
        {...styleAndId('DOOR_FL')}
      >
        <g transform="matrix(0.44208205,0.64903471,-0.79378544,0.69670162,148.81472,-6.6319309)">
          <path d="M 38.380725,165.87127 9.8100293,145.86457 -3.6745729,124.28424 23.696506,111.77141 56.679361,133.82446 Z" />
          <path
            d="m 2.9139148,126.62994 40.8163632,22.97391 -5.956616,9.82493 -25.135468,-16.38771 z"
            style={TRANSPARENT_VIEW_NODE_STYLE}
          />
        </g>
      </g>
      <g
        transform="matrix(-0.60730759,0.47293051,0.32597251,0.88109973,174.63144,-35.625514)"
        {...styleAndId('DOOR_FR')}
      >
        <g transform="matrix(0.44208205,0.64903471,-0.79378544,0.69670162,148.81472,-6.6319309)">
          <path d="M 38.380725,165.87127 9.8100293,145.86457 -3.6745729,124.28424 23.696506,111.77141 56.679361,133.82446 Z" />
          <path
            style={TRANSPARENT_VIEW_NODE_STYLE}
            d="m 2.9139148,126.62994 40.8163632,22.97391 -5.956616,9.82493 -25.135468,-16.38771 z"
          />
        </g>
      </g>
      <g
        transform="matrix(0.60730759,0.47293051,-0.32597251,0.88109973,24.162033,13.587005)"
        {...styleAndId('DOOR_BL')}
      >
        <g transform="matrix(0.44208205,0.64903471,-0.79378544,0.69670162,148.81472,-6.6319309)">
          <path d="M 38.380725,165.87127 9.8100293,145.86457 -3.6745729,124.28424 23.696506,111.77141 56.679361,133.82446 Z" />
          <path
            style={TRANSPARENT_VIEW_NODE_STYLE}
            d="m 2.9139148,126.62994 40.8163632,22.97391 -5.956616,9.82493 -25.135468,-16.38771 z"
          />
        </g>
      </g>
      <g
        transform="matrix(-0.60730759,0.47293051,0.32597251,0.88109973,174.63144,13.587005)"
        {...styleAndId('DOOR_BR')}
      >
        <g transform="matrix(0.44208205,0.64903471,-0.79378544,0.69670162,148.81472,-6.6319309)">
          <path d="M 38.380725,165.87127 9.8100293,145.86457 -3.6745729,124.28424 23.696506,111.77141 56.679361,133.82446 Z" />
          <path
            d="m 2.9139148,126.62994 40.8163632,22.97391 -5.956616,9.82493 -25.135468,-16.38771 z"
            style={TRANSPARENT_VIEW_NODE_STYLE}
          />
        </g>
      </g>
      <path
        d="m 159.60352,645.72852 v 115.00781 l 134.35156,53.18164 163.64453,-1.00977 138.74805,-52.46679 -0.35743,-114.71289 z m 46.98046,27.5 h 105.90235 v 99.21289 H 206.58398 Z m 235.69922,0 H 550 v 99.21289 H 442.2832 Z"
        transform="scale(0.26458333)"
        {...styleAndId('TRUNK', 6.8)}
      />
      <rect
        width="35.85305"
        height="33.408691"
        x="81.478279"
        y="129.43814"
        {...styleAndId('SEAT_BC')}
      />
      <rect
        width="37.685005"
        height="33.408691"
        x="118.43803"
        y="78.090141"
        {...styleAndId('SEAT_FR')}
      />
      <rect
        y="78.34127"
        x="43.286919"
        height="33.408691"
        width="37.685005"
        {...styleAndId('SEAT_FL')}
      />
      <rect
        width="28.105627"
        height="42.652119"
        x="85.363434"
        y="71.785385"
        {...styleAndId('CONSOLE')}
      />
      <rect
        y="129.43814"
        x="121.43507"
        height="33.408691"
        width="35.85305"
        {...styleAndId('SEAT_BR')}
      />
      <rect
        width="35.85305"
        height="33.408691"
        x="41.699417"
        y="129.43814"
        {...styleAndId('SEAT_BL')}
      />
      <path
        d="M 39.6875,66.81249 V 54.33928 L 53.294642,46.02381 H 143.63095 L 158.75,54.33928 v 12.47321 z"
        {...styleAndId('DASHBOARD')}
      />
      <path
        d="m 158.75,25.448897 18.86281,-14.026215 17.89551,14.026215 z"
        {...styleAndId('ROOF')}
      />
      <rect width="28.5" height="26.25" x="117.02089" y="178.125" {...styleAndId('TRUNK_SEAT_R')} />
      <rect
        width="28.019859"
        height="26.25"
        x="54.658688"
        y="178.125"
        {...styleAndId('TRUNK_SEAT_L')}
      />
    </CarViewCanvas>
  );
}
