import { type PackageDealCategory } from '@stimcar/libs-base';

export const COMPANY_TYPE_UNKNOWN = '';
export const COMPANY_TYPE_SOLE_TRADER = 'Auto Entrepreneur';
export const COMPANY_TYPE_SAS = 'SAS';
export const COMPANY_TYPE_SARL = 'SARL';
export const COMPANY_TYPES = [
  COMPANY_TYPE_UNKNOWN,
  COMPANY_TYPE_SOLE_TRADER,
  COMPANY_TYPE_SAS,
  COMPANY_TYPE_SARL,
];
export type CompanyType = (typeof COMPANY_TYPES)[number];

export type MarketplacePackageDeal = {
  readonly category: PackageDealCategory;
  readonly carElement: string;
  readonly label: string;
  readonly VATRate: number;
  readonly priceWithVAT: number;
};

export type MarketplaceSaleOverview = {
  readonly salePriceWithVAT: number;
  readonly buyFixedFeePackageDeals: readonly MarketplacePackageDeal[];
  readonly saleFixedFeePackageDeals: readonly MarketplacePackageDeal[];
  readonly saleRefurbishFeePackageDeals: readonly MarketplacePackageDeal[];
};

export type MarketplaceBuyOverview = {
  readonly salePriceWithVAT: number;
  readonly deposit: number;
  readonly buyFixedFeePackageDeals: readonly MarketplacePackageDeal[];
  readonly buyRegistrationFeePackageDeals: readonly MarketplacePackageDeal[];
  readonly buyRefurbishFeePackageDeals: readonly MarketplacePackageDeal[];
};

export type MarketplaceWorkOverview = {
  readonly packageDeals: readonly MarketplacePackageDeal[];
};

export type AvailableStimer = {
  readonly id: string;
  readonly name: string;
};

export type StimerInfos = {
  readonly name: string;
  readonly ignoreVAT: boolean;
  readonly companyType: CompanyType;
  readonly companyId: string;
  readonly address: string;
  readonly zipCode: string;
  readonly city: string;
  readonly mail: string;
  readonly phone: string;
  readonly iban: string;
  readonly bic: string;
  readonly shareCapital?: string;
  readonly registration?: string;
  readonly VATNumber?: string;
};

export type CommissionLine = {
  readonly label: string;
  readonly priceWithoutVAT: number;
  readonly VAT: number;
  readonly priceWithVAT: number;
};

export type MarketplaceStimerOverview = {
  readonly invoiceId: string;
  readonly stimerInfos: StimerInfos;
  readonly stimerPercentage: string;
  readonly commissions: readonly CommissionLine[];
  readonly totalStimerWithoutVAT: number;
  readonly totalCommissionsWithoutVAT: number;
  readonly totalCommissionsVAT: number;
  readonly totalCommissionsWithVAT: number;
};

export type MarketplaceOverviewRequest = {
  readonly saleOverview?: MarketplaceSaleOverview;
  readonly buyOverview?: MarketplaceBuyOverview;
  readonly workOverview?: MarketplaceWorkOverview;
  readonly stimerOverview?: MarketplaceStimerOverview;
};
