/* eslint-disable-next-line max-classes-per-file */
import type { MariaDbConnection, MariaDbDAO, MariaDbPool, TableConfig } from '@stimcar/libs-base';
import { camelToSnake, MariaDbDAOImpl, toUpperFirst } from '@stimcar/libs-base';
import { keysOf, nonnull } from '@stimcar/libs-kernel';
import type {
  Database,
  DatabaseFactory,
  DatabaseStoreDesc,
  DatabaseStoresConfigurator,
  DatabaseTx,
  DatabaseUpgrader,
} from './typings/database.js';

type MariaDbDatabaseIndexes<DSD extends DatabaseStoreDesc> = {
  readonly [SN in keyof DSD]: { [name: string]: string };
};

type MariaDbDatabaseIdKeys<DSD extends DatabaseStoreDesc> = {
  readonly [SN in keyof DSD]: string;
};

interface MariaDbDatabaseConfiguration<DSD extends DatabaseStoreDesc> {
  readonly version?: number;
  readonly indexes: MariaDbDatabaseIndexes<DSD>;
  readonly idKeys: MariaDbDatabaseIdKeys<DSD>;
}

const computeTableName = <DSD extends DatabaseStoreDesc>(
  databaseName: string,
  storeName: keyof DSD
): string => `repoDb${toUpperFirst(databaseName)}${toUpperFirst(String(storeName))}`;

interface DbStore<C> {
  readonly id: string;
  readonly content: C;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dbStoreTableConfig: TableConfig<DbStore<any>> = {
  id: 'text',
  content: 'json',
};

const getDAO = <C>(tx: MariaDbConnection, tableName: string): MariaDbDAO<DbStore<C>> => {
  return new MariaDbDAOImpl<DbStore<C>>(
    tx,
    undefined,
    tableName.toUpperCase(),
    dbStoreTableConfig,
    ['id']
  );
};

class MariaDbTx<DSD extends DatabaseStoreDesc> implements DatabaseTx<DSD> {
  private txPromise: Promise<MariaDbConnection>;

  private configuration: MariaDbDatabaseConfiguration<DSD>;

  private databaseName: string;

  public constructor(
    txPromise: Promise<MariaDbConnection>,
    configuration: MariaDbDatabaseConfiguration<DSD>,
    databaseName: string
  ) {
    this.txPromise = txPromise;
    this.configuration = configuration;
    this.databaseName = databaseName;
  }

  private async getDAO<SN extends keyof DSD>(storeName: SN): Promise<MariaDbDAO<DbStore<DSD[SN]>>> {
    return new MariaDbDAOImpl(
      await this.txPromise,
      undefined,
      computeTableName(this.databaseName, String(storeName)),
      dbStoreTableConfig,
      ['id']
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as any;
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/require-await
  public commit = async (): Promise<void> => {
    const tx = await this.txPromise;
    await tx.commit();
    tx.release();
  };

  // eslint-disable-next-line class-methods-use-this
  public rollback = async (): Promise<void> => {
    const tx = await this.txPromise;
    await tx.rollback();
    tx.release();
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  public async getFromIndex<SN extends keyof DSD>(
    storeName: SN,
    indexName: string,
    indexValue: string | number | boolean
  ): Promise<DSD[SN][]> {
    const dao = await this.getDAO(storeName);
    const storeIndexHandlers = this.configuration.indexes[storeName];
    if (storeIndexHandlers) {
      const fieldName = storeIndexHandlers[indexName];
      if (fieldName) {
        // FIXME for now a full scan is performed which is not acceptable
        const allElements = await dao.find({});
        return (
          allElements
            // element variable seems no to be used, bu it is used in the eval call
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter((element): boolean => {
              try {
                // eslint-disable-next-line no-eval
                return eval(`element.${fieldName}`) === indexValue;
              } catch {
                return false;
              }
            })
            .map((row): DSD[SN] => this.convertDbToDTO(storeName, row))
        );
      }
    }
    throw new Error(`Unknown index '${indexName}' for store '${String(storeName)}'`);
  }

  private convertDbToDTO<SN extends keyof DSD>(storeName: SN, row: DbStore<DSD[SN]>): DSD[SN] {
    const idKey = this.configuration.idKeys[storeName];
    return {
      ...row?.content,
      [idKey]: row.id,
    };
  }

  private convertDTOToDb<SN extends keyof DSD>(storeName: SN, obj: DSD[SN]): DbStore<DSD[SN]> {
    const idKey = this.configuration.idKeys[storeName];
    const objId = Reflect.get(obj, idKey) as string;
    const content = {};
    keysOf(obj).forEach((k) => {
      if (k !== idKey) {
        Reflect.set(content, k, obj[k]);
      }
    });
    return {
      id: objId,
      content,
    } as DbStore<DSD[SN]>;
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public async get<SN extends keyof DSD>(storeName: SN, id: string): Promise<DSD[SN] | undefined> {
    const dao = await this.getDAO(storeName);
    const row = await dao.findOne({ id });
    return row ? this.convertDbToDTO(storeName, row) : undefined;
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public async getN<SN extends keyof DSD>(storeName: SN, ...ids: string[]): Promise<DSD[SN][]> {
    const dao = await this.getDAO(storeName);
    return (await dao.find({ id: { $in: ids } })).map((row): DSD[SN] =>
      this.convertDbToDTO(storeName, row)
    );
  }

  public async put<SN extends keyof DSD>(storeName: SN, obj: DSD[SN]): Promise<void> {
    const dao = await this.getDAO(storeName);
    const idKey = this.configuration.idKeys[storeName];
    const objId = Reflect.get(obj, idKey) as string;
    const existing = await dao.findOne({ id: objId });
    const dbObject = this.convertDTOToDb(storeName, obj);
    if (existing) {
      await dao.updateByPK(dbObject);
    } else {
      await dao.insert(dbObject);
    }
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public async getAll<SN extends keyof DSD>(storeName: SN): Promise<DSD[SN][]> {
    const dao = await this.getDAO(storeName);
    return (await dao.find({})).map((row): DSD[SN] => this.convertDbToDTO(storeName, row));
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public async delete<SN extends keyof DSD>(storeName: SN, id: string): Promise<void> {
    const dao = await this.getDAO(storeName);
    await dao.deleteOne({ id });
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public async deleteAll<SN extends keyof DSD>(storeName: SN): Promise<void> {
    const dao = await this.getDAO(storeName);
    await dao.delete({});
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public async count<SN extends keyof DSD>(storeName: SN): Promise<number> {
    const dao = await this.getDAO(storeName);
    return dao.count({});
  }

  public async exists<SN extends keyof DSD>(storeName: SN, id: string): Promise<boolean> {
    const dao = await this.getDAO(storeName);
    return (await dao.count({ id })) > 0;
  }
}

class MariaDbDatabaseImpl<DSD extends DatabaseStoreDesc> implements Database<DSD> {
  private pool: MariaDbPool;

  private configuration: MariaDbDatabaseConfiguration<DSD>;

  private databaseName: string;

  public constructor(
    pool: MariaDbPool,
    configuration: MariaDbDatabaseConfiguration<DSD>,
    databaseName: string
  ) {
    this.pool = pool;
    this.configuration = configuration;
    this.databaseName = databaseName;
  }

  public beginTx(): DatabaseTx<DSD> {
    return new MariaDbTx<DSD>(this.pool.getConnection(), this.configuration, this.databaseName);
  }
}

export class MariaDbDatabaseFactoryImpl implements DatabaseFactory {
  private pool: MariaDbPool;

  constructor(pool: MariaDbPool) {
    this.pool = pool;
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public async create<DSD extends DatabaseStoreDesc>(
    databaseName: string,
    version: number,
    upgrade: DatabaseUpgrader<DSD>
  ): Promise<Database<DSD>> {
    const tx = await this.pool.getConnection();
    try {
      await tx.query(
        'create table if not exists REPO_DB_CONFIGURATION (' +
          'id varchar(50) not null, ' +
          "content mediumtext not null default ('{ version: 0 }'), " +
          'CHECK (JSON_VALID(content)), ' +
          'constraint CONFIGURATION_PK primary key (id)' +
          ') engine=innodb',
        []
      );
      const configurationDAO = new MariaDbDAOImpl<DbStore<MariaDbDatabaseConfiguration<DSD>>>(
        tx,
        undefined,
        'repoDbConfiguration',
        dbStoreTableConfig,
        ['id']
      );
      let dbConfiguration = await configurationDAO.findOne({ id: databaseName });
      if (!dbConfiguration) {
        dbConfiguration = {
          id: databaseName,
          content: {
            idKeys: [],
            indexes: [],
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any;
        await configurationDAO.insert(nonnull(dbConfiguration));
      }
      const configuration = nonnull(dbConfiguration).content;
      if (!configuration.version || version > configuration.version) {
        const tablesToCreate: string[] = [];
        const configurator: DatabaseStoresConfigurator<DSD> = {
          createObjectStore: (storeName: keyof DSD, idKey: string): void => {
            const tableName = camelToSnake(computeTableName(databaseName, storeName)).toUpperCase();
            tablesToCreate.push(tableName);
            Reflect.set(configuration.indexes, storeName, {});
            Reflect.set(configuration.idKeys, storeName, idKey);
          },
          createIndex: (storeName: keyof DSD, indexName: string, keyPath: string): void => {
            Reflect.set(configuration.indexes[storeName], indexName, keyPath);
          },
          deleteIndex: (storeName: keyof DSD, indexName: string): void => {
            Reflect.deleteProperty(configuration.indexes[storeName], indexName);
          },
        };
        upgrade(configurator, configuration.version ?? 0, version);
        // Create tables
        await Promise.all(
          tablesToCreate.map(
            async (tableToCreate): Promise<void> =>
              tx.query(
                `create table ${tableToCreate} (
id varchar(50) not null, 
content mediumtext not null default ('{}'), 
CHECK (JSON_VALID(content)), 
constraint ${tableToCreate}_PK primary key (id)
) engine=innodb`,
                []
              )
          )
        );
        Reflect.set(configuration, 'version', version);
        // Update the configuration
        await configurationDAO.updateByPK({ id: databaseName, content: configuration });
      }
      return new MariaDbDatabaseImpl(this.pool, configuration, databaseName);
    } finally {
      await tx.commit();
      tx.release();
    }
  }

  public removeAllData = async (): Promise<void> => {
    const tx = await this.pool.getConnection();
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const configurationDAO = getDAO<MariaDbDatabaseConfiguration<any>>(tx, 'repoDbConfiguration');
      const configurations = await configurationDAO.find({});
      // Iterate over all
      await Promise.all(
        configurations.map(async ({ id: databaseName, content }): Promise<void> => {
          await Promise.all(
            keysOf(content.indexes).map(async (storeName): Promise<void> => {
              const dao = getDAO(tx, computeTableName(databaseName, String(storeName)));
              await dao.delete({});
            })
          );
        })
      );
    } finally {
      await tx.commit();
      tx.release();
    }
  };
}
