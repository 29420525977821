import type { JSX } from 'react';
import React from 'react';

export interface WithWarningProps {
  readonly warning: string | undefined;
  readonly children: React.ReactNode;
  readonly additionalWarningTextClassName?: string;
  readonly noExclamationTriangleIfWarning?: boolean;
}

export function WithWarning({
  warning,
  children,
  noExclamationTriangleIfWarning = false,
  additionalWarningTextClassName = '',
}: WithWarningProps): JSX.Element {
  return (
    <>
      <div
        className={`control${warning && !noExclamationTriangleIfWarning ? ' has-icons-right' : ''}`}
      >
        {children}
        {warning && !noExclamationTriangleIfWarning && (
          <span className="icon is-right">
            <i className="fas fa-lg m-t-sm fa-exclamation-triangle" />
          </span>
        )}
      </div>
      {warning && <p className={`help is-primary ${additionalWarningTextClassName}`}>{warning}</p>}
    </>
  );
}
