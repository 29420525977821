import type { TFunction } from 'i18next';
import type {
  Attachment,
  AttachmentMetadata,
  AttributeType,
  CarViewCategory,
  ContractAttributeDesc,
  Kanban,
  KanbanHandling,
  KanbanMessage,
  KanbanWorkInterval,
  PackageDeal,
  PackageDealStatus,
  PurchaseOrder,
  RepositoryEntityStatus,
  SiteConfiguration,
  SortDirection,
  SparePart,
} from '@stimcar/libs-base';
import {
  AIRTABLE_SOURCE,
  carElementHelpers,
  CLONE_SOURCE,
  DELEGATION_SOURCE,
  enumerate,
  filterReject,
  kanbanHelpers,
  longNumberDayMonthYearWithHourFormatOptions,
  nonDeleted,
  packageDealHelpers,
  shortDayMonthWithHourFormatOptions,
  sortingHelpers,
  SUBCONTRACTOR_REQUEST_MESSAGE_ICON_ID,
  VOLEAN_SOURCE,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, keysOf } from '@stimcar/libs-kernel';
import { getLocationIconForHandling } from '../utils/useGetLocationIconForHandling.js';
import type {
  AttributeDetailsInternalDataStructure,
  EmptyHandlingDetailsInternalDataStructure,
  HistoryDetailsInternalDataStructure,
  IntervalDetailsInternalDataStructure,
  MessageDetailsInternalDataStructure,
  OperationDetailsInternalDataStructure,
  PackageDealDetailsInternalDataStructure,
  PkgUnachievableStatus,
  SparePartDetailsInternalDataStructure,
  SparePartDetailStatus,
} from './typings/store.js';

export const DURATION_DECIMAL_LENGTH = 2;
export const PRICE_DECIMAL_LENGTH = 2;

export function shouldAllowAttachmentDeletion(
  kanbanStatus: RepositoryEntityStatus | undefined,
  attachment: Attachment,
  metadata: AttachmentMetadata | undefined
): boolean {
  switch (kanbanStatus) {
    case 'open':
      return true;
    case 'archived':
    case 'closed':
      if (isTruthy(metadata)) {
        // 1 day in ms
        return Date.now() - metadata.lastModified < 24 * 60 * 60 * 1000;
      }
      return false;
    default:
      return false;
  }
}

export function computeDateLabelForTimespamp(timestamp: number | undefined): string {
  if (timestamp) {
    return new Date(timestamp).toLocaleDateString(
      undefined,
      longNumberDayMonthYearWithHourFormatOptions
    );
  }
  return '';
}

export function computeShortDateLabelForTimespamp(timestamp: number | undefined): string {
  if (timestamp) {
    return new Date(timestamp).toLocaleDateString(undefined, shortDayMonthWithHourFormatOptions);
  }
  return '';
}

export const createSortHistoryByUsersFunction = (
  sortDirection: SortDirection
): ((
  history1: HistoryDetailsInternalDataStructure,
  history2: HistoryDetailsInternalDataStructure
) => number) => {
  return function sort(history1, history2): number {
    const users1 = enumerate(history1.users);
    const users2 = enumerate(history2.users);
    return sortingHelpers.compareStrings(users1, users2, sortDirection);
  };
};

export function isHistoryElementAnIntervalOrAnEmptyHandling(
  history: HistoryDetailsInternalDataStructure
): history is EmptyHandlingDetailsInternalDataStructure {
  const handling = history as EmptyHandlingDetailsInternalDataStructure;
  return (
    handling.post !== undefined &&
    handling.endDate !== undefined &&
    handling.stand !== undefined &&
    handling.isCurrent !== undefined
  );
}

export function isHistoryElementAnAnEmptyHandling(
  history: HistoryDetailsInternalDataStructure
): history is EmptyHandlingDetailsInternalDataStructure {
  return (
    isHistoryElementAnIntervalOrAnEmptyHandling(history) &&
    (history as IntervalDetailsInternalDataStructure).handlingId === undefined
  );
}

export function isHistoryElementAnInterval(
  history: HistoryDetailsInternalDataStructure
): history is IntervalDetailsInternalDataStructure {
  return (
    isHistoryElementAnIntervalOrAnEmptyHandling(history) &&
    (history as IntervalDetailsInternalDataStructure).handlingId !== undefined
  );
}

export function isHistoryElementAMessage(
  history: HistoryDetailsInternalDataStructure
): history is MessageDetailsInternalDataStructure {
  return (history as MessageDetailsInternalDataStructure).content !== undefined;
}

export function createSortHistoryByStandFunction(
  sortDirection: SortDirection
): (
  history1: HistoryDetailsInternalDataStructure,
  history2: HistoryDetailsInternalDataStructure
) => number {
  return function sort(history1, history2): number {
    if (isHistoryElementAnInterval(history1) && isHistoryElementAnInterval(history2)) {
      return sortingHelpers.compareStrings(history1.stand, history2.stand, sortDirection);
    }
    if (isHistoryElementAnInterval(history1)) {
      return 1;
    }
    if (isHistoryElementAnInterval(history2)) {
      return 1;
    }
    return 0;
  };
}

export function createSortHistoryByDurationFunction(
  sortDirection: SortDirection
): (
  history1: HistoryDetailsInternalDataStructure,
  history2: HistoryDetailsInternalDataStructure
) => number {
  return function sort(history1, history2): number {
    if (isHistoryElementAnInterval(history1) && isHistoryElementAnInterval(history2)) {
      const duration1 = history1?.endDate ?? Date.now() - history1.date;
      const duration2 = history2?.endDate ?? Date.now() - history2.date;
      return sortingHelpers.compareNumbers(duration1, duration2, sortDirection);
    }
    if (isHistoryElementAnInterval(history1)) {
      return 1;
    }
    if (isHistoryElementAnInterval(history2)) {
      return 1;
    }
    return 0;
  };
}

export function computeHandlingDuration(startDate: number, endDate: number, t: TFunction): string {
  const mins = (endDate - startDate) / 1000 / 60;
  return mins > 59
    ? t('tabs.history.hoursDuration', { hours: (mins / 60).toFixed(1) })
    : t('tabs.history.minDuration', { minutes: mins.toFixed(0) });
}

function getIconIdForInterval(
  configuration: SiteConfiguration,
  interval: KanbanWorkInterval | undefined,
  handling: KanbanHandling
): string {
  if (!interval) {
    return 'pause-circle';
  }
  if (interval.type === 'anomaly') {
    return 'exclamation-triangle';
  }
  if (interval.type === 'andon') {
    return 'ambulance';
  }
  return getLocationIconForHandling(configuration, handling);
}

function toIntervalDetailsInternalDataStructure(
  configuration: SiteConfiguration,
  handlings: readonly KanbanHandling[]
): (IntervalDetailsInternalDataStructure | EmptyHandlingDetailsInternalDataStructure)[] {
  const datas: (
    | IntervalDetailsInternalDataStructure
    | EmptyHandlingDetailsInternalDataStructure
  )[] = [];
  handlings.forEach((h) => {
    let hasOpenInterval = false;
    h.intervals.forEach((i) => {
      const isCurrent = !isTruthy(i.endDate);
      hasOpenInterval = hasOpenInterval || isCurrent;
      datas.push({
        handlingId: h.id,
        id: i.id,
        stand: h.standId,
        post: kanbanHelpers.getPostLabelForHandling(h),
        users: i.users,
        date: i.startDate,
        endDate: i.endDate ?? Date.now(),
        isCurrent,
        iconId: getIconIdForInterval(configuration, i, h),
        comment: i.comment,
      });
    });
    if (h.intervals.length === 0 || (!isTruthy(h.endDate) && !hasOpenInterval)) {
      datas.push({
        id: h.id,
        stand: h.standId,
        post: kanbanHelpers.getPostLabelForHandling(h),
        users: [],
        date: h.startDate,
        endDate: h.endDate ?? Date.now(),
        isCurrent: !isTruthy(h.endDate),
        iconId: getIconIdForInterval(configuration, undefined, h),
      });
    }
  });
  return datas;
}

export function toCommentDetailsInternalDataStructure(
  messages: readonly KanbanMessage[]
): MessageDetailsInternalDataStructure[] {
  const datas: MessageDetailsInternalDataStructure[] = [];
  messages.forEach((c) => {
    const isRequest = c.type === 'request';

    datas.push({
      id: c.id,
      users: [c.username],
      date: c.timestamp,
      content: c.content,
      iconId: isRequest ? SUBCONTRACTOR_REQUEST_MESSAGE_ICON_ID : 'comment',
    });
  });
  return datas;
}

export function createCommentDetailsInternalDataStructureForCreation(
  t: TFunction,
  kanban: Kanban
): MessageDetailsInternalDataStructure {
  let content = '';
  switch (kanban.origin.source) {
    case AIRTABLE_SOURCE:
    case VOLEAN_SOURCE:
      content = t('tabs.history.creation.importedFrom', {
        source: kanban.origin.source,
        qualidiedId: kanban.origin.id,
      });
      break;
    case CLONE_SOURCE:
    case DELEGATION_SOURCE:
      content = t(`tabs.history.creation.${kanban.origin.source}`, { id: kanban.origin.id });
      break;
    default:
      content = t('tabs.history.creation.manual');
      break;
  }
  return {
    date: kanban.creationDate,
    id: '',
    users: [],
    iconId: 'plus-square',
    content,
  };
}

export function kanbanToDetailsInternalHistoryStructure(
  t: TFunction,
  configuration: SiteConfiguration,
  kanban: Kanban
): HistoryDetailsInternalDataStructure[] {
  const datas: HistoryDetailsInternalDataStructure[] = [];
  datas.push(...toIntervalDetailsInternalDataStructure(configuration, kanban.handlings));
  datas.push(...toCommentDetailsInternalDataStructure(kanban.messages));
  datas.push(createCommentDetailsInternalDataStructureForCreation(t, kanban));
  return datas;
}

function computePackageDealStatus(pd: PackageDeal): PackageDealStatus | 'unachievable' {
  if (packageDealHelpers.isPackageDealAvailableAndAchievable(pd, true)) {
    return 'available';
  }
  if (packageDealHelpers.isPackageDealAvailable(pd, true)) {
    return 'unachievable';
  }
  if (packageDealHelpers.isPackageDealCanceled(pd, true)) {
    return 'canceled';
  }
  throw Error(`Unknown package deal status (${pd.id})`);
}

export function kanbanToDetailsInternalPackageDealStructure(
  packageDeals: readonly PackageDeal[],
  carViewCategoryLabels: Record<CarViewCategory, string>,
  purchaseOrders: readonly PurchaseOrder[],
  delegationSite: string | undefined
): PackageDealDetailsInternalDataStructure[] {
  const datas: PackageDealDetailsInternalDataStructure[] = [];
  packageDeals.filter(nonDeleted).forEach((pd) => {
    const status = computePackageDealStatus(pd);
    let completionDate: number | null = null;
    let startDate: number | null = null;

    const activeOperations = pd.operations.filter(nonDeleted);
    const { filtered: finishedOps } = filterReject(activeOperations, (o) =>
      isTruthy(o.completionDate)
    );
    if (activeOperations.length > 0) {
      if (finishedOps.length > 0) {
        const sortedFinishedOps = finishedOps
          .slice()
          .sort(sortingHelpers.createSortByNumericField('DOWN', 'completionDate'));
        startDate = sortedFinishedOps[sortedFinishedOps.length - 1].completionDate;
        if (activeOperations.length === finishedOps.length) {
          completionDate = sortedFinishedOps[0].completionDate;
        }
      }
    }
    let unachievableStatus: PkgUnachievableStatus;
    const orderedOrReceivedSpareParts = pd.spareParts.filter(
      (sp) => nonDeleted(sp) && (isTruthy(sp.dateOfOrder) || isTruthy(sp.dateOfReception))
    );
    if (isTruthyAndNotEmpty(pd.unachievableReason)) {
      unachievableStatus = 'repickable';
    } else if (packageDealHelpers.allOperationsAreFinished(pd)) {
      unachievableStatus = 'unmarkableAsUnachievableBecauseOfFinishedOperations';
    } else if (packageDealHelpers.isPackageDealUnremovable(pd.code)) {
      unachievableStatus = 'unmarkableAsUnachievableBecauseMandatory';
    } else if (orderedOrReceivedSpareParts.length > 0) {
      unachievableStatus = 'unmarkableAsUnachievableBecauseOrderedOrReceivedSpareParts';
    } else {
      const doesStatusPermitMarkAsUnachievable =
        status !== 'unachievable' && !isTruthy(completionDate) && status !== 'canceled';
      const finishedOperationsCount = packageDealHelpers.getFinishedOperations(pd).length;
      if (!doesStatusPermitMarkAsUnachievable) {
        unachievableStatus = 'unmarkableAsUnachievableBecauseOfStatus';
      } else {
        unachievableStatus =
          finishedOperationsCount === 0
            ? 'markableAsUnachievable'
            : 'unmarkableAsUnachievableBecauseOfFinishedOperations';
      }
    }

    const category = carElementHelpers.getCarElementCategory(pd.carElement);
    const purchaseOrder = purchaseOrders.find(({ id }) => id === pd.purchaseOrderId);
    const purchaseOrderNumber = purchaseOrder?.purchaseNumber ?? '';

    datas.push({
      id: pd.id,
      purchaseOrder: purchaseOrderNumber,
      code: pd.code,
      label: packageDealHelpers.getPackageDealDisplayedLabel(pd),
      carElement: pd.carElement?.label ?? '',
      category,
      tag: enumerate(pd.tags),
      translatedCategoryLabel: carViewCategoryLabels[category],
      price: pd.price,
      duration: pd.duration,
      spareParts: pd.spareParts.filter(nonDeleted),
      operations: pd.operations.filter(nonDeleted),
      recommendedByExpert: pd.recommendedByExpert,
      hint: pd.hint,
      comment: pd.comment,
      status,
      attachments: !pd.attachments ? [] : pd.attachments.filter(nonDeleted),
      unachievableStatus,
      unachievableReason: pd.unachievableReason ?? undefined,
      completionDate,
      startDate,
      operationsCount: activeOperations.length,
      delegatedPackageDealId: pd.attributes.delegatedPackageDealId
        ? String(pd.attributes.delegatedPackageDealId)
        : null,
      attributes: pd.attributes,
      delegationSite,
    });
  });
  return datas;
}

export function kanbanToDetailsInternalOperationStructure(
  packageDeals: readonly PackageDeal[],
  carViewCategoryLabels: Record<CarViewCategory, string>
): OperationDetailsInternalDataStructure[] {
  const data: OperationDetailsInternalDataStructure[] = [];
  packageDeals.filter(nonDeleted).forEach((pd) => {
    pd.operations.forEach((o) => {
      if (!o.deleted) {
        const status = computePackageDealStatus(pd);
        if (status) {
          data.push({
            id: o.id,
            packageDealLabel: packageDealHelpers.getPackageDealDisplayedLabel(pd),
            isExpertisePackageDeal: packageDealHelpers.isExpertisePackageDeal(pd),
            translatedCategoryLabel:
              carViewCategoryLabels[carElementHelpers.getCarElementCategory(pd.carElement)],
            standId: o.standId,
            label: packageDealHelpers.getOperationDisplayedLabel(o, pd.variables),
            stand: o.standId,
            carElement: pd.carElement?.label ?? '',
            workload: o.workload,
            status,
            operationCompletionDate: o.completionDate,
            user: o.user,
            subcontractor: o.subcontractor,
            attributes: o.attributes,
            orderIndex: o.orderIndex,
          });
        }
      }
    });
  });
  return data;
}

export function getSparePartDetailStatus(sp: SparePart): SparePartDetailStatus {
  let status: SparePartDetailStatus = 'none';
  if (isTruthy(sp.dateOfReception)) {
    status = 'received';
  } else if (isTruthy(sp.dateOfOrder)) {
    status = 'ordered';
  } else if (isTruthy(sp.dateOfReference)) {
    status = 'referenced';
  }
  return status;
}

export function kanbanToSparePartDetailsInternalDataStructure(
  packageDeals: readonly PackageDeal[],
  carViewCategoryLabels: Record<CarViewCategory, string>
): readonly SparePartDetailsInternalDataStructure[] {
  const datas = packageDeals
    .filter(nonDeleted)
    .reduce<readonly SparePartDetailsInternalDataStructure[]>((acc, cur) => {
      const category = carElementHelpers.getCarElementCategory(cur.carElement);
      const pdSparePartDatas = cur.spareParts
        .filter(nonDeleted)
        .map((sp): SparePartDetailsInternalDataStructure => {
          const providerPrice = sp.providerUnitPrice * sp.quantity;
          const status: SparePartDetailStatus = getSparePartDetailStatus(sp);
          return {
            ...sp,
            status,
            carElement: cur.carElement?.label ?? '',
            packageDealLabel: cur.label,
            translatedCategoryLabel: carViewCategoryLabels[category],
            pdStatus: computePackageDealStatus(cur),
            marginPercentage: (100 * (sp.price - providerPrice)) / providerPrice,
            providerPrice,
          };
        });
      return [...acc, ...pdSparePartDatas];
    }, []);
  return datas;
}

export function kanbanToDetailsInternalAttributeStructure(
  attributes: Record<string, AttributeType>,
  contractAttributeDescs: readonly ContractAttributeDesc[]
): AttributeDetailsInternalDataStructure[] {
  const temp: Record<string, AttributeType> = { ...attributes };
  contractAttributeDescs.forEach((ad) => {
    if (!keysOf(attributes).find((key) => key === ad.id)) {
      temp[ad.id] = '';
    }
  });
  const data: AttributeDetailsInternalDataStructure[] = [];
  keysOf(temp).forEach((key) => {
    const foundAD = contractAttributeDescs.find((ad) => ad.id === key);
    data.push({
      label: key,
      value: String(temp[key]),
      isDisplayedEstimate: foundAD?.showInEstimate ?? false,
      isMandatory: foundAD?.mandatory ?? false,
    });
  });
  return data;
}
