import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Stand } from '@stimcar/libs-base';
import type { NoArgActionCallback, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { workflowHelpers } from '@stimcar/libs-base';
import { useGetState } from '@stimcar/libs-uikernel';
import {
  ModalCardDialog,
  ReactSelectFormField,
  ReadOnlyInputFormField,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type {
  MoveStandSparePartFormData,
  MoveStandSparePartModalState,
} from '../details/typings/store.js';
import type { Store } from '../state/typings/store.js';

function canSparePartsBeReallocatedToStand(stand: Stand): boolean {
  return (
    !workflowHelpers.isExpertiseStand(stand) &&
    stand.noReallocationTo !== true &&
    stand.type !== 'expertiseValidation' &&
    stand.type !== 'sparepartsReference'
  );
}

interface MoveStandSparePartModalProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, MoveStandSparePartModalState>;
  readonly submitValidDataAction: NoArgActionCallback<Store>;
}

const mandatoryFields: (keyof MoveStandSparePartFormData)[] = ['standId'];

export function MoveStandSparePartModal({
  $,
  $gs,
  submitValidDataAction,
}: MoveStandSparePartModalProps): JSX.Element {
  const [t] = useTranslation('details');

  const currentStandId = useGetState($.$currentStandId);
  const stands = useGetState($gs.$siteConfiguration.$stands);
  const sparePartLabel = useGetState($.$sparePartLabel);

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    MoveStandSparePartModalState
  >({
    $,
    mandatoryFields,
    checkFieldContentActions: undefined,
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });

  const standIds = useMemo(
    (): string[] =>
      stands
        .filter((s) => s.id !== currentStandId && canSparePartsBeReallocatedToStand(s))
        .map(({ id }) => id),
    [stands, currentStandId]
  );

  const formWarning = useGetState($.$formWarning);

  return (
    <ModalCardDialog
      title={t('tabs.spareParts.moveStandModal.title', { label: sparePartLabel })}
      $active={$.$active}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <ReadOnlyInputFormField
        label={t('tabs.spareParts.moveStandModal.currentStandId')}
        value={useGetState($.$currentStandId) ?? ''}
        horizontal
      />
      <ReactSelectFormField
        label={t('tabs.spareParts.moveStandModal.standId')}
        suggestions={standIds}
        $={$formDataWithChangeTrigger.$standId}
        creation={false}
        horizontal
      />
    </ModalCardDialog>
  );
}
