import type { JSX } from 'react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import type {
  Attachment,
  AttachmentMetadata,
  Kanban,
  Operation,
  StorageCategories,
} from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes, EMPTY_UICONTRACT } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState, useScreenIsBulmaMobile } from '@stimcar/libs-uikernel';
import type { Store } from '../state/typings/store.js';
import { ConfirmAttachmentRemovalDialog } from '../../lib/components/attachments/ConfirmAttachmentRemovalDialog.js';
import { initializeSelectedPurchaseOrderTabAction } from '../../lib/components/documentGeneration/estimate/EstimateView.js';
import { EMPTY_ESTIMATE_VIEW_STATE } from '../../lib/components/documentGeneration/estimate/typings/store.js';
import { DISPLAY_ATTRIBUTES_EMPTY_STATE } from '../../lib/components/typings/store.js';
import { useComponentWithMountTracking } from '../../lib/hooks/useComponentWithMountTracking.js';
import { getFoldersWithKanbanSpecificAttachmentFolders } from '../../utils/folderUtils.js';
import {
  EXPERTISE_ATTACHMENT_CATEGORY,
  useGetAllAttachmentFoldersForContractDocuments,
} from '../utils/attachmentGalleryActions.js';
import { loadKanbanLocallyOrFromServer } from '../utils/navigationActions.js';
import { appendRegisteredBrowserSessionToken } from '../utils/security.js';
import { useComputeAttachmentUrl } from '../utils/useComputeAttachmentUrl.js';
import { useGetContractByCode } from '../utils/useGetContract.js';
import type { KanbanDetailsState } from './typings/store.js';
import { operationUpdateNotificationInEditDialogAction } from './EditOperationModal.js';
import { KanbanDetailsComponent } from './KanbanDetailsComponent.js';
import { shouldAllowAttachmentDeletion } from './kanbanDetailsUtils.js';
import { MobileDetailsView } from './MobileDetailsView.js';
import {
  DESKTOP_KANBAN_DETAILS_EMPTY_STATE,
  EMPTY_KANBAN_DETAILS_STATE,
  KanbanDetailsContentDisplayTabs,
  MOBILE_KANBAN_DETAILS_EMPTY_STATE,
} from './typings/store.js';

async function doInitializeOrUpdateDetailsViewState(
  {
    actionDispatch,
    getState,
    globalActionDispatch,
    getGlobalState,
  }: ActionContext<Store, KanbanDetailsState>,
  selectedKanban: Kanban,
  addInNavbar: boolean,
  startWithFreshState: boolean
): Promise<void> {
  if (addInNavbar) {
    globalActionDispatch.reduce((initial) => {
      return {
        ...initial,
        detailKanbanId: selectedKanban.id,
      };
    });
  }

  const { contracts } = getGlobalState();

  const contract = contracts.find((c) => c.code === selectedKanban.contract.code);

  actionDispatch.reduce((initial) => {
    if (startWithFreshState) {
      return {
        ...EMPTY_KANBAN_DETAILS_STATE,
        // Don't override "componentIsMounted" attribute
        componentIsMounted: initial.componentIsMounted,
        selectedKanban,
        allContracts: contracts,
        desktopState: {
          ...DESKTOP_KANBAN_DETAILS_EMPTY_STATE,
          attributesTab: {
            ...DISPLAY_ATTRIBUTES_EMPTY_STATE,
            attributes: selectedKanban.attributes,
            contractAttributeDescs: contract?.attributeDescs ?? [],
          },
        },
        mobileState: MOBILE_KANBAN_DETAILS_EMPTY_STATE,
        estimateView: EMPTY_ESTIMATE_VIEW_STATE,
        viewToShow: 'details',
      };
    }
    return {
      ...initial,
      selectedKanban,
      allContracts: contracts,
      desktopState: {
        ...initial.desktopState,
        attributesTab: {
          ...initial.desktopState.attributesTab,
          attributes: selectedKanban.attributes,
          contractAttributeDescs: contract?.attributeDescs ?? [],
        },
      },
    };
  });

  await actionDispatch
    .scopeProperty('desktopState')
    .scopeProperty('estimateView')
    .exec(initializeSelectedPurchaseOrderTabAction, selectedKanban.purchaseOrders);

  // Update edited operation if needed
  const editOperationModalState = getState().desktopState.operationsTab.editOperationModal;
  if (
    selectedKanban &&
    editOperationModalState.active &&
    editOperationModalState.initialOperation
  ) {
    const operationId = editOperationModalState.initialOperation.id;
    const updated = selectedKanban.packageDeals.reduce<Operation | undefined>((p, c) => {
      if (p) {
        return p;
      }
      if (c.deleted) {
        return undefined;
      }
      return c.operations.filter((o) => !o.deleted).find((o) => o.id === operationId);
    }, undefined);
    if (updated) {
      await actionDispatch
        .scopeProperty('desktopState')
        .scopeProperty('operationsTab')
        .scopeProperty('editOperationModal')
        .exec(operationUpdateNotificationInEditDialogAction, updated);
    }
  }
}

async function initSearchDetailsViewState(
  {
    actionDispatch,
    kanbanRepository,
    httpClient,
    getGlobalState,
    globalActionDispatch,
  }: ActionContext<Store, KanbanDetailsState>,
  kanbanId: string,
  addInNavbar: boolean
): Promise<void> {
  const selectedKanbanOrError = await loadKanbanLocallyOrFromServer(
    kanbanRepository,
    httpClient,
    getGlobalState().session.isOnline,
    kanbanId
  );
  if (isTruthy(selectedKanbanOrError)) {
    if (typeof selectedKanbanOrError === 'string') {
      globalActionDispatch.setProperty('message', selectedKanbanOrError);
      return;
    }
    await actionDispatch.exec(
      doInitializeOrUpdateDetailsViewState,
      selectedKanbanOrError,
      addInNavbar,
      true
    );
  }
}

export async function handleClosedOrArchivedKanbansInDetailsViewStateFromSSEAction(
  {
    actionDispatch,
    getState,
    kanbanRepository,
    httpClient,
    getGlobalState,
  }: ActionContext<Store, KanbanDetailsState>,
  ids: string[]
): Promise<void> {
  // Only update the state if the component is mounted
  if (!getState().componentIsMounted) {
    return;
  }
  if (getState().selectedKanban) {
    const kanban = nonnull(getState().selectedKanban);
    if (ids.includes(kanban.id)) {
      const selectedKanbanOrError = await loadKanbanLocallyOrFromServer(
        kanbanRepository,
        httpClient,
        getGlobalState().session.isOnline,
        kanban.id
      );
      if (typeof selectedKanbanOrError === 'object') {
        await actionDispatch.exec(
          doInitializeOrUpdateDetailsViewState,
          selectedKanbanOrError,
          false,
          false
        );
      }
    }
  }
}

export async function updateDetailsViewStateFromSSEAction(
  { actionDispatch, getState }: ActionContext<Store, KanbanDetailsState>,
  kanban: Kanban
): Promise<void> {
  // Only update the state if the component is mounted
  if (!getState().componentIsMounted) {
    return;
  }
  if (getState().selectedKanban?.id === kanban.id) {
    await actionDispatch.exec(doInitializeOrUpdateDetailsViewState, kanban, false, false);
  }
}

async function removeAttachmentAction(
  {
    actionDispatch,
    getState,
    httpClient,
    getGlobalState,
  }: ActionContext<Store, KanbanDetailsState>,
  category: StorageCategories
): Promise<void> {
  const { selectedKanban, attachmentsState } = getState();
  const { confirmAttachmentRemovalDialog, attachments } = attachmentsState;
  if (!isTruthy(selectedKanban)) {
    throw Error('No kanban are selected');
  }

  const { folder, name, id } = confirmAttachmentRemovalDialog;
  await httpClient.httpGet(
    appendRegisteredBrowserSessionToken(
      CoreBackendRoutes.ATTACHMENT(category, selectedKanban.id, folder, name),
      nonnull(getGlobalState().session.infos).sessionToken
    ),
    'DELETE'
  );
  actionDispatch
    .scopeProperty('attachmentsState')
    .scopeProperty('confirmAttachmentRemovalDialog')
    .setProperty('active', false);
  const remindedAttachments = attachments.filter(({ id: currentId, folder: currentFolder }) => {
    return currentId !== id || currentFolder !== folder;
  });
  actionDispatch.scopeProperty('attachmentsState').setProperty('attachments', remindedAttachments);
}

function useOnScreenOrientationChange($: StoreStateSelector<Store, KanbanDetailsState>): void {
  const onScreenOrientationChangeAsyncEffect = useActionCallback(
    ({ actionDispatch, getState }) => {
      const { isUnfoldedOnMobile } = getState().standardPicturesTab;
      if (isUnfoldedOnMobile) {
        actionDispatch
          .scopeProperty('desktopState')
          .scopeProperty('kanbanDetailsContentDisplayTab')
          .setValue(KanbanDetailsContentDisplayTabs.standardPictures);
      }
    },
    [],
    $
  );

  useEffect(() => {
    const windowOrientationListener = ({ matches: isLandscape }: MediaQueryListEvent) => {
      if (isLandscape) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        onScreenOrientationChangeAsyncEffect();
      }
    };
    window
      .matchMedia('(orientation: landscape)')
      .addEventListener('change', windowOrientationListener);
    return () => {
      window
        .matchMedia('(orientation: landscape)')
        .removeEventListener('change', windowOrientationListener);
    };
  }, [onScreenOrientationChangeAsyncEffect]);
}

interface KanbanDetailsProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, KanbanDetailsState>;
  readonly addKanbanInNavbar: boolean;
}

export function KanbanDetails({ $, $gs, addKanbanInNavbar }: KanbanDetailsProps): JSX.Element {
  const [t] = useTranslation('details');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { id } = useParams<any>();

  // Allows to track if the view is mounted (through a boolean in the state)
  useComponentWithMountTracking(
    $,
    undefined /* not used, as we want the initializer to be called only if an id is provided */,
    undefined // We don't clean the state when unmount not to lose the selected kanban
  );

  const { $confirmAttachmentRemovalDialog } = $.$attachmentsState;

  const isMobile = useScreenIsBulmaMobile($gs.$window);

  const computeAttachmentUrl = useComputeAttachmentUrl($gs);

  const asyncEffect = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      if (isTruthyAndNotEmpty(id)) {
        await actionDispatch.exec(initSearchDetailsViewState, id, addKanbanInNavbar);
      }
    },
    [id, addKanbanInNavbar],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
  }, [asyncEffect]);

  useOnScreenOrientationChange($);

  const removeAttachmentActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(removeAttachmentAction, EXPERTISE_ATTACHMENT_CATEGORY);
    },
    [],
    $
  );
  const selectedKanban = useGetState($.$selectedKanban);

  const contract = useGetContractByCode($gs, selectedKanban?.contract.code) ?? EMPTY_UICONTRACT;

  const contractAttachmentFolders = useGetAllAttachmentFoldersForContractDocuments(
    contract.documents
  );

  const documentFolders = useMemo(
    () => getFoldersWithKanbanSpecificAttachmentFolders(selectedKanban, contractAttachmentFolders),
    [contractAttachmentFolders, selectedKanban]
  );

  const shouldRestrictAttachmentRemoval = useCallback(
    (attachment: Attachment, metadata: AttachmentMetadata | undefined): boolean => {
      return !shouldAllowAttachmentDeletion(selectedKanban?.status, attachment, metadata);
    },
    [selectedKanban]
  );

  return (
    <>
      {selectedKanban === undefined ? (
        <div className="box">
          <p className="has-text-centered">{t('noCarSelected')}</p>
        </div>
      ) : (
        <>
          {isMobile ? (
            <MobileDetailsView $={$} $gs={$gs} documentsFolders={documentFolders} />
          ) : (
            <KanbanDetailsComponent
              $={$}
              $gs={$gs}
              documentsFolders={documentFolders}
              contract={contract}
            />
          )}
          <ConfirmAttachmentRemovalDialog
            category="kanban"
            objectId={selectedKanban?.id ?? ''}
            $={$confirmAttachmentRemovalDialog}
            okLabel={t('confirmAttachmentRemovalDialog.okLabel')}
            onOkClicked={removeAttachmentActionCallback}
            computeAttachmentUrl={computeAttachmentUrl}
            shouldRestrictRemove={shouldRestrictAttachmentRemoval}
          >
            <p>{t('confirmAttachmentRemovalDialog.message')}</p>
          </ConfirmAttachmentRemovalDialog>
        </>
      )}
    </>
  );
}
