import type { ErrorInfo, PropsWithChildren } from 'react';
import React from 'react';
import { Logger } from '@stimcar/libs-kernel';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

interface State {
  readonly hasError: boolean;
  readonly error: Error | null;
}

export class ErrorBoundary extends React.Component<PropsWithChildren, State> {
  public constructor(props: object) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, info: ErrorInfo): void {
    if (error) {
      log.error('componentDidCatch:', error.message, error.stack);
    }
    if (info) {
      log.info('componentDidCatch:', info.componentStack);
    }
  }

  public render(): React.ReactNode {
    const { hasError, error } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div className="m-l-sm">
          <h2 className="is-size-2">Something went wrong</h2>
          <details open>
            <summary className="is-size-4">{error?.message ?? 'Error:'}</summary>
            <pre>{error?.stack ?? ''}</pre>
          </details>
        </div>
      );
    }

    return children;
  }
}
