import type { KeyValueStorage } from '@stimcar/libs-kernel';
import { Logger } from '@stimcar/libs-kernel';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

export type KeyValueDictionnary = {
  [key: string]: string | object;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class InMemoryKeyValueStorageImpl<ARGS extends any[] = []> implements KeyValueStorage {
  private dictionnary: KeyValueDictionnary;

  public constructor(...args: ARGS) {
    this.dictionnary = this.loadDictionnary(...args);
  }

  public clear(): void {
    this.dictionnary = {};
    this.persistDictionnary(this.dictionnary);
  }

  public getItem(key: string): string | null {
    const value = this.dictionnary[key];
    return !value ? null : (value as string);
  }

  public getNumberItem(key: string): number | null {
    const value = this.getItem(key);
    return !value ? null : Number.parseInt(value, 10);
  }

  public getObjectItem<T extends object>(key: string): T | null {
    const value = this.dictionnary[key];
    return !value ? null : (value as T);
  }

  public removeItem(key: string): void {
    if (Object.keys(this.dictionnary).includes(key)) {
      delete this.dictionnary[key];
    }
    this.persistDictionnary(this.dictionnary);
  }

  public setItem(key: string, value: string | number | boolean): void {
    this.dictionnary[key] = String(value);
    this.persistDictionnary(this.dictionnary);
  }

  public setObjectItem<T extends object>(key: string, value: T): void {
    this.dictionnary[key] = value;
    this.persistDictionnary(this.dictionnary);
  }

  public size(): number {
    return Object.keys(this.dictionnary).length;
  }

  public key(index: number): string | null {
    const keys = Object.keys(this.dictionnary);
    return keys.length < index ? null : keys[index];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected persistDictionnary(dictionnay: KeyValueDictionnary): void {
    // No-op for in-memory dictionnary
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected loadDictionnary(...args: ARGS): KeyValueDictionnary {
    return {};
  }
}
