import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { AttachmentFolder, StorageCategories } from '@stimcar/libs-base';
import type {
  ActionCallback,
  ActionContext,
  AnyStoreDef,
  StoreStateSelector,
  WindowState,
} from '@stimcar/libs-uikernel';
import type { BaseStoreDefWithHttpClient, ImageModalState } from '@stimcar/libs-uitoolkit';
import { nonDeleted } from '@stimcar/libs-base';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Button, ImportButton } from '@stimcar/libs-uitoolkit';
import type { LoadAttachmentsActionCallback } from './AttachmentsGallery.js';
import type { ComputeAttachmentUrlCallback } from './typings/attachment.js';
import type { AttachmentsState } from './typings/store.js';
import { AttachmentsGalleryDialog } from './AttachmentsGalleryDialog.js';
import { AttachmentThumbnail } from './AttachmentThumbnail.js';
import { ConfirmAttachmentRemovalDialog } from './ConfirmAttachmentRemovalDialog.js';

const showGalleryModalAction = async <SD extends AnyStoreDef>({
  actionDispatch,
  getState,
}: // eslint-disable-next-line @typescript-eslint/require-await
ActionContext<SD, AttachmentsState>): Promise<void> => {
  const { attachments } = getState();
  actionDispatch.applyPayload({
    galleryModal: {
      active: true,
      selectedPaths: attachments
        ? attachments.filter(nonDeleted).map(({ folder, name }): string => `${folder}/${name}`)
        : [],
    },
  });
};

const showRemoveAttachmentConfirmDialogAction = async <SD extends AnyStoreDef>(
  { actionDispatch }: ActionContext<SD, AttachmentsState>,
  folder: string,
  name: string,
  id: string
  // eslint-disable-next-line @typescript-eslint/require-await
): Promise<void> => {
  actionDispatch.applyPayload({
    confirmRemoval: {
      active: true,
      folder,
      name,
      id,
    },
  });
};

export interface AttachmentsProps<SD extends BaseStoreDefWithHttpClient> {
  readonly category: StorageCategories;
  readonly objectId: string;
  readonly folder: AttachmentFolder;
  readonly toolsAreEnabled: boolean;
  readonly $: StoreStateSelector<SD, AttachmentsState>;
  readonly $window: StoreStateSelector<SD, WindowState>;
  readonly computeAttachmentUrl: ComputeAttachmentUrlCallback;
  readonly $imageModal: StoreStateSelector<SD, ImageModalState>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly attachDocumentActionCallback?: ActionCallback<SD, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly detachDocumentActionCallback?: ActionCallback<SD, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly importDocumentAction?: (
    context: ActionContext<SD, AttachmentsState>,
    files: readonly File[]
  ) => Promise<void>;
  readonly onUploadButtonClickedCallback?: () => void | Promise<void>;
  readonly loadAttachmentsActionCallback: LoadAttachmentsActionCallback<SD>;
  readonly nothingToDisplayPlaceholder?: string;
  readonly selectMode?: 'multiple' | 'one';
  readonly onClickOnThumbnailBehavior?: 'openImageModal' | 'select';
}

export function AttachmentsPanel<SD extends BaseStoreDefWithHttpClient>({
  category,
  objectId,
  folder,
  toolsAreEnabled,
  $,
  $window,
  computeAttachmentUrl,
  $imageModal,
  importDocumentAction,
  attachDocumentActionCallback,
  detachDocumentActionCallback,
  loadAttachmentsActionCallback,
  onUploadButtonClickedCallback,
  nothingToDisplayPlaceholder,
  selectMode,
  onClickOnThumbnailBehavior,
}: AttachmentsProps<SD>): JSX.Element {
  const [t] = useTranslation('libComponents');
  const attachments = useGetState($.$attachments);
  const galleryModal = useGetState($.$galleryModal);

  /**
   * Attachment removal modal
   */
  const showRemoveAttachmentConfirmDialogCallback = useActionCallback(
    showRemoveAttachmentConfirmDialogAction,
    [],
    $
  );

  /**
   * Gallery modal
   */
  const showGalleryModal = useActionCallback(showGalleryModalAction, [attachments], $);

  const importDocumentActionCallback = useActionCallback(
    async ({ actionDispatch }, files: readonly File[]) => {
      // eslint-disable-next-line
      // @ts-ignore 2345
      await actionDispatch.exec(importDocumentAction, files);
    },
    [importDocumentAction],
    $
  );

  const nonDeletedAttachments = useMemo(() => attachments.filter(nonDeleted), [attachments]);

  return (
    <>
      <div className="columns">
        {toolsAreEnabled && (
          <>
            <div className="column is-narrow">
              <Button
                label={t('attachments.panel.select')}
                additionalClass="is-primary"
                size="small"
                iconId="images"
                onClick={showGalleryModal}
                tooltip={t('attachments.panel.select')}
                disabled={!toolsAreEnabled}
              />
            </div>
            <div className="column">
              <ImportButton
                onFilesReadyCallback={importDocumentActionCallback}
                label={t('attachments.importButton')}
                additionalClass="is-primary"
                size="small"
                disabled={!toolsAreEnabled}
                allowMultipleImport
                onButtonClickedCallback={onUploadButtonClickedCallback}
              />
            </div>
          </>
        )}
      </div>
      <div className="columns is-mobile is-multiline">
        {nonDeletedAttachments.length > 0 ? (
          nonDeletedAttachments.map(
            ({ id, name }, index): JSX.Element => (
              <div className="column is-narrow" key={id}>
                <AttachmentThumbnail
                  category={category}
                  objectId={objectId}
                  computeAttachmentUrl={computeAttachmentUrl}
                  onRemoveCallback={
                    toolsAreEnabled ? showRemoveAttachmentConfirmDialogCallback : undefined
                  }
                  size={64}
                  caption={name}
                  attachments={nonDeletedAttachments}
                  $imageModal={$imageModal}
                  attachmentIndex={index}
                  selectMode={selectMode}
                  onClickBehavior={onClickOnThumbnailBehavior}
                />
              </div>
            )
          )
        ) : (
          <div className="column is-narrow">
            {isTruthyAndNotEmpty(nothingToDisplayPlaceholder)
              ? nothingToDisplayPlaceholder
              : t('attachments.panel.noAttachment')}
          </div>
        )}
      </div>
      {galleryModal && attachDocumentActionCallback && (
        <AttachmentsGalleryDialog
          category={category}
          objectId={objectId}
          folders={folder}
          $={$.$galleryModal}
          $window={$window}
          loadAttachmentsActionCallback={loadAttachmentsActionCallback}
          onOkClicked={attachDocumentActionCallback}
          computeAttachmentUrl={computeAttachmentUrl}
          $imageModal={$imageModal}
          isOnline={toolsAreEnabled}
          selectMode={selectMode}
          onClickOnThumbnailBehavior={onClickOnThumbnailBehavior}
        />
      )}
      {detachDocumentActionCallback && (
        <ConfirmAttachmentRemovalDialog
          category={category}
          objectId={objectId}
          $={$.$confirmRemoval}
          okLabel={t('attachments.panel.confirmRemovalLabel')}
          onOkClicked={detachDocumentActionCallback}
          computeAttachmentUrl={computeAttachmentUrl}
        >
          <p>{t('attachments.panel.confirmRemovalMessage')}</p>
        </ConfirmAttachmentRemovalDialog>
      )}
    </>
  );
}
