import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';

export const DISPLAY_RELATIVE_PATH = `display`;
export const DISPLAY_FULL_PATH = `/${DISPLAY_RELATIVE_PATH}`;
export const DISPLAY_DASHBOARD_RELATIVE_PATH = `dashboard`;
export const DISPLAY_DASHBOARD_FULL_PATH = `${DISPLAY_FULL_PATH}/${DISPLAY_DASHBOARD_RELATIVE_PATH}`;
export const DISPLAY_STAND_ACHIEVEMENTS_RELATIVE_PATH = `stand`;
export const DISPLAY_STAND_ACHIEVEMENTS_FULL_PATH = `${DISPLAY_FULL_PATH}/${DISPLAY_STAND_ACHIEVEMENTS_RELATIVE_PATH}`;
export const DISPLAY_WORKSHOP_DASHBOARD_V2_RELATIVE_PATH = `workshop_dashboard_v2`;
export const DISPLAY_WORKSHOP_DASHBOARD_V2_FULL_PATH = `${DISPLAY_FULL_PATH}/${DISPLAY_WORKSHOP_DASHBOARD_V2_RELATIVE_PATH}`;
export const DISPLAY_SHIFT_PROGRESS_RELATIVE_PATH = `shiftProgress`;
export const DISPLAY_SHIFT_PROGRESS_FULL_PATH = `${DISPLAY_FULL_PATH}/${DISPLAY_SHIFT_PROGRESS_RELATIVE_PATH}`;

export const SELECT_KANBAN_RELATIVE_PATH = `select`;
export const SELECT_KANBAN_FULL_PATH = `/${SELECT_KANBAN_RELATIVE_PATH}`;

export const ID_PARAM_PATH_FRAGMENT = `:id`;
export const KANBAN_ROUTE_CONFIGURATION_RELATIVE_PATH = `${ID_PARAM_PATH_FRAGMENT}`;
export const KANBAN_ROUTE_CONFIGURATION_FULL_PATH = `/${KANBAN_ROUTE_CONFIGURATION_RELATIVE_PATH}`;

export const ARCHIVE_RELATIVE_PATH = `archive`;
export const ARCHIVE_FULL_PATH = `/${ARCHIVE_RELATIVE_PATH}`;

export const ARCHIVE_DETAILS_RELATIVE_PATH = `${ARCHIVE_RELATIVE_PATH}/${ID_PARAM_PATH_FRAGMENT}`;
export const ARCHIVE_DETAILS_FULL_PATH = `/${ARCHIVE_DETAILS_RELATIVE_PATH}`;
export const computeArchiveDetailsPath = (id: string | undefined): string =>
  isTruthyAndNotEmpty(id) ? `${ARCHIVE_FULL_PATH}/${id}` : ARCHIVE_FULL_PATH;

export const computeBaseKanbanPath = (id: string | undefined): string =>
  isTruthyAndNotEmpty(id) ? `/${id}` : SELECT_KANBAN_FULL_PATH;

export const DETAILS_ROUTE_CONFIGURATION_RELATIVE_PATH = `${KANBAN_ROUTE_CONFIGURATION_RELATIVE_PATH}/details`;
export const DETAILS_ROUTE_CONFIGURATION_FULL_PATH = `/${DETAILS_ROUTE_CONFIGURATION_RELATIVE_PATH}`;
export const computeKanbanDetailsPath = (id: string | undefined): string =>
  isTruthyAndNotEmpty(id) ? `${computeBaseKanbanPath(id)}/details` : SELECT_KANBAN_FULL_PATH;

export const OPERATE_ROUTE_CONFIGURATION_RELATIVE_PATH = `${KANBAN_ROUTE_CONFIGURATION_RELATIVE_PATH}/operate/:standId`;
export const OPERATE_ROUTE_CONFIGURATION_FULL_PATH = `/${OPERATE_ROUTE_CONFIGURATION_RELATIVE_PATH}`;
export const computeOperatePath = (id: string | undefined, standId: string): string =>
  isTruthyAndNotEmpty(id)
    ? `${computeBaseKanbanPath(id)}/operate/${standId}`
    : SELECT_KANBAN_FULL_PATH;

export const VALIDATE_ROUTE_CONFIGURATION_PATH = `${KANBAN_ROUTE_CONFIGURATION_FULL_PATH}/validate`;
export const computeExpertiseValidationPath = (id: string | undefined): string =>
  isTruthyAndNotEmpty(id) ? `${computeBaseKanbanPath(id)}/validate` : SELECT_KANBAN_FULL_PATH;

export const ADMIN_BASE_RELATIVE_PATH = `admin`;
export const ADMIN_BASE_FULL_PATH = `/${ADMIN_BASE_RELATIVE_PATH}`;
export const ADMIN_WORKFLOWS_RELATIVE_PATH = `${ADMIN_BASE_RELATIVE_PATH}/workflows`;
export const ADMIN_WORKFLOWS_FULL_PATH = `/${ADMIN_WORKFLOWS_RELATIVE_PATH}`;
export const ADMIN_CUSTOMERS_RELATIVE_PATH = `${ADMIN_BASE_RELATIVE_PATH}/customers`;
export const ADMIN_CUSTOMERS_FULL_PATH = `/${ADMIN_CUSTOMERS_RELATIVE_PATH}`;
export const ADMIN_CAR_ELEMENTS_RELATIVE_PATH = `${ADMIN_BASE_RELATIVE_PATH}/car-elements`;
export const ADMIN_CAR_ELEMENTS_FULL_PATH = `/${ADMIN_CAR_ELEMENTS_RELATIVE_PATH}`;
export const ADMIN_PACKAGE_DEALS_RELATIVE_PATH = `${ADMIN_BASE_RELATIVE_PATH}/packages`;
export const ADMIN_PACKAGE_DEALS_FULL_PATH = `/${ADMIN_PACKAGE_DEALS_RELATIVE_PATH}`;
export const ADMIN_KANBAN_IMPORT_RELATIVE_PATH = `${ADMIN_BASE_RELATIVE_PATH}/kanban-import`;
export const ADMIN_KANBAN_IMPORT_FULL_PATH = `/${ADMIN_KANBAN_IMPORT_RELATIVE_PATH}`;

export const ADMIN_CREATE_KANBAN_RELATIVE_PATH = `${ADMIN_BASE_RELATIVE_PATH}/create`;
export const ADMIN_CREATE_KANBAN_FROM_RELATIVE_PATH = `${ADMIN_BASE_RELATIVE_PATH}/create/from/:id`;
export const ADMIN_CREATE_KANBAN_FULL_PATH = `/${ADMIN_CREATE_KANBAN_RELATIVE_PATH}`;
export const computeCreateFormPath = (id: string | undefined): string =>
  isTruthyAndNotEmpty(id)
    ? `${ADMIN_CREATE_KANBAN_FULL_PATH}/from/${id}`
    : ADMIN_CREATE_KANBAN_FULL_PATH;

export const USERS_ADMIN_RELATIVE_PATH = `${ADMIN_BASE_RELATIVE_PATH}/users`;
export const USERS_ADMIN_FULL_PATH = `/${USERS_ADMIN_RELATIVE_PATH}`;

export const WORKSHOP_DASHBOARD_RELATIVE_PATH = `workshop_dashboard`;
export const WORKSHOP_DASHBOARD_FULL_PATH = `/${WORKSHOP_DASHBOARD_RELATIVE_PATH}`;
export const WORKSHOP_OPERATOR_DASHBOARD_RELATIVE_PATH = `workshop`;
export const WORKSHOP_OPERATOR_DASHBOARD_FULL_PATH = `/${WORKSHOP_OPERATOR_DASHBOARD_RELATIVE_PATH}`;
export const WORKSHOP_OPERATOR_RELATIVE_PATH = `${WORKSHOP_OPERATOR_DASHBOARD_RELATIVE_PATH}/post`;
export const WORKSHOP_OPERATOR_FULL_PATH = `/${WORKSHOP_OPERATOR_RELATIVE_PATH}`;

export const ADMIN_SCHEDULED_TASKS_RELATIVE_PATH = `${ADMIN_BASE_RELATIVE_PATH}/scheduledTasks`;
export const ADMIN_SCHEDULED_TASKS_FULL_PATH = `/${ADMIN_SCHEDULED_TASKS_RELATIVE_PATH}`;
export const ADMIN_CONTRACTS_RELATIVE_PATH = `${ADMIN_BASE_RELATIVE_PATH}/contracts`;
export const ADMIN_CONTRACTS_FULL_PATH = `/${ADMIN_CONTRACTS_RELATIVE_PATH}`;
export const ADMIN_SPAREPARTS_PROVIDERS_RELATIVE_PATH = `${ADMIN_BASE_RELATIVE_PATH}/sparePartsProviders`;
export const ADMIN_SPAREPARTS_PROVIDERS_FULL_PATH = `/${ADMIN_SPAREPARTS_PROVIDERS_RELATIVE_PATH}`;

export const DEFAULT_AFTER_LOGIN_PATHS_FOR_ROLES = {
  WorkshopOperator: WORKSHOP_OPERATOR_FULL_PATH,
  Operator: SELECT_KANBAN_FULL_PATH,
  Display: DISPLAY_FULL_PATH,
};

export const DEFAULT_AFTER_LOGOUT_FULL_PATHS_FOR_ROLES = {
  WorkshopOperator: DISPLAY_WORKSHOP_DASHBOARD_V2_FULL_PATH,
  Operator: DISPLAY_DASHBOARD_FULL_PATH,
  Display: DISPLAY_DASHBOARD_FULL_PATH,
};
