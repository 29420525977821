import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { kanbanHelpers } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../../state/typings/store.js';
import { QualityControl } from './QualityControl.js';
import './QualityControlPage.scss';

// TODO : AMT : Prise photo sur mobile => test PP
// TODO : FONCTIONNEL : que faire des données ?
// TODO : FONCTIONNEL : gestion de l'opération CQUA ?
// TODO : FONCTIONNEL : proper check affichage contrôle qualité poste photo (from configuration ?)
// TODO : FONCTIONNEL : affiche en opérateur et pas seulement opérateur d'atelier

// TODO : FONCTIONNEL : phase 2 : relever des anomalies non reportées à un forfait ? Sur une shape ou générales
// TODO : FONCTIONNEL : phase 2 : gérer prépa esthétique
// TODO : FONCTIONNEL : phase 2 : gérer check configurables par clients
export function QualityControlPage({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation('workshop');
  const standId = useGetState($gs.$session.$infos.optChaining().$standId) ?? '';
  const qualifiedPostId = useGetState($gs.$session.$infos.optChaining().$label) ?? '';

  const { $workshopImplantationView, $qualityControl } = $gs;
  const kanbans = useGetState($workshopImplantationView.$kanbans);

  const operatedKanban = useMemo(
    () =>
      kanbanHelpers.getKanbanCurrentlyOnWorkshopQualifiedPost(kanbans, standId, qualifiedPostId),
    [kanbans, qualifiedPostId, standId]
  );

  const shouldDisplayLinkToQualityControl = useMemo(
    () => qualifiedPostId?.includes('photo'),
    [qualifiedPostId]
  );

  if (!shouldDisplayLinkToQualityControl) {
    return (
      <article className="message is-link">
        <div className="message-header">
          <p>{t('qualityControl.unavailable.title')}</p>
        </div>
        <div className="message-body">{t('qualityControl.unavailable.stand')}</div>
      </article>
    );
  }

  if (!isTruthy(operatedKanban)) {
    return (
      <article className="message is-link">
        <div className="message-header">
          <p>{t('qualityControl.unavailable.title')}</p>
        </div>
        <div className="message-body">{t('qualityControl.unavailable.missingKanban')}</div>
      </article>
    );
  }

  return (
    <QualityControl
      $gs={$gs}
      $={$qualityControl}
      kanbanId={operatedKanban.id}
      packageDeals={operatedKanban.packageDeals}
    />
  );
}
