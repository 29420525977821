/* eslint-disable jsx-a11y/control-has-associated-label */

/* eslint-disable jsx-a11y/control-has-associated-label */
import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PackageDeal } from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  nonDeleted,
  packageDealHelpers,
  sortingHelpers,
  sparePartHelpers,
} from '@stimcar/libs-base';
import { applyPayload, isTruthy, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ScrollableTableComponent, TableActionCell } from '@stimcar/libs-uitoolkit';
import type { Store } from '../state/typings/store.js';
import { TableActionHeaderCell } from '../../lib/bulma/elements/table/TableHeaderActionCell.js';
import { Checkbox } from '../../lib/bulma/form/Checkbox.js';
import { DisplayContentOrPlaceholder } from '../../lib/components/misc/DisplayContentOrPlaceholder.js';
import { TableSortableHeaderComponent } from '../../lib/components/TableSortableHeaderComponent.js';
import { useGetAllCarViewCategoryLabels } from '../../utils/carViewCategoriesTranslationHooks.js';
import { MoveStandSparePartModal } from '../globalModals/MoveStandSparePartModal.js';
import type {
  KanbanDetailsState,
  MoveStandSparePartModalState,
  SparePartDetailsInternalDataStructure,
} from './typings/store.js';
import { kanbanToSparePartDetailsInternalDataStructure } from './kanbanDetailsUtils.js';
import { SparePartStatusIcon } from './SparePartStatusIcon.js';
import { EMPTY_MOVE_STAND_SPARE_PART_MODAL_STATE } from './typings/store.js';

interface SparePartItemProps {
  readonly sparePart: SparePartDetailsInternalDataStructure;
  readonly packageDeal: PackageDeal;
  readonly $: StoreStateSelector<Store, MoveStandSparePartModalState>;
  readonly showTechnicalId: boolean;
  readonly isEditable: boolean;
  readonly t: TFunction;
  readonly kanbanCustomerShortName: string;
}

function SparePartItem({
  sparePart,
  packageDeal,
  $,
  t,
  isEditable,
  showTechnicalId,
  kanbanCustomerShortName,
}: SparePartItemProps): JSX.Element {
  const showMoveStandSparePartModalCallback = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.reduce(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (initial): MoveStandSparePartModalState => {
          return {
            ...EMPTY_MOVE_STAND_SPARE_PART_MODAL_STATE,
            active: true,
            sparePartId: sparePart.id,
            sparePartLabel: sparePart.label,
            currentStandId: sparePart.standId,
          };
        }
      );
    },
    [sparePart.id, sparePart.label, sparePart.standId],
    $
  );

  const sparePartLabel = useMemo(() => {
    return packageDealHelpers.getSparePartDisplayedLabel(sparePart, packageDeal);
  }, [sparePart, packageDeal]);

  const isSparePartReceived = isTruthy(sparePart.dateOfReception);

  return (
    <tr
      style={{
        textDecoration: sparePart.pdStatus === 'canceled' ? 'line-through' : 'none',
      }}
    >
      {showTechnicalId && <td>{sparePart.id}</td>}
      <td>{sparePart.standId}</td>
      <td>{sparePart.translatedCategoryLabel}</td>
      <td>{packageDealHelpers.getPackageDealDisplayedLabel(packageDeal)}</td>
      <td>{sparePartLabel}</td>
      <td className="has-text-right">
        {t('tabs.price', {
          price: sparePart.providerPrice.toFixed(2),
        })}
      </td>
      <td className="has-text-right">
        {t('tabs.price', {
          price: sparePart.price.toFixed(2),
        })}
      </td>
      <td className="has-text-right">
        {Number.isNaN(sparePart.marginPercentage)
          ? '-'
          : `${sparePart.marginPercentage.toFixed(1)}%`}
      </td>
      <td>
        {sparePartHelpers.getProviderDisplayedLabel(
          sparePart.provider,
          sparePart.managementType,
          kanbanCustomerShortName
        )}
      </td>
      <td>{sparePart.comment}</td>
      <td>{sparePart.commentForWorkshop}</td>
      <td>{sparePart.commentForCustomer}</td>
      <td className="has-text-centered">
        {t(`tabs.spareParts.managementType.${sparePart.managementType}`)}
      </td>
      <td className="has-text-centered">
        <SparePartStatusIcon
          status={sparePart.status}
          dateOfOrder={sparePart.dateOfOrder}
          dateOfReception={sparePart.dateOfReception}
          t={t}
        />
      </td>
      {isEditable && (
        <TableActionCell
          onClick={showMoveStandSparePartModalCallback}
          iconId="edit"
          tooltip={
            isSparePartReceived ? t('tabs.spareParts.disabledEditButton') : t('tabs.editButton')
          }
          disabled={isSparePartReceived || sparePart.pdStatus === 'canceled'}
        />
      )}
    </tr>
  );
}

interface Props extends AppProps<Store> {
  readonly packageDeals: readonly PackageDeal[];
  readonly $: StoreStateSelector<Store, KanbanDetailsState>;
  readonly isEditable: boolean;
  readonly kanbanCustomerShortName: string;
}

export function DisplayAllSparePartsComponent({
  packageDeals,
  $,
  $gs,
  isEditable,
  kanbanCustomerShortName,
}: Props): JSX.Element {
  const [t] = useTranslation('details');

  const { $sparePartsTab } = $.$desktopState;
  const { $moveStandSparePartModalState } = $sparePartsTab;

  const expertiseCategoryLabels = useGetAllCarViewCategoryLabels();

  const internalData = useMemo(() => {
    return kanbanToSparePartDetailsInternalDataStructure(packageDeals, expertiseCategoryLabels);
  }, [packageDeals, expertiseCategoryLabels]);

  const sortBy = useGetState($sparePartsTab.$sort.$by);
  const sortDirection = useGetState($sparePartsTab.$sort.$direction);
  const sortedSpareParts = useMemo((): readonly SparePartDetailsInternalDataStructure[] => {
    const sortedData = internalData.slice();
    let sortFunction: (
      c1: SparePartDetailsInternalDataStructure,
      c2: SparePartDetailsInternalDataStructure
    ) => number;
    switch (sortBy) {
      case 'packageDealLabel':
      case 'translatedCategoryLabel':
      case 'carElement':
      case 'label':
      case 'comment':
      case 'standId':
      case 'status':
      case 'provider':
      case 'managementType':
        sortFunction = sortingHelpers.createSortByStringField(sortDirection, sortBy);
        break;
      case 'price':
      case 'providerPrice':
      case 'marginPercentage':
        sortFunction = sortingHelpers.createSortByNumericField(sortDirection, sortBy);
        break;
      default:
        return sortedData;
    }
    return sortedData.sort(sortFunction);
  }, [internalData, sortBy, sortDirection]);

  const submitMoveStandSparePartActionCallback = useActionCallback(
    async ({ getState, kanbanRepository, actionDispatch }) => {
      const { selectedKanban, desktopState } = getState();
      const { sparePartsTab } = desktopState;

      const { formData, sparePartId } = sparePartsTab.moveStandSparePartModalState;
      const kanban = nonnull(selectedKanban);
      const thePackage = kanban.packageDeals
        .filter(nonDeleted)
        .find((pkg) => isTruthy(pkg.spareParts.find(({ id }) => id === nonnull(sparePartId))));
      if (!thePackage) {
        throw new Error(`Unexpected error : unknown package deal for spare part ${sparePartId}`);
      }
      const newKanban = applyPayload(kanban, {
        packageDeals: [
          {
            id: thePackage.id,
            spareParts: [
              {
                id: sparePartId,
                standId: formData.standId,
              },
            ],
          },
        ],
      });
      await kanbanRepository.updateEntity(newKanban);
      actionDispatch
        .scopeProperty('desktopState')
        .scopeProperty('sparePartsTab')
        .scopeProperty('moveStandSparePartModalState')
        .setValue(EMPTY_MOVE_STAND_SPARE_PART_MODAL_STATE);
    },
    [],
    $
  );

  const totalReceivedSparePArtsPrice = useMemo(() => {
    return sortedSpareParts.reduce(
      (acc, sp) => (sp.pdStatus === 'available' && sp.status === 'received' ? acc + sp.price : acc),
      0
    );
  }, [sortedSpareParts]);

  const showTechnicalId = useGetState($sparePartsTab.$showTechnicalId);
  return (
    <>
      <DisplayContentOrPlaceholder
        displayCondition={internalData.length > 0}
        placeholder={t('tabs.spareParts.emptyPlaceholder')}
        isScrollable
      >
        <>
          <Checkbox $={$sparePartsTab.$showTechnicalId} text="ID " />
          <ScrollableTableComponent tableClassName="table is-narrow is-striped is-hoverable is-fullwidth">
            <thead>
              <tr>
                {showTechnicalId && <th>{t('tabs.idTitle')}</th>}
                <TableSortableHeaderComponent
                  content={t('tabs.spareParts.standId')}
                  tooltip={t('tabs.spareParts.standIdTooltip')}
                  centerLabel={false}
                  sortedField="standId"
                  isTruncable
                  $sort={$sparePartsTab.$sort}
                />
                <TableSortableHeaderComponent
                  content={t('tabs.spareParts.category')}
                  centerLabel={false}
                  isTruncable
                  sortedField="translatedCategoryLabel"
                  $sort={$sparePartsTab.$sort}
                />
                <TableSortableHeaderComponent
                  content={t('tabs.spareParts.packageDealLabel')}
                  centerLabel={false}
                  isTruncable
                  sortedField="packageDealLabel"
                  $sort={$sparePartsTab.$sort}
                />
                <TableSortableHeaderComponent
                  content={t('tabs.spareParts.label')}
                  centerLabel={false}
                  sortedField="label"
                  isTruncable
                  $sort={$sparePartsTab.$sort}
                />
                <TableSortableHeaderComponent
                  content={t('tabs.spareParts.providerPrice')}
                  centerLabel={false}
                  className="has-text-right"
                  sortedField="providerPrice"
                  isTruncable
                  $sort={$sparePartsTab.$sort}
                />
                <TableSortableHeaderComponent
                  content={t('tabs.spareParts.price')}
                  centerLabel={false}
                  className="has-text-right"
                  sortedField="price"
                  isTruncable
                  $sort={$sparePartsTab.$sort}
                />
                <TableSortableHeaderComponent
                  content={t('tabs.spareParts.marginPercentage')}
                  centerLabel={false}
                  className="has-text-right"
                  sortedField="marginPercentage"
                  isTruncable
                  $sort={$sparePartsTab.$sort}
                />
                <TableSortableHeaderComponent
                  content={t('tabs.spareParts.provider')}
                  className="has-text-centered"
                  centerLabel={false}
                  isTruncable
                  sortedField="provider"
                  $sort={$sparePartsTab.$sort}
                />
                <TableSortableHeaderComponent
                  content={t('tabs.spareParts.comment')}
                  className="has-text-centered"
                  centerLabel={false}
                  isTruncable
                  sortedField="comment"
                  $sort={$sparePartsTab.$sort}
                />
                <TableSortableHeaderComponent
                  content={t('tabs.spareParts.commentForWorkshop')}
                  className="has-text-centered"
                  centerLabel={false}
                  isTruncable
                  sortedField="commentForWorkshop"
                  $sort={$sparePartsTab.$sort}
                />
                <TableSortableHeaderComponent
                  content={t('tabs.spareParts.commentForCustomer')}
                  className="has-text-centered"
                  centerLabel={false}
                  isTruncable
                  sortedField="commentForCustomer"
                  $sort={$sparePartsTab.$sort}
                />
                <TableSortableHeaderComponent
                  content={t('tabs.spareParts.managementType.headerLabel')}
                  className="has-text-centered"
                  centerLabel={false}
                  isTruncable
                  sortedField="managementType"
                  $sort={$sparePartsTab.$sort}
                />
                <TableSortableHeaderComponent
                  content={t('tabs.spareParts.status')}
                  className="has-text-centered"
                  centerLabel={false}
                  isTruncable
                  sortedField="status"
                  $sort={$sparePartsTab.$sort}
                />
                {isEditable && <TableActionHeaderCell />}
              </tr>
            </thead>
            <tbody>
              {sortedSpareParts.map((sp): JSX.Element => {
                const pkgDeal = sparePartHelpers.getPackageDealThatHasGivenSparePart(
                  packageDeals,
                  sp.id
                );
                return (
                  <SparePartItem
                    key={sp.id}
                    sparePart={sp}
                    packageDeal={nonnull(pkgDeal)}
                    $={$moveStandSparePartModalState}
                    showTechnicalId={showTechnicalId}
                    isEditable={isEditable}
                    t={t}
                    kanbanCustomerShortName={kanbanCustomerShortName}
                  />
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={showTechnicalId ? 6 : 5} className="has-text-right">
                  <strong>{t('tabs.spareParts.totalReceived')}</strong>
                </td>
                <td className="has-text-right">
                  {t('tabs.price', {
                    price: totalReceivedSparePArtsPrice,
                  })}
                </td>
                <td colSpan={isEditable ? 6 : 5} />
              </tr>
            </tfoot>
          </ScrollableTableComponent>
        </>
      </DisplayContentOrPlaceholder>
      <MoveStandSparePartModal
        $={$moveStandSparePartModalState}
        $gs={$gs}
        submitValidDataAction={submitMoveStandSparePartActionCallback}
      />
    </>
  );
}
