import type { Customer } from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { Store, StoreState } from '../typings/store.js';
import { updateAdminCustomersViewStateFromSSEAction } from '../../admin/customer/AdminCustomers.js';

export async function customerRepositoryEventListenerAction(
  { actionDispatch }: ActionContext<Store, StoreState>,
  updatedEntities: readonly Customer[],
  addedEntities: readonly Customer[],
  archivedEntities: readonly string[],
  closedEntities: readonly string[]
) {
  // If at least one entity has been added, reload the entities list
  await actionDispatch
    .scopeProperty('adminView')
    .scopeProperty('adminCustomers')
    .exec(
      updateAdminCustomersViewStateFromSSEAction,
      [...updatedEntities, ...addedEntities],
      [...archivedEntities, ...closedEntities]
    );
}
