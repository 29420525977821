import type { RepositoryEntities, RepositoryEntityPayload, Shutdownable } from '@stimcar/libs-base';
import type {
  DatabaseConfigurator,
  EntityMutationsDAO,
  EntityQueriesDAO,
} from '../dao/typings/repository-dao.js';

export const LAST_KNOWN_SEQUENCE_ID_PROPERTY = <ENAME extends keyof RepositoryEntities>(
  entityName: ENAME
): string => `${entityName}Repository.lastKnownSequenceId`;

export type RepositoryEventListener<ENAME extends keyof RepositoryEntities> = (
  unexpectedServerPushMessageErrors: readonly Error[],
  updatedEntities: readonly RepositoryEntities[ENAME][],
  addedEntities: readonly RepositoryEntities[ENAME][],
  archivedEntityIds: readonly string[],
  closedEntityIds: readonly string[]
) => Promise<void>;

export type RepositoryExtender<ENAME extends keyof RepositoryEntities, T extends object> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly [P in keyof T]: T[P] extends (...args: any) => any
    ? (
        entityQueriesDAO: EntityQueriesDAO<ENAME>,
        entityMutationsDAO: EntityMutationsDAO<ENAME>,
        ...args: Parameters<T[P]>
      ) => ReturnType<T[P]>
    : T[P];
};

export type ExtendedRepository<
  ENAME extends keyof RepositoryEntities,
  I extends object,
> = Repository<ENAME> & I;

export interface Repository<ENAME extends keyof RepositoryEntities> extends Shutdownable {
  /**
   * Wait until the action log is empty or the timeout reached.
   * Throw an error if the action log is not empty at the end of the timedout
   */
  waitForEmptyActionLog: (timeout?: number) => Promise<void>;
  /**
   * Create an ID for repository entities.
   */
  createId(): string;

  /**
   * Tells whether an entity exists or not.
   *
   * @param id the entity identifier.
   * @return true if the entity exists.
   */
  hasEntity(id: string): Promise<boolean>;

  /**
   * Loads en entity.
   *
   * @param id the entity identifier.
   * @return the entity or undefined if it doesn't exist.
   */
  getEntity(id: string): Promise<RepositoryEntities[ENAME]>;

  /**
   * Closes en entity.
   *
   * @param id the entity identifier.
   */
  closeEntity(id: string): Promise<void>;

  /**
   * Archives en entity.
   *
   * @param id the entity identifier.
   */
  archiveEntity(id: string): Promise<void>;

  /**
   * Loads entities with the given IDs.
   *
   * @param ids the entity identifiers.
   * @return the entities.
   */
  getEntities(...ids: string[]): Promise<readonly RepositoryEntities[ENAME][]>;

  /**
   * Loads entities with the given values in the given index.
   *
   * @param index the name of the index to use for the research
   * @param value identifiers for the index
   * @return the entities.
   */
  getEntitiesFromIndex(
    index: string,
    value: string | number | boolean
  ): Promise<readonly RepositoryEntities[ENAME][]>;

  /**
   * Selects the entities that match the given partition key (or all open
   * entities if no partition key is specified).
   * @param partitionKey the partition key (optional).
   */
  getAllEntities(partitionKey?: string): Promise<readonly RepositoryEntities[ENAME][]>;

  /**
   * Selects the archived entities that match the given partition key (or all open
   * entities if no partition key is specified).
   * @param partitionKey the partition key (optional).
   */
  getArchivedEntities(partitionKey?: string): Promise<readonly RepositoryEntities[ENAME][]>;

  /**
   * Handles a create entity action request.
   *
   * @param entity the new entity.
   */
  createEntity(entity: RepositoryEntities[ENAME]): Promise<RepositoryEntities[ENAME]>;

  /**
   * Handles create entity action requests.
   *
   * @param entities the new entities.
   */
  createEntities(
    ...entity: readonly RepositoryEntities[ENAME][]
  ): Promise<readonly RepositoryEntities[ENAME][]>;

  /**
   * Updates a given entity.
   * @param entity the entity to update.
   */
  updateEntity(entity: RepositoryEntities[ENAME]): Promise<void>;

  /**
   * Updates a given entity.
   * @param entities the entities to update.
   */
  updateEntities(...entities: readonly RepositoryEntities[ENAME][]): Promise<void>;

  /**
   * Updates a given entity.
   * @param payload the payload to apply.
   */
  updateEntityFromPayload(
    payload: RepositoryEntityPayload<RepositoryEntities[ENAME], never>
  ): Promise<RepositoryEntities[ENAME]>;

  /**
   * Updates several entities.
   * @param payloads the payloads to apply.
   */
  updateEntitiesFromPayloads(
    ...payloads: RepositoryEntityPayload<RepositoryEntities[ENAME], never>[]
  ): Promise<readonly RepositoryEntities[ENAME][]>;

  /**
   * Defined as an anonymous function in order to be an object's method (instead of
   * being attached to the prototype so that there is no bind to do to let the UI
   * invoke it).
   */
  registerUINotifier: (notifier: RepositoryEventListener<ENAME>) => void;

  /**
   * Returns the local actions change count.
   */
  getLocalChangesCount: () => Promise<number>;

  /**
   * Extends the behavior of the repository with the given RepositoryExtender.
   *
   * @param extender the repository extender
   */
  extend: <E extends object>(
    extender: RepositoryExtender<ENAME, E> & DatabaseConfigurator
  ) => ExtendedRepository<ENAME, E>;
}
