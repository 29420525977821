function getFontAwesomeStyleAndIconFromIconId(iconId: string): { style: string; icon: string } {
  let style = 's';
  let icon = iconId;
  if (iconId.includes('/')) {
    const array = iconId.split('/');
    [style, icon] = array;
  }
  return { style, icon };
}

export const iconHelpers = {
  getFontAwesomeStyleAndIconFromIconId,
};
