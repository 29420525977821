import type { JSX } from 'react';
import React, { useCallback } from 'react';
import { nonnull } from '@stimcar/libs-kernel';
import type { FaIconSize } from '../../bulma/elements/icons/FaIcon.js';
import { convertFaIconSizeToFontSize, FaIcon } from '../../bulma/elements/icons/FaIcon.js';
import { UPLOAD_BUTTON_DEFAULT_ICON_ID } from './utils.js';

export const FILE_ACCEPT =
  'image/*,application/pdf,video/mp4,video/quicktime,text/plain,text/markdown';

export type OnFilesReadyCallback = (files: readonly File[]) => Promise<void>;

export async function loadFile(file: File): Promise<void> {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onloadend = (): void => {
      resolve();
    };
    reader.onerror = (): void => {
      reader.abort();
      reject(new Error(`Failed to load ${file.name}`));
    };
    reader.readAsDataURL(file);
  });
}

interface ImportButtonProps {
  readonly disabled?: boolean;
  readonly additionalClass?: string;
  readonly tooltip?: string;
  readonly label?: string;
  readonly iconId?: string;
  readonly openCameraOnMobile?: boolean;
  readonly allowMultipleImport?: boolean;
  readonly size?: FaIconSize;
  readonly onFilesReadyCallback: OnFilesReadyCallback;
  readonly onButtonClickedCallback?: () => void | Promise<void>;
  readonly accept?: string;
}

export function ImportButton({
  disabled,
  onFilesReadyCallback,
  tooltip,
  label,
  iconId,
  size = 'standard',
  openCameraOnMobile,
  allowMultipleImport = false,
  onButtonClickedCallback,
  additionalClass,
  accept,
}: ImportButtonProps): JSX.Element {
  const onImportAttachmentCallback = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      let files = [...nonnull(nonnull(event.target).files)];
      if (!allowMultipleImport) {
        files = [files[0]];
      }
      await Promise.all(files.map(async (f): Promise<void> => loadFile(f)));
      await onFilesReadyCallback(files);
    },
    [allowMultipleImport, onFilesReadyCallback]
  );

  const buttonSize = size === 'standard' ? undefined : size.replace(/-.*$/g, '');

  return (
    <>
      {!disabled ? (
        <div className={`file${buttonSize ? ` is-${buttonSize}` : ''}`}>
          <label className="file-label">
            <input
              className="file-input"
              type="file"
              value=""
              accept={accept === undefined ? FILE_ACCEPT : accept}
              name="resume"
              onClick={onButtonClickedCallback}
              capture={openCameraOnMobile}
              onChange={onImportAttachmentCallback}
              multiple={allowMultipleImport}
            />
            <span
              id="button"
              className={`button${additionalClass ? ` ${additionalClass}` : ''}${size.startsWith('small') ? ' is-small' : ''}`}
            >
              <span className="file-icon">
                <FaIcon
                  id={iconId || UPLOAD_BUTTON_DEFAULT_ICON_ID}
                  tooltip={tooltip}
                  size={size}
                />
              </span>
              {label && (
                <span className={`file-label is-size-${convertFaIconSizeToFontSize(size)}`}>
                  {label}
                </span>
              )}
            </span>
          </label>
        </div>
      ) : (
        <button
          type="button"
          className={`button ${buttonSize ? ` is-${buttonSize}` : ''}${additionalClass ? ` ${additionalClass}` : ''}${size.startsWith('small') ? ' is-small' : ''}`}
          disabled
        >
          <span className="icon">
            <FaIcon id={iconId || UPLOAD_BUTTON_DEFAULT_ICON_ID} tooltip={label} size={size} />
          </span>
          <span className={`is-size-${convertFaIconSizeToFontSize(size)}`}>{label}</span>
        </button>
      )}
    </>
  );
}
