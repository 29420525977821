import type { FormFieldStoreDef } from '../../../storybook-helpers/formFieldStorybookStoreStates.js';
import type { ReactSelectFormFieldProps } from '../ReactSelectFormField.js';

export const reactSelectSuggestions: ReactSelectFormFieldProps<
  FormFieldStoreDef<string>,
  string
>['suggestions'] = [
  'Stimcar',
  'Cosméticars',
  { value: 'lEmpireDesBots', label: "L'Empire des Bots" },
  'La Boucherie Sansot',
  {
    label: 'Administrations',
    options: [
      {
        label: 'Direction Générale des Impôts',
        value: 'dgfip',
      },
      'Pôle Emploi',
    ],
  },
];
