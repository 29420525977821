import type { JSX } from 'react';
import React, { useMemo } from 'react';
import type { PositionOnCar } from '@stimcar/libs-kernel';
import type { PositionOnPicture, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  MarkerOnPictureComponentWithSelector,
  PictureSelector,
  useActionCallback,
  useGetState,
} from '@stimcar/libs-uikernel';
import type { Store } from '../../../state/typings/store.js';
import { useComputeStandardPictureUrl } from '../../../utils/useComputeAttachmentUrl.js';
import {
  getPictureIconPath,
  getPicturesIdsForSelector,
  getPictureSrcFromPictureIdForSelector,
} from './defectUtils.js';

interface PositionOnCarSelectorProps extends AppProps<Store> {
  readonly $selectedPictureId: StoreStateSelector<Store, string>;
  readonly $positionOnCar: StoreStateSelector<Store, PositionOnCar>;
  readonly $kanbanId: StoreStateSelector<Store, string>;
}

export function PositionOnCarSelector({
  $selectedPictureId,
  $positionOnCar,
  $kanbanId,
  $gs,
}: PositionOnCarSelectorProps): JSX.Element {
  const kanbanId = useGetState($kanbanId);
  const positionOnCar = useGetState($positionOnCar);

  const picturesIds = useMemo(() => getPicturesIdsForSelector(), []);

  const onClickOnPicCallback = useActionCallback(
    ({ actionDispatch }, positionOnPicture: PositionOnPicture) => {
      actionDispatch.setValue(positionOnPicture as PositionOnCar);
    },
    [],
    $positionOnCar
  );

  const computeStandardPictureUrl = useComputeStandardPictureUrl($gs);

  const getPictureSrcFromPictureId = (pictureId: string): string =>
    getPictureSrcFromPictureIdForSelector(kanbanId, pictureId, computeStandardPictureUrl);

  const atLeastOneDefectPresentOnPictureCallback = (selectedPictureId: string) => {
    const hasDefect = positionOnCar.pictureId === selectedPictureId;
    return hasDefect ? 'button-has-defect' : undefined;
  };

  return (
    <PictureSelector
      $selectedPictureId={$selectedPictureId}
      picturesIds={picturesIds}
      getIconSrcFromPictureId={getPictureIconPath}
      getPictureSrcFromPictureId={getPictureSrcFromPictureId}
      onPositionSelectionCallback={onClickOnPicCallback}
      getAdditionalLayerOnButtonClassnameCallback={atLeastOneDefectPresentOnPictureCallback}
    >
      <MarkerOnPictureComponentWithSelector
        positionOnPicture={positionOnCar}
        $selectedPictureId={$selectedPictureId}
      >
        <div className="bullet-marker defect-marker" />
      </MarkerOnPictureComponentWithSelector>
    </PictureSelector>
  );
}
