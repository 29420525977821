import type { JSX } from 'react';
import i18next from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  CarElement,
  PackageDealDesc,
  PackageDealDescWithScoreAndCarElement,
  PackageDealsPredictionsInput,
  PackageDealsPredictionsOutput,
  Prediction,
  SparePartManagementType,
} from '@stimcar/libs-base';
import type { KanbanInfos } from '@stimcar/libs-kernel';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX } from '@stimcar/core-libs-repository';
import { compareNumbers, CoreBackendRoutes, packageDealDescHelpers } from '@stimcar/libs-base';
import { isTruthy, Logger } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ClickableIcon, FasPulseSpinner, ScrollableContainer } from '@stimcar/libs-uitoolkit';
import type { OperatorExpertViewState } from '../../app/operators/typings/store.js';
import type { Store } from '../../app/state/typings/store.js';
import { openDialogForAddition } from '../../app/operators/components/expert/EditPackageDealModalComponent.js';
import './DisplayPredictionsComponent.scss';

const log: Logger = Logger.new(import.meta.url);

const SCROLLABLE_CONTAINER_HEIGHT = '240px';

function getPredictedPackageDealDescs(
  predictions: readonly Prediction[],
  packageDealDatabase: string,
  packageDealsDescs: readonly PackageDealDesc[],
  allCarElements: readonly CarElement[],
  logErrorInMattermost: (message: string) => void
): readonly PackageDealDescWithScoreAndCarElement[] {
  return predictions
    .map(({ packageDealCode, carElementLabel, carElementCategory, score }) => {
      const correspondingPackageDealDesc = packageDealsDescs.find(
        (packageDeal) => packageDeal.code.toLowerCase() === packageDealCode.toLowerCase()
      );

      if (correspondingPackageDealDesc) {
        const correspondingCarElement = allCarElements.find(
          (carElement) =>
            carElement.label === carElementLabel && carElement.category === carElementCategory
        );

        if (correspondingCarElement) {
          if (correspondingPackageDealDesc.carElementIds.includes(correspondingCarElement.id)) {
            return {
              ...correspondingPackageDealDesc,
              score,
              carElement: correspondingCarElement,
            };
          }
          logErrorInMattermost(
            i18next.t(
              'operators:expertiseView.predictions.predictedPackageDealHasNoAssociatedCarElement',
              {
                carElement: `${correspondingCarElement.label} - ${
                  correspondingCarElement.category
                } (${correspondingCarElement.id})`,
                packageDeal: correspondingPackageDealDesc.label,
                database: packageDealDatabase,
              }
            )
          );
        }
      } else {
        logErrorInMattermost(
          i18next.t('operators:expertiseView.predictions.predictedPackageDealNotInDatabase', {
            code: packageDealCode,
            database: packageDealDatabase,
          })
        );
      }

      return null;
    })
    .filter(isTruthy);
}

async function loadPredictionsAction(
  {
    actionDispatch,
    httpClient,
    packageDealDescRepository,
    getState,
    loggerClient,
  }: ActionContext<Store, OperatorExpertViewState>,
  kanbanId: string,
  { brand, model, motor, mileage, dateOfRegistration }: KanbanInfos
): Promise<void> {
  try {
    const response = await httpClient.httpPostAsJSON<
      PackageDealsPredictionsInput,
      PackageDealsPredictionsOutput
    >(CoreBackendRoutes.PREDICT_PACKAGE_DEALS, {
      kanbanId,
      brand,
      model,
      motor,
      mileage,
      dateOfRegistration,
    });

    if (response) {
      const predictions = response.data;

      const { name: company } = httpClient.getBrowserInfos().company;
      const { packageDealDatabase, allCarElements } = getState();
      const packageDealsDescs = await packageDealDescRepository.getEntitiesFromIndex(
        PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX,
        packageDealDatabase
      );

      const logErrorInMattermost = (message: string) => {
        loggerClient.error(
          i18next.t('operators:expertiseView.predictions.errorLogTitle', {
            company,
          }),
          message
        );
      };

      const predictedPackageDealsDescs = getPredictedPackageDealDescs(
        predictions,
        packageDealDatabase,
        packageDealsDescs,
        allCarElements,
        logErrorInMattermost
      );

      const predictedPackageDealDescsSortedByScore = [...predictedPackageDealsDescs].sort((a, b) =>
        compareNumbers(a.score, b.score, 'UP')
      );

      actionDispatch.setProperty('predictionsTabState', {
        isLoading: false,
        predictedPackageDealsDescs: predictedPackageDealDescsSortedByScore,
      });
    }
  } catch (error) {
    log.error(error);
    actionDispatch.setProperty('predictionsTabState', {
      error: true,
      isLoading: false,
      predictedPackageDealsDescs: [],
    });
  }
}

interface DisplayPredictionsComponentProps {
  readonly kanbanId: string;
  readonly isOnline: boolean;
  readonly kanbanInfos: KanbanInfos;
  readonly $: StoreStateSelector<Store, OperatorExpertViewState>;
  readonly sparePartManagementType: SparePartManagementType;
}

export function DisplayPredictionsComponent({
  $,
  kanbanId,
  isOnline,
  kanbanInfos,
  sparePartManagementType,
}: DisplayPredictionsComponentProps): JSX.Element {
  const [t] = useTranslation('operators');

  const loadPredictionsActionCallback = useActionCallback(loadPredictionsAction, [], $);
  const predictedPackageDealsDescs = useGetState(
    $.$predictionsTabState.$predictedPackageDealsDescs
  );

  useEffect(() => {
    if (isOnline && predictedPackageDealsDescs.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loadPredictionsActionCallback(kanbanId, kanbanInfos);
    }
  }, [
    isOnline,
    kanbanId,
    kanbanInfos,
    predictedPackageDealsDescs.length,
    loadPredictionsActionCallback,
  ]);

  const error = useGetState($.$predictionsTabState.$error);
  const isLoading = useGetState($.$predictionsTabState.$isLoading);

  if (!isOnline) {
    return <p className="pl-1">{t('expertiseView.predictions.offline')}</p>;
  }

  if (isLoading) {
    return <FasPulseSpinner label={t('expertiseView.predictions.loadingPredictions')} />;
  }

  if (error) {
    return <p className="pl-1">{t('expertiseView.predictions.error')}</p>;
  }

  if (predictedPackageDealsDescs.length === 0) {
    return <p className="pl-1">{t('expertiseView.predictions.noPredictionsFound')}</p>;
  }

  return (
    <div className="predictions-container">
      <ScrollableContainer height={SCROLLABLE_CONTAINER_HEIGHT} className="p-t-sm">
        <table className="table is-striped is-narrow is-fullwidth">
          <thead>
            <tr>
              <td className="has-text-weight-bold">
                {t('expertiseView.predictions.packageDeals')}
              </td>
              <td className="has-text-weight-bold">{t('expertiseView.predictions.carElements')}</td>
              <td className="has-text-weight-bold">{t('expertiseView.predictions.score')}</td>
              <td aria-label="empty" />
            </tr>
          </thead>
          <tbody>
            {predictedPackageDealsDescs.map((predictedPackageDealDesc) => (
              <PredictionRow
                $={$}
                key={predictedPackageDealDesc.code}
                sparePartManagementType={sparePartManagementType}
                predictedPackageDealDescWithScore={predictedPackageDealDesc}
              />
            ))}
          </tbody>
        </table>
      </ScrollableContainer>
    </div>
  );
}

interface PredictionRowProps {
  readonly sparePartManagementType: SparePartManagementType;
  readonly $: StoreStateSelector<Store, OperatorExpertViewState>;
  readonly predictedPackageDealDescWithScore: PackageDealDescWithScoreAndCarElement;
}

function PredictionRow({
  $,
  sparePartManagementType,
  predictedPackageDealDescWithScore,
}: PredictionRowProps): JSX.Element {
  const scoreIndicatorBackground = useMemo(() => {
    const color = predictedPackageDealDescWithScore.score > 0.5 ? 'green' : 'orange';
    return `linear-gradient(
        to right, 
        ${color} ${Math.ceil(predictedPackageDealDescWithScore.score * 100)}%, 
        orange, 
        transparent, 
        transparent ${Math.floor(1 - predictedPackageDealDescWithScore.score) * 100}%
      )`;
  }, [predictedPackageDealDescWithScore.score]);

  return (
    <tr>
      <td>
        {packageDealDescHelpers.getPackageDealDescLabelWithVariableValuePlaceholder(
          predictedPackageDealDescWithScore
        )}
      </td>
      <td>{predictedPackageDealDescWithScore.carElement.label}</td>
      <td aria-label="score">
        <div className="score-indicator" style={{ background: scoreIndicatorBackground }} />
      </td>
      <td>
        <OpenAddPackageDealDialogButton
          $={$}
          packageDealDescId={predictedPackageDealDescWithScore.id}
          carElement={predictedPackageDealDescWithScore.carElement}
          sparePartManagementType={sparePartManagementType}
          packageDealDescCode={predictedPackageDealDescWithScore.code}
        />
      </td>
    </tr>
  );
}

interface OpenAddPackageDealDialogButtonProps {
  readonly packageDealDescId: string;
  readonly packageDealDescCode: string;
  readonly carElement: CarElement | undefined;
  readonly sparePartManagementType: SparePartManagementType;
  readonly $: StoreStateSelector<Store, OperatorExpertViewState>;
}

function OpenAddPackageDealDialogButton({
  $,
  carElement,
  packageDealDescId,
  packageDealDescCode,
  sparePartManagementType,
}: OpenAddPackageDealDialogButtonProps): JSX.Element {
  const [t] = useTranslation('operators');

  const clickHandler = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(
        openDialogForAddition,
        packageDealDescId,
        sparePartManagementType,
        carElement
      );
    },
    [carElement, packageDealDescId, sparePartManagementType],
    $
  );

  const selectedPackageDeals = useGetState($.$packageDealListComponentState.$packageDeals);

  const isDisabled = useMemo(
    () =>
      isTruthy(
        selectedPackageDeals.find(
          (packageDeal) =>
            packageDeal.code === packageDealDescCode &&
            packageDeal.carElement?.id === carElement?.id
        )
      ),
    [selectedPackageDeals, packageDealDescCode, carElement?.id]
  );

  const tooltip = useMemo(
    () =>
      isDisabled
        ? t('expertiseView.predictions.packageDealAlreadyAdded')
        : t('expertiseView.predictions.addPackageDeal'),
    [isDisabled, t]
  );

  return (
    <ClickableIcon
      id="cart-plus"
      tooltip={tooltip}
      isDisabled={isDisabled}
      clickHandler={clickHandler}
    />
  );
}
