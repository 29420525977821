import type { ActionContext, ActionDispatch } from '@stimcar/libs-uikernel';
import { Logger } from '@stimcar/libs-kernel';
import type { RegisteredAppStore, SessionState } from '../state/typings/store.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

export function deactivateCurrentWindowSessionAction<
  IS_LIGHT_ENVIRONMENT extends boolean,
  SD extends RegisteredAppStore<IS_LIGHT_ENVIRONMENT>,
>({ httpClient, actionDispatch }: ActionContext<SD, SD['globalState']>) {
  log.info('❌ deactivateCurrentSessionAction');
  actionDispatch.scopeProperty('session').setProperty('isActiveWindow', false);
  // To kill a session, we only need to stop the SSE
  httpClient.closeSSE();
}

export async function activateCurrentWindowSessionAction<
  IS_LIGHT_ENVIRONMENT extends boolean,
  SD extends RegisteredAppStore<IS_LIGHT_ENVIRONMENT>,
>({
  httpClient,
  actionDispatch,
  registeredSessionsBroadcastChannel,
}: ActionContext<SD, SD['globalState']>) {
  log.info('✅ activate current session');
  // Notify others that the current session becomes active
  registeredSessionsBroadcastChannel.postMessage('');
  // To re-enable a session, we only need to stop the SSE
  // (after having sent a broadcast message so that other
  // sessions stop themselves)
  httpClient.startSSE();
  // Update authenticated user
  const isBrowserRegistered = httpClient.isBrowserRegistered();
  const infos = isBrowserRegistered ? httpClient.getBrowserInfos() : undefined;
  const authenticatedUser = await httpClient.getAuthenticatedUser();
  (actionDispatch.scopeProperty('session') as ActionDispatch<SD, SessionState>).applyPayload({
    isRegistered: isBrowserRegistered,
    infos: isBrowserRegistered
      ? { ...infos, sessionToken: httpClient.getBrowserSessionToken() }
      : undefined,
    user: authenticatedUser,
    isActiveWindow: true,
  });
}
