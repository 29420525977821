import type { Deletable, Entity } from '@stimcar/libs-kernel';
import {
  asyncTimeout,
  BUILD_VERSION,
  BUILD_VERSION_HEADER,
  isTruthy,
  keysOf,
  Logger,
} from '@stimcar/libs-kernel';
import type { EntityServerActionList } from '../model/typings/action.js';
import type { Ordered } from '../model/typings/general.js';
import type { CoreFields, RepositoryEntity } from '../model/typings/repository.js';
import type { Sequence } from './sequence.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

export async function retry<T>(
  fn: () => Promise<T>,
  timeoutInSeconds: number,
  errorMessage: string,
  startDate: number = Date.now(),
  retryCount: number = 0
): Promise<T> {
  try {
    return await fn();
  } catch (err) {
    const elapsedTimeInSeconds = Math.round((Date.now() - startDate) / 1_000);
    if (elapsedTimeInSeconds >= timeoutInSeconds) {
      throw Error(`${errorMessage} (${err}), timeout`);
    }

    const remainingTimeInSeconds = timeoutInSeconds - elapsedTimeInSeconds;
    log.info(
      `${errorMessage} (attempt #${retryCount}, still trying for ${remainingTimeInSeconds} seconds)`
    );
    await asyncTimeout(1_000);
    return retry(fn, timeoutInSeconds, errorMessage, startDate, retryCount + 1);
  }
}

export function nonDeleted({ deleted }: Deletable): boolean {
  return !deleted;
}

export function ensureNotDeleted<T extends Deletable>(payload: T): T {
  return {
    ...payload,
    ...(payload.deleted ? { deleted: false } : {}),
  };
}

export function removeExtraWhitespaces(input: string): string {
  return input.replace(/\s+/g, ' ').trim();
}

export function convertEntityToCoreFields<E extends RepositoryEntity>(entity: E): CoreFields<E> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { dirty, sequenceId, timestamp, status, ...rest } = entity;
  return rest;
}

export function appendBuildVersion(url: string): string {
  return `${url}${url.includes('?') ? '&' : '?'}${BUILD_VERSION_HEADER}=${BUILD_VERSION}`;
}

export function getKanbanUrl(companyId: string, kanbanId: string): string {
  return `https://${companyId}.stimcar.app/${kanbanId}/details`;
}

export function getMarketplaceUrl(companyId: string, siteId: string, kanbanId: string): string {
  return `https://stimcar.fr/vehicule/${companyId}/${siteId}/${kanbanId}`;
}

export function countActions<E extends RepositoryEntity>(
  actionList: readonly EntityServerActionList<E>[]
): number {
  let count = 0;
  actionList.forEach((h) =>
    h.actions.forEach(() => {
      count += 1;
    })
  );
  return count;
}

export function cloneWithNewIds<T extends Entity>(
  values: readonly T[],
  sequence: Sequence
): readonly T[] {
  return values.map((value) => ({
    ...value,
    id: sequence.next(),
  }));
}

export function enumerate(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  array: readonly any[],
  separator = ', ',
  itemWrapper?: {
    prefix: string;
    suffix: string;
  }
): string {
  return `${(itemWrapper
    ? array.map((a): string => `${itemWrapper.prefix}${a}${itemWrapper.suffix}`)
    : array
  ).join(separator)}`;
}

export function uniqueValues<T>(values: readonly T[]): readonly T[] {
  return [...new Set(values)];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function forEachRecordValues<T, K extends keyof any = string>(
  obj: Record<K, T>,
  callbackfn: (value: T, key: K, index: number) => void
): void {
  keysOf(obj).forEach((key, index) => {
    callbackfn(obj[key], key, index);
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sortRecord<T, K extends keyof any = string>(
  obj: Record<K, T>,
  comparator: (t1: T, t2: T, k1: K, k2: K) => number
): Record<K, T> {
  const keys = keysOf(obj)
    .slice()
    .sort((k1, k2) => {
      return comparator(obj[k1], obj[k2], k1, k2);
    });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newRecord: Record<K, T> = {} as any;
  keys.forEach((k) => {
    newRecord[k] = obj[k];
  });
  return newRecord;
}

export function groupBy<K extends string, T>(
  arr: readonly T[],
  keyGetter: (item: T) => K
): Record<K, readonly T[]> {
  return arr.reduce<Record<K, T[]>>(
    (record, value) => {
      const key = keyGetter(value);
      const groupedValues = record[key] || [];
      groupedValues.push(value);
      return { ...record, [key]: groupedValues };
    },
    {} as Record<K, T[]>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapRecordValues<U, T, K extends keyof any = string>(
  obj: Record<K, T>,
  callbackfn: (value: T, key: K, index: number) => U
): readonly U[] {
  return keysOf(obj).map((key, index): U => {
    return callbackfn(obj[key], key, index);
  });
}

export function arrayToMap<K extends string, T>(
  array: readonly T[],
  keyGetter: (item: T) => K
): Map<K, T> {
  return new Map<K, T>(array.map((element) => [keyGetter(element), element]));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function filterRecordEntries<T, K extends keyof any = string>(
  input: Record<K, T>,
  filterFn: (val: T, key: K) => boolean
): Record<K, T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: Record<K, T> = {} as any;

  keysOf(input).forEach((key) => {
    const val = input[key];
    if (filterFn(val, key)) {
      result[key] = val;
    }
  });
  return result;
}

export function objectListToMap<T extends object, Y = T>(
  key: keyof T | ((obj: T) => string),
  objs: readonly T[],
  convert?: (obj: T) => Y
): Record<string, Y> {
  const getKeyValue = typeof key === 'function' ? key : (obj: T): string => String(obj[key]);
  const result: Record<string, Y> = {};
  objs.forEach((obj) => {
    result[getKeyValue(obj)] = (convert ? convert(obj) : obj) as Y;
  });
  return result;
}

/**
 * Merges a item list by applying updated items and removing items.
 *
 * Updated items and removed items are retrieved by their id attribute.
 */
export function mergeArrayItems<T extends Entity>(
  sourceItems: readonly T[],
  updatedItems: readonly T[],
  removeItemIds: readonly string[]
): readonly T[] {
  const itemsByIdMap = objectListToMap(
    'id',
    sourceItems.filter(({ id }) => !removeItemIds.includes(id))
  );
  // Replace updated items
  updatedItems.forEach((updatedItem) => {
    itemsByIdMap[updatedItem.id] = updatedItem;
  });
  const newItems = keysOf(itemsByIdMap).map((id) => itemsByIdMap[id]);
  return newItems;
}

export function filterReject<T>(
  list: readonly T[],
  filterFn: (t: T) => boolean,
  compareFn?: (a: T, b: T) => number
): { filtered: readonly T[]; rejected: readonly T[] } {
  const filtered: T[] = [];
  const rejected: T[] = [];
  list.forEach((item) => {
    if (filterFn(item)) {
      filtered.push(item);
    } else {
      rejected.push(item);
    }
  });
  return {
    filtered: compareFn ? filtered.sort(compareFn) : filtered,
    rejected: compareFn ? rejected.sort(compareFn) : rejected,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepFreeze(o: any): any {
  Object.freeze(o);
  Object.getOwnPropertyNames(o).forEach((prop) => {
    const propValue = Reflect.get(o, prop);
    if (
      Reflect.has(o, prop) &&
      propValue !== null &&
      (typeof propValue === 'object' || typeof propValue === 'function') &&
      !Object.isFrozen(propValue)
    ) {
      deepFreeze(propValue);
    }
  });
  return o;
}

export function ORDERED_COMPARATOR<T extends Ordered>(t1: T, t2: T): number {
  return t1.orderIndex - t2.orderIndex;
}

export function splitElementsIntoEqualParts<T>(
  elements: readonly T[],
  partsNumber: number
): readonly T[][] {
  const results = [];
  const tmpElements = [...elements];

  const nbElementsInSinglePart = Math.ceil(tmpElements.length / partsNumber);
  while (tmpElements.length) {
    results.push(tmpElements.splice(0, nbElementsInSinglePart));
  }

  return results;
}

export function chunkArrayInPartsOfLength<T>(
  array: readonly T[],
  chunkSize: number
): readonly T[][] {
  const returnValue = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    returnValue.push(array.slice(i, i + chunkSize));
  }
  return returnValue;
}

export function chunkArrayInNParts<T>(array: readonly T[], numberOfChunks: number): readonly T[][] {
  const result: T[][] = [];

  const chunkLength = Math.ceil(array.length / numberOfChunks);

  for (let line = 0; line < numberOfChunks; line += 1) {
    result[line] = [];
    for (let i = 0; i < chunkLength; i += 1) {
      const value = array[i + line * chunkLength];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (isTruthy(value as any)) {
        result[line].push(value);
      }
    }
  }
  return result;
}

const FIRST_FIELDS_TO_STRINGIFY: readonly (string | number | symbol)[] = ['id', 'deleted'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function stringifySorted(value: any, space?: string | number): string {
  return JSON.stringify(
    value,
    (nestedKey, nestedValue) => {
      // Sort arrays by id if possible
      if (Array.isArray(nestedValue)) {
        if (nestedValue.length > 0 && keysOf(nestedValue[0]).includes('id')) {
          return nestedValue.sort((o1, o2) => String(o1.id).localeCompare(String(o2.id)));
        }
        return nestedValue;
      }
      if (nestedValue === undefined) {
        return undefined;
      }
      if (nestedValue === null) {
        return null;
      }
      // Sort fields for objects
      if (typeof nestedValue === 'object') {
        const newNestedValue: Record<string, unknown> = {};
        const nestedValueKeys = keysOf(nestedValue);
        FIRST_FIELDS_TO_STRINGIFY.forEach((key) => {
          if (nestedValueKeys.includes(key)) {
            newNestedValue[String(key)] = nestedValue[key];
          }
        });
        const { filtered: primitiveFields, rejected: otherFields } = filterReject(
          keysOf(nestedValue)
            .filter((key) => !FIRST_FIELDS_TO_STRINGIFY.includes(key))
            .sort(),
          (key): boolean => {
            const fieldValue = nestedValue[key];
            return (
              fieldValue === null ||
              fieldValue === undefined ||
              ['string', 'number', 'boolean'].includes(typeof fieldValue)
            );
          }
        );
        // Serialize primitive values first
        primitiveFields.forEach((key) => {
          const v = nestedValue[key];
          newNestedValue[String(key)] = v === undefined ? '__UNDEFINED__' : v;
        });
        // Separate arrays from objects
        const { filtered: arrayFields, rejected: objectFields } = filterReject(
          otherFields,
          (key): boolean => Array.isArray(nestedValue[key])
        );
        // Serialize object values
        objectFields.forEach((key) => {
          const v = nestedValue[key];
          newNestedValue[String(key)] = v === undefined ? '__UNDEFINED__' : v;
        });
        // Serialize array values at last
        arrayFields.forEach((key) => {
          const v = nestedValue[key];
          newNestedValue[String(key)] = v === undefined ? '__UNDEFINED__' : v;
        });

        return newNestedValue;
      }
      return nestedValue;
    },
    space
  );
}

export function convertUndefinedToNull<T>(
  value: T | undefined
): T extends undefined ? T | null : T {
  if (value === undefined || value === null) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return null as any;
  }
  if (Array.isArray(value)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return value.map((item) => convertUndefinedToNull(item)) as unknown as any;
  }
  if (typeof value === 'object') {
    const newObject = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    keysOf(value as any).forEach((k) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Reflect.set(newObject, k, convertUndefinedToNull(Reflect.get(value as any, k)));
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return newObject as any;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return value as any;
}

const MILLISECONDS_TO_HUMAN_DIVIDERS = {
  ms: 1000, // milliseconds per second
  s: 60, // seconds per minute
  min: 60, // minutes per hour
  h: 24, // hours per day
  d: 365 / 12, // days per month
  mth: 12, // months per year
  yr: Number.MAX_SAFE_INTEGER, // years per... infinity !
};

export function millisecondsToHuman(millis: number): string {
  let value = millis;
  const fragments: string[] = [];
  for (const unit of keysOf(MILLISECONDS_TO_HUMAN_DIVIDERS)) {
    const divider = MILLISECONDS_TO_HUMAN_DIVIDERS[unit];
    const nextValue = Math.floor(value / divider);
    const rest = value - nextValue * divider;
    fragments.push(`${rest.toFixed(0)}${unit}`);
    if (nextValue === 0) {
      return fragments.slice(-2).reverse().join(' ');
    }
    value /= divider;
  }
  throw Error('not possible');
}

export function isSameUnorderedStringArray(
  array1: readonly string[],
  array2: readonly string[]
): boolean {
  // Check arrays length
  if (array1.length !== array2.length) {
    return false;
  }
  // Check that array1 has no duplicate key
  const duplicateKeys = array1.filter((item, index) => array1.indexOf(item, index + 1) > 0);
  if (duplicateKeys.length > 0) {
    throw new Error(
      `Duplicate keys in ${JSON.stringify(array1)}: ${JSON.stringify(duplicateKeys)}}`
    );
  }
  // Check that all keys in array 1 are matched in array 2
  const unmatchedItems = array1.filter((item) => !array2.includes(item));
  return unmatchedItems.length === 0;
}

export function dedupUnorderedStringArray(array: readonly string[]): readonly string[] {
  return [...new Set(array).values()].sort();
}
