export const SUBCONTRACTOR_LOGIN_FULL_PATH = `login`;

export const SUBCONTRACTOR_SELECT_KANBAN_FULL_PATH = `select`;
export const computeSubcontractorOperateKanbanPath = (
  kanbanId: string,
  standId: string,
  implantationId?: string,
  implantationCategory?: string,
  postName?: string
): string => {
  let path = `/operate/${kanbanId}/${standId}`;
  if (implantationId && implantationCategory && postName) {
    path += `?implantationId=${implantationId}&categoryId=${implantationCategory}&postName=${postName}`;
  }
  return path;
};
export const SUBCONTRACTOR_OPERATE_KANBAN_FULL_PATH = computeSubcontractorOperateKanbanPath(
  ':id',
  ':standId'
);
