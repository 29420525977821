import type {
  CarElementRepository,
  CustomerRepository,
  DatabaseFactory,
  Environment,
  EnvironmentResetListener,
  KanbanRepository,
  LightEnvironment,
  MattermostLogger,
  PackageDealDescRepository,
  RepositoryHTTPClient,
} from '@stimcar/core-libs-repository';
import type { MetricsRegistry } from '@stimcar/libs-base';
import type { KeyValueStorage } from '@stimcar/libs-kernel';
import { newEnvironment } from '@stimcar/core-libs-repository';
import { NullMetricsRegistry } from '@stimcar/libs-base';
import { BrowserKeyValueStorageImpl } from '@stimcar/libs-uikernel';
import { BrowserEventSourceFactory, BrowserFormDataFactory } from '@stimcar/libs-uitoolkit';
import { IndexedDbDatabaseFactoryImpl } from './IndexedDbDatabaseImpl.js';

export interface LightBrowserEnvironment extends LightEnvironment {
  readonly getRegisteredSessionsBroadcastChannel: () => BroadcastChannel;
}

export interface BrowserEnvironment extends LightBrowserEnvironment, Environment {}

class BrowserEnvironmentImpl<IS_LIGHT_ENVIRONMENT extends boolean> implements BrowserEnvironment {
  private wrapped: IS_LIGHT_ENVIRONMENT extends false ? Environment : LightEnvironment;

  private lightMode: IS_LIGHT_ENVIRONMENT;

  private registeredSessionBroadcastChannel: BroadcastChannel | undefined;

  constructor(
    localStorageKeysToKeepDuringSoftReset: readonly string[],
    lightMode: IS_LIGHT_ENVIRONMENT
  ) {
    this.wrapped = newEnvironment(
      new BrowserKeyValueStorageImpl(),
      new IndexedDbDatabaseFactoryImpl(),
      fetch.bind(window),
      BrowserFormDataFactory,
      BrowserEventSourceFactory,
      new NullMetricsRegistry(),
      localStorageKeysToKeepDuringSoftReset,
      undefined,
      undefined,
      undefined,
      undefined,
      lightMode,
      false
    );
    this.lightMode = lightMode;
  }

  public getRegisteredSessionsBroadcastChannel(): BroadcastChannel {
    if (this.registeredSessionBroadcastChannel === undefined) {
      this.registeredSessionBroadcastChannel = new BroadcastChannel(
        'RegisteredSessionsBroadcastChannel'
      );
    }
    return this.registeredSessionBroadcastChannel;
  }

  private closeRegisteredSessionsBroadcastChannel(): void {
    if (this.registeredSessionBroadcastChannel !== undefined) {
      this.registeredSessionBroadcastChannel.close();
      this.registeredSessionBroadcastChannel = undefined;
    }
  }

  public getKeyValueStorage(): KeyValueStorage {
    return this.wrapped.getKeyValueStorage();
  }

  public getDatabaseFactory(): DatabaseFactory {
    return this.wrapped.getDatabaseFactory();
  }

  public getHttpClient(): RepositoryHTTPClient {
    return this.wrapped.getHttpClient();
  }

  public getLoggerClient(): MattermostLogger {
    return this.wrapped.getLoggerClient();
  }

  public async getKanbanRepository(): Promise<KanbanRepository> {
    return this.wrapped.getKanbanRepository();
  }

  public async getPackageDealDescRepository(): Promise<PackageDealDescRepository> {
    if (!this.lightMode) {
      return (this.wrapped as Environment).getPackageDealDescRepository();
    }
    throw new Error('getPackageDealDescRepository is not expected to be called in light mode');
  }

  public async getCarElementRepository(): Promise<CarElementRepository> {
    if (!this.lightMode) {
      return (this.wrapped as Environment).getCarElementRepository();
    }
    throw new Error('getCarElementRepository is not expected to be called in light mode');
  }

  public async getCustomerRepository(): Promise<CustomerRepository> {
    if (!this.lightMode) {
      return (this.wrapped as Environment).getCustomerRepository();
    }
    throw new Error('getCustomerRepository is not expected to be called in light mode');
  }

  public getMetricsRegistry(): MetricsRegistry {
    return this.wrapped.getMetricsRegistry();
  }

  public async reset(hard?: boolean): Promise<void> {
    this.closeRegisteredSessionsBroadcastChannel();
    return this.wrapped.reset(hard);
  }

  public registerResetListener(listener: EnvironmentResetListener): void {
    return this.wrapped.registerResetListener(listener);
  }

  public async shutdown(timeout?: number): Promise<void> {
    this.closeRegisteredSessionsBroadcastChannel();
    return this.wrapped.shutdown(timeout);
  }

  public isShutdown(): boolean {
    return this.wrapped.isShutdown();
  }
}

export function newBrowserEnvironment<IS_LIGHT_ENVIRONMENT extends boolean>(
  localStorageKeysToKeepDuringSoftReset: readonly string[],
  lightMode: IS_LIGHT_ENVIRONMENT
): IS_LIGHT_ENVIRONMENT extends false ? BrowserEnvironment : LightBrowserEnvironment {
  return new BrowserEnvironmentImpl<IS_LIGHT_ENVIRONMENT>(
    localStorageKeysToKeepDuringSoftReset,
    lightMode
  );
}
