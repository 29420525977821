import type { JSX } from 'react';
import React, { useEffect } from 'react';
import type { AnyStoreDef, State, StoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { Meta, StoryObj } from '@storybook/react';
import { createDispatchableStore } from '@stimcar/libs-uikernel';
import { useArgs } from '@storybook/preview-api';

export interface StoreConnector<TCmpOrArgs, SD extends AnyStoreDef> {
  readonly connectArgs: (input: Meta<TCmpOrArgs>['args'] | undefined) => Meta<TCmpOrArgs>['args'];
  readonly connectArgTypes: (
    input: Meta<TCmpOrArgs>['argTypes'] | undefined
  ) => Meta<TCmpOrArgs>['argTypes'];
  readonly connectStory: (input: StoryObj<TCmpOrArgs>) => StoryObj<TCmpOrArgs>;
  readonly $: StoreStateSelector<SD, SD['globalState']>;
}

interface MetaArgsWithStoreState<SD extends AnyStoreDef> {
  readonly $: StoreStateSelector<SD, SD['globalState']>;
  readonly state: SD['globalState'];
}

export function createStorybookStore<TCmpOrArgs, GS extends State, C extends object = object>(
  initialState: GS,
  actionContext?: Partial<C>
): StoreConnector<TCmpOrArgs, StoreDef<GS, C>> {
  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    globalDispatch, // the global dispatch
    $, // the root state selector
    actionContextSetter, // the action context setter
    stateChangeListenerRegisterer, // the state change listener registerer
  ] = createDispatchableStore<StoreDef<GS, C>>(initialState);
  // If provided, set the action context
  if (actionContext) {
    actionContextSetter(actionContext);
  }
  function connectArgs(input: Meta<TCmpOrArgs>['args'] | undefined): Meta<TCmpOrArgs>['args'] {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return { ...(input === undefined ? {} : input), $, state: initialState } as any;
  }

  function connectArgTypes(
    input: Meta<TCmpOrArgs>['argTypes'] | undefined
  ): Meta<TCmpOrArgs>['argTypes'] {
    return {
      ...(input === undefined ? {} : input),
      $: {
        table: {
          // Hide $ field from storybook controls panel
          disable: true,
        },
      },
    } as any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }

  const ConnectStoreStateDecorator = (Story: React.ComponentType): JSX.Element => {
    const [args, updateArgs] = useArgs<MetaArgsWithStoreState<StoreDef<GS, C>>>();
    useEffect(() => {
      // Listen to store state changes to propagate them to storybook args
      stateChangeListenerRegisterer((newState) => {
        updateArgs({ state: newState });
      });
    }, [updateArgs]);

    useEffect(() => {
      // Listen to storybook args changes to propagate them to store state
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      globalDispatch.setValue(args.state);
    }, [args.state]);

    // Drop selector and state fields
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return <Story />;
  };

  function connectStory<TCmpOrArgs>(input: StoryObj<TCmpOrArgs>): StoryObj<TCmpOrArgs> {
    // No input decorators
    if (input.decorators === undefined) {
      return {
        ...input,
        decorators: [ConnectStoreStateDecorator],
      };
    }
    // Input decorators as array
    if (Array.isArray(input.decorators)) {
      return {
        ...input,
        decorators: [...input.decorators, ConnectStoreStateDecorator],
      };
    }
    // Input decorators as single decorator
    return { ...input, decorators: [input.decorators, ConnectStoreStateDecorator] };
  }

  const storeConnector: StoreConnector<TCmpOrArgs, StoreDef<GS, C>> = {
    connectArgs,
    connectArgTypes,
    connectStory,
    $,
  };
  return storeConnector;
}
