import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { CoreFrontendRoutes } from '../model/index.js';

const URL_PARAMETER_NOT_RESTRICTED_CHARACTERS = /[0-9a-z_~.-]*/gi;

const getURLParametersRestrictedCharacters = (value: string): string[] => {
  const uniqueRestrictedChar = new Set(value.replace(URL_PARAMETER_NOT_RESTRICTED_CHARACTERS, ''));
  return [...uniqueRestrictedChar];
};

const containsInvalidURLCharacters = (value: string): boolean => {
  return getURLParametersRestrictedCharacters(value).length !== 0;
};

const computeShareUrl = (
  baseUrl: string | undefined,
  shareId: string,
  defaultTab?: string
): string => {
  if (!isTruthyAndNotEmpty(baseUrl)) {
    throw Error('No site URL is provided, the shared link cannot be computed');
  }
  return baseUrl + CoreFrontendRoutes.SHARE(shareId, defaultTab);
};

export const urlHelpers = {
  /**
   * Only alphanumeric, '-' and '_' are authorized in url paramters.
   * Other character may have specific meanings (i.e. '&').
   * @returns the list of restricted characters (as string array since TS does not have a type for Char)
   */
  getURLParametersRestrictedCharacters,
  containsInvalidURLCharacters,
  computeShareUrl,
};
