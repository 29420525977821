import { useMemo } from 'react';

export function useSparePartEstimatedDateOfReceptionColorClass(
  estimatedDateOfReception: number | null
) {
  const estimatedDateOfReceptionColorClass = useMemo(() => {
    if (estimatedDateOfReception !== null) {
      if (estimatedDateOfReception === -1) {
        // No delay
        return 'has-background-anomaly';
      }
      const now = Date.now();
      if (estimatedDateOfReception <= now) {
        return (now - estimatedDateOfReception) / 1000 / 3600 / 24 < 1
          ? 'has-background-orange-light'
          : 'has-background-red-light';
      }
    }
    return undefined;
  }, [estimatedDateOfReception]);

  return estimatedDateOfReceptionColorClass;
}
