import type { TFunction } from 'i18next';
import { useMemo } from 'react';

export function useSearchArchivesWarning(
  t: TFunction,
  searchText: string,
  isOnline: boolean
): string | undefined {
  const warning = useMemo((): string | undefined => {
    const textLength = searchText.trim().length;
    if (textLength > 0 && textLength < 2) {
      return t('atLeast2Chars');
    }
    if (!isOnline) {
      return t('offlineWarning');
    }
    return undefined;
  }, [isOnline, searchText, t]);
  return warning;
}
