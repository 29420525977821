import type { KeyValueDictionnary } from '@stimcar/libs-base';
import { InMemoryKeyValueStorageImpl } from '@stimcar/libs-base';
import { Logger } from '@stimcar/libs-kernel';
import type { Filesystem } from './typings/fs.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

export class StandaloneKeyValueStorageImpl extends InMemoryKeyValueStorageImpl<
  [Filesystem, string]
> {
  private fsImpl: Filesystem;

  private storagePath: string;

  public constructor(fsImpl: Filesystem, storagePath: string) {
    super(fsImpl, storagePath);
    this.fsImpl = fsImpl;
    this.storagePath = storagePath;
  }

  protected persistDictionnary(dictionnary: KeyValueDictionnary): void {
    const asJson = JSON.stringify(dictionnary, null, 2);
    this.fsImpl.writeFileSync(this.storagePath, asJson);
  }

  protected loadDictionnary(fsImpl: Filesystem, storagePath: string): KeyValueDictionnary {
    // Check that the folder exists
    if (!fsImpl.existsSync(storagePath)) {
      return {};
    }
    if (!fsImpl.lstatSync(storagePath).isFile()) {
      throw new Error(`Standalone storage path '${storagePath}' is not a file`);
    } else {
      // Load configuration if it exists
      const contents = fsImpl.readFileSync(storagePath, 'utf8');
      return JSON.parse(contents);
    }
  }
}
