import { useMemo } from 'react';
import type { GlobalStoreStateSelector } from '@stimcar/libs-uikernel';
import { Role } from '@stimcar/libs-base';
import { useGetState } from '@stimcar/libs-uikernel';
import type { LightRegisteredAppStore } from '../../../state/typings/store.js';

export function useStandIds<SD extends LightRegisteredAppStore>(
  $gs: GlobalStoreStateSelector<SD>,
  role: string
): string[] {
  const stands = useGetState($gs.$siteConfiguration.$stands);
  return useMemo((): string[] => {
    if (stands && role === Role.Operator) {
      return stands.map(({ id }): string => id);
    }
    return [];
  }, [stands, role]);
}
