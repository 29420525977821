import type { JSX } from 'react';
import { type TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { CoreBackendRoutes } from '@stimcar/libs-base';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { Checkbox } from '../../../lib/bulma/form/Checkbox.js';
import type { InvoiceRefundModalState } from './typings/store.js';
import { extractMessageFromError } from './invoicingUtils.js';
import { INVOICE_REFUND_MODAL_EMPTY_STATE } from './typings/store.js';

export function openRefundInvoiceModalAction(
  { actionDispatch }: ActionContext<Store, InvoiceRefundModalState>,
  invoiceId: number,
  purchaseOrderId: string,
  reference: string
): void {
  actionDispatch.setValue({
    ...INVOICE_REFUND_MODAL_EMPTY_STATE,
    invoiceId: String(invoiceId),
    purchaseOrderId,
    invoiceReference: reference,
    generateNewInvoice: true,
    isActive: true,
  });
}

async function refundInvoiceAction(
  {
    httpClient,
    getState,
    actionDispatch,
    globalActionDispatch,
  }: ActionContext<Store, InvoiceRefundModalState>,
  kanbanId: string,
  t: TFunction
): Promise<void> {
  const { invoiceId, purchaseOrderId, generateNewInvoice } = getState();
  try {
    await httpClient.httpPostAsJSON(CoreBackendRoutes.REFUND_INVOICE, {
      invoiceId,
      purchaseOrderId,
      kanbanId,
      generateNewInvoice,
    });
  } catch (e) {
    // Close dialog
    actionDispatch.setValue({ ...INVOICE_REFUND_MODAL_EMPTY_STATE });
    // and display error in message dialog
    globalActionDispatch.scopeProperty('message').setValue({
      type: 'warning',
      title: t('tabs.invoice.errorTitle'),
      content: extractMessageFromError(e as Error),
    });
    return;
  }
  actionDispatch.setValue({ ...INVOICE_REFUND_MODAL_EMPTY_STATE });
}

type RefundInvoiceModalProps = {
  $: StoreStateSelector<Store, InvoiceRefundModalState>;
  kanbanId: string;
};

export function RefundInvoiceModal({ $, kanbanId }: RefundInvoiceModalProps): JSX.Element {
  const [t] = useTranslation('details');

  const refundInvoiceActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(refundInvoiceAction, kanbanId, t);
    },
    [kanbanId, t],
    $
  );

  const invoiceReferenceToBeRefunded = useGetState($.$invoiceReference);

  return (
    <ModalCardDialog
      title={t('tabs.invoice.refundInvoice.title')}
      $active={$.$isActive}
      okLabel={t('tabs.invoice.refundInvoice.okButtonLabel')}
      onOkClicked={refundInvoiceActionCallback}
    >
      <p>
        {t('tabs.invoice.refundInvoice.confirm', {
          invoiceReference: invoiceReferenceToBeRefunded,
        })}
      </p>
      <Checkbox
        $={$.$generateNewInvoice}
        text={t('tabs.invoice.refundInvoice.generateNewInvoice')}
      />
    </ModalCardDialog>
  );
}
