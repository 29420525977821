import type { JSX } from 'react';
import React, { useMemo } from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { CheckboxProps } from './Checkbox.js';
import { Label } from './Label.js';

export interface SwitchActionProps extends Omit<SwitchProps<AnyStoreDef>, '$'> {
  readonly isChecked: boolean;
  readonly toggleAction: (e: React.ChangeEvent<HTMLInputElement>) => void | Promise<void>;
}

export function RawSwitch({
  text,
  disabled = false,
  isChecked,
  toggleAction,
  tooltip,
  switchId,
  className,
  style,
}: SwitchActionProps): JSX.Element {
  const theStyle = useMemo(() => ({ display: 'none', ...style }), [style]);
  return (
    <>
      <input
        disabled={disabled !== undefined ? disabled : false}
        type="checkbox"
        id={switchId}
        className={`m-r-sm switch${className ? ` ${className}` : ''}`}
        checked={isChecked}
        onChange={toggleAction}
        title={tooltip}
        style={theStyle}
      />
      <Label htmlFor={switchId} tooltip={tooltip} disabled={disabled} className="">
        {text}
      </Label>
    </>
  );
}

export interface SwitchProps<SD extends AnyStoreDef> extends CheckboxProps<SD> {
  readonly switchId: string;
}

export function Switch<SD extends AnyStoreDef>({
  text,
  disabled = false,
  $,
  tooltip,
  switchId,
  className,
  style,
}: SwitchProps<SD>): JSX.Element {
  const isChecked = useGetState($);
  const onClickActionCallback = useActionCallback(
    function onClickAction({ actionDispatch }, e: React.ChangeEvent<HTMLInputElement>) {
      actionDispatch.setValue(e.target.checked);
    },
    [],
    $
  );
  return (
    <RawSwitch
      disabled={disabled}
      switchId={switchId}
      className={className}
      tooltip={tooltip}
      text={text}
      isChecked={isChecked}
      style={style}
      toggleAction={onClickActionCallback}
    />
  );
}
