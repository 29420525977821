import type { JSX } from 'react';
import React, { useMemo } from 'react';
import type { AnyStoreDef, NoArgActionCallback, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { DeleteClickableIcon } from '../elements/icons/DeleteClickableIcon.js';
import { FaIcon } from '../elements/icons/FaIcon.js';
import { FormButtons } from '../form/FormButtons.js';
import type { BaseModalDialogProps } from './ModalDialog.js';
import { ModalDialogWithoutDispatch } from './ModalDialog.js';

export interface BaseModalCardDialogProps extends BaseModalDialogProps {
  readonly title: string;
  readonly titleIconId?: string;
  readonly isReadonly?: boolean;
  readonly okLabel?: string;
  readonly cancelLabel?: string;
  readonly warning?: string;
  readonly preventModalBodyScrolling?: boolean;
  readonly noContentTag?: boolean;
  readonly dontShowCancelButton?: boolean;
}

export interface ModalCardDialogProps<SD extends AnyStoreDef> extends BaseModalCardDialogProps {
  readonly $active: StoreStateSelector<SD, boolean>;
  readonly onOkClicked?: NoArgActionCallback<SD>;
  readonly onCancelClicked?: NoArgActionCallback<SD>;
}

export function ModalCardDialog<SD extends AnyStoreDef>({
  $active,
  onOkClicked,
  onCancelClicked,
  dontShowCancelButton,
  children,
  ...props
}: ModalCardDialogProps<SD>): JSX.Element {
  const showCancelButton = onOkClicked && !dontShowCancelButton;

  const closeModalHandlerActionCallback = useActionCallback(
    async function closeModalHandlerAction({
      globalActionDispatch,
      actionDispatch,
    }): Promise<void> {
      if (showCancelButton && onCancelClicked) {
        await globalActionDispatch.execCallback(onCancelClicked);
      }
      actionDispatch.setValue(false);
    },
    [onCancelClicked, showCancelButton],
    $active
  );

  const okClickHandlerActionCallback = useActionCallback(
    async function okClickHandlerAction({ globalActionDispatch, actionDispatch }): Promise<void> {
      if (onOkClicked) {
        await globalActionDispatch.execCallback(onOkClicked);
      } else {
        actionDispatch.setValue(false);
      }
    },
    [onOkClicked],
    $active
  );

  const active = useGetState($active);

  return (
    <>
      <ModalCardDialogWithoutDispatch
        isActive={active !== false && active !== undefined && active !== null}
        onOkClicked={okClickHandlerActionCallback}
        onCancelClicked={closeModalHandlerActionCallback}
        dontShowCancelButton={dontShowCancelButton}
        {...props}
      >
        {children}
      </ModalCardDialogWithoutDispatch>
    </>
  );
}

interface ModalCardDialogWithoutDispatchProps extends BaseModalCardDialogProps {
  readonly isActive: boolean;
  readonly onOkClicked: () => Promise<void>;
  readonly onCancelClicked: () => Promise<void>;
}

export function ModalCardDialogWithoutDispatch({
  isActive,
  title,
  titleIconId,
  onOkClicked,
  onCancelClicked,
  okLabel,
  cancelLabel,
  warning,
  children,
  isReadonly = false,
  zIndex,
  preventModalBodyScrolling = false,
  size,
  noContentTag = false,
  dontShowCancelButton = false,
}: ModalCardDialogWithoutDispatchProps): JSX.Element {
  const closeHandler = useMemo(() => {
    if (onCancelClicked) {
      return onCancelClicked;
    }
    return onOkClicked;
  }, [onCancelClicked, onOkClicked]);

  return (
    <ModalDialogWithoutDispatch
      isActive={isActive}
      closeHandler={closeHandler}
      zIndex={zIndex}
      size={size}
    >
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title" style={{ marginBottom: 0 }}>
            {titleIconId ? <FaIcon id={titleIconId} tooltip={title} /> : ''}
            &nbsp;&nbsp;
            {title}
          </p>
          <DeleteClickableIcon handler={closeHandler} />
        </header>
        <section
          className="modal-card-body"
          style={preventModalBodyScrolling ? { overflow: 'hidden' } : {}}
        >
          {noContentTag ? children : <div className="content">{children}</div>}
        </section>
        <footer className="modal-card-foot">
          <FormButtons
            onSubmitButtonClicked={onOkClicked}
            submitLabel={okLabel}
            onCancelButtonClicked={!dontShowCancelButton ? closeHandler : undefined}
            cancelLabel={cancelLabel}
            formWarning={warning}
            isReadonly={isReadonly}
          />
        </footer>
      </div>
    </ModalDialogWithoutDispatch>
  );
}
