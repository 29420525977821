import type { CarElement } from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { Store, StoreState } from '../typings/store.js';
import { updateAdminCarElementsStateFromSSEAction } from '../../admin/carElement/AdminCarElements.js';
import { updateCarElementInSortDialogStateFromSSEAction } from '../../admin/carElement/SortCarElementsDialog.js';
import { updateCarElementsInAdminPackageDealDescViewStateFromSSEAction } from '../../admin/packageDealDesc/AdminPackageDealDescs.js';

export async function carElementRepositoryEventListenerAction(
  { actionDispatch }: ActionContext<Store, StoreState>,
  updatedEntities: readonly CarElement[],
  addedEntities: readonly CarElement[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  archivedEntities: readonly string[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  closedEntities: readonly string[]
) {
  await actionDispatch
    .scopeProperty('adminView')
    .scopeProperty('adminCarElements')
    .exec(
      updateAdminCarElementsStateFromSSEAction,
      [...updatedEntities, ...addedEntities],
      [...archivedEntities, ...closedEntities]
    );

  await actionDispatch
    .scopeProperty('adminView')
    .scopeProperty('adminCarElements')
    .scopeProperty('sortCarElementDialog')
    .exec(
      updateCarElementInSortDialogStateFromSSEAction,
      [...updatedEntities, ...addedEntities],
      [...archivedEntities, ...closedEntities]
    );
  // Update car elements in admin package deals descs
  await actionDispatch
    .scopeProperty('adminView')
    .scopeProperty('adminPackageDealDescs')
    .exec(
      updateCarElementsInAdminPackageDealDescViewStateFromSSEAction,
      [...updatedEntities, ...addedEntities],
      [...archivedEntities, ...closedEntities]
    );
}
