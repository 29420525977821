import { useCallback } from 'react';
import type { StorageCategories } from '@stimcar/libs-base';
import type { GlobalStoreStateSelector } from '@stimcar/libs-uikernel';
import { SubcontractorBackendRoutes } from '@stimcar/libs-base';
import { useGetState } from '@stimcar/libs-uikernel';
import type {
  ComputeAttachmentUrlCallback,
  ThumbnailOptions,
} from '../../lib/components/attachments/typings/attachment.js';
import type { SubcontractorStore } from '../state/typings/store.js';

export function useComputeSubcontractorAttachmentUrl(
  $gs: GlobalStoreStateSelector<SubcontractorStore>
): ComputeAttachmentUrlCallback {
  const selectedSite = useGetState($gs.$session.$selectedSite);
  return useCallback(
    (
      category: StorageCategories,
      folder: string,
      name: string,
      objectId: string,
      thumbnailOptions?: ThumbnailOptions
    ): string => {
      const baseUrl = thumbnailOptions
        ? SubcontractorBackendRoutes.SUBCONTRACTOR_ATTACHMENT_THUMBNAIL(
            selectedSite,
            category,
            objectId,
            folder,
            thumbnailOptions.mode,
            thumbnailOptions.size,
            name
          )
        : SubcontractorBackendRoutes.SUBCONTRACTOR_ATTACHMENT(
            selectedSite,
            category,
            objectId,
            folder,
            name
          );
      return baseUrl;
    },
    [selectedSite]
  );
}
