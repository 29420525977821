import type { JSX } from 'react';
import React from 'react';
import type { DefectHighlighter } from '@stimcar/libs-kernel';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { ConfirmAttachmentDialogState } from '../../../../../lib/components/attachments/typings/store.js';
import type { PictureInputState } from '../../../../../lib/components/typings/store.js';
import type { Store } from '../../../../state/typings/store.js';
import type { HighlightDefectDialogState } from '../../../typings/store.js';
import { PictureInput } from '../../../../../lib/components/pictureInput/PictureInput.js';
import { EMPTY_DEFECT_HIGHLIGHTER } from '../../../typings/store.js';
import { getDefectHighlighterStyle, isValidDefectHighlighter } from '../defectUtils.js';
import { openHightlightDefectModalAction } from './HighlightDefectModal.js';

interface DefectPictureSelectorProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, PictureInputState>;
  readonly $kanbanId: StoreStateSelector<Store, string>;
  readonly placeholderPicturePath: string;
  readonly $confirmAttachmentRemovalDialog: StoreStateSelector<Store, ConfirmAttachmentDialogState>;
  readonly isEditable?: boolean;
  readonly $defectHighlighter: StoreStateSelector<Store, DefectHighlighter>;
  readonly $highlightDefectDialogState: StoreStateSelector<Store, HighlightDefectDialogState>;
}

export function DefectPictureSelector({
  $,
  $kanbanId,
  placeholderPicturePath,
  $confirmAttachmentRemovalDialog,
  isEditable = true,
  $defectHighlighter,
  $highlightDefectDialogState,
  $gs,
}: DefectPictureSelectorProps): JSX.Element {
  const kanbanId = useGetState($kanbanId);
  const filename = useGetState($.$id);
  const initialDefectHiglighter = useGetState($defectHighlighter) ?? EMPTY_DEFECT_HIGHLIGHTER;

  const openHightlightDefectModalActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openHightlightDefectModalAction, initialDefectHiglighter);
    },
    [initialDefectHiglighter],
    $highlightDefectDialogState
  );

  return (
    <div className="cell is-col-span-3 is-relative">
      <PictureInput
        $gs={$gs}
        folder={MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID}
        kanbanId={kanbanId}
        filename={filename}
        isEditable={isEditable}
        placeholderPicturePath={placeholderPicturePath}
        $={$}
        $confirmAttachmentRemovalDialog={$confirmAttachmentRemovalDialog}
        notSetPictureAdditionalLayer="img/standard-pictures/set-picture-layer.webp"
        overridingOnClickCallback={openHightlightDefectModalActionCallback}
      />
      {isTruthy(initialDefectHiglighter) && isValidDefectHighlighter(initialDefectHiglighter) && (
        <span
          className="defect-highlighter"
          style={getDefectHighlighterStyle(initialDefectHiglighter)}
        />
      )}
    </div>
  );
}
