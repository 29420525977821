import { DeepPartial } from './typings/typescript.js';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isDefined(value: any): boolean {
  return value !== undefined && value !== null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isPrimitiveArray(
  array: readonly any[],
  arrayName?: string | number | symbol
): boolean | undefined {
  if (array === undefined) {
    return undefined;
  }
  let primitiveTypesCount = 0;
  let objectTypeCount = 0;
  array.forEach(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (element: any): void => {
      switch (typeof element) {
        case 'boolean':
        case 'number':
        case 'bigint':
        case 'string':
        case 'undefined':
          primitiveTypesCount += 1;
          break;
        case 'object':
          // Nested arrays are considered as primitive
          if (Array.isArray(element)) {
            primitiveTypesCount += 1;
          } else {
            objectTypeCount += 1;
          }
          break;
        case 'symbol':
        case 'function':
        default:
      }
      // As soon as we see that there is a mix, we raise an error
      if (primitiveTypesCount > 0 && objectTypeCount > 0) {
        throw Error(
          `Unexpected array type ${
            arrayName ? `in '${String(arrayName)}'` : ''
          }: ${typeof element} (mixes primitive and object types which is not supported)`
        );
      }
    }
  );
  if (primitiveTypesCount > 0 && objectTypeCount > 0) {
    throw Error('Array mixing primitive types and objects are not supported');
  }
  if (primitiveTypesCount === 0 && objectTypeCount === 0) {
    return undefined;
  }
  return primitiveTypesCount === array.length;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getId(object: any): string {
  if (!isDefined(object)) {
    throw Error(`Undefined or null object detected`);
  }
  const id = Reflect.get(object, 'id');
  if (!id) {
    throw Error(`An object has no id ${JSON.stringify(object).replace(/"/g, "'")}`);
  }
  return id;
}

function copyFields(from: object, to: object, exceptFields: (string | number | symbol)[]): void {
  const fromKeys = Reflect.ownKeys(from);
  fromKeys.forEach((key): void => {
    if (!exceptFields.includes(key)) {
      const fromField = Reflect.get(from, key);
      const fromFieldIsArray = Array.isArray(fromField);
      if (fromFieldIsArray) {
        // Check that there is no mix
        if (!isPrimitiveArray(fromField, key)) {
          // And that each object has an ID (if it's an
          // object collection)
          fromField.forEach(getId);
        }
      }
      // Then set the value
      Reflect.set(to, key, fromField);
    }
  });
}

type ApplyObjectPayload = (initial: object, payload: undefined | DeepPartial<object>) => object;

/**
 * Applies a payload on a given object.
 * @param initial the initial object the payload has to be applied on.
 * @param payload the payload to apply.
 */
function applyArrayPayloadImpl<T extends object>(
  initial: readonly T[] | undefined,
  payload: undefined | readonly DeepPartial<T>[],
  applyObjectPayload: ApplyObjectPayload
): readonly T[] {
  if (!payload || payload.length === 0) {
    // Before returning the result, we must
    // check that the objects have identifiers
    // in the initial collection
    if (Array.isArray(initial)) {
      initial.forEach(getId);
    }
    return !initial ? [] : initial;
  }
  const newArray = initial ? [...initial] : [];
  const ids = newArray.map(getId);

  const handledTargetIds = new Set();
  payload.forEach((element): void => {
    const id = getId(element);

    if (handledTargetIds.has(id)) {
      throw Error(`Duplicate id ${id} in target array`);
    }
    handledTargetIds.add(id);

    const idx = ids.indexOf(id);
    if (idx >= 0) {
      const objectToChange = newArray[idx];
      newArray[idx] = applyObjectPayload(objectToChange, element) as T;
    } else {
      newArray.push(element as T);
    }
  });
  return newArray;
}

/**
 * Applies a payload on a given object.
 * @param initial the initial object the payload has to be applied on.
 * @param payload the payload to apply.
 */
export function applyPayload<T extends object>(initial: T, payload: undefined | DeepPartial<T>): T {
  // Prevent from getting arrays
  if (Array.isArray(initial) || Array.isArray(payload)) {
    return applyArrayPayloadImpl(
      initial as readonly any[],
      payload as readonly any[],
      applyPayload
    ) as any;
  }
  if (payload === undefined || Reflect.ownKeys(payload).length === 0) {
    return initial;
  }
  const target = {};
  const ownPayloadKeys = Reflect.ownKeys(payload);
  ownPayloadKeys.forEach((key): void => {
    const initialField = Reflect.get(initial, key);
    const initialIsArray = Array.isArray(initialField);
    const initialIsPrimitiveArray = initialIsArray
      ? isPrimitiveArray(initialField, key)
      : undefined;
    const payloadField = Reflect.get(payload, key);
    const payloadIsArray = Array.isArray(payloadField);
    const payloadIsPrimitiveArray = payloadIsArray
      ? isPrimitiveArray(payloadField, key)
      : undefined;
    if (
      (initialIsArray || payloadIsArray) &&
      (initialIsPrimitiveArray === false || payloadIsPrimitiveArray === false)
    ) {
      const initialArray = initialField as any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
      const payloadArray = payloadField as any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
      if (
        initialIsPrimitiveArray !== undefined &&
        payloadIsPrimitiveArray !== undefined &&
        initialIsPrimitiveArray !== payloadIsPrimitiveArray
      ) {
        throw Error(
          `${String(
            key
          )} array types are different (${initialField} / ${payloadField}, one is an object array, the other a primitive type array)`
        );
      }
      if (initialIsPrimitiveArray || payloadIsPrimitiveArray) {
        Reflect.set(target, key, payloadArray);
      } else {
        const newArray = applyArrayPayloadImpl(initialArray, payloadArray, applyPayload);
        Reflect.set(target, key, newArray);
      }
    }
    // If the payload field === undefined or null, targetField must be === to payloadField
    else if (!isDefined(payloadField)) {
      Reflect.set(target, key, payloadField);
    }
    // If the initial field is null or undefined, targetField must be === to payloadField
    else if (!isDefined(initialField)) {
      Reflect.set(target, key, payloadField);
    }
    // If both initialField and  payloadField are defined (!== null && !== undefined), types
    // must match
    else if (typeof initialField !== typeof payloadField) {
      throw Error(
        `${String(
          key
        )} has not the same type in the initial object (${initialField}:${typeof initialField}) and in the payload (${payloadField}:${typeof payloadField})`
      );
    } else if (initialIsArray !== payloadIsArray) {
      throw Error(
        `${String(
          key
        )} mismatch, one is an array but not the other (${initialField} / ${payloadField})`
      );
    } else if (initialIsArray) {
      Reflect.set(target, key, payloadField);
    } else {
      switch (typeof payloadField) {
        case 'boolean':
        case 'number':
        case 'bigint':
        case 'string':
        case 'symbol':
          Reflect.set(target, key, payloadField);
          break;
        case 'object':
          Reflect.set(target, key, applyPayload(initialField as object, payloadField));
          break;
        case 'function':
        case 'undefined':
        default:
          throw Error(`Unexpected type : ${typeof initialField}`);
      }
    }
  });
  // Copy non existing fields in the payload that don't exist in the initial object
  copyFields(initial, target, ownPayloadKeys);
  return target as T;
}

function computeArrayPayloadImpl<T extends object>(
  collectionName: string | undefined,
  initial: readonly T[] | undefined,
  target: readonly T[] | undefined
): undefined | readonly DeepPartial<T>[] {
  if (initial === undefined) {
    return target;
  }
  if (target === undefined) {
    return undefined;
  }
  if (initial.length > target.length) {
    throw Error(
      `Target collection cannot be smaller than the initial collection ${
        collectionName ? `(${collectionName})` : ''
      }`
    );
  }
  if (initial.length === 0) {
    // Check that the target collection contains identified objects
    target.forEach(getId);
    return target;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const payloadArray: any[] = [];
  const ids = initial.map(getId);
  const targetIds = target.map(getId);

  const missingIdsInTarget = ids.filter((id) => !targetIds.includes(id));
  if (missingIdsInTarget.length > 0) {
    // Check that the target collection contains all objects coming from source collection
    throw Error(
      `Target array does not contain all ids from source array ${
        collectionName ? `(${collectionName})` : ''
      }: missing ids are [${missingIdsInTarget.join(',')}]`
    );
  }

  const handledTargetIds = new Set();
  target.forEach((element): void => {
    const id = getId(element);

    if (handledTargetIds.has(id)) {
      throw Error(
        `Duplicate id ${id} in target array${
          collectionName ? ` for collection ${collectionName}` : ''
        }`
      );
    }
    handledTargetIds.add(id);

    const idx = ids.indexOf(id);
    if (idx >= 0) {
      const objectToChange = initial[idx];
      const changedObject = computePayload(objectToChange, element, true);
      // Process the object only if it changes
      if (changedObject !== undefined) {
        // Retrieve the keys, except the id key
        const keys = Reflect.ownKeys(changedObject).filter((aKey): boolean => aKey !== 'id');
        if (keys.length > 0) {
          payloadArray.push(changedObject);
        }
      }
    } else {
      payloadArray.push(element);
    }
  });
  return payloadArray;
}

/**
 * Computes the payload that would have to be applied on the initial version of the
 * object to get the expected target version.
 * @param initial the initial version of the object to consider.
 * @param target  the target version of the object to consider.
 * @param checkId default false. Tells whether we must check if the object has an identifier (this is
 * normally only used by the function itself when it recurses when leeting arrays of
 * objects).
 */
export function computePayload<T extends object>(
  initial: T,
  target: T,
  checkId: boolean = false
): T extends readonly (infer U)[] ? readonly DeepPartial<U>[] : undefined | DeepPartial<T> {
  // Prevent from getting arrays
  if (Array.isArray(initial) || Array.isArray(target)) {
    return computeArrayPayloadImpl(
      undefined,
      initial as readonly any[],
      target as readonly any[]
    ) as any;
  }
  const payload: DeepPartial<T> = {};
  const initialKeys = Reflect.ownKeys(initial);
  if (checkId) {
    const initialId = getId(initial);
    const targetId = getId(target);
    if (initialId !== targetId) {
      throw Error(`Objects have not the same id ${initialId} / ${targetId}`);
    }
    Reflect.set(payload, 'id', initialId);
  }
  initialKeys.forEach((key): void => {
    const initialField = Reflect.get(initial, key);
    const initialIsArray = Array.isArray(initialField);
    const initialIsPrimitiveArray = initialIsArray
      ? isPrimitiveArray(initialField, key)
      : undefined;
    const targetField = Reflect.get(target, key);
    const targetIsArray = Array.isArray(targetField);
    const targetIsPrimitiveArray = targetIsArray ? isPrimitiveArray(targetField, key) : undefined;
    // If one of both array is an object array
    //  (we have ton compare equality to false because initialIsPrimitiveArray or
    // targetIsPrimitiveArray can be undefined if we don't know if the list is
    // primitive or not (which happens for an empty list))
    if (
      (initialIsArray || targetIsArray) &&
      (initialIsPrimitiveArray === false || targetIsPrimitiveArray === false)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const payloadArray = computeArrayPayloadImpl(
        String(key),
        initialField as any[],
        targetField as any[]
      );
      if (payloadArray && payloadArray.length > 0) {
        Reflect.set(payload, key, payloadArray);
      }
    } else if (!isDefined(initialField)) {
      // Set the value only if the target is not undefined as well
      if (isDefined(targetField)) {
        Reflect.set(payload, key, targetField);
      }
    } else if (!isDefined(targetField)) {
      Reflect.set(payload, key, targetField);
    } else if (typeof initialField !== typeof targetField) {
      throw Error(
        `${String(
          key
        )} has not the same type in the initial object (${initialField}:${typeof initialField}) and in the target object (${targetField}:${typeof targetField})`
      );
    } else if (initialIsArray) {
      // Here we can only be in a primitive case (object cases have been
      // processed before)
      const initialArray = initialField as any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
      const targetArray = targetField as any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
      if (initialArray.length !== targetArray.length) {
        Reflect.set(payload, key, targetArray);
      } else {
        let equals = true;
        for (let index = 0; index < initialArray.length && equals; index += 1) {
          const initialElement = initialArray[index];
          const targetElement = targetArray[index];
          equals = initialElement === targetElement;
        }
        // Create the payload only if both arrays are different
        if (!equals) {
          Reflect.set(payload, key, targetArray);
        }
      }
    } else {
      switch (typeof initialField) {
        case 'number':
          // NaN is a trap : in Javascript NaN === NaN returns false !
          // So we have to ckeck that both are not NaN before conluding
          // that the payload must contain the given field
          const bothAreNaN = isNaN(initialField) && isNaN(targetField as number);
          if (initialField !== targetField && !bothAreNaN) {
            Reflect.set(payload, key, targetField);
          }
          break;
        case 'boolean':
        case 'bigint':
        case 'string':
        case 'symbol':
          if (initialField !== targetField) {
            Reflect.set(payload, key, targetField);
          }
          break;
        case 'object':
          {
            const nestedPayload = computePayload(initialField as object, targetField as object);
            if (nestedPayload !== undefined && Reflect.ownKeys(nestedPayload).length > 0) {
              Reflect.set(payload, key, nestedPayload);
            }
          }
          break;
        case 'function':
        case 'undefined':
        default:
          throw Error(`Unexpected type : ${typeof initialField}`);
      }
    }
  });
  // Copy non existing fields in the target that don't exist in the initial object
  copyFields(target, payload, initialKeys);
  return (Reflect.ownKeys(payload).length === 0 ? undefined : payload) as any;
}
