export type MessageDialogState = {
  readonly type: 'info' | 'warning' | 'error';
  readonly title: string;
  readonly content: string;
  readonly redirectTo?: string;
};

export type MessageModalContainerState = {
  readonly message: MessageDialogState | string | false;
};

export type ResetDialogState = {
  readonly active: boolean;
  readonly hardReset: boolean;
};

export const EMPTY_RESET_DIALOG_STATE: ResetDialogState = {
  active: false,
  hardReset: false,
};
