import i18next from 'i18next';
import type { AttachmentFolder, BaseKanban } from '@stimcar/libs-base';
import { kanbanHelpers, MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';

export function getFoldersWithKanbanSpecificAttachmentFolders(
  kanban: BaseKanban | undefined,
  contractAttachmentFolders: readonly AttachmentFolder[]
): readonly AttachmentFolder[] {
  if (isTruthy(kanban) && kanbanHelpers.isMarketplaceKanban(kanban)) {
    return [
      {
        id: MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID,
        isShareable: true,
        label: i18next.t('globals:marketplaceFolderUploadLabel'),
      },
      ...contractAttachmentFolders,
    ];
  }
  return contractAttachmentFolders;
}

export function isReservedAttachmentFolder(attachmentFolderId: string): boolean {
  return attachmentFolderId === MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID;
}
