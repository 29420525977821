/* eslint-disable jsx-a11y/control-has-associated-label */
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { WorkshopOperation, WorkshopPostCategory } from '@stimcar/libs-base';
import type { ActionCallback, ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { isTruthy, keysOf, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Button, FaIcon, ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { useGetIconForWorkshopPost } from '../../../lib/components/workshop/implantation/useGetIconForWorkshopPost.js';
import { WorkshopDisplaySparePartAsTextWithEmphaseOnMissing } from '../../../lib/components/workshop/post/WorkshopPostOperations.js';
import type { WorkshopPostViewState } from './typings/store.js';

function openWorkOnModalAction(
  { actionDispatch }: ActionContext<Store, WorkshopPostViewState>,
  operationId: string
): void {
  actionDispatch.reduce((initial): WorkshopPostViewState => {
    return {
      ...initial,
      workOnFurtherOperationModal: {
        active: true,
        furtherOperationToWorkOn: operationId,
      },
    };
  });
}

interface SingleFurtherOperationLineProps {
  readonly workshopOperation: WorkshopOperation;
  readonly $: StoreStateSelector<Store, WorkshopPostViewState>;
}
function SingleFurtherOperationLine({
  workshopOperation,
  $,
}: SingleFurtherOperationLineProps): JSX.Element {
  const [t] = useTranslation('workshop');
  const showWorkOnModal = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openWorkOnModalAction, workshopOperation.id);
    },
    [workshopOperation.id],
    $
  );

  return (
    <tr key={workshopOperation.id}>
      <td>{workshopOperation.carElement.label ?? t('post.miscCarElementLabel')}</td>
      <td style={{ textAlign: 'right' }}>{workshopOperation.workload.toFixed(2)}</td>
      <td>
        <WorkshopDisplaySparePartAsTextWithEmphaseOnMissing
          receivedSpareParts={workshopOperation.receivedSpareParts}
          missingSpareParts={workshopOperation.missingSpareParts}
        />
      </td>
      <td>{workshopOperation.packageDealComment}</td>
      <td>
        <Button
          label={t('post.workOn')}
          additionalClass="button is-primary"
          onClick={showWorkOnModal}
        />
      </td>
    </tr>
  );
}

interface WorkshopPostWithOperationsProps extends AppProps<Store> {
  readonly tubeStandId: string;
  readonly workshopPostCategory: WorkshopPostCategory;
  readonly $: StoreStateSelector<Store, WorkshopPostViewState>;
  readonly operationsOfWorkshopPost: Record<string, readonly WorkshopOperation[]>;
}
function WorkshopPostWithOperations({
  workshopPostCategory,
  tubeStandId,
  $,
  $gs,
  operationsOfWorkshopPost,
}: WorkshopPostWithOperationsProps): JSX.Element {
  const [t] = useTranslation('workshop');

  const computedIcon = useGetIconForWorkshopPost($gs, tubeStandId, workshopPostCategory.id);
  const opLabels = isTruthy(operationsOfWorkshopPost)
    ? keysOf(operationsOfWorkshopPost).slice().sort()
    : [];

  if (!isTruthy(operationsOfWorkshopPost) || keysOf(operationsOfWorkshopPost).length === 0) {
    return <></>;
  }

  return (
    <>
      <p className="title is-3 box" style={{ marginBottom: 0, marginTop: '1em' }}>
        <FaIcon id={computedIcon} additionalClass="m-r-md" />
        {workshopPostCategory.id}
      </p>
      {opLabels.map((opLabel): JSX.Element => {
        const opsForOneCarElement = operationsOfWorkshopPost[opLabel];
        return (
          <div key={opLabel} className="box" style={{ marginBottom: '0.5em' }}>
            <p className="title is-4" style={{ marginBottom: 0 }}>
              {opLabel}
            </p>
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: '30%' }}>{t('post.table.carElement')}</th>
                  <th style={{ width: '4%' }}>{t('post.table.workload')}</th>
                  <th style={{ width: '30%' }}>{t('post.table.spareParts')}</th>
                  <th style={{ width: '35%' }}>{t('post.table.comment')}</th>
                  <th style={{ width: '1%' }}> </th>
                </tr>
              </thead>
              <tbody>
                {opsForOneCarElement.map(
                  (workshopOperation): JSX.Element => (
                    <SingleFurtherOperationLine
                      workshopOperation={workshopOperation}
                      $={$}
                      key={workshopOperation.id}
                    />
                  )
                )}
              </tbody>
            </table>
          </div>
        );
      })}
    </>
  );
}

type WorkOnFurtherOperationAction = (
  ctx: ActionContext<Store, WorkshopPostViewState>,
  operationId: string
) => Promise<void> | void;

export interface FurtherOperationsProps extends AppProps<Store> {
  readonly tubeStandId: string;
  readonly postId: string;
  readonly operationsGroupedByWorkshopCategoryThenOpLabel: Record<
    string,
    Record<string, readonly WorkshopOperation[]>
  >;
  readonly workOnFurtherOperationCallback: ActionCallback<Store, WorkOnFurtherOperationAction>;
  readonly nextLevels: readonly WorkshopPostCategory[];
}

export function FurtherOperations({
  $gs,
  tubeStandId,
  operationsGroupedByWorkshopCategoryThenOpLabel,
  workOnFurtherOperationCallback,
  nextLevels,
}: FurtherOperationsProps): JSX.Element {
  const [t] = useTranslation('workshop');
  const { $workshopPostView } = $gs;
  const { $workOnFurtherOperationModal } = $workshopPostView;

  const furtherOperationToWorkOn = useGetState(
    $workshopPostView.$workOnFurtherOperationModal.$furtherOperationToWorkOn
  );

  const workOn = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.execCallback(
        workOnFurtherOperationCallback,
        nonnull(furtherOperationToWorkOn)
      );
    },
    [furtherOperationToWorkOn, workOnFurtherOperationCallback],
    $workshopPostView
  );

  return (
    <>
      {nextLevels.map((category): JSX.Element => {
        const operationsOfWorkshopPost =
          operationsGroupedByWorkshopCategoryThenOpLabel[category.id];
        return (
          <WorkshopPostWithOperations
            key={category.id}
            $gs={$gs}
            tubeStandId={tubeStandId}
            operationsOfWorkshopPost={operationsOfWorkshopPost}
            $={$workshopPostView}
            workshopPostCategory={category}
          />
        );
      })}
      <ModalCardDialog
        $active={$workOnFurtherOperationModal.$active}
        title={t('post.workOnFurtherOperationsModal.title')}
        onOkClicked={workOn}
        okLabel={t('post.workOnFurtherOperationsModal.okLabel')}
      >
        <p>{t('post.workOnFurtherOperationsModal.msg')}</p>
      </ModalCardDialog>
    </>
  );
}
