import type {
  Attachment,
  AttributeType,
  CarViewCategory,
  Kanban,
  KanbanMessage,
  Operation,
  PackageDeal,
  SparePart,
} from '@stimcar/libs-base';
import type { Entity } from '@stimcar/libs-kernel';
import type {
  BusinessObjectToFormData,
  ObjectStateType,
  WithFormValidationWarnings,
} from '@stimcar/libs-uikernel';
import type { FormWithValidationState } from '@stimcar/libs-uitoolkit';
import { EXTERNAL_STANDARD_PICTURES, INTERNAL_STANDARD_PICTURES } from '@stimcar/libs-kernel';
import type {
  AttachmentsTabState,
  ConfirmAttachmentDialogState,
  PictureEditionToolkitState,
} from '../../../lib/components/attachments/typings/store.js';
import type { EditCustomerDialogState } from '../../../lib/components/customer/typings/store.js';
import type { EstimateViewState } from '../../../lib/components/documentGeneration/estimate/typings/store.js';
import type {
  EditDueDateDialogState,
  EditRefitEndDateDialogState,
} from '../../../lib/components/identityPictureAndGeneralInfoDisplay/typings/store.js';
import type { SortState } from '../../../lib/components/TableSortableHeaderComponent.js';
import type {
  DisplayAttributesState,
  PictureInputState,
} from '../../../lib/components/typings/store.js';
import type { ComponentWithMountTracking } from '../../../lib/hooks/useComponentWithMountTracking.js';
import type { SelectPredefinedCustomerDialogState } from '../../creation/typings/store.js';
import type { UserDefinedNullMemo } from '../../operators/typings/store.js';
import type { EditGeneralInformationsDialogState } from '../../utils/generalInformations/typings/store.js';
import type { ShareKanbanModalState } from '../../utils/typings/store.js';
import type { KanbanInvoiceTabState } from '../invoicing/typings/store.js';
import type { MarketplaceDetailsState } from '../marketplace/typings/store.js';
import {
  ATTACHMENTS_TAB_EMPTY_STATE,
  EMPTY_CONFIRM_ATTACHMENT_DIALOG_STATE,
  EMPTY_PICTURE_EDITION_TOOLKIT_STATE,
} from '../../../lib/components/attachments/typings/store.js';
import { EMPTY_EDIT_CUSTOMER_DIALOG_STATE } from '../../../lib/components/customer/typings/store.js';
import { EMPTY_ESTIMATE_VIEW_STATE } from '../../../lib/components/documentGeneration/estimate/typings/store.js';
import {
  EMPTY_EDIT_DUE_DATE_DIALOG_STATE,
  EMPTY_EDIT_REFIT_END_DATE_DIALOG_STATE,
} from '../../../lib/components/identityPictureAndGeneralInfoDisplay/typings/store.js';
import { DISPLAY_ATTRIBUTES_EMPTY_STATE } from '../../../lib/components/typings/store.js';
import { EMPTY_SELECT_PREDEFINED_CUSTOMER_DIALOG_SATE } from '../../creation/typings/store.js';
import { EMPTY_EDIT_GENERAL_INFORMATIONS_DIALOG_STATE } from '../../utils/generalInformations/typings/store.js';
import { EMPTY_SHARE_KANBAN_MODAL_STATE } from '../../utils/typings/store.js';
import { EMPTY_KANBAN_INVOICE_TAB_STATE } from '../invoicing/typings/store.js';
import { MARKETPLACE_DETAILS_EMPTY_STATE } from '../marketplace/typings/store.js';

export enum KanbanDetailsContentDisplayTabs {
  history = 'history',
  packageDeals = 'packageDeals',
  operations = 'operations',
  kanbanMessages = 'kanbanMessages',
  kanbanAttributes = 'kanbanAttributes',
  standardPictures = 'standardPictures',
  marketplace = 'marketplace',
  memos = 'memos',
  invoice = 'invoice',
  files = 'files',
  spareParts = 'spareParts',
  workSheet = 'workSheet',
}

export type ListWithTechnicalIdTabState = {
  readonly showTechnicalId: boolean;
};

export type KanbanMessageRequestAnswerResponseType = 'accept' | 'reject';

export type KanbanMessageRequestAnswerModalState = {
  readonly active: boolean;
  readonly choice: KanbanMessageRequestAnswerResponseType;
  readonly messageId: string;
};

export const KANBAN_MESSAGE_REQUEST_ANSWER_MODAL_EMPTY_STATE: KanbanMessageRequestAnswerModalState =
  {
    active: false,
    choice: 'reject',
    messageId: '',
  };

type AddKanbanMessageInputFormData = {
  readonly message: string;
};

export type AddKanbanMessageInputState = FormWithValidationState<AddKanbanMessageInputFormData>;

export const EMPTY_ADD_KANBAN_MESSAGE_INPUT_STATE: AddKanbanMessageInputState = {
  formSubmitted: false,
  formWarning: undefined,
  formSubmitClickedOnce: false,
  formData: { message: '', warnings: {} },
};

export type KanbanMessageTabState = ListWithTechnicalIdTabState & {
  readonly sort: SortState<'timestamp' | 'username'>;
  readonly addKanbanMessageInputState: AddKanbanMessageInputState;
  readonly messageRequestAnswerModal: KanbanMessageRequestAnswerModalState;
};

export const EMPTY_KANBAN_MESSAGE_TAB_STATE: KanbanMessageTabState = {
  showTechnicalId: false,
  sort: { by: 'timestamp', direction: 'UP' },
  addKanbanMessageInputState: EMPTY_ADD_KANBAN_MESSAGE_INPUT_STATE,
  messageRequestAnswerModal: KANBAN_MESSAGE_REQUEST_ANSWER_MODAL_EMPTY_STATE,
};

export type KanbanDetailsHandlingSortValues = 'stand' | 'users' | 'date' | 'duration';

export type CloseHandlingConfirmDialogState = {
  readonly active: boolean;
  readonly handlingId: string;
  readonly intervalId: string;
};

export const EMPTY_CLOSE_HANDLING_CONFIRM_DIALOG_STATE: CloseHandlingConfirmDialogState = {
  active: false,
  handlingId: '',
  intervalId: '',
};

export type HistoryTabState = ListWithTechnicalIdTabState & {
  readonly sort: SortState<KanbanDetailsHandlingSortValues>;
  readonly closeHandlingConfirmDialog: CloseHandlingConfirmDialogState;
};

const EMPTY_HISTORY_TAB_STATE: HistoryTabState = {
  showTechnicalId: false,
  sort: { by: 'date', direction: 'UP' },
  closeHandlingConfirmDialog: EMPTY_CLOSE_HANDLING_CONFIRM_DIALOG_STATE,
};

export type OperationFormData = {
  readonly workload: string;
  readonly label: string;
  readonly standId: string;
};

export type OperationForm = WithFormValidationWarnings<OperationFormData>;

export const EMPTY_OPERATION_FORM: OperationForm = {
  workload: '',
  label: '',
  standId: '',
  warnings: {},
};

export type EditOperationModalState = FormWithValidationState<OperationFormData> & {
  readonly active: boolean;
  readonly initialOperation: Operation | undefined;
  readonly isExpertisePackageDeal: boolean;
};

export const EMPTY_EDIT_OPERATION_MODAL_STATE: EditOperationModalState = {
  active: false,
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formWarning: undefined,
  formData: EMPTY_OPERATION_FORM,
  initialOperation: undefined,
  isExpertisePackageDeal: false,
};

export type MarkAsUnachievablePackageDealFormData = {
  readonly reason: string;
};

export type MarkAsUnachievablePackageDealForm =
  WithFormValidationWarnings<MarkAsUnachievablePackageDealFormData>;

export const EMPTY_MARK_AS_UNACHIEVABLE_PACKAGE_DEAL_FORM: MarkAsUnachievablePackageDealForm = {
  reason: '',
  warnings: {},
};

export type MarkAsUnachievablePackageDealModalState =
  FormWithValidationState<MarkAsUnachievablePackageDealFormData> & {
    readonly active: boolean;
    readonly repickActive: boolean;
    readonly packageDealId: string;
    readonly packageDealName: string;
    readonly kanbanId: string;
    readonly pkgUnachievableStatus: PkgUnachievableStatus | undefined;
  };

export const EMPTY_MARK_AS_UNACHIEVABLE_PACKAGE_DEAL_MODAL_STATE: MarkAsUnachievablePackageDealModalState =
  {
    active: false,
    repickActive: false,
    formSubmitClickedOnce: false,
    formSubmitted: false,
    formWarning: undefined,
    formData: EMPTY_MARK_AS_UNACHIEVABLE_PACKAGE_DEAL_FORM,
    packageDealId: '',
    packageDealName: '',
    kanbanId: '',
    pkgUnachievableStatus: undefined,
  };

export type DelegatePackageDealFormData = {
  readonly delegationSite: string;
};

export type DelegatePackageDealForm = WithFormValidationWarnings<DelegatePackageDealFormData>;

export const EMPTY_DELEGATE_PACKAGE_DEAL_FORM: DelegatePackageDealForm = {
  delegationSite: '',
  warnings: {},
};

export type SiteRef = Entity & {
  readonly companyId: string;
};

export type DelegatePackageDealModalState = FormWithValidationState<DelegatePackageDealFormData> & {
  readonly active: boolean;
  readonly kanbanId: string;
  readonly packageDealId: string;
  readonly availableSites: readonly SiteRef[];
};

const EMPTY_DELEGATE_PACKAGE_DEAL_MODAL_STATE: DelegatePackageDealModalState = {
  ...EMPTY_DELEGATE_PACKAGE_DEAL_FORM,
  active: false,
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formWarning: undefined,
  formData: EMPTY_DELEGATE_PACKAGE_DEAL_FORM,
  availableSites: [],
  kanbanId: '',
  packageDealId: '',
};

export type KanbanDetailsOperationSortValues =
  | 'packageDealLabel'
  | 'translatedCategoryLabel'
  | 'label'
  | 'stand'
  | 'workload'
  | 'operationCompletionDate'
  | 'carElement';
type OperationsTabState = ListWithTechnicalIdTabState & {
  readonly sort: SortState<KanbanDetailsOperationSortValues>;
  readonly editOperationModal: EditOperationModalState;
};

const EMPTY_OPERATIONS_TAB_STATE: OperationsTabState = {
  showTechnicalId: false,
  sort: { by: undefined, direction: 'UP' },
  editOperationModal: EMPTY_EDIT_OPERATION_MODAL_STATE,
};

export type KanbanDetailsPackageDealSortValues =
  | 'purchaseOrder'
  | 'code'
  | 'tag'
  | 'label'
  | 'carElement'
  | 'price'
  | 'duration'
  | 'translatedCategoryLabel'
  | 'completionDate'
  | 'delegationSite';

export enum PackageDealsDisplayTabs {
  availablePackageDeals = 'availablePackageDeals',
  canceledPackageDeals = 'canceledPackageDeals',
  unachievablePackageDeals = 'unachievablePackageDeals',
}

export type EditPackageDealWorkshopCommentModalState = FormWithValidationState<{
  comment: string;
}> & {
  readonly active: boolean;
  readonly initialComment: string;
  readonly packageDealId: string;
  readonly packageDealCode: string;
  readonly kanbanId: string;
};

export const EDIT_PACKAGE_DEAL_WORKSHOP_COMMENT_MODAL_EMPTY_STATE: EditPackageDealWorkshopCommentModalState =
  {
    active: false,
    formData: {
      comment: '',
      warnings: {},
    },
    kanbanId: '',
    packageDealCode: '',
    packageDealId: '',
    formSubmitClickedOnce: false,
    formSubmitted: false,
    formWarning: undefined,
    initialComment: '',
  };
export type PackageDealsTabState = ListWithTechnicalIdTabState & {
  readonly sort: SortState<KanbanDetailsPackageDealSortValues>;
  readonly selectedCategories: readonly string[];
  readonly markAsUnachievablePackageDealModalState: MarkAsUnachievablePackageDealModalState;
  readonly delegatePackageDealModalState: DelegatePackageDealModalState;
  readonly packageDealsDisplayTabs: PackageDealsDisplayTabs;
  readonly updateWorkshopCommentModal: EditPackageDealWorkshopCommentModalState;
};

const EMPTY_PACKAGE_DEALS_TAB_STATE: PackageDealsTabState = {
  showTechnicalId: false,
  sort: { by: undefined, direction: 'UP' },
  selectedCategories: ['BUMP', 'EXTE', 'INTE', 'MECA', 'MISC'],
  markAsUnachievablePackageDealModalState: EMPTY_MARK_AS_UNACHIEVABLE_PACKAGE_DEAL_MODAL_STATE,
  packageDealsDisplayTabs: PackageDealsDisplayTabs.availablePackageDeals,
  updateWorkshopCommentModal: EDIT_PACKAGE_DEAL_WORKSHOP_COMMENT_MODAL_EMPTY_STATE,
  delegatePackageDealModalState: EMPTY_DELEGATE_PACKAGE_DEAL_MODAL_STATE,
};

type ViewToShow = 'details' | 'estimate' | 'delivery';

export type HistoryDetailsInternalDataStructure =
  | IntervalDetailsInternalDataStructure
  | EmptyHandlingDetailsInternalDataStructure
  | MessageDetailsInternalDataStructure;

type HistoryDetailsCommonInternalDataStructure = {
  readonly id: string;
  readonly users: readonly string[];
  readonly date: number;
  readonly iconId: string;
};

export type MessageDetailsInternalDataStructure = HistoryDetailsCommonInternalDataStructure & {
  readonly content: string;
};

export type EmptyHandlingDetailsInternalDataStructure =
  HistoryDetailsCommonInternalDataStructure & {
    readonly stand: string;
    readonly post: string;
    readonly endDate: number;
    readonly isCurrent: boolean;
  };

export type IntervalDetailsInternalDataStructure = EmptyHandlingDetailsInternalDataStructure & {
  readonly handlingId: string;
  readonly comment: string;
};

export type MemoDetailsInternalDataStructure = UserDefinedNullMemo;

export type PkgUnachievableStatus =
  | 'markableAsUnachievable'
  | 'repickable'
  | 'unmarkableAsUnachievableBecauseMandatory'
  | 'unmarkableAsUnachievableBecauseOfFinishedOperations'
  | 'unmarkableAsUnachievableBecauseOfStatus'
  | 'unmarkableAsUnachievableBecauseGroupedByTag'
  | 'unmarkableAsUnachievableBecauseOrderedOrReceivedSpareParts';

export type KanbanDetailsElementStatus = 'available' | 'canceled' | 'unachievable';

export type PackageDealDetailsInternalDataStructure = {
  readonly id: string;
  readonly purchaseOrder: string | undefined;
  readonly code: string;
  readonly label: string;
  readonly carElement: string;
  readonly category: CarViewCategory;
  readonly tag: string;
  readonly translatedCategoryLabel: string;
  readonly price: number;
  readonly duration: number;
  readonly spareParts: readonly SparePart[];
  readonly operations: readonly Operation[];
  readonly hint: string;
  readonly comment: string;
  readonly status: KanbanDetailsElementStatus | undefined;
  readonly attachments: readonly Attachment[];
  readonly unachievableStatus: PkgUnachievableStatus;
  readonly recommendedByExpert: boolean;
  readonly unachievableReason: string | undefined;
  readonly completionDate: number | null;
  readonly startDate: number | null;
  readonly operationsCount: number;
  readonly delegatedPackageDealId: string | null;
  readonly attributes: Record<string, AttributeType>;
  readonly delegationSite: string | undefined;
};

export type OperationDetailsInternalDataStructure = {
  readonly id: string;
  readonly standId: string;
  readonly packageDealLabel: string;
  readonly isExpertisePackageDeal: boolean;
  readonly translatedCategoryLabel: string;
  readonly label: string;
  readonly stand: string;
  readonly carElement: string;
  readonly workload: number;
  readonly status: KanbanDetailsElementStatus | undefined;
  readonly operationCompletionDate: number | null;
  readonly user: string | null;
  readonly subcontractor: string | null | undefined;
  readonly attributes: Record<string, AttributeType>;
  readonly orderIndex: number;
};

export type AttributeDetailsInternalDataStructure = {
  readonly label: string;
  readonly value: string;
  readonly isDisplayedEstimate: boolean;
  readonly isMandatory: boolean;
};

export type SparePartDetailStatus = 'referenced' | 'ordered' | 'received' | 'none';

export type SparePartDetailsInternalDataStructure = SparePart & {
  readonly packageDealLabel: string;
  readonly translatedCategoryLabel: string;
  readonly carElement: string;
  readonly status: SparePartDetailStatus;
  readonly pdStatus: KanbanDetailsElementStatus | undefined;
  readonly providerPrice: number;
  readonly marginPercentage: number;
};

export type KanbanDetailsSparePartSortValues =
  | 'packageDealLabel'
  | 'translatedCategoryLabel'
  | 'carElement'
  | 'label'
  | 'standId'
  | 'managementType'
  | 'price'
  | 'providerPrice'
  | 'marginPercentage'
  | 'status'
  | 'commentForCustomer'
  | 'commentForWorkshop'
  | 'comment'
  | 'provider';

export type MoveStandSparePartFormData = {
  readonly standId: string;
};

export type MoveStandSparePartForm = WithFormValidationWarnings<MoveStandSparePartFormData>;

export const EMPTY_MOVE_STAND_SPARE_PART_FORM: MoveStandSparePartForm = {
  standId: '',
  warnings: {},
};

export type MoveStandSparePartModalState = FormWithValidationState<MoveStandSparePartFormData> & {
  readonly active: boolean;
  readonly sparePartId: string | undefined;
  readonly sparePartLabel: string | undefined;
  readonly currentStandId: string | undefined;
};

export const EMPTY_MOVE_STAND_SPARE_PART_MODAL_STATE: MoveStandSparePartModalState = {
  active: false,
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formWarning: undefined,
  formData: EMPTY_MOVE_STAND_SPARE_PART_FORM,
  sparePartId: undefined,
  sparePartLabel: undefined,
  currentStandId: undefined,
};

type SparePartsTabState = ListWithTechnicalIdTabState & {
  readonly sort: SortState<KanbanDetailsSparePartSortValues>;
  readonly moveStandSparePartModalState: MoveStandSparePartModalState;
};

const EMPTY_SPARE_PARTS_TAB_STATE: SparePartsTabState = {
  showTechnicalId: false,
  sort: { by: 'managementType', direction: 'DOWN' },
  moveStandSparePartModalState: EMPTY_MOVE_STAND_SPARE_PART_MODAL_STATE,
};

export type StandardPicturesTabState = {
  readonly isUnfoldedOnMobile: boolean;
  readonly pictures: readonly PictureInputState[];
  readonly confirmAttachmentRemovalDialog: ConfirmAttachmentDialogState;
};

export const STANDARD_PICTURES_TABS_EMPTY_STATE: StandardPicturesTabState = {
  pictures: [
    ...Object.values(EXTERNAL_STANDARD_PICTURES),
    ...Object.values(INTERNAL_STANDARD_PICTURES),
  ].map(({ id }) => ({
    id,
    isSet: false,
    displayCameraModal: false,
  })),
  confirmAttachmentRemovalDialog: EMPTY_CONFIRM_ATTACHMENT_DIALOG_STATE,
  isUnfoldedOnMobile: false,
};

export type DesktopKanbanDetailsState = {
  readonly kanbanDetailsContentDisplayTab: KanbanDetailsContentDisplayTabs;
  readonly historyTab: HistoryTabState;
  readonly kanbanMessageTab: KanbanMessageTabState;
  readonly kanbanInvoiceTab: KanbanInvoiceTabState;
  readonly operationsTab: OperationsTabState;
  readonly packageDealsTab: PackageDealsTabState;
  readonly attributesTab: DisplayAttributesState;
  readonly marketplaceTab: MarketplaceDetailsState;
  readonly sparePartsTab: SparePartsTabState;
  readonly showTimeline: boolean;
  readonly viewToShow: ViewToShow;
  readonly estimateView: EstimateViewState;
  readonly isIncorrectOrientationModalOpen: boolean;
  readonly pictureEditionToolkitState: PictureEditionToolkitState;
};

export const DESKTOP_KANBAN_DETAILS_EMPTY_STATE: DesktopKanbanDetailsState = {
  kanbanDetailsContentDisplayTab: KanbanDetailsContentDisplayTabs.history,
  historyTab: EMPTY_HISTORY_TAB_STATE,
  kanbanMessageTab: EMPTY_KANBAN_MESSAGE_TAB_STATE,
  kanbanInvoiceTab: EMPTY_KANBAN_INVOICE_TAB_STATE,
  operationsTab: EMPTY_OPERATIONS_TAB_STATE,
  packageDealsTab: EMPTY_PACKAGE_DEALS_TAB_STATE,
  sparePartsTab: EMPTY_SPARE_PARTS_TAB_STATE,
  showTimeline: false,
  viewToShow: 'details',
  estimateView: EMPTY_ESTIMATE_VIEW_STATE,
  attributesTab: DISPLAY_ATTRIBUTES_EMPTY_STATE,
  isIncorrectOrientationModalOpen: false,
  pictureEditionToolkitState: EMPTY_PICTURE_EDITION_TOOLKIT_STATE,
  marketplaceTab: MARKETPLACE_DETAILS_EMPTY_STATE,
};

export type MobileDetailsSubPartState<T extends ObjectStateType> = {
  readonly isUnfolded: boolean;
  readonly showDetailsFor?: T;
};

export type MobileKanbanMessageState = MobileDetailsSubPartState<KanbanMessage> & {
  readonly messageRequestAnswerModal: KanbanMessageRequestAnswerModalState;
};

export type MobileKanbanDetailsAttachmentState = {
  readonly isExpanded: boolean;
};

export type MobileKanbanDetailsState = {
  readonly showAllDetailsModal: boolean;
  readonly isGraphicWorkflowUnfolded: boolean;
  readonly attachmentFolderStates: Record<string, MobileKanbanDetailsAttachmentState>;
  readonly history: MobileDetailsSubPartState<HistoryDetailsInternalDataStructure>;
  readonly availablePackageDeals: MobileDetailsSubPartState<PackageDealDetailsInternalDataStructure>;
  readonly canceledPackageDeals: MobileDetailsSubPartState<PackageDealDetailsInternalDataStructure>;
  readonly unachievablePackageDeals: MobileDetailsSubPartState<PackageDealDetailsInternalDataStructure>;
  readonly operations: MobileDetailsSubPartState<OperationDetailsInternalDataStructure>;
  readonly attributes: MobileDetailsSubPartState<AttributeDetailsInternalDataStructure>;
  readonly memos: MobileDetailsSubPartState<MemoDetailsInternalDataStructure>;
  readonly marketplace: MobileDetailsSubPartState<MarketplaceDetailsState>;
  readonly kanbanMessages: MobileKanbanMessageState;
};

export const MOBILE_KANBAN_DETAILS_EMPTY_STATE: MobileKanbanDetailsState = {
  showAllDetailsModal: false,
  isGraphicWorkflowUnfolded: false,
  attachmentFolderStates: {},
  availablePackageDeals: { isUnfolded: false },
  canceledPackageDeals: { isUnfolded: false },
  unachievablePackageDeals: { isUnfolded: false },
  operations: { isUnfolded: false },
  history: { isUnfolded: false },
  attributes: { isUnfolded: false },
  memos: { isUnfolded: false },
  kanbanMessages: {
    isUnfolded: false,
    messageRequestAnswerModal: KANBAN_MESSAGE_REQUEST_ANSWER_MODAL_EMPTY_STATE,
  },
  marketplace: { isUnfolded: false, showDetailsFor: MARKETPLACE_DETAILS_EMPTY_STATE },
};

export type ActionOnKanbanWithReasonData = BusinessObjectToFormData<{
  readonly reason: string;
  readonly force: boolean;
}>;

export const ACTION_ON_KANBAN_WITH_REASON_EMPTY_DATA: WithFormValidationWarnings<ActionOnKanbanWithReasonData> =
  {
    reason: '',
    force: false,
    warnings: {},
  };

export type RevokeKanbanModalState = FormWithValidationState<ActionOnKanbanWithReasonData> & {
  readonly reasonDialog: {
    readonly active: boolean;
  };
  readonly confirmDialog: {
    readonly active: boolean;
  };
};

export const EMPTY_REVOKE_KANBAN_MODAL_STATE: RevokeKanbanModalState = {
  reasonDialog: {
    active: false,
  },
  confirmDialog: {
    active: false,
  },
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formWarning: undefined,
  formData: ACTION_ON_KANBAN_WITH_REASON_EMPTY_DATA,
};

export type KanbanCancellationModalState = FormWithValidationState<ActionOnKanbanWithReasonData> & {
  readonly reasonDialog: {
    readonly active: boolean;
  };
  readonly notAllowedDialog: {
    readonly active: boolean;
  };
  readonly confirmDialog: {
    readonly active: boolean;
  };
  readonly blockingPackageDeals?: readonly PackageDeal[];
  readonly packageDealsToCharge: readonly PackageDeal[];
  readonly totalPriceToChargeWithoutVat: number;
  readonly vat: number;
  readonly totalPriceToCharge: number;
};

export const EMPTY_KANBAN_CANCELLATION_MODAL_STATE: KanbanCancellationModalState = {
  reasonDialog: {
    active: false,
  },
  notAllowedDialog: {
    active: false,
  },
  confirmDialog: {
    active: false,
  },
  packageDealsToCharge: [],
  totalPriceToChargeWithoutVat: 0,
  vat: 0,
  totalPriceToCharge: 0,
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formWarning: undefined,
  formData: ACTION_ON_KANBAN_WITH_REASON_EMPTY_DATA,
};

export type KanbanDetailsState = ComponentWithMountTracking & {
  readonly handleKanbanMenuActive: boolean;
  readonly selectedKanban: Kanban | undefined;
  readonly desktopState: DesktopKanbanDetailsState;
  readonly mobileState: MobileKanbanDetailsState;
  readonly shareKanbanModal: ShareKanbanModalState;
  readonly revokeKanbanModal: RevokeKanbanModalState;
  readonly kanbanCancellationModal: KanbanCancellationModalState;
  readonly attachmentsState: AttachmentsTabState;
  readonly editCustomerDialogState: EditCustomerDialogState;
  readonly editGeneralInformationsDialogState: EditGeneralInformationsDialogState;
  readonly selectPredefinedCustomerDialogState: SelectPredefinedCustomerDialogState;
  readonly editDueDateDialogState: EditDueDateDialogState;
  readonly editRefitEndDateDialogState: EditRefitEndDateDialogState;
  readonly standardPicturesTab: StandardPicturesTabState;
};

export const EMPTY_KANBAN_DETAILS_STATE: KanbanDetailsState = {
  handleKanbanMenuActive: false,
  componentIsMounted: false,
  selectedKanban: undefined,
  shareKanbanModal: EMPTY_SHARE_KANBAN_MODAL_STATE,
  revokeKanbanModal: EMPTY_REVOKE_KANBAN_MODAL_STATE,
  kanbanCancellationModal: EMPTY_KANBAN_CANCELLATION_MODAL_STATE,
  desktopState: DESKTOP_KANBAN_DETAILS_EMPTY_STATE,
  mobileState: MOBILE_KANBAN_DETAILS_EMPTY_STATE,
  attachmentsState: ATTACHMENTS_TAB_EMPTY_STATE,
  editCustomerDialogState: EMPTY_EDIT_CUSTOMER_DIALOG_STATE,
  editGeneralInformationsDialogState: EMPTY_EDIT_GENERAL_INFORMATIONS_DIALOG_STATE,
  selectPredefinedCustomerDialogState: EMPTY_SELECT_PREDEFINED_CUSTOMER_DIALOG_SATE,
  editDueDateDialogState: EMPTY_EDIT_DUE_DATE_DIALOG_STATE,
  editRefitEndDateDialogState: EMPTY_EDIT_REFIT_END_DATE_DIALOG_STATE,
  standardPicturesTab: STANDARD_PICTURES_TABS_EMPTY_STATE,
};
