import type { JSX } from 'react';
import React from 'react';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { useGetState } from '@stimcar/libs-uikernel';
import type { LightRegisteredAppStore } from '../../state/typings/store.js';
import { NetworkIcon } from './NetworkIcon.js';

export function NetworkIconWithRepositoryChanges<SD extends LightRegisteredAppStore>({
  $gs,
}: AppProps<SD>): JSX.Element {
  const localRepositoryChangesCount = useGetState($gs.$session.$localRepositoryChangesCount);
  return (
    <div className="navbar-item">
      {localRepositoryChangesCount > 0 && (
        <div
          className="button is-rounded is-narrow is-small has-background-beige-light"
          style={{ zIndex: 1, position: 'absolute', padding: '0.1rem', height: '0.8rem' }}
        >
          {localRepositoryChangesCount}
        </div>
      )}
      <NetworkIcon $gs={$gs} />
    </div>
  );
}
